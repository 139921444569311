<template>
  <div class="moin-fresh">
    <v-divider class="mb-4" />
    <v-row>
      <template v-if="currentBotContactId">
        <v-col cols="12">
          <v-card-title class="pa-0 primary--text text--lighten-2">
            <v-icon color="primary lighten-2" class="pt-1" left>
              contact_support
            </v-icon>
            <span class="d-inline-block">{{ $t(`welcome.supportTitle`) }}</span>
          </v-card-title>
        </v-col>
        <v-col>
          <v-card class="fill-height mx-n3" flat>
            <ContactItem
              :contact-id="currentBotContactId"
              :title="$t(`welcome.supportContact.${currentBotStage}`)"
              large
            />
          </v-card>
        </v-col>
        <v-divider vertical class="d-none d-md-inline-block mx-4 my-4" />
      </template>
      <template v-else>
        <v-col class="col-2 hidden-sm-and-down">&nbsp;</v-col>
      </template>
      <v-col
        class="col-12 col-md-6"
        :class="{ 'col-12 col-md-8': !currentBotContactId }"
      >
        <div class="pt-3 px-0 mx-n4 mx-sm-0 px-sm-9">
          <ExpertTipSlider />
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import ContactItem from '@/components/ContactItem.vue';
import ExpertTipSlider from '@/components/welcome/WelcomeExpertTipSlider.vue';

export default {
  name: 'WelcomeSupport',
  components: {
    ContactItem,
    ExpertTipSlider,
  },
  data() {
    return {
      contact: null,
    };
  },
  computed: {
    ...mapGetters('bots', ['currentBotContactId', 'currentBotStage']),
  },
};
</script>

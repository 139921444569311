<template>
  <v-container fluid>
    <v-row>
      <v-col xs="12" sm="12" md="3" >
        <v-card class color="#fafafa" style="padding: 20px">
          TODO <br />
          Chat Bot Preview
        </v-card>
      </v-col>
      <v-col xs="12" sm="12" md="7" >
        <v-card color="transparent" flat>
          <v-card-title class="pt-0">{{$t(`channelSettings.config.headline`)}}</v-card-title>
          <v-card-subtitle>{{$t(`channelSettings.config.subtitle`)}}</v-card-subtitle>
          <v-card-text>{{ $t(`channelSettings.config.explanantion`) }}</v-card-text>
          <v-card-actions class="px-16 d-flex flex-column justify-start align-start">
            <v-container fluid>
              <v-text-field label="Widget Title" v-model="widget_title"></v-text-field>
              <v-text-field label="Chatbot Name" v-model="chatbot_name"></v-text-field>
              <v-text-field label="Chatbot Title" v-model="chatbot_title"></v-text-field>
              <v-text-field label="Avatar URL" v-model="avatar_url">
                <template v-slot:append-outer>
                    <v-avatar size=32>
                      <img :src="avatar_url" alt="Avatar Preview">
                    </v-avatar>
                </template>
              </v-text-field>
            </v-container>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { createHelpers } from 'vuex-map-fields';

const { mapFields } = createHelpers({
  getterType: 'channelsettings/getField',
  mutationType: 'channelsettings/updateField',
});

export default {
  name: 'WidgetConfigFields',
  props: ['config'],
  computed: {
    ...mapFields({
      widget_title: 'settings.header.name',
      chatbot_name: 'settings.operator.name',
      chatbot_title: 'settings.operator.titel',
      avatar_url: 'settings.operator.avatar_url',
    }),
  }
};
</script>

const state = {
  notifications: [],
  closedNotificationNames: [],
};

const getters = {
  notification: (state) => state.notifications[0] || null,
};

const actions = {
  // Read the docu: https://github.com/knowhereto/moin-hub/wiki/Notifications
  // Don't forget to update the docu if you change something.
  
  async addNotification({ commit, state }, notification) {
    if (state.closedNotificationNames.includes(notification.name)) return;

    if (notification.action) {
      // build wrapper function for action
      const actionWrapper = (action) => {
        return () => {
          commit('removeNotification', notification.name);
          action();
        };
      };
      notification.action = actionWrapper(notification.action);
    } else if (!notification.permanent) {
      const timeout = notification.timeout || 3000;
      setTimeout(() => {
        commit('removeNotification', notification.name);
      }, timeout);
    }

    if (!state.notifications.find((i) => i.name === notification.name)) {
      commit('setNotification', notification);
    }
  },
};

const mutations = {
  setNotification(state, notification) {
    let index = state.notifications.length;
    // Sort in front of permanent notification
    if (
      !notification.permanent &&
      state.notifications.find((n) => n.permanent)
    ) {
      index = state.notifications.findIndex((n) => n.permanent) - 1;
    }
    state.notifications.splice(index, 0, notification);
  },
  removeNotification(state, name) {
    const n = state.notifications.find((item) => item.name === name);
    if (n?.stayDismissed) {
      state.closedNotificationNames.push(name);
    }
    const nIndex = state.notifications.findIndex((item) => item.name === name);
    if (nIndex >= 0) {
      state.notifications.splice(nIndex, 1);
    }
  },
  clearNotifications(state) {
    state.notifications = [];
  },
  clearRouteNotifications(state, { routeName, routeFullPath }) {
    state.notifications = state.notifications.filter((notification) => {
      if (notification.routeName && notification.routeName !== routeName) {
        return false;
      }
      if (
        notification.routeFullPath &&
        notification.routeFullPath !== routeFullPath
      ) {
        return false;
      }
      return true;
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

import PasswordValidator from "password-validator";
import _ from "lodash";

// Create a password schema
const schema = new PasswordValidator();

// Get the password complexity rules from the environment variable
const passwordRules = JSON.parse(process.env.VUE_APP_PASSWORD_COMPLEXITY_RULES || '{"minLength": 8, "minUppercase": 1, "minLowercase": 1, "minDigits": 1, "minSymbols": 0}');

// Set password complexity rules or use defaults
schema
  .is().min(passwordRules.minLength || 8); // Minimum length of 8 characters or default

if (passwordRules.minUppercase !== 0 && passwordRules.minUppercase !== false) { 
  // Require uppercase letter unless explicitly set to 0 or false
  schema.has().uppercase(_.get(passwordRules, 'minUppercase', 1)); 
}

if (passwordRules.minLowercase !== 0 && passwordRules.minLowercase !== false) {
  // Require lowercase letter unless explicitly set to 0 or false
  schema.has().lowercase(_.get(passwordRules, 'minLowercase', 1)); 
}

if (passwordRules.minDigits !== 0 && passwordRules.minDigits !== false) {
  // Require at least one digit unless explicitly set to 0 or false
  schema.has().digits(_.get(passwordRules, 'minDigits', 1)); 
}

if (passwordRules.minSymbols !== 0 && passwordRules.minSymbols !== false) {
  // Require at least one symbol unless explicitly set to 0 or false
  schema.has().symbols(_.get(passwordRules, 'minSymbols', 1)); 
}

// Validate a password
export default function validatePasswordComplexity(password) {
    return schema.validate(password, { list: true});
};
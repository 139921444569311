<template>
  <div>
    <v-select
      :items="bots"
      item-text="name"
      :value="currentBot"
      return-object
      @change="changeBot"
      dense
      solo
      class="moin-cursor-pointer full-width"
      hide-details
      prepend-inner-icon="mdi-robot-outline"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'HubBotSelect',
  components: {},
  computed: {
    ...mapGetters('bots', [
      // create properties from auth store
      'bots',
      'currentBot',
    ]),
  },
  methods: {
    changeBot(bot) {
      this.$store.dispatch('bots/changeBot', {
        uniqueBotId: bot.uniqueBotId,
        router: this.$router,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
:deep(.theme--dark.v-text-field--solo > .v-input__control > .v-input__slot) {
  background: #5e7aaa !important;
  color: white;
}

:deep(.theme--dark.v-text-field--solo > .v-input__control > .v-input__slot) {
  box-shadow: none !important;
}

:deep(.v-text-field input) {
  display: none !important;
}
</style>

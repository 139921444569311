// 
// e.g. :footer-props="footerPropsItemsPerPage"
export const footerPropsItemsPerPage = {
  'items-per-page-options': [10, 20, 50, 100, -1],
};

// e.g. :items-per-page="initialItemsPerPage"
export const initialItemsPerPage = 20;

export default {
  footerPropsItemsPerPage,
  initialItemsPerPage,
};

import { getField, updateField } from 'vuex-map-fields';
import SettingService from '../../services/settings.js';
import deepEqual from 'fast-deep-equal/es6';

const state = {
  isLoading: false,
  isSaving: false,
  hash: '',
  settings: {},
  appeareance: {
    themetype: 'regular',
    themecolors: {
      primary: {
        name: 'primary',
        color: '#11243c',
      },
      secondary: {
        name: 'secondary',
        color: '#02c7b2',
      },
    },
    button: {
      color: 'primary',
      contrast: 'light',
    },
    widgetheader: {
      color: 'secondary',
      contrast: 'light',
    },
    usermessage: {
      color: 'primary',
      contrast: 'light',
    },
    alignmentOrigin: 'bottom right',
  },
};

const getters = {
  isLoading(state) {
    return state.isLoading;
  },
  isSaving(state) {
    return state.isSaving;
  },
  getThemeColors(state) {
    return state.appeareance.themecolors;
  },
  getThemeColorcodeByType: (state) => (type) =>
    state.appeareance.themecolors[type].color,
  gotConfig(state) {
    return state.settings;
  },
  getVersion(state) {
    return state.settings.version;
  },
  getField,
  getDirty(state) {
    try {
      const hashJSON = JSON.parse(state.hash);
      const settingsJSON = {
        appeareance: state.appeareance,
        settings: state.settings,
      };
      return !deepEqual(hashJSON, settingsJSON);
    } catch (e) {
      return false;
    }
  },
  appeareance(state) {
    return state.appeareance;
  },
};

const actions = {
  async fetchChannelConfig({ commit }, payload) {
    commit('setLoading', true);
    const settings = await SettingService.getChannelConfig(
      payload.botId,
      payload.channelId
    );
    commit('setChannelConfig', settings);
    commit('setLoading', false);
    return settings;
  },
  async saveChannelConfig({ commit, state }, payload) {
    const config = {
      appeareance: state.appeareance,
      settings: state.settings,
    };

    state.isSaving = true;

    const savedSettings = await SettingService.saveChannelConfig(
      payload.botId,
      payload.channelId,
      config
    );
    commit('setChannelConfig', savedSettings);
    state.isSaving = false;
    return savedSettings;
  },
  async changeChannelState(_, payload) {
    const savedState = await SettingService.saveChannelState(
      payload.botId,
      payload.channelId,
      payload.state
    );
    if (savedState.error) throw new Error(savedState.error);
    return savedState;
  },
};

const mutations = {
  setLoading(state, payload) {
    state.isLoading = payload;
  },
  updateField,
  setChannelConfig(state, payload) {
    // Setting config fetch from api
    state.appeareance = payload.appeareance || null;
    state.settings = payload.settings || {};

    state.hash = JSON.stringify({
      appeareance: state.appeareance,
      settings: state.settings,
    });
  },
  setSettingStartScreen(state, payload) {
    // Delete it
    if (!payload && state.settings) {
      state.settings.startscreen = null;
      state.settings = { ...state.settings };
      return;
    }
    // Set it
    state.settings.startscreen = { ...payload };
    state.settings = { ...state.settings };
  },
  setAppearanceValue(state, { key, value }) {
    state.appeareance[key] = value;

    state.appeareance = { ...state.appeareance };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

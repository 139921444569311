<template>
  <div
    class="moin-checkbox d-flex items-center justify-center"
    :class="[value && 'moin-checkbox--active', error && 'moin-checkbox--error']"
    @click="$emit('onClick')"
  >
    <v-icon v-if="value" color="secondary">mdi-check-bold</v-icon>
  </div>
</template>
<script>
export default {
  name: 'Checkbox',
  components: {},
  props: {
    label: String,
    value: Boolean,
    error: Boolean,
  },
};
</script>
<style lang="scss" scoped>
.moin-checkbox {
  height: $spacer * 6;
  width: $spacer * 6;
  border-radius: $spacer;
  border: 2px solid #9e9e9e;
  cursor: pointer;
}

.moin-checkbox--active {
  border-color: var(--v-secondary-base);
}

.moin-checkbox--error {
  border-color: var(--v-error-base) !important;
}
</style>

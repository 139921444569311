<script>
import DropDownActuator from '@/components/common/dropdown/DropDownActuator.vue';
import DropDownMenu from '@/components/common/dropdown/DropDownMenu.vue';

export default {
  name: 'DropDown',
  components: { DropDownMenu, DropDownActuator },
  props: {
    /** @type {DropDownItem[]} */
    "items": {
      type: Array,
      default: () => [],
    },
    // Thin separating line between items
    "separator": {
      type: Boolean,
      default: false,
    },
    /**
     * Width of the dropdown. By default, it will only take necessary width.
     * A CSS unit can be passed, or a number that will be treated as pixels.
     */
    "width": {
      type: [String, Number],
      default: 'max-content',
    },
    // HTML classes to put on the dialog element
    "classDialog": {
      type: String,
      default: '',
    },
    // Make dropdown appear next to button rather than below it
    "inlined": {
      type: Boolean,
      default: false,
    },
    /**
     * Internal use:
     * - allows nested dropdowns to inherit parent options
     * - tells each dropdown item what level of nesting it is in
     * - carries preferences for buttons/dropdown down to them and any children
     * ... and more
     */
    "options": {
      type: Object,
      default: () => ({}),
    },
    // Dropdown is disabled: you cannot open it
    "disabled": {
      type: Boolean,
      default: false,
    },
    // Presets of styles for dropdown
    "type": {
      type: String,
      enum: ["default", "companion", "admin"],
      default: "default",
    },
    // Color for icons in the menu items
    "colorIcon": {
      type: String,
    },
    // Color for the menu item buttons
    "colorBtn": {
      type: String,
    },
    /**
     * Dropdown closes itself when an item is clicked.
     * If you have nested menus, you should probably handle closing
     * manually using the arguments from the `onClick` callback.
     */
    "autoClose": {
      type: Boolean,
      default: () => false,
    }
  },
  data() {
    const parentDropdownOptions = {
      level: this.$options.level === undefined ? 0 : this.$options.level + 1,
      separator: this.$props.separator,
      classDialog: this.$props.classDialog,
      width: this.$props.width,
      type: this.$props.type,
      colorIcon: this.$props.colorIcon,
      colorBtn: this.$props.type === "admin" && !this.$props.colorBtn
        ? "admin"
        : this.$props.colorBtn,
      autoClose: this.$props.autoClose,
      /**
       * Allows us to pass open/close triggers in callbacks of buttons.
       * With this you can create your own auto-closing in your button callbacks.
       */
      toggle: this.options.toggle ? this.options.toggle : this.toggle,
      setOpen: this.options.toggle ? this.options.toggle : this.setOpen,

      // Now merge in passed props and overwrite where necessary
      ...this.$props.options,

      // Nested sub-menus should open inlined, always
      inlined: this.$props.inlined ?? true,
    };

    return {
      opts: parentDropdownOptions,
      "isOpen": false,
      "clickOutsideHandler": {
        handler: () => {
          this.setOpen(false);
        },
        closeConditional: () => this.isOpen,
      },
    };
  },
  methods: {
    /**
     * Cycle between open and closed
     */
    toggle() {
      if (this.disabled) return;

      this.isOpen = !this.isOpen;
      if (this.isOpen) setImmediate(this.keepInsideWindow);
    },
    /**
     * Force the dropdown to open or close
     * @param {boolean} isOpen
     */
    setOpen(isOpen) {
      if (this.disabled) return;

      this.isOpen = !!isOpen;
      if (this.isOpen) setImmediate(this.keepInsideWindow);
    },
    /**
     * A function that ensures that the dropdown stays inside the browser visible area.
     * If not, it will align itself to the other side of the parent element.
     * This applies to X and Y axis.
     */
    keepInsideWindow() {
      if (!this.isOpen) return;
      this.moveIntoPosition();

      let dialogPosition = this.$refs.dialog.getBoundingClientRect();
      const overflowsRight = dialogPosition.right >= document.body.clientWidth;
      const overflowsBottom = dialogPosition.bottom >= document.body.clientHeight;
      if (!this.isOpen || (!overflowsRight && !overflowsBottom)) return;

      // Account for bottom overflow: move dialog up from bottom
      if (overflowsBottom) {
        const bottomMargin = 10;
        const delta =
          dialogPosition.bottom - document.body.clientHeight + bottomMargin;
        this.$refs.dialog.style.top = `${
          parseInt(this.$refs.dialog.style.top) - delta
        }px`;
      }

      if (overflowsRight) {
        const currentLeft = parseInt(this.$refs.dialog.style.left);
        const dialogGrandParent = this.$refs.dialog
          .parentElement
          .parentElement
          .getBoundingClientRect();

        // Left offset calculation is different if the dropdown is inlined or not
        if (this.opts.inlined) {
          const left = dialogGrandParent.width + dialogPosition.width;
          return (this.$refs.dialog.style.left = `${currentLeft - left}px`);
        }

        const parentWidth = dialogGrandParent.width;
        this.$refs.dialog.style.left = `${currentLeft - dialogPosition.width + parentWidth}px`;
      }
    },
    /**
     * Since the dropdown needs to be position fixed to bypass parent `overflow: hidden`,
     * we need to move the dropdown into position, below the button that opened it.
     */
    moveIntoPosition() {
      if (!this.isOpen) return;

      const pos = this.$refs.wrapper.getBoundingClientRect();
      const dialog = this.$refs.dialog;
      dialog.style.left = `${pos.left}px`;
      dialog.style.top = this.opts.inlined ? `${pos.top}px` : `${pos.bottom}px`;
    },
  },
  mounted() {
    window.addEventListener('resize', this.keepInsideWindow);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.keepInsideWindow);
  },
};
</script>

<template>
  <div
    class="DropDown"
    :class="{
      'd-flex': opts.inlined,
    }"
  >
    <slot name="actuator" :toggle="toggle" :setOpen="setOpen" :disabled="disabled">
      <DropDownActuator @click="toggle" :disabled="disabled" />
    </slot>

    <div class="dialog-wrapper" ref="wrapper">
      <transition name="dd-toggle">
        <!-- `v-if` is only needed for transition animation -->
        <dialog
          v-if="isOpen"
          v-click-outside="clickOutsideHandler"
          ref="dialog"
          :open="isOpen ? 'true' : undefined"
          class="DropDownDialog elevation-4"
          :class="{
              [`${opts.classDialog}`]: true,
              [`dropdown-${opts.type}`]: true,
            }"
          :style="{
              width:
                opts.width && !isNaN(Number(opts.width))
                  ? `${opts.width}px`
                  : opts.width,
            }"
        >
          <slot name="content" :items="items" :options="opts" :toggle="toggle" :setOpen="setOpen" :disabled="disabled">
            <DropDownMenu
              :items="items"
              :separator="opts.separator"
              :options="opts"
            />
          </slot>
        </dialog>
      </transition>
    </div>
  </div>
</template>

<style lang="scss">
// Styles are not scoped to decrease specificy

.DropDown {
  width: max-content;
}
.dialog-wrapper {
  position: relative;
}
.DropDownDialog {
  border-radius: 4px;
  z-index: 10;
  position: fixed;
}
/**
 * Dropdown preset styles
 */
.dropdown-default {
  border: solid thin var(--v-grey-darken1);
}
.dropdown-admin {
  border: solid thin var(--v-admin-base);
}
.dropdown-companion {
  border: solid thin var(--moin-color-accent-5);
}
.dd-toggle-enter-active,
.dd-toggle-leave-active {
  transition: opacity 0.2s;
}
.dd-toggle-enter, .dd-toggle-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

</style>
<template>
  <div>
    <div class="mb-10">
      <DemoStepper />
    </div>
    <div class="moin-fade-in">
      <div v-if="celebrate" style="font-size: 40px">🎉</div>
      <h1
        class="text-h5 moinblue--text mb-2"
        v-html="$t(`demo.${step}.title`)"
      ></h1>
      <div class="text-body-1" v-html="$t(`demo.${step}.body`)"></div>
      <div class="pt-6">
        <slot />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import DemoStepper from '@/components/demo/components/DemoStepper.vue';

export default {
  name: 'DemoStepText',
  components: { DemoStepper },
  props: {
    celebrate: Boolean,
  },
  computed: {
    ...mapGetters('demo', ['step', 'loading']),
  },
};
</script>

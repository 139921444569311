<template>
  <div>
    <v-dialog
      :value="dialogVisible"
      :max-width="maxWidth"
      :persistent="modal"
      @input="changeValue"
      scrollable
    >
      <v-card>
        <v-progress-linear
          v-if="closing || loading"
          indeterminate
          absolute
          color="secondary darken-2"
        />
        <v-card-title
          v-if="title || !modal"
          class="text-h5 pt-6"
          style="word-break: break-word"
        >
          {{ title }}
          <v-spacer v-if="!modal" />
          <BtnSquare v-if="!modal" @click="close" icon="close" />
        </v-card-title>
        <v-divider />
        <v-card-text class="text--primary pt-4">
          <slot />
        </v-card-text>

        <v-divider />
        <v-card-actions>
          <v-container class="pa-3" fluid>
            <v-row :class="[btnsRight && 'hub-dialog-right-row']">
              <v-col :class="[btnsRight && 'hub-dialog-right-col']">
                <v-btn
                  v-if="!hideSecondary"
                  :disabled="closing"
                  outlined
                  color="primary lighten-2"
                  class="px-4 primary--text"
                  :block="$vuetify.breakpoint.xs"
                  @click="secondaryButton"
                >
                  {{ secondaryLabel }}
                  <v-icon right>{{ secondaryIcon }}</v-icon>
                </v-btn>
              </v-col>
              <v-col
                class="text-right"
                :class="[btnsRight && 'hub-dialog-right-col']"
              >
                <v-btn
                  v-if="!hidePrimary"
                  :disabled="disabled || closing"
                  class="px-4 white--text"
                  :block="$vuetify.breakpoint.xs"
                  depressed
                  :color="primaryColor"
                  @click="primaryButton"
                  data-cy="hub-dialog-primary-btn"
                >
                  {{ primaryLabel }}
                  <v-icon right>{{ primaryIcon }}</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import BtnSquare from '@/components/common/BtnSquare.vue';
/**
 * The standard dialog in moin.ai Hub.
 *
 * @displayName Hub Dialog
 */
export default {
  name: 'HubDialog',
  emits: ['input', 'onPrimary', 'onSecondary'],
  components: {
    BtnSquare,
  },
  props: {
    value: {
      type: Boolean,
    },
    title: {
      type: String,
      default: '',
    },
    /**
     * if modal is true the dialog can only closed by one button.
     */
    modal: {
      type: Boolean,
      default: false,
    },
    secondaryIcon: {
      type: String,
      default: 'close',
    },
    hideSecondary: {
      type: Boolean,
      default: false,
    },
    hidePrimary: {
      type: Boolean,
      default: false,
    },
    hideActions: {
      type: Boolean,
      default: false,
    },
    primaryIcon: {
      type: String,
      default: 'done',
    },
    secondaryLabel: {
      type: String,
      default: function() {
        return this.$t('common.cancel');
      },
    },
    primaryLabel: {
      type: String,
      default: function() {
        return this.$t('common.ok');
      },
    },
    primaryColor: {
      type: String,
      default: "primary lighten-2",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    asyncDialog: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: String,
      default: '500px',
    },
    // Align the buttons to the right (if maxWidth to wide)
    btnsRight: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    }
  },
  data: function() {
    return {
      showDialog: null,
      closing: false,
    };
  },
  computed: {
    dialogVisible() {
      return (this.value && this.showDialog === null) || this.showDialog;
    },
  },
  watch: {
    value() {
      this.showDialog = this.value;
      if (!this.showDialog) {
        this.closing = false;
      }
    },
  },
  methods: {
    primaryButton() {
      if (this.asyncDialog === false) {
        this.showDialog = false;
        this.$emit('input', this.showDialog);
      } else {
        this.closing = true;
      }
      this.$emit('onPrimary');
    },
    secondaryButton() {
      if (this.asyncDialog === false) {
        this.showDialog = false;
        this.$emit('input', this.showDialog);
      } else {
        this.closing = true;
      }
      this.$emit('onSecondary');
    },
    close() {
      this.showDialog = false;
      this.$emit('input', this.showDialog);
    },
    changeValue(value) {
      this.showDialog = value;
      this.$emit('input', value);
    },
  },
};
</script>
<style scoped>
.hub-dialog-right-row {
  justify-content: end;
}

.hub-dialog-right-col {
  flex-grow: 0;
}
</style>

import { StatsService } from '@/services/stats';
import { ProductService } from '@/services/product';
import moment from 'moment';

const state = {
  isLoading: false,
  kpi: {
    days30: 0,
    days30Before: 0,
    days30LastYear: 0,
  },
  kpisYear: [],
  trainingData: [],
};

const getters = {
  isLoading(state) {
    return state.isLoading;
  },
  kpi: (state) => state.kpi,
  kpisYear: (state) => state.kpisYear,
  trainingData: (state) => state.trainingData,
};

const actions = {
  async fetchKpis({ commit }, { botId }) {
    commit('setValue', { key: 'isLoading', value: true });
    const value = {};
    {
      const dateRange = [moment().add(-30, 'days'), moment()];
      const data = await StatsService.getKpi(botId, 'kpi', dateRange);
      value.days30 = data.kpi ? (data.kpi * 100).toFixed(1) : 0;
    }

    {
      const dateRange = [moment().add(-60, 'days'), moment().add(-30, 'days')];
      const data = await StatsService.getKpi(botId, 'kpi', dateRange);
      value.days30Before = data.kpi ? (data.kpi * 100).toFixed(1) : 0;
    }

    {
      const dateRange = [moment().add(-385, 'days'), moment().add(-365, 'days')];
      const data = await StatsService.getKpi(botId, 'kpi', dateRange);
      value.days30LastYear = data.kpi ? (data.kpi * 100).toFixed(1) : 0;
    }

    commit('setValue', { key: 'kpi', value });
    commit('setValue', { key: 'isLoading', value: false });
  },

  async fetchKpisYear({ commit }, { botId }) {
    const dateRanges = [];
    let chartData = [];

    for (let i = 1; i <= 12; i += 1) {
      const start = moment()
        .add(i * -1, 'month')
        .startOf('month');
      const end = moment()
        .add(i * -1, 'month')
        .endOf('month');
      dateRanges.push([start, end]);
    }

    for (let dateRange of dateRanges) {
      const data = await StatsService.getKpi(botId, 'kpi', dateRange);
      chartData.push({
        x: dateRange[0].lang('de').format('MMMM'),
        y: data.kpi ? (data.kpi * 100).toFixed(1) : 0,
      });
    }

    chartData = chartData.reverse();

    commit('setValue', { key: 'kpisYear', value: chartData });
  },
  async fetchTrainingdata({ commit }, { botId }) {
    const dateRange = [moment().add(-30, 'days'), moment().add(1, 'days')];

    let value = await ProductService.getTrainingdataPeriod(botId, dateRange);
    // value = value.filter(item => item.name !== 'NOISE');
    commit('setValue', { key: 'trainingData', value});
  },
};

const mutations = {
  setValue(state, { key, value }) {
    state[key] = value;
  },
  resetState(state) {
    state.kpi = {
      days30: 0,
      days30Before: 0,
      days30LastYear: 0,
    };
    state.kpisYear = [];
    state.trainingData = [];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

<template>
  <div class="moin-fresh" :class="{'action-row': !useSeparator}">
    <div :class="{
      'action-row-content': $slots.line && !useSeparator,
      'py-2': verticalPadding,
    }">
      <slot name="content" />
    </div>
    <div class="action-row-line" v-if="$slots.line && !useSeparator">
      <slot name="line" />
    </div>
    <ActionGapSeparator v-if="useSeparator" />
  </div>
</template>
<script>
import ActionGapSeparator from "@/components/editor/components/ActionGapSeparator.vue";

export default {
  name: 'ActionRow',
	components: {ActionGapSeparator},
	props: {
		"useSeparator": {
			type: Boolean,
			default: false,
		},
    "property": String,
    "verticalPadding": {
      type: Boolean,
      default: () => true,
    }
	}
};
</script>
<style lang="scss" scoped>
$box-line-width: 50px;

.action-row {
  position: relative;
  overflow: hidden;
}

.action-row-line {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: $box-line-width;
}

.action-row-content {
  position: relative;
  padding-left: $box-line-width;
}
</style>

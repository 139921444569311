<template>
  <div>
    <h2 class="headline primary--text mb-3">
      <v-icon left class="mr-2" color="primary lighten-3">
        mdi-chart-timeline-variant-shimmer
      </v-icon>
      <span>{{ $t('forms.analysis.title') }}</span>
    </h2>

    <p class="primary--text">
      {{ $t('forms.analysis.subTitle') }}
      <span v-if="noDataAndTracking">
        {{ $t('forms.analysis.nodataandtracking') }}
      </span>
    </p>
    <div>
      <div class="d-flex">
        <div>
          <v-icon
            :color="
              !noDataAndTracking ? 'secondary darken-2' : 'grey lighten-2'
            "
            class="mr-2 mt-n1"
          >
            date_range
          </v-icon>
          <span class="text-body-1">
            {{ dateRangeFormat }}
          </span>
        </div>
        <v-menu
          ref="menu"
          v-model="isDateMenuOpen"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              small
              text
              class="ml-2 text-body-1"
              color="secondary darken-3"
              v-on="on"
              :disabled="noDataAndTracking"
            >
              {{ $t('common.change') }}
            </v-btn>
          </template>
          <v-date-picker v-model="dateRange" no-title range color="secondary">
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="isDateMenuOpen = false">
              {{ $t('common.cancel') }}
            </v-btn>
            <v-btn text color="primary" @click="changeDateRange">
              {{ $t('common.ok') }}
            </v-btn>
          </v-date-picker>
        </v-menu>
      </div>
    </div>

    <div class="mt-8 mb-5 pb-10">
      <h2 class="text-h6 primary--text mb-0">
        {{ $t('forms.analysis.resultTitle') }}
      </h2>
      <div
        v-if="!noData"
        class="text-body-2 primary--text text--lighten-3 mt-n2"
      >
        {{
          $t('forms.analysis.result', {
            finished: String(resultFinished),
          })
        }}
      </div>

      <BarPercentage
        large
        class="mt-4"
        :buffer="resultPercentage"
        :value="resultPercentage"
        :no-data="noData"
        :tooltip="
          $t('forms.analysis.tooltipResult', {
            started: String(resultStarted),
            finished: String(resultFinished),
          })
        "
      />
      <div class="mt-6" v-if="isAdmin && resultFinished > 0">
        <v-tooltip bottom color="primary lighten-3">
          <template
            bottom
            v-slot:activator="{ on: onTooltip, attrs: attrsTooltip }"
          >
            <v-btn
              outlined
              x-small
              color="admin"
              @click="openLeads(finishedUsers)"
              v-if="resultFinished > 0"
              v-on="onTooltip"
              v-bind="attrsTooltip"
            >
              Leads
              <v-icon right>mdi-list-box-outline</v-icon>
            </v-btn>
          </template>
          <span>{{ 'Resultate anzeigen' }}</span>
        </v-tooltip>
      </div>
    </div>

    <h2 class="text-h6 primary--text mt-0 mb-0">
      {{ $t('forms.analysis.resultStepTitle') }}
    </h2>

    <!-- <v-divider class="mt-10 grey" /> -->
    <BotFormsInsightsItem
      v-for="(formStep, index) in insightSteps"
      :formStep="formStep"
      :intent="form.intent"
      :channelId="channelId"
      :key="index"
      :no-data="noData"
      @openLeads="openLeads"
    />

    <HubDialog v-model="leadExportDialog" maxWidth="100%" btnsRight>
      <h1 class="text-h5 mb-4">Leads {{ dateRangeFormat }}</h1>
      <v-data-table :headers="leadHeaders" :items="leads" dense>
        <template v-slot:[`item.createdAt`]="{ item }">
          <span>{{ formatCreatedAt(item.createdAt, $t('common.dateTimeFormat')) }}</span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <BtnSquare
            icon="open_in_new"
            :to="`/chats/messaging/${item.uniqueUserId}?jumpto='${item.uuid}'`"
            target="_blank"
          ></BtnSquare>
        </template>
      </v-data-table>
      <div>
        <v-btn
          outlined
          color="primary lighten-3"
          :disabled="!leads.length"
          @click="downloadLeadsAsCsv"
        >
          CSV Download
          <v-icon right>mdi-file-delimited</v-icon>
        </v-btn>
      </div>
    </HubDialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BotFormsInsightsItem from '@/components/botForms/BotFormsInsightsItem.vue';
import HubDialog from '@/components/hub/HubDialog.vue';
import BotFormsService from '@/services/botForms';
import BarPercentage from '@/components/common/BarPercentage.vue';
import { parseSteps } from '@/utils/botForms';
import { calcPercentage } from '@/utils/calc';
import moment from 'moment';
import NumbersUtil from '@/utils/numbers';
import BtnSquare from '../common/BtnSquare.vue';
import { formatDateTime } from '@/utils/formatDateTime';

export default {
  name: 'BotFormsInsights',
  components: { BotFormsInsightsItem, BarPercentage, HubDialog, BtnSquare },
  props: {
    form: {
      type: Object,
      required: true,
    },
    channelId: {
      type: String,
    },
  },
  data() {
    return {
      insightSteps: [],
      resultPercentage: 0,
      resultStarted: 0,
      resultFinished: 0,
      finishedUsers: [],
      dateRange: [
        new moment().add(-30, 'days').format('YYYY-MM-DD'),
        new moment().format('YYYY-MM-DD'),
      ],
      dateRangeFormat: null,
      isDateMenuOpen: false,
      leadExportDialog: false,
      leads: [],
      leadHeaders: [],
    };
  },
  computed: {
    ...mapGetters('bots', ['currentBotId']),
    ...mapGetters('formsEditor', ['formSteps', 'isEventTrackingAvailable']),
    ...mapGetters('glimpseContent', ['getContent']),
    ...mapGetters('auth', ['isAdmin']),
    noDataAndTracking() {
      return this.noData && !this.isEventTrackingAvailable;
    },
    noData() {
      return this.resultStarted === 0;
    },
  },
  created() {
    this.changeDateRangeFormat();
  },
  mounted() {
    this.init();
  },
  watch: {
    formSteps() {
      this.loadInsights();
    },
  },
  methods: {
    init() {
      if (this.formSteps?.length) {
        this.loadInsights(this.formSteps);
      }
    },
    formatCreatedAt(date, format) {
      return formatDateTime(date, format);
    },
    async openLeads(users) {
      if (!users || users.length === 0) {
        return;
      }
      // = this.formSteps.map((s) => {box: s.box, text: s.displayName});
      const contexts = this.formSteps.map((s) => {
        let content = this.getContent(
          this.currentBotId,
          this.form.intent,
          s.box
        );
        return { value: content?.context || s.box, text: s.displayName };
      });

      const res = await BotFormsService.getLeads({
        botId: this.currentBotId,
        steps: contexts.map((c) => c.value),
        users: users.map((u) => u.uuid),
      });

      this.leadExportDialog = true;
      this.leads = res;

      const expectedHeaders = contexts.map((c) => c.value);
      // create a map with expected headers
      const headerMap = {};
      expectedHeaders.forEach((h) => {
        headerMap[h] = false;
      });

      // check if all headers are present
      this.leads.forEach((l) => {
        Object.keys(l).forEach((k) => {
          if (l[k] && l[k] !== null) {
            headerMap[k] = true;
          }
        });
      });

      this.leadHeaders = contexts
        .filter((c) => headerMap[c.value])
        .map((c) => {
          const val = {
            text: c.text,
            value: c.value,
            width: '120px',
            cellClass: ['text-truncate', 'overflow-x-hidden', 'cell-max-width'],
          };
          return val;
        });

      this.leadHeaders.push({ text: 'Datum', value: 'createdAt' });
      this.leadHeaders.push({ text: '', value: 'actions', sortable: false });
    },
    downloadLeadsAsCsv() {
      const fieldsToExclude = [
        'uuid',
        'uniqueUserId',
      ];

      let csv = '';

      const existingFields = Object.keys(this.leads[0]);
      existingFields.forEach(field => {
        if (field && field.startsWith('box_form')) {
          fieldsToExclude.push(field);
        }
      });
      // headline for csv from json keys
      existingFields
      .filter((field) => !fieldsToExclude.includes(field))
      .forEach((field) => {
        const name = this.leadHeaders.find((h) => h.value === field)?.text || field;
        csv += name + ',';
      });
      // Delete last empty
      csv = csv.slice(0, -1);

      csv += '\n';
      // loop through json data
      this.leads.forEach((row) => {
        existingFields
        .filter((field) => !fieldsToExclude.includes(field))
        .forEach((field) => {
          if (field === 'createdAt') {
            csv += `${ this.formatCreatedAt(row[field], this.$t('common.dateTimeFormat')) },`;
            return;
          }
          // escape " in row[field]
          const value =row[field] === null ? '' : row[field].replace(/"/g, "'");
          csv += `"${value}",`;
        });
        // Delete last empty
        csv = csv.slice(0, -1);
        csv += '\n'; 
      });

      const anchor = document.createElement('a');
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
      anchor.target = '_blank';
      let name = `moinAI-leads-${this.dateRange[0]}-${this.dateRange[1]}`;

      anchor.download = `${name}.csv`;
      anchor.click();
    },
    async loadInsights() {
      if (this.dateRange.length < 2) {
        return;
      }
      let startDate = this.dateRange[0];
      let endDate = this.dateRange[1];

      const steps = this.formSteps.map((s) => s.box);
      const result = await BotFormsService.getFormInsights({
        botId: this.currentBotId,
        channelId: this.channelId,
        startDate,
        endDate,
        intent: this.form.intent,
        steps,
      });
      this.resultStarted = result.started;
      this.resultFinished = result.finished;
      this.finishedUsers = result.finishedUsers;
      this.resultPercentage = calcPercentage(result.finished, result.started);
      this.insightSteps = parseSteps(result, this.formSteps);
    },
    changeDateRange() {
      // check if startDate before endDate and swap if not
      if (this.dateRange.length === 1) {
        this.dateRange = [this.dateRange[0], this.dateRange[0]];
      }

      if (this.dateRange[0] > this.dateRange[1]) {
        this.dateRange = [this.dateRange[1], this.dateRange[0]];
      }

      this.changeDateRangeFormat();
      this.loadInsights();
      this.isDateMenuOpen = false;
    },
    changeDateRangeFormat() {
      if (this.dateRange[0] === this.dateRange[1]) {
        return (this.dateRangeFormat = NumbersUtil.formatDate(
          this,
          this.dateRange[0]
        ));
      }

      const isSmall = this.$vuetify.breakpoint.lgAndDown;

      this.dateRangeFormat =
        NumbersUtil.formatDate(this, this.dateRange[0], isSmall) +
        ' - ' +
        NumbersUtil.formatDate(this, this.dateRange[1], isSmall);
    },
  },
};
</script>

<style lang="scss">
td.cell-max-width {
  max-width: calc(100vw / 3);
}
</style>

<template>
  <v-text-field
    v-model="value.color"
    hide-details
    class="ma-0 pa-0"
    :label="label"
    outlined
  >
    <template v-slot:append>
      <v-menu top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
        <template v-slot:activator="{ on }">
          <div :style="swatchStyle(value.color)" v-on="on" />
        </template>
        <v-card>
          <v-card-text class="pa-0">
            <v-color-picker
              v-model="colorPicked"
              :modes="['rgb', 'hex']"
              @input="propagateColorChange"
              flat
            />
          </v-card-text>
        </v-card>
      </v-menu>
    </template>
  </v-text-field>
</template>

<script>
/* import debounce from 'lodash.debounce'; */

export default {
  props: ['value', 'label'],
  data() {
    return {
      colorPicked: this.value.color,
      menus: {}
    };
  },
  methods: {
    swatchStyle(color) {
      return {
        backgroundColor: color,
        cursor: 'pointer',
        height: '30px',
        width: '30px',
        border: '1px solid #666',
        borderRadius: '4px',
        transition: 'border-radius 200ms ease-in-out'
      };
    },
    propagateColorChange() {
      this.value.color = this.colorPicked;
      this.$emit('input', this.value);
    }
  },
}
</script>

<style>

</style>
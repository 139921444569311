<template>
  <v-chip-group
    multiple
    column
    @change="selectOption"
  >
    <v-tooltip v-for="(item, index) in options" :key="index" bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-chip
          v-bind="attrs"
          v-on="on"
          class="pl-4"
          :class="[
            item.isActive
              ? 'primary lighten-2 white--text'
              : 'white primary--text text-lighten-2',
              !showText ? 'icon-only' : '',
          ]"
          :style="{
            border: `1px solid ${
              !item.isActive
                ? 'rgba(189, 189, 189, 1)'
                : 'var(--v-primary-lighten3)'
            } !important`,
          }"
          :disabled="item.isDisabled || (disableNoAmount && !item.amount)"
        >
          <v-icon
            small
            :class="{ 'mr-2': showText }"
            :color="
              item.isActive
                ? 'white'
                : item.iconColor
                ? item.iconColor
                : 'primary lighten-3'
            "
          >
            {{ item.icon }}
          </v-icon>
          <span v-if="showText">{{ item.name }}</span>
          <span v-if="item.amount" class="pl-2" style="opacity: 0.6;">{{ item.amount }}</span>
        </v-chip>
      </template>
      <span v-if="item.isActive">
        {{ $t('common.filter.off') }}
      </span>
      <span v-else>{{ $t('common.filter.on') }}</span>
    </v-tooltip>
  </v-chip-group>
</template>
<script>
// @ options
// @ option {value: String, icon: String, iconColor: String, name: String, amount: Number}
export default {
  name: 'ChipFilter',
  props: {
    options: {
      type: Array,
      required: true,
    },
    disableNoAmount: {
      type: Boolean,
      default: false,
    },
    showText: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    selectOption(optionValue) {
      this.$emit('onChange', optionValue);
    },
  },
};
</script>

<style scoped>
.icon-only {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
  min-width: 32px; /* oder eine andere geeignete Größe */
  height: 32px; /* oder eine andere geeignete Größe */
}

.icon-only .v-icon {
  margin: 0 !important;
}
</style>
import ProductService from '@/services/product';
import { getField, updateField } from 'vuex-map-fields';
import { IntentService } from '../../services/intents';

// status of creation process
export const statusTypes = {
  initial: 'initial',
  creatingWithTemplate: 'creatingWithTemplate',
  creatingWithoutTemplate: 'creatingWithoutTemplate',
  created: 'created',
};

// Clone this for initial and cleanup
const cleanIntent = {
  intent: '',
  displayName: '',
  description: '',
  scenario: '',
  samples: ['', '', '', '', ''],
  template: ''
};

const state = {
  intentNewOpen: false,
  status: statusTypes.initial,
  newIntent: { ...cleanIntent },
  searchRequest: null,
  searchResults: null,
  activeStep: 1,
  templatesExpansion: [], // Vuetify logic `[0]` = open / [] = close (0 is index of 1st panel)
  createdIntent: '', // Intent string of created intent without template,
};

const getters = {
  getField,
  intentNewOpen: (state) => state.intentNewOpen,
  newIntent: (state) => state.newIntent,
  status: (state) => state.status,
  searchRequest: (state) => state.searchRequest,
  searchResults: (state) => state.searchResults,
  isDisabled: (state) => state.isDisabled,
  samples: (state) => state.newIntent.samples,
  activeStep: (state) => state.activeStep,
  templatesExpansion: (state) => state.templatesExpansion,
  createdIntent: (state) => state.createdIntent,
};

const actions = {
  async createNewIntent({ commit, state }, { botId }) {
    const result = await ProductService.addNewIntent({
      botId,
      ...state.newIntent,
    });

    if (result.error || !result.intent) {
      return false;
    }
    commit('intents/addIntent', result.intent, { root: true });
    commit('setCreatedIntent', result.intent.intent);
    commit('setStatus', statusTypes.created);
    return true;
  },
  async validateQuery({ commit, state }, { botId, query }) {
    if (query === '') return { valid: null };
    const result = await IntentService.validateQuery(botId, query, state.newIntent.displayName, state.newIntent.scenario);
    return { valid: result.valid, label: result.label};
  },
  async searchStandard({ commit, rootGetters }, { botId, text, language }) {
    const intentLibrary = rootGetters['intents/getIntentLibraryMap'];

    let similarIntents = {};
    let searchResults = {};

    try {
      similarIntents = await IntentService.similarIntents(botId, text, language);
      searchResults = {
        own: similarIntents.topics.map((topic) => ({ label: topic.intent, confidence: 1 })),
        all: similarIntents.templateTopics.map((topic) => ({ label: topic.intent, confidence: 1 })),
      };

    } catch (e) {
      searchResults = await IntentService.searchIntent(botId, text);
    }

    // {own: Array, standard: Array, smalltalk: Array, all: Array}
    for (const [key, value] of Object.entries(searchResults)) {
      //if (key !== 'own') {
      searchResults[key] = value.filter((intent) => {
        if (!intentLibrary[intent.label]) return false;
        if (intentLibrary[intent.label].standard && key === 'own') return false;
        if (!intentLibrary[intent.label].standard && key !== 'own')
          return false;
        return true;
      });
      //}
    }

    searchResults.newTopic = similarIntents.newTopic;
    commit('setSearchRequest', { text, results: searchResults });
  },
};

const mutations = {
  updateField,
  setIntentNewOpen(state, value) {
    state.intentNewOpen = value;
  },
  updateSamples(state, samples) {
    state.newIntent.samples = samples;
  },
  // Use statusTypes export as kind of enum
  setStatus(state, value) {
    state.status = value;
  },
  setIntentWithTemplate(
    state,
    { intent, displayName, description = '', scenario = '', samples = [''], template }
  ) {
    state.newIntent = {
      intent,
      displayName,
      description,
      samples,
      scenario,
      template
    };
    state.status = statusTypes.creatingWithTemplate;
  },
  setIntentWithoutTemplate(state) {
    state.newIntent = { ...cleanIntent };
    state.status = statusTypes.creatingWithoutTemplate;
  },
  addSample(state) {
    state.newIntent.samples.push('');
  },
  deleteSample(state, index) {
    state.newIntent.samples.splice(index, 1);
  },
  setSearchRequest(state, { text, results }) {
    state.searchRequest = text;
    state.searchResults = results;
  },
  setSearchResults(state, { results }) {
    state.searchResults = results;
  },
  clearSearch(state) {
    state.newIntent = { ...cleanIntent };
    state.activeStep = 1;
    state.searchRequest = null;
    state.searchResults = null;
    state.status = statusTypes.initial;
  },
  setActiveStep(state, value) {
    state.activeStep = value;
  },
  setTemplatesExpansion(state, value) {
    state.templatesExpansion = value;
  },
  setCreatedIntent(state, value) {
    state.createdIntent = value;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};

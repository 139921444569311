var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"DropDown",class:{
      'd-flex': _vm.opts.inlined,
    }},[_vm._t("actuator",function(){return [_c('DropDownActuator',{attrs:{"disabled":_vm.disabled},on:{"click":_vm.toggle}})]},{"toggle":_vm.toggle,"setOpen":_vm.setOpen,"disabled":_vm.disabled}),_c('div',{ref:"wrapper",staticClass:"dialog-wrapper"},[_c('transition',{attrs:{"name":"dd-toggle"}},[(_vm.isOpen)?_c('dialog',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.clickOutsideHandler),expression:"clickOutsideHandler"}],ref:"dialog",staticClass:"DropDownDialog elevation-4",class:{
              [`${_vm.opts.classDialog}`]: true,
              [`dropdown-${_vm.opts.type}`]: true,
            },style:({
              width:
                _vm.opts.width && !isNaN(Number(_vm.opts.width))
                  ? `${_vm.opts.width}px`
                  : _vm.opts.width,
            }),attrs:{"open":_vm.isOpen ? 'true' : undefined}},[_vm._t("content",function(){return [_c('DropDownMenu',{attrs:{"items":_vm.items,"separator":_vm.opts.separator,"options":_vm.opts}})]},{"items":_vm.items,"options":_vm.opts,"toggle":_vm.toggle,"setOpen":_vm.setOpen,"disabled":_vm.disabled})],2):_vm._e()])],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
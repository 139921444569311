import moment from 'moment';

const LicenceUtil = {
  currentLicence(licences = [], date = moment()) {
    let current = false;
    licences.forEach((l) => {
      if (date.isSameOrAfter(l.start) && date.isBefore(l.end)) {
        current = l;
      }
    });
    return current;
  },
};

export default LicenceUtil;

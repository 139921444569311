<template>
  <v-app>
    <HubBg>
      <HubPanelLogo :loading="false">
        <v-form @submit.prevent>
          <v-container>
            <v-row>
              <v-col cols="12">
                <h1>{{$t('maintenance.title')}}</h1>
                <p class="mt-4 mb-4 mr-4">{{$t('maintenance.text')}}</p>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </HubPanelLogo>
    </HubBg>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import HubBg from '@/components/hub/HubBg.vue';
import HubPanelLogo from '@/components/hub/HubPanelLogo.vue';

export default {
  name: 'maintenance',
  components: {
    HubBg,
    HubPanelLogo,
  },
  created() {
    // this.loginReset();
  },

  computed: {
  },

  methods: {
    ...mapActions('auth', ['loginReset']),
  },
};
</script>

<script>
export default {
  name: 'WidgetRAGPReview',
  props: {
    generatedText: {
      type: String,
      default: 'Generated',
    },
    sourcesText: {
      type: String,
      default: 'sources',
    },
    disclaimerText: {
      type: String,
      default: 'AI Answers can be wrong. Always fact-check.',
    },
    pdfPagesText: {
      type: String,
      default: 'Pages',
    },
    enabled: {
      type: Boolean,
      default: true,
    },
    avatar: {
      type: String,
      default: 'https://media.botcast.ai/1595514610395_chatbot.png',
    },
    message: {
      type: String,
      default: '#CCCCCC',
    },
    messageContrast: {
      type: String,
      default: 'dark',
    },
  },
  data() {
    return {
      isOpen: false,
      amount: 10,
      target: 'generated',
      generated: {
        x: 97,
        y: 178,
      },
      sources: {
        x: 375,
        y: 178,
      },
      disclaimer: {
        x: 70,
        y: 190,
      },
      question: {
        x: 120,
        y: 51,
      },
      answerLineOne: {
        x: 81,
        y: 116,
      },
      answerLineTwo: {
        x: 81,
        y: 139,
      },
      e: false,
      resourcesUsed: [
        {
          source: null,
          type: 'PDF',
          pages: [1, 2, 3],
          _sample: 0,
        },
        {
          source: null,
          type: 'WEBPAGE',
          _sample: 1,
        },
        {
          source: null,
          type: 'WEBPAGE',
          _sample: 2,
        },
      ],
    };
  },
  methods: {
    incr(target, axis) {
      this[target][axis] += this.amount;
    },
    decr(target, axis) {
      this[target][axis] -= this.amount;
    },
    handleToggle() {
      this.isOpen = this.$refs.details.open;
    },
    sourceLabel(source) {
      if (source.type === 'PDF') {
        if (source.pages) {
          return `PDF, ${this.pdfPagesText} ${source.pages.join(', ')}`;
        }
        return 'PDF';
      }

      if ('_sample' in source) {
        if (source._sample === 1) return `https://example.com/1/2f41aa8441b17`;
        if (source._sample === 2) return `https://example.com/general-information/faq/before-you-go`;
        return `https://example.com/${source._sample}/${Math.random().toString(16).slice(2)}`;
      }

      return source?.source;
    },
  },
  watch: {
    /**
     * Ensure user sees the field they're editing by opening the summary container
     */
    pdfPagesText() {
      if (this.isOpen) return;
      this.isOpen = true;
      return this.$refs.details.open = true;
    }
  }
};
</script>

<template>
  <!-- Debugging positioning controls
  <div class="d-flex gap-2">
    <input type="number" v-model.number="amount" style="width: 4rem">
    <button @click="decr(target, 'x')">Left</button>
    <div class="d-flex flex-col gap-1">
      <button @click="decr(target, 'y')">Up</button>
      <button @click="incr(target, 'y')">Down</button>
    </div>
    <button @click="incr(target, 'x')">Right</button>
    <select v-model="target">
      <option value="question">question</option>
      <option value="generated">generated</option>
      <option value="disclaimer">disclaimer</option>
      <option value="sources">sources</option>
      <option value="answerLineOne">answerLineOne</option>
      <option value="answerLineTwo">answerLineTwo</option>
    </select>
  </div> -->
  <div class="d-flex flex-col" style="width: fit-content">
    <svg
      width="376"
      height="72"
      viewBox="0 0 448 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="448" height="86" fill="#ECECEC" />
      <!-- Is there any age limit? -->
      <path
        d="M108 27.72a6.72 6.72 0 0 1 6.72-6.72h320.564A6.716 6.716 0 0 1 442 27.716V65.94H114.72a6.72 6.72 0 0 1-6.72-6.72z"
        :fill="message"
      />
      <text
        :x="question.x"
        :y="question.y"
        :fill="messageContrast === 'light' ? '#FFFFFF' : '#484848'"
        font-family="Helvetica"
        font-size="17"
        font-weight="500"
      >
        Gibt es eine Altersgrenze?
      </text>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M433.045 65.94H442v13.433z"
        :fill="message"
      />
    </svg>
    <div class="rag-ai-container">
      <div class="avatar-container">
        <img :src="avatar" width="44px" height="44px" alt="Avatar" />
      </div>
      <div class="chat-container">
        <!-- Left side fib -->
        <svg
          width="8"
          height="11"
          viewBox="59.701 85.523 8.955 13.432"
          style="position: relative; left: 1px"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M59.701 85.523h8.955v13.432z"
            fill="#fff"
          />
        </svg>
        <!-- Container for chat bubbles -->
        <div class="chat-bubbles">
          <!-- First chat bubble -->
          <div class="chat-bubble" :class="{ 'rounded-bottom': !enabled }">
            <p>
              Es gibt keine Altersbeschränkung und es ist für alle Altersgruppen
              geeignet.
            </p>
          </div>

          <!-- Lower half with AI indicator -->
          <details v-if="enabled" ref="details" class="ai-indicator rounded-bottom" @toggle="handleToggle">
            <summary class="ai-generated">
              <span class="ai-badge-container">
                <span class="ai-badge">AI</span>
                {{ generatedText }}
              </span>

              <div class="expand">
                {{ resourcesUsed.length }} {{ sourcesText }}
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 20"
                  xmlns="http://www.w3.org/2000/svg"
                  :style="`transform: rotate(${isOpen ? '0' : '-90'}deg);flex-shrink: 0;`"
                >
                  <path
                    d="M14.7846 7.57617L10.7862 11.5659L6.78784 7.57617L5.55957 8.80444L10.7862 14.0311L16.0129 8.80444L14.7846 7.57617Z"
                    fill="black"
                  />
                </svg>
              </div>
            </summary>

            <ol>
              <li v-for="source in resourcesUsed" :key="source.source">
                <a rel="noopener">
                  {{ sourceLabel(source) }}
                </a>
              </li>
            </ol>
          </details>

          <!-- Disclaimer about accuracy of AI -->
          <p v-if="enabled" class="ai-disclaimer">
            {{ disclaimerText }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.rag-ai-container {
  background: #ececec;
  width: 376px;
  display: grid;
  grid-template-columns: 50px 1fr;
  padding-bottom: 1rem;
}

.avatar-container {
  margin: 0 5px;
}

.rag-ai-container img {
  border: solid 1px white;
  border-radius: 50%;
  position: relative;
  top: -5px;
}

.chat-container {
  display: flex;
}

.chat-bubbles {
  width: 284px;
  margin-right: 35px;
  font-family: 'Helvetica', sans-serif;
  text-align: start;
  font-size: 15px;
}

.chat-bubble {
  width: 100%;
  background: #fff;
  border-radius: 0 8px 0 0;
  padding: 12px 12px 8px 12px;
  line-height: 22px;
}
.chat-bubble p {
  margin-bottom: 0;
}

.ai-disclaimer {
  line-height: 120%;
  font-size: 12px;
  color: gray;
  margin: 0.5em 0;
  padding: 0;
  text-align: center;
}

.expand,
.ai-badge-container {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  text-overflow: ellipsis;
  white-space: initial;
  overflow: hidden;
  min-width: auto;
  word-wrap: anywhere;
}

.ai-generated {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2em;
}

.ai-indicator .ai-generated {
  cursor: pointer;
}

.rounded-bottom {
  border-radius: 0 0 8px 8px;
}

.ai-indicator {
  margin-top: 1px;
  background: #fff;
  color: #000;
  padding: 7px 12px 7px 12px;
  font-size: 12px;
}

ol {
  list-style: decimal;
  padding: 8px 12px 8px calc(12px + 1rem);
  margin: 0;
  overflow-wrap: break-word;
  text-align: start;
  text-decoration: underline;
}

.ai-badge {
  color: #fff;
  background: #000;

  font-weight: 600;
  font-size: 10px;
  font-style: normal;
  line-height: 100%;

  border-radius: 3px;
  display: flex;
  padding: 2px 3px 2px 2px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: fit-content;
  word-wrap: break-word;
}
</style>
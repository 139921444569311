<template>
  <div class="relative" style="width: 25px; height: 6px; overflow: hidden">
    <template v-if="!isActive">
      <v-tooltip bottom color="primary lighten-3">
        <template v-slot:activator="{ on: onTooltip, attrs: attrsTooltip }">
          <div
            v-on="onTooltip"
            v-bind="attrsTooltip"
            style="
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              border: 1px solid rgb(158, 158, 158, 0.4);
            "
          ></div>
        </template>
        <span>{{ 'Noch nicht verfügbar' }}</span>
      </v-tooltip>
    </template>
    <template v-else>
      <div
        class="secondary darken-2"
        style="
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0.3;
        "
      ></div>

      <div
        class="secondary darken-2"
        :style="{
          position: 'absolute',
          top: 0,
          left: 0,
          height: '100%',
          width: barWidth,
          maxWidth: '100% !import',
        }"
      ></div>
    </template>
  </div>
</template>
<script>
import IntentUtils from '@/utils/intent';
export default {
  name: 'IntentsRelevanceBar',
  props: {
    intent: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isActive() {
      if ( typeof this.intent?.relevance?.frequent === 'undefined') return false;
      if (this.intent?.status === IntentUtils.statusTypes.IN_REVIEW) return false;

      return true;
    },
    barWidth() {
      const frequent = this.intent?.relevance?.frequent || 0;
      let frequQuarter = 0;
      if (frequent >= 1 && frequent <= 3) frequQuarter = 1;
      if (frequent >= 4 && frequent <= 6) frequQuarter = 2;
      if (frequent >= 7 && frequent <= 8) frequQuarter = 3;
      if (frequent >= 9) frequQuarter = 4;
      return `${frequQuarter * 25}%`;
    },
  },
};
</script>
<template>
  <v-app style="max-height: 100vh; height: 100vh">
    <HubMenu />
    <Appbar />
    <HubContent>
      <template v-slot:navbar>
        <HubContentNavbar :title="'QM Dashboard'" :isLoading="isLoading">
          <template v-slot:toolbar>
            <v-tooltip bottom color="primary lighten-3">
              <template v-slot:activator="{ on }">
                <span v-on="on">
                  <BtnSquare icon="mdi-chart-arc" @click="exportAnalysis()" />
                </span>
              </template>
              <span>Download der Analyse als ZIP mit mehreren Dateien.</span>
            </v-tooltip>
            <v-tooltip bottom color="primary lighten-3">
              <template v-slot:activator="{ on }">
                <span v-on="on">
                  <BtnSquare
                    icon="mdi-thumb-up"
                    v-on="on"
                    @click="exportFeedback(true)"
                  />
                </span>
              </template>
              <span>Download der positiven Bewertungen als CSV.</span>
            </v-tooltip>
            <v-tooltip bottom color="primary lighten-3">
              <template v-slot:activator="{ on }">
                <span v-on="on">
                  <BtnSquare
                    icon="mdi-thumb-down"
                    v-on="on"
                    @click="exportFeedback(false)"
                  />
                </span>
              </template>
              <span>Download der negativen Bewertungen als CSV.</span>
            </v-tooltip>
          </template>
        </HubContentNavbar>
      </template>
      <template v-slot:content v-if="currentBotId">
        <HubFrameSimple>
          <template v-slot:content>
            <v-row>
              <v-col cols="12">
                <div class="text-h6">KPI</div>
                <v-divider />
              </v-col>
              <v-col cols="12" md="4">
                <QmDashboardKpi
                  :value="`${kpi.days30}%`"
                  text="KPI letzten 30 Tage"
                />
              </v-col>
              <v-col cols="12" md="4">
                <QmDashboardKpi
                  :value="`${kpi.days30Before}%`"
                  text="KPI 30 Tage davor"
                />
              </v-col>
              <v-col cols="12" md="4">
                <QmDashboardKpi
                  :value="`${kpi.days30LastYear}%`"
                  text="KPI 30 Tage letztes Jahr"
                />
              </v-col>
              <v-col cols="12">
                <QmDashboardKpiChart />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6" lg="3">
                <QmDashboardModel />
              </v-col>
              <v-col cols="12" md="6" lg="3">
                <QmDashboardFeatures />
              </v-col>
              <v-col cols="12" md="6" lg="3">
                <QmDashboardLanguages />
              </v-col>
              <v-col cols="12" md="6" lg="3">
                <QmDashboardAmounts />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" lg="6">
                <QmDashboardListVariants />
              </v-col>
              <v-col cols="12" lg="6">
                <QmDashboardListIntents />
              </v-col>
            </v-row>
          </template>
        </HubFrameSimple>
      </template>
    </HubContent>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import HubMenu from '@/components/hub/HubMenu.vue';
import Appbar from '@/components/Appbar.vue';
import HubContent from '@/components/hub/HubContent.vue';
import HubContentNavbar from '@/components/hub/HubContentNavbar.vue';
import HubFrameSimple from '@/components/hub/HubFrameSimple.vue';
import QmDashboardKpi from '@/components/qmDashboard/QmDashboardKpi.vue';
import QmDashboardModel from '@/components/qmDashboard/QmDashboardModel.vue';
import QmDashboardFeatures from '@/components/qmDashboard/QmDashboardFeatures.vue';
import QmDashboardLanguages from '@/components/qmDashboard/QmDashboardLanguages.vue';
import QmDashboardAmounts from '@/components/qmDashboard/QmDashboardAmounts.vue';
import QmDashboardListVariants from '@/components/qmDashboard/QmDashboardListVariants.vue';
import QmDashboardListIntents from '@/components/qmDashboard/QmDashboardListIntents.vue';
import QmDashboardKpiChart from '@/components/qmDashboard/QmDashboardKpiChart.vue';
import BtnSquare from '@/components/common/BtnSquare.vue';
import StatsService from '@/services/stats';

export default {
  name: 'QmDashboard',
  components: {
    HubMenu,
    Appbar,
    HubContent,
    HubFrameSimple,
    HubContentNavbar,
    BtnSquare,
    QmDashboardKpi,
    QmDashboardModel,
    QmDashboardFeatures,
    QmDashboardLanguages,
    QmDashboardAmounts,
    QmDashboardListVariants,
    QmDashboardListIntents,
    QmDashboardKpiChart,
  },
  computed: {
    ...mapGetters('bots', ['currentBotStage', 'currentBotId']),
    ...mapGetters('qm', ['kpi']),
  },
  data() {
    return {
      isLoading: false,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.$store.commit('qm/resetState');
      this.$store.dispatch('qm/fetchKpis', { botId: this.currentBotId });
      this.$store.dispatch('qm/fetchKpisYear', { botId: this.currentBotId });
      this.$store.dispatch('qm/fetchTrainingdata', {
        botId: this.currentBotId,
      });
    },
    async exportAnalysis() {
      this.isLoading = true;
      const dateRange = [moment().add(-30, 'days'), moment()];

      let value = await StatsService.getAnalysisExport(
        this.currentBotId,
        dateRange
      );

      const blob = new Blob([value]);

      const url = URL.createObjectURL(blob);

      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = `moinAI-analysis-${dateRange[0].format(
        'YYYY-MM-DD'
      )}-${dateRange[1].format('YYYY-MM-DD')}.zip`;

      document.body.appendChild(anchor);
      anchor.click();

      // Clean up the URL object to free up memory
      document.body.removeChild(anchor);
      URL.revokeObjectURL(url);

      this.isLoading = false;
    },
    async exportFeedback(positive = false) {
      this.isLoading = true;
      const dateRange = [
        moment().add(-30, 'days'),
        moment(),
      ];

      let csvString = await StatsService.getAIFeedbackExport(
        this.currentBotId,
        dateRange,
        positive
      );

      const anchor = document.createElement('a');
      anchor.href =
        'data:text/csv;charset=utf-8,' + encodeURIComponent(csvString);

      anchor.download = `moinAI-Feedback-${
        positive ? 'Pos' : 'Neg'
      }-${dateRange[0].format('YYYY-MM-DD')}-${dateRange[1].format(
        'YYYY-MM-DD'
      )}.csv`;

      anchor.click();

      this.isLoading = false;
    },
  },
};
</script>

<template>
  <v-tab-item transition="none" color="black" reverse-transition="none" value="config" style="height: 100%">
    <v-card v-if="getChannelID" class="px-8 pt-4" color="#f0f0f0" elevation="0" height="100%">
      <h2>{{$t('channelSettings.config.tabHeadline')}}</h2>
      <WidgetConfigFields v-if="getCurrentChannelConfig" :config="getCurrentChannelConfig(getChannelID)"/>
    </v-card>
  </v-tab-item>
</template>

<script>
import { mapGetters } from 'vuex'
import WidgetConfigFields from '@/components/channelSettings/WidgetConfigFields.vue';

export default {
  components: {
    WidgetConfigFields,
  },
  computed: {
    getChannelID() {
      return this.$route.params.channelid;
    },
    ...mapGetters('channelsettings', ['getThemeColors']),
    ...mapGetters('bots', ['getCurrentChannelConfig']),
  },
  methods: {
  },
  data() {
    return {}
  },
};
</script>

<style>
</style>
<template>
  <div
    class="moin-bg-ci-primary"
    style="
      position: relative;
      width: 100%;
      height: 792px;
      overflow: hidden;
    "
  >
    <img
      src="/moin_logo_white.png"
      style="position: absolute; height: 35px; top: 40px; right: 40px"
    />
    <div
      style="position: absolute; top: -100px; left: 0; transform: scale(0.7)"
    >
      <HubBraces color="#EF7457" />
    </div>
    <div
      style="position: absolute; top: 300px; right: 0; transform: scale(0.7) rotate(270deg)"
    >
      <HubBraces color="#EFF2FF" />
    </div>
    <div
      class="d-flex flex-column justify-center"
      style="
        position: absolute;
        top: 0;
        left: 35%;
        max-width: 55%;
        height: 100%;
      "
    >
      <div>
        <div class="moin-text-ci-secondary text-uppercase mb-2">
          {{ `{ ${$t('stats.title')} }` }}
        </div>
        <div class="pdf-title white--text mb-2">
          {{ currentBot.name }}
        </div>
        <div
          v-if="currentChannels.length > 1"
          class="text-h5 white--text text-uppercase mb-1"
        >
          {{ $t('common.channel') }}:
          <span class="moin-text-ci-secondary">
            {{ channel || $t('common.allChannelsLabel') }}
          </span>
        </div>
        <template v-if="isAnualReview">
          <div class="text-h4 white--text mb-1">
            {{ $t('stats.anualReview') }} {{ anualReviewYear}}
          </div>
        </template>
        <template v-else>
          <div class="text-h5 white--text text-uppercase mb-1">
            {{ dateRangeFormatted }}
          </div>
          <div
            v-if="dateRangeCompareFormatted"
            class="text-h5 white--text text-uppercase"
          >
            <span>{{ $t('common.comparison') }}:</span>
            {{ dateRangeCompareFormatted }}
          </div>
        </template>
      </div>
    </div>
    <div
      class="text-h6 white--text text-uppercase"
      style="position: absolute; bottom: 32px; right: 40px"
    >
      {{ dateToday }}
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import HubBraces from '@/components/hub/HubBraces.vue';

export default {
  name: 'StatsPdfCover',
  components: {
    HubBraces,
  },
  computed: {
    ...mapGetters('bots', ['currentBot', 'currentChannels']),
    ...mapGetters('stats', [
      'channel',
      'dateRangeFormatted',
      'dateRangeCompareFormatted',
      'isAnualReview',
      'anualReviewYear',
    ]),
    dateToday() {
      const date = moment();
      return date.format(this.$t('common.dateFormatShort'));
    },
  },
};
</script>
<style lang="scss" scoped>
  .pdf-title {
    font-size: 3rem !important;
    font-weight: 400;
    line-height: 3.125rem;
  }
</style>
import { render, staticRenderFns } from "./HubEditorFrame.vue?vue&type=template&id=3d4039d7&scoped=true"
import script from "./HubEditorFrame.vue?vue&type=script&lang=js"
export * from "./HubEditorFrame.vue?vue&type=script&lang=js"
import style0 from "./HubEditorFrame.vue?vue&type=style&index=0&id=3d4039d7&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d4039d7",
  null
  
)

export default component.exports
<template>
  <v-app>
    <HubMenu />
    <Appbar />
    <HubContent :is-sidebar-open="isSidebarOpen">
      <template v-slot:navbar>
        <HubContentNavbar :title="$t('ai.title')" />
      </template>
      <template v-slot:content>
        <v-container class="pa-6">
          <v-row>
            <v-col cols="12" lg="8" offset-lg="2">
              <AiFeedback :text="text" @onAiFetched="updateSidebar" @onAiFetching="loading" />
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template v-if="isAdmin" v-slot:sidebar>
        <HubContentSidebarSimple
          :isOpen="isSidebarOpen"
          @onClose="closeSidebar()"
          title="K2 Index"
          icon="info"
        >
            <div
              v-bind:class="{'ai-loading': isLoading }"
              v-for="(intent, index) in k2Intents"
              :key="index"
            >
              <v-card class="pa-2">
                <strong>{{ intent.displayName }}</strong> ({{ intent.intent }})
                <br>
                <span v-for="(name,index) in intent.k2names" :key="index">
                {{ name }}<span v-if="index + 1 < intent.k2names.length">,&nbsp;</span>
                </span>
                <br>
                <strong>{{ formatPercentPrecision(intent.score) }}</strong>
              </v-card>
              <v-divider></v-divider>
            </div>
        </HubContentSidebarSimple>
      </template>
    </HubContent>
  </v-app>
</template>
<style>
  .ai-loading {
    opacity: 0.5;
  }
</style>

<script>
import { mapGetters } from 'vuex';

import HubMenu from '@/components/hub/HubMenu.vue';
import Appbar from '@/components/Appbar.vue';
import HubContent from '@/components/hub/HubContent.vue';
import HubContentNavbar from '@/components/hub/HubContentNavbar.vue';
import HubContentSidebarSimple from '@/components/hub/HubContentSidebarSimple.vue';

import AiFeedback from '@/components/AiFeedback.vue';

import IntentService from '../services/intents';
import {
  formatPercentPrecision,
} from '@/utils/formatNumber';

export default {
  name: 'AITooling',
  props: ['text'],
  components: {
    HubMenu,
    Appbar,
    AiFeedback,
    HubContent,
    HubContentNavbar,
    HubContentSidebarSimple,
  },
  data() {
    return {
      isSidebarOpen: false,
      k2Intents: [],
      isLoading: false,
      formatPercentPrecision,
    };
  },
  computed: {
    ...mapGetters('auth', ['isAdmin']),
    ...mapGetters('bots', ['currentBotId']),
  },
  methods: {
    closeSidebar() {
      this.isSidebarOpen = false;
    },
    openSidebar() {
      this.isSidebarOpen = true && this.isAdmin;
    },
    loading() {
      this.isLoading = true;
    },
    async updateSidebar(data) {
      
      const k2Indices = await IntentService.getK2Indices({ botId: this.currentBotId, text: data.text});
      if (k2Indices) {
        this.k2Intents = k2Indices.intents;
        this.openSidebar();
        console.log(k2Indices);
        this.isLoading = false;
      } else {
        this.k2Intents = [];
      }
    },
  },
};
</script>

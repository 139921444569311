<template>
  <div>
    <ActionList :actions="actions" schema="*" :quickreplies="ratingValue === null" @onUpdate="update" />
    <v-timeline-item v-if="isAdmin" color="white">
      <template v-slot:icon>
        <v-icon>star</v-icon>
      </template>
      <v-select
        :items="ratingForms"
        item-text="displayName"
        item-value="value"
        :value="ratingValue"
        @change="changeRating"
        :disabled="ratingForms.length === 1"
      ></v-select>
    </v-timeline-item>
  </div>
</template>

<script>
import ActionList from '@/components/ActionList.vue';
import ActionUtil from '@/utils/actions';
import { mapGetters } from 'vuex';

export default {
  name: 'LivechatHandoverToBot',
  props: {
    actions: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    ActionList,
  },
  data: () => ({}),
  computed: {
    ...mapGetters('auth', ['isAdmin']),
    ...mapGetters('forms', ['forms']),
    ratingValue() {
      const nextAction = ActionUtil.findNextAction(this.actions);
      if (nextAction) {
        return nextAction.parameters.intent;
      }

      return null;
    },
    ratingForms() {
      const ratingForms = this.forms ? this.forms.filter(f => f.template === 'BotFormRating') : [];
      return [
        {
          displayName: 'Kein Livechat Rating',
          value: null,
        },
      ].concat(ratingForms.map(f => ({
        displayName: f.displayName,
        value: f.intent,
      })));
    },
  },
  methods: {
    update() {
      this.$emit('onUpdate');
    },
    changeRating(value) {
      const contentActions = ActionUtil.filterContentActions(this.actions);
      if (contentActions.length > 0) {
        const lastAction = contentActions[contentActions.length - 1];
        ActionUtil.clearQuickReplies(lastAction);
      }

      if (value) {
        ActionUtil.updateOrCreateNextAction(this.actions, value);
      } else {
        ActionUtil.removeNextAction(this.actions);
      }

      
      this.$emit('onUpdate');
    },
  },
};
</script>
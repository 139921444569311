<template>
  <div class="moin-avatar" @click="outsch()">
    <img src="@/assets/avatar-moini/moin-avatar.svg" />
    <img
      v-if="stateSmiling"
      src="@/assets/avatar-moini/moin-avatar-mouth-smiling.svg"
    />
    <img
      v-if="stateThinking"
      src="@/assets/avatar-moini/moin-avatar-mouth-thinking.svg"
    />
    <img
      v-if="stateOutsch"
      src="@/assets/avatar-moini/moin-avatar-mouth-outsch.svg"
    />
    <img
      v-if="stateBlinking"
      src="@/assets/avatar-moini/moin-avatar-eyes-closed.svg"
    />
    <img
      v-if="!stateBlinking && !stateThinking"
      src="@/assets/avatar-moini/moin-avatar-eyes-open.svg"
    />
    <img
      v-if="stateThinking"
      class="moin-avatar-eyes-thinking"
      src="@/assets/avatar-moini/moin-avatar-eyes-thinking.svg"
    />
  </div>
</template>
<script>
export default {
  name: 'DemoMoinAvatar',
  components: {},
  data() {
    return {
      stateSmiling: true,
      stateBlinking: false,
      stateOutsch: false,
      stateThinking: false,
      loading: false,
    };
  },
  created() {
    this.blink();
  },
  watch: {
    loading() {
      if (this.loading) {
        this.stateThinking = true;
        this.stateOutsch = false;
        this.stateSmiling = false;
      } else {
        this.stateSmiling = true;
        this.stateThinking = false;
        this.stateBlinking = false;
      }
    },
  },
  methods: {
    outsch() {
      const random = Math.floor(Math.random() * 2);
      if (random) {
        this.stateOutsch = true;
        this.stateSmiling = false;
        this.stateBlinking = true;
      } else {
        this.loading = true;
      }

      setTimeout(() => {
        this.stateOutsch = false;
        this.stateSmiling = true;
        this.stateBlinking = false;
        this.loading = false;
      }, 1000);
    },
    async blink() {
      // random time between 5 and 10 seconds
      const time = Math.floor(Math.random() * 5000) + 5000;

      await new Promise((resolve) => setTimeout(resolve, time));
      // Not now, try again
      if (this.stateOutsch || this.stateThinking) {
        this.blink();
        return;
      }
      this.stateBlinking = true;
      await new Promise((resolve) => setTimeout(resolve, 300));
      this.stateBlinking = false;
      // Random 0 or 1
      const again = Math.floor(Math.random() * 2);
      if (again) {
        await new Promise((resolve) => setTimeout(resolve, 200));
        this.stateBlinking = true;
        await new Promise((resolve) => setTimeout(resolve, 300));
        this.stateBlinking = false;
      }
      this.blink();
    },
  },
};
</script>
<style lang="scss" scoped>
.moin-avatar {
  position: relative;
  width: 146px;
  height: 146px;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  @keyframes eyesThinking {
    0% {
      transform: scaleX(1);
    }
    50% {
      transform: scaleX(-1);
    }
    100% {
      transform: scaleX(1);
    }
  }

  .moin-avatar-eyes-thinking {
    transform: scaleX(-1);
    animation: eyesThinking 1.5s steps(1) infinite;
  }
}
</style>

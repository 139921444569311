const state = {
  channels: [],
};

const getters = {
  channels: (state) => state.channels,
};

const actions = {
  loadChannels({ rootGetters, commit }) {
    // check if channels are already loaded
    const channels = rootGetters['bots/currentBot'].channels;
    commit('setValue', { key: 'channels', value: channels });
  },
};

const mutations = {
  setValue(state, { key, value }) {
    state[key] = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

<template>
  <div>
    <v-timeline-item
      color="white"
      v-if="isUsable('text', 0) || isUsable('image', 0) || isUsable('cards', 0)"
    >
      <template v-slot:icon>+</template>
      <v-card flat class="mb-12">
        <v-row>
          <v-card-actions>
            <v-btn
              v-if="isUsable('text', 0)"
              small
              tile
              outlined
              color="primary lighten-3"
              data-cy="add-text-input-button"
              @click="addText()"
            >
              <v-icon left>short_text</v-icon>
              <span class="d-none d-sm-inline">Text</span>
            </v-btn>
            <v-btn
              v-if="isUsable('image', 0)"
              small
              tile
              outlined
              color="primary lighten-3"
              data-cy="add-image-button"
              @click="addImage()"
            >
              <v-icon left>insert_photo</v-icon>
              <span class="d-none d-sm-inline">Media</span>
            </v-btn>
            <v-btn
              v-if="isUsable('cards', 0)"
              :disabled="isUserlike"
              small
              tile
              outlined
              data-cy="add-cards-button"
              color="primary lighten-3"
              @click="addCards()"
            >
              <v-icon left>view_carousel</v-icon>
              <span class="d-none d-sm-inline">Slides</span>
            </v-btn>
            <v-btn
              v-if="isAdmin && isUsable('webhook', 0)"
              small
              tile
              outlined
              @click="addWebhook()"
              color="admin"
            >
              <v-icon left>http</v-icon>
              <span class="d-none d-sm-inline">Webhook</span>
            </v-btn>
            <v-btn
              v-if="isAdmin && isUsable('userlike_check', 0)"
              small
              tile
              outlined
              @click="addUserlikeCheck()"
              color="admin"
            >
              <v-icon left>http</v-icon>
              <span class="d-none d-sm-inline">Userlike Agenten</span>
            </v-btn>
          </v-card-actions>
        </v-row>
      </v-card>
    </v-timeline-item>
    <v-timeline-item
      color="white"
      v-for="(action, index) in contentActions"
      v-bind:key="action.name"
    >
      <template v-slot:icon>{{index+1}}</template>
      <v-card flat class="mb-12">
        <v-row>
          <ActionEdit
            :channelType="channelType"
            :variations="variations"
            :quickreplies="quickreplies && index === contentActions.length - 1"
            :action="action"
            @onUpdated="update"
            :deletable="deletableAction && action.content.quick.length === 0 && isDeletable(index)"
            @onDeleted="deleteAction(action)"
          ></ActionEdit>

          <v-card-actions>
            <v-btn
              :disabled="linkDisabled(action)"
              @click="addLink(action)"
              small
              outlined
              tile
              data-cy="add-link-button"
              color="primary lighten-3"
            >
              <v-icon left>link</v-icon>
              <span class="d-none d-sm-inline">Links</span>
            </v-btn>
            <v-btn
              v-if="action.content.quick.length === 0 && isUsable('text', index + 1)"
              small
              tile
              outlined
              color="primary lighten-3"
              @click="addText(action)"
            >
              <v-icon left>short_text</v-icon>
              <span class="d-none d-sm-inline">Text</span>
            </v-btn>
            <v-btn
              v-if="action.content.quick.length === 0 && isUsable('image', index + 1)"
              small
              tile
              outlined
              color="primary lighten-3"
              @click="addImage(action)"
            >
              <v-icon left>insert_photo</v-icon>
              <span class="d-none d-sm-inline">Media</span>
            </v-btn>
            <v-btn
              :disabled="isUserlike"
              v-if="action.content.quick.length === 0 && isUsable('cards', index + 1) && !withoutCards"
              small
              tile
              outlined
              @click="addCards(action)"
              color="primary lighten-3"
            >
              <v-icon left>view_carousel</v-icon>
              <span class="d-none d-sm-inline">Slides</span>
            </v-btn>
            <v-btn
              v-if="isAdmin && action.content.quick.length === 0 && isUsable('webhook', index + 1)"
              small
              tile
              outlined
              @click="addWebhook(action)"
              color="admin"
            >
              <v-icon left>http</v-icon>
              <span class="d-none d-sm-inline">Webhook</span>
            </v-btn>
            <v-btn
              v-if="isAdmin && isUsable('userlike_check', index + 1)"
              small
              tile
              outlined
              @click="addUserlikeCheck()"
              color="admin"
            >
              <v-icon left>http</v-icon>
              <span class="d-none d-sm-inline">Userlike Agenten</span>
            </v-btn>
          </v-card-actions>
        </v-row>
      </v-card>
    </v-timeline-item>
  </div>
</template>

<script>
// import { mapActions } from "vuex";
// import BotService from '../services/bot';
import ActionEdit from '@/components/ActionEdit.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'ActionList',
  props: {
    actions: {
      type: Array,
      default: () => [],
    },
    name: String,
    botId: String,
    quickreplies: {
      type: Boolean,
      default: false,
    },
    variations: {
      type: Boolean,
      default: false,
    },
    channelType: {
      type: String,
      default: 'widget',
    },
    withoutCards: {
      type: Boolean,
      default: false,
    },
    schema: {
      type: String,
      default: '*',
    },
  },
  components: {
    ActionEdit,
  },
  data: () => ({
    /*    quickReplies: [] */
  }),
  computed: {
    showWebhooks: function () {
      return this.isAdmin;
    },
    showUserlikeCheck: function () {
      return this.isAdmin;
    },
    deletableAction: function () {
      if (this.contentActions.length > 1) {
        return true;
      } else {
        return undefined;
      }
    },
    contentActions: function () {
      if (this.actions) {
        const mappedActions = this.actions.map(a => {
          if (!a.content && a.name === 'webhook' && this.showWebhooks) {
            // virtual action: name 'webhook' + random number from 0 to 100000
            const newName = 'webhook' + Math.floor(Math.random() * 100000);
            const newAction = {
              name: newName,
              content: {
                name: newName,
                type: 'webhook',
                data: a.parameters,
                quick: [],
              },
              refAction: a
            }
            return newAction;
          }

          if (!a.content && a.name === 'userlike' && this.showUserlikeCheck) {
            // virtual action: name 'userlike' + random number from 0 to 100000
            const newName = 'userlike' + Math.floor(Math.random() * 100000);
            const newAction = {
              name: newName,
              content: {
                name: newName,
                type: 'userlike_check',
                data: a.parameters,
                quick: [],
              },
              refAction: a
            }
            return newAction;
          }

          return a;
        })

        return mappedActions.filter((a) => {
          if (this.withoutCards) {
            return !!a.content && a.content.type !== 'cards';
          }
          return a.content;
        });
      }

      return [];
    },
    ...mapGetters('auth', ['isAdmin']),
    isUserlike() {
      return this.channelType === 'userlike';
    },
  },
  created() {
    const schemaWithoutWildcards = this.schema.replace(/\*/g, '');
    const actionCode = this.actionCode();
    if (schemaWithoutWildcards.length > actionCode.length) {
      // missing schema types
      const missingTypes = schemaWithoutWildcards.substring(actionCode.length);

      for (let i = 0; i < missingTypes.length; i++) {
        const lastAction = this.contentActions[this.contentActions.length - 1];
        switch (missingTypes[i]) {
          case 't':
            this.addText(lastAction);
            break;
          case 'i':
            this.addImage(lastAction);
            break;
          case 'c':
            this.addCards(lastAction);
            break;
        }
      }
    }
  },
  methods: {
    update() {
      this.$emit('onUpdate');
    },
    typeMapping(typeToMap) {
      switch (typeToMap) {
        case 'text':
          return 't';
        case 'image':
          return 'i';
        case 'cards':
          return 'c';
        default:
          return 'u';
      }
    },
    actionCode() {
      let actionCode = this.contentActions.map((a) => {
        return this.typeMapping(a.content.type);
      });
      return actionCode.join('');
    },
    schemaMatched(code) {
      const regexp = new RegExp(`^${this.schema.replace(/\*/g, '.*')}$`);
      return regexp.test(code);
    },
    isUsable(type, index) {
      let actionCode = this.actionCode();
      let typeChar = this.typeMapping(type);
      // insert the type char at the index from the action code
      actionCode =
        actionCode.substr(0, index) + typeChar + actionCode.substr(index);

      // if the actionCode has minor characters than the schema, fill up with the rest of the schema
      if (actionCode.length < this.schema.length) {
        actionCode = actionCode + this.schema.substring(actionCode.length);
      }
      if (this.schemaMatched(actionCode)) {
        return true;
      }

      return false;
    },
    isDeletable(index) {
      let actionCode = this.actionCode();
      // remove the type char at the index from the action code
      actionCode = actionCode.substr(0, index) + actionCode.substr(index + 1);
      if (this.schemaMatched(actionCode)) {
        return true;
      }
      return false;
    },
    /* updateQuickReplies() {
                for (let i = 0; i < this.contentActions.length; i++) {
                    if (i < this.contentActions.length - 1 && Array.isArray(this.contentActions[i].content.quick) && this.contentActions[i].content.quick.length > 0) {
                        this.contentActions[i].content.quick.forEach(qr => {
                            this.contentActions[i].content.strings = this.contentActions[i].content.strings.filter(str => {
                                return (`{${str.name}}` !== qr.label);
                            });
                        });
                        this.contentActions[i].content.quick.length = 0;
                    }

                    if (i === this.contentActions.length - 1) {
                        this.contentActions[i].content.quick.length = 0;
                        this.quickReplies.forEach(qr => {
                            this.contentActions[i].content.quick.push({label: qr.label, intent: qr.intent});
                            this.contentActions[i].content.strings.push(qr.str);
                        });
                    }
                }
            }, */
    deleteAction: function (action) {
      // Webhook HACK
      if (action.refAction && action.refAction.name === 'webhook') {

        // equal to js object reference
        const index = this.actions.findIndex((a) => action.refAction === a);
        this.actions.splice(index, 1);
        this.update();

        return;
      }

      // Userlike HACK
      if (action.refAction && action.refAction.name === 'userlike') {

        // equal to js object reference
        const index = this.actions.findIndex((a) => action.refAction === a);
        this.actions.splice(index, 1);
        this.update();

        return;
      }
      
      let index = this.actions.findIndex((a) => {
        return action.name === a.name;
      });

      if (index - 1 >= 0 && this.actions[index - 1].name === 'delay') {
        this.actions.splice(index - 1, 2);
      } else {
        this.actions.splice(index, 1);
      }

      /*    this.updateQuickReplies(); */
      this.update();
    },
    addCards: function (action) {
      let index = action
        ? this.actions.findIndex((a) => action.name === a.name)
        : 0;

      let newName = 'new_' + Date.now();
      let newAction = {
        name: newName,
        content: {
          name: newName,
          bot: this.botId,
          displayName: 'new text',
          quick: [],
          data: [
            {
              title: '{title1}',
              subtitle: '{subtitle1}',
              image: '{image1}',
              buttons: [],
            },
          ],
          strings: [
            { data: this.$t('botFormEdit.titlePlaceholder'), name: 'title1' },
            { data: this.$t('botFormEdit.descriptionLabel'), name: 'subtitle1' },
            {
              data: 'https://media.moin.ai/1619973037606_bild-platzhalter-var2jpg',
              name: 'image1',
            },
          ],
          type: 'cards',
          context: [],
        },
      };

      let newDelay = {
        name: 'delay',
        parameters: 1500,
      };

      if (
        index > 0 ||
        (this.actions.length > 0 && this.actions[0].name !== 'delay')
      ) {
        this.actions.splice(index + 1, 0, newDelay);
        index += 1;
      }

      this.actions.splice(index + 1, 0, newAction);

      let nextRuleIndex = this.actions.findIndex((a) => {
        return a.name === 'next';
      });

      if (nextRuleIndex >= 0 && nextRuleIndex < this.actions.length - 1) {
        let next = this.actions[nextRuleIndex];
        this.actions.splice(nextRuleIndex, 1);
        this.actions.push(next);
      }

      /* this.updateQuickReplies(); */
      this.update();
    },
    addText: function (action) {
      let index = action
        ? this.actions.findIndex((a) => action.name === a.name)
        : 0;

      // new name for the action with the current timestamp and a random number
      let newName = 'new_' + Date.now() + Math.floor(Math.random() * 1000);

      let newAction = {
        name: newName,
        content: {
          name: newName,
          bot: this.botId,
          displayName: 'new text',
          quick: [],
          data: '{text}',
          strings: [{ data: 'text hier', name: 'text' }],
          type: 'text',
          context: [],
        },
      };

      let newDelay = {
        name: 'delay',
        parameters: 1500,
      };

      if (
        index >= 0 ||
        (this.actions.length > 0 && this.actions[0].name !== 'delay')
      ) {
        if (action) {
          this.actions.splice(index + 1, 0, newDelay);
          index += 1;
        } else {
          this.actions.splice(index, 0, newDelay);
        }
      }

      this.actions.splice(index + 1, 0, newAction);
      let nextRuleIndex = this.actions.findIndex((a) => {
        return a.name === 'next';
      });

      if (nextRuleIndex >= 0 && nextRuleIndex < this.actions.length - 1) {
        let next = this.actions[nextRuleIndex];
        this.actions.splice(nextRuleIndex, 1);
        this.actions.push(next);
      }

      /*    this.updateQuickReplies(); */
      this.update();
    },
    addImage: function (action) {
      let index = action
        ? this.actions.findIndex((a) => action.name === a.name)
        : 0;

      let newName = 'new_' + Date.now();
      let newAction = {
        name: newName,
        content: {
          name: newName,
          bot: this.botId,
          displayName: 'new image',
          quick: [],
          data: '{url}',
          strings: [
            {
              data: 'https://media.moin.ai/1619973037606_bild-platzhalter-var2jpg',
              name: 'url',
            },
          ],
          type: 'image',
          context: [],
        },
      };

      let newDelay = {
        name: 'delay',
        parameters: 1500,
      };

      if (
        index >= 0 ||
        (this.actions.length > 0 && this.actions[0].name !== 'delay')
      ) {
        if (action) {
          this.actions.splice(index + 1, 0, newDelay);
          index += 1;
        } else {
          this.actions.splice(index, 0, newDelay);
        }
      }

      this.actions.splice(index + 1, 0, newAction);
      let nextRuleIndex = this.actions.findIndex((a) => {
        return a.name === 'next';
      });

      if (nextRuleIndex >= 0 && nextRuleIndex < this.actions.length - 1) {
        let next = this.actions[nextRuleIndex];
        this.actions.splice(nextRuleIndex, 1);
        this.actions.push(next);
      }

      /*    this.updateQuickReplies(); */
      this.update();
    },
    addLink: function (action) {
      if (action.content.type !== 'button') {
        let text = action.content.data;
        if (Array.isArray(text)) {
          text = text[0];
        }

        action.content.data = [
          {
            buttons: [],
            text,
          },
        ];

        action.content.type = 'button';
      }

      let id = `${Date.now()}`;
      let payload = `{btn_url${id}}`;
      let title = `{btn_title${id}}`;
      let newBtn = {
        type: 'web_url',
        payload,
        title,
      };

      action.content.data[0].buttons.push(newBtn);
      action.content.strings.push({
        name: `btn_url${id}`,
        data: 'https://test.de',
      });
      action.content.strings.push({
        name: `btn_title${id}`,
        data: 'neuer button',
      });

      this.update();
    },
    addWebhook: function (action) {
      let index = action
        ? this.actions.findIndex((a) => action.name === a.name)
        : 0;

      let newAction = {
        name: 'webhook',
        parameters: {type: null}
      };

      this.actions.splice(index + 1, 0, newAction);
      let nextRuleIndex = this.actions.findIndex((a) => {
        return a.name === 'next';
      });

      if (nextRuleIndex >= 0 && nextRuleIndex < this.actions.length - 1) {
        let next = this.actions[nextRuleIndex];
        this.actions.splice(nextRuleIndex, 1);
        this.actions.push(next);
      }

      this.update();
    },
    addUserlikeCheck: function (action) {
      let index = action
        ? this.actions.findIndex((a) => action.name === a.name)
        : 0;

      //userlike({type: "check", onlySetContext: true}) 
      let newAction = {
        name: 'userlike',
        parameters: {type: 'check', onlySetContext: true}
      };

      this.actions.splice(index + 1, 0, newAction);
      let nextRuleIndex = this.actions.findIndex((a) => {
        return a.name === 'next';
      });

      if (nextRuleIndex >= 0 && nextRuleIndex < this.actions.length - 1) {
        let next = this.actions[nextRuleIndex];
        this.actions.splice(nextRuleIndex, 1);
        this.actions.push(next);
      }

      this.update();
    },
    linkDisabled: function (action) {
      if (action && action.content && action.content.type === 'text') {
        return false;
      }

      return true;
    },
  },
};
</script>
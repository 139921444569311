<template>
  <div v-if="content">
    <v-timeline-item hide-dot color="grey lighten-3" fill-dot v-if="content">
      <v-row>
        <v-col>
          <v-text-field
            outlined
            class="font-weight-medium ma-0"
            dense
            v-model="content.displayName"
            :label="$t('botFormEdit.whatIsAsked')"
            @input="update"
          ></v-text-field>
        </v-col>
        <v-col>
          <div v-if="isAdmin" class="moin-admin-box pa-1">
            <v-text-field
              outlined
              v-model="content.context"
              dense
              :label="$t('botFormEdit.technicalName')"
              @input="update"
              hide-details
            />
          </div>
        </v-col>
      </v-row>
    </v-timeline-item>
    <ActionList
      :actions="this.content.question.actions"
      @onUpdate="update"
      @hook:mounted="updateName"
      withoutCards
      schema="*t"
      :channelType="channelType"
    ></ActionList>

    <v-timeline-item color="white">
      <template v-slot:icon>
        <v-icon small>account_tree</v-icon>
      </template>
      <!-- control active tab -->
      <v-tabs show-arrows @change="selectTab" :value="selectedTab">
        <v-tab v-for="(card, index) in cards" :key="index">
          <span
            class="text-truncate d-inline-block text-left"
            style="max-width: 180px;"
          >{{ card.title.data }}</span>
        </v-tab>

        <v-btn :disabled="cards.length >= 10" @click="addCard" icon class="align-self-center mr-4">
          <v-icon small>add</v-icon>
        </v-btn>
        <v-btn :disabled="cards.length < 2" @click="removeCard" icon class="align-self-center mr-4">
          <v-icon small>remove</v-icon>
        </v-btn>
        <v-tab-item v-for="(card, index) in cards" :key="index" class="pt-4">
          <v-card flat>
            <v-row>
              <v-col cols="6" md="3">
                <ImageUpload
                  :mediaSrc="card.image.data"
                  @onUpload="(imageFile) => uploadImage(card, imageFile)"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  :label="$t('botFormEdit.titleLabel')"
                  :placeholder="$t('botFormEdit.titlePlaceholder')"
                  v-model="card.title.data"
                  @input="update"
                  filled
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-textarea
                  :label="$t('botFormEdit.descriptionLabel')"
                  :placeholder="$t('botFormEdit.descriptionLabel')"
                  v-model="card.subtitle.data"
                  @input="update"
                  row-height="1"
                  auto-grow
                  filled
                />
              </v-col>
            </v-row>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-timeline-item>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ActionList from '@/components/ActionList.vue';
import ChannelsUtil from '@/utils/channels';
import ActionUtil from '@/utils/actions';
import ImageUpload from '@/components/common/ImageUpload.vue';
import UploadService from '@/services/upload';

export default {
  name: 'BotFormMultipleChoiceSliderEdit',
  components: {
    ActionList,
    ImageUpload,
  },
  props: ['box', 'intent', 'channel', 'name'],
  data: () => ({
    cardTab: 0,
  }),
  computed: {
    ...mapGetters('intents', ['intents']),
    ...mapGetters('bots', ['currentBotId', 'currentChannels']),
    ...mapGetters('auth', ['isAdmin']),
    content() {
      return this.$store.getters['content/getContent'](
        this.currentBotId,
        this.intent,
        this.box,
        true,
        this.channel
      );
    },
    answers() {
      let actions = this.content.question.actions.filter((a) => {
        return a.content;
      });

      return actions[actions.length - 1].content.quick;
    },
    selectedPath() {
      // returns the path value of the selected answer
      return this.$store.getters['formsEditor/getSelectedPath'](this.box);
    },
    selectedTab() {
      // if no answer is selected, return the first card
      if (!this.selectedPath) return 0;

      // get the index of the selected answer
      const cardIndex = this.cards.findIndex((card) => {
        return card?.button?.payload?.name === this.selectedPath;
      });

      return cardIndex < 0 ? 0 : cardIndex;
    },
    cardAction() {
      return (
        this.content?.question?.actions?.filter(
          (a) => a.content?.type === 'cards'
        )[0] || null
      );
    },
    cards() {
      if (!this.cardAction) return [];
      const strings = this.cardAction?.content?.strings || [];
      const data = this.cardAction?.content?.data || [];
      if (!Array.isArray(data)) {
        return [];
      }

      const newCards = data.map((card, index) => {
        return {
          title: ActionUtil.getStringObjectFrom(card?.title, strings),
          subtitle: ActionUtil.getStringObjectFrom(card?.subtitle, strings),
          image: ActionUtil.getStringObjectFrom(card?.image, strings),
          button: card.buttons[0], // returns the first button
          index,
        };
      });
      return newCards;
    },
    channelType() {
      return ChannelsUtil.getChannelType(
        this.content?.channel,
        this.currentChannels
      );
    },
  },
  methods: {
    updateName() {
      if (this.content && !this.content.displayName) {
        this.content.displayName = this.name;
      }
    },
    selectTab(cardIndex) {
      // set the selected card
      this.cardTab = cardIndex;
      const card = this.cards[cardIndex];
      // get intent from card button
      const intent = card?.button?.payload?.name;

      // update selected path and refresh the form
      this.$store.commit('formsEditor/selectPath', { box: this.box, intent });
      this.$store.dispatch(
        'formsEditor/updateRenderedSteps',
        this.currentBotId
      );
    },
    update() {
      this.$emit('onUpdated');
    },
    addCard() {
      if (!this.cardAction) return;
      const dateSuffix = '_' + Date.now();
      // generate intent value
      let intent = 'value' + Date.now();
      const cardData = {
        title: `{title${dateSuffix}}`,
        subtitle: `{subtitle${dateSuffix}}`,
        image: `{image${dateSuffix}}`,
        buttons: [
          {
            // create a button with the intent value
            title: '{btn_1_title}',
            type: 'postback',
            payload: {
              name: intent,
            },
          },
        ],
      };
      const strings = [
        { name: `title${dateSuffix}`, data: this.$t('common.newTitle') },
        {
          name: `subtitle${dateSuffix}`,
          data: this.$t('common.newDescription'),
        },
        {
          name: `image${dateSuffix}`,
          data: 'https://media.moin.ai/1619973037606_bild-platzhalter-var2jpg',
        },
      ];

      this.cardAction.content.data.push(cardData);
      this.cardAction.content.strings = [
        ...this.cardAction.content.strings,
        ...strings,
      ];
      this.cardTab = this.cards.length - 1;
      // send information to the bot form component for adding the new card to the values array
      this.$emit('addMultiStep', { intent, box: this.box });
    },
    removeCard() {
      const card = this.cardAction.content.data[this.cardTab];
      this.cardAction.content.strings = this.cardAction.content.strings.filter(
        (str) => {
          return (
            str.name !== card.title.name &&
            str.name !== card.subtitle.name &&
            str.name !== card.image.name
          );
        }
      );
      // get intent from card button to remove it from values array
      const intent = card.buttons[0].payload.name;
      this.cardAction.content.data.splice(this.cardTab, 1);
      this.cardTab = this.cards.length - 1;
      // send information to the bot form component for removing the card from the values array
      this.$emit('removeMultiStep', { intent, box: this.box });
    },
    async uploadImage(item, imageFile) {
      if (!imageFile) return;
      let response = await UploadService.uploadFile(imageFile, () => {});
      if (response?.file?.location) {
        if (item) {
          item.image.data = response.file.location;
        }
      }
    },
  },
};
</script>

<template>
  <v-app style="max-height: 100vh; height: 100vh">
    <HubMenu />
    <Appbar />
    <HubEditorFrame
      v-if="channel"
      ref="hubeditor"
      v-model="isSidebarOpen"
      :title="channel.displayName"
      :loading="isLoading"
      :draft="isDirty"
      saveIcon="cloud_upload"
      @onReset="resetChannel"
      @onSave="saveChannel"
      back="/settings/channels"
      :sidebar-items="[{ title: '', icon: 'info', name: 'info' }]"
    >
      <v-container fluid class="white pa-6 pb-12">
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="displayNameInput"
              class="mt-8"
              :label="$t('channelManagement.createDialog.nameLabel')"
              type="text"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              outlined
              :label="$t('channelManagement.createDialog.typeLabel')"
              :items="typeOptions"
              v-model="selectedType"
              item-text="text"
              item-value="value"
              dense
              hide-details
            />
          </v-col>
          <v-col>
            <v-select
              outlined
              :label="$t('common.language')"
              :items="langOptions"
              v-model="selectedLang"
              item-text="text"
              item-value="value"
              dense
              hide-details
            />
          </v-col>
        </v-row>
      </v-container>
    </HubEditorFrame>
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex';
import HubMenu from '@/components/hub/HubMenu.vue';
import Appbar from '@/components/Appbar.vue';
import HubEditorFrame from '@/components/hub/HubEditorFrame.vue';
export default {
  name: 'SettingsChannelsDetail',
  components: {
    Appbar,
    HubMenu,
    HubEditorFrame,
  },
  data() {
    return {
      channelId: null,
      isLoading: false,
      isSidebarOpen: false,
      displayNameInput: '',
      selectedType: null,
      selectedLang: null,
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...mapGetters('channels', ['channels']),
    ...mapGetters('bots', ['currentBotId', 'currentBot']),
    channel() {
      return this.channels.find((c) => c.channelId === this.channelId);
    },
    typeOptions() {
      return [
        {
          value: 'widget',
          text: this.$t('channelManagement.types.widget'),
        },
        {
          value: 'facebook',
          text: this.$t('channelManagement.types.facebook'),
        },
        {
          value: 'userlike',
          text: this.$t('channelManagement.types.userlike'),
        },
      ];
    },
    langOptions() {
      const languages = this.$t('languages');

      const items = Object.keys(languages);
      return items.map((code) => ({
        value: code,
        text: languages[code],
      }));
    },
    isDirty() {
      if (
        this.displayNameInput !== this.channel.displayName ||
        this.selectedType !== this.channel.type ||
        this.selectedLang !== this.channel.language
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    async init() {
      this.isLoading = true;
      if (!this.currentBot?.uniqueBotId) return;

      await this.$store.dispatch('channels/loadChannels', {
        botId: this.currentBot.uniqueBotId,
      });

      this.channelId = this.$router.currentRoute?.params?.id;

      this.resetChannel();
      this.isLoading = false;
    },
    resetChannel() {
      if (!this.channel) return;

      this.displayNameInput = this.channel.displayName;
      this.selectedType = this.channel.type;
      this.selectedLang = this.channel.language;
    },
    async saveChannel() {
      // TBD
    },
  },
};
</script>

<template>
  <div>
    <v-app style="overflow: hidden; height: 100vh; max-height: 100vh">
      <v-app-bar class="moin-fresh" app color="white">
        <div class="pa-2">
          <a href="https://moin.ai">
            <img src="@/assets/moin-logo.svg" height="38px" />
          </a>
        </div>
        <div class="ml-3 mr-4" style="height: 30px">
          <v-divider vertical />
        </div>
        <h1
          class="primary--text"
          :class="
            $vuetify.breakpoint.smAndDown
              ? 'text-body-1 font-weight-bold'
              : 'text-h6'
          "
          style="line-height: 1"
        >
          {{ $t('demo.appTitle') }}
        </h1>
        <div
          v-if="event"
          style="position: absolute; right: 8px; top: 0px; height: 100%"
          class="d-flex items-center"
        >
          <template v-if="event === eventStrings.ccw">
            <div
              class="demo-ccw-logo"
              :class="[
                $vuetify.breakpoint.xs ? 'demo-ccw-logo--xs' : 'mr-3 mt-1',
              ]"
            >
              <img height="44" width="auto" src="/demo-event/ccw-logo.png" />
            </div>
          </template>
          <template v-if="event === eventStrings.ccw2024">
            <div
              class="demo-ccw-logo"
              :class="[
                $vuetify.breakpoint.xs ? 'demo-ccw-logo--xs' : 'mr-3 mt-1',
              ]"
            >
              <img height="44" width="auto" src="/demo-event/logo_ccw_2024.png" />
            </div>
          </template>
          <template v-if="event === eventStrings.internorga">
            <img
              :height="$vuetify.breakpoint.xs ? 20 : 40"
              width="auto"
              src="/demo-event/internorga-logo.png"
            />
          </template>
          <template v-if="event === eventStrings.omr">
            <img
              height="38"
              width="auto"
              src="/demo-event/omr-logo.png"
              class="mr-2"
            />
          </template>
          <template v-if="event === eventStrings.sesu">
            <img
              height="38"
              width="auto"
              src="/demo-event/sesu-logo.png"
              class="mr-2"
            />
          </template>
        </div>
        <v-progress-linear
          absolute
          indeterminate
          style="bottom: 0; left: 0; right: 0"
          v-if="loading"
          color="secondary"
        />
      </v-app-bar>
      <v-main app class="demo-main moin-fresh">
        <div class="demo d-flex">
          <template v-if="step === stepSlugs.stepCompany">
            <DemoStepCompany />
          </template>
          <template v-else>
            <div v-if="$vuetify.breakpoint.mdAndUp" class="demo-sidebar">
              <DemoSidebar />
            </div>
            <div class="demo-content flex-grow-1">
              <div class="demo-slider">
                <div id="demo-slider-top"></div>
                <DemoContent />
              </div>
            </div>
          </template>
        </div>
      </v-main>
    </v-app>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import DemoSidebar from '@/components/demo/DemoSidebar.vue';
import DemoStepCompany from '@/components/demo/DemoStepCompany.vue';
import DemoContent from '@/components/demo/DemoContent.vue';
import { stepSlugs } from '@/components/demo/stepSlugs';
import BtnSquare from '@/components/common/BtnSquare.vue';

import { eventStrings } from './eventStrings';

export default {
  name: 'DemoComp',
  components: {
    DemoStepCompany,
    DemoSidebar,
    DemoContent,
    BtnSquare,
  },
  props: {
    event: String,
  },
  data: () => ({
    valid: true,
    stepSlugs,
    eventStrings,
  }),
  created() {
    // get query params from url and store them in vuex
    const urlParams = new URLSearchParams(window.location.search);
    // a object with all query params
    const queryParams = Object.fromEntries(urlParams.entries());
    // store query params in vuex
    this.$store.commit('demo/setQueryParams', queryParams);

    // if event is set, set the event in vuex
    if (this.event) {
      this.$store.commit('demo/setValue', { key: 'event', value: this.event });
    }
  },
  mounted() {
    this.initTagScript();
    this.initUserIds();
  },
  computed: {
    ...mapGetters('demo', ['step', 'loading', 'fingerprintId']),
  },
  watch: {
    step() {
      this.scrollUp();
    },
  },
  methods: {
    async initUserIds() {
      this.$store.commit('demo/setValue', { key: 'loading', value: true });
      await this.$store.dispatch('demo/initializeUserIds');
      this.$store.commit('demo/setValue', { key: 'loading', value: false });
    },
    initTagScript() {
      const script = document.createElement('script');
      script.setAttribute('src', '/demo-event/tag-script.js');
      if (!this.event) {
        document.head.appendChild(script);
      }
    },
    async scrollUp() {
      await new Promise((resolve) => setTimeout(resolve, 200));
      let el = document.querySelector('#demo-slider-top');
      el?.scrollIntoView({ behavior: 'smooth' });
    },
  },
};
</script>
<style lang="scss" scoped>
:deep(.v-application--wrap) {
  max-height: 100vh;
}

.demo-main {
  height: 100%;
  max-height: 100%;
  position: relative;
  overflow: hidden;
}
.demo {
  background-color: white;
  height: 100%;
}

.demo-sidebar {
  width: 30vw;
  background-color: var(--v-primary-base);
  height: 100%;
}

.demo-content {
  height: 100%;
  max-height: 100%;
  position: relative;
  overflow: hidden;
}
.demo-slider {
  overflow: hidden;
  overflow-y: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.demo-ccw-logo {
  display: inline-block;
  overflow: hidden;
}

.demo-ccw-logo--xs {
  height: 44px;
  width: 44px;
  transform: scale(0.7);
}
</style>

<template>
  <HubBox
    :title="$t('common.general')"
    boxId="intents-general"
  >
    <div class="text-body-2">
      {{$t('intent.general.description')}}
    </div>

    <div class="mt-4">
      <p class="primary--text text--lighten-3 mb-1 text-body-2">
        {{ $t('intent.name') }}
      </p>
      <div>{{ intent.displayName }}</div>
    </div>

    <div class="mt-4">
      <p class="primary--text text--lighten-3 mb-1 text-body-2">
        {{ $t('intent.description') }}
      </p>
      <div>{{ intent.description }}</div>
    </div>

    <div class="mt-6">
      <v-btn
        outlined
        color="primary lighten-3"
        @click="editDialogOpen = true"
      >
        {{ $t('common.edit') }}
        <v-icon right>edit</v-icon>
      </v-btn>
    </div>

    <HubDialog
      :value="editDialogOpen"
      :primary-label="$t('common.save')"
      primary-icon="save"
      :secondary-label="$t('common.cancel')"
      secondary-icon="close"
      title="Generelles bearbeiten"
      @onPrimary="saveEdit"
      @onSecondary="cancelEdit"
      modal
    >
      <div>
        <v-row class="mt-4">
          <v-col cols="12">
            <div></div>
            <v-text-field
              class="mb-2"
              v-model="copyIntent.displayName"
              outlined
              color="secondary darken-2"
              autofocus
              hide-details
            />
            <InfoBox
              :text="$t('intentNew.stepName.displayName.hint')"
              :icon="null"
            />
          </v-col>
        </v-row>
        <v-row class="mt-4">
          <v-col cols="12">
            <v-textarea
              class="mb-2"
              v-model="copyIntent.description"
              outlined
              color="secondary darken-2"
              hide-details
              :readonly="!isAdmin"
              :disabled="!isAdmin"
            />
            <InfoBox
              :text="$t('intentNew.stepName.description.hint')"
              :icon="null"
            />
          </v-col>
        </v-row>
      </div>
    </HubDialog>
  </HubBox>
</template>

<script>
import HubBox from '@/components/hub/HubBox.vue';
import InfoBox from '@/components/common/InfoBox.vue';
import HubDialog from '../hub/HubDialog.vue';
import ProductService from '@/services/product';

import { mapGetters } from 'vuex';

export default {
  name: 'IntentGeneral',
  components: {
    HubBox,
    InfoBox,
    HubDialog,
  },
  props: {
    intent: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      editDialogOpen: false,
      copyIntent: null,
    };
  },
  computed:{
    ...mapGetters('auth', ['isAdmin']),
  },
  created() {
    this.copyIntent = { ...this.intent };
  },
  methods: {
    cancelEdit() {
      this.copyIntent = { ...this.intent };
      this.editDialogOpen = false;
    },
    async saveEdit() {
      // save to local store
      this.intent.displayName = this.copyIntent.displayName;
      this.intent.description = this.copyIntent.description;

      await ProductService.updateIntent(this.copyIntent);
      this.$store.commit('intents/setIntent', this.copyIntent);
      this.editDialogOpen = false;
    },
  },
};
</script>

<template>
  <div>
    <v-row v-if="teaser && teaser.payload">
      <v-col cols="12">
        <h3 class="text-h6">{{ $t('teasers.integrations.title') }}</h3>
        <div class="text-body-1">{{ $t('teasers.integrations.info') }}</div>
        <v-switch
          class="mt-4"
          v-model="isOptionsActivated"
          :color="isOptionsActivated ? 'green' : 'grey'"
          :label="$t('teasers.integrations.switchLabel')"
          inset
          @change="updateTeaserIntegrations"
        />
        <InfoBox :text="$t('teasers.integrations.switchHint')" />
      </v-col>
      <v-col cols="12" v-if="isOptionsActivated">
        <v-select
          class="mb-4"
          :items="options"
          :label="$t('teasers.integrations.kindOfConstraint')"
          v-model="selectedListOption"
          @change="changeTeaserListOption"
        />
        <!-- allow list -->
        <template v-if="selectedListOption">
          <div v-for="(item, index) in urls" :key="index">
            <v-divider class="mt-2" />
            <div v-if="index > 0" class="ti-or">
              <div class="grey--text text-body-2 ti-or-inner">
                {{ $t('teasers.integrations.or') }}
              </div>
            </div>
            <v-radio-group
              class="mb-2"
              :value="getUrlConstraintType(item)"
              row
              dense
              hide-details
              @change="(type) => changeUrlConstraint(type, index)"
            >
              <v-radio
                :label="
                  $t(
                    `teasers.integrations.urlConstraintName.${urlConstraintTypes.EXACT}`
                  )
                "
                :value="urlConstraintTypes.EXACT"
              ></v-radio>
              <v-radio
                :label="
                  $t(
                    `teasers.integrations.urlConstraintName.${urlConstraintTypes.SUBPAGES}`
                  )
                "
                :value="urlConstraintTypes.SUBPAGES"
              ></v-radio>
              <v-radio
                :label="
                  $t(
                    `teasers.integrations.urlConstraintName.${urlConstraintTypes.KEYWORD}`
                  )
                "
                :value="urlConstraintTypes.KEYWORD"
              ></v-radio>
            </v-radio-group>
            <div class="d-flex">
              <v-text-field
                class="mb-4"
                outlined
                dense
                hide-details
                type="text"
                :value="cleanRegexToUrl(item)"
                @input="(url) => updateUrl(url, index)"
                :label="
                  $t(
                    `teasers.integrations.urlConstraintLabel.${getUrlConstraintType(
                      item
                    )}`
                  )
                "
              ></v-text-field>
              <div class="pl-2">
                <BtnSquare
                  icon="mdi-delete"
                  @click="deleteUrlFromList(index)"
                  :disabled="index === 0 && urls.length === 1"
                />
              </div>
            </div>
          </div>
        </template>

        <v-divider />

        <v-btn
          class="mt-4"
          outlined
          @click="addUrl"
          :disabled="!selectedListOption"
        >
          {{ $t('teasers.integrations.addUrl') }}
          <v-icon outlined right>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import InfoBox from '@/components/common/InfoBox.vue';
import BtnSquare from '@/components/common/BtnSquare.vue';

export default {
  name: 'TeasersIntegrations',
  components: {
    InfoBox,
    BtnSquare,
  },
  props: {
    teaser: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isInitialized: false,
      isOptionsActivated: false,
      urls: [],
      selectedListOption: null,
      ALLOWLIST: 'allowlist',
      BLOCKLIST: 'blocklist',
      urlConstraintTypes: {
        EXACT: 'exact',
        SUBPAGES: 'subpages',
        KEYWORD: 'keyword',
      },
    };
  },
  computed: {
    options() {
      return [
        {
          text: this.$t('teasers.integrations.optionAllowList'),
          value: this.ALLOWLIST,
        },
        {
          text: this.$t('teasers.integrations.optionBlockList'),
          value: this.BLOCKLIST,
        },
      ];
    },
  },
  created() {
    this.init();
  },
  watch: {
    teaser() {
      if (this.teaser) {
        this.init();
      }
    },
  },
  methods: {
    init() {
      if (this.teaser?.allowList?.length) {
        this.selectedListOption = this.ALLOWLIST;
      } else if (this.teaser?.blockList?.length) {
        this.selectedListOption = this.BLOCKLIST;
      }

      if (this.selectedListOption) {
        this.isOptionsActivated = true;
        this.createLocalList();
      }
    },
    activateOptions() {
      this.isOptionsActivated = true;
    },
    addUrl() {
      // ^$ to get initial constraint type of EXACT
      this.urls.push('^$');
      this.updateTeaserList();
    },
    createLocalList() {
      this.urls = [];
      let list =
        this.selectedListOption === this.ALLOWLIST
          ? this.teaser.allowList
          : this.teaser.blockList;

      list.forEach((item) => {
        this.urls.push(item);
      });
    },
    updateTeaserList() {
      if (this.selectedListOption === this.ALLOWLIST) {
        this.$store.commit('teasers/updateAllowList', this.urls);
      } else if (this.selectedListOption === this.BLOCKLIST) {
        this.$store.commit('teasers/updateBlockList', this.urls);
      } else {
        this.$store.commit('teasers/deleteIntegrationLists');
      }
      this.$store.commit('teasers/checkIsDirty');
    },
    updateTeaserIntegrations(newValue) {
      if (newValue === false) {
        this.selectedListOption = null;
        this.urls = [];
        this.$store.commit('teasers/deleteIntegrationLists');
        this.$store.commit('teasers/checkIsDirty');
      } else {
        this.selectedListOption = this.ALLOWLIST;
        this.addUrl();
      }
    },
    changeTeaserListOption() {
      this.updateTeaserList();
    },
    deleteUrlFromList(index) {
      this.urls.splice(index, 1);
      this.updateTeaserList();
    },
    getUrlConstraintType(url) {
      if (url.includes('^') && url.includes('$')) {
        return this.urlConstraintTypes.EXACT;
      }
      if (url.includes('^')) {
        return this.urlConstraintTypes.SUBPAGES;
      }
      return this.urlConstraintTypes.KEYWORD;
    },
    changeUrlConstraint(type, urlIndex) {
      let urlString = this.urls[urlIndex];

      if (!urlString) return;

      this.$set(this.urls, urlIndex, this.parseUrlToRegex(urlString, type));
      this.updateTeaserList();
    },
    cleanRegexToUrl(urlString) {
      urlString = String(urlString).replace('^', '');
      urlString = urlString.replace('$', '');

      return urlString;
    },
    parseUrlToRegex(urlString, type) {
      urlString = this.cleanRegexToUrl(urlString);

      if (type === this.urlConstraintTypes.EXACT) {
        urlString = `^${urlString}$`;
      } else if (type === this.urlConstraintTypes.SUBPAGES) {
        urlString = `^${urlString}`;
      }

      return urlString;
    },
    updateUrl(urlString, index) {
      const type = this.getUrlConstraintType(this.urls[index]);
      this.$set(this.urls, index, this.parseUrlToRegex(urlString, type));
      this.updateTeaserList();
    },
  },
};
</script>
<style scoped>
.ti-or {
  position: relative;
  height: 0;
}

.ti-or-inner {
  position: absolute;
  top: -16px;
  left: 50%;
  background-color: #fff;
  padding: 4px;
  width: 50px;
  margin-left: -25px;
  text-align: center;
}
</style>

// The '_' is important [blockType] _ [variant]
// Todo rename media -> image
// Todo rename slides -> cards
export const blockTypes = {
  START_TAG_QUESTION: 'start_tagQuestion',
  START_YES_NO: 'start_yesNo',
  START_FREE_TEXT: 'start_freeText',
  START_EMAIL: 'start_email',
  START_VALIDABLE: 'start_validable',
  START_MULTIPLE_CHOICE: 'start_multipleChoice',
  START_FOLLOWUP: 'start_followup',
  START_FEEDBACK: 'start_feedback',
  START_NEXT: 'start_next', // aka happy path
  START_UNKNOWN_FILES: 'start_unknownFiles',
  START_SERVICE: 'start_service', // aka unhappy path
  START_LOADING: 'start_loading',
  START_TAG_ONBOARDING: 'start_tagOnboarding',
  START_FALLBACK: 'start_fallback',
  START_COMPLETION: 'start_completion',
  START_CANCEL: 'start_cancel',
  START_SMALLTALKS: 'start_smalltalks',
  START_ANSWER: 'start_answer',
  START_FORM: 'start_form',
  START_LIVECHAT: 'start_livechat',
  END_AHEAD: 'end_ahead',
  END_TURN: 'end_turn',
  END_LOADING: 'end_loading',
  END_GO_UP: 'end_goup',
  CONTENT_TEXT: 'content_text',
  CONTENT_TEXT_VARIANTS: 'content_textVariants',
  CONTENT_MEDIA: 'content_media',
  CONTENT_SLIDES: 'content_slides',
  CONTENT_LOADING: 'content_loading',
  SYSTEM_VARIABLE: 'system_variable',
  INPUT: 'input',
  SYSTEM: 'system',
  SYSTEM_VALIDABLE: 'system_validable',
  SYSTEM_WEBHOOK: 'system_webhook',
  SYSTEM_NOTIFICATION: 'system_notification',
  END: 'end',
  DELAY: 'delay',
  TREE_PARENTS: 'tree_parents',
};

// Old name concept, do not change!
export const actionTypes = {
  TEXT: 'text',
  CARDS: 'cards',
  IMAGE: 'image',
  VIDEO: 'video',
};

// ! Spelling according components/contentTemplates and template slug (Case sensitive!)
export const contentTypes = {
  SIMPLE_INFORMATION: 'SimpleInformation',
  MULTIPLE_CHOICE: 'MultipleChoiceInfo',
  FOLLOWUP: 'FollowupIntent',
  YES_NO_QUESTION: 'YesNoQuestionInfo',
  ANSWER_INFO: 'AnswerInfo',
  FEEDBACK: 'feedback',
  NONE: 'none',
  NEXT: 'Next',
  SERVICE: 'Service',
  SMALLTALKS: 'Smalltalks',
};

export const botFormTypes = {
  BOT_FORM_COMPLETION: 'BotFormCompletion',
  BOT_FORM_CANCEL: 'BotFormCancel',
}

export const modeTypes = {
  edit: 'edit',
  editTree: 'edit-tree',
  tree: 'tree',
  copyEditorial: 'copy-editorial',
};
<template>
  <div>
    <v-card>
      <v-card-title>Zendesk authorisieren via OAuth</v-card-title>
      <v-card-subtitle>
        Zendesk authorisieren damit via Botformulare die Daten in ein Zendesk
        Ticket übertragen werden können
      </v-card-subtitle>

      <v-form ref="form">
        <v-col>
          <v-text-field
            label="OAuth-URL"
            placeholder="OAuth-URL"
            v-model="oauthUrl"
            :rules="rules.oauthUrl"
          />
          <p class="primary--text">Format: https://company.zendesk.com<br/></p>
        </v-col>
        <v-col>
          <v-text-field
            label="Scopes"
            placeholder="scopes"
            v-model="scopes"
          />
        </v-col>
      </v-form>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-if="isConfigured" color="primary lighten-2" outlined small @click="resetOriginalConfig()" :disabled="!isChanged">
          Reset <v-icon right dark>refresh</v-icon>
        </v-btn>
        <v-btn color="success" small @click="oauth()" :disabled="!isValid">
          Auth <v-icon right dark>mdi-robot-love-outline</v-icon>
        </v-btn>
        <!-- <v-btn :disabled="true" outlined>auth<v-icon right dark>mdi-robot-love-outline</v-icon></v-btn> -->
      </v-card-actions>
    </v-card>

    <v-snackbar v-model="savingToast" :timeout="6000">
      <div class="d-flex align-center">
        <v-icon class="mr-2" color="green" type="success">check</v-icon>
        <div>Oauth success</div>
      </div>
    </v-snackbar>

    <v-snackbar v-model="savingToastError" :timeout="6000">
      <div class="d-flex align-center">
        <v-icon class="mr-2" color="red">mdi-alert-circle</v-icon>
        <div>{{ message || $t('common.notSavedError') }}</div>
      </div>
    </v-snackbar>
  </div>
</template>


<script>
import integrationTypeConsts from '@/components/integrations/integrationTypeConsts';
import { _rootConfig } from '@/components/integrations/integrationConstants';

export default {
  name: "ZendeskAuth",
  data() {
    return {
      provider: integrationTypeConsts.zendesk,

      key: 'default',
      oauthUrl: '', // in DB zendeskOAuthUrl
      scopes: '',
      clientId: 'zdg-moinai_zendesk_integration',
      originConfig: {},
      currConfig: {},

      message: '',
      savingToast: false,
      savingToastError: false,

      isValid: false,
      isConfigured: false,
      isChanged: false,

      rules: {
        oauthUrl: [
          (v) => /^(https):\/\/[^ "]+$/.test(v) || this.$t('integrations.rules.url'),
          (v) => /[A-z0-9]$/i.test(v) || `Not valid url. Url can't ends with special symbol!`,
        ],
      }
    };
  },
  computed: {
    localProviderConfigByKey() {
      return this.$store.getters['integrations/localProviderConfigByKey']({ provider: this.provider, key: this.key });
    }
  },
  methods: {
    async checkProvider() {
      this.isConfigured = await this.$store.dispatch('integrations/checkIfProviderExists', {
        provider: this.provider
      });
    },
    async fetchConfig() {
      this.originConfig = await this.$store.dispatch('integrations/fetchProviderVersions', {
        provider: this.provider, key: _rootConfig // Another key. Because this logic collects all root in reserved key
      });
    },
    parseConfig() {
      return {
        key: this.key,
        url: this.oauthUrl?.trim(),
        scope: this.scopes?.trim(),
      };
    },
    async resetOriginalConfig() {
      this.oauthUrl = this.originConfig?.url || '',
      this.scopes = this.originConfig?.scopes || 'tickets:write';
      // this.clientId = this.originConfig?.clientId || '';
      this.$store.commit('integrations/setProviderConfigLocal', {
        provider: this.provider, config: this.parseConfig(),
      });
      setTimeout(() => { this.isChanged = false }, 0);

    },
    async init() {
      await this.checkProvider();

      this.currConfig = this.localProviderConfigByKey || {}; // computed getter

      if (this.isConfigured && !Object.keys(this.originConfig).length) {
        await this.fetchConfig();
        if (!Object.keys(this.currConfig).length) this.currConfig = { ...this.originConfig };
      }

      this.oauthUrl = this.currConfig?.url || '';
      this.scopes = this.originConfig?.scopes || 'tickets:write';
      // this.clientId = this.originConfig?.clientId || '';
      setTimeout(() => { this.isChanged = this.originConfig?.url !== this.currConfig?.url; }, 0);
    },
    async oauth() {
      /*
        %3A: URL-encoded standard representation of the character ':'
        %253A: This appears to be double URL-encoded (in some cases)
      */
      const HUB_URL = process.env.VUE_APP_HUB_URL || 'https://hub.moin.ai';
      const redirectUrl = `${HUB_URL}/oauth/zendesk`;

      const scope = this.scopes;
      const clientId = this.clientId;
      const baseUrl = this.oauthUrl;

      this.$store.commit('integrations/setProviderConfigLocal', {
        provider: this.provider, config: this.parseConfig(),
      });

      const oauthUrl = `${baseUrl}/oauth/authorizations/new?response_type=code&redirect_uri=${redirectUrl}&client_id=${clientId}&scope=${scope}`;

      window.open(oauthUrl);
    },
    validate() {
      this.isValid = this.$refs.form.validate();
    },
    checkForLocalChange(val, oldVal) {
      if (val === oldVal) return;
      this.isChanged = true;
      this.isValid = this.$refs.form.validate();
    },
  },
  watch: {
    oauthUrl(val, oldVal) {
      this.checkForLocalChange(val, oldVal)
    },
  },
  created() {
    this.init();
  },
};
</script>
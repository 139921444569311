<template>
  <div>
    <v-btn depressed color="secondary darken-2" @click="dialogIsOpen = true">
      {{ $t('common.add') }}
      <v-icon right>mdi-account-plus</v-icon>
    </v-btn>
    <HubDialog
      :value="dialogIsOpen"
      :title="$t('botSettings.newUser.title')"
      asyncDialog
      primaryIcon="mdi-send"
      :primaryLabel="$t('botSettings.newUser.btnSend')"
      :secondaryLabel="$t('common.cancel')"
      @onSecondary="dialogIsOpen = false"
      @onPrimary="sendNewUser"
      :isLoading="isLoading"
      :disabled="!isValid"
      modal
    >
      <div class="text-body-1">
        {{ $t('botSettings.newUser.info') }}
      </div>
      <v-text-field
        v-model="emailInput"
        class="mt-8"
        :label="$t('common.eMailAddress')"
        type="email"
        prepend-inner-icon="mdi-at"
        :rules="inputRules"
        @update:error="(v) => (isValid = !v)"
        validate-on-blur
      />

      <v-radio-group v-model="selectedRole">
        <v-radio
          v-for="option in rolesOptions"
          :value="option.value"
          :key="option.value"
          :label="option.label"
        />
      </v-radio-group>
      <InfoBox :text="$t('botSettings.newUser.infoBox')" />
    </HubDialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import HubDialog from '@/components/hub/HubDialog.vue';
import InfoBox from '@/components/common/InfoBox.vue';
import UserService from '@/services/user';
import { getFeaturesByRole, rolesTypes } from '@/utils/userRoles';

export default {
  name: 'SettingsNewUser',
  components: {
    HubDialog,
    InfoBox,
  },
  props: {
    with2FA: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      emailInput: '',
      dialogIsOpen: false,
      selectedRole: 'spectator',
      isLoading: false,
      isValid: false,
    };
  },
  computed: {
    ...mapGetters('bots', ['currentBotId']),
    /**
     * Whether 2FA is required during SSO login
     * @returns {boolean}
     */
    withSSO2FA() {
      return !!this.$store.getters['bots/getBotSettings'](this.currentBotId)?.sso?.sso2FA;
    },
    inputRules() {
      const rules = [];

      const validMailAddress = (v) =>
        /^\S+@\S+\.\S+$/.test(v) || this.$t('botSettings.newUser.validRole');
      rules.push(validMailAddress);

      return rules;
    },
    rolesOptions() {
      return [
        {
          label: this.$t(`botSettings.roles.role.${rolesTypes.TESTER}`),
          value: rolesTypes.TESTER,
        },
        {
          label: this.$t(`botSettings.roles.role.${rolesTypes.VIEWER}`),
          value: rolesTypes.VIEWER,
        },
        {
          label: this.$t(`botSettings.roles.role.${rolesTypes.EDITOR}`),
          value: rolesTypes.EDITOR,
        },
        {
          label: this.$t(`botSettings.roles.role.${rolesTypes.OWNER}`),
          value: rolesTypes.OWNER,
        },
      ];
    },
  },
  methods: {
    async sendNewUser() {
      const features = getFeaturesByRole(this.selectedRole);

      if (this.with2FA) {
        features.push('2FA');
      }
      if (this.withSSO2FA) {
        features.push('sso2FA');
      }

      await UserService.requestInvite(
        this.emailInput,
        this.currentBotId,
        features
      );

      this.isLoading = false;
      this.dialogIsOpen = false;
      this.emailInput = '';

      this.$emit('onInvited');
    },
  },
};
</script>
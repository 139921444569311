const state = {
  // {[botId]: channelId}
  lastChannelId: {}
}

const getters = {
  /**
   * Return the channel ID from the URL query string
   * @returns {string|null}
   */
  queryChannelId() {
    return new URLSearchParams(window.location.search)?.get("channel") || null;
  }
}

const actions = {
  /**
   * Get the last selected channel for a bot by ID
   * @param {any} state
   * @param {string} botId
   * @returns {string|null}
   */
  lastSelectedChannelId({state}, botId) {
    return state.lastChannelId[botId] ?? null;
  }
}

const mutations = {
  /**
   * Sets which channel user last viewed
   * @param state
   * @param {{botId: string, channelId: string|null}} payload
   */
  setLastChannelId(state, payload) {
    state.lastChannelId[payload.botId] = payload.channelId;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
<template>
  <v-app style="max-height: 100vh; height: 100vh">
    <HubMenu />

    <Appbar />

    <HubList
      :sidebar-items="[
        {
          title: '',
          icon: 'info',
          name: 'info',
        },
      ]"
      :title="$t('forms.title')"
      :items="forms || []"
      :sidebar="true"
      :sidebar-width="5"
      @input="onSidebarChanged"
      :value="selectedForm"
    >
      <template v-slot:item-icon>
        <v-icon small color="primary lighten-3">list_alt</v-icon>
      </template>

      <template #[`sidebar.info`]="{ item }">
        <FormsGeneral v-if="item" :form="item" />
      </template>

      <template v-slot:toolbar>
        <v-btn
          v-if="isAdmin"
          color="admin lighten-1"
          text
          @click="
            newFormularAlert = false;
            showNewFormDialog = true;
          "
        >
          {{ $t('forms.newForm.btn') }}
          <v-icon class="me-1" right>mdi-plus-circle</v-icon>
        </v-btn>
      </template>
    </HubList>

    <!-- New Form Dialog -->
    <HubDialog
      v-model="showNewFormDialog"
      :title="$t('forms.newForm.title')"
      :primary-label="$t('common.create')"
      :secondary-label="$t('common.cancel')"
      primary-icon="add"
      :disabled="newFormular === ''"
      modal
      @onPrimary="newForm"
    >
      <v-text-field
        v-model="newFormular"
        autofocus
        :label="$t('common.name')"
      />
      <v-checkbox v-model="ratingForm" label="Rating Form" />
    </HubDialog>
  </v-app>
</template>

<script>
import HubMenu from '@/components/hub/HubMenu.vue';
import Appbar from '@/components/Appbar.vue';
import { mapGetters } from 'vuex';
import HubDialog from '@/components/hub/HubDialog.vue';
import HubList from '@/components/hub/HubList.vue';
import FormsGeneral from '@/components/forms/FormsGeneral.vue';

export default {
  name: 'Forms',
  components: {
    HubMenu,
    Appbar,
    HubDialog,
    HubList,
    FormsGeneral,
  },
  props: ['viewTab'],
  data() {
    return {
      showNewFormDialog: false,
      newFormular: '',
      newFormularAlert: false,
      selectedForm: null,
      ratingForm: false,
    };
  },
  computed: {
    ...mapGetters('bots', ['currentBotId']),
    ...mapGetters('forms', ['forms']),
    ...mapGetters('intents', ['intents', 'bookedIntents', 'suggested']),
    ...mapGetters('auth', ['isAdmin']),
    formsLoading() {
      return this.forms === false;
    },
  },
  methods: {
    async init() {
      this.$store.dispatch('forms/get', this.currentBotId);
    },
    async newForm() {
      const template = this.ratingForm ? 'BotFormRating' : 'BotForm';
      const response = await this.$store.dispatch('forms/add', {
        botId: this.currentBotId,
        displayName: this.newFormular,
        template,
      });
      if (response.status === 'error') {
        this.newFormularAlert = true;
      }
      this.newFormular = '';
      this.ratingForm = false;
    },

    onSidebarChanged(val) {
      if (val === null) {
        this.selectedForm = null;
      } else {
        this.selectedForm = val;
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    next(true);
    // called before the route that renders this component is confirmed.
    // does NOT have access to `this` component instance,
    // because it has not been created yet when this guard is called!
  },
};
</script>

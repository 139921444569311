<template>
  <div>
    <div v-if="hasError" class="text-center pa-10">
      <v-icon color="error" class="pr-2">mdi-alert</v-icon>Sorry, ein Fehler ist aufgetreten.
      <br />
      <br />
      <div class="text-body-2">
      Vermutlich haben Sie bereits zu viele Anfragen gestellt.

      </div>
    </div>
    <template v-else>
      <DemoStars />
      <div>
        <h1
          class="text-h5 moinblue--text mb-4"
          v-html="$t(`demo.${step}.loading.title`)"
        ></h1>
        <div v-html="$t(`demo.${step}.loading.body`)"></div>
      </div>
      <DemoCompanyUrl />
    </template>
  </div>
</template>
<script>
import DemoStars from '@/components/common/DemoStars.vue';
import DemoCompanyUrl from '@/components/demo/components/DemoCompanyUrl.vue';
import { mapGetters } from 'vuex';
export default {
  name: 'DemoStepLoad',
  components: {
    DemoStars,
    DemoCompanyUrl,
  },
  computed: {
    ...mapGetters('demo', ['step', 'hasError']),
  },
};
</script>

<template>
 
    <!-- Select Box -->
    <v-select
      flat
      hide-details
      :label="label"
      :value="value"
      @change="changeValue"
      :items="all"
      item-value="intent"
      item-text="displayName"
      :disabled="isDisabled"
      class="rounded-tl"
    >
    </v-select>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "IntentSelection",
  props: {
    exclude: Array,
    disabled: Boolean,
    value: String,
    intent: {
      type: Boolean,
      default: true
    },
    form: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters("bots", ["currentBotId"]),
    ...mapGetters("intents", ["intents",  'displayName']),
    ...mapGetters("forms", ["forms"]),
    all() {
      let allIntents = [];
      if (this.form && Array.isArray(this.forms)) {
        allIntents = allIntents.concat(this.forms);
      }

      if (this.intent && Array.isArray(this.intents)) {
        allIntents = allIntents.concat(this.intents);
      }

      if (this.exclude) {
        allIntents = allIntents.filter(a => {
          let exc = this.exclude.find(e => {
            return (e === a.intent && this.value !== e);
          });

          return !exc;
        })
      }
    
      allIntents = allIntents.map((a) => {
        return {
          ...a,
          displayName: this.displayName(a.intent, this.$vuetify.lang.current),
        }
      });

      return allIntents;
    },
    label() {
      let labels = [];
      if (this.intent) {
        labels.push('Thema')
      }
      if (this.form) {
        labels.push('Bot Formular');
      }

      return labels.join(' / ');
    },
    notAvailable() {
      if (this.value) {
        return !this.all.find(a => {
          return a.intent === this.value;
        })
      } else {
        return false;
      }
    },
    isDisabled() {
      return this.disabled || this.notAvailable;
    }
  },
  watch: {},
  methods: {
    changeValue(newValue) {
      this.$emit('input', newValue);
      this.$emit('change', newValue)
    }
  }
};
</script>
<style scoped scss></style>

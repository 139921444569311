const { LivechatProvidersEnum } = require("./livechatProvider.enum");

/**
 * @typedef {string} IntegrationEnum
 * @enum {IntegrationEnum}
 */
const IntegrationEnum = {
  crisp: LivechatProvidersEnum.crisp,
  freshchat: LivechatProvidersEnum.freshchat,
  greyhound: LivechatProvidersEnum.greyhound,
  hubspot: 'hubspot',
  livechat: LivechatProvidersEnum.livechat,
  moinLivechat: LivechatProvidersEnum.moinLivechat,
  salesforce: LivechatProvidersEnum.salesforce,
  snapengage: LivechatProvidersEnum.snapengage,
  userLike: LivechatProvidersEnum.userLike,
  webhook: 'webhook',
  zendeskLivechat: 'zChat',
  zendeskTicket: 'zendesk',
};

/**
 * Checks if Integration is available for general user (everyone except admin)
 * @param {string} integrationName 
 * @returns {boolean}
 */
const isAdminOnlyIntegration = (integrationName) => {
  // All integrations are for admin Only (view). Except:
  const exceptionSet = new Set([IntegrationEnum.hubspot]);

  if (!exceptionSet.has(integrationName)) return true;

  return false; // Available for other roles
};

module.exports = { IntegrationEnum, isAdminOnlyIntegration };

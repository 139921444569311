export const contentTreeProperties = {
  computed: {
    currentContentNode() {
      if (!this.contentTree) return {};

      return this.contentTree.findNode(this.box);
    }
  }
}

export const contentProperties = {
  computed: {
    templateContent() {
      if (!this.content || !this.box) return null;
      return this.content[this.box];
    },
  }
}

export const userInputMethod = {
  methods: {
    userInputAction(property) {
      const lastAction = this.content[this.box][property].actions
      .filter(answer => answer.content)
      .pop();

      return lastAction;
    },
    currentSelectedUserInputIndex(property) {
      if (this.options?.selectedPathes?.[this.box]?.[property]) {
        return this.options.selectedPathes[this.box][property];
      } else {
        return 0;
      }
    },
    currentSelectedUserInput(property) {
      
      if (this.currentSelectedUserInputIndex(property) === -1) {
        return null;
      }

      if (this.content?.[this.box]?.[property]?.actions) {
        const lastAction = this.userInputAction(property);
        
        if (lastAction) {
          return lastAction.content?.quick?.[this.currentSelectedUserInputIndex(property)];
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    userInputCount(property) {
      if (this.content?.[this.box]?.[property]?.actions) {
        // get last action in answers, that is has content property
        const lastAction = this.content[this.box][property].actions
          .filter(answer => answer.content)
          .pop();
        
        if (lastAction) {
          return lastAction.content?.quick?.length || 0;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    },
    updateUserInput(property, followup) {
      if (!followup || !followup.intent) return;
      const userInput = this.currentSelectedUserInput(property);

      if (userInput) {
        userInput.intent = followup.intent;
      }
    }
  }
}
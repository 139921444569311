<template>
  <v-tab-item transition="none" color="black" reverse-transition="none" value="editor">
    <v-card class="px-8 pt-4" color="#f0f0f0" >
      <h2>{{$t('channelSettings.appearance.themetype.sectionheadline')}}</h2>
      <ThemeSelection v-model="themetype" />
      <v-divider class="ma-8" color="#666"></v-divider>
      <h2>{{$t('channelSettings.appearance.themecolors.sectionheadline')}}</h2>
      <ColorSelection v-model="colorKeys" />
      <v-divider class="ma-8" color="#666"></v-divider>
      <h2>{{$t('channelSettings.appearance.widgetelements.sectionheadline')}}</h2>
      <SettingsEditor v-model="widgetbutton" 
        :title="$t('channelSettings.appearance.widgetelements.button.title')"
        :subtitle="$t(`channelSettings.appearance.widgetelements.button.subtitle`)"
        :explaination="$t(`channelSettings.appearance.widgetelements.button.explanantion`)"
        :colors="getThemeColors" />
      <v-divider></v-divider>
      <SettingsEditor
        v-model="widgetheader"
        :title="$t('channelSettings.appearance.widgetelements.widgetheader.title')"
        :subtitle="$t(`channelSettings.appearance.widgetelements.widgetheader.subtitle`)"
        :explaination="$t(`channelSettings.appearance.widgetelements.widgetheader.explanantion`)"
        :colors="getThemeColors"
        demoelement="DemoHeader"
      />
      <v-divider></v-divider>
      <SettingsEditor
        v-model="usermessage"
        :title="$t('channelSettings.appearance.widgetelements.usermessage.title')"
        :subtitle="$t(`channelSettings.appearance.widgetelements.usermessage.subtitle`)"
        :explaination="$t(`channelSettings.appearance.widgetelements.usermessage.explanantion`)"
        :colors="getThemeColors"
        demoelement="DemoMessage"
      />
    </v-card>
  </v-tab-item>
</template>

<script>
import { createHelpers } from 'vuex-map-fields';
import { mapGetters } from 'vuex'

import SettingsEditor from '@/components/channelSettings/SettingsEditor.vue';
import ThemeSelection from '@/components/channelSettings/ThemeSelection.vue';
import ColorSelection from '@/components/channelSettings/ColorSelection.vue';

// `fooModule` is the name of the Vuex module.
const { mapFields } = createHelpers({
  getterType: 'channelsettings/getField',
  mutationType: 'channelsettings/updateField',
});
export default {
  components: {
    SettingsEditor,
    ThemeSelection,
    ColorSelection,
    // VuePerfectScrollbar
  },
  computed: {
    colorKeys() {
      return Object.keys(this.themecolors);
    },
    ...mapGetters('channelsettings', ['getThemeColors']),
    ...mapFields({
      themetype: 'appeareance.themetype',
      widgetbutton: 'appeareance.button',
      widgetheader: 'appeareance.widgetheader',
      usermessage: 'appeareance.usermessage',
      themecolors: 'appeareance.themecolors',
    })
  },
  methods: {
  },
  data() {
    return {
    }
  }
};
</script>

<style>
</style>
import moment from 'moment'
import 'moment/locale/de'
import 'moment/locale/en-gb'

const NumbersUtil = {
  // Deprecated - use utils/formatDateTime.formatDateTime() instead
  formatDate(self, value, short) {
    let formatString = 'common.dateFormat'
    if (short) {
      formatString = 'common.dateFormatShort'
    }

      // set current locale
    if (typeof value !== 'string') {
      value.locale(self.$vuetify.lang.current)
      return value.format(self.$t(formatString))
    } else if (value) {
      const newValue = moment(String(value))
      newValue.locale(self.$vuetify.lang.current)
      return newValue.format(self.$t(formatString))
    }
  },
  formatTime(self, value) {
    // set current locale
    if (typeof value !== 'string') {
      value.locale(self.$vuetify.lang.current)
      return value.format(self.$t('common.timeFormat'))
    } else if (value) {
      const newValue = moment(String(value))
      newValue.locale(self.$vuetify.lang.current)
      return newValue.format(self.$t('common.timeFormat'))
    }
  },
}

export const lastTwelveWeekDates = Array.from({ length: 12 }, (v, k) => {
  return moment().subtract(k, 'weeks').toISOString()
}).reverse()

export const lastTwelveMonthDates = Array.from({ length: 12 }, (v, k) => {
  return moment().subtract(k, 'month').format('MMMM YY')
}).reverse()

export default NumbersUtil

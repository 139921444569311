<template>
  <div
    class="bar-percentage"
    :class="[
      small && 'bar-percentage--small',
      large && 'bar-percentage--large',
    ]"
  >
    <v-tooltip bottom :disabled="!tooltip">
      <template v-slot:activator="{ on: onTooltip, attrs: attrsTooltip }">
        <div
          :class="!noBackground && 'grey lighten-3'"
          v-on="onTooltip"
          v-bind="attrsTooltip"
        >
          <v-progress-linear
            :background-color="backgroundColor"
            :buffer-value="buffer"
            color="primary"
            :height="small ? 4 : large ? 20 : 10"
            :value="value"
          >
            <template v-slot:default="{ value }">
              <strong
                v-if="!noData && !hideText"
                :style="getProgressValueStyle(value)"
              >
                {{ value.toFixed(1) }}%
              </strong>
            </template>
          </v-progress-linear>
        </div>
      </template>
      <span v-if="!noData">{{ tooltip }}</span>
      <span v-else>{{ $t('common.nodata') }}</span>
    </v-tooltip>
  </div>
</template>
<script>
import { getProgressValueStyle } from '@/utils/charts';
export default {
  name: 'BarPercentage',
  props: {
    buffer: {
      type: Number,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
    small: {
      type: Boolean,
    },
    large: {
      type: Boolean,
    },
    noBackground: {
      type: Boolean,
    },
    tooltip: {
      type: String,
    },
    noData: {
      type: Boolean,
      default: false,
    },
    backgroundColor: {
      type: String,
      default: 'grey',
    },
    hideText: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getProgressValueStyle,
  },
};
</script>
<style scoped lang="scss">
.bar-percentage {
  :deep(.v-progress-linear) {
    overflow: visible !important;
  }
  :deep(.v-progress-linear) {
    overflow: visible !important;
    transition: 0.5s map-get($transition, 'ease-in-out');
  }
  :deep(.v-progress-linear--outer-value) {
    padding-bottom: $spacer * 4;
    margin-bottom: $spacer;
  }
  :deep(.v-progress-linear__background) {
    height: inherit;
  }
  :deep(.v-progress-linear__content) {
    justify-content: flex-start;
    strong {
      top: 12px;
      font-size: 14px;
      position: absolute;
      color: var(--v-primary-lighten2);
      transition: 0.5s map-get($transition, 'ease-in-out');
      transition-property: left, right;
    }
  }
}

.bar-percentage--small {
  :deep(.v-progress-linear__content) {
    strong {
      top: 4px;
    }
  }
}

.bar-percentage--large {
  :deep(.v-progress-linear__content) {
    strong {
      top: 22px;
    }
  }
}
</style>

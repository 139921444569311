import _ from 'lodash';
import CopyPasteUtil from '@/utils/copyPaste';

// This module is for copy & paste of answers, and their branches

const state = {
  answer: null,
  protocol: [],
  loadingStack: [],
};

const getters = {
  answerInClipboard: (state) => state.answer,
  loadingStack: (state) => state.loadingStack,
};

const actions = {
  /**
   *
   * @param {{
   *   currentBotId,
   *   intent,
   *   box,
   *   template
   * }} payload
   * @returns sring boxName
   */
  async cloneBox({ dispatch, commit }, payload) {
    const boxName = await dispatch(
      'content/createBox',
      {
        botId: payload.currentBotId,
        intent: payload.intent,
        channel: payload.channel || null,
        template: payload.template,
      },
      { root: true }
    );

    commit('addToProtocol', {
      boxOriginal: payload.box,
      boxCloned: boxName,
    });

    return boxName;
  },
  /**
   *
   * @param {{
   *   currentBotId,
   *   intent,
   *   targetIntent,
   *   box,  // starts with answer.box ->
   *   alreadyCloned,
   * }} payload
   * @returns sring boxName
   */
  async crawl({ state, rootGetters, dispatch, commit }, payload) {
    // Step 2. get box from protocol
    let boxOriginal = state.protocol.find(
      (item) => item.boxCloned === payload.box
    )?.boxOriginal;

    // first crawl
    if (!boxOriginal) boxOriginal = payload.box;

    // Step 2.1 get content of answer or sub-step
    const content = rootGetters['content/getContent'](
      payload.currentBotId,
      payload.intent,
      boxOriginal
    );
    // Step 3. create new box
    let boxName;

    // Clone box
    if (!payload.alreadyCloned) {
      boxName = await dispatch('cloneBox', {
        currentBotId: payload.currentBotId,
        intent: payload.targetIntent,
        box: boxOriginal,
        template: content.template,
      });
    } else {
      boxName = payload.box;
    }

    // Step 4. get content of new created box
    const boxContent = rootGetters['content/getContent'](
      payload.currentBotId,
      payload.targetIntent,
      boxName
    );

    if (content.answer)
      boxContent.answer = CopyPasteUtil.cloneAnswer(content.answer);
    if (content.answers)
      boxContent.answers = CopyPasteUtil.cloneAnswers(content.answers);
    if (content.question)
      boxContent.question = CopyPasteUtil.cloneQuestion(content.question);

    // Clone answer boxes
    if (boxContent.answers) {
      for (let a of boxContent.answers) {
        if (a.box) {
          const newBoxName = await dispatch('cloneBox', {
            currentBotId: payload.currentBotId,
            intent: payload.targetIntent,
            box: a.box,
            template: a.template,
          });

          a.box = newBoxName;
          commit('addToLoadingStack', newBoxName);
          await dispatch('crawl', {
            currentBotId: payload.currentBotId,
            intent: payload.intent,
            targetIntent: payload.targetIntent,
            channel: payload.channel || null,
            box: newBoxName,
            alreadyCloned: true,
          });
          commit('removeFromLoadingStack', newBoxName);
        }
      }
    }

    // Step 5. Check if box has followup. If so, template should be:
    // FollowupIntent || YesNoQuestionInfo || MultipleChoiceInfo || MultipleChoiceSlider
    if (content?.followup?.box) {
      // Step 6. Clone followup box
      const followupBoxName = await dispatch('cloneBox', {
        currentBotId: payload.currentBotId,
        intent: payload.targetIntent,
        box: content?.followup?.box,
        template: content?.followup.template,
      });

      // Step 6.1 put boxName into parent followup
      boxContent.followup = {
        box: followupBoxName,
        template: content.followup.template,
      };

      // Step 6.2 start next crawl loop with in crawl created box
      commit('addToLoadingStack', followupBoxName);
      await dispatch('crawl', {
        currentBotId: payload.currentBotId,
        intent: payload.intent,
        targetIntent: payload.targetIntent,
        channel: payload.channel || null,
        box: followupBoxName,
        alreadyCloned: true,
      });
      commit('removeFromLoadingStack', followupBoxName);
    } else {
      // Step 7. If no followup, check if box has followup template menu or feedback
      if (content?.followup?.template) {
        boxContent.followup.template = content.followup.template;
      }

      commit('resetProtocol');
    }

    return boxName;
  },
  /**
   *
   * @param {{
   *   targetIntent,
   *   channel,
   * }} payload
   * @returns
   */
  async answerFromClipboard({ state, commit, dispatch }, payload) {
    if (!state.answer || !payload.currentBotId) return null;

    const newAnswer = _.cloneDeep(state.answer);
    newAnswer.qr = `${newAnswer.qr} (Kopie)`;

    if (
      newAnswer.template === 'FollowupIntent' ||
      newAnswer.template === 'AnswerInfo'
    ) {
      // Step 1. first crawl with box of answer
      commit('addToLoadingStack', 'answer');
      newAnswer.box = await dispatch('crawl', {
        currentBotId: payload.currentBotId,
        intent: state.answer.intent,
        targetIntent: payload.targetIntent,
        channel: payload.channel || null,
        box: state?.answer?.box,
      });

      commit('removeFromLoadingStack', 'answer');
    }

    return newAnswer;
  },
};

const mutations = {
  copyAnswer(state, payload) {
    state.answer = payload.answer;
    state.answer.intent = payload.intent;
    state.answer.channel = payload.channel;
  },
  addToProtocol(state, payload) {
    state.protocol.push(payload);
  },
  resetProtocol(state) {
    state.protocol = [];
  },
  addToLoadingStack(state, box) {
    state.loadingStack.push(box);
  },
  removeFromLoadingStack(state, box) {
    const index = state.loadingStack.indexOf(box);
    if (index < 0) return;
    state.loadingStack.splice(index, 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

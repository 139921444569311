<template>
  <DemoStep slug="stepIntents">
    <!-- <DemoIntent v-for="item in intents" :key="item.id" :intent="item" class="mb-2" /> -->

    <div class="question-item d-flex flex-column">
      <!-- Container for icon and header -->
      <div class="header-container d-flex align-items-center">
          <v-icon color="primary lighten-3">mdi-check</v-icon>
          <div class="font-weight-bold text-body-1 primary--text ml-2">
              redaktionelle Antworten
          </div>
      </div>
      <!-- Description text -->
      <div class="text-body-2 grey--text mt-2">
        Mit redaktionellen Antworten können Sie Ihren Nutzern in vordefinierten Dialogen antworten. Damit behalten Sie die volle Kontrolle.
        <br /><br />
        Empfohlene Themen für redaktionelle Antworten für Ihre Webseite:
      </div>

      <ul class="product-advisors-list mt-2">
        <li v-for="topic in features.editorialTopics" :key="topic">{{topic}}</li>
     </ul>
  </div>

    <div class="question-item d-flex flex-column mt-3">
      <!-- Container for icon and header -->
      <div class="header-container d-flex align-items-center">
          <v-icon color="primary lighten-3">mdi-check</v-icon>
          <div class="font-weight-bold text-body-1 primary--text ml-2">
              Knowledge Base
          </div>
      </div>
      <!-- Description text -->
      <div class="text-body-2 grey--text mt-2">
        Mit der Knowledge Base und generativer KI kann der Chatbot auf Basis von Webseiten, Dokumenten und Wissensdatenbanken passend zur Anfrage des Nutzers die richtige Antwort geben.
      
        <br /><br />
        Eine generierte Antwort auf Basis Ihrer Webseite:
      </div>
      
      <div class="example-request mt-2">
        <div class="request">
            <strong>Anfrage:</strong> "{{ features.answer.query }}"
        </div>
        <div class="response mt-1">
            <strong>Antwort:</strong> "{{ features.answer.answer }}"
        </div>
    </div>
  </div>

  <div v-if="features.product_advisor" class="question-item d-flex flex-column mt-3 highlight-item">
      <!-- Container for icon and header -->
      <div class="header-container d-flex align-items-center">
          <v-icon color="#8046eb">mdi-check</v-icon>
          <div class="font-weight-bold text-body-1 primary--text ml-2">
            moinAI Produktberater
          </div>
      </div>
      <!-- Description text -->
      <div class="text-body-2 grey--text mt-2">
        Mit dem moinAI Produktberater können Sie Ihren Nutzern eine interaktive Beratung anbieten, um das passende Produkt zu empfehlen. Fügen Sie für jede Ihrer Produktkategorien einen Berater hinzu. <br /> <br />Importieren Sie einfach Ihre Produktkategorien und Produkte und lassen Sie moinAI den Rest erledigen.
      
        <br /><br />
        Empfohlene Produktberater für Ihre Webseite:
      </div>

      <ul class="product-advisors-list mt-2">
        <li v-for="advisors in features.product_advisors" :key="advisors">{{ advisors }}</li>
     </ul>
  </div>

  <div v-if="features.livechat" class="question-item d-flex flex-column mt-3">
      <!-- Container for icon and header -->
      <div class="header-container d-flex align-items-center">
          <v-icon color="primary lighten-3">mdi-check</v-icon>
          <div class="font-weight-bold text-body-1 primary--text ml-2">
            moinAI Livechat
          </div>
      </div>
      <!-- Description text -->
      <div class="text-body-2 grey--text mt-2">
        Mit dem moinAI Livechat können Sie Ihren Nutzern einen direkten Kontakt mit Ihren Mitarbeitern ermöglichen. Der Chatbot kann die Übergabe dann einleiten, wenn es Sinnvoll ist. So sparen Sie wertvolle Zeit ihrere Mitarbeiter.
      </div>
  </div>



    <DemoBtns @onBack="back()" @onNext="next()" />
  </DemoStep>
</template>
<script>
import { mapGetters } from 'vuex';
import DemoStep from '@/components/demo/DemoStep.vue';
import DemoIntent from '@/components/demo/components/DemoIntent.vue';
import DemoBtns from '@/components/demo/components/DemoBtns.vue';
import {stepSlugs} from '@/components/demo/stepSlugs';

export default {
  name: 'DemoStepIntents',
  components: { DemoStep, DemoIntent, DemoBtns },
  computed: {
    ...mapGetters('demo', ['intents', 'features']),
  },
  methods: {
    back() {
      this.$store.commit('demo/setValue', {
        key: 'step',
        value: stepSlugs.stepQuestions,
      });

      this.$store.commit('demo/setValue', {
        key: 'isQuestionsDirty',
        value: false,
      });
    },
    next() {
      this.$store.commit('demo/setValue', {
        key: 'step',
        value: stepSlugs.stepContact,
      })
    },
  }
};
</script>
<style lang="scss" scoped>
.question-item {
    border-radius: $spacer;
    padding: $spacer * 3 $spacer * 4;
    border: 1px solid #E0E0E0;
    display: flex;
    flex-direction: column; /* Ändert die Ausrichtung der Kinder auf vertikal */
}

.highlight-item {
  border: 1px solid #8046eb;
}

.product-advisors-list {
    list-style: none; /* Entfernt die Aufzählungszeichen */
    padding-left: 0; /* Entfernt den linken Abstand */
    margin-top: 0; /* Optional: Entfernt den oberen Abstand, falls nötig */
}

.product-advisors-list li {
    padding: 4px 0; /* Fügt vertikalen Abstand zwischen den Elementen hinzu */
    border-bottom: 1px solid #e0e0e0; /* Fügt eine Trennlinie zwischen den Elementen hinzu */
}

.product-advisors-list li:last-child {
    border-bottom: none; /* Entfernt die untere Linie vom letzten Element */
}

.example-request {
    background-color: #f9f9f9; /* Leichter Hintergrund für Kontrast */
    border-left: 4px solid #fa7a59; /* Blaue Linie links als Hervorhebung */
    padding: 10px 15px; /* Innenabstand */
    border-radius: 4px; /* Ecken abrunden */
    margin-top: 10px; /* Abstand nach oben */
}

.request, .response {
    padding: 5px 0; /* Vertikaler Abstand */
}


</style>


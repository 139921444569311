export default {
  createImageURL(file, cb) {
    const url = URL.createObjectURL(file);

    const image = new Image();
    image.onload = function() {
      const canvas = document.createElement('canvas');

      canvas.width = this.width; // need this width for the dimension of the loaded image
      canvas.height = this.height;

      const context = canvas.getContext('2d');
      // draw image in canvas starting left-0 , top - 0
      context.drawImage(image, 0, 0, this.width, this.height);
      // console.log('url', canvas.toDataURL())
      cb(canvas.toDataURL());
    };
    image.src = url;
  },
  b64toBlob(dataURI) {
    const byteString = atob(dataURI.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new File([ab], 'avatar.png', { type: 'image/png' });
  },
};

<template>
  <v-card flat>
    <v-card-title class="text-h5 px-0">
      {{ displayName(intent.intent, user.language) }}
      <ChipIcon
        class="ml-2"
        v-if="isOriginIntent(intent.intent)"
        icon="model_training"
        color="secondary darken-2"
        :text="$t('intent.aiTemplate.label')"
      />
    </v-card-title>

    <div class="pb-6">
      <p class="body-text-1 mb-0">
        {{ description(intent.intent, user.language) }}
      </p>
      <div
        v-if="isAutoTranslated(intent.intent, user.language)"
        class="text-body-2 grey--text mt-2"
      >
        {{ $t('common.autoTranslated') }}
      </div>
    </div>

    <div>
      <IntentStatusSuggested
        v-if="intent.suggested"
        :intent="intent"
        :status="status"
        class="mb-4"
      />
      <IntentStatus v-else :intent="intent" class="mb-4" />

      <IntentSamples :intent="intent" class="mb-4" />

      <IntentRelevance
        v-if="!inReview && intent.template !== 'Smalltalks'"
        :channel="null"
        :intent="intent.intent"
        :bot-id="currentBotId"
        class="mb-4"
      />

      <IntentFeedback
        v-if="!inReview && !noMeasurement && !isConversion"
        :channel="null"
        :intent="intent.intent"
        :bot-id="currentBotId"
        class="mb-4"
      />

      <IntentConversion
        v-if="!noMeasurement && isConversion && false"
        :channel="currentChannels[0].channel"
        :intent="intent.intent"
        :bot-id="currentBotId"
        class="mb-4"
      />

      <IntentAccuracy
        v-if="isAdmin && !noMeasurement"
        :channel="null"
        :intent="intent.intent"
        :bot-id="currentBotId"
        class="mb-4"
      />

      <IntentKeywords
        v-if="
          !intent.intent.startsWith('smalltalk_') &&
          !isOriginIntent(intent.intent) &&
          !isOriginIntent(intent.intent, 'smalltalks') &&
          hasKeywordMatchFeature
        "
        class="mb-4"
        :intent="intent"
        :botId="currentBotId"
      />

      <IntentGeneral :intent="intent" class="mb-4" />

      <IntentRelations v-if="isAdmin" :intentId="intent.intent" class="mb-4" />

      <IntentK2Names v-if="isAdmin" :intent="intent" class="mb-4"/>

      <div v-if="(isAdmin || (!hasLegacyModel && isOwner)) && intent.template !== 'Smalltalks'">
        <v-divider class="my-8" />

        <v-btn v-if="!intent.suggested" :color="!hasLegacyModel ? 'error lighten-1' : 'admin lighten-1'" depressed @click="deleteMode = true">
          {{ $t('common.delete') }}
        </v-btn>
        <v-btn v-else-if="isAdmin" color="admin lighten-1" depressed @click="deleteMode = true">
          {{ $t('common.delete') }}
        </v-btn>
        <v-btn
          v-if="inReview && isAdmin"
          class="ml-2"
          outlined
          color="admin lighten-1"
          @click="
            isApproveOpen = true;
            approveCheck();
          "
        >
          Approval
        </v-btn>
        <IntentReviewAssistant
          v-if="isAdmin"
          :open="isApproveOpen"
          @onClose="isApproveOpen = false"
          @onApprove="approve"
          :intent="intent"
        />
      </div>
    </div>

    <HubDialog
      v-model="deleteMode"
      :title="$t('intent.deleteHeadline')"
      primary-icon="delete"
      :primary-label="$t('common.delete')"
      primaryColor="error lighten-1"
      @onPrimary="deleteIntent"
    >
      <span v-html="$t('intent.deleteConfirmation', { name: intent.displayName })"></span>
    </HubDialog>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { ProductService } from '@/services/product';
import { StatsService } from '@/services/stats';
import HubDialog from '@/components/hub/HubDialog.vue';
import IntentStatus from '@/components/intent/IntentStatus.vue';
import IntentStatusSuggested from '@/components/intent/IntentStatusSuggested.vue';
import IntentConversion from '@/components/intent/IntentConversion.vue';
import IntentFeedback from '@/components/intent/IntentFeedback.vue';
import IntentRelevance from '@/components/intent/IntentRelevance.vue';
import IntentAccuracy from '@/components/intent/IntentAccuracy.vue';
import ChipIcon from '@/components/common/ChipIcon.vue';
import IntentSamples from '@/components/intent/IntentSamples.vue';
import IntentKeywords from '@/components/intent/IntentKeywords.vue';
import IntentGeneral from '@/components/intent/IntentGeneral.vue';
import IntentRelations from '@/components/intent/IntentRelations.vue';
import IntentReviewAssistant from '@/components/intent/IntentReviewAssistant.vue';
import IntentK2Names from '@/components/intent/IntentK2Names.vue';
import IntentUtil from '@/utils/intent';
import IntentService from '@/services/intents';

export default {
  name: 'IntentsItemInfo',
  components: {
    HubDialog,
    IntentStatus,
    IntentStatusSuggested,
    IntentSamples,
    IntentConversion,
    IntentFeedback,
    IntentRelevance,
    IntentAccuracy,
    IntentK2Names,
    ChipIcon,
    IntentKeywords,
    IntentGeneral,
    IntentRelations,
    IntentReviewAssistant,
  },
  props: {
    intent: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      creating: false,
      status: [],
      refMounted: [],
      isLoading: true,
      suggestions: null,
      deleteMode: false,
      statusTypes: IntentUtil.statusTypes,
      isApproveOpen: false,
      approveChecks: [],
    };
  },
  computed: {
    ...mapGetters('auth', ['isAdmin', 'isOwner', 'isSimpleEdit', 'user']),
    ...mapGetters('intents', [
      'intents',
      'isOriginIntent',
      'displayName',
      'description',
      'isAutoTranslated',
    ]),
    ...mapGetters('bots', [
      // create properties from auth store
      'currentChannels',
      'currentBotId',
      'hasKeywordMatchFeature',
      'hasLegacyModel'
    ]),
    noMeasurement() {
      if (
        this.isLoading ||
        this.intent.template === 'Smalltalks' ||
        this.intent.suggested
      ) {
        return true;
      }

      const forward = this.status.every((state) => state.forward);

      return forward;
    },
    isConversion() {
      // TODO Channel Selection
      return (
        this.intent &&
        this.intent.payload &&
        this.intent.payload.measurement === 'conversion'
      );
    },
    inReview() {
      return this.intent?.payload?.inReview;
    },
    isDemoLicence() {
      const currLicence = this.$store.getters['bots/currentLicence'];
      return currLicence?.package === 'demo';
    },
  },
  async created() {
    const $this = this;
    ProductService.getIntentDetails(
      this.currentBotId,
      this.intent.template,
      this.intent.intent
    ).then((result) => {
      if (result.status === 'ok') {
        $this.status = Object.keys(result.state.channels).map((key) => ({
          channelId: key,
          state: result.state.channels[key].state,
          forward: result.state.channels[key].forward,
        }));
      }
      $this.isLoading = false;
    });

    StatsService.getSuggestionFrequency(
      this.currentBotId,
      this.intent.intent
    ).then((result) => {
      $this.suggestions = result;
    });
  },
  methods: {
    ...mapActions('intents', ['updateIntent']),
    channelDisplayName(channelId) {
      if (this.currentChannels && Array.isArray(this.currentChannels)) {
        const channel = this.currentChannels.find(
          (c) => c.channelId === channelId
        );

        if (channel && channel.displayName) {
          return channel.displayName;
        }
      }

      return channelId;
    },
    deleteIntent() {
      this.$store.dispatch('intents/deleteIntent', {
        botId: this.currentBotId,
        intent: this.intent.intent,
      });
    },
    openCreateDialog(intent) {
      this.isSuggestionDialogOpen = true;
      this.suggestedIntent = intent;
    },
    async approveCheck() {
      for (let text of this.intent.samples) {
        const nlpCheck = await ProductService.nlpCheck(
          this.currentBotId,
          text,
          true,
          true
        );
        const searchResults = await IntentService.searchIntent(
          this.currentBotId,
          text
        );
        if (searchResults.all && searchResults.all.length > 0) {
          this.approveChecks.push({
            text,
            best: searchResults.all.slice(0, 3),
            nlpCheck,
          });
        } else {
          this.approveChecks.push({ text, best: [], nlpCheck });
        }
      }
    },
    async approve() {
      this.isLoading = true;
      await ProductService.setInReview(this.currentBotId, this.intent.intent, false);
      await this.$store.dispatch('intents/get', this.currentBotId);
      this.isLoading = false;
      this.isApproveOpen = false;
    },
  },
};
</script>

<style scoped scss>
.list-enter-active,
.list-leave-active {
  transition: all 0.2s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(20px);
}
</style>

<template>
  <div
    class="px-4 pt-8 pb-4 mx-auto moin-fade-in d-flex items-center justify-center"
    style="height: 100%; max-width: 400px; max-height: 1000px"
  >
    <div>
      <DemoMoinAvatarBubble
        v-if="!bubbleAni"
        class="mx-auto mb-4 moin-fade-in"
        :text="knowledge[itemIndex]"
      />
      <DemoMoinAvatar class="mb-8 mx-auto" />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import DemoMoinAvatar from '@/components/demo/components/DemoMoinAvatar.vue';
import DemoMoinAvatarBubble from '@/components/demo/components/DemoMoinAvatarBubble.vue';

const knowledge = [
  'moinAI nutzt keine Cloud-Services von US-Firmen und ist komplett DSGVO-konform.',
  'Unternehmen automatisieren im Durchschnitt 75% ihrer Chatanfragen mit moinAI.',
  'Integrationen in Tools wie Zendesk, Livechat und Co sind problemlos möglich.',
  'moinAI automatisiert nicht nur Kundenservice, sondern kann auch spannende Customer Insights generieren.',
  'Bei moinAI hat jedes Unternehmen eine:n feste:n CSM-Ansprechpartner:in.',
  'moinAI wurde in Hamburg entwickelt und wird auf europäischen Servern gehostet.',
  "Die Implementierung von moinAI benötigt keine IT Skills. Zwei Zeilen Code. That's it.",
  'Wenn Ihr Chatbot mal nicht weiter weiß, kann das Gespräch an einen Menschen übergeben werden.',
];

export default {
  name: 'DemoSidebar',
  components: {
    DemoMoinAvatar,
    DemoMoinAvatarBubble,
  },
  computed: {
    ...mapGetters('demo', ['step']),
  },
  created() {
    this.nextItem();
  },
  data() {
    return {
      knowledge,
      itemIndex: -1,
      bubbleAni: false,
    };
  },
  methods: {
    async nextItem() {
      this.bubbleAni = true;
      await this.$nextTick();
      this.bubbleAni = false;
      this.itemIndex = (this.itemIndex + 1) % this.knowledge.length;
      setTimeout(() => {
        this.nextItem();
      }, 10000);
    },
  },
};
</script>

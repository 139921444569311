<template>
  <HubDialog
    :value="isOpen"
    :primary-label="$t('common.accept')"
    primary-icon="mdi-plus"
    :title="$t('intentsSuggestion.title')"
    modal
    @onSecondary="$emit('onClose')"
    @onPrimary="createIntentFromSuggestion"
    :disabled="!isQuotaOk"
  >
    <div
      class="text-body-1"
      v-html="
        $t('intentsSuggestion.text', {
          displayName: intent ? displayName(intent.intent, $vuetify.lang.current) : '',
        })
      "
    ></div>
    <div class="pt-6" v-if="isAdmin">
      <InfoBox
        v-if="quota"
        :text="
          isQuotaOk
            ? $t('intentsSuggestion.quotaInfoOk', {
                available: quota.intents - intents.length - 1,
                all: quota.intents,
              })
            : $t('intentsSuggestion.quotaInfoNotOk', { all: quota.intents })
        "
        :icon="isQuotaOk ? 'mdi-comment-check' : 'mdi-comment-alert-outline'"
      />
      <InfoBox
        v-else
        text="Es sind noch keine Quota eingestellt."
        icon="mdi-comment-alert-outline"
      />
    </div>
    <div class="pt-6" v-else>
      <InfoBox
        v-if="quota"
        :text="
          isQuotaOk
            ? $t('intentsSuggestion.quotaInfoOk', {
                available: quota.intents - intents.length - 1,
                all: quota.intents,
              })
            : $t('intentsSuggestion.quotaInfoNotOk', { all: quota.intents })
        "
        :icon="isQuotaOk ? 'mdi-comment-check' : 'mdi-comment-alert-outline'"
      />
    </div>
    </HubDialog>
  </HubDialog>
</template>
<script>
import { mapGetters } from 'vuex';
import HubDialog from '@/components/hub/HubDialog.vue';
import InfoBox from '../common/InfoBox.vue';

export default {
  name: 'IntentsDialogSuggestion',
  components: {
    HubDialog,
    InfoBox,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    intent: {
      type: Object,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters('bots', ['currentBotId', 'getBotSettings']),
    ...mapGetters('intents', ['intents', 'displayName']),
    ...mapGetters('auth', ['isAdmin']),
    isQuotaOk() {
      if (!this.quota) return null;
      return this.intents.length < this.quota.intents;
    },
    quota() {
      return this.getBotSettings(this.currentBotId)?.quota || null;
    },
  },
  methods: {
    async createIntentFromSuggestion() {
      this.isLoading = true;
      const result = await this.$store.dispatch('intents/addSuggestion', {
        botId: this.intent.botId,
        intent: this.intent.intent,
      });

      if (result) {
        this.$router.push({
          name: 'intentsedit',
          params: { name: this.intent.intent },
        });
        this.isLoading = false;
      }
    },
  },
};
</script>

import ApiService from './api'

const TranslationService = {
    getTranslation: async function(botId, text, language = "DE") {
        try {
            const config = {
                text,
                language
            }
            const response = await ApiService.post(`/schaltzentrale/translate/${botId}`, config);
            return response.data;
        } catch (error) {
            return {};
        }
    },

    /**
     * translate multiple texts
     * 
     * @param {string} botId 
     * @param {string[]} texts 
     * @param {string} language - target language
     * @returns {Promise<object>} Promise<any> {error: string} or {data: object}
     */
    getTranslations: async function(botId, texts, language = "DE") {
        try {
            const config = {
                texts,
                language
            }
            const response = await ApiService.post(`/schaltzentrale/translate-bulk/${botId}`, config);
            return response.data;
        } catch (error) {
            console.log(error);
            throw new Error("Failed to translate texts");
        }
    },
}

export default TranslationService
<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <div v-on="on">
        <v-btn
          :disabled="!isDeployable(currentBotId) || isDeploying"
          class="mx-2 px-2 primary--text font-weight-bold"
          color="white"
          @click="show()"
        >
          <!-- Button -->
          <span class="hidden-sm-and-down pl-2">{{ $t('settings.deployment.deploy') }}</span>
          <v-icon class="pl-0 mx-2">mdi-truck-delivery</v-icon>
          <!-- Deployment Dialog -->
          <v-dialog v-model="showDialog" max-width="500">
            <v-card class>
              <v-card-title
                class="text-h5 pt-6"
                style="word-break: break-word;"
              >{{ $t('settings.deployment.dialog.title') }}</v-card-title>
              <v-card-text>
                <div class="text--primary">
                  {{ $t('settings.deployment.dialog.description') }}
                  <br />
                  <br />
                  <span
                    v-if="selfDeploymentAllowed"
                    class="grey--text italic"
                  >{{ $t('settings.deployment.dialog.newdisclaimer') }}</span>
                  <span
                    v-else
                    class="grey--text italic"
                  >{{ $t('settings.deployment.dialog.disclaimer') }}</span>
                </div>
              </v-card-text>
              <v-divider class="mt-2" />
              <v-card-actions class="flex-column flex-sm-row justify-space-between align-stretch">
                <v-btn
                  text
                  class="px-4 grey--text d-block d-sm-inline-block my-4 my-sm-0"
                  @click="showDialog = false"
                >
                  {{ $t('common.cancel') }}
                  <v-icon class="ml-2 grey--text">cancel</v-icon>
                </v-btn>
                <v-btn
                  :disabled="(!isSimpleEdit && !isAdmin) || areChannelsBlocked"
                  class="px-4 white--text ml-0 ml-sm-2 d-block d-sm-inline-block"
                  color="primary"
                  elevation="0"
                  @click="deploy"
                >
                  {{ $t('settings.deployment.deploy') }}
                  <v-icon class="ml-2">mdi-truck-delivery</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-btn>
      </div>
    </template>
    <span v-if="isDeployable(currentBotId)">{{ $t('settings.deployment.readytodeply') }}</span>
    <span v-else>{{ $t('settings.deployment.nochanges') }}</span>
  </v-tooltip>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'DeploymentButton',
  data() {
    return {
      showDialog: false,
      isDeploying: false,
    };
  },
  computed: {
    ...mapGetters('bots', ['currentBotId', 'getBotSettings', 'areChannelsBlocked']),
    ...mapGetters('content', ['isDeployable', 'lastChangeDate']),
    ...mapGetters('auth', ['isAdmin', 'isSimpleEdit']),
    selfDeploymentAllowed() {
      const botSettings = this.getBotSettings(this.currentBotId);
      if (
        botSettings !== false && botSettings?.deployment?.notAllowed === true
      )
        return false;
      else return true;
    },
  },
  methods: {
    deploy() {
      if (this.selfDeploymentAllowed) {
        this.isDeploying = true;
        this.$store
          .dispatch('content/deploy', { botId: this.currentBotId })
          .then((result) => {
            this.$store
              .dispatch('content/getDeployment', { botId: this.currentBotId })
              .then(() => {
                this.isDeploying = false;
              });
          });
      } else {
        this.$store.dispatch('content/fakeDeploy', {
          botId: this.currentBotId,
          lastChangeDate: this.lastChangeDate(this.currentBotId),
        });
      }
      this.showDialog = false;
    },
    show() {
      this.showDialog = true;
    },
  },
};
</script>

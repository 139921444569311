const ChannelsUtil = {
  getChannelType(channelId, currentChannels = []) {
    if (!channelId) {
      if (currentChannels.length > 0) {
        return currentChannels[0].channel;
      } else {
        return 'widget';
      }
    }

    let channel = currentChannels.find((c) => {
      return c.channelId === channelId;
    });

    if (channel) {
      return channel.channel;
    }

    return 'widget';
  },
};

export default ChannelsUtil;

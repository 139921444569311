var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('HubMenu'),_c('Appbar'),_c('HubContent',{scopedSlots:_vm._u([{key:"navbar",fn:function(){return [_c('HubContentNavbar',{attrs:{"title":_vm.$t('channels.headline'),"isLoading":_vm.intentsLoading}})]},proxy:true},{key:"content",fn:function(){return [_c('v-container',{staticClass:"pa-6",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[(_vm.isAdmin)?_c('h3',{staticClass:"mb-1"},[_vm._v("Production")]):_vm._e(),_c('v-simple-table',{staticClass:"fixed-table"},[_c('thead',[_c('tr',[_c('th',{staticClass:"text-left",attrs:{"width":"60%"}},[_vm._v("Channel")]),_c('th',{staticClass:"text-left",attrs:{"width":"20%"}},[_vm._v("Type")]),_c('th',{staticClass:"text-right"})])]),_c('tbody',_vm._l((_vm.getProductionChannel),function(channel){return _c('tr',{key:channel.channelId},[_c('td',[_vm._v(_vm._s(_vm.displayName(channel)))]),_c('td',[_c('v-icon',[_vm._v(_vm._s(_vm.channelTypeValues(channel).icon))]),_vm._v(" "+_vm._s(_vm.$t(_vm.channelTypeValues(channel).name))+" ")],1),_c('td',{staticClass:"text-right"},[_c('v-btn',{attrs:{"title":"Copy Embedd Code","icon":""},on:{"click":function($event){return _vm.codeToClipboard(
                            _vm.currentBot.uniqueBotId,
                            channel.channelId
                          )}}},[_c('v-icon',[_vm._v("mdi-code-tags")])],1),_c('v-btn',{attrs:{"to":`/channels/setting/${_vm.currentBot.uniqueBotId}/${channel.channelId}`,"icon":""}},[_c('v-icon',[_vm._v("mdi-cog")])],1)],1)])}),0)])],1)],1),(_vm.isAdmin)?_c('v-row',[_c('v-col',[_c('h3',{staticClass:"mb-1"},[_vm._v("Preview")]),(_vm.isAdmin)?_c('v-simple-table',{staticClass:"fixed-table"},[_c('thead',{staticClass:"blue lighten-2"},[_c('tr',[_c('th',{staticClass:"text-left",attrs:{"width":"60%"}},[_vm._v("Channel")]),_c('th',{staticClass:"text-left",attrs:{"width":"20%"}},[_vm._v("Type")]),_c('th',{staticClass:"text-right"})])]),_c('tbody',_vm._l((_vm.getStagingChannel),function(channel){return _c('tr',{key:channel.channelId},[_c('td',[_vm._v(_vm._s(_vm.displayName(channel)))]),_c('td',[_c('v-icon',[_vm._v(_vm._s(_vm.channelTypeValues(channel).icon))]),_vm._v(" "+_vm._s(_vm.$t(_vm.channelTypeValues(channel).name))+" ")],1),_c('td',{staticClass:"text-right"},[_c('v-btn',{attrs:{"title":"Copy Embedd Code","icon":""},on:{"click":function($event){return _vm.codeToClipboard(
                            _vm.currentBot.stagingBot,
                            channel.channelId
                          )}}},[_c('v-icon',[_vm._v("mdi-code-tags")])],1),_c('v-btn',{attrs:{"to":`/channels/setting/${_vm.currentBot.stagingBot}/${channel.channelId}`,"icon":""}},[_c('v-icon',[_vm._v("mdi-cog")])],1)],1)])}),0)]):_vm._e()],1)],1):_vm._e()],1)]},proxy:true}])}),_c('router-view',{attrs:{"name":"dialog"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import ApiService from './api';

const TeasersService = {
  async getTeasers(botId, channelId) {
    try {
      const url = `/widget/teaser/management/${botId}/${channelId}`;

      const response = await ApiService.get(url);
      if (response.data) {
        return response.data;
      }

      return {};
    } catch (error) {
      return null;
    }
  },
  async saveTeasers({ botId, channelId, teasers }) {
    try {
      const url = `/widget/teaser/management/${botId}/${channelId}`;

      const response = await ApiService.post(url, {
        teaser: teasers,
      });
      if (response.data) {
        return response.data;
      }

      return {};
    } catch (error) {
      return null;
    }
  },
  async getTeaserStats(botId, teaserId, dateRange) {
    try {
      const url = `/widget/teaser/${botId}/${teaserId}/stats?start=${dateRange[0]}&end=${dateRange[1]}`;
      const response = await ApiService.get(url);
      if (response.data) {
        return response.data;
      }

      return {};
    } catch (error) {
      return null;
    }
  },
};

export default TeasersService;

import { formatNumberFixed, formatNumberFixedOne } from '@/utils/formatNumber';

const NumberTeaserUtil = {
  getChangedPercent({ oldValue, newValue, isPercent }) {
    if (!isPercent) {
      // ((New - Old) x 100) / Old
      let value =
        ((Number(newValue) - Number(oldValue)) * 100) / Number(oldValue);

      if (value < 0) value = value * -1;
      value = formatNumberFixedOne(value);
      if (isNaN(Number(value))) value = 100;

      return `${value}%`;
    }
    // For absolute percentage
    else {
      let value = newValue - oldValue;
      value = formatNumberFixed(value);
      if (value > 0) value = `+${value}%`;
      if (value < 0) value = `${value}%`;

      return value;
    }
  },
  getChangedStrings({ oldValue, newValue, isPercent }) {
    let change = newValue - oldValue;
    let before = oldValue;
    // round percentage
    if (isPercent) {
      change = formatNumberFixed(change);
      before = formatNumberFixed(before);
    }

    let changeString = change >= 0 ? change : change * -1;
    let beforeString = before >= 0 ? before : before * -1;

    if (isPercent) {
      changeString += '%';
      beforeString += '%';
    }

    return { change, changeString, beforeString };
  },
};

export default NumberTeaserUtil;

<template>
  <div>
    <ActionRow class="action-row-end">
      <template #line>
        <ActionLine :blockType="blockTypes.END_LOADING" />
      </template>
      <template #content>
        <div class="relative" style="max-width: 400px">
          <v-skeleton-loader
            :height="40"
            width="100%"
            type="image"
          />
        </div>
      </template>
    </ActionRow>
  </div>
</template>
<script>
import ActionRow from '@/components/editor/components/ActionRow.vue';
import ActionLine from '@/components/editor/components/ActionLine.vue';
import { blockTypes } from '@/components/editor/actionConsts';

export default {
  name: 'ActionRowEndLoading',
  components: {
    ActionRow,
    ActionLine,
  },
  data() {
    return {
      blockTypes
    }
  }
};
</script>
<style scoped lang="scss">
.action-row-end {
  height: 64px;
}

/**
 * These enums let you know mostly the "state" of the document relative to its status in the database.
 * @enum {string}
 */
export const ResourceDraftType = {
  /**
   * This indicates a new document that has never been published.
   * This happens when you create a new document and are taken to the editor.
   * This document is populated in the Vuex store when you create a new document.
   */
  NEW_DRAFT: 'newDraft',
  /**
   * The document is published and is an unmodified version you can compare edits against.
   * It is the document that is loaded and populated into the Content Vuex store.
   * Do not make changes to this document, use the DRAFT copy instead.
   */
  PUBLISHED: 'published',
  /**
   * This indicates a published document that has been edited.
   * It will exist alongside the PUBLISHED one.
   * This copy is automatically populated in the Vuex store when you start editing a document.
   */
  DRAFT: 'draftOfPublished',
}

export const LegacyMarkdownDocumentType = {
  /**
   * This indicates a new document that has never been published.
   * This happens when you create a new document and are taken to the editor.
   * This document is populated in the Vuex store when you create a new document.
   */
  NEW_DRAFT: 'KnowledgeBaseDocumentNewDraft',
  /**
   * The document is published and is an unmodified version you can compare edits against.
   * It is the document that is loaded and populated into the Content Vuex store.
   * Do not make changes to this document, use the DRAFT copy instead.
   */
  PUBLISHED: 'KnowledgeBaseDocument',
  /**
   * This indicates a published document that has been edited.
   * It will exist alongside the PUBLISHED one.
   * This copy is automatically populated in the Vuex store when you start editing a document.
   */
  DRAFT: 'KnowledgeBaseDocumentDraft',
}

/**
 * Where we can find the Markdown content for a given type of RAG resource
 */
export const MarkdownPath = {
  MARKDOWN: 'content.markdown',
  QNA: 'content.answer',
  WEBPAGE: 'markdown',
  PDF: 'markdown',
  TABLE: 'markdown',
  undefined: 'markdown',
};
/**
 * Where we can find the display title stored for a given type of RAG resource
 */
export const TitlePath = {
  MARKDOWN: 'metaData.title',
  QNA: 'content.question',
  QNAFallback: 'metaData.originalTitle',
  PDF: 'url',
  WEBPAGE: 'url',
  TABLE: 'url',
};
/**
 * Types of RAG resources that we can use the Markdown editor on
 * @type {string[]}
 */
export const EditableTypes = [
  'QNA',
  'MARKDOWN',
];

<template>
  <div>
    <div
      :class="['relative', circleStyle && 'image-upload-circle-img']"
      :style="{ width: `${width}px`, height: `${width}px` }"
    >
      <v-icon
        style="
          position: absolute;
          top: 50%;
          left: 50%;
          margin-left: -16px;
          margin-top: -16px;
        "
        size="32"
        color="white"
      >
        insert_photo
      </v-icon>
      <v-img
        @dragover.prevent
        @dragleave="dragleave"
        @dragenter="dragenter"
        @drop="drop"
        :src="imageSrc"
        :aspect-ratio="circleStyle ? 1 : 4 / 3"
      >
        <v-row
          v-show="hoveringContent"
          class="fill-height ma-0 success no-pointer-events"
          align="center"
          justify="center"
        >
          <v-icon large color="white">insert_photo</v-icon>
        </v-row>
        <v-row
          v-show="hoveringContent"
          class="fill-height ma-0 error no-pointer-events"
          align="center"
          justify="center"
        >
          <v-icon large color="white">close</v-icon>
        </v-row>
      </v-img>
    </div>
    <v-file-input
      accept="image/png, image/jpeg, image/bmp, image/gif"
      @click:append-outer="uploadImage()"
      append-outer-icon="mdi-cloud-upload"
      show-size
      :label="$t('botFormEdit.replaceImage')"
      v-model="imageFile"
    ></v-file-input>
  </div>
</template>

<script>
export default {
  name: 'ImageUpload',
  props: {
    imageSrc: {
      type: String,
      required: true,
    },
    circleStyle: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 200,
    },
  },
  data() {
    return {
      imageFile: null,
      hoveringContent: false,
    };
  },
  methods: {
    dragenter() {
      this.hoveringContent = true;
    },
    dragleave() {
      this.hoveringContent = false;
    },
    drop(e) {
      e.preventDefault();
      if (e.dataTransfer.items && e.dataTransfer.items.length === 1) {
        for (let i = 0; i < e.dataTransfer.items.length; i++) {
          if (e.dataTransfer.items[i].kind === 'file') {
            // Directories are not supported. Skip any that are found
            if (e.dataTransfer.items[i].webkitGetAsEntry) {
              const entry = e.dataTransfer.items[i].webkitGetAsEntry();
              if (entry.isDirectory) {
                continue;
              }
            }
            const file = e.dataTransfer.items[i].getAsFile();
            if (file?.type.startsWith('image')) {
              this.imageFile = file;
            }
          }
        }
      }
      this.hoveringContent = false;
    },
    uploadImage() {
      this.$emit('onUpload', this.imageFile);
    },
  },
};
</script>
<style lang="scss" scoped>
.no-pointer-events {
  pointer-events: none;
}

:deep(.v-text-field__slot) {
  overflow: hidden;
}

.image-upload-circle-img {
  border-radius: 50%;
  overflow: hidden;
  margin: auto;
  background: #e2e2e2;
}
</style>

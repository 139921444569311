<template>
  <div class="intent-statistic">
    <h2 class="text-h6 primary--text mb-3">
      <v-icon v-if="icon" left class="mr-3" color="primary lighten-3">
        {{ icon }}
      </v-icon>
      <span v-html="title" />
      <span
        v-if="currentChannels.length > 1"
        class="primary--text text--lighten-3 text-body-2"
      >
        ({{ channelName }})
      </span>
    </h2>

    <div class="pb-4">
      <small class="mb-3 text-body-2 d-block" v-html="progressTitle" />
      <slot name="progressBar">
        <!-- default -->
      </slot>
    </div>

    <div class="chart">
      <h3 class="text-body-2">{{ chartTitle }}</h3>
      <p class="primary--text text--lighten-3">
        <small>{{ chartSubline }}</small>
      </p>
      <slot v-if="!loading" name="chart">
        <!-- default -->
      </slot>
      <v-card v-else class="text-center" min-height="140px" flat>
        <v-progress-circular
          :size="24"
          :width="2"
          indeterminate
          color="secondary darken-2"
        />
      </v-card>
    </div>

    <v-alert
      v-if="false"
      icon="mdi-lightbulb"
      close-text="Close Alert"
      dismissible
    >
      <small class="primary--text text--lighten-3">
        Nutze diese Ansicht um besser einzuschätzen, wie die Bewertungen des
        Themas sich in letzter Zeit entwickelt haben
      </small>
      <template v-slot:close="{ toggle }">
        <v-icon @click="toggle">mdi-close</v-icon>
      </template>
    </v-alert>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'IntentStatistic',
  props: {
    title: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    progressTitle: {
      type: String,
      default: '',
    },
    chartTitle: {
      type: String,
      default: '',
    },
    chartSubline: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    channel: {
      type: String,
    },
  },
  computed: {
    ...mapGetters('bots', ['currentChannels']),
    channelName() {
      return this.channel === null
        ? this.$t('intents.details.channels.all')
        : '';
    },
  },
};
</script>

<style lang="scss" scoped>
.intent-statistic {
  width: 100%;
  h2 {
    span {
      vertical-align: middle;
    }
  }
}

:deep(.v-alert__icon) {
  align-self: center;
  color: var(--v-secondary-darken1);
}

:deep(.v-progress-linear) {
  overflow: visible;
  transition: 0.5s map-get($transition, 'ease-in-out');
}
:deep(.v-progress-linear--outer-value) {
  margin-bottom: 0.6875rem;
}
:deep(.v-progress-linear__content ) {
  justify-content: flex-start;
  strong {
    top: 0.6875rem;
    position: absolute;
    transition: 0.5s map-get($transition, 'ease-in-out');
    transition-property: left, right;
  }
}
</style>

<template>
  <v-dialog persistent :value="isOpen" max-width="500px" hide-overlay>
    <div class="hub-panel">
      <v-progress-linear
        v-if="loading"
        absolute
        indeterminate
        color="secondary darken-2"
      />
      <div class="hub-panel-logo py-8 px-2">
        <div class="mb-8">
          <v-img src="@/assets/moin-logo.svg" class="mx-auto" width="160" />
        </div>
        <slot />
      </div>
    </div>
  </v-dialog>
</template>
<script>
export default {
  name: 'HubPanelLogo',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },

  created() {
    setTimeout(() => {
      this.isOpen = true;
    }, 300);
  },
};
</script>
<style scoped>
.hub-panel {
  position: relative;
  overflow: hidden;
}

.hub-panel-logo {
  background: white;
}

/*Bug hack https://github.com/vuetifyjs/vuetify/issues/9175 */
.v-dialog__container {
  display: unset;
}
</style>

<template>
  <div>
    <BtnSquare
      icon="add"
      @click="isDialogOpen = true"
      colorBtn="admin"
      colorIcon="admin"
    />
    <HubDialog
      v-model="isDialogOpen"
      title="Neues Thema als Vorschlag"
      :primary-label="$t('common.save')"
      primary-icon="save"
      :secondary-label="$t('common.cancel')"
      secondary-icon="close"
      @onPrimary="createIntent"
      @onSecondary="isDialogOpen = false"
      modal
      :loading="isLoading"
      :disabled="isLoading"
      asyncDialog
    >
      <div class="pt-10">
        <v-form ref="createForm">
          <v-row>
            <v-col>
              <v-text-field
                label="Name"
                outlined
                v-model="nameInput"
                :rules="displayNameRules"
                counter
                hint="z.B. Kosten"
                persistent-hint
              />
              <div>
                Es ist ein Fehler mit dem Namen aufgetreten.
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                label="Beschreibung"
                outlined
                v-model="descriptionInput"
                :rules="descriptionRules"
                counter
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-textarea
                label="5 Varianten"
                outlined
                v-model="samplesInput"
                :rules="samplesRules"
                rows="10"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div class="mb-4">
                <v-switch
                  v-model="isForwardActive"
                  :color="isForwardActive ? 'green' : 'grey'"
                  inset
                  label="Mit Weiterleitung"
                  hide-details
                />
              </div>
              <v-select
                v-if="isForwardActive"
                label="Weiterleiten zu"
                v-model="forwardIntent"
                :items="intents"
                item-text="displayName"
                item-value="intent"
                clearable
              ></v-select>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </HubDialog>
  </div>
</template>
<script>
// This component is for Admins only

import { mapGetters } from 'vuex';
import HubDialog from '../hub/HubDialog.vue';
import BtnSquare from '@/components/common/BtnSquare.vue';
import { styleTypes } from '@/utils/notifications';

export default {
  components: { HubDialog, BtnSquare },
  data() {
    return {
      isDialogOpen: false,
      isLoading: false,
      nameInput: '',
      descriptionInput: '',
      samplesInput: '',
      isForwardActive: false,
      forwardIntent: null,
    };
  },
  computed: {
    ...mapGetters('intents', ['intents', 'suggested']),
    ...mapGetters('bots', ['currentBot', 'currentBotId']),
    samples() {
      const items = this.samplesInput.split('\n');

      return items.filter((i) => i.length > 0);
    },
    displayNameRules() {
      const rules = [];

      // requireed
      const ruleRequired = (v) => !!v || 'Name muss gesetzt sein.';
      rules.push(ruleRequired);

      // 3 characters or more
      const ruleMin = (v) =>
        (v || '').length >= 3 ||
        'Name muss mindestens aus drei Zeichen bestehen.';
      rules.push(ruleMin);

      // max 30 characters
      const ruleMax = (v) =>
        (v || '').length <= 30 || 'Name darf maximal aus 30 Zeichen bestehen.';
      rules.push(ruleMax);

      // no duplicate names
      const ruleName = (v) => {
        if (v) {
          const dup = !this.suggested.find(
            (i) => i.displayName.toLowerCase() === v.toLowerCase()
          );
          return dup || 'Name wird schon verwendet.';
        } else {
          return true;
        }
      };

      rules.push(ruleName);

      return rules;
    },
    descriptionRules() {
      const rules = [];

      // required
      const ruleRequired = (v) =>
        !!v ||
        'Beschreibung muss gesetzt sein und mind. aus 50 Zeichen bestehen.';
      rules.push(ruleRequired);

      // min 50 characters
      const ruleMin = (v) =>
        (v || '').length >= 50 ||
        'Beschreibung muss mindestens aus 50 Zeichen bestehen.';
      rules.push(ruleMin);

      return rules;
    },
    samplesRules() {
      const rules = [];

      const ruleMin = () =>
        this.samples.length === 5 ||
        'Trage 5 Beispiele durch einen Zeilenumbruch getrennt (Return) ein.';

      rules.push(ruleMin);

      const ruleMinTwo = (v) =>
        (v || '')
          .split('\n')
          .every(
            (item) =>
              !item.length ||
              (item.split(' ').length >= 2 && item.split(' ')[1] !== '')
          ) || 'Beispielanfrage muss mindestens aus zwei Wörter bestehen.';

      rules.push(ruleMinTwo);

      const ruleMax = (v) =>
        (v || '').split('\n').every((v) => v.length <= 160) ||
        'Beispielanfrage darf maximal aus 160 Zeichen bestehen.';

      rules.push(ruleMax);

      const ruleDup = (v) => {
        const items = v.split('\n');
        if (!items.length) return true;

        return (
          items.every((item) => {
            const dup = this.samples.filter(
              (sample) =>
                sample.length && sample.toLowerCase() === item.toLowerCase()
            );
            return dup.length <= 1;
          }) || 'Die Beispiele dürfen nicht gleich sein.'
        );
      };

      rules.push(ruleDup);

      return rules;
    },
  },
  methods: {
    async createIntent() {
      this.isLoading = true;
      const result = await this.$store.dispatch('intents/add', {
        botId: this.currentBot.uniqueBotId,
        samples: this.samples.map((str) => {
          return { text: str };
        }),
        displayName: this.nameInput,
        description: this.descriptionInput,
        suggested: true,
        forward: this.forwardIntent,
      });

      if (result.status === 'ok') {
        this.resetInputs();
        await this.$store.dispatch('intents/get', this.currentBotId);
      } else {
        this.$store.dispatch('notifications/addNotification', {
          name: 'admin-create-intent-error',
          styleType: styleTypes.ERROR,
          messageString: `Es ist ein Fehler beim speichern aufgetreten.`,
          action: () => true,
        });
      }

      this.isDialogOpen = false;
      this.isLoading = false;
    },
    resetInputs() {
      this.nameInput = '';
      this.descriptionInput = '';
      this.samplesInput = '';
      this.isForwardActive = false;
      this.forwardIntent = null;

      this.$refs.createForm.resetValidation();
    },
  },
};
</script>

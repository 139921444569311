import moment from 'moment';
import DateRangeUtil from '@/utils/dateRange';
import StatsService from '@/services/stats';

const state = {
  isLoading: false,
  dateRange: DateRangeUtil.getRangeLastXDays(27),
  dateRangeFormatted: null,
  dateRangeCompare: null,
  dateRangeCompareFormatted: null,
  dateCompareValue: null,
  intentId: null,
  channel: null,
  initialIntentsOnceSet: false, // Remember if initial intents have been set
  selectedIntentsToCompare: [],
  isAnualReview: false,
  anualReviewYear: 2023,
  anualConversationsPerMonth: [],
  anualDistributionsWeekDays: {},
  anualPosFeedback: 0,
};

const getters = {
  dateRange: (state) => state.dateRange,
  dateRangeFormatted: (state) => state.dateRangeFormatted,
  dateRangeCompare: (state) => state.dateRangeCompare,
  dateRangeCompareFormatted: (state) => state.dateRangeCompareFormatted,
  dateCompareValue: (state) => state.dateCompareValue,
  intentId: (state) => state.intentId,
  channel: (state) => state.channel,
  selectedIntentsToCompare: (state) => state.selectedIntentsToCompare,
  initialIntentsOnceSet: (state) => state.initialIntentsOnceSet,
  isAnualReview: (state) => state.isAnualReview,
  anualReviewYear: (state) => state.anualReviewYear,
  anualConversationsPerMonth: (state) => state.anualConversationsPerMonth,
  anualDistributionsWeekDays: (state) => state.anualDistributionsWeekDays,
  anualPosFeedback: (state) => state.anualPosFeedback,
  isLoading: (state) => state.isLoading,
};

const actions = {
  async loadAnualReviewData({ commit }, { botId, channel, year }) {
    commit('setValue', {
      key: 'anualReviewYear',
      value: year,
    });
    
    // 1. Conversations per month
    let monthIndex = 0;
    const conversationsPerMonth = [];

    while (monthIndex < 12) {
      const startOfMonth = moment()
        .year(year)
        .month(monthIndex)
        .startOf('month');

      const endOfMonth = moment()
        .year(year)
        .month(monthIndex)
        .endOf('month');

      const dateRange = [startOfMonth, endOfMonth];

      const { sum, series } = await StatsService.getConversationSeries(
        botId,
        dateRange,
        channel
      );

      conversationsPerMonth.push({ sum, series });

      monthIndex++;
    }

    commit('setValue', {
      key: 'anualConversationsPerMonth',
      value: conversationsPerMonth,
    });

    // 2. Distributions Week Days
    const dateRange = DateRangeUtil.getAnualReviewDateRange(year);
    const distributionsWeekDays = await StatsService.getConversationDistribution(
      botId,
      dateRange,
      channel
    );
    commit('setValue', {
      key: 'anualDistributionsWeekDays',
      value: distributionsWeekDays,
    });

    // 3. Positive Feedback for anual review
    const { posCount } = await StatsService.getFeedbackSeries(
      botId,
      dateRange,
      channel
    );

    commit('setValue', {
      key: 'anualPosFeedback',
      value: posCount,
    });
  },
};

const mutations = {
  setValue(state, { key, value }) {
    state[key] = value;
  },
  setDateRange(state, { dateRange, language }) {
    state.dateRange = dateRange.map((date) => moment(date));
    state.dateRangeFormatted = DateRangeUtil.getDateRangeFormatted({
      dateRange,
      language,
    });
  },
  setDateRangeCompare(state, { dateRange, language }) {
    if (!dateRange) {
      state.dateRangeCompare = null;
      state.dateRangeCompareFormatted = null;
      return;
    }
    state.dateRangeCompare = dateRange.map((date) => moment(date));
    state.dateRangeCompareFormatted = DateRangeUtil.getDateRangeFormatted({
      dateRange,
      language,
    });
  },
  setDateCompareValue(state, dateCompareValue) {
    state.dateCompareValue = dateCompareValue;
  },
  toggleSelectedIntentToCompare(state, intentId) {
    if (state.selectedIntentsToCompare.includes(intentId)) {
      state.selectedIntentsToCompare = state.selectedIntentsToCompare.filter(
        (i) => i !== intentId
      );
    } else {
      state.selectedIntentsToCompare.push(intentId);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

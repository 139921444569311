import productAdvisor from '@/services/productAdvisor';
import _ from 'lodash';
import statsService from '@/services/stats';

const state = {
  isLoading: false,
  // All advisors of this bot
  advisors: [],
};

const getters = {
  isLoading: state => state.isLoading,
  getAdvisors: state => (botId) => state.advisors.filter(advisor => advisor.botId === botId),
  getAdvisor: state => (botId, id) => state.advisors.find(advisor => advisor._id === id && advisor.botId === botId),
};

const actions = {
  /**
   * Gets advisor by ID from cache, and if necessary, fetches all advisors then tries again
   * @param getters
   * @param dispatch
   * @param {string} botId
   * @param {string} advisorId
   * @returns {Promise<Object|null>} If null, there is no such advisor in backend either
   */
  async getOrFetchAdvisor({ getters, dispatch }, {botId, advisorId}) {
    const advisor = getters.getAdvisor(botId, advisorId);
    if (advisor) return advisor;
    await dispatch('fetchAdvisors', botId);
    return getters.getAdvisor(botId, advisorId);
  },
  /**
   * Performs the necessary steps to initialize this store module
   * @param state
   * @param dispatch
   * @param {string} botId Current bot ID
   * @returns {Promise<void>}
   */
  async init({ state, dispatch }, { botId }) {
    state.isLoading = true;
    await dispatch('fetchAdvisors', botId);
    state.isLoading = false;
  },
  /**
   * Fetches advisors from backend and updates local state
   * @param commit
   * @param {string} botId Bot ID to get advisors of
   * @returns {Promise<void>}
   */
  async fetchAdvisors({ commit }, botId) {
    const advisors = await productAdvisor.getAdvisors(botId);
    if (advisors === null) return;
    commit('setAdvisors', advisors);
  },
  /**
   * Updates an advisor in the backend and local state
   * @param {Object} context
   * @param {Object} payload
   */
  async patchAdvisor({ commit }, { botId, advisorId, newProperties }) {
    const savedAdvisor = await productAdvisor.updateAdvisor(botId, advisorId, newProperties);
    commit('updateAdvisor', savedAdvisor);
  },

  /**
   * Create a new product for the given advisor
   * @param {Object} context
   * @param {Object} payload
   */
  async createProduct({ commit }, { botId, advisorId, product }) {
    const savedProduct = await productAdvisor.createProduct(botId, advisorId, product);
    commit('addProduct', { advisorId, product: savedProduct });
  },

  /**
   * Update a product
   * @param {Object} context
   * @param {Object} payload
   */
  async updateProduct({ commit }, { botId, advisorId, productId, product }) {
    const savedProduct = await productAdvisor.updateProduct(botId, advisorId, productId, product);
    commit('updateProduct', { advisorId, productId, newProperties: product });
  },
  /**
   * Delete a product
   * @param {Object} context
   * @param {Object} payload
   */
  async deleteProduct({ commit }, { botId, advisorId, productId }) {
    await productAdvisor.deleteProduct(botId, advisorId, productId);
    commit('deleteProduct', { advisorId, productId });
  },

  async getAmountOfStartedConversations({ commit }, { botId, intent, from, to }) {
    const data = await statsService.productAdvisor.getAmountOfStartedConsultations({ botId, intent, from, to });
    // Save data if needed
    return data;
  },

  async getConversionRate({ commit }, { botId, intent, from, to }) {
    const data = await statsService.productAdvisor.getConversionRate({ botId, intent, from, to });
    // Save data if needed
    return data;
  },

  // TODO: Finish others
  async getMostPopularProducts({}, { botId, intent, from, to, limit }) {
    const data = await statsService.productAdvisor.getPopularProducts({ botId, intent, from, to, limit });
    // Save data if needed
    return data;
  },
  async getMostRecommendedProducts({}, { botId, intent, from, to, limit }) {
    const data = await statsService.productAdvisor.getRecommendedProducts({ botId, intent, from, to, limit });
    // Save data if needed
    return data;
  },
  async getMostRecommendedProductCount({}, { botId, intent, from, to, unique }) {},
  async getAvgAmountOfRecommendedProducts({}, { botId, intent, from, to }) {
    const data = await statsService.productAdvisor.getRecommendedProductsAvg({ botId, intent, from, to });
    // Save data if needed
    return data;
  },
  async getMostRequestedQueryParams({}, { botId, intent, from, to }) {
    // - Retrieve detailed list of most requested query parameters
    const data = await statsService.productAdvisor.getMostRequestedQueryProperties({ botId, intent, from, to });
    // Save data if needed
    return data;
  },
};

const mutations = {
  /**
   * Overwrites the current advisors with the given list
   * @param state
   * @param {Object[]} advisors
   */
  setAdvisors(state, advisors) {
    state.advisors = advisors;
  },
  /**
   * Adds a new advisor to the list
   * @param state
   * @param {Object} advisor
   */
  addAdvisor(state, advisor) {
    const foundIndex = state.advisors.findIndex(a => a._id === advisor._id);

    if (foundIndex !== -1) {
      state.advisors[foundIndex] = advisor;
    } else {
      state.advisors.push(advisor);
    }
  },
  updateAdvisor(state, advisor) {
    const foundIndex = state.advisors.findIndex(a => a._id === advisor._id);
    if (foundIndex !== -1) {
      const advisorsCopy = [...state.advisors];
      advisorsCopy[foundIndex] = { ...advisorsCopy[foundIndex], ...advisor };
      state.advisors = advisorsCopy; // reassign the whole advisors array to trigger reactivity
    }
  },
  addProduct(state, { advisorId, product }) {
    const advisor = state.advisors.find(a => a._id === advisorId);
    if (advisor) {
      advisor.products.push(product);
    }
  },
  updateProduct(state, { advisorId, productId, newProperties }) {
    const advisor = state.advisors.find(a => a._id === advisorId);
    if (advisor) {
      const product = advisor.products.find(p => p._id === productId);
      if (product) {
        Object.assign(product, _.cloneDeep(newProperties));
        state.advisors = _.cloneDeep(state.advisors); // reassign the whole advisors array to trigger reactivity
      }
    }
  },
  deleteProduct(state, { advisorId, productId }) {
    const advisor = state.advisors.find(a => a._id === advisorId);
    if (advisor) {
      advisor.products = advisor.products.filter(p => p._id !== productId);
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
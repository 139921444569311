/**
 * 
 * channels vuejs plugin
 * 
 * - channel type i18n string mapping
 * - channel type icon ids
 * - language iso code i18n string mapping
 * 
 */

export default {
    install(Vue) {

        /**
         * 
         * @param {String or Channel} channelType 
         */
        Vue.prototype.$channelIcon = function (channelType) {

            if (typeof channelType === 'object' && channelType.channel) {
                channelType = channelType.channel
            }
            
            switch (channelType) {
                case 'widget':
                    return 'web';
                case 'facebook':
                    return 'facebook';
                case 'userlike':
                    return 'support_agent';
            }

            return 'error';
        }

        Vue.prototype.$channelLabel = function () {
            return 'facebook';
        }
      
    }
 }
 
import LivechatService from '@/services/livechat';

const state = {
    organization: null,
    isLoading: false,
}

const getters = {
    organization: state => state.organization,
    agents: state => state.organization.agents,
    groups: state => state.organization?.groups,
    isLoading: state => state.isLoading,
}

const actions = {
    async init({ dispatch, state }, uniqueBotId) {
        state.isLoading = true;
        await dispatch('fetchOrganization', uniqueBotId);
        state.isLoading = false;
    },
    async fetchOrganization({ commit }, uniqueBotId) {
        const organization = await LivechatService.getOrganizatioByBotId(uniqueBotId);
        commit('setOrganization', organization);
    },
    async removeAgentGroup({ commit, state }, { agentUuid, groupUuid} ) {
        try {
            const agent = await LivechatService.removeAgentGroup(agentUuid, groupUuid);
            const agents = state.organization.agents;
            agents.find(a => a.uuid === agentUuid).groups = [...agent.groups]
            commit('setOrganization', {
                ...state.organization,
                agents: [...agents]
            });
        } catch (e) {
            console.error(e);
        }
    },

    async addAgentGroups({ commit, state }, { agentUuid, groups} ) {
        try {
            const agent = await LivechatService.addAgentGroups(agentUuid, groups);
            const agents = state.organization.agents;
            agents.find(a => a.uuid === agentUuid).groups = [...agent.groups]
            commit('setOrganization', {
                ...state.organization,
                agents: [...agents]
            });
        } catch (e) {
            console.error(e);
        }
    },
    /**
     * 
     * @param {object} context 
     * @param {object} payload - The payload object.
     * @param {string} payload.username - email of the agent.
     * @param {string[]} payload.groups - The UUIDs of the groups.
     */
    async createAndAddAgent({ commit, state }, { username, groups} ) {
        try {
            const agent = await LivechatService.createAndAddAgent(username, state.organization.uuid, groups);
            
            state.organization.agents.push(agent);
            commit('setOrganization', {
                ...state.organization
            });
        } catch (e) {
            console.error(e);
        }
    },
    async updateAgent({ commit, state }, agent ) {
        try {
            const updatedAgent = await LivechatService.updateAgent(agent);
            const agents = state.organization.agents;
            const currentAgent = agents.find(a => a.uuid === agent.uuid);
            
            Object.keys(updatedAgent).forEach(key => {
                currentAgent[key] = updatedAgent[key];
            });
            commit('setOrganization', {
                ...state.organization,
                agents: [...agents]
            });
        } catch (e) {
            console.error(e);
        }
    },
    async deleteAgent({ commit, state }, agentUuid ) {
        try {
            await LivechatService.deleteAgent(agentUuid);

            commit('setOrganization', {
                ...state.organization,
                agents: [...state.organization.agents.filter(a => a.uuid !== agentUuid)]
            });
        } catch (e) {
            console.error(e);
        }
    },
    /**
     * Adds a new group to the organization.
     * 
     * @param {object} context 
     * @param {import('@/services/livechat').Group} newGroup 
     */
    async createGroup({ commit, state }, newGroup) {
        try {
            const organization = await LivechatService.addGroupToOrganization(state.organization.uuid, newGroup);
            commit('setOrganization', {
                ...state.organization,
                groups: [...organization.groups]
            });
        } catch (e) {
            console.error(e);
        }
    },
    /**
     * Updates an existing group.
     * 
     * @param {object} context 
     * @param {import('@/services/livechat').Group} group 
     */
    async updateGroup({ commit, state }, group) {
        try {
            const organization = await LivechatService.updateGroup(state.organization.uuid, group);
            commit('setOrganization', {
                ...state.organization,
                groups: [...organization.groups]
            });
        } catch (e) {
            console.error(e);
            throw new Error("Failed to update group");
        }
    },
    // async fetchAgents({ commit }, organizationUuid) {
    //     const agents = await LivechatService.getAgents(organizationUuid);
    //     commit('setAgents', agents);
    // },
    async updateOrganization({ commit }, { uuid, organizationData}) {
        const updatedOrganization = await LivechatService.updateOrganizationLimited(uuid, organizationData);
        
        commit('setOrganization', updatedOrganization);
    },
}

const mutations = {
    setOrganization(state, organization) {
        if (state.organization) {
            state.organization = {
                ...state.organization,
                ...organization
            }
        } else {
            state.organization = organization;
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
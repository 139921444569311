<script>
export default {
	name: "ActionGapSeparator",
	props: {
		// Some places require double height, e.g. start of a new branch
		"doubleHeight": {
			type: Boolean,
			default: () => false,
		},
	},
}
</script>

<template>
	<div class="gap-separator" :class="{'double-height': doubleHeight}"></div>
</template>

<style scoped lang="scss">
.gap-separator {
	height: 15px;
}
.double-height {
  height: 30px;
}
.default-indent {
	/*
	Magic number:
		ActionLine.vue (.action-line) has a width of `$spacer * 10`
		The line (.action-line-line) is then centred with `left 50%`, which means 10/2 = 5.
	*/
	left: $spacer * 5;
}
</style>
<template>
  <div>
    <HubBoxSimple
      :title="$t('stats.export.title')"
      icon="mdi-file-download-outline"
      :isLoading="isLoading"
    >
      <template v-slot:content>
        <p class="body-text-2">
          {{ $t('stats.export.info') }}
        </p>
        <v-btn
          class="mt-4"
          outlined
          color="primary"
          @click="isDialogOpen = true; trackIt('stats-export-open');"
          :loading="isLoading"
        >
          {{ $t('stats.export.btnSelect') }}
          <v-icon right>mdi-file-download-outline</v-icon>
        </v-btn>
      </template>
    </HubBoxSimple>

    <HubDialog
      :value="isDialogOpen"
      :title="$t('stats.export.title')"
      hideActions
      @input="isDialogOpen = false"
    >
      <div class="text-body-2 mb-4">
        <div>Bot: "{{ currentBot.name }}"</div>
        <div v-if="channel">{{ $t('common.channel') }}: {{ channelDisplayName }}</div>
        <div>{{ $t('dateRange.labelDateRange') }}: {{ dateRangeFormatted }}</div>
        <div v-if="dateRangeCompareFormatted">
          {{ $t('dateRange.labelDateRangeCompare') }}: {{ dateRangeCompareFormatted }}
        </div>
      </div>
      <v-divider class="my-6"></v-divider>
      <div class="text-body-2 mb-4">
        {{ $t('stats.export.selectOption') }}
      </div>
      <div class="text-body-2 mb-2">
        <v-icon class="mr-2" size="20" color="primary lighten-3">
          {{ exportOptions[0].icon }}
        </v-icon>
        <strong>{{ $t('stats.export.optionPDF.title') }}:</strong>
        {{ $t('stats.export.optionPDF.description') }}
      </div>
      <div class="text-body-2 mb-2">
        <v-icon class="mr-2" size="18" color="primary lighten-3">
          {{ exportOptions[1].icon }}
        </v-icon>
        <strong>{{ $t('stats.export.optionCSV.title') }}:</strong>
        {{ $t('stats.export.optionCSV.description') }}
      </div>
      <div class="text-body-2 mb-2">
        <v-icon class="mr-2" size="18" color="primary lighten-3">
          {{ exportOptions[2].icon }}
        </v-icon>
        <strong>{{ $t('stats.export.optionCSV2.title') }}:</strong>
        {{ $t('stats.export.optionCSV2.description') }}
      </div>
      <v-select
        class="my-6"
        v-model="selectedOption"
        :label="$t('stats.export.exportKind')"
        :items="exportOptions"
        item-text="label"
        item-value="value"
        :disabled="isExporting"
      ></v-select>
      <v-card
        outlined
        style="min-height: 80px"
        class="d-flex align-center pa-4"
      >
        <v-progress-linear
          absolute
          v-if="isExporting"
          indeterminate
          color="secondary darken-2"
          style="top: 0; left: 0"
        />

        <div v-if="!selectedOption" class="text-body-2">
          {{ $t('stats.export.noOptionSelected') }}
        </div>

        <v-row v-if="selectedOption">
          <template
            v-if="selectedOptionObject.value === exportTypes.OVERVIEW_PDF"
          >
            <v-col cols="12" sm="7">
              <div class="d-flex align-center">
                <div>
                  <v-icon size="32" color="primary">
                    {{ selectedOptionObject.icon }}
                  </v-icon>
                </div>
                <div class="pl-2">
                  <div class="text-body font-weight-bold">
                    {{ selectedOptionObject.label }}
                  </div>
                  <div class="text-body-2 grey--text">
                    {{ selectedOptionObject.labelSub }}
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols="12" sm="5" class="d-flex align-center justify-end">
              <v-btn
                depressed
                color="primary lighten-2"
                @click="downloadExport"
              >
                {{ $t('common.download') }}
                <v-icon right>mdi-download</v-icon>
              </v-btn>
            </v-col>
          </template>
          <template v-else>
            <v-col cols="12" sm="7">
              <div v-if="!isExportDone" class="d-flex align-center">
                <div>
                  <v-icon size="32" color="primary">
                    {{ selectedOptionObject.icon }}
                  </v-icon>
                </div>
                <div class="pl-2">
                  <div class="text-body font-weight-bold">
                    {{ selectedOptionObject.label }}
                  </div>
                  <div class="text-body-2 grey--text">
                    <span v-if="!isExporting && !isExportDone">
                      {{ selectedOptionObject.labelSub }}
                    </span>
                    <span v-if="isExporting">
                      {{ loadingInfo }}
                    </span>
                  </div>
                </div>
              </div>
              <div v-if="isExportDone" class="d-flex align-center">
                <div>
                  <v-icon size="32" color="primary">task</v-icon>
                </div>
                <div class="pl-2">
                  <div class="text-body font-weight-bold">
                    {{ $t('stats.export.exportDone1') }}
                  </div>
                  <div class="text-body-2 grey--text">
                    {{ $t('stats.export.exportDone2') }}
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols="12" sm="5" class="d-flex align-center justify-end">
              <v-btn
                v-if="!isExportDone"
                outlined
                color="primary lighten-2"
                @click="startExport"
                :disabled="isExporting"
              >
                {{ $t('stats.export.btnCreateCSV') }}
                <v-icon right>mdi-database-export-outline</v-icon>
              </v-btn>
              <v-btn
                v-if="isExportDone"
                depressed
                color="primary lighten-2"
                @click="downloadExport"
              >
                {{ $t('common.download') }}
                <v-icon right>mdi-download</v-icon>
              </v-btn>
            </v-col>
          </template>
        </v-row>
      </v-card>
    </HubDialog>
    <StatsOverviewExport ref="exportOverview" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import HubBoxSimple from '@/components/hub/HubBoxSimple.vue';
import HubDialog from '@/components/hub/HubDialog.vue';
import StatsOverviewExport from '@/components/stats/StatsOverviewExport.vue';
import { TrackingService } from '@/services/tracking';
import { formatDateTime } from '@/utils/formatDateTime';

const exportTypes = {
  OVERVIEW_PDF: 'overviewPDF',
  PERFORMANCE_CSV: 'performanceCSV',
  ANSWERS_CSV: 'answersCSV',
};

export default {
  name: 'StatsSidebarExport',
  components: {
    HubBoxSimple,
    HubDialog,
    StatsOverviewExport,
  },
  data() {
    return {
      isDialogOpen: false,
      isLoading: false,
      exportTypes,
      selectedOption: null,
      exportOptions: [
        {
          label: this.$t('stats.export.optionPDF.title'),
          labelSub: this.$t('stats.export.exportReady'),
          value: exportTypes.OVERVIEW_PDF,
          icon: 'mdi-file-pdf-box',
        },
        {
          label: this.$t('stats.export.optionCSV.title'),
          labelSub: this.$t('stats.export.exportReadySoon'),
          value: exportTypes.PERFORMANCE_CSV,
          icon: 'mdi-file-delimited',
        },
        {
          label: this.$t('stats.export.optionCSV2.title'),
          labelSub: this.$t('stats.export.exportReadySoon'),
          value: exportTypes.ANSWERS_CSV,
          icon: 'mdi-file-delimited-outline',
        },
      ],
      formatDateTime,
    };
  },
  computed: {
    ...mapGetters('exportStats', [
      'exportsPerformance',
      'exportsAnswers',
      'isExporting',
      'isPerformanceDone',
      'isAnswersDone',
    ]),
    ...mapGetters('bots', [
      'currentBot',
      'currentChannels',
      'areChannelsBlocked',
      'currentBotId',
    ]),
    ...mapGetters('stats', [
      'channel',
      'dateRange',
      'dateRangeCompare',
      'dateRangeFormatted',
      'dateRangeCompareFormatted',
    ]),
    selectedOptionObject() {
      return this.exportOptions.find((o) => o.value === this.selectedOption);
    },
    channelDisplayName() {
      return this.currentChannels.find((c) => c.channelId === this.channel)?.displayName;
    },
    isExportDone() {
      if (
        this.selectedOption === exportTypes.PERFORMANCE_CSV &&
        this.isPerformanceDone
      ) {
        return true;
      }
      if (
        this.selectedOption === exportTypes.ANSWERS_CSV &&
        this.isAnswersDone
      ) {
        return true;
      }
      return false;
    },
    loadingInfo() {
      if (this.selectedOption === exportTypes.OVERVIEW_PDF) {
        return this.$t('stats.export.exporting');
      }

      if (this.selectedOption === exportTypes.PERFORMANCE_CSV) {
        return this.exportsPerformance[this.exportsPerformance.length - 1]?.date
          ? formatDateTime(
              this.exportsPerformance[this.exportsPerformance.length - 1]?.date,
              $t('common.dateFormatShort')
            )
          : this.$t('stats.export.exporting');
      }

      if (this.selectedOption === exportTypes.ANSWERS_CSV) {
        return this.exportsAnswers[this.exportsAnswers.length - 1]?.date
          ? formatDateTime(
              this.exportsAnswers[this.exportsAnswers.length - 1]?.date,
              $t('common.dateFormatShort')
            )
          : this.$t('stats.export.exporting');
      }

      return this.$t('stats.export.exporting');
    },
  },
  methods: {
    startExport() {
      switch (this.selectedOption) {
        case exportTypes.OVERVIEW_PDF:
          this.$refs.exportOverview.generateReport();
          break;
        case exportTypes.PERFORMANCE_CSV:
          this.$store.dispatch('exportStats/startPerformanceExport');
          break;
        case exportTypes.ANSWERS_CSV:
          this.$store.dispatch('exportStats/startAnswersExport');
          break;
      }
      this.trackIt('stats-export-start');
    },
    downloadExport() {
      switch (this.selectedOption) {
        case exportTypes.OVERVIEW_PDF:
          this.$refs.exportOverview.generateReport();
          break;
        case exportTypes.PERFORMANCE_CSV:
          this.$store.dispatch('exportStats/downloadCSVPerformance');
          break;
        case exportTypes.ANSWERS_CSV:
          this.$store.dispatch('exportStats/downloadCSVAnswers');
          break;
      }
      this.trackIt('stats-export-download');
    },
    trackIt(type = '') {
      const body = {
        botId: this.currentBotId,
        type,
        source: 'StatsSidebarExport.vue',
        payload: {
          selectedOption: this.selectedOption,
          fullPath: this.$route.fullPath,
        },
      };
      TrackingService.trackStatsView(body);
    },
  },
};
</script>
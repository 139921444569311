<template>
  <HubBox
    v-if="getSamples && getSamples.length > 0"
    icon="chat_bubble_outline"
    :title="$t('intents.samples')"
    :boxId="boxId"
    expandedInitial
    @onExpand="(isExpanded) => isExpanded && trackIt('open-intent-samples')"
  >
    <div class="pb-4">
      <v-list-item
        v-for="(sample, index) in getSamples"
        :key="index"
        selectable
        class="ref-item"
      >
        <v-card class="rounded-lg elevation-0 ma-1" color="grey lighten-3">
          <v-card-text class="pa-2 primary--text">
            {{ sample }}
          </v-card-text>
        </v-card>
      </v-list-item>
    </div>
    <div v-if="isAutoTranslated(intent.intent, user.language)" class="text-body-2 grey--text pl-6">
      {{ $t('common.autoTranslated') }}
    </div>
    <v-card flat class="pa-4" v-if="showInsightsButton">
      <v-divider class="mb-2"></v-divider>
      <div class="text-body-2 primary--text text--lighten-2 mb-3">
        {{ $t('intents.insightsDescription') }}
      </div>
      <v-btn outlined color="primary lighten-3" :to="`/conversations?intent=${intent.intent}`">Insights
        <v-icon right>mdi-forum-outline</v-icon>
      </v-btn>
    </v-card>
  </HubBox>
</template>
<script>
import HubBox from '@/components/hub/HubBox.vue';
import { mapGetters } from 'vuex';
import { TrackingService } from '@/services/tracking';

export default {
  name: 'IntentSamples',
  components: {
    HubBox,
  },
  props: {
    intent: {
      type: Object,
      required: true,
    },
    boxId: {
      type: String,
      default: 'IntentSamples',
    },
  },
  computed: {
    ...mapGetters('bots', ['currentBotId']),
    ...mapGetters('auth', ['user']),
    ...mapGetters('intents', ['samples', 'isAutoTranslated']),
    getSamples() {
      return this.samples(this.intent.intent, this.user.language);
    },
    showInsightsButton() {
      // if intent is set and intent is SimpleInformation template and intent is not in review or a suggestion
      return this.intent && this.intent.template === 'SimpleInformation' && !this.intent.suggested && !this.intent.payload?.inReview;
    },
  },
  methods: {
    trackIt(type) {
      TrackingService.trackIntentsView({
        botId: this.currentBotId,
        type,
        source: 'IntentSamples',
      });
    },
  },
};
</script>
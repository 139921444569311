<template>
  <div v-if="agent">
    <div class="text-body-2 grey--text text--darken-2 mb-6">
      {{ $t('livechat.profile.description') }}
    </div>

    <!-- 8< Temporäre Info für CSM Admins -->
    <div v-if="false" class="admin--text text-copy-2 mb-4">
      Pro Hub User gibt es aktuell einen Agenten. Geplant ist aber, dass pro Bot
      der Agent ein eigenes Profil hat, um Bild, Name und Titel entsprechend
      anzupassen.
    </div>
    <!-- >8 -->
    
    <div class="d-sm-flex">
      <div style="width: 150px">
        <ImageUpload
          :width="150"
          circleStyle
          :imageSrc="profilePicture"
          @onUpload="uploadImage($event)"
          :disabled="loading"
        />
      </div>
      <div
        class="flex-grow-1 items-center pt-4"
        :class="[$vuetify.breakpoint.smAndUp ? 'ml-6' : '']"
      >
        <v-text-field
          outlined
          dense
          :label="$t('livechat.profile.nameLabel')"
          type="text"
          required
          v-model="userName"
          :disabled="loading"
        ></v-text-field>
        <CountryLanguageSelect useLanguages v-model="selectedLanguages" />
        <!-- <v-text-field
          outlined
          dense
          :label="$t('livechat.profile.titleLabel')"
          type="text"
          v-model="titleInput"
          :disabled="loading"
        ></v-text-field> -->
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import HubEditorFrame from '@/components/hub/HubEditorFrame.vue';
import ImageUpload from '@/components/common/ImageUpload.vue';
import UploadService from '@/services/upload';
import CountryLanguageSelect from '@/components/common/CountryLanguageSelect.vue';
import _ from 'lodash';

export default {
  name: 'LivechatProfile',
  components: {
    HubEditorFrame,
    ImageUpload,
    CountryLanguageSelect,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      userName: '',
      titleInput: '',
      profilePicture: '',
      selectedLanguages: [],
    };
  },
  created() {
    this.init();
  },
  watch: {
    isDirty() {
      this.$emit('onDirty', this.isDirty);
    },
  },
  computed: {
    ...mapGetters('livechat', ['agent']),
    isDirty() {
      if (
        (this.userName !== this.agent?.name && this.agent?.name) ||
        (this.profilePicture !== this.agent?.profilePicture && this.agent?.profilePicture) ||
        (!_.isEqual(this.selectedLanguages, this.agent?.languages) && this.agent?.languages)
        //  || (this.titleInput !== this.agent?.title && this.agent?.title)
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    async init() {
      const isAgentExisting = await this.$store.dispatch('livechat/loadAgent');
      this.reset();
    },
    async uploadImage(imageFile) {
      if (!imageFile) return;
      let response = await UploadService.uploadFile(imageFile, () => {});
      if (response?.file?.location) {
        this.profilePicture = response.file.location;
      }
    },
    reset() {
      this.userName = this.agent?.name || '';
      this.profilePicture = this.agent?.profilePicture || '';
      this.titleInput = this.agent?.title || '';
      this.selectedLanguages = this.agent?.languages || [];
    },
    async save() {
      return await this.$store.dispatch('livechat/updateAgent', {
        name: this.userName,
        profilePicture: this.profilePicture,
        languages: this.selectedLanguages,
        // title: this.titleInput,
        isAgentSelf: true,
      });
    },
  },
};
</script>

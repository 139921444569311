<template>
  <div>
    <!-- Nothing shown, only for PDF generation. (Used in StatsSidebarActiveFilter.vue) -->

    <VueHtml2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :filename="pdfName"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="landscape"
      pdf-content-width="1130px"
      @hasPaginated="exportDone"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <v-app class="pa-0 ma-0">
          <!-- page 1 -->
          <StatsPdfCover />
          <!-- page 2 -->
          <div class="html2pdf__page-break" />
          <div class="page-wrap">
            <v-container class="pt-8">
              <v-row>
                <v-col cols="12" md="6">
                  <StatsChartConversation :height="chartHeight" />
                </v-col>
                <v-col cols="12" md="6">
                  <StatsChartAutomation :height="chartHeight" />
                </v-col>
              </v-row>
            </v-container>
          </div>
          <!-- page 3 -->
          <div class="html2pdf__page-break" />
          <div class="page-wrap">
            <v-container class="pt-8">
              <v-row>
                <v-col cols="12" md="6">
                  <StatsChartFeedback positive :height="chartHeight" />
                </v-col>
                <v-col cols="12" md="6">
                  <StatsChartFeedback negative :height="chartHeight" />
                </v-col>
              </v-row>
            </v-container>
          </div>
          <!-- page 4 -->
          <div class="html2pdf__page-break" />
          <div class="page-wrap">
            <v-container class="pt-8">
              <v-row>
                <v-col cols="12" md="6">
                  <StatsChartConversions />
                </v-col>
                <v-col cols="12" md="6">
                  <StatsChartTakeovers />
                </v-col>
              </v-row>
            </v-container>
          </div>
          <!-- page 5 -->
          <div class="html2pdf__page-break" />
          <div class="page-wrap">
            <v-container class="pt-8">
              <v-row>
                <v-col cols="12">
                  <StatsIntents />
                </v-col>
              </v-row>
            </v-container>
          </div>
        </v-app>
      </section>
    </VueHtml2pdf>
  </div>
</template>
<script>
import VueHtml2pdf from 'vue-html2pdf';
import { mapGetters } from 'vuex';
import StatsChartConversation from '@/components/stats/components/StatsChartConversation.vue';
import StatsChartAutomation from '@/components/stats/components/StatsChartAutomation.vue';
import StatsChartFeedback from '@/components/stats/components/StatsChartFeedback.vue';
import StatsChartConversions from '@/components/stats/components/StatsChartConversions.vue';
import StatsChartTakeovers from '@/components/stats/components/StatsChartTakeovers.vue';
import StatsIntents from '@/components/stats/components/StatsIntents.vue';
import StatsPdfCover from '@/components/stats/components/StatsPdfCover.vue';

export default {
  name: 'StatsOverviewExport',
  components: {
    VueHtml2pdf,
    StatsChartConversation,
    StatsChartAutomation,
    StatsChartFeedback,
    StatsChartConversions,
    StatsChartTakeovers,
    StatsIntents,
    StatsPdfCover,
  },
  data() {
    return {
      chartHeight: '400px',
    };
  },
  computed: {
    ...mapGetters('bots', [
      'currentBot',
      'currentChannels',
    ]),
    ...mapGetters('stats', [
      'channel',
      'dateRangeFormatted',
      'dateRangeCompareFormatted',
    ]),
    pdfName() {
      return 'moinAI_bericht';
    },
  },
  methods: {
    generateReport() {
      this.$store.commit('exportStats/setValue', {
        key: 'isExporting',
        value: true,
      });
      this.$refs.html2Pdf.generatePdf();
    },
    exportDone() {
      this.$store.commit('exportStats/setValue', {
        key: 'isExporting',
        value: false,
      });
    },
  },
};
</script>
<style scoped>
.page-wrap {
  width: 1060px;
  padding-top: 50px;
  margin-left: auto;
  margin-right: auto;
}
</style>
<template>
  <v-card v-if="aiFeedbackAvailable" outlined>
    <v-card-title v-if="!isEmbbeded">
      <v-icon
        large
        left color="secondary darken-2"
      >
        school
      </v-icon>
      <span class="ml-2 text-h6">Feedback</span>
    </v-card-title>
    <v-card-subtitle class="mt-1">{{ $t('ai.tool.requestLabel') }}:</v-card-subtitle>
    <v-textarea
      ref="request"
      class="mx-4" :value="requestedText"
      color="secondary darken-2"
      counter="200"
      :label="$t('ai.tool.request')" auto-grow solo flat outlined autofocus
      @input="update"
    />
   
    <div class="d-flex justify-space-between align-center mx-4 mb-8">
      <div>
        <span v-if="aiFetching" class="grey--text">thinking...</span>
        <template v-else>
          <span v-if="aiFetched">{{ displayNameIntent(detectedIntent) }}</span>
          <span v-else class="grey--text">{{ $t('ai.tool.detectedintent') }}</span>
        </template>
      </div>
      <div>
        <BtnSquare icon="check" colorIcon="success" colorBtn="success" :disabled="!requestedText" @click="()=> feedback = 'pos'"/>
        <BtnSquare icon="close" colorIcon="error" colorBtn="error" :disabled="!requestedText" @click="()=> feedback = 'neg'"/>
      </div>
    </div>
    <v-row>
      <v-col cols="12">
        <div class="px-4">
          <v-autocomplete
            v-show="errorMode"
            ref="aiintent"
            v-model="newIntent"
            :items="allIntents"
            item-text="displayName"
            item-value="intent"
            cache-items
            flat
            hide-no-data
            color="secondary darken-2"
            outlined
            :label="$t('ai.tool.correctintent')"
            :disabled="!errorMode"
          />
        </div>
        <v-card-actions class="pa-4">
          <v-btn v-if="!isEmbbeded" :disabled="!requestedText" color="secondary darken-2" text @click="cancel">{{ $t('common.cancel') }}</v-btn>
          <v-spacer />
          <v-btn :disabled="!completed" color="secondary darken-2" text @click="sendFeedback">Feedback</v-btn>
        </v-card-actions>
        <v-snackbar
          v-model="savingToast"
          :timeout="3000"
        >
          {{ $t('ai.tool.success') }}<v-spacer /><v-icon color="success">check</v-icon>
        </v-snackbar>
      </v-col>
    </v-row>
    <v-row v-if="isKnowledge" class="ml-4 mr-4 mb-4">
      <v-col cols="12">
        <v-divider></v-divider>
        <v-btn :disabled="!!(generatedAnswer || generatedAnswerErrorNoKnowledge)" :loading="answerGenerating" @click="generateAnswer" outlined class="mt-2" small><v-icon left small>auto_awesome</v-icon>Antwort generieren</v-btn>
      </v-col>
      <v-col v-if="generatedAnswer" cols="12">
        <v-divider></v-divider>
        <v-textarea
          v-model="generatedAnswer"
          readonly
          color="secondary darken-2"
          :label="`Antwort`" auto-grow solo flat outlined
        />
      </v-col>
      <v-col v-if="generatedAnswerErrorNoKnowledge" cols="12">
        <v-divider></v-divider>
        <v-alert
          outlined
          color="error"
          icon="mdi-alert"
        >
          <span>Es konnte keine Antwort generiert werden. Nicht genügend Wissen in der Knowledge Base.</span>
        </v-alert>
      </v-col>
      <v-col v-if="generatedAnswerError" cols="12">
        <v-divider></v-divider>
        <v-alert
          outlined
          color="error"
          icon="mdi-alert"
        >
          <span>Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.</span>
        </v-alert>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
    import _ from 'lodash'
    import ProductService from '../services/product'
    import BtnSquare from "@/components/common/BtnSquare.vue"
    import { mapGetters } from 'vuex'
    import RAGService from '@/services/rag';

    export default {
      name: 'AiFeedback',
      components: {
        BtnSquare
      },
      props: {
        text: {
          type: String,
          default: '',
        },
        embedded: {
          type: Boolean,
          default: false,
        },
      },
      data() {
        return {
          feedback: null,
          detectedIntent: null,
          requestedText: this.text,
          newIntent: null,

          conf: null,
          aiFetching: false,
          aiFetched: false,
          answerGenerating: false,
          generatedAnswer: null,
          savingToast: false,
          generatedAnswerErrorNoKnowledge: false,
          generatedAnswerError: false,
        }
      },
      computed: {
        isEmbbeded() {
          return this.embedded !== undefined
        },
        ...mapGetters('bots', [
          'currentBot',
          'currentLicence',
        ]),
        ...mapGetters('intents', [
          'intents',
          'bookedIntents',
          'suggested',
          'getIntentFromLibraryByName',
          'getIntentsFromLibrary',
          'displayName',
        ]),
        ...mapGetters('auth', [
          'isAdmin',
          'isNewIntent',
          'isSuggestion',
        ]),
        aiFeedbackAvailable() {
          return (Array.isArray(this.intents) && this.intents.length > 0) ||
            this.fallbackIntent;
        },
        fallbackIntent() {
          if (typeof this.currentBot?.fallbackFaqIntent === 'object') {
            
            if (this.currentBot?.fallbackFaqIntent?.intent && this.currentBot?.fallbackFaqIntent?.active === true) {
              return this.currentBot?.fallbackFaqIntent?.intent;
            } else {
              return undefined;
            }
          }

          return this.currentBot?.fallbackFaqIntent?.intent;
        },
        isKnowledge() {
          
          if (this.embedded) {
            return false
          }

          if (this.detectedIntent === this.fallbackIntent) {
            return true;
          }

          const intent = this.intents.find(i => i.intent === this.detectedIntent);
          if (intent && intent.template === 'RAGTopic') {
            return true;
          }

          return false;
        },
        errorMode() {
          if (this.feedback && this.feedback === 'neg') {
            return true
          }

          return false
        },
        completed() {
          if (!this.requestedText) {
            return false
          }

          if (!this.feedback) {
            return false
          }

          if (this.feedback === 'neg' && !this.newIntent) {
            return false
          }

          return true
        },
        intentsLoading: function() {
          return this.intents === false
        },
        allIntents: function() {
          let intentsList = []
          if (this.intents) {
            const concatedIntents = this.intents.concat(this.suggested).concat([
              { displayName: this.$t('ai.tool.unknown'), intent: 'unknown' },
              { displayName: this.$t('intent.smalltalk.label'), intent: 'smalltalk' },
            ])
            intentsList = concatedIntents
          }
          if (this.isAdmin || this.isNewIntent || this.isDemoLicence) {
            intentsList = intentsList.concat(this.getStandardIntents())
          }

          intentsList = intentsList.map((i) => {
            return {
              ...i,
              displayName: this.displayNameIntent(i.intent),
            }
          })

          return intentsList
        },
        isDemoLicence: function () {
          return this.currentLicence === "demo"
        }
      },
      watch: {
        currentBot: function() {
          this.cancel()
        },
        feedback: function(newFeedback) {
          if (newFeedback === 'neg') {
            this.$nextTick(() => {
              this.$refs.aiintent.focus()
            })
          }
        },
      },
      created() {
        if (this.text) {
          this.fetchAI()
        }
      },
      methods: {
        displayNameIntent(intent) {

          if (this.intents && intent) {
            if (intent === 'smalltalk') {
              return this.$t('intent.smalltalk.label')
            }

            if (intent === 'unknown' || intent === 'TOXIC' || intent === 'NOISE' || intent === 'NAME') {
              return this.$t('ai.tool.unknown')
            }

            if (intent === this.fallbackIntent) {
              return this.$t('ai.tool.fallback')
            }

            return this.displayName(intent, this.$vuetify.lang.current);
          }
          return ''
        },
        async sendFeedback() {
          this.savingToast = true

          if (this.feedback === 'pos') {
            ProductService.nlpFeedback(this.currentBot.uniqueBotId, this.requestedText, true, this.detectedIntent)
          } else {
            if (this.detectedIntent && this.detectedIntent !== 'unknown' && this.detectedIntent !== 'smalltalk') {
              ProductService.nlpFeedback(this.currentBot.uniqueBotId, this.requestedText, false, this.detectedIntent)
            }

            ProductService.nlpFeedback(this.currentBot.uniqueBotId, this.requestedText, true, this.newIntent)
          }

          this.cancel()
        },
        cancel() {
          /**
           * reset all fields
           */
          this.feedback = null
          this.detectedIntent = null
          this.requestedText = null
          this.newIntent = null
          if (!this.isEmbbeded) {
            this.$refs.request.focus()
          } else {
            const $this = this
            setTimeout(() => {
              $this.$emit('close')
            }, 1000)
          }
        },
        async fetchAI() {
          this.detectedIntent = null;
          this.generatedAnswer = null;
          this.answerGenerating = false;
          this.generatedAnswerErrorNoKnowledge = false;
          this.generatedAnswerError = false;
          this.aiFetching = true;
          this.aiFetched = false;
          this.$emit('onAiFetching');
          const result = await ProductService.nlpCheck(this.currentBot.uniqueBotId, this.requestedText)

          if (result.check.nlp.intent.startsWith('smalltalk_')) {
            this.detectedIntent = 'smalltalk'
            this.conf = result.check.nlp.confidence
          } else {
            this.detectedIntent = result.check.nlp.intent
            this.conf = result.check.nlp.confidence
          }
          this.aiFetching = false;
          this.aiFetched = true;
          this.$emit('onAiFetched', {text: this.requestedText});
        },
        setErrorMode() {
          this.errorMode = true
          this.$refs.aiintent.focus()
        },
        update: _.debounce(async function(e) {
          this.requestedText = e
          this.feedback = null

          if (!this.aiFetching && this.requestedText) {
            await this.fetchAI()

          } else if (!this.requestedText && !this.aiFetching) {
            this.cancel()
          }

        }, 1000),
        getStandardIntents () {
          let standardIntents = this.getIntentsFromLibrary
            .filter((i) => i.standard)
            .map((i) => {
              return {
                ...i,
                displayName: `${i.displayName} ${this.$t('ai.fromTemplate')}`
              }
            })
          return standardIntents || []
        },
        async generateAnswer() {
          this.answerGenerating = true;
          this.generatedAnswerErrorNoKnowledge = false;
          this.generatedAnswerError = false;
          let channelId = null;
          const r = await RAGService.getAnswer(
            this.currentBot?.stagingBot || this.currentBotId,
            this.detectedIntent,
            this.requestedText,
            {
              topK: 15
            },
            channelId,
          );

          this.answerGenerating = false;
          this.generatedAnswer = r.result?.answer;

          if (r.status === 'error' && r.code === 101) {
            this.generatedAnswerErrorNoKnowledge = true;
            this.generatedAnswer = null;
          } else if (r.status === 'error') {
            this.generatedAnswer = null;
            this.generatedAnswerError = true;
          }
        }
      },
    }
</script>

<template>
<v-row>
  <v-col cols="3">
  <v-card class="p-4 mb-8" outlined elevation="2">
    <div class="d-flex justify-center align-center" style="min-height: 150px;">
      <!-- Temporary placeholder for the images / interactive Elements -->
      <div v-if="!demoelement" style="width: 60px; height: 60px; border-radius: 50%; display: flex; justify-content: center; align-items: center;" :style="{background: getCurrentColor, color: getContrast}">
        <svg data-v-a4e29072="" width="38px" height="32px" :fill="getContrast" viewBox="0 0 38 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="Symbols" stroke="none" stroke-width="1" fill-rule="evenodd"><g id="10-Teaser-/-Icon-/-nobadge" transform="translate(-10.000000, -18.000000)"><path d="M42.7870161,18 C45.2722974,18 47.2870161,20.0147186 47.2870161,22.5 L47.2870161,45.6110514 L47.3519671,49.5 L39.7354526,45.6917427 L14.9875798,45.6234373 C12.5071569,45.6165912 10.5,43.6038868 10.5,41.1234545 L10.5,22.5 C10.5,20.0147186 12.5147186,18 15,18 L42.7870161,18 Z M19.0396423,29.7817959 C17.1467242,29.7817959 15.6122108,31.3163093 15.6122108,33.2092275 C15.6122108,35.1021456 17.1467242,36.636659 19.0396423,36.636659 C20.9325605,36.636659 22.4670739,35.1021456 22.4670739,33.2092275 C22.4670739,31.3163093 20.9325605,29.7817959 19.0396423,29.7817959 Z M29.2552925,29.7817959 C27.3623743,29.7817959 25.8278609,31.3163093 25.8278609,33.2092275 C25.8278609,35.1021456 27.3623743,36.636659 29.2552925,36.636659 C31.1482106,36.636659 32.682724,35.1021456 32.682724,33.2092275 C32.682724,31.3163093 31.1482106,29.7817959 29.2552925,29.7817959 Z M39.4709426,29.7817959 C37.5780244,29.7817959 36.043511,31.3163093 36.043511,33.2092275 C36.043511,35.1021456 37.5780244,36.636659 39.4709426,36.636659 C41.3638608,36.636659 42.8983741,35.1021456 42.8983741,33.2092275 C42.8983741,31.3163093 41.3638608,29.7817959 39.4709426,29.7817959 Z" id="Chatbubble" ></path></g></g></svg>
      </div>
      <component :is="demoelement" :colors="{color: getCurrentColor, contrast: getContrast }"/>
    </div>
  </v-card>
  </v-col>
  <v-col cols="7">
  <v-card color="transparent" flat>
    <v-card-title class="pt-0">{{this.title}}</v-card-title>
    <v-card-subtitle>
      {{this.subtitle}}
    </v-card-subtitle>
    <v-card-text>
      {{this.explaination}}
    </v-card-text>
    <v-card-subtitle class="pb-0"> <strong>Farbe</strong> </v-card-subtitle>
    <v-card-actions class="px-16">
        <div class="d-flex">
          <div v-for="(color, index) in this.colors" :key="index">
            <v-chip
            outlined
            class="ma-1"
            color="blue-grey lighten-3"
            text-color="black"
            @click="selectColor(color.name)"
            >
            <v-avatar left>
              <v-icon v-if="value.color === color.name" :color="color.color">mdi-checkbox-marked-circle</v-icon>
              <v-icon v-else :color="color.color">mdi-checkbox-blank-circle</v-icon>
            </v-avatar>
            {{color.name}}
            </v-chip>
          </div>
        </div>
    </v-card-actions>
    
    <v-card-subtitle class="pb-0"> <strong>Kontrast</strong> </v-card-subtitle>
    <v-card-actions>
        <v-chip
          class="ma-2"
          color="blue-grey lighten-3"
          outlined
          text-color="black"
          @click="selectContrast('light')"
        >
          <v-avatar left>
            <v-icon v-if="value.contrast === 'light'">mdi-checkbox-marked-circle</v-icon>
            <v-icon v-else>mdi-checkbox-blank-circle</v-icon>
          </v-avatar>
          Light mode
        </v-chip>
        <v-chip
          class="ma-2"
          outlined
          text-color="black"
          color="blue-grey lighten-3"
          @click="selectContrast('dark')"
        >
          <v-avatar left>
            <v-icon v-if="value.contrast === 'dark'">mdi-checkbox-marked-circle</v-icon>
            <v-icon v-else>mdi-checkbox-blank-circle</v-icon>
          </v-avatar>
          Dark mode
        </v-chip>
    </v-card-actions>
<!--     <v-divider class="mx-4"></v-divider>
    <v-card-title>More Options</v-card-title>
    <v-card-actions>
        <v-switch value input-value="true"></v-switch>
    </v-card-actions> -->
    </v-card>
  
  </v-col>
</v-row>
</template>

<script>
import DemoHeader from '@/components/channelSettings/demoElements/DemoHeader.vue';
import DemoMessage from '@/components/channelSettings/demoElements/DemoMessage.vue';

export default {
  name: 'SettingsEditor',
  props: ['value', 'colors', 'title', 'subtitle', 'explaination', 'demoelement'],
  components: { DemoHeader, DemoMessage },
  data() {
    return {
    }
  },
  computed: {
    getContrast() {
      return this.value.contrast === 'light' ? '#fff' : '#333';
    },
    getCurrentColor() {
      return this.$store.getters['channelsettings/getThemeColorcodeByType'](this.value.color)
    },
  },
  methods: {
    selectColor(color) {
      
      this.emitColors(color, this.value.contrast);
    },
    selectContrast(contrast){
      
      this.emitColors(this.value.color, contrast);
    },
    emitColors(color, contrast) {
      const colorOptions = {
        color,
        contrast
      }; 
      this.$emit('input', colorOptions);
    }
  }
};
</script>

<style>
</style>
<script>
import HubMenu from '@/components/hub/HubMenu.vue';
import Appbar from '@/components/Appbar.vue';

export default {
  name: 'SettingsAI',
  components: { Appbar, HubMenu },
};
</script>

<template>
  <v-app style="max-height: 100vh; height: 100vh">
    <HubMenu />
    <Appbar />
    <v-main class="full-height">
      <RouterView />
    </v-main>
  </v-app>
</template>

<template>
  <HubBoxSimple :title="$t('stats.activeFilters')" icon="filter_alt" titleHeightAuto>
    <template v-slot:content>
      <div class="mb-3">
        <label class="text-body-2 grey--text">
          {{ $t('dateRange.labelDateRange') }}
        </label>
        <div class="text-body-1">{{ dateRangeFormatted }}</div>
      </div>
      <div class="mb-3" v-if="dateRangeCompare">
        <label class="text-body-2 grey--text">
          {{ $t('dateRange.labelDateRangeCompare') }}
        </label>
        <div class="text-body-1">{{ dateRangeCompareFormatted }}</div>
      </div>
      <div class="mb-3" v-if="currentChannels.length > 1">
        <label class="text-body-2 grey--text">
          {{ $t('common.channel') }}
        </label>
        <div class="text-body-1" v-if="!channel">
          {{ $t('common.allChannelsLabel') }}
        </div>
        <div class="text-body-1" v-else>{{ channelName }}</div>
      </div>
    </template>
  </HubBoxSimple>
</template>
<script>
import { mapGetters } from 'vuex';
import HubBoxSimple from '@/components/hub/HubBoxSimple.vue';
import DateRangeUtil from '@/utils/dateRange';

export default {
  name: 'StatsSidebarActiveFilter',
  data() {
    return {};
  },
  components: {
    HubBoxSimple,
  },
  computed: {
    ...mapGetters('stats', ['dateRange', 'dateRangeCompare', 'channel']),
    ...mapGetters('bots', ['currentChannels']),
    ...mapGetters('auth', ['user']),
    dateRangeFormatted() {
      return DateRangeUtil.getDateRangeFormatted({
        dateRange: this.dateRange,
        language: this.user.language,
      });
    },
    dateRangeCompareFormatted() {
      if (!this.dateRangeCompare) return null;

      return DateRangeUtil.getDateRangeFormatted({
        dateRange: this.dateRangeCompare,
        language: this.user.language,
      });
    },
    channelName() {
      if (!this.channel || !this.currentChannels.length) return null;

      return this.currentChannels.find((c) => c.channelId === this.channel)
        .displayName || '';
    },
  },
};
</script>

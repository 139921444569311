import { DashboardService } from '../../services/dashboard'

// initial state for authentication
const state =  {
    successRate: null,
    topAnswers: false,
    top80quantil: 0 // 80% Quantil of the top answers
}

const getters = {
    successRate: (state) => {
        return state.successRate;
    },
    topAnswers: (state) => {
        return state.topAnswers;
    },
    top80quantil: (state) => {
        return state.top80quantil;
    }
}

const actions = {
    async fetchAll({ commit }, uniqueBotId) {
        const bots = await DashboardService.fetch(uniqueBotId);
        commit('setSuccessRate', bots.successRate);
        return true;
    },
    async fetchTopAnswers({commit}, {uniqueBotId, intents}) {
        const answers = await DashboardService.topAnswers(uniqueBotId);
        if (answers && answers.result && answers.result.answers) {

            let all = 0;
            answers.result.answers = answers.result.answers.map(a => {
                all += a.count; // count all Top Counts

                let intent = intents.find(i => {
                    return i.intent === a.name;
                });
        
                if (intent) {
                    a.displayName = intent.displayName;
                } else {
                    a.displayName = a.name;
                }

                return a;
            });
         
            let reached = 0;
            let quantil = answers.result.answers.filter(a => {
                if (reached / all >= 0.8) return false;

                reached += a.count;
                return true;
            })

            commit('setTopAnswers', answers.result.answers);
            commit('setTop80Quantil', quantil.length);
        }
    },
    clear({commit}) {
        commit('setTopAnswers', false);
        commit('setTop80Quantil', 0);
    }
}

const mutations = {
    setSuccessRate(state, rate) {
        state.successRate = rate;
    },
    setTopAnswers(state, answers) {
        state.topAnswers = answers;
    },
    setTop80Quantil(state, count) {
        state.top80quantil = count;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
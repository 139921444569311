import ApiService from './api';

// Please read the docu:
// https://github.com/knowhereto/moin-hub/wiki/User-Tracking

const trackingNameTypes = {
  newIntent: 'new-intent',
  menu: 'menu',
  welcomeView: 'welcome-view',
  copyPaste: 'copy-paste',
  keywords: 'keywords',
  settings: 'settings',
  settingsRoles: 'settings-roles',
  settingsPrivacy: 'settings-privacy',
  settingsFiles: 'settings-files',
  teasers: 'teasers',
  intentsView: 'intents-view',
  statsView: 'statistics',
  channelManagement: 'channel-management',
};

const TrackingService = {
  async track(body) {
    
    if (!body.botId) {
      return false;
    }

    try {
      const response = await ApiService.post(
        '/schaltzentrale/tracking/' + body.botId,
        body
      );
      return response.data;
    } catch (error) {
      return false;
    }
  },
  trackNewIntent(body) {
    body.name = trackingNameTypes.newIntent;
    this.track(body);
  },
  trackMenu(body) {
    body.name = trackingNameTypes.menu;
    this.track(body);
  },
  trackWelcomeView(body) {
    body.name = trackingNameTypes.welcomeView;
    this.track(body);
  },
  trackCopyPaste(body) {
    body.name = trackingNameTypes.copyPaste;
    this.track(body);
  },
  trackKeywords(body) {
    body.name = trackingNameTypes.keywords;
    this.track(body);
  },
  trackSettings(body) {
    body.name = trackingNameTypes.settings;
    this.track(body);
  },
  trackSettingsRoles(body) {
    body.name = trackingNameTypes.settingsRoles;
    this.track(body);
  },
  trackSettingsPrivacy(body) {
    body.name = trackingNameTypes.settingsPrivacy;
    this.track(body);
  },
  trackSettingsFiles(body) {
    body.name = trackingNameTypes.settingsFiles;
    this.track(body);
  },
  trackTeasers(body) {
    body.name = trackingNameTypes.teasers;
    this.track(body);
  },
  trackIntentsView(body) {
    body.name = trackingNameTypes.intentsView;
    this.track(body);
  },
  trackStatsView(body) {
    body.name = trackingNameTypes.statsView;
    this.track(body);
  },
  trackChannelManagement(body) {
    body.name = trackingNameTypes.channelManagement;
    this.track(body);
  },
};

export default TrackingService;

export { TrackingService };

export function createCSVDownload(exportData) {
  if (!exportData?.length) return 'no data';
  let csv = '';
  // headline for csv from json keys
  csv += Object.keys(exportData[0]).join(',') + '\n';
  // loop through json data
  exportData.forEach(function(row) {
    csv += Object.values(row).join(',') + '\n';
  });
  return csv;
}

export function createDownloadWithCSV(csv, name) {
  const anchor = document.createElement('a');
  anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
  anchor.target = '_blank';

  anchor.download = `${name}.csv`;
  return anchor;
}

export function getNameWithDateRange(dateRange) {
  return `export-${dateRange[0].format('YYYY-MM-DD')}-${dateRange[1].format(
    'YYYY-MM-DD'
  )}`;
}

<template>
  <div v-if="content">
    <v-timeline-item hide-dot color="grey lighten-3" fill-dot v-if="content">
      <v-row>
        <v-col>
          <v-text-field
            outlined
            class="font-weight-medium ma-0"
            dense
            v-model="content.displayName"
            :label="$t('botFormEdit.whatIsAsked')"
            :disabled="isLivechatRating"
            @input="update"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-timeline-item>

    <ActionList
      v-if="content"
      :actions="content.answer.actions"
      @onUpdate="update"
      @hook:mounted="updateName"
      :channelType="channelType"
    ></ActionList>
 
    <v-timeline-item color="white" v-if="selectedAnswer">
      <template v-slot:icon>
        <v-icon small>account_tree</v-icon>
      </template>
      <v-chip-group>
        <v-chip
          close-icon="mdi-delete"
          :close="answers.length > 1 && !isLivechatRating"
          @click:close="removeAnswer(answer)"
          :color="active(answer) ? 'primary': ''"
          @click="select(answer)"
          v-for="(answer, index) of answers"
          :key="index"
        >{{ contextString(index) }}</v-chip>
        <v-chip v-if="!isLivechatRating && isAdmin" @click="addAnswer" outlined>
          <v-avatar left>
            <v-icon>add</v-icon>
          </v-avatar>{{ $t('botFormEdit.newAnswer') }}
        </v-chip>
      </v-chip-group>
      <div v-if="selectedAnswerContextEditable && isAdmin">
        <!-- {"name":"numbers_situation", "eq":1} -->
        <v-text-field :value="editableName" @input="changeName"></v-text-field>
        <v-select :value="editableOperator" :items="operators" item-text="label" item-value="operator" @change="changeOperator"></v-select>
        <v-text-field v-if="editableOperator !== 'exists'" :value="editableValue" @input="changeValue"></v-text-field>
      </div>
      <div v-else-if="isAdmin">
        Bedingung nicht editierbar: {{ selectedAnswer.context }}
        <v-btn @click="doEditable">Editierbar machen</v-btn>
      </div>
    </v-timeline-item>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ActionList from '@/components/ActionList.vue';
import ChannelsUtil from '@/utils/channels';
/* import ActionEdit from '@/components/ActionEdit.vue';
// import FollowupEdit from '@/components/editor/FollowupEdit.vue';
import AnswerTemplate from '@/components/editor/AnswerTemplate.js';
import AnswerInfoEdit from '@/components/editor/AnswerInfoEdit.vue';
import YesNoQuestionTransEdit from '@/components/editor/YesNoQuestionTransEdit.vue'; */

// import MultipleChoiceInfoEdit from '@/components/editor/MultipleChoiceInfoEdit.vue';

export default {
  name: 'BotFormConditionalSwitch',
  components: {
    ActionList,
  },
  props: ['box', 'intent', 'channel', 'name'],
  data: () => ({
    tab: null,
  }),
  async created() {},
  computed: {
    ...mapGetters('intents', ['intents']),
    ...mapGetters('bots', ['currentBotId', 'currentChannels']),
    ...mapGetters('auth', ['isAdmin']),
    ...mapGetters('forms', ['forms']),
    content() {
      return this.$store.getters['content/getContent'](
        this.currentBotId,
        this.intent,
        this.box,
        true,
        this.channel
      );
    },
    operators() {
      /* {operator: 'eq', label: '='} */
      return [
        { operator: 'eq', label: '=' },
        { operator: 'lt', label: '<' },
        { operator: 'gt', label: '>' },
        { operator: 'lte', label: '<=' },
        { operator: 'gte', label: '>=' },
        { operator: 'exists', label: 'existiert'},
        { operator: 'not_false', label: 'not false' }
      ];
    },
    answers() {
      return this.content?.answers || [];
    },
    selectedAnswer() {
      if (!this.selectedPath) {
        return this.answers[0];
      } else {
        let answer = this.answers.find((a) => {
          return a.intent === this.selectedPath;
        });

        if (!answer) {
          return this.answers[0];
        }

        return answer;
      }
    },
    selectedAnswerContextEditable() {
      if (!this.selectedAnswer) {
        return false;
      }

      return this.answerContextEditable(this.selectedAnswer);
    },
    selectedPath() {
      return this.$store.getters['formsEditor/getSelectedPath'](this.box);
    },
    isLivechatRating() {
      const currentForm = this.forms.find((f) => 
        f.intent === this.intent
      );
      
      return currentForm?.template === 'BotFormRating' && this.content?.context === 'rating';
    },
    channelType() {
      return ChannelsUtil.getChannelType(
        this.content?.channel,
        this.currentChannels
      );
    },
    editableName() {
      return this.selectedAnswerContextEditable.name;
    },
    editableOperator() {
      // return the operator, key of the object that is not name
      return this.editableOperatorByAnswer(this.selectedAnswer)
    },
    editableValue() {
      let ctx = this.selectedAnswerContextEditable;
      for (let key in ctx) {
        if (key !== 'name') {
          return ctx[key];
        }
      }

      return null;
    },
  },
  methods: {
    active(answer) {
      return this.selectedPath === answer.intent;
    },
    select(answer) {
      this.$store.commit('formsEditor/selectPath', {
        box: this.box,
        intent: answer.intent,
      });
      this.$store.dispatch(
        'formsEditor/updateRenderedSteps',
        this.currentBotId
      );
    },
    answerContextEditable(answer) {
      let ctx = answer.context;
      try {
        if (ctx && ctx.length === 1) {
          ctx = JSON.parse(ctx[0]);
          if (ctx.name && (ctx.hasOwnProperty('eq') || ctx.hasOwnProperty('lt') || ctx.hasOwnProperty('gt') || ctx.hasOwnProperty('lte') || ctx.hasOwnProperty('gte') || ctx.hasOwnProperty('not_false'))) {
            return ctx;
          }        
        }
      } catch(e) {

        if (typeof ctx[0] === 'string') {
          // return exists operator
          return {
            name: ctx[0],
            exists: true,
          };
        }

        return false;
      }

      return false;
    },
    contextString(index) {
      const answer = this.answers[index];
      const ctx = this.answerContextEditable(answer);
    
      if (ctx === false) {
        return this.selectedAnswer?.context;
      }

      if (this.editableOperatorByAnswer(answer) === 'exists') {
        return `${ctx.name} ${this.operators.find((o) => o.operator === this.editableOperatorByAnswer(answer)).label}`;
      }
      // use displayname of operator
      return `${ctx.name} ${this.operators.find((o) => o.operator === this.editableOperatorByAnswer(answer)).label} ${ctx[this.editableOperatorByAnswer(answer)]}`;
    },
    editableOperatorByAnswer(answer) {
      // return the operator, key of the object that is not name
      let ctx = this.answerContextEditable(answer);
      for (let key in ctx) {
        if (key !== 'name') {
          return key;
        }
      }

      return null;
    },
    addAnswer() {
      let intent = 'value' + Date.now();
      this.answers.push({ context: ['{"name":"user_text","eq": 0}'], intent });
      this.$emit('addMultiStep', { intent, box: this.box });
    },
    removeAnswer(answer) {
      let intent = answer.intent;
    
      if (
        this.$store.getters['formsEditor/getSelectedPath'](this.box) === intent
      ) {
        let otherIndex = this.answers.findIndex(
          (q) => {
            return q.intent !== intent;
          }
        );

        this.$store.commit('formsEditor/selectPath', {
          box: this.box,
          intent: this.answers[otherIndex].intent,
        });
      }

      // remove answer from this.answers array
      this.answers.splice(this.answers.indexOf(answer), 1);

      this.$emit('removeMultiStep', { intent, box: this.box });
    },
    updateName() {
      if (this.content && !this.content.displayName) {
        this.content.displayName = this.name;
      }
    },
    update() {
      this.$emit('onUpdated');
    },
    doEditable() {
      this.selectedAnswer.context = [JSON.stringify({
          name: 'user_test',
          eq: 1,
        })];

      this.update();
    },
    changeOperator(operator) {

      const ctx = this.selectedAnswerContextEditable;
      const oldOperator = this.editableOperator;
     
      if (operator === 'exists') {
        this.selectedAnswer.context = [ctx.name];
        this.update();
        return;
      }

      if (oldOperator === 'exists') {
        ctx[oldOperator] = 0;
      }

      this.selectedAnswer.context = [JSON.stringify({
          name: ctx.name,
          [operator]: ctx[oldOperator],
        })];
      
      this.update();
    },
    changeName(name) {
      const ctx = this.selectedAnswerContextEditable;
      this.selectedAnswer.context = [JSON.stringify({
          name,
          [this.editableOperator]: ctx[this.editableOperator],
        })];
      
      this.update();
    },
    changeValue(value) {
      // if value is number convert to a number
      if (!isNaN(value)) {
        value = Number(value);
      }
      
      const ctx = this.selectedAnswerContextEditable;
      this.selectedAnswer.context = [JSON.stringify({
          name: ctx.name,
          [this.editableOperator]: value,
        })];
      
      this.update();
    },
    // addAnswer() {
    //     this.content.answers.push({template: 'feedback', qr: 'Label'});
    //     this.update();
    // },
    // removeAnswer() {
    //     this.content.answers.splice(this.answerTab, 1);
    //     this.answerTab = this.content.answers.length - 1;
    //     this.update();
    // }
  },
  watch: {
    name() {
      this.content.displayName = this.name;
    },
    tab() {
      // this.$emit('tabChanged', {intent: this.answers[this.tab].intent, box: this.box});
    },
  },
};
</script>
<template>
  <div>
    <v-timeline-item hide-dot color="grey lighten-3" fill-dot v-if="content">
      <v-row>
        <v-col>
          <v-text-field
            outlined
            class="font-weight-medium ma-0"
            @input="update"
            dense
            v-model="content.displayName"
            :label="$t('botFormEdit.whatIsAsked')"
          ></v-text-field>
        </v-col>
        <v-col>
          <div v-if="isAdmin" class="moin-admin-box pa-1">
            <v-text-field
              outlined
              v-model="content.context"
              @input="update"
              dense
              :label="$t('botFormEdit.technicalName')"
              hide-details
            />
          </div>
        </v-col>
      </v-row>
    </v-timeline-item>

    <ActionList
      v-if="content"
      :actions="content.question.actions"
      @hook:mounted="updateName"
      @onUpdate="update"
      :channelType="channelType"
    ></ActionList>

    <v-timeline-item color="white" v-if="content">
      <template v-slot:icon>
        <v-icon color="error">cancel</v-icon>
      </template>

      <span class="text-subtitle-1">
        {{ $t('botFormEdit.validationErrorTitle') }}
      </span>
      <p class="text-subtitle-2">{{ $t('botFormEdit.validationErrorText') }}</p>
    </v-timeline-item>

    <ActionList
      v-if="content"
      :actions="content.validation.actions"
      @onUpdate="update"
      :channelType="channelType"
    ></ActionList>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ActionList from '@/components/ActionList.vue';
import ChannelsUtil from '@/utils/channels';

export default {
  name: 'BotFormEmailEdit',
  components: {
    ActionList,
  },
  props: ['box', 'intent', 'channel', 'name'],
  data: () => ({
    tab: null,
  }),
  computed: {
    ...mapGetters('bots', ['currentBotId', 'currentChannels']),
    ...mapGetters('intents', ['intents']),
    ...mapGetters('bots', ['currentBotId']),
    ...mapGetters('auth', ['isAdmin']),
    content() {
      return this.$store.getters['content/getContent'](
        this.currentBotId,
        this.intent,
        this.box,
        true,
        this.channel
      );
    },
    channelType() {
      return ChannelsUtil.getChannelType(
        this.content?.channel,
        this.currentChannels
      );
    },
  },
  methods: {
    updateName() {
      if (this.content && !this.content.displayName) {
        this.content.displayName = this.name;
      }
    },
    update() {
      this.$emit('onUpdated');
    },
  },
  watch: {
    name() {
      this.content.displayName = this.name;
    },
  },
};
</script>

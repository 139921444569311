<template>
  <v-form>
    <v-container>
      <v-row>
        <v-col>
          <h1 class="text-h5 mb-4">
            {{ $t('login.passwordReset.setPassword.title') }}
          </h1>
          <p class="text-body-1">
            {{ $t('login.passwordReset.setPassword.subTitle') }}
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            outlined
            :label="$t('login.passwordReset.setPassword.input')"
            name="login"
            v-model="password"
            type="password"
            hide-details
          />
          <PasswordValidationFeedback
            :password="password"
            @update:isValidPassword="handleIsValidPassword" />
        </v-col>
        <v-col cols="12">
          <v-text-field
            outlined
            :label="$t('login.passwordReset.setPassword.inputConfirm')"
            name="login"
            v-model="confirmedPw"
            @input="validation = false"
            type="password"
            hide-details
            :append-icon="passwordMatchesIcon"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="text-right pt-6">
          <v-btn
            depressed
            color="primary"
            large
            v-on:click="handleSubmit()"
          >
            {{ $t('login.passwordReset.requestToken.button') }}
          </v-btn>
        </v-col>
        <v-col v-if="validationMessage">
          <v-alert outlined type="error">
            {{ validationMessage }}
          </v-alert>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
  <!-- <v-card tile outlined>
    <v-card-text>
      <v-form>
        <v-text-field
          :label="$t('login.passwordReset.setPassword.input')"
          name="login"
          v-model="password"
          type="password"
        ></v-text-field>
        <v-text-field
          :label="$t('login.passwordReset.setPassword.inputConfirm')"
          name="login"
          v-model="confirmedPw"
          @input="validation = false"
          :append-icon="passwordMatchesIcon"
          type="password"
        ></v-text-field>
      </v-form>
      <v-alert v-if="validation" border="left" colored-border type="error">
        {{ validationMessage }}
      </v-alert>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" v-on:click="handleSubmit()">
        {{ $t('login.passwordReset.setPassword.button') }}
      </v-btn>
    </v-card-actions>
  </v-card> -->
</template>

<script>
import PasswordValidationFeedback from './PasswordValidationFeedback.vue';

export default {
  data() {
    return {
      password: '',
      confirmedPw: '',
      validation: false,
      validationMessage: '',
      isValidPassword: false,
    };
  },
  components: {
    PasswordValidationFeedback,
  },
  computed: {
    passwordMatchesIcon() {
      if (
        this.password !== '' &&
        this.confirmedPw !== '' &&
        this.password === this.confirmedPw
      ) {
        return 'check';
      }
      return '';
    },
  },
  methods: {
    handleIsValidPassword(isValid) {
      this.isValidPassword = isValid;
    },
    async handleSubmit() {
      if (this.isValidPassword && this.password === this.confirmedPw) {
        try {
          await this.$store.dispatch('auth/setNewPassword', {
            username: this.$route.params.username,
            token: this.$route.params.token,
            password: this.password,
          });
          this.$store.commit('addSnackbarMessage', {
            show: true,
            message: this.$t('login.passwordReset.setPassword.success'),
          });
          this.$router.push('/login');
        } catch (error) {
          // Right now we don't differentiate between errors according to issue#34
          this.validation = true;
          if (error.response.data.tokenAlreadyUsed) {
            this.validationMessage = this.$t(
              'login.passwordReset.setPassword.error'
            );
          }

          if (error.response.data.tokenExpired) {
            this.validationMessage = this.$t(
              'login.passwordReset.setPassword.error'
            );
          }

          if (error.response.data.tokenNotFound) {
            this.validationMessage = this.$t(
              'login.passwordReset.setPassword.error'
            );
          }

          if (error.response.data.tokenUserMismatch) {
            this.validationMessage = this.$t(
              'login.passwordReset.setPassword.error'
            );
          }
        }
      } else {
        // Password input are not matching
        // alternatively we could disbale the submit button
        this.validation = true;
        this.validationMessage = this.$t( 
          this.isValidPassword ? 
          'login.passwordReset.setPassword.validationError' :
          'login.passwordReset.setPassword.passNotStrong'
        );
      }
    },
  },
};
</script>

<style></style>

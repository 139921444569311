// initial state for authentication
const state =  {
    elements: [{
        intent: 'start'
      },{
        intent: 'unknown'
      },
      {
        intent: 'next'
      },
      {
        intent: 'service'
      },
       {
        intent: 'faq_feedback'
      },
      {
        intent: 'smalltalk_thanks'
      }, {
        intent: 'file_uploaded_not_requested'
      }]
}

const getters = {
    elements: (state) => {
        return state.elements;
    }
}

const actions = {
   
}

const mutations = {
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
<template>
  <div>
    <HubBox
      :title="$t('botSettings.2factorAuth.title')"
      :titleSub="activatedInitial ? $t('botSettings.2factorAuth.isActive') : $t('botSettings.2factorAuth.isInactive')"
      icon="mdi-lock-check-outline"
      expandedInitial
    >
      <div class="text-body-2">
        {{$t('botSettings.2factorAuth.description')}}
      </div>
      <div v-if="activatedInitial" class="py-4 d-flex align-center">
        <v-icon class="mr-2" color="green">mdi-checkbox-marked-circle</v-icon>
        <div class="green--text">{{$t('botSettings.2factorAuth.isActiveInfo')}}</div>
      </div>
      <div v-else class="py-4 d-flex align-center">
        <v-icon class="mr-2" color="grey">mdi-cancel</v-icon>
        <div class="grey--text">{{$t('botSettings.2factorAuth.isInactiveInfo')}}</div>
      </div>
      <InfoBox
        :text="$t('botSettings.2factorAuth.infoBox')"
      />
      <v-btn
        class="mt-6"
        outlined
        color="primary lighten-2"
        @click="dialogIsOpen = true"
      >
        {{ $t('common.edit') }}
        <v-icon right>edit</v-icon>
      </v-btn>
    </HubBox>
    <HubDialog
      :value="dialogIsOpen"
      :title="$t('botSettings.2factorAuth.title')"
      modal
      asyncDialog
      primaryIcon="cloud_upload"
      :primaryLabel="$t('editor.common.deploy')"
      @onSecondary="dialogIsOpen = false"
      @onPrimary="save2Auth"
      :isLoading="isLoading"
      :disabled="!isChanged"
    >
      <div class="text-body-1">
        {{ $t('botSettings.2factorAuth.dialogDescription') }}
      </div>
      <div class="my-4">
        <v-switch
          v-model="switchActivated"
          :color="switchActivated ? 'green' : 'grey'"
          inset
          :label="$t('botSettings.2factorAuth.title')"
        />
      </div>
      <InfoBox
        :text="$t('botSettings.2factorAuth.changeHint')"
      />
    </HubDialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import HubDialog from '@/components/hub/HubDialog.vue';
import InfoBox from '@/components/common/InfoBox.vue';
import HubBox from '@/components/hub/HubBox.vue';
import BotService from '@/services/bot';

export default {
  name: 'Settings2Auth',
  components: {
    HubDialog,
    InfoBox,
    HubBox,
  },
  props: {
    activatedInitial: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      dialogIsOpen: false,
      switchActivated: false,
      isLoading: false,
    };
  },
  created() {
    this.switchActivated = this.activatedInitial;
  },
  computed: {
    ...mapGetters('bots', ['currentBotId']),
    isChanged() {
      return this.activatedInitial !== this.switchActivated;
    },
  },
  methods: {
    async save2Auth() {
      await BotService.set2FAActivation({
        botId: this.currentBotId,
        activate: this.switchActivated,
      });

      this.$emit('onChange');

      this.isLoading = false;
      this.dialogIsOpen = false;
    },
  },
};
</script>
<template>
  <div>
    <HubBoxSimple
      :title="$t('stats.reports.export.title')"
      icon="mdi-file-download-outline"
      :isLoading="isLoading"
    >
      <template v-slot:content>
        <p class="body-text-2">
          {{ $t('stats.reports.export.info') }}
        </p>
        <v-btn
          class="mt-4"
          depressed
          color="primary lighten-2"
          @click="createPDF()"
          :loading="isLoading"
        >
          {{ $t('stats.reports.export.btn') }}
          <v-icon right>mdi-file-download-outline</v-icon>
        </v-btn>
      </template>
    </HubBoxSimple>

    <component :is="componentMapping[$route.params.type]" ref="pdfExport" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import HubBoxSimple from '@/components/hub/HubBoxSimple.vue';
import { statsTypes } from '@/components/stats/statsTypes.js';
import { TrackingService } from '@/services/tracking';

export default {
  name: 'StatsSidebarReportExport',
  components: {
    HubBoxSimple,
    StatsReportConversationExport: () =>
      import('@/components/stats/reports/StatsReportConversationExport.vue'),
    StatsReportIntentsExport: () =>
      import('@/components/stats/reports/StatsReportIntentsExport.vue'),
    StatsReportAnualReviewExport: () =>
      import('@/components/stats/reports/StatsReportAnualReviewExport.vue'),
  },
  data() {
    return {
      isLoading: false,
      componentMapping: {
        [statsTypes.conversation]: 'StatsReportConversationExport',
        [statsTypes.intents]: 'StatsReportIntentsExport',
        [statsTypes.anualReview]: 'StatsReportAnualReviewExport',
      },
    };
  },
  computed: {
    ...mapGetters('bots', [
      'currentBotId',
    ]),
  },
  methods: {
    async createPDF() {
      this.isLoading = true;
      this.$refs.pdfExport.generateReport();
      this.isLoading = false;
      this.trackIt('stats-report-export-create-pdf');
    },
    trackIt(type = '') {
      const body = {
        botId: this.currentBotId,
        type,
        source: 'StatsSidebarReportExport.vue',
        payload: {
          type: this.$route.params.type,
          fullPath: this.$route.fullPath,
        },
      };
      TrackingService.trackStatsView(body);
    },
  },
};
</script>

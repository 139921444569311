<template>
  <div class="fill-height">
    <v-card outlined class="fill-height pa-4">
      <h2 class="text-h6">Vorschläge</h2>
      <v-divider class="my-2" />
      <v-data-table :headers="tableHeaders" :items="suggested" :custom-sort="customSort">
        <template v-slot:[`item.displayName`]="{ item }">
          <div class="py-2 d-flex" :id="item.intent">
            <div class="mr-2" >
              <v-icon size="20" color="secondary darken-2">auto_awesome</v-icon>
            </div>
            <div>
              <span class="text-body-1">
                {{ displayName(item.intent, user.language) }}
              </span>
              <v-icon v-if="item.inReview"></v-icon>

              <div
                class="text-body-2 grey--text"
                v-if="$vuetify.breakpoint.lgAndUp"
              >
                {{
                  description(item.intent, user.language) &&
                  description(item.intent, user.language).length > 0
                    ? description(item.intent, user.language)
                    : ''
                }}
              </div>
            </div>
          </div>
        </template>
        <template v-slot:[`item.relevance`]="{ item }">
          <IntentsRelevanceBar :intent="item" />
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import IntentsRelevanceBar from '@/components/intents/IntentsRelevanceBar.vue';

export default {
  name: 'QmDashboardListIntents',
  components: {
    IntentsRelevanceBar,
  },
  computed: {
    ...mapGetters('intents', ['suggested', 'displayName', 'description']),
    ...mapGetters('auth', ['user']),
    tableHeaders() {
      return [
        { text: this.$t('intents.headers.name'), value: 'displayName' },
        { text: '', value: 'relevance' },
      ];
    },
  },
  methods: {
    customSort: function(items, index, isDesc) {
      let desc = false; 
      if (Array.isArray(isDesc) && isDesc.length === 1) {
        desc = isDesc[0];
      }

      if (Array.isArray(index) && index.length === 1 && index[0] === 'displayName') {
        
        // sort by ddisplayName
        items.sort((a, b) => {
          const aName = this.displayName(a.intent, this.user.language);
          const bName = this.displayName(b.intent, this.user.language);
          if (aName < bName) {
            return desc ? 1 : -1;
          }
          if (aName > bName) {
            return desc ? -1 : 1;
          }
          return 0;
        });
      }

      if (Array.isArray(index) && index.length === 1 && index[0] === 'relevance') {
        // sort by relevanceFrequent
        items.sort((a, b) => {
          if (a.relevance.frequent < b.relevance.frequent) {
            return desc ? 1 : -1;
          }
          if (a.relevance.frequent > b.relevance.frequent) {
            return desc ? -1 : 1;
          }
          return 0;
        });
      }
      
      return items;
    },
  }
};
</script>

<template>
  <div class="fill-height">
    <v-card outlined class="fill-height pa-4">
      <h2 class="text-h6">Features</h2>
      <v-divider class="my-2" />
      <div v-if="nlpFeatures" class="text-body-2">
        <div v-for="(feature, index) in nlpFeatures" :key="index">
          {{ feature }},
        </div>
      </div>
    </v-card>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { BotService } from '@/services/bot';

export default {
  name: 'QmDashboardFeatures',
  data() {
    return {
      k1Model: null,
      wisdomModel: null,
      nlpFeatures: null,
      nlpTemplate: null,
    };
  },
  computed: {
    ...mapGetters('bots', ['currentBot', 'currentBotId', 'getBotSettings']),
  },
  created() {
    this.init();
  },
  watch: {
    currentBotId() {
      this.init();
    },
  },
  methods: {
    async init() {
      this.resetData();

      const featureResult = await BotService.getNlPFeatures(this.currentBotId);
      this.nlpFeatures = featureResult.features;
    },
    resetData() {
      this.nlpFeatures = null;
    },
  },
};
</script>
<style scoped>
.moin-label {
  color: var(--v-primary-lighten3);
}
.moin-col-label {
  display: inline-block;
  width: 100px;
}
.moin-col-data {
  display: inline-block;
  width: calc(100% - 100px);
}
</style>

const IntentUtil = {
  getConfidenceFromAILevel(level) {
    switch (level) {
      case 1:
        return 0.3
      case 2:
        return 0.42
      case 3:
        return 0.5
      case 4:
        return 0.6
      case 5:
        return 0.7
    }

    return null
  },
  getAILevelFromConfidence(confidence) {
    if (confidence > 0.75) return 5
    if (confidence < 0.3) return 0
    if (confidence === 0.3) return 1

    const interval = (0.75 - 0.3) / 5
    return Math.ceil((confidence - 0.3) / interval)
  },
  getAILevelFromBot(bot) {
    const confLimit = bot.confidenceLimit || 0.6

    return IntentUtil.getAILevelFromConfidence(confLimit)
  },
  getAILevelFromIntent(intent) {
    if (intent.confidenceLimit) {
      return IntentUtil.getAILevelFromConfidence(intent.confidenceLimit)
    }

    return 0
  },
  getAILevel(intent, bot) {
    let confLimit = bot ? bot.confidenceLimit || 0.6 : 0.6
    if (intent.confidenceLimit && intent.confidenceLimit > confLimit) {
      confLimit = intent.confidenceLimit
    }

    return IntentUtil.getAILevelFromConfidence(confLimit)
  },
  getIntentType(self, intent) {
    if (!intent || !intent.template) return false

    if (intent.payload && intent.payload.measurement) {
      switch (intent.payload.measurement) {
        case 'feedback':
          return { value: 'feedback', label: self.$t('intents.types.feedback.label'), icon: 'thumbs_up_down' }
        case 'conversion':
          return { value: 'conversion', label: self.$t('intents.types.conversion.label'), icon: 'outlined_flag' }
      }
    }

    switch (intent.template) {
      case 'SimpleInformation':
        return { value: 'feedback', label: self.$t('intents.types.feedback.label'), icon: 'thumbs_up_down' }
      // case 'Transactional':
      //     return {value: 'conversion', label: self.$t('intents.types.conversion.label'), icon: 'outlined_flag'};
    }

    return false
  },
  searchTemplate(boxes, template) {
    for (const box of boxes) {
      if (box.followup && box.followup.template === template) {
        return true
      }

      if (box.answers && Array.isArray(box.answers)) {
        for (const answer of box.answers) {
          if (answer.template === template) {
            return true
          }
        }
      }
    }

    return false
  },
  replaceTemplate(boxes, template, replaceWith) {
    for (const box of boxes) {
      if (box.followup && box.followup.template === template) {
        box.followup.template = replaceWith
      }

      if (box.answers && Array.isArray(box.answers)) {
        for (const answer of box.answers) {
          if (answer.template === template) {
            box.template = replaceWith
          }
        }
      }
    }
  },
}

export default IntentUtil

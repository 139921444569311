import ApiService from "./api";

const NotificationService = {
    get: async (botId) => {
        try {
            const response = await ApiService.get(`/notifications/${botId}/`);
            return response.data;
        } catch (error) {
            return false;
        }
    },
    resend: async (botId, notificationId, recipients) => {
        try {
            const response = await ApiService.post(`/notifications/resend/${botId}/${notificationId}/${JSON.stringify(recipients)}`,);
            return response.data;
        } catch (error) {
            return false;
        }
    }
};

export default NotificationService;
<template>
  <v-main :class="[!noBg && 'moin-bg-grey-lightest']" style="max-height: 100%">
    <div class="fill-height flex-column d-flex">
      <!-- navbar -->
      <div class="flex-grow-0">
        <slot name="navbar"></slot>
      </div>

      <v-row
        class="ma-0 flex-grow-1 flex-nowrap"
        style="height: 0%; max-height: 100%; overflow-hidden;"
      >
        <v-col
          class="fill-height pa-0"
          :class="[
            isSidebarOpenFade
              ? 'flex-md-column d-md-flex flex-md-grow-1 hidden-sm-and-down'
              : 'flex-column d-flex fill-height flex-grow-1',
            fixedHeight ? 'hub-content-fixed-height' : 'hub-content-scroll',
          ]"
          :cols="isSidebarOpenFade ? 12 - sidebarWidth : '12'"
        >
          <slot name="content"></slot>
        </v-col>

        <!-- sidebar -->
        <slot name="sidebar"></slot>
      </v-row>
    </div>
  </v-main>
</template>
<script>
export default {
  name: 'HubContent',
  data() {
    return {
      isSidebarOpenFade: false,
    };
  },
  props: {
    isSidebarOpen: {
      type: Boolean,
      default: false,
    },
    sidebarWidth: {
      type: Number,
      default: 4,
    },
    fixedHeight: {
      type: Boolean,
      default: false,
    },
    noBg: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    isSidebarOpen() {
      if (!this.isSidebarOpen) {
        setTimeout(() => (this.isSidebarOpenFade = false), 300);
      } else {
        this.isSidebarOpenFade = true;
      }
    },
  },
};
</script>
<style lang="scss">
.hub-content-fixed-height {
  max-height: 100%;
  overflow: hidden;
}
.hub-content-scroll {
  overflow-y: auto;
}
</style>
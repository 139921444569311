<template>
    <v-card flat>
        <v-card-title>
            <h3 class="text-h4 mb-0">{{ `${notification.type} || ${notification.updatedAt}` }}</h3>
        </v-card-title>
        <ChipsArrayInput
            :values="recipients"
            :placeholder="$t('botNotifications.recipientsOverridePlaceholder')"
            @onRemoveValue="removeRecipient"
            @onAddValue="addRecipient"
            :validator="emailValidator"
        />
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                @click="resend"
            >
                {{ $t('botNotifications.resendBtn') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
    import ChipsArrayInput from "@/components/common/ChipsArrayInput.vue";
    export default {
        name: "BotNotificationResend",
        components: {
            ChipsArrayInput,
        },
        props: {
            notification: {
                type: Object,
                required: true,
            },
        },
        data: () => ({
            recipients: [],
            emailValidator: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return pattern.test(value)
            },
        }),
        created() {
            this.recipients = [...this.notification.recipients];
        },
        methods: {
            addRecipient(recipient) {
                this.recipients.push(recipient);
            },
            removeRecipient(index) {
                this.recipients.splice(index, 1);
            },
            resend() {
                this.$emit('onResend', this.recipients);
            },
        },
    };
</script>
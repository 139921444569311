export default {
  webhook_push: 'webhook_push',
  greyhound: 'greyhound',
  freshchat: 'freshchat',
  hubspot: 'hubspot',
  livechat: 'livechat',
  salesforce: 'salesforce',
  snapengage: 'snapengage',
  userlike: 'userlike',
  crisp: 'crisp',
  zendesk: 'zendesk',
};

<template>
  <HubBoxSimple :title="$t('stats.intents')" :isLoading="isLoading">
    <template v-slot:content>
      <v-data-table
        :items="items"
        :headers="headers"
        :hide-default-footer="items && items.length <= 5"
        :footer-props="{
          'items-per-page-options': [5, 20, 50, -1],
        }"
      >
        <template v-slot:[`item.displayName`]="{ item }">
          <div class="py-2" :id="item.intent">
            <v-icon size="20" class="mr-2" color="primary lighten-3">
              mdi-folder-text
            </v-icon>

            <span class="text-body-1">
              {{ displayName(item.topic) }}
            </span>
          </div>
        </template>

        <template v-slot:[`item.count`]="{ item }">
          <span class="text-left">
            {{ item.count }}
          </span>
        </template>

        <template v-slot:[`item.isConversion`]="{ item }">
          <template v-if="$vuetify.breakpoint.smAndUp">
            <StatsBarCountConversion
              v-if="item.isConversion"
              :isLoading="isLoading"
              :countAll="item.count"
              :countGoals="item.goals"
              :language="user.language"
            />
            <StatsBarCountFeedback
              v-else
              :isLoading="isLoading"
              :countAll="item.count"
              :countPos="item.pos"
              :countNeg="item.neg"
              :language="user.language"
            />
          </template>
          <template v-else>
            <span class="green--text">{{ item.pos }}</span>
            <span>/</span>
            <span class="red--text">{{ item.neg }}</span>
          </template>
        </template>
      </v-data-table>
    </template>
    <template v-slot:footer>
      <v-btn
        class="mt-4"
        small
        outlined
        color="primary"
        :to="`statistics/${statsTypes.intents}`"
      >
        {{ $t('stats.reports.title') }}
        <v-icon right>query_stats</v-icon>
      </v-btn>
    </template>
  </HubBoxSimple>
</template>
<script>
import { mapGetters } from 'vuex';
import HubBoxSimple from '@/components/hub/HubBoxSimple.vue';
import StatsBarCountFeedback from '@/components/stats/components/StatsBarCountFeedback.vue';
import StatsBarCountConversion from '@/components/stats/components/StatsBarCountConversion.vue';
import StatsService from '@/services/stats';
import { statsTypes } from '@/components/stats/statsTypes';
import { formatNumberFixedOne } from '@/utils/formatNumber';

export default {
  name: 'StatsIntents',
  components: { HubBoxSimple, StatsBarCountFeedback, StatsBarCountConversion },
  data() {
    return {
      items: [],
      statsTypes,
      isLoading: true,
      isFailed: false,
      noData: false,
      amount: 5,
    };
  },
  computed: {
    ...mapGetters('bots', ['currentBotId']),
    ...mapGetters('stats', ['dateRange', 'dateRangeCompare', 'channel']),
    ...mapGetters('intents', ['intents', 'intent', 'displayName']),
    ...mapGetters('auth', ['user']),
    headers() {
      return [
        {
          text: this.$t('common.intent'),
          value: 'displayName',
          sortable: false,
          width: '50%',
        },
        {
          text: this.$t('stats.invocations'),
          value: 'count',
          sortable: false,
          align: 'start',
          width: '10%',
        },
        {
          value: 'isConversion',
          text: this.$t('stats.conversationsLabel'),
          sortable: false,
          width: '30%',
        },
      ];
    },
  },
  created() {
    this.init();
  },
  watch: {
    dateRange() {
      this.init();
    },
  },
  methods: {
    async init() {
      if (!this.dateRange) return;
      this.isLoading = true;

      const items = [];
      // Skeleton like placeholder
      for (let i = 0; i < this.amount; i++) {
        items.push({
          topic: '',
          pos: 0,
          neg: 0,
        });
      }
      this.items = items;

      const intentsList = await StatsService.getTopTopics(
        this.currentBotId,
        this.dateRange,
        this.channel,
        this.amount
      );

      intentsList.forEach((i) => {
        const intent = this.intents.find((t) => t.intent === i.topic);
        if (intent?.payload?.measurement === 'conversion') {
          i.isConversion = true;
        }
      });
      this.items = intentsList;

      this.isLoading = false;
    },
    calcPro(count, countAll) {
      let pro = (count / countAll) * 100;
      pro = formatNumberFixedOne(pro);
      return `${pro}%`;
    },
  },
};
</script>

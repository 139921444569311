<template>
  <div class="moin-fresh" v-if="channels && channels.length > 1">
    <!-- Select Box -->
    <v-select
      outlined
      dense
      flat
      hide-details
      :value="selectedChannelId"
      @change="changeChannelId"
      :items="channels"
      item-value="channelId"
      item-text="displayName"
      :disabled="disabled"
      item-disabled="blocked"
      style="min-width: 240px"
    >
      <template v-slot:selection="{ item }">
        <div class="d-flex justify-space-between full-width">
          <div
            :style="{
              maxWidth: $vuetify.breakpoint.smOnly ? '210px' : 'auto',
            }"
            class="text-truncate"
          >
            {{ item.displayName }}
            <v-tooltip
              bottom
              color="primary lighten-3"
              v-if="draftAvailable(item)"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-on="on"
                  v-bind="attrs"
                  class="secondary--text pl-2 mb-1"
                  small
                  left
                >
                  edit
                </v-icon>
              </template>
              <span>{{ $t('common.unsavedChanges') }}</span>
            </v-tooltip>
          </div>
          <div>
            <span
              class="ml-auto mr-2 text-caption align-self-center"
              v-text="languageCode(item)"
            ></span>
            <span class="mr-1">
              <v-tooltip bottom color="primary lighten-3">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    color="primary lighten-2"
                  >
                    {{ $channelIcon(item) }}
                  </v-icon>
                </template>
                <span>{{ channelTypeDescription(item.channel) }}</span>
              </v-tooltip>
            </span>
          </div>
        </div>
      </template>
      <template v-slot:item="{ item }">
        <span class="ml-0">
          {{ item.displayName }}
          <span v-if="!item.displayName">Kein Name</span>
          <v-tooltip
            bottom
            color="primary lighten-3"
            v-if="draftAvailable(item)"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-on="on"
                v-bind="attrs"
                class="secondary--text text--darken-2 pl-2 mb-1"
                small
                left
              >
                edit
              </v-icon>
            </template>
            <span>{{ $t('common.unsavedChanges') }}</span>
          </v-tooltip>
        </span>
        <span
          class="ml-auto mr-2 text-caption align-self-center"
          v-text="languageCode(item)"
        ></span>
        <span class="mr-1">
          <v-tooltip bottom color="primary lighten-3">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="mb-1"
                color="primary lighten-2"
                small
                v-bind="attrs"
                v-on="on"
              >
                {{ $channelIcon(item) }}
              </v-icon>
            </template>
            <span>{{ channelTypeDescription(item.channel) }}</span>
          </v-tooltip>
        </span>
      </template>
    </v-select>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: 'ChannelSelection',
  props: {
    channels: Array,
    disabled: Boolean,
    value: String,
    intent: String,
  },
  data: function () {
    return {};
  },
  computed: {
    ...mapGetters('bots', ['currentBotId']),
    selectedChannel() {
      if (!this.value) {
        this.$store.commit(
          'bots/setCurrentChannelId',
          this.channels[0].channelId
        );
        return 0;
      } else {
        let index = this.channels.findIndex((a) => {
          return a.channelId === this.value;
        });

        if (index < 0) return 0;
        this.$store.commit(
          'bots/setCurrentChannelId',
          this.channels[index].channelId
        );

        return index;
      }
    },
    selectedChannelId() {
      if (!this.value) {
        return null;
      }

      return this.value;
    },
  },
  async created() {
    let channelId = this.$route.query.channel;
    if (!channelId) {
      channelId = await this.lastSelectedChannelId(this.currentBotId);
    } else {
      this.setLastChannelId({ botId: this.currentBotId, channelId });
    }

    // no channel avaiable but channelId is set
    if (channelId && this.channels.length === 0) {
      let query = Object.assign({}, this.$route.query);
      delete query.channel;
      this.setLastChannelId({ botId: this.currentBotId, channelId: null });
      return this.$router.replace({ query });
    }

    if (
      channelId &&
      channelId !== this.channels[this.selectedChannel].channelId
    ) {
      let index = this.channels.findIndex((a) => {
        return a.channelId === channelId;
      });

      if (index < 0) {
        index = 0;
      }

      this.changeChannel(index);
    } else if (
      !channelId &&
      this.channels.length > 0 &&
      this.channels[0].blocked
    ) {
      // do block handling
      this.changeChannel(0);
    } else if (!channelId && this.selectedChannel === 0 && this.channels.length > 0 && this.channels[0].channelId) {
      // fix if first channel is blocked
      this.changeChannel(0);
    }
  },
  watch: {
    channels() {
      // channels changed switch to first channel
      if (this.selectedChannel !== 0 && this.$route.query.channel) {
        let index = this.channels.findIndex((a) => {
          return a.channelId === this.$route.query.channel;
        });
        if (index < 0) {
          this.changeChannel(0);
        }
      } else if (this.channels.length > 0) {
        this.changeChannel(0);
      }
    },
    '$route.query.channel'() {
      if (
        (this.$route.query.channel || this.selectedChannel !== 0) &&
        this.channels[this.selectedChannel].channelId !==
          this.$route.query.channel
      ) {
        let channelId = this.$route.query.channel;
        //if (!channelId && this.value !== 0 || channelId && channelId !== this.channels[this.value].channelId) {
        let index = this.channels.findIndex((a) => {
          return a.channelId === channelId;
        });

        if (index < 0) {
          index = 0;
        }

        this.changeChannel(index);
        //}
      }
    },
  },
  methods: {
    ...mapActions('preferences', ['lastSelectedChannelId']),
    ...mapMutations('preferences', ['setLastChannelId']),
    getValue(o) {
      return this.channels.findIndex((c) => {
        return (c.channelId = o.channelId);
      });
    },
    changeChannel(c) {
      // if channel is blocked?
      if (this.channels[c].blocked) {
        const notBlocked = this.channels.findIndex((channel) => {
          return !channel.blocked;
        });
        return this.changeChannel(notBlocked);
      }

      // switch channel
      if (
        (c !== 0 || this.$route.query.channel !== undefined) &&
        (c === 0 || this.$route.query.channel !== this.channels[c].channelId)
      ) {
        let query = Object.assign({}, this.$route.query);
        if (c === 0) {
          delete query.channel;
        } else {
          query.channel = this.channels[c].channelId;
        }

        this.$router.replace({ query });
      }

      this.$emit('input', this.channels[c].channelId);
    },
    changeChannelId(c) {
      // switch channel
      if (
        (c === null && this.$route.query.channel === undefined) ||
        (c !== null || this.$route.query.channel !== undefined) &&
        (c === null || this.$route.query.channel !== c)
      ) {
        let query = Object.assign({}, this.$route.query);
        if (c === null) {
          delete query.channel;
        } else {
          query.channel = c;
        }

        this.setLastChannelId({ botId: this.currentBotId, channelId: c });
        this.$router.replace({ query });
      }

      this.$emit('input', c);
    },
    channelTypeDescription(type) {
      switch (type) {
        case 'widget':
          return 'Website';
        case 'facebook':
          return 'Facebook Messenger';
        case 'userlike':
          return 'Userlike';
        case 'whatsapp':
          return 'WhatsApp';
      }

      return '';
    },
    languageCode(c) {
      if (Array.isArray(c.languages) && c.languages.length > 0) {
        return c.languages[0].toUpperCase();
      }

      return '';
    },
    draftAvailable(c) {
      if (this.intent) {
        return this.$store.getters['content/isContentChanged'](
          this.currentBotId,
          this.intent,
          c.channelId
        );
      } else {
        return false;
      }
    },
  },
};
</script>
<style scoped scss>
:deep(.v-select__selections input) {
  display: none !important;
}
</style>

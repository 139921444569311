<template>
  <HubBoxSimple fillHeight>
    <template v-slot:content>
      <div>
        <IconNumberTeaser
          :title="$t('stats.conversation')"
          icon="chat"
          :oldValue="sumOld"
          :newValue="sumNew"
          :loading="isLoading"
          :compareDisabled="
            !dateRangeCompare ? $t('dateRange.selectComparePeriod') : ''
          "
          titleHeading
        />
      </div>
      <div class="relative my-4 mx-n4">
        <v-divider />
        <v-progress-linear
          v-if="isLoading"
          indeterminate
          style="position: absolute; top: 0; left: 0; width: 100%;"
          color="secondary darken-2"
        ></v-progress-linear>
      </div>
      <StatsChart
        :isLoading="isLoading"
        :seriesData="seriesData"
        :seriesDataCompare="seriesDataCompare"
        :dateRange="dateRange"
        :dateRangeCompare="dateRangeCompare"
        :height="height"
        :timeFormat="timeFormat"
        type="line"
        opposite
        fixedNumbers
      />
    </template>
    <template v-if="!noFooter" v-slot:footer>
      <v-btn
        class="mt-8"
        small
        outlined
        color="primary"
        :to="`statistics/${statsTypes.conversation}`"
      >
        {{ $t('stats.reports.title') }}
        <v-icon right>query_stats</v-icon>
      </v-btn>
    </template>
  </HubBoxSimple>
</template>
<script>
import { mapGetters } from 'vuex';
import HubBoxSimple from '@/components/hub/HubBoxSimple.vue';
import { statsTypes } from '@/components/stats/statsTypes';
import StatsChart from '@/components/stats/components/StatsChart.vue';
import IconNumberTeaser from '@/components/stats/components/IconNumberTeaser.vue';
import StatsService from '@/services/stats';
import { parseSeries } from '@/utils/stats';

export default {
  name: 'StatsChartConversation',
  components: {
    HubBoxSimple,
    IconNumberTeaser,
    StatsChart,
  },
  props: {
    height: {
      type: String,
    },
    noFooter: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      statsTypes,
      isLoading: false,
      isFailed: false,
      sumOld: 0,
      sumNew: 0,
      seriesData: [],
      seriesDataCompare: null,
      timeFormat: 'day',
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...mapGetters('auth', ['user']),
    ...mapGetters('bots', ['viewableBotId']),
    ...mapGetters('stats', ['dateRange', 'dateRangeCompare', 'channel']),
  },
  watch: {
    dateRange() {
      this.init();
    },
    dateRangeCompare() {
      this.init();
    },
    viewableBotId() {
      this.init();
    },
    channel() {
      this.init();
    },
  },
  methods: {
    async init() {
      this.isLoading = true;
      this.seriesData = [];
      this.seriesDataCompare = null;

      if (this.dateRange) {
        await this.fetchData();
      }
      if (this.dateRangeCompare) {
        await this.fetchDataCompare();
      }
      this.isLoading = false;
    },
    async fetchData() {
      const {
        sum,
        series,
        timeFormat,
      } = await StatsService.getConversationSeries(
        this.viewableBotId,
        this.dateRange,
        this.channel
      );

      this.sumNew = sum;
      this.seriesData = parseSeries({
        series,
        timeFormat,
        language: this.user.language,
      });
      this.timeFormat = timeFormat;
      return true;
    },
    async fetchDataCompare() {
      const {
        sum,
        series,
        timeFormat,
      } = await StatsService.getConversationSeries(
        this.viewableBotId,
        this.dateRangeCompare,
        this.channel
      );

      this.sumOld = sum;
      this.seriesDataCompare = parseSeries({
        series,
        timeFormat,
        language: this.user.language,
      });
      return true;
    },
  },
};
</script>

<template>
    <div>
        <div>
            <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            class="ma-4"
            hide-details
            ></v-text-field>
        </div>
        <v-data-table
            :items="preparedNotifications"
            :headers="headers"
            :loading="isLoading"
            :sort-by="['updatedAt']"
            :sort-desc="[true]"
            :multi-sort="true"
            :footer-props="{
            'items-per-page-options': [20, 50, -1],
            }"
        >
            <template v-slot:item.createdAt="{ item }">
            {{ formatDateTime(item.createdAt) }}
            </template>
            <template v-slot:item.updatedAt="{ item }">
            {{ formatDateTime(item.updatedAt) }}
            </template>
            <template v-slot:item.recipients="{ item }">
            <v-chip
                v-for="(recipient) in item.recipients"
                small
                class="ma-1"
            >
                {{ recipient }}
            </v-chip>
            </template>
            <template v-slot:item.status="{ item }">
            <v-chip
                :color="isStatusSuccess(item.status) ? 'success' : 'error'"
                small
                class="ma-1"
            >
                {{ $t(`botNotifications.status.${item.status}`) }}
            </v-chip>
            </template>
            <template v-slot:item.conversation="{ item }">
            <v-btn
                :title="$t('botNotifications.actions.openConversation')"
                @click="openConversation(item)"
                color="primary"
                text
            >
                <v-icon>message</v-icon>
            </v-btn>
            </template>
            <template v-slot:item.actions="{ item }">
            <v-btn
                :data-cy="`bot-notifications-${item._id}-btn`"
                :title="$t('botNotifications.actions.resend')"
                @click="openResendNotification(item)"
                color="primary"
                text
            >
                <v-icon>mdi-arrow-u-right-top</v-icon>
            </v-btn>
            </template>
        </v-data-table>
    </div>
</template>
<script>
    import moment from 'moment';

    export default {
        props: {
            notifications: {
                type: Array,
                required: true,
                default: [],
            },
            isLoading: {
                type: Boolean,
                required: true,
            },
        },
        data() {
            return {
                search: '',
                headers: [
                    {
                        text: this.$t('botNotifications.header.updatedAt'),
                        value: 'updatedAt',
                        align: 'left',
                        sortable: true,
                    },
                    {
                        text: this.$t('botNotifications.header.createdAt'),
                        value: 'createdAt',
                        align: 'left',
                        sortable: true,
                    },
                    {
                        text: this.$t('botNotifications.header.recipients'),
                        value: 'recipients',
                        align: 'left',
                        sortable: false,
                    },
                    {
                        text: this.$t('botNotifications.header.status'),
                        value: 'status',
                        align: 'left',
                        sortable: true,
                    },
                    {
                        text: this.$t('botNotifications.header.conversation'),
                        value: 'conversation',
                        align: 'left',
                        sortable: false,
                    },
                    {
                        text: this.$t('botNotifications.header.actions'),
                        value: 'actions',
                        align: 'left',
                        sortable: false,
                    },
                ],
            };
        },
        computed: {
            preparedNotifications() {
                return this.notifications.map((notification) => {
                    return {
                        uniqueBotId: notification.uniqueBotId,
                        type: notification.type,
                        status: notification.mail,
                        recipients: notification.mail_settings.receipients.map(r => r.Email),
                        createdAt: notification.createdAt,
                        updatedAt: notification.updatedAt,
                        context: notification.context,
                        _id: notification._id,
                    };
                }).filter(notification => {
                    return notification.type.toLowerCase().includes(this.search.toLowerCase()) ||
                    notification.status.toLowerCase().includes(this.search.toLowerCase()) ||
                    notification.recipients.join(', ').toLowerCase().includes(this.search.toLowerCase()) ||
                    this.formatDateTime(notification.createdAt).toLowerCase().includes(this.search.toLowerCase()) ||
                    this.formatDateTime(notification.updatedAt).toLowerCase().includes(this.search.toLowerCase());
                });
            },
        },
        methods: {
            formatDateTime(date) {
                return moment(date).format('DD.MM.YYYY HH:mm');
            },
            isStatusSuccess(status) {
                return status === 'sent' || status === 'resent';
            },
            openConversation(notification) {
                this.$router.push({
                    name: 'chat-history',
                    params: {
                        uuid: notification.context.uniqueUserId,
                    },
                });
            },
            openResendNotification(notification) {
                this.$emit('onResendNotification', notification);
            },
        },
    };
</script>
<template>
  <div class="hub-loader" :class="[fixed && 'hub-loader--fixed']">
    <div style="margin-top: -3%">
      <div style="width: 36px">
        <v-img src="@/assets/moin-logo-mini.svg" class="logo-bounce-in" />
      </div>
      <div
        class="mt-2"
        style="overflow: hidden; border-radius: 3px; height: 2px; width: 36px"
      >
        <v-progress-linear
          class="moin-fresh"
          indeterminate
          color="secondary"
          height="2"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'HubLoader',
  props: {
    fixed: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
};
</script>
<style scoped lang="scss">
.hub-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
}

.hub-loader--fixed {
  position: fixed;
  z-index: 9999;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }

  60% {
    transform: scale(1.2);
  }
  80% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}

.logo-bounce-in {
  animation: bounce-in 0.6s ease-in-out;
}
</style>

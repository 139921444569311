const state = {
  lastOwnDateRanges: [],
};

const getters = {
  lastOwnDateRanges: (state) => state.lastOwnDateRanges,
};

const actions = {};

const mutations = {
  addOwnDateRange(state, period) {
    let periods = [...state.lastOwnDateRanges];
    
    periods = periods.filter(
      (item) => item.value !== period.value
    );

    period.isOwn = true;

    periods.unshift(period);

    state.lastOwnDateRanges = periods.splice(0, 3);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

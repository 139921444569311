<template>
  <transition name="slide-fade">
    <v-col
      v-if="isOpen"
      cols="12"
      :md="width"
      class="flex-column d-flex px-0 fill-height white"
    >
      <v-row class="overflow-y-auto mx-0">
        <v-tabs
          v-if="items.length > 0"
          v-model="sidebarContent"
          slider-color="primary lighten-2"
          :background-color="
            isProminent() ? 'secondary darken-2' : 'grey lighten-2'
          "
          grow
          center-active
          :class="{ 'is-prominent': isProminent() }"
        >
          <template v-for="(sidebarItem, index) in items">
            <v-tab
              v-if="isTabActive(sidebarItem, value)"
              :key="index + '-sidebar'"
              :ripple="false"
              :class="{
                'white--text': isProminent(sidebarItem),
                'primary--text text--lighten-2': !isProminent,
              }"
            >
              <v-icon
                left
                :color="
                  isProminent(sidebarItem) ? 'white' : 'primary lighten-3'
                "
              >
                {{ sidebarItem.icon }}
              </v-icon>
              <slot name="sidebar.title" :item-def="sidebarItem">
                {{ sidebarItem.title }}
              </slot>
            </v-tab>
          </template>
          <div class="mr-2 mt-auto mb-auto">
            <BtnSquare
              v-if="!openSidebar"
              icon="close"
              :colorIcon="isProminent() ? 'white' : 'primary lighten-2'"
              @click="closeSidebar"
            />
          </div>
          <v-tabs-items v-model="sidebarContent">
            <template v-for="(sidebarItem, index) in items">
              <v-tab-item
                v-if="isTabActive(sidebarItem, value)"
                :key="index + '-sidebar-content'"
                reverse-transition="none"
                transition="none"
                :class="{ 'pa-6': !sidebarItem.padlessItem }"
                :style="{ minHeight: '100%' }"
              >
                <slot :name="`${sidebarItem.name || index}`" :item="value" />
              </v-tab-item>
            </template>
          </v-tabs-items>
        </v-tabs>
      </v-row>
    </v-col>
  </transition>
</template>

<script>
import BtnSquare from '@/components/common/BtnSquare.vue';

export default {
  name: 'HubContentSidebar',
  components: {
    BtnSquare,
  },
  props: {
    value: null,
    items: {
      type: Array,
      default: () => [],
    },
    width: {
      type: Number,
      default: 4,
    },
    prominent: {
      type: Boolean,
      default: false,
    },
    openSidebar: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sidebarContent: 0,
    };
  },
  computed: {
    isOpen() {
      return this.value || this.openSidebar;
    },
  },
  methods: {
    closeSidebar() {
      this.$emit('input', null);
    },
    isTabActive(sidebarItem, value) {
      if (typeof sidebarItem.active === 'function') {
        return sidebarItem.active(value);
      }

      return true;
    },
    isProminent(sidebarItem) {
      // prominent is set true return always true
      if (this.prominent) return true;

      // Tabs are only prominent if prominent property or all active tabs are prominent

      // check if no sidebar item exists than return conjunct prominent properties of all active tabs
      if (!sidebarItem && this.value && this.items) {
        const items = this.items.filter((item) =>
          this.isTabActive(item, this.value)
        );
        return items.every((item) => item.prominent);
      }

      // returns sidebar item prominent property
      if (sidebarItem && sidebarItem.prominent) return true;
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.slide-fade-leave-active,
.slide-fade-enter-active {
  transition-duration: 0.3s;
  transition-property: transform, opacity;
  transition-timing-function: ease;
  position: relative;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.v-tabs {
  &.is-prominent {
    :deep(.v-tabs-slider-wrapper) {
      display: none;
    }
  }
}

.v-tabs-items {
  height: calc(100% - 48px);
}

:deep(.v-window__container) {
  min-height: 100%;
}
</style>

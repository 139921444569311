<template>
  <HubBox title="Genauigkeit" icon="school" :boxId="boxId" adminOnly>
    <IntentStatistic
      class="mb-4"
      progress-title="Wie oft lag die KI richtig?"
      :chart-title="$t('intents.details.often.title')"
      chart-subline=""
      :loading="loading"
      :channel="channel"
    >
      <template #progressBar>
        <v-progress-linear
          class="v-progress-linear--outer-value"
          :value="progressValue"
          buffer-value="100"
          :background-color="noData || loading ? null : 'grey'"
          :color="noData || loading ? 'grey lighten-2' : 'primary'"
          height="10"
        >
          <template v-slot:default="{ value }">
            <strong class="text-caption" :style="getProgressValueStyle(value)">
              {{ Math.ceil(value) }}%
            </strong>
          </template>
        </v-progress-linear>
        <div v-if="!noData" class="text-caption pt-2">
          {{ totalData }} Anfragen mit Rückfrage
        </div>
      </template>

      <template #chart>
        <apexchart
          v-if="!noData && chart.series.length === 1"
          type="bar"
          :options="chart.options"
          :series="chart.series"
          height="140px"
        />
        <div v-else-if="!loading && noData" class="d-flex justify-center">
          <v-card-subtitle>
            <v-icon small left>warning</v-icon>
            {{ $t('intents.details.often.nodata') }}
          </v-card-subtitle>
        </div>
      </template>
    </IntentStatistic>
  </HubBox>
</template>

<script>
import moment from 'moment';
import { getProgressValueStyle, baseBarChartOptions } from '@/utils/charts';
import { lastTwelveWeekDates } from '@/utils/numbers';
import { StatsService } from '@/services/stats';
import IntentStatistic from './IntentStatistic';
import HubBox from '@/components/hub/HubBox.vue';

export default {
  name: 'IntentAccuracy',
  components: {
    IntentStatistic,
    HubBox,
  },
  props: {
    intent: {
      type: String,
      required: true,
    },
    channel: {
      type: String,
    },
    botId: {
      type: String,
      required: true,
    },
    boxId: {
      type: String,
      default: 'intentAccuracy',
    },
  },
  data() {
    return {
      loading: false,
      noData: false,
      getProgressValueStyle,
      positiveString: '',
      progressValue: 0,
      totalData: 0,
      pointData: new Array(12),
      chart: {
        series: [],
        options: Object.assign(
          JSON.parse(JSON.stringify(baseBarChartOptions)),
          {
            colors: ['#18293E'],
            tooltip: {
              enabled: true,
              x: {},
              y: {},
            },
            xaxis: {
              type: 'datetime',
              categories: lastTwelveWeekDates,
              labels: {
                rotateAlways: true,
                offsetY: 3,
              },
            },
            yaxis: {
              labels: {
                formatter: function(val) {
                  if (val === 0) return '';
                  return Math.round(val) + '%';
                },
              },
            },
          }
        ),
      },
    };
  },
  beforeMount() {
    this.loading = true;
    StatsService.getIntentAccuracy(this.botId, this.intent, this.channel).then(
      ({ pos, neg, history }) => {
        if (pos === 0 && neg === 0) {
          this.noData = true;
          this.loading = false;
          return;
        }

        let count = 0;
        const posArray = history.map(({ pos, neg }) => {
          this.totalData += pos + neg;
          this.pointData[count++] = pos + neg;
          return Math.max((pos / (pos + neg)) * 100, 5.0123);
        });

        this.chart.series.push({
          name: this.positiveString,
          data: posArray,
        });

        this.chart.options.yaxis.tickAmount = 4;
        this.chart.options.yaxis.max = 100;

        this.progressValue = (pos / (pos + neg)) * 100;
        this.loading = false;
      }
    );
  },
  created() {
    const calendarWeekStr = this.$t('common.calendarweek');
    this.chart.options.tooltip.x.formatter = function(val) {
      return `${calendarWeekStr} ${moment(val).week()}`;
    };

    const $this = this;
    this.chart.options.tooltip.y.formatter = function(val, context) {
      if (val === 5.0123) {
        val = 0;
      }
      return `${Math.round(val)}% (${
        $this.pointData[context.dataPointIndex]
      } Anfragen)`;
    };
    this.chart.options.xaxis.labels.formatter = function(_, timestamp) {
      return `${calendarWeekStr} ${moment(timestamp).week()}`;
    };

    this.positiveString = 'Genauigkeit';
  },
};
</script>

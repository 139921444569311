<template>
  <div>
    <v-timeline-item hide-dot color="grey lighten-3" fill-dot v-if="content">
      <!-- <template v-slot:icon>
                                <v-icon small>space_bar</v-icon>
      </template>-->
      <v-row>
        <v-col>
          <v-text-field
            outlined
            class="font-weight-medium ma-0"
            dense
            v-model="content.displayName"
            :label="$t('botFormEdit.whatIsAsked')"
            @input="update"
            :disabled="isLivechatRating"
          ></v-text-field>
        </v-col>
        <v-col>
          <div v-if="isAdmin" class="moin-admin-box pa-1">
            <v-text-field
              outlined
              v-model="content.context"
              dense
              :label="$t('botFormEdit.technicalName')"
              @input="update"
              :disabled="isLivechatRating"
              hide-details
            />
          </div>
        </v-col>
      </v-row>
    </v-timeline-item>
    <ActionList
      v-if="content"
      :actions="content.question.actions"
      @hook:mounted="updateName"
      @onUpdate="update"
      :channelType="channelType"
    ></ActionList>
    <!--  <v-timeline-item color="white" v-if="content && isAdmin">
                <template v-slot:icon>
                    <v-icon small>space_bar</v-icon>
                </template>
                <v-text-field v-model="content.context" :label="$t('botFormEdit.technicalName')"></v-text-field>
    </v-timeline-item>-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ActionList from '@/components/ActionList.vue';
import ChannelsUtil from '@/utils/channels';
/* import ActionEdit from '@/components/ActionEdit.vue';
// import FollowupEdit from '@/components/editor/FollowupEdit.vue';
import AnswerTemplate from '@/components/editor/AnswerTemplate.js';
import AnswerInfoEdit from '@/components/editor/AnswerInfoEdit.vue';
import YesNoQuestionTransEdit from '@/components/editor/YesNoQuestionTransEdit.vue'; */

// import MultipleChoiceInfoEdit from '@/components/editor/MultipleChoiceInfoEdit.vue';

export default {
  name: 'BotFormFreeTextEdit',
  components: {
    ActionList,
  },
  props: ['box', 'intent', 'channel', 'name'],
  data: () => ({}),
  async created() {
    /*  if (this.box && this.intent) {
                await this.$store.dispatch('content/getContent', {botId: this.currentBotId, box: this.box, intent: this.intent, template: 'BotFormFreeText', editable: false, preloading: false});
                if (this.content) {
                    this.content.displayName = this.name;
                }
            } */
  },
  computed: {
    ...mapGetters('intents', ['intents']),
    ...mapGetters('bots', ['currentBotId', 'currentChannels']),
    ...mapGetters('auth', ['isAdmin']),
    ...mapGetters('forms', ['forms']),
    content() {
      return this.$store.getters['content/getContent'](
        this.currentBotId,
        this.intent,
        this.box,
        true,
        this.channel
      );
    },
    isLivechatRating() {
      const currentForm = this.forms.find((f) => f.intent === this.intent);

      return (
        currentForm?.template === 'BotFormRating' &&
        this.content?.context === 'rating_comment'
      );
    },
    channelType() {
      return ChannelsUtil.getChannelType(
        this.content?.channel,
        this.currentChannels
      );
    },
    // answers: function() {
    //     return this.content.answers;
    // },
    // yes: function() {
    //     if (this.content && this.content.answers && Array.isArray(this.content.answers) && this.content.answers.length > 0) {
    //         return this.content.answers[0].template;
    //     }

    //     return false;
    // },
    // no: function() {
    //     if (this.content && this.content.answers && Array.isArray(this.content.answers) && this.content.answers.length > 1) {
    //         return this.content.answers[1].template;
    //     }

    //     return false;
    // },
  },
  methods: {
    updateName() {
      if (this.content && !this.content.displayName) {
        this.content.displayName = this.name;
      }
    },
    // addAnswer() {
    //     this.content.answers.push({template: 'feedback', qr: 'Label'});
    //     this.update();
    // },
    // removeAnswer() {
    //     this.content.answers.splice(this.answerTab, 1);
    //     this.answerTab = this.content.answers.length - 1;
    //     this.update();
    // }
    update() {
      this.$emit('onUpdated');
    },
  },
  watch: {
    name() {
      if (this.content) {
        this.content.displayName = this.name;
      }
    },
  },
};
</script>
<template>
  <v-app style="max-height: 100vh; height: 100vh">
    <HubMenu />
    <Appbar />
    <HubEditorFrame
      v-model="isSidebarOpen"
      :title="$t('botSettings.fileUpload.title')"
      :loading="isLoading"
      :draft="isChanged || hasUndefinedSetting"
      saveIcon="cloud_upload"
      @onReset="reset"
      @onSave="save"
      back="/settings"
      :sidebar-items="[{ title: '', icon: 'info', name: 'info' }]"
    >
      <template v-slot="{ isLoading }">
        <v-container fluid class="white pa-6">
          <v-row>
            <v-col cols="12" lg="6" xl="4">
              <h2 class="text-h6">{{ $t('botSettings.fileUpload.active.title') }}</h2>
              <div class="text-body-2">{{ $t('botSettings.fileUpload.active.description') }}</div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-switch
                v-model="active"
                :label="$t('botSettings.fileUpload.active.label')"
                color="green"
                inset
                dense
                :disabled="isLoading"
                @change="(v) => {
                  handleChange(v, active);
                  return active = v;
                }"
              />
              <InfoBox class="my-4" :text="$t('botSettings.fileUpload.active.info')"/>
            </v-col>
          </v-row>
        </v-container>
        <v-divider/>
        <v-container fluid class="white pa-6">
          <v-row>
            <v-col cols="12" lg="6" xl="4">
              <h2 class="text-h6">{{ $t('botSettings.fileUpload.accessViaSecretUrl.title') }}</h2>
              <div class="text-body-2">{{ $t('botSettings.fileUpload.accessViaSecretUrl.description') }}</div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-switch
                v-model="accessViaSecretAllowed"
                :label="$t('botSettings.fileUpload.accessViaSecretUrl.label')"
                color="green"
                inset
                dense
                :disabled="isLoading"
                @change="(v) => {
                  handleChange(v, accessViaSecretAllowed);
                  return accessViaSecretAllowed = v;
                }"
              />
              <InfoBox class="my-4" :text="$t('botSettings.fileUpload.accessViaSecretUrl.info')"/>
              <InfoBox icon="mdi-exclamation-thick" class="my-4" :text="$t('botSettings.fileUpload.accessViaSecretUrl.note')"/>
            </v-col>
          </v-row>
        </v-container>
        <v-divider/>
      </template>

      <!-- Uncomment sidebar if needed -->
      <!-- <template #[`sidebar.info`]>
        <div class="pa-4">
          <HubBoxSimple
            :title="$t('botSettings.fileUpload.info.title')"
            icon="mdi-delete-clock-outline"
            class="mb-4"
          >
            <template v-slot:content>
              <p class="text-body-1">
                {{ $t('botSettings.fileUpload.info.description')}}
              </p>
            </template>
          </HubBoxSimple>
        </div>
      </template>
      <template v-slot:toolbar>
        <v-tooltip bottom>
          <template v-slot:activator="{ on: onTooltip, attrs: attrsTooltip }">
            <span v-bind="attrsTooltip" v-on="onTooltip">
              <BtnSquare icon="info_outline" @click="isSidebarOpen = !isSidebarOpen"/>
            </span>
          </template>
          <span>{{ $t('common.moreInfo') }}</span>
        </v-tooltip>
      </template> -->

    </HubEditorFrame>
  </v-app>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { TrackingService } from '@/services/tracking';
import Appbar from '@/components/Appbar.vue';
import BtnSquare from '@/components/common/BtnSquare.vue';
import HubBoxSimple from '@/components/hub/HubBoxSimple.vue';
import HubEditorFrame from '../components/hub/HubEditorFrame.vue';
import HubMenu from '@/components/hub/HubMenu.vue';
import InfoBox from '@/components/common/InfoBox.vue';
import { BotSettingKeyEnum } from '@/constants';

export default {
  name: 'SettingsFiles',
  components: {
    HubMenu,
    Appbar,
    HubEditorFrame,
    HubBoxSimple,
    BtnSquare,
    InfoBox,
  },
  props: [],
  data() {
    return {
      // This setting 'key' in bot.settings[key]
      key: BotSettingKeyEnum.files,

      isChanged: false,
      hasUndefinedSetting: false,
      isInitialized: false,
      isLoading: false,
      isSidebarOpen: false,
      nextUrl: null,

      initialConfig: {},
      active: false,
      accessViaSecretAllowed: false,
    };
  },

  computed: {
    ...mapGetters('auth', ['isAdmin']),
    ...mapGetters('bots', ['currentBot', 'currentBotId', 'getBotSettings']),
  },
  created() {
    this.init();
  },
  watch: {
    active(val, oldVal) { this.handleChange(val, oldVal); },
    accessViaSecretAllowed(val, oldVal) { this.handleChange(val, oldVal); },
  },
  methods: {
    ...mapActions('bots', ['setSettingsByKey']),
    localHasChanged() {
      if (
        this.initialConfig.active !== this.active ||
        this.initialConfig.accessViaSecretAllowed !== this.accessViaSecretAllowed
      ) return true;
      return false;
    },
    handleChange(val, oldVal) {
      if (!this.isInitialized) return;
      if (val === oldVal) return;
      this.isChanged = this.localHasChanged();
    },
    reset() {
      this.active = this.initialConfig.active;
      this.accessViaSecretAllowed = this.initialConfig.accessViaSecretAllowed;
      this.isChanged = false;
    },
    parseConfig() {
      const config = {
        active: this.active,
        accessViaSecretAllowed: this.accessViaSecretAllowed,
      };

      return config;
    },
    async fetchConfig() {
      const botSettings = await this.getBotSettings(this.currentBotId);
      const fileSettings = !_.isArray(botSettings) && _.isObject(botSettings?.files)
        ? botSettings.files
        : {};

      this.initialConfig = fileSettings;
      return fileSettings;
    },
    async save() {
      this.isLoading = true;

      const success = await this.setSettingsByKey({
        botId: this.currentBotId,
        key: this.key,
        config: this.parseConfig(),
      })

      if (success) {
        this.initialConfig.active = this.active;
        this.initialConfig.accessViaSecretAllowed = this.accessViaSecretAllowed;
        this.isChanged = false;
        this.hasUndefinedSetting = false;
      }

      this.isLoading = false;
      this.trackIt('save');
    },
    async init() {
      this.isLoading = true;
      const fileSettings = await this.fetchConfig();

      // If config is not defined => then Enabled/Active
      this.active = fileSettings?.active ?? true;
      this.accessViaSecretAllowed = fileSettings?.accessViaSecretAllowed ?? true;

      this.hasUndefinedSetting = !_.isBoolean(fileSettings?.active) || !_.isBoolean(fileSettings?.accessViaSecretAllowed);

      this.initialConfig = this.parseConfig();

      this.isLoading = false;

      this.$nextTick(() => {
        this.isInitialized = true;
      });
    },
    trackIt(type = 'view', payload) {
      const body = {
        botId: this.currentBotId,
        type: type,
        source: 'SettingsFile.vue',
      };
      if (payload) {
        body.payload = payload;
      }
      TrackingService.trackSettingsFiles(body);
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.isChanged && !this.nextUrl) {
      this.isLostDialogOpen = true;
      this.nextUrl = to;
      return next(false);
    }
    return next(true);
  },
};
</script>

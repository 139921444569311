import ApiService from './api';

const BotFormsService = {
  // startDate & endDate in format 'yyyy-mm-dd'
  async getFormInsights({ botId, channelId, startDate, endDate, steps, intent }) {
    try {
      const url = `/schaltzentrale/stats/botforms/${botId}/${channelId || ''}?startTime=${startDate}&endTime=${endDate}`;

      const response = await ApiService.post(url, {
        steps,
        intent,
        include_userIds: true,
      });

      return response.data;
    } catch (e) {
      return false;
    }
  },
  // available in #431 moin-api, 
  async getLeads({ botId, steps, users }) {
    try {
      const url = `/schaltzentrale/stats/botforms/${botId}/leads`;

      const response = await ApiService.post(url, {
        steps,
        users,
      });
     
      return response.data;
    } catch (e) {
      return false;
    }
  },
  /**
   * @typedef {Object} RecentlyCompletedFormBot
   * @property {?string} [channelDisplay] The display name of the channel (populated after API request)
   * @property {string} channel The display name of the channel
   * @property {string} channelId The ID of the channel
   * @property {string} botId The ID of the bot
   */
  /**
   * @typedef {Object} RecentlyCompletedFormStep
   * @property {string} stepName The name of the step in the UI
   * @property {string} template The template used for this step, which lets you know the type for context value
   * @property {?string} [rule] The rule used for this step. Useful for `BotFormValidableInput` to know what it's trying to validate (phone number, license plate, etc.)
   * @property {?string} [contextName] The technical name of the context ("technical name" in UI)
   * @property {?string} [contextValue] The value the user responded with
   * @property {?string} [contextDate] An ISO date-string of when the answer was given
   */
  /**
   * @typedef {Object} RecentlyCompletedForm
   * @property {string} name The technical name of the form
   * @property {string} displayName The display name of the form
   * @property {RecentlyCompletedFormStep[]} steps The steps of the form
   * @property {RecentlyCompletedFormBot} bot Information about the bot (channel)
   */
  /**
   * Searches for the most recent bot forms a user have completed
   * @param {string} botId Staging or Live bot ID
   * @param {string} uniqueUserId The chat widget user ID
   * @param {number} [count=1] Number of forms to return.
   * `0` for no limit.
   * @returns {Promise<null | RecentlyCompletedForm[]>} Array of forms in descending order (newest to oldest).<br/>
   * If empty array, user has not completed any forms.<br/>
   * `null` if error.
   */
  async searchCompleted(botId, uniqueUserId, count = 1) {
    try {
      const url = `/schaltzentrale/forms/searchCompleted`;

      const response = await ApiService.post(url, {
        uniqueBotId: botId,
        uniqueUserId,
        count,
      });

      return response.data;
    } catch(e) {
      console.error(e);
      return null;
    }
  }
};

export default BotFormsService;

<template>
  <div class="pa-3">
    <v-card flat class="pb-6">
      <StatsHeader />
      <v-container :fluid="$vuetify.breakpoint.lgAndDown" class="pa-4">
        <v-row>
          <v-col cols="12" md="6">
            <StatsChartConversation />
          </v-col>
          <v-col cols="12" md="6">
            <StatsChartAutomation />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <StatsChartFeedback positive />
          </v-col>
          <v-col cols="12" md="6">
            <StatsChartFeedback negative />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <StatsChartConversions />
          </v-col>
          <v-col cols="12" md="6">
            <StatsChartTakeovers />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <StatsIntents />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import StatsHeader from '@/components/stats/components/StatsHeader.vue';
import StatsChartConversation from '@/components/stats/components/StatsChartConversation.vue';
import StatsChartAutomation from '@/components/stats/components/StatsChartAutomation.vue';
import StatsChartFeedback from '@/components/stats/components/StatsChartFeedback.vue';
import StatsChartConversions from '@/components/stats/components/StatsChartConversions.vue';
import StatsChartTakeovers from '@/components/stats/components/StatsChartTakeovers.vue';
import StatsIntents from '@/components/stats/components/StatsIntents.vue';
import { TrackingService } from '@/services/tracking';

export default {
  name: 'StatsOverview',
  components: {
    StatsHeader,
    StatsChartConversation,
    StatsChartAutomation,
    StatsChartFeedback,
    StatsChartConversions,
    StatsChartTakeovers,
    StatsIntents,
  },
  computed: {
    ...mapGetters('auth', ['user']),
    ...mapGetters('bots', ['currentBot', 'currentBotId', 'currentChannels']),
  },
  created() {
    this.trackIt();
  },
  methods: {
    trackIt() {
      const body = {
        botId: this.currentBotId,
        type: 'stats-view-overview',
        source: 'StatsOverview.vue',
        payload: {
          fullPath: this.$route.fullPath,
        },
      };
      TrackingService.trackStatsView(body);
    },
  },
};
</script>
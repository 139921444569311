<template>
  <div class="pa-6">
    <h2 class="text-h6 primary--text mt-2 mb-4">{{ $t('stats.title') }}</h2>
    <StatsSidebarActiveFilter class="mb-4" />
    <StatsSidebarReportsList class="mb-4" />
    <StatsSidebarExport class="mb-4" />
    <StatsSidebarInfoText  class="mb-4" />
  </div>
</template>
<script>
import StatsSidebarExport from '@/components/stats/components/StatsSidebarExport.vue';
import StatsSidebarReportsList from '@/components/stats/components/StatsSidebarReportsList.vue';
import StatsSidebarActiveFilter from '@/components/stats/components/StatsSidebarActiveFilter.vue';
import StatsSidebarInfoText from '@/components/stats/components/StatsSidebarInfoText.vue';

export default {
  name: 'StatsOverviewSidebar',
  components: {
    StatsSidebarExport,
    StatsSidebarInfoText,
    StatsSidebarReportsList,
    StatsSidebarActiveFilter
  },
};
</script>

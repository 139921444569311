<template>
  <div>
    <apexchart
      v-if="isInitialized"
      height="300"
      width="100%"
      type="area"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'QmDashboardKpiChart',
  data() {
    return {
      isInitialized: false,
      chartOptions: {
        chart: {
          toolbar: {
            show: false,
          },
          animations: {
            enabled: false,
          },
          zoom: {
            enabled: false,
          },
        },
        stroke: {
          curve: 'smooth',
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.5,
            opacityTo: 0,
            stops: [0, 90, 100],
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function(value) {
            return value + '%';
          },
        },
        yaxis: {
          min: 0,
          max: 100,
          labels: {
            formatter: function(value) {
              return value + '%';
            },
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters('qm', ['kpisYear']),
    series() {
      return [
        {
          name: 'KPI im Monat',
          data: this.kpisYear,
        },
      ];
    },
  },
  mounted() {
    setTimeout(() => {
      this.isInitialized = true;
    }, 300);
  }
};
</script>

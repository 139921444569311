import ProductService from '@/services/product';
import LicenceUtil from '@/utils/licence';
import Vue from 'vue';
import { BotService } from '../../services/bot';
import store from '../index';
import { getRidOfTabs } from '@/utils/stringUtils';
import SettingService from '@/services/settings';

const state = {
  currentBot: null,
  viewStaging: false,
  bots: [],
  botDetails: {},
  currentChannelId: null,
};

const getters = {
  bots: (state) => state.bots,
  currentBot: (state) => (typeof state.currentBot === 'object' ? state.currentBot : null),
  currentChannelId: (state) => (state.currentChannelId),
  currentChannels: (state, getters, rootState, rootGetters) => {
    const channels = getters['currentBot'] ? getters['currentBot'].channels : [];
    const user = rootGetters['auth/user'];
    return channels.map((channel) => {
      const channelCopy = { ...channel };
      if (!channelCopy.displayName) {
        channelCopy.displayName = channelCopy.channelId;
      }
      if (user && Array.isArray(user.blockedChannels)) {
        channelCopy.blocked = user.blockedChannels.includes(
          channelCopy.channelId
        );
      }
      return channelCopy;
    });
  },
  areChannelsBlocked: (state, getters) =>
    getters.currentChannels.filter((channel) => channel.blocked).length > 0,
  currentBotId: (state) =>
    state.currentBot ? state.currentBot.uniqueBotId : null,
  viewableBotId: (state) =>
    state.currentBot
      ? state.viewStaging
        ? state.currentBot.stagingBot
        : state.currentBot.uniqueBotId
      : null,
  viewStaging: (state) => state.viewStaging,
  getBot: (state) => (botId) => state.botDetails[botId],
  getBotSettings: (state) => (botId) => {
    const bots = state.botDetails;
    if (!bots[botId]) return false;
    return bots[botId].settings;
  },
  getNLPTemplate(state) {
    return state.currentBot && state.currentBot.nlpTemplate
      ? state.currentBot.nlpTemplate
      : null;
  },
  // TODO: remove this
  getChannelById: (state) => (botId, channelId) => {
    const chan = state.botDetails[botId].channels.filter(
      (channel) => channel.channelId === channelId
    );
    return chan[0];
  },
  getCurrentChannelById: (state) => (channelId) => {
    if (!state.currentBot) return null;
    const channel = state.currentBot.channels.filter(
      (channel) => channel.channelId === channelId
    );
    if (channel.length > 0) {
      return channel[0];
    }
    return null;
  },
  getCurrentChannelConfig: (state) => (channelId) => {
    const channelConfig = state.currentBot.channels.filter(
      (channel) => channel.channelId === channelId
    );
    if (channelConfig.length > 0) {
      return channelConfig[0].config;
    }
    return {};
  },
  currentBotContactId: (state) => {
    if (
      !state.botDetails ||
      !state.currentBot ||
      !state.currentBot.uniqueBotId
    ) {
      return null;
    }
    const botId = state.currentBot.uniqueBotId;

    if (
      !state.botDetails[botId] ||
      !state.botDetails[botId].settings ||
      !state.botDetails[botId].settings.contact
    ) {
      return null;
    }

    return state.botDetails[botId].settings.contact.contactId;
  },
  currentBotStage: (state) => {
    if (
      !state.botDetails ||
      !state.currentBot ||
      !state.currentBot.uniqueBotId
    ) {
      return 'undefined';
    }
    const botId = state.currentBot.uniqueBotId;

    if (
      !state.botDetails[botId] ||
      !state.botDetails[botId].settings ||
      !state.botDetails[botId].settings.stage
    ) {
      return 'undefined';
    }

    return state.botDetails[botId].settings.stage;
  },
  currentLicence: (state) =>
    LicenceUtil.currentLicence(state.currentBot?.licences),
  hasWidget: (state) =>
    !!state.currentBot?.channels?.find((c) => c.channel === 'widget'),
  hasKeywordMatchFeature: (state) => {
    if (
      !state.botDetails ||
      !state.currentBot ||
      !state.currentBot.uniqueBotId
    ) {
      return false;
    }

    const botId = state.currentBot.uniqueBotId;

    if (!state.botDetails[botId] || !state.botDetails[botId].nlpFeatures) {
      return false;
    }

    return !!state.botDetails[botId].nlpFeatures.find(
      (f) => f === 'keywordmatch'
    );
  },
  hasLegacyModel: (state) => {
    if (
      !state.botDetails ||
      !state.currentBot ||
      !state.currentBot.uniqueBotId
    ) {
      return false;
    }

    const botId = state.currentBot.uniqueBotId;

    if (!state.botDetails[botId] || !state.botDetails[botId].nlpFeatures) {
      return false;
    }

    return !!state.botDetails[botId].nlpFeatures.find((f) => f === 'k1');
  }
};

const actions = {
  async get({ commit }) {
    const bots = await BotService.get();
    commit('setBots', bots);
    return true;
  },
  async changeBot({ }, {uniqueBotId, router}) {
    // Just set bid and reload app
    let query = Object.assign({}, router.query);
    query.bid = uniqueBotId;
    router.replace({ query }).catch(() => true);
    await new Promise((resolve) => setTimeout(resolve, 200));
    window.location.reload(true);
  },
  check({ commit, state }) {
    if (state.bots.length > 0) {
      commit('setCurrentBot', state.bots[0]);
    }
  },
  setViewStaging({ commit }, view) {
    commit('setViewStaging', view);
  },
  clear({ commit }) {
    commit('setCurrentBot', null);
    commit('setBots', []);
  },
  async getBotById({ commit, state }, botId) {
    const bot = await BotService.getBot(botId);

    // fetch nlp pipe infos
    const featureResult = await BotService.getNlPFeatures(botId);
    if (featureResult && featureResult.status === 'ok') {
      bot.nlpFeatures = featureResult.features;
    }

    commit('setBotDetail', { botId, bot });

    const stagingBot = state.currentBot?.stagingBot || undefined;
    const stagingBotFor = state.currentBot?.stagingBotFor || undefined;
    if (
      state.currentBot &&
      state.currentBot.uniqueBotId !== botId &&
      (stagingBot === undefined || stagingBot !== botId) &&
      (stagingBotFor === undefined || stagingBotFor !== botId)
    ) {
      commit('setCurrentBot', bot);
    } else if (!state.currentBot && bot.stagingBot) {
      commit('setCurrentBot', bot);
    }
    return bot;
  },
  async updateLicences({ commit, state }, botId) {
    const bot = await BotService.getBot(botId);
    if (state.currentBot && state.currentBot.uniqueBotId === botId) {
      commit('setLicences', bot);
    } else {
      commit('setCurrentBot', bot);
    }
  },
  async setNlpTemplate(
    { commit, state },
    { botId, templateBotId, displayName }
  ) {
    const bot = state.bots.find((bot) => bot.uniqueBotId === botId);
    if (!bot) {
      return;
    }

    const result = await ProductService.setNlpTemplate(botId, {
      displayName: displayName,
      botId: templateBotId,
    });
    if (result && result.status === 'ok') {
      commit('setNlpTemplate', { botId, templateBotId, displayName });
    }
  },
  async addNewAlias({ commit, state }, { botId, alias }) {
    const mergedSettings = { ...state.botDetails[botId].settings };

    mergedSettings.notifications.mail[getRidOfTabs(alias.name)] = {
      ...alias,
      name: getRidOfTabs(alias.name),
      subject: getRidOfTabs(alias.subject),
      receipients: alias.receipients.map((r) => ({
        Email: getRidOfTabs(r.Email),
      })),
    };

    try {
      const newSettings = await BotService.saveSettings(
        botId,
        mergedSettings
      );
      commit('setSettings', { botId, settings: newSettings });
      return 'success';
    } catch (error) {
      return 'error';
    }
  },
  async setSettingsByKey({ commit, state }, { botId, key, config }) {
    if (!key) return false;

    const mergedSettings = { ...state.botDetails[botId].settings };
    mergedSettings[key] = config;

    try {
      const newSettings = await BotService.saveSettings(
        botId,
        mergedSettings
      );
      commit('setSettings', { botId, settings: newSettings });
      return true;
    } catch (_) {
      return false;
    }
  },
  async setContact({ commit, state }, { botId, contactId }) {
    const mergedSettings = { ...state.botDetails[botId].settings };
    if (!mergedSettings.contact) {
      mergedSettings.contact = {};
    }
    mergedSettings.contact.contactId = contactId;
    try {
      const newSettings = await BotService.saveSettings(
        botId,
        mergedSettings
      );
      commit('setSettings', { botId, settings: newSettings });
      return true;
    } catch (_) {
      return false;
    }
  },
  async setStage({ commit, state }, { botId, stage }) {
    const mergedSettings = { ...state.botDetails[botId].settings };
    mergedSettings.stage = stage;
    try {
      const newSettings = await BotService.saveSettings(
        botId,
        mergedSettings
      );
      commit('setSettings', { botId, settings: newSettings });
      return true;
    } catch (_) {
      return false;
    }
  },
  async setCleanup({ commit, state }, { botId, cleanup }) {
    const mergedSettings = { ...state.botDetails[botId].settings };
    mergedSettings.cleanup = cleanup;
    try {
      const newSettings = await BotService.saveSettings(
        botId,
        mergedSettings
      );
      commit('setSettings', { botId, settings: newSettings });
      return true;
    } catch (error) {
      return false;
    }
  },
  async setQuota({ commit, state }, { botId, quota }) {
    const mergedSettings = { ...state.botDetails[botId].settings };
    mergedSettings.quota = quota;
    try {
      const newSettings = await BotService.saveSettings(
        botId,
        mergedSettings
      );
      commit('setSettings', { botId, settings: newSettings });
      return true;
    } catch (error) {
      return false;
    }
  },
  async setTrafficMonitoring({ commit, state }, {botId, isActive}) {
    const mergedSettings = { ...state.botDetails[botId].settings };
    mergedSettings.trafficMonitoring = { active: isActive };
    try {
      const newSettings = await BotService.saveSettings(
        botId,
        mergedSettings
      );
      commit('setSettings', { botId, settings: newSettings });
      return true;
    } catch (error) {
      return false;
    }
  },
  async deleteAlias({ commit, state }, { botId, aliasId }) {
    const mergedSettings = { ...state.botDetails[botId].settings };
    delete mergedSettings.notifications.mail[aliasId];
    try {
      const newSettings = await BotService.saveSettings(
        botId,
        mergedSettings
      );
      commit('setSettings', { botId, settings: newSettings });
      return 'success';
    } catch (error) {
      return 'error';
    }
  },
  /**
   * Fetch the Tone of Voice settings from the backend, populating any defaults if you have nothing
   * @param {?string} [botId=null] The ID of the bot you want to use, if not the current one
   * @throws {Error} If the API responds with error or there is no bot active
   * @returns {Promise<{channelId: string, enabled: boolean, examples: {scenario: string, answer: string}[]}[]>}
   */
  async fetchToneOfVoice({ commit, state }, botId = null) {
    if (!botId) {
      if (!state?.currentBot?.uniqueBotId) throw new Error("No current bot");
      botId = state.currentBot.uniqueBotId;
    }

    // Enforce always live bot
    if (state.botDetails[botId].stagingBotFor) botId = state.botDetails[botId].stagingBotFor;

    commit('setLoading', true);
    const channelTones = await SettingService.getToneOfVoiceSettings(botId);
    commit('setLoading', false);

    if (channelTones.error) throw new Error(channelTones.error);
    commit('setToneOfVoice', { botId, channelTones });

    return channelTones;
  },
  /**
   * Stores the Tone of Voice settings for a given channel in the backend
   * @param commit
   * @param state
   * @param {string} botId The ID of the bot
   * @param {string | null} [channelId=null] The ID of the channel, or `null` for the default/first channel
   * @param {boolean} enabled Whether the tone of voice feature is enabled or not
   * @param {string[]} answers The answers for each scenario, ordered by index
   * @returns {Promise<Object>} The entire channel's new state
   */
  async putToneOfVoice({ commit, state }, { botId, channelId, enabled, rules }) {
    commit('setLoading', true);
    const channel = await SettingService.setToneOfVoiceSettings(botId, channelId, enabled, rules);
    commit('setLoading', false);

    const channelTones = [{
      channelId,
      ...channel.toneOfVoice,
    }];
    commit('setToneOfVoice', { botId, channelTones });

    return channel;
  }
};

const mutations = {
  setBotDetail(state, data) {
    Vue.set(state.botDetails, data.botId, data.bot);
  },
  setViewStaging(state, view) {
    state.viewStaging = view;
  },
  setBots(state, bots) {
    // Sort ASC
    bots.sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
      if (a.name.toLowerCase() === b.name.toLowerCase()) return 0;
      return 1;
    });

    bots = bots.filter(
      (b) => b.stagingBot // only bots with staging bot
    );

    state.bots = bots;
  },
  setCurrentBot(state, bot) {
    store.dispatch('dashboard/clear');
    store.dispatch('intents/clear');
    state.currentBot = bot;
  },
  setCurrentChannelId(state, channelId) {
    state.currentChannelId = channelId;
  },
  setLicences(state, bot) {
    if (state.currentBot) {
      state.currentBot.licences = bot.licences;
    }
  },
  setNlpTemplate(state, { botId, templateBotId, displayName }) {
    const bot = state.bots.find((bot) => bot.uniqueBotId === botId);
    if (!bot) {
      return;
    }

    if (state.currentBot && state.currentBot.uniqueBotId === botId) {
      state.currentBot.nlpTemplate = {
        botId: templateBotId,
        displayName: displayName,
      };
    }

    bot.nlpTemplate = {
      botId: templateBotId,
      displayName: displayName,
    };
  },
  setSettings(state, { botId, settings }) {
    Vue.set(state.botDetails[botId], 'settings', settings);
  },
  setChannelActiveState(state, { botId, channelId, activeState }) {
    // set state in bot details and current bot
    if (state.botDetails[botId]) {
      const chan = state.botDetails[botId].channels.filter(
        (channel) => channel.channelId === channelId
      );
      if (chan && chan.length > 0) {
        Vue.set(chan[0], 'active', activeState);
      }
    }

    if (state.currentBot) {
      const chan = state.currentBot.channels.filter(
        (channel) => channel.channelId === channelId
      );
      if (chan && chan.length > 0) {
        Vue.set(chan[0], 'active', activeState);
      }
    }
  },
  setFallbackFaqIntent(state, fallbackFaqIntentObject) {
    if (!state.currentBot) return;
    state.currentBot.fallbackFaqIntent = fallbackFaqIntentObject;
  },
  setToneOfVoice(state, { botId, channelTones }) {
    let bot = state.botDetails[botId];

    // Always target the live bot
    if (bot.stagingBotFor) bot = state.botDetails[bot.stagingBotFor];
    if (!bot) return;

    // Helper function for applying the changes
    const applyChannelChanges = (bot, channelTones) => {
      // For each channel we received, overwrite tone of voice for that channel
      for (let i = 0; i < channelTones.length; i++) {
        // Most likely the indexes match up...
        let channel = bot.channels[i];

        // ... but double-check and use fallback approach
        if (channel.channelId !== channelTones[i].channelId) {
          channel = bot.channels.find((c) => c.channelId === channelTones[i].channelId);
        }
        if (!channel) continue;

        Vue.set(channel, 'toneOfVoice', {
          enabled: !!channelTones[i].enabled,
          rules: channelTones[i].rules,
        });
      }
    };

    // Apply for the bo tin botDetails
    applyChannelChanges(bot, channelTones);

    // Redundancy if currentBot is this bot, since it uses a copy rather than reference in botDetails
    if (state.currentBot.uniqueBotId === bot.uniqueBotId) {
      applyChannelChanges(state.currentBot, channelTones);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

import { DialogService } from '../../services/dialogs'

// initial state for authentication
const state =  {
    dialogs: false,
    chat: false
}

const getters = {
    dialogs: (state) => {
        return state.dialogs;
    },
    chat: (state) => {
        return state.chat;
    }
}

const actions = {
    async get({ commit }, uniqueBotId) {
        const result = await DialogService.getDialogs(uniqueBotId);
        commit('setDialogs', result.dialogs);
        return true;
    },
    async chat({commit}, {uniqueUserId, uniqueBotId}) {
      
        const uuid = uniqueUserId.replace(/%C3%B6/g, 'ö').replace(/�/g, 'ö');
        const result = await DialogService.getChat(uuid, uniqueBotId);
        commit('setChat', result.chat);
        return true;
    },
    clearChat({commit}) {
        commit('setChat', false);
    },
    clear({commit}) {
        commit('setDialogs', false);
    }
}

const mutations = {
    setDialogs(state, dialogs) {
        state.dialogs = dialogs;
    },
    setChat(state, chat) {
        state.chat = chat;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
const state = {
  isMenuOpen: false,
};

const getters = {
  isMenuOpen: (state) => state.isMenuOpen,
  isTouchDevice: () => {
    return matchMedia('(hover: none)').matches;
  }
};

const actions = {};

const mutations = {
  setValue(state, { key, value }) {
    state[key] = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
const state =  {
    intentOverviewScrollPosition: 0,
    hublistFilter: {
        intents: true,
        suggestion: false,
        smalltalks: true,
        inReview: false,
    }
}

const getters = {
    intentOverviewScrollPosition: (state) => {
        return state.intentOverviewScrollPosition;
    },
    hublistFilter: (state) => {
        return state.hublistFilter;
    }
}

const actions = {
    setIntentOverviewScrollPosition({commit}, position) {
        commit('setIntentOverviewScrollPosition', position);
    },
    setHublistFilters({commit}, filters) {
        commit('setHublistFilters', filters);
    }
}

const mutations = {

    setHublistFilters(state, filters) {
        if (filters) {
            Object.keys(filters).forEach(key => {
                state.hublistFilter[key] = filters[key];
            });
        }
    },

    setIntentOverviewScrollPosition(state, position) {
        state.intentOverviewScrollPosition = position;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
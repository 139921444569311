// Align value label to progress-bar and prevent overflow
export const getProgressValueStyle = (value) => {
  let style;
  const offsetLeft = value <= 85 && value;
  const offsetRight = value <= 85 ? 'inherit' : 100 - value;

  if (offsetLeft) {
    style = `left: calc(${offsetLeft}% - 12px);`;
  } else {
    style = `right: calc(${offsetRight}% - 12px);%`;
  }

  return style;
};

export const baseBarChartOptions = {
  chart: {
    height: '100%',
    type: 'bar',
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
    sparkline: {
      enabled: false,
    },
  },
  plotOptions: {
    bar: {
      borderRadius: 0,
      columnWidth: '50%',
      barHeight: '100%',
    },
  },
  colors: ['#8AC5C2'],
  annotations: {
    position: 'front',
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    show: true,
    width: 2,
    colors: ['transparent'],
  },
  states: {
    hover: {
      filter: {
        type: 'none',
      },
    },
  },
  xaxis: {
    axisBorder: {
      color: '#BDBDBD',
    },
    axisTicks: {
      show: 0,
    },
    crosshairs: {
      show: false,
    },
  },
  yaxis: {
    crosshairs: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    max: 10,
    tickAmount: 2,
  },
};

<template>
  <div>
    <v-timeline-item hide-dot color="grey lighten-3" fill-dot v-if="content">
      <v-row>
        <v-col>
          <v-text-field
            outlined
            class="font-weight-medium ma-0"
            @input="update"
            dense
            v-model="content.displayName"
            :label="$t('botFormEdit.whatIsAsked')"
          ></v-text-field>
        </v-col>
        <v-col>
          <div v-if="isAdmin" class="moin-admin-box pa-1">
            <v-text-field
              outlined
              v-model="content.context"
              @input="update"
              dense
              :label="$t('botFormEdit.technicalName')"
              hide-details
            />
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-select
            outlined
            dense
            :items="validableTypes"
            item-text="label"
            item-value="value"
            v-model="content.entity"
            :disabled="validableType.custom"
            :label="$t('botFormEdit.validable.entity')"
            @input="update"
          ></v-select>
        </v-col>
      </v-row>
    </v-timeline-item>

    <ActionList
      v-if="content"
      :actions="content.question.actions"
      @hook:mounted="updateName"
      @onUpdate="update"
      :channelType="channelType"
    ></ActionList>

    <v-timeline-item color="white" v-if="content">
      <template v-slot:icon>
        <v-icon color="error">cancel</v-icon>
      </template>

      <span class="text-subtitle-1">
        {{ $t('botFormEdit.validable.validationErrorTitle') }}
      </span>
      <p class="text-subtitle-2">{{ $t('botFormEdit.validable.validationErrorText') }}</p>
    </v-timeline-item>

    <ActionList
      v-if="content"
      :actions="content.validation.actions"
      @onUpdate="update"
      :channelType="channelType"
    ></ActionList>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ActionList from '@/components/ActionList.vue';
import ChannelsUtil from '@/utils/channels';

export default {
  name: 'BotFormValidableInputEdit',
  components: {
    ActionList,
  },
  props: ['box', 'intent', 'channel', 'name'],
  data: () => ({
    tab: null,
  }),
  computed: {
    ...mapGetters('bots', ['currentBotId', 'currentChannels']),
    ...mapGetters('intents', ['intents']),
    ...mapGetters('bots', ['currentBotId']),
    ...mapGetters('auth', ['isAdmin']),
    content() {
      return this.$store.getters['content/getContent'](
        this.currentBotId,
        this.intent,
        this.box,
        true,
        this.channel
      );
    },
    validableType() {
      return this.validableTypes.find(
        (type) => type.value === this.content.entity
      );
    },
    validableTypes() {
      const types = [
        { value: 'email', label: this.$t('common.eMail') },
        { value: 'url', label: this.$t('common.url') },
        { value: 'zipcode', label: this.$t('common.zipCode') },
        { value: 'number', label: this.$t('common.number') },
        { value: 'date', label: this.$t('common.dateName') },
        { value: 'phone', label: this.$t('common.phoneNr') },
        { value: 'licenceplate', label: this.$t('common.licensePlate') },
        { value: 'iban', label: this.$t('common.iban') }
      ]

      // content.entity is not in types, add custom type to list
      if (types.findIndex(type => type.value === this.content.entity) === -1) {
        types.push({ value: this.content.entity, label: this.$t('botFormEdit.validable.custom'), custom: true })
      }

      return types;
    },
    channelType() {
      return ChannelsUtil.getChannelType(
        this.content?.channel,
        this.currentChannels
      );
    },
  },
  methods: {
    updateName() {
      if (this.content && !this.content.displayName) {
        this.content.displayName = this.name;
      }
    },
    update() {
      this.$emit('onUpdated');
    },
  },
  watch: {
    name() {
      this.content.displayName = this.name;
    },
  },
};
</script>

<template></template>
<script>
import { mapGetters } from 'vuex';
import { incomeTypes } from '@/utils/livechat';

const faviconActive = '/favicon-income/favicon-active.png';

const faviconList = [
  '/favicon.png',
  '/favicon-income/favicon-income-01.png',
  '/favicon-income/favicon-income-02.png',
  '/favicon-income/favicon-income-03.png',
  '/favicon-income/favicon-income-04.png',
  '/favicon-income/favicon-income-04.png',
  '/favicon-income/favicon-income-05.png',
  '/favicon-income/favicon-income-06.png',
  '/favicon-income/favicon-income-07.png',
  '/favicon.png',
];

export default {
  name: 'HubFaviconIncome',
  components: {},
  data() {
    return {
      titleInitial: document.title,
      titleUpdateInterval: null,
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...mapGetters('livechat', ['botHasLivechat', 'incomeType', 'isChatOpen']),
  },
  destroyed() {
    clearInterval(this.titleUpdateInterval);
  },
  methods: {
    init() {
      if (this.titleUpdateInterval) {
        this.stopTitleUpdate();
      }
      if (!this.botHasLivechat) {
        return;
      }

      // Prepare favicon images to avoid requests
      const faviconImageList = faviconList.map((path) => {
        const img = new Image();
        img.src = path;
        return img;
      });
      const favicon = document.querySelector('link[rel*="icon"]');
      let faviconIndex = 0;

      const canvas = document.createElement('canvas');
      canvas.width = 60;
      canvas.height = 60;
      const context = canvas.getContext('2d');
      // active tab but no animation
      if (this.incomeType === incomeTypes.activeTab) {
        favicon.href = faviconActive;
        document.title = this.titleInitial;
        this.stopTitleUpdate();
        return;
      }

      const message =
        this.incomeType === incomeTypes.newChat
          ? this.$t('livechat.newChat')
          : this.$t('livechat.newMessage');
      let title = `${message} +++ moin.ai Hub +++`;
      let titleIndex = 0;

      if (!this.incomeType) {
        this.stopTitleUpdate();
        setTimeout(() => {
          favicon.href = faviconList[0];
          document.title = this.titleInitial;
        }, 300);
        return;
      }
      
      this.titleUpdateInterval = setInterval(() => {
        faviconIndex = (faviconIndex + 1) % faviconList.length;
        context.clearRect(0, 0, canvas.width, canvas.height);
        context.drawImage(faviconImageList[faviconIndex], 0, 0);

        favicon.href = canvas.toDataURL('image/png');

        if (titleIndex >= title.length) {
          titleIndex = 0;
        } else {
          titleIndex += 1;
        }
        const partA = title.substring(0, titleIndex);
        const partB = title.substring(titleIndex + 1);
        document.title = partB + ' ' + partA;
      }, 150);

    },
    stopTitleUpdate() {
      clearInterval(this.titleUpdateInterval);
      this.titleUpdateInterval = null;
    },
  },
  watch: {
    incomeType() {
      this.init();
    },
    isChatOpen() {
      this.init();
    },
  },
};
</script>

// This module keeps the expanded state of HubBox.vue components (prop: boxId).
// So UI will remember, which card is opened or closed. Although after reload.

const state = {
  expandedBoxes: [],
  closedBoxes: [],
};

const getters = {
  boxIsExpanded: (state) => (value) => !!state.expandedBoxes.includes(value),
  boxIsClosed: (state) => (value) => !!state.closedBoxes.includes(value),
};

const mutations = {
  addBoxId(state, value) {
    if (state.expandedBoxes.includes(value)) return;
    state.expandedBoxes.push(value);
  },
  removeBoxId(state, value) {
    const index = state.expandedBoxes.findIndex((c) => c === value);
    state.expandedBoxes.splice(index, 1);
  },
  closeBoxId(state, value) {
    if (state.closedBoxes.includes(value)) return;
    state.closedBoxes.push(value);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};

import ProductService from '../../services/product';

const state = {
  botResults: [],
  intentResults: [],
  searchQueries: [],
  suggestionResults: [],
  //searching: false
};

const getters = {
  botResults: (state) => {
    return state.botResults;
  },
  intentResults: (state) => {
    return state.intentResults;
  },
  queries: (state, getters, rootState, rootGetters) => {
    let currentBotId = rootState.bots.currentBot.uniqueBotId;

    return state.searchQueries
      .filter((query) => {
        if (query.type === 'bot' && !rootGetters['auth/isAdmin']) {
          return false;
        }

        return query.type !== 'intent' || query.value.botId === currentBotId;
      })
      .slice(0, 5);
  },
};

const actions = {
  async search({ rootState, commit, rootGetters }, { text, botId }) {
    if (!text || text.trim().length === 0 || text === '') {
      return commit('setResults', { bots: [], intents: [] });
    }

    //commit('searching', true);

    // search bots
    let bots = rootState.bots.bots;
    let botResults = [];
    const language = rootGetters['auth/user'].language;

    if (rootGetters['auth/isAdmin']) {
      botResults = bots
        .filter((bot) => {
          return bot.name.toLowerCase().indexOf(text.toLowerCase()) >= 0;
        })
        .map((bot) => {
          return {
            displayName: bot.name,
            type: 'bot',
            icon: 'smart_toy',
            value: bot,
          };
        });
    }

    // search intent names
    let intentResults = [];

    if (text.trim().length > 1) {
      let intents = rootState.intents.intents;
      let suggestions = rootState.intents.suggested;
      if (suggestions && intents) {
        intents = intents.concat(suggestions);
      }

      if (intents) {
        intentResults = intents
          .filter((intent) => {
            const displayName =
              rootGetters['intents/displayName'](intent.intent, language) ||
              intent.displayName;
            return displayName?.toLowerCase().indexOf(text.toLowerCase()) >= 0;
          })
          .map((intent) => {
            const displayName =
              rootGetters['intents/displayName'](intent.intent, language) ||
              intent.displayName;
            return {
              displayName,
              type: 'intent',
              icon: intent.suggested ? 'auto_awesome' : 'mdi-folder-text',
              value: intent,
            };
          });

        if (text.trim().length > 2) {
          ProductService.nlpCheck(botId, text).then((result) => {
            if (result && result.check && result.check.nlp) {
              let found = intents.find((intent) => {
                return intent.intent === result.check.nlp.intent;
              });

              if (found) {
                const displayName =
                  rootGetters['intents/displayName'](found.intent, language) ||
                  found.displayName;
                commit('unshiftResult', {
                  intent: {
                    displayName,
                    type: 'intent',
                    icon: 'mdi-folder-text',
                    value: found,
                  },
                });
              }
            }
          });
        }
      }
    }

    commit('setResults', { bots: botResults, intents: intentResults });
  },
  async pick({ commit }, item) {
    commit('addQuery', item);
    commit('setResults', { bots: [], intents: [] });
  },
  async removeQuery({ commit }, item) {
    commit('removeQuery', item);
  },
};

const mutations = {
  setResults(state, { bots, intents }) {
    state.botResults = bots.slice(0, 5);
    
    state.intentResults = intents.slice(0, 5);
  },
  unshiftResult(state, { bot, intent }) {
    if (intent) {
      state.intentResults = state.intentResults.filter((q) => {
        return !(
          q.type === intent.type && q.displayName === intent.displayName
        );
      });

      state.intentResults.unshift(intent);
      if (state.intentResults.length > 5) {
        state.intentResults.length = 5;
      }
    }

    if (bot) {
      state.botResults = state.botResults.unshift(bot);
      if (state.botResults.length > 5) {
        state.botResults.length = 5;
      }
    }
  },
  addQuery(state, query) {
    state.searchQueries = state.searchQueries.filter((q) => {
      return !(q.type === query.type && q.displayName === query.displayName);
    });

    state.searchQueries.unshift(query);
    if (state.searchQueries.length > 100) {
      state.searchQueries.length = 100;
    }
  },
  removeQuery(state, query) {
    state.searchQueries = state.searchQueries.filter((q) => {
      return !(q.type === query.type && q.displayName === query.displayName);
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

<template>
    <v-autocomplete
        :value="value"
        chips
        multiple
        @input="$emit('input', $event)"
        :items="items"
        item-text="name"
        item-value="code"
        :label="$t('settingsLivechat.agentDetails.agentLanguages')"
        :filter="filter"
    >
        <template #item="data">
            <CountryFlag :country="data.item.country" size="small" /> 
            <span v-if="useLanguages" class="ml-1">
                {{ data.item.translatedName }} ({{ data.item.name }})
            </span>
            <span class="ml-1" v-else>{{ data.item.translatedName }}</span>
        </template>
        <template #selection="data">
            <v-chip 
                v-bind="data.attrs" 
                class="mr-2"
                close
                @click:close="removeSelected(data.item)"
            >
                <CountryFlag :country="data.item.country" size="small" />
                <span v-if="useLanguages" class="ml-1">
                    {{ data.item.translatedName }} ({{ data.item.name }})
                </span>
                <span class="ml-1" v-else>{{ data.item.translatedName }}</span>
            </v-chip>
        </template>
    </v-autocomplete>
</template>
<script>
import CountryFlag from 'vue-country-flag';
import countryLanguageMixin from '@/mixins/common/countryLanguageMixin';

export default {
    name: 'CountryLanguageSelect',
    components: {
        CountryFlag
    },
    props: {
        useLanguages: {
            type: Boolean,
            default: false
        },
        value: {
            type: Array,
            default: () => []
        }
    },
    mixins: [countryLanguageMixin],
    computed: {
        items() {
            return this.useLanguages ? 
            this.languages
            .sort((a, b) => a.language.localeCompare(b.language))
            .map(language => ({ ...language, code: language.language, translatedName: this.$t(`common.languageNames.${language.language}`)}))
            : 
            this.countries.map(country => ({ ...country, code: country.country.toLowerCase(), translatedName: this.$t(`common.countries.${country.country}`)}));
        }
    },
    methods: {
        filter(item, queryText, itemText) {
            const hasName = itemText.toLowerCase().includes(queryText.toLowerCase());
            const hasCode = item.code.toLowerCase().includes(queryText.toLowerCase());
            const hasTranslatedName = item.translatedName.toLowerCase().includes(queryText.toLowerCase());
            return hasName || hasCode || hasTranslatedName;
        },
        removeSelected(item) {
            this.$emit('input', this.value.filter(value => value !== item.code));
        }
    }
}

</script>
<template>
      <div>
        <h4>Webhook</h4>
        <v-select
            :label="$t('botFormEdit.chooseWebhook')"
            :items="webhookConfigsList"
            item-text="displayName"
            item-value="key"
            v-model="action.refAction.parameters.type"
            @change="update"
          />
      </div>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        name: 'WebhookEdit',
        props: ['action'],
        created() {
            this.$store.dispatch('integrations/fetchWebhookConfigs').then(() => {
                if (!this.action.refAction.parameters?.type) {
                    this.action.refAction.parameters.type = this.webhookConfigsList[0].key
                }
            })
        },
        computed: {
            ...mapGetters('integrations', ['webhookConfigsList']),
        },
        watch: {
            value: function(val) {
                if  (this.editMode === false) {
                    this.parentValue = val;
                }
            }
        },
        methods: {        
           update: function () {
                this.$emit('onUpdated');
           }
        }
    }
</script>
import ApiService from './api';

const IntentService = {
  async validateQuery(botId, query, title, description) {
    try {
      const response = await ApiService.post(
        `/schaltzentrale/intents/assistant/validate-new-topic-query/${botId}`,
        {
          query,
          title,
          description,
        });

      return response.data;
    } catch (error) {
      return false;
    }
  },
  async similarIntents(botId, text, language = 'de') {
    const encodedText = encodeURIComponent(text);
    const response = await ApiService.get(
      `/schaltzentrale/intents/assistant/similar-topics/${botId}?query=${encodedText}&language=${language}`
    );

    return response.data;
  },
  async searchIntent(botId, text) {
    try {
      const response = await ApiService.post(
        `/schaltzentrale/nlp/newtopiccheck/${botId}`,
        { text }
      );
      let result = {};

      if (response && response.data && response.data.result) {
        for (const [key, value] of Object.entries(response.data.result)) {
          let intents = value.topResults.slice();
          const best = intents.find(
            (intent) => intent.label === value.bestfit.label
          );
          if (best) {
            best.confidence += value.bestfit.confidence;
          } else {
            intents.push(value.bestfit);
          }

          intents = intents
            .filter((intent) => {
              if (
                intent.label === 'unknown' ||
                intent.label === 'NAME' ||
                intent.label === 'TOXIC' ||
                intent.label === 'NOISE' ||
                intent.confidence < 0.1
              ) {
                return false;
              }

              return true;
            })
            .sort((a, b) => b.confidence - a.confidence);

          result = { ...result, [key]: intents };
        }
      }

      // merge results except own
      const all = [];
      for (const [key, value] of Object.entries(result)) {
        if (key !== 'own') {
          // merge arrays
          for (const item of value) {
            // const existsInOwn = result.own.find(i => {
            //   return i.label === item.label
            // })

            // if (existsInOwn) {
            //   existsInOwn.confidence += item.confidence
            // } else {
            const exists = all.find((i) => i.label === item.label);
            if (!exists) {
              all.push(item);
            } else {
              exists.confidence += item.confidence;
            }
            // }
          }
        }
      }
      result.all = all;
      result.all.sort((a, b) => b.confidence - a.confidence);

      result.own.sort((a, b) => b.confidence - a.confidence);

      return result;
    } catch (error) {
      return {};
    }
  },
  async getDefaults(type, botId) {
    try {
      const response = await ApiService.get(
        `/schaltzentrale/intent/${type}/${botId}`
      );
      return response.data;
    } catch (_) {
      return [];
    }
  },
  // @ data: {
  //  intent: string,
  //  relatedIntent: string,
  //  relation: "similar" | "special_case" | "generalization",
  // }
  async saveRelation(botId, data) {
    try {
      if (!data?.intent || !data?.relation || !data?.relatedIntent) {
        return false;
      }

      const response = await ApiService.post(
        `/schaltzentrale/intent/${botId}/relation`,
        data
      );
      return response.data;
    } catch (error) {
      return false;
    }
  },
  // @ data: {
  //  intent: string,
  //  relatedIntent: string,
  // }
  async deleteRelation(botId, data) {
    try {
      if (!data?.intent || !data?.relatedIntent) {
        return false;
      }

      const response = await ApiService.delete(
        `/schaltzentrale/intent/${botId}/relation`,
        data
      );
      return response.data;
    } catch (error) {
      return false;
    }
  },
  // Initialize a poll for the review assistant
  async initReviewAssist({
    uniqueBotId,
    intentInReview,
    excludeIntentsInReview,
  }) {
    excludeIntentsInReview = !!excludeIntentsInReview || false;
    try {
      const response = await ApiService.post(
        `/schaltzentrale/reviewAssistant/${uniqueBotId}/intentReview`,
        { uniqueBotId, intentInReview, excludeIntentsInReview }
      );
      return response.data;
    } catch (error) {
      return { error };
    }
  },

  // Check status of poll for the review assistant
  async checkReviewAssist({
    uniqueBotId,
    intentReviewId
  }) {
    console.log('getReviewAssist');
    try {
      const response = await ApiService.get(
        `/schaltzentrale/reviewAssistant/${uniqueBotId}/intentReview/${intentReviewId}`);
      return response.data;
    } catch (error) {
      return { error };
    }
  },
  // All props in lower case, please.
  async saveK2Names({ botId, k2names, intent }) {
    try {
      const response = await ApiService.post(
        `/schaltzentrale/intent/${botId}/k2names`,
        {
          k2names,
          intent,
        }
      );
    } catch (error) {
      return true;
    }
  },

  /**
   * @typedef {Object} K2IndecyItem
   * @property {string} intent The intent technical name
   * @property {string} displayName The intent display name
   * @property {string} description The intent description
   * @property {string[]} k2names
   * @property {number} score The confidence of the match / match score
   */
  /**
   * @typedef {Object} K2IndecyResult
   * @property {string} bot_id Id of the bot
   * @property {string} text The search query
   * @property {K2IndecyItem[]} intents Intents found
   */
  /**
   * Searches for intents/topics using `k2` and `k2index`.
   * Both of these features must be enabled for the bot.
   * @param {string} botId Current bo tID
   * @param {string} text Search query
   * @param {?number} [limit] Optional: limit results to first N matches
   * @returns {Promise<boolean|K2IndecyResult>} `false` if an error occurred, otherwise the result
   */
  async getK2Indices({ botId, text, limit }) {
    const payload = {
      text,
    };

    if (limit) {
      payload.top_k = limit;
    }

    try {
      const response = await ApiService.post(
        `/schaltzentrale/k2/index/intentreduce/${botId}`,
        payload
      );
      return response.data;
    } catch (error) {
      return false;
    }
  },
};

export default IntentService;

export { IntentService };

<template>
  <v-app>
    <HubBg>
      <HubPanelLogo :loading="authenticating">
        <v-form @submit.prevent>
          <v-container>
            <v-row>
              <v-col>
                <h1 class="text-h5 mb-4">{{ $t('login.title') }}</h1>
                <p class="text-body-1">
                  {{ $t('login.subTitle') }}
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  outlined
                  :label="$t('login.username')"
                  name="login"
                  v-model="username"
                  type="text"
                  data-cy="login-username"
                  v-on:keyup.enter.prevent="handleSubmit()"
                  hide-details
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  id="password"
                  :label="$t('login.password')"
                  name="password"
                  v-model="password"
                  type="password"
                  data-cy="login-password"
                  v-on:keyup.enter.prevent="handleSubmit()"
                  hide-details
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" class="pt-6">
                <router-link to="/password" class="grey--text">
                  {{ $t('login.lostPassword') }}
                </router-link>
              </v-col>
              <v-col cols="12" sm="6" class="text-right pt-6">
                <v-btn
                  depressed
                  color="primary"
                  large
                  v-bind:loading="authenticating"
                  data-cy="login"
                  v-on:click="handleSubmit()"
                >
                  {{ $t('login.btnLabel') }}
                </v-btn>
              </v-col>
              <v-col v-if="authenticationError">
                <v-alert outlined type="error">
                  {{ $t('login.errorLogin') }}
                </v-alert>
              </v-col>
            </v-row>
          </v-container>
        </v-form>

        <v-container>
          <div class="d-flex gap-2 items-center py-4">
            <v-divider />
            <span class="text-body-1">{{ $t('common.or') }}</span>
            <v-divider />
          </div>

          <!-- SSO options -->
          <div class="d-flex flex-column justify-center gap-3">
            <SSOLoginButton provider="microsoft" />
          </div>
        </v-container>
      </HubPanelLogo>
    </HubBg>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import HubBg from '@/components/hub/HubBg.vue';
import HubPanelLogo from '@/components/hub/HubPanelLogo.vue';
import { TokenService } from '@/services/token';
import SSOLoginButton from '@/components/common/SSOLoginButton.vue';

export default {
  name: 'login',
  components: {
    SSOLoginButton,
    HubBg,
    HubPanelLogo,
  },

  data() {
    return {
      username: '',
      password: '',
    };
  },

  created() {
    this.loginReset();
  },

  computed: {
    ...mapGetters('auth', ['authenticating', 'authenticationError']),
  },

  methods: {
    ...mapActions('auth', ['login', 'loginReset']),
    ...mapActions('bots', ['clear']),

    async handleSubmit() {
      // Perform a simple validation that email and password have been typed in
      if (this.username && this.password) {
        // call login method from auth store
        await this.login({ username: this.username, password: this.password });
        this.clear();
        this.password = '';
      }
    },
  },
};
</script>

<template>
  <div>
    <v-select
      :value="value"
      @input="updateValue"
      :items="dateOptions"
      :label="$t('dateRange.labelDateRange')"
      prepend-inner-icon="date_range"
      item-text="text"
      item-value="value"
      :menuProps="{ maxHeight: 460 }"
      :disabled="disabled"
    >
      <template v-slot:item="{ item, attrs, on }">
        <v-list-item
          v-on="on"
          v-bind="attrs"
          :class="item.value === openOwnPeriod && 'dr-border-bottom'"
        >
          <v-list-item-content
            :class="item.isOwn && 'primary--text text--lighten-3'"
          >
            {{ item.text }}
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-select>
    <v-menu
      ref="menu"
      v-model="isMenuOpen"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{}">
        <div></div>
      </template>
      <v-date-picker
        v-model="dates"
        range
        no-title
        scrollable
        color="primary lighten-2"
        :max="new Date().toISOString()"
        @input="checkDates()"
      >
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="isMenuOpen = false">Cancel</v-btn>
        <v-btn
          text
          color="primary"
          @click="changeOwnDateRange()"
        >
          OK
        </v-btn>
      </v-date-picker>
    </v-menu>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import DateRangeUtil, { dateRangeTypes } from '@/utils/dateRange';

const openOwnPeriod = 'openOwnPeriod';

export default {
  name: 'DateRange',
  props: {
    value: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dates: null,
      isMenuOpen: false,
      datesFormated: '',
      openOwnPeriod,
    };
  },
  computed: {
    ...mapGetters('dateRange', ['lastOwnDateRanges']),
    dateOptions() {
      return [
        {
          text: this.$t('dateRange.yesterday'),
          value: dateRangeTypes.yesterday,
        },
        {
          text: this.$t('dateRange.lastXdays', { days: String(7) }),
          value: dateRangeTypes.last7days,
        },
        {
          text: this.$t('dateRange.lastXdays', { days: String(28) }),
          value: dateRangeTypes.last28days,
        },
        {
          text: this.$t('dateRange.sinceBeginMonth'),
          value: dateRangeTypes.beginMonth,
        },
        {
          text: this.$t('dateRange.sinceBeginYear'),
          value: dateRangeTypes.beginYear,
        },
        {
          text: this.$t('dateRange.ownPeriod'),
          value: openOwnPeriod,
        },
        ...this.lastOwnDateRanges,
      ];
    },
  },
  methods: {
    updateValue: function(value) {
      if (value === openOwnPeriod) {
        this.isMenuOpen = true;
        return;
      }
      this.$emit('input', value);
      this.$emit('change', value);
    },
    changeOwnDateRange() {
      if (!this.dates) return;

      if (!this.dates[1]) {
        this.dates[1] = this.dates[0];
      }

      const dateRange = DateRangeUtil.getRangeWithDates(this.dates);
      this.datesFormated = DateRangeUtil.getDatesFormatted(dateRange);

      const value = `${this.dates[0]}–${this.dates[1]}`;

      this.$store.commit('dateRange/addOwnDateRange', {
        text: this.datesFormated,
        value,
      });

      this.updateValue(value);

      this.isMenuOpen = false;
    },
    checkDates() {
      if (
        this.dates[0] &&
        this.dates[1] &&
        new Date(this.dates[0]).getTime() > new Date(this.dates[1]).getTime()
      ) {
        this.dates = this.dates.reverse();
      }
    },
  },
};
</script>
<style scoped>
.dr-border-bottom {
  border-bottom: 1px solid #e0e0e0;
}
</style>

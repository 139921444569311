<template>
  <div class="checker pa-1" @click="isDialogOpen = true">
    <div class="d-flex">
      <div class="checker-icon mr-1" style="padding: 2px">
        <v-icon
          v-if="status === statusTypes.loading"
          size="24"
          class="mt-1"
          key="icon-a"
        >
          mdi-eyedropper
        </v-icon>
        <v-icon
          v-if="status === statusTypes.good"
          size="28"
          color="green"
          key="icon-b"
        >
          mdi-check-circle
        </v-icon>
        <v-icon
          v-if="status === statusTypes.bad"
          size="28"
          color="warning"
          key="icon-c"
        >
          mdi-circle-half-full
        </v-icon>
      </div>
      <div class="checker-rows pr-2">
        <div class="checker-row-a text-base-2" style="font-size: 0.8em">
          {{ $t('contrastChecker.title') }}
        </div>
        <div class="checker-row-b">
          <div v-if="status === statusTypes.loading" class="pt-1">
            <v-progress-linear indeterminate color="secondary darken-2" />
          </div>
          <div v-else class="text-base-2 mt-n1" style="font-size: 0.7em">
            <span v-if="status === statusTypes.good">
              {{ $t('contrastChecker.good') }}
            </span>
            <span v-if="status === statusTypes.bad">
              {{ $t('contrastChecker.bad') }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <HubDialog
      v-model="isDialogOpen"
      :title="$t('contrastChecker.dialog.title')"
      :primary-label="$t('common.ok')"
      primary-icon="close"
      @onPrimary="isDialogOpen = false"
      hideSecondary
    >
      <div v-html="$t('contrastChecker.dialog.body')"></div>
    </HubDialog>
  </div>
</template>
<script>
import HubDialog from '@/components/hub/HubDialog.vue';
import CCC from 'color-contrast-checker';
import { rgbToHex } from '@/utils/colors';

const statusTypes = {
  loading: 'loading',
  good: 'good',
  bad: 'bad',
};

export default {
  name: 'ContrastChecker',
  components: {
    HubDialog,
  },
  props: {
    colorA: {
      type: String,
    },
    colorB: {
      type: String,
    },
    fontSize: {
      type: Number,
      default: 16,
    },
  },
  data: () => ({
    status: statusTypes.loading,
    statusTypes,
    delayTimer: null,
    isDialogOpen: false,
  }),
  watch: {
    colorA() {
      this.checkContrast();
    },
    colorB() {
      this.checkContrast();
    },
    fontSize() {
      this.checkContrast();
    },
  },
  created() {
    this.checkContrast();
  },
  methods: {
    checkContrast() {
      this.status = statusTypes.loading;
      const ccc = new CCC();
      let colorA = this.colorA;
      let colorB = this.colorB;

      if (colorA.includes('rgb')) {
        colorA = rgbToHex(colorA);
      }
      if (colorB.includes('rgb')) {
        colorB = rgbToHex(colorB);
      }

      clearTimeout(this.delayTimer);

      this.delayTimer = setTimeout(() => {
        try {
          if (ccc.isLevelAA(colorA, colorB, this.fontSize)) {
            this.status = statusTypes.good;
          } else {
            this.status = statusTypes.bad;
          }
        } catch (e) {}
      }, 700);
    },
  },
};
</script>

<style scoped>
.checker {
  display: inline-block;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  cursor: pointer;
}

.checker-rows {
  width: 120px;
}

.checker-icon {
  width: 32px;
  height: 32px;
}
</style>

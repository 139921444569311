<template>
  <v-row>
    <v-col cols="12" class="pb-0">
      <h3 class="text-h6">{{ $t('teasers.triggers.title') }}</h3>
    </v-col>
    <v-col class="mb-4" cols="12" md="6">
      <div class="text-body-1 mb-4">{{ $t('teasers.triggers.info') }}</div>
      <v-text-field
        outlined
        dense
        hide-details
        style="max-width: 200px"
        type="number"
        :suffix="
          timeoutInSeconds === 1
            ? $t('teasers.triggers.second')
            : $t('teasers.triggers.seconds')
        "
        :label="$t('teasers.triggers.triggerOptions.afterTime.inputLabel')"
        :value="timeoutInSeconds"
        @input="changeTimeout"
      ></v-text-field>
    </v-col>
    <v-col>
      <div class="text-body-1 mb-4">
        {{ $t('teasers.triggers.infoDevices') }}
      </div>

      <v-radio-group
        class="my-2"
        :value="teaser.device || 'both'"
        row
        dense
        hide-details
        @change="(value) => changeDevice(value)"
      >
        <v-radio
          :label="$t(`teasers.triggers.devices.both`)"
          value="both"
        ></v-radio>
        <v-radio
          :label="$t(`teasers.triggers.devices.mobile`)"
          value="mobile"
        ></v-radio>
        <v-radio
          :label="$t(`teasers.triggers.devices.desktop`)"
          value="desktop"
        ></v-radio>
      </v-radio-group>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  name: 'TeasersTriggers',
  components: {},
  computed: {
    ...mapGetters('teasers', ['teaser', 'isDirty']),
    timeoutInSeconds() {
      return this.teaser.payload.timeout / 1000;
    },
  },
  methods: {
    changeDevice(device) {
      this.$store.commit('teasers/setTeaserDevice', device);
      this.$store.commit('teasers/checkIsDirty');
    },
    changeTimeout(timeoutInSeconds) {
      this.$store.commit('teasers/setTimeout', timeoutInSeconds * 1000);
      this.$store.commit('teasers/checkIsDirty');
    },
  },
};
</script>

<template>
  <HubBoxSimple :title="$t('quotaInfo.title')">
    <template v-slot:content>
      <div v-if="!quota">Noch keine Quota gesetzt</div>
      <div>
        <div class="d-flex justify-space-between">
          <div class="mb-2">
            <v-icon small color="primary lighten-3" class="mr-1">mdi-folder-text</v-icon>
            <span class="text-body-2 grey--text text--darken-2">
              {{ $t('quotaInfo.intentsLabel') }}:
            </span>
          </div>
          <div>
            {{ quota.intents - intentsUsed }}
            <span class="grey--text text--darken-2">/{{ quota.intents }}</span>
          </div>
        </div>
        <div class="d-flex justify-space-between">
          <div class="mb-2">
            <v-icon small color="primary lighten-3" class="mr-1">
              mdi-format-list-bulleted-type
            </v-icon>
            <span class="text-body-2 grey--text text--darken-2">
              {{ $t('quotaInfo.channelsLabel') }}:
            </span>
          </div>
          <div>
            {{ quota.channels - channelsUsed }}
            <span class="grey--text text--darken-2">/{{ quota.channels }}</span>
          </div>
        </div>
        <div class="d-flex justify-space-between">
          <div class="mb-2">
            <v-icon small color="primary lighten-3" class="mr-1">list_alt</v-icon>
            <span class="text-body-2 grey--text text--darken-2">
              {{ $t('quotaInfo.formsLabel') }}:
            </span>
          </div>
          <div>
            {{ quota.forms - formsUsed }}
            <span class="grey--text text--darken-2">/{{ quota.forms }}</span>
          </div>
        </div>
        <div class="d-flex justify-space-between">
          <div class="mb-2">
            <v-icon small color="primary lighten-3" class="mr-1">mdi-web</v-icon>
            <span class="text-body-2 grey--text text--darken-2">
              {{ $t('quotaInfo.languagesLabel') }}:
            </span>
          </div>
          <div>
            {{ quota.languages.length - languagesUsed.length }}
            <span class="grey--text text--darken-2">
              /{{ quota.languages.length }}
            </span>
          </div>
        </div>
      </div>
    </template>
  </HubBoxSimple>
</template>
<script>
import { mapGetters } from 'vuex';
import HubBoxSimple from '@/components/hub/HubBoxSimple.vue';
export default {
  name: 'QuotaInfo',
  components: {
    HubBoxSimple,
  },
  computed: {
    ...mapGetters('bots', [
      'currentBotId',
      'getBotSettings',
      'currentChannels',
    ]),
    ...mapGetters('forms', ['forms']),
    ...mapGetters('intents', ['intents']),
    quota() {
      const { quota } = this.getBotSettings(this.currentBotId);
      return quota;
    },
    intentsUsed() {
      return this.intents?.length || 0;
    },
    channelsUsed() {
      return this.currentChannels?.length || 0;
    },
    formsUsed() {
      return this.forms?.length || 0;
    },
    languagesUsed() {
      const usedLanguages = [];

      // Get languages from each channel
      this.currentChannels.forEach((channel) => {
        channel.languages.forEach((lang) => {
          if (!usedLanguages.includes(lang)) {
            usedLanguages.push(lang);
          }
        });
      });

      if (!usedLanguages.length) {
        usedLanguages.push('de');
      }

      return usedLanguages;
    },
  },
};
</script>

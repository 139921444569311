<template>
  <v-container class="text-caption">
    <v-col>
      <v-row v-for="req in Object.keys(passwordRequirements)" :key="req">
        <span :class="{'text--disabled': passwordRequirements[req]}">
          <v-icon 
            :color="passwordRequirements[req] ? 'success': 'warning'"
            x-small
            left
          >{{ passwordRequirements[req] ? 'mdi-check' : 'mdi-circle-outline' }}</v-icon>
          {{ $t('login.passwordReset.setPassword.complexity.' + req) }}
        </span>
      </v-row>
    </v-col>
  </v-container>
</template>
<script>
import validatePasswordComplexity from '@/utils/validatePasswordComplexity';

export default {
  props: {
    password: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      passwordRequirements: {}, // { min: true, uppercase: false, ... }
    };
  },
  computed: {
    isValidPassword() {
      return Object.values(this.passwordRequirements).every((req) => req);
    },
    
  },
  watch: {
    password(pass) {
      this.updatePasswordRequirements(pass);
    }
  },
  methods: {
    updatePasswordRequirements(pass) {
      const errors = validatePasswordComplexity(pass);
      // mark all errors found as false and the rest as true
      const newRequirementValues = {};
      Object.keys(this.passwordRequirements).forEach((key) => {
        newRequirementValues[key] = this.passwordRequirements[key] = !errors.includes(key);
      });
      this.passwordRequirements = newRequirementValues;
      this.$emit('update:isValidPassword', this.isValidPassword);
    },
    initPasswordRequirements() {
      // seed with all possible errors
      const errors = validatePasswordComplexity('');
      errors.forEach((error) => {
        this.passwordRequirements[error] = false;
      });
    },
  },
  created() {
    this.initPasswordRequirements();
  }
};

</script>
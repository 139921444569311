<template>
  <v-col cols="12">
    <v-row v-if="hasVariation" class="mb-4 pr-4">
      <v-spacer></v-spacer>
      <v-menu v-model="variationMenu" :close-on-content-click="false" offset-y>
        <template v-slot:activator="{ on: onMenu, attrs: attrsMenu }">
          <v-tooltip bottom color="primary lighten-3">
            <template v-slot:activator="{ on: onTooltip, attrs: attrsTooltip }">
              <v-btn
                v-on="{ ...onMenu, ...onTooltip }"
                v-bind="{ ...attrsMenu, ...attrsTooltip }"
                class="ml-2 mr-2 rounded"
                color="primary lighten-3"
                icon
                tile
              >
                <v-icon>library_books</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('botFormEdit.variations') }}</span>
          </v-tooltip>
        </template>
        <v-list>
          <v-list-item-group v-model="variation" mandatory>
            <v-list-item v-for="index in variationCount" :key="index">
              <v-list-item-avatar>{{ index }}.</v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t('botFormEdit.variation') }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action v-if="variationCount > 1">
                <v-btn tile icon @click="removeVariant(index)">
                  <v-icon small>{{ iconDelete }}</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list-item-group>
          <v-divider></v-divider>
          <v-list-item @click="addVariant">
            <v-list-item-avatar>
              <v-icon left small>{{ iconAdd }}</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t('botFormEdit.newVariation') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-row>

    <HubDialog
      v-model="deleteDialog"
      :title="$t('botFormEdit.removeTitle')"
      :primary-label="$t('common.remove')"
      primary-icon="save"
      :secondary-label="$t('common.cancel')"
      secondary-icon="close"
      @onPrimary="_deleteConfirm"
      @onSecondary="deleteDialog = false"
    >
      <div class="text-body-1 py-4">
        {{ $t('botFormEdit.removeDescription') }}
      </div>
    </HubDialog>

    <div data-cy="text-input-parent">
      <v-textarea
        v-if="isText"
        :label="labelString"
        filled
        v-model="textModel.data"
        :rows="rows"
        @input="update"
        :append-icon="isDeletable"
        @click:append="_delete"
        auto-grow
        data-cy="text-input"
      ></v-textarea>
    </div>
    <v-row v-if="isWebhook" class="moin-admin-box">
      <v-col v-if="isDeletable" cols="12">
        <v-btn @click="_delete" icon tile data-cy="delete-image-button"><v-icon>delete</v-icon></v-btn>
      </v-col>
      <v-col cols="12" md="6">
        <WebhookEdit :action="action"  @onUpdated="update"></WebhookEdit>
      </v-col>
    </v-row>
    <v-row v-if="isUserlikeCheck" class="moin-admin-box">
      <v-col v-if="isDeletable" cols="12">
        <v-btn @click="_delete" icon tile data-cy="delete-image-button"><v-icon>delete</v-icon></v-btn>
      </v-col>
      <v-col cols="12" md="6">
        Userlike Check ob Agenten verfügbar sind: agent_available, agent_not_available
      </v-col>
    </v-row>
    <v-row v-if="isImage">
      <v-col v-if="isDeletable" cols="12">
        <v-btn @click="_delete" icon tile data-cy="delete-image-button"><v-icon>delete</v-icon></v-btn>
      </v-col>
      <v-col cols="12" md="6">
        <v-img
          :src="textModel.data"
          max-height="200"
          width="358"
          @dragover.prevent
          @dragleave="dragleave"
          @dragenter="dragenter"
          @drop="drop"
          data-cy="image"
        >
          <v-row
            v-show="hoveringContent"
            class="fill-height ma-0 success"
            align="center"
            justify="center"
          >
            <v-icon large color="white">insert_photo</v-icon>
          </v-row>
          <v-row
            v-show="hoverCounter > 0 && !hoveringContent"
            class="fill-height ma-0 error"
            align="center"
            justify="center"
          >
            <v-icon large color="white">close</v-icon>
          </v-row>
        </v-img>
        <v-row class="pa-2">
          <v-col cols="12">
            <v-file-input
              :accept="acceptedImageTypes"
              @click:append-outer="upload()"
              append-outer-icon="mdi-cloud-upload"
              show-size
              :label="$t('botFormEdit.replaceImage')"
              v-model="files"
              data-cy="image-file-input"
              :loading="isUploading"
              :disabled="isUploading"
              :rules="rules"
            ></v-file-input>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="isCards">
      <v-col cols="12">
        <v-tabs v-model="cardTab" show-arrows data-cy="cards">
          <v-tab v-for="(card, index) in cards" :key="index" data-cy="card-tab">
            Slide {{ index + 1 }}
          </v-tab>

          <v-btn
            :disabled="cards.length >= 10"
            @click="addCard"
            icon
            class="align-self-center mr-4"
            data-cy="add-new-card-button"
          >
            <v-icon small>add</v-icon>
          </v-btn>
          <v-btn
            :disabled="cards.length < 2"
            @click="removeCard"
            icon
            class="align-self-center mr-4"
          >
            <v-icon small>{{ iconRemove }}</v-icon>
          </v-btn>

          <v-btn
            v-if="isDeletable"
            @click="_delete"
            icon
            class="align-self-center mr-4"
            data-cy="delete-card-button"
          >
            <v-icon small>{{ iconDelete }}</v-icon>
          </v-btn>
          <v-tab-item class="pt-2" v-for="(card, index) in cards" :key="index">
            <v-card
              flat
              v-if="editable"
              @dragover.prevent
              @dragleave="dragleave"
              @dragenter="(e) => dragenter(e, true)"
              @drop="drop"
            >
              <v-row>
                <v-col cols="12" md="6">
                  <v-img
                    v-if="getIsImage(card.image.data)"
                    :src="card.image.data"
                    :aspect-ratio="4 / 3"
                    data-cy="card-image"
                  >
                    <v-row
                      v-show="hoveringContent"
                      class="fill-height ma-0 success"
                      align="center"
                      justify="center"
                    >
                      <v-icon large color="white">insert_photo</v-icon>
                    </v-row>
                    <v-row
                      v-show="hoverCounter > 0 && !hoveringContent"
                      class="fill-height ma-0 error"
                      align="center"
                      justify="center"
                    >
                      <v-icon large color="white">close</v-icon>
                    </v-row>
                  </v-img>
                  <video v-else class="video-player" controls preload="auto">
                    <source :src="card.image.data" type="video/mp4" />
                  </video>
                  <v-file-input
                    :accept="acceptedMediaTypes"
                    @click:append-outer="upload(card)"
                    append-outer-icon="mdi-cloud-upload"
                    show-size
                    :label="$t('botFormEdit.replaceImageOrVideo')"
                    v-model="files"
                    data-cy="card-image-file-input"
                    :loading="isUploading"
                    :disabled="isUploading"
                    :rules="rules"
                  ></v-file-input>
                  <v-text-field
                    :label="$t('botFormEdit.titleLabel')"
                    :placeholder="$t('botFormEdit.titlePlaceholder')"
                    v-model="card.title.data"
                    @input="update"
                    filled
                    data-cy="card-title"
                  ></v-text-field>
                  <v-textarea
                    :label="$t('botFormEdit.descriptionLabel')"
                    :placeholder="$t('botFormEdit.descriptionLabel')"
                    v-model="card.subtitle.data"
                    @input="update"
                    filled
                    data-cy="card-description"
                  ></v-textarea>
                </v-col>
              </v-row>
              <ActionButtonList
                :channelType="channelType"
                :buttons="card.buttons"
                @onUpdated="update"
                @addButton="addCardButton"
                @removeButton="removeCardButton"
              ></ActionButtonList>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
    <ActionButtonList
      v-if="hasButtons"
      :buttons="buttons"
      @onUpdated="update"
      @addButton="addButton"
      @removeButton="removeButton"
      :channelType="channelType"
    ></ActionButtonList>
    <v-row v-if="quickreplies">
      <v-col cols="12" class="pt-0 pb-0">
        {{ $t('botFormEdit.quickReplies') }}
      </v-col>
      <v-col cols="12">
        <v-chip-group
          show-arrows
          v-model="selectedQuickReply"
          :column="dragging"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                v-on="on"
                v-bind="attrs"
                label
                @click="addQuick"
                :disabled="allIntents.length === 0"
              >
                <v-icon>{{ iconAdd }}</v-icon>
              </v-chip>
            </template>
            <span>{{ $t('botFormEdit.addQuickReplies') }}</span>
          </v-tooltip>
          <VueDraggable
            v-model="action.content.quick"
            @start="dragStart"
            @end="dragEnd"
            @change="update"
          >
            <v-chip
              color="primary lighten-3"
              v-for="(chip, index) in action.content.quick"
              :key="index"
              close
              :close-icon="iconDelete"
              @click:close="deleteQuick(chip)"
            >
              {{ quickLabel(chip.label) }}
            </v-chip>
          </VueDraggable>
        </v-chip-group>
      </v-col>
      <v-col
        cols="12"
        md="6"
        v-if="
          action.content.quick.length > 0 &&
          selectedQuickReply !== undefined &&
          selectedQuickReply !== null &&
          selectedQuickReply !== 0
        "
      >
        <v-text-field
          label="Label"
          v-model="
            quickData(action.content.quick[selectedQuickReply - 1].label).data
          "
          @input="update"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        md="6"
        v-if="
          action.content.quick.length > 0 &&
          selectedQuickReply !== undefined &&
          selectedQuickReply !== null &&
          selectedQuickReply !== 0
        "
      >
        <intent-selection
          v-model="action.content.quick[selectedQuickReply - 1].intent"
          @change="update"
          :exclude="quickIntents"
        ></intent-selection>
        <!--  <v-text-field label="Thema / Formular" v-model="action.content.quick[selectedQuickReply].intent" @input="update"></v-text-field> -->
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { mapGetters } from 'vuex';
// import BotService from '../services/bot';
import ActionButtonList from '@/components/ActionButtonList.vue';
import ActionUtil from '../utils/actions';
import IntentSelection from './editor/IntentSelection.vue';
import VueDraggable from 'vuedraggable';
import UploadService from '../services/upload';
import HubDialog from '@/components/hub/HubDialog.vue';
import { iconAdd, iconRemove, iconDelete } from '@/utils/icons';
import axios from 'axios';
import WebhookEdit from './WebhookEdit.vue';

export default {
  name: 'ActionEdit',
  components: {
    ActionButtonList,
    IntentSelection,
    VueDraggable,
    HubDialog,
    WebhookEdit,
  },
  props: {
    action: Object,
    short: {
      type: Boolean,
      default: false,
    },
    label: String,
    deletable: {
      type: Boolean,
      default: false,
    },
    quickreplies: {
      type: Boolean,
      default: false,
    },
    variations: {
      type: Boolean,
      default: false,
    },
    channelType: {
      type: String,
      default: 'widget',
    },
  },
  data() {
    return {
      file: null,
      deleteDialog: null,
      cardTab: null,
      selectedQuickReply: null,
      currentTag: null,
      dragging: false,
      variation: 0,
      variationMenu: false,
      version: 0,
      hoverCounter: 0,
      hoveringContent: false,
      editable: true,
      files: [],
      isUploading: false,
      acceptedImageTypes: 'image/png, image/jpeg, image/bmp, image/gif',
      acceptedMediaTypes:
        'image/png, image/jpeg, image/bmp, image/gif, video/quicktime, video/mpeg, video/x-m4v, video/mpeg2, video/x-mpeg2, video/mp4',
      rules: [
        (value) =>
          !value?.size ||
          value?.size < process.env.VUE_APP_MAX_FILE_SIZE_UPLOAD ||
          this.$t('botFormEdit.ruleSizeLess100Mb'),
      ],
      iconAdd,
      iconRemove,
      iconDelete,
    };
  },
  computed: {
    selectedVariation: function () {
      if (this.variationCount <= this.variation) {
        return 0;
      }

      return this.variation;
    },
    hasVariation: function () {
      return this.variations && this.isText && !this.hasButtons;
    },
    variationCount: function () {
      if (this.hasVariation) {
        if (Array.isArray(this.action.content.data)) {
          return this.action.content.data.length;
        } else {
          return 1;
        }
      }

      return 0;
    },
    isDeletable: function () {
      if (this.deletable === true) {
        return 'delete';
      } else return null;
    },
    labelString: function () {
      if (this.label) {
        return this.label;
      }

      if (this.isText) {
        return 'Text';
      }

      return '';
    },
    rows: function () {
      return this.short !== undefined ? 2 : 5;
    },
    isText: function () {
      return (
        this.action.content &&
        (this.action.content.type === 'text' ||
          this.action.content.type === 'button')
      );
    },
    hasButtons: function () {
      return this.action.content && this.action.content.type === 'button';
    },
    isImage: function () {
      return this.action.content && this.action.content.type === 'image';
    },
    isCards: function () {
      return this.action.content && this.action.content.type === 'cards';
    },
    isWebhook: function () {
      return this.action.content && this.action.content.type === 'webhook';
    },
    isUserlikeCheck: function() {
      return this.action.content && this.action.content.type === 'userlike_check';
    },
    cards: function () {
      let cards = [];
      this.action.content.data.forEach((card) => {
        let c = {};
        c.title = ActionUtil.getStringObjectFrom(
          card.title,
          this.action.content.strings
        );
        c.subtitle = ActionUtil.getStringObjectFrom(
          card.subtitle,
          this.action.content.strings
        );
        c.image = ActionUtil.getStringObjectFrom(
          card.image,
          this.action.content.strings
        );

        if (!c.image.data.startsWith('http') || !c.image.data) {
          c.image.data =
            'https://media.moin.ai/1619973037606_bild-platzhalter-var2jpg';
        }

        let btns = [];
        card.buttons.forEach((btn) => {
          if (btn.type === 'web_url') {
            let newBtn = {};
            newBtn.title = ActionUtil.getStringObjectFrom(
              btn.title,
              this.action.content.strings
            );
            newBtn.url = ActionUtil.getStringObjectFrom(
              btn.payload,
              this.action.content.strings
            );
            btns.push(newBtn);
          }

          if (btn.type === 'url_postback') {
            let newBtn = {};
            newBtn.title = ActionUtil.getStringObjectFrom(
              btn.title,
              this.action.content.strings
            );
            newBtn.url = ActionUtil.getStringObjectFrom(
              btn.payload.url,
              this.action.content.strings
            );
            btns.push(newBtn);
          }
        });
        c.buttons = btns;
        cards.push(c);
      });

      return cards;
    },
    buttons: function () {
      if (this.action.content.type === 'text') return false;

      if (this.action.content.type === 'button') {
        let btns = [];
        this.action.content.data[this.selectedVariation].buttons.forEach(
          (btn) => {
            if (btn.type === 'web_url') {
              let newBtn = {};
              newBtn.title = ActionUtil.getStringObjectFrom(
                btn.title,
                this.action.content.strings
              );
              newBtn.url = ActionUtil.getStringObjectFrom(
                btn.payload,
                this.action.content.strings
              );
              btns.push(newBtn);
            }

            if (btn.type === 'url_postback') {
              let newBtn = {};
              newBtn.title = ActionUtil.getStringObjectFrom(
                btn.title,
                this.action.content.strings
              );
              newBtn.url = ActionUtil.getStringObjectFrom(
                btn.payload.url,
                this.action.content.strings
              );
              btns.push(newBtn);
            }
          }
        );

        return btns;
      }

      return [];
    },
    textModel: function () {
      let dataId = this.action.content.data;

      if (this.action.content.type === 'button') {
        dataId = this.action.content.data[this.selectedVariation].text;
      }

      //  supports no variations, takes only first
      if (Array.isArray(dataId) && dataId.length > 0) {
        dataId = dataId[this.selectedVariation];
      }

      return ActionUtil.getStringObjectFrom(
        dataId,
        this.action.content.strings
      );
    },
    fileupload: function () {
      return this.file ? 'cloud_upload' : undefined;
    },
    ...mapGetters('intents', ['intents', 'displayName']),
    ...mapGetters('forms', ['forms']),
    ...mapGetters('bots', ['currentBotId']),
    ...mapGetters('auth', ['isAdmin']),
    allIntents() {
      let all = [];
      all = all.concat(this.intents);
      all = all.concat(this.forms);

      all = all.filter((a) => {
        let found = this.action.content.quick.find((q) => {
          return q.intent === a.intent;
        });

        return !found;
      });

      return all;
    },
    quickIntents() {
      return this.action.content.quick.map((q) => {
        return q.intent;
      });
    },
  },
  methods: {
    removeVariant(index) {
      this.action.content.data.splice(index - 1, 1);
      this.update();
    },
    addVariant() {
      if (!Array.isArray(this.action.content.data)) {
        this.action.content.data = [this.action.content.data];
      }

      let newLabel = `text${new Date().getTime()}`;
      this.action.content.strings.push({
        name: newLabel,
        data: 'neue variante',
      });
      this.action.content.data.push(`{${newLabel}}`);

      this.variation = this.action.content.data.length - 1;

      this.variationMenu = false;
      this.update();
    },
    dragStart() {
      this.dragging = true;
      if (
        this.selectedQuickReply !== 0 &&
        this.selectedQuickReply !== null &&
        this.selectedQuickReply !== undefined
      ) {
        this.currentTag =
          this.action.content.quick[this.selectedQuickReply - 1].intent;
      }
    },
    dragEnd() {
      this.dragging = false;

      if (this.currentTag) {
        let idx = this.action.content.quick.findIndex((q) => {
          return q.intent === this.currentTag;
        });

        this.selectedQuickReply = idx + 1;
        this.currentTag = null;
      }
    },
    quickLabel: function (labelString) {
      let data = this.action.content.strings.find((data) => {
        return `{${data.name}}` === labelString;
      });
      return data ? data.data : labelString;
    },
    quickData: function (labelString) {
      let data = this.action.content.strings.find((data) => {
        return `{${data.name}}` === labelString;
      });
      return data;
    },
    addQuick: function () {
      let newLabel = `label${new Date().getTime()}`;

      let qr = this.allIntents[0];
      this.action.content.strings.push({
        name: newLabel,
        data: this.displayName(qr.intent, this.$vuetify.lang.current),
      });
      this.action.content.quick.unshift({
        label: `{${newLabel}}`,
        intent: qr.intent,
        parameters: { confidence: 1 },
      });
      this.selectedQuickReply = 1;
      this.update();
    },
    deleteQuick: function (quick) {
      if (this.selectedQuickReply === this.action.content.quick.length) {
        this.selectedQuickReply = this.selectedQuickReply - 1;
      }
      this.action.content.quick = this.action.content.quick.filter((q) => {
        return q.intent !== quick.intent;
      });

      // remove label from strings
      this.action.content.strings = this.action.content.strings.filter(
        (str) => {
          return `{${str.name}}` !== quick.label;
        }
      );

      this.update();
    },
    update: function () {
      this.$emit('onUpdated');
    },
    _delete: function () {
      this.deleteDialog = true;
    },
    _deleteConfirm: function () {
      this.deleteDialog = false;
      this.$emit('onDeleted');
    },
    addCard: function () {
      let dateSuffix = '_' + Date.now();
      let newCard = {
        title: `{title${dateSuffix}}`,
        subtitle: `{subtitle${dateSuffix}}`,
        image: `{image${dateSuffix}}`,
        buttons: [],
      };

      this.action.content.strings.push({
        name: `title${dateSuffix}`,
        data: 'neuer title',
      });
      this.action.content.strings.push({
        name: `subtitle${dateSuffix}`,
        data: 'neue Beschreibung',
      });
      this.action.content.strings.push({
        name: `image${dateSuffix}`,
        data: 'https://media.moin.ai/1619973037606_bild-platzhalter-var2jpg',
      });

      this.action.content.data.push(newCard);
      this.cardTab = this.action.content.data.length - 1;

      this.update();
    },
    addButton: function () {
      let dateSuffix = '_' + Date.now();
      let newBtn = {
        title: `{title${dateSuffix}}`,
        payload: `{url${dateSuffix}}`,
        type: 'web_url',
      };

      this.action.content.strings.push({
        name: `title${dateSuffix}`,
        data: 'neuer title',
      });
      this.action.content.strings.push({
        name: `url${dateSuffix}`,
        data: 'https://moin.ai',
      });
      this.action.content.data[this.selectedVariation].buttons.push(newBtn);

      this.update();
    },
    removeButton: function (index) {
      let btn = this.action.content.data[this.selectedVariation].buttons[index];

      this.action.content.strings = this.action.content.strings.filter(
        (str) => {
          return str.name !== btn.title.name && str.name !== btn.payload.name;
        }
      );

      this.action.content.data[this.selectedVariation].buttons.splice(index, 1);

      if (
        this.action.content.data[this.selectedVariation].buttons.length === 0
      ) {
        this.action.content.type = 'text';
        // TODO
        this.action.content.data =
          this.action.content.data[this.selectedVariation].text;
      }

      this.update();
    },
    removeCardButton: function (index) {
      let btn = this.action.content.data[this.cardTab].buttons[index];

      this.action.content.strings = this.action.content.strings.filter(
        (str) => {
          return str.name !== btn.title.name && str.name !== btn.payload.name;
        }
      );

      this.action.content.data[this.cardTab].buttons.splice(index, 1);
      this.update();
    },
    addCardButton: function () {
      let dateSuffix = '_' + Date.now();
      let newBtn = {
        title: `{title${dateSuffix}}`,
        payload: `{url${dateSuffix}}`,
        type: 'web_url',
      };

      this.action.content.strings.push({
        name: `title${dateSuffix}`,
        data: 'neuer title',
      });
      this.action.content.strings.push({
        name: `url${dateSuffix}`,
        data: 'https://knowhere.to',
      });

      this.action.content.data[this.cardTab].buttons.push(newBtn);

      this.update();
    },
    removeCard: function () {
      let card = this.action.content.data[this.cardTab];

      this.action.content.strings = this.action.content.strings.filter(
        (str) => {
          return (
            str.name !== card.title.name &&
            str.name !== card.subtitle.name &&
            str.name !== card.image.name
          );
        }
      );

      this.action.content.data.splice(this.cardTab, 1);
      this.cardTab = this.action.content.data.length - 1;
      this.update();
    },
    upload: async function (item) {
      if (this.files) {
        this.isUploading = true;
        let response = await UploadService.uploadFile(this.files, () => {});
        this.isUploading = false;

        if (response && response.file && response.file.location) {
          if (item) {
            item.image.data = response.file.location;
          } else {
            this.textModel.data = response.file.location;
          }

          this.update();
        }

        this.files = null;
      }
    },
    dragenter(e, videoAllowed) {
      this.hoverCounter++;
      if (
        this.hoverCounter > 0 &&
        e.dataTransfer &&
        e.dataTransfer.items &&
        e.dataTransfer.items.length === 1
      ) {
        if (e.dataTransfer.items[0].kind === 'file') {
          const type = e.dataTransfer?.items[0]?.type;
          const fileAccepted = videoAllowed
            ? this.getIsAcceptedMedia(type)
            : this.getIsAcceptedImage(type);
          if (fileAccepted) {
            this.hoveringContent = true;
          } else {
            this.hoveringContent = false;
          }
        } else {
          this.hoveringContent = false;
        }
      } else {
        this.hoveringContent = false;
      }
    },
    dragleave() {
      this.hoverCounter--;
      if (this.hoverCounter === 0) {
        this.hoveringContent = false;
      }
    },
    drop(e) {
      e.preventDefault(); // Keep from leaving the page
      this.hoverCounter = 0; // Content can't be dragged out, so go ahead and reset the counter
      this.hoveringContent = false;
      if (e.dataTransfer.items && e.dataTransfer.items.length === 1) {
        //const rejected = [] // Keeps track of rejected items for reporting at the end
        for (let i = 0; i < e.dataTransfer.items.length; i++) {
          if (e.dataTransfer.items[i].kind === 'file') {
            // Directories are not supported. Skip any that are found
            if (e.dataTransfer.items[i].webkitGetAsEntry) {
              const entry = e.dataTransfer.items[i].webkitGetAsEntry();
              if (entry.isDirectory) {
                continue;
              }
            }
            const file = e.dataTransfer.items[i].getAsFile();
            if (this.getIsAcceptedMedia(file?.type)) {
              this.files = file;
            }
          }
        }
      }
    },
    async isUrlImage(url) {
      // check if url has one of the suffixes anywhere in url path
      const suffixList = ['jpg', 'jpeg', 'png', 'bmp', 'gif'];
      let isImage = false;
      suffixList.forEach((s) => {
        if (url.includes(s)) isImage = true;
      });
      if (isImage) return true;
    },
    async getIsImage(fileName) {
      return await this.isUrlImage(fileName);
    },
    getIsAcceptedImage(type) {
      let isOk = false;
      const typeList = this.acceptedImageTypes.split(', ');
      typeList.forEach((t) => {
        if (type.includes(t)) isOk = true;
      });
      return isOk;
    },
    getIsAcceptedMedia(type) {
      let isOk = false;
      const typeList = this.acceptedMediaTypes.split(', ');
      typeList.forEach((t) => {
        if (type.includes(t)) isOk = true;
      });
      return isOk;
    },
  },
};
</script>

<style scoped scss>
.warning-border {
  border-left: var(--v-warning-base) solid 5px;
}

.video-player {
  max-width: 100%;
}
</style>

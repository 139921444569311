<template>
  <div class="fill-height">
    <v-card outlined class="fill-height pa-4">
      <h2 class="text-h6">Models</h2>
      <v-divider class="my-2" />
      <div v-if="k1Model" class="text-body-2 mb-2">
        <div class="moin-label moin-col-label">K1:</div>
        <div class="moin-col-data">{{ k1Model.model_id }}</div>
      </div>
      <div v-if="wisdomModel" class="text-body-2 mb-2">
        <div class="moin-label moin-col-label">Wisdom:</div>
        <div class="moin-col-data">{{ wisdomModel.model_id }}</div>
      </div>
      <div v-if="nlpTemplate" class="text-body-2 mb-2">
        <div class="moin-label moin-col-label">Template:</div>
        <div class="moin-col-data">
          {{ nlpTemplate }}
        </div>
      </div>
    </v-card>
  </div>
</template>
<script>
import ProductService from '@/services/product';
import { mapGetters } from 'vuex';

export default {
  name: 'QmDashboardModel',
  data() {
    return {
      k1Model: null,
      wisdomModel: null,
      nlpTemplate: null,
    };
  },
  computed: {
    ...mapGetters('bots', ['currentBot', 'currentBotId', 'getBotSettings']),
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      const { models } = await ProductService.getNLPModels(this.currentBotId);

      this.resetData();

      this.k1Model =
        models.find((m) => m.model_type === 'k1' && m.isLiveModel) || null;

      this.wisdomModel =
        models.find((m) => m.model_type === 'wisdom' && m.isLiveModel) || null;


      this.nlpTemplate = this.currentBot?.nlpTemplate?.displayName || null;
    },
    resetData() {
      this.k1Model = null;
      this.wisdomModel = null;
      this.nlpTemplate = null;
    },
  },
};
</script>
<style scoped>
.moin-label {
  color: var(--v-primary-lighten3);
}
.moin-col-label {
  display: inline-block;
  width: 100px;
}
.moin-col-data {
  display: inline-block;
  width: calc(100% - 100px);
}
</style>

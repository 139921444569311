export const statsTypes = {
  conversation: 'conversation',
  takeovers: 'takeovers',
  automation: 'automation',
  ratings: 'ratings',
  answers: 'answers',
  conversions: 'conversions',
  intents: 'intents',
  anualReview: 'anualReview',
};

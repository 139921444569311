<template>
  <div
    class="action-text-input pt-1"
    :class="[
      typeInput && 'action-text-input--is-input',
      white && 'action-text-input-white',
    ]"
  >
  <label class="ml-3 mt-1 action-text-label" v-html="label"></label>
  <div class="input-wrapper">
      <v-text-field
				:readonly="readOnly"
        v-model="input"
        :rules="validationRules"
        @input="$emit('input', input)"
        outlined
        dense
        style="height: 40px;"
        :type="number ? 'number' : 'text'"
        autofocus
      ></v-text-field>
      <div class="mb-2 mr-1">
        <slot name="action-buttons">
        </slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ActionTextInput',
  props: {
    // can be a string or a number
    value: {
      type: [String, Number],
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    typeInput: {
      type: Boolean,
      default: false,
    },
    validationRules: {
      type: Array,
    },
    validationMessages: {
      type: Array,
    },
    white: {
      type: Boolean,
      default: false,
    },
    number: {
      type: Boolean,
      default: false,
    },
		readOnly: {
			type: Boolean,
			default: false,
		},
    autofocus: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      input: this.value,
    };
  },
  watch: {
    value() {
      this.input = this.value;
    },
  },
};
</script>
<style scoped lang="scss">
.action-text-input {
  background: $moin-color-grey-lightest;
  border: 1px solid $moin-color-grey-light;
  border-radius: 4px;
}

.action-text-input-white {
  background: white !important;
}

.action-text-input--is-input {
  border-color: var(--v-primary-lighten4) !important;
}

.action-text-input--is-input::v-deep {
  .v-label {
    color: var(--v-primary-lighten4) !important;
  }
}

.action-text-label {
  font-size: 12px;
}
.action-text-input::v-deep {
  fieldset {
    border-color: transparent;
  }

  .v-text-field--outlined .v-label {
    top: 6px;
  }

  .v-text-field--outlined .v-label--active {
    top: 10px;
  }

  .v-messages__wrapper {
    padding-top: 4px;
  }

  .input-wrapper {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }
}
</style>

import ApiService from './api';

const UploadService = {
  async uploadFile(file) {
    try {
      let formData = new FormData();
      formData.append('file', file);

      const response = await ApiService.postData(`/files/message`, formData);
      return response?.data;
    } catch (error) {
      throw new Error(error);
    }
  },
};

export default UploadService;

export { UploadService };

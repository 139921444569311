<template>
  <div :style="{ minHeight: hideCompare ? '80px' : '110px' }">
    <div
      v-if="title"
      class="primary--text pb-0 text-center"
      :class="[titleHeading ? 'text-h6' : 'text-body-1']"
    >
      {{ title }}
    </div>

    <div style="height: 48px">
      <div v-if="loading" class="d-flex justify-center pt-3">
        <v-skeleton-loader type="image" height="25" width="48" />
      </div>
      <div v-else class="d-flex justify-center pt-1">
        <v-icon v-if="!noIcon" color="primary lighten-4" size="32" left>{{ icon }}</v-icon>
        <div v-if="noData" :class="`pt-1 grey--text text--darken-1 ${noIcon ? 'text-h1' : 'text-h4'}`">0</div>
        <template v-else-if="valueString">
          <div :class="`text-truncate pt-1 text-center ${noIcon ? 'text-h2' : 'text-h4'}`">{{ valueString }}</div>
        </template>
        <template v-else>
          <div v-if="isPercent" :class="`pt-1 ${noIcon ? 'text-h1' : 'text-h4'}`">
            {{ formatNumberFixed(newValue) }}
            <span>%</span>
          </div>
          <div v-else :class="`pt-1 ${noIcon ? 'text-h1' : 'text-h4'}`">
            {{ newValue }}
          </div>
        </template>
      </div>
    </div>
    <div class="d-flex justify-center pt-1" v-if="loading">
      <v-skeleton-loader type="image" height="15" width="30" />
    </div>
    <div v-else-if="!hideCompare" class="relative" style="height: 30px">
      <div v-if="noData" class="text-body-2 grey--text text-center">
        {{ $t('stats.iconNumberTeaser.noData') }}
      </div>

      <v-tooltip v-if="!noData && !compareDisabled" bottom>
        <template v-slot:activator="{ on: onTooltip, attrs: attrsTooltip }">
          <div
            class="d-flex justify-center"
            v-on="onTooltip"
            v-bind="attrsTooltip"
          >
            <v-icon v-if="newValue < oldValue" size="20" :color="colorUp">
              arrow_downward
            </v-icon>
            <v-icon
              v-if="newValue === oldValue"
              size="20"
              color="primary lighten-2"
            >
              arrow_forward
            </v-icon>
            <v-icon v-if="newValue > oldValue" size="20" :color="colorDown">
              arrow_upward
            </v-icon>
            <span class="primary--text">
              {{ changedPercent }}
            </span>
          </div>
        </template>
        <span>{{ changedToolTip }}</span>
      </v-tooltip>
    </div>
  </div>
</template>
<script>
import NumberTeaserUtil from '@/utils/numberTeaser';
import { formatNumberFixed } from '@/utils/formatNumber';

export default {
  name: 'IconNumberTeaser',
  data() {
    return {
      formatNumberFixed
    }
  },
  props: {
    title: {
      type: String,
    },
    titleHeading: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: 'speed',
    },
    newValue: {
      type: Number,
      default: 0,
    },
    oldValue: {
      type: Number,
      default: 0,
    },
    valueString: {
      type: String,
    },
    isPercent: {
      type: Boolean,
      default: false,
    },
    lessIsBetter: {
      type: Boolean,
      default: false,
    },
    isNeutral: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: true,
    },
    noData: {
      type: Boolean,
      default: false,
    },
    compareDisabled: {
      type: String,
    },
    hideCompare: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    noIcon() { return this.icon === 'no-icon' },
    changedPercent() {
      return NumberTeaserUtil.getChangedPercent({
        oldValue: this.oldValue,
        newValue: this.newValue,
        isPercent: this.isPercent,
      });
    },
    changedToolTip() {
      const {
        change,
        changeString,
        beforeString,
      } = NumberTeaserUtil.getChangedStrings({
        oldValue: this.oldValue,
        newValue: this.newValue,
        isPercent: this.isPercent,
      });

      if (change > 0) {
        return this.$t('stats.iconNumberTeaser.tooltipMore', {
          change: changeString,
          before: beforeString,
        });
      } else {
        return this.$t('stats.iconNumberTeaser.tooltipLess', {
          change: changeString,
          before: beforeString,
        });
      }
    },
    colorUp() {
      if (this.isNeutral) return 'grey lighten-1';
      return this.lessIsBetter ? 'green lighten-1' : 'red lighten-1';
    },
    colorDown() {
      if (this.isNeutral) return 'grey lighten-1';
      return this.lessIsBetter ? 'red lighten-1' : 'green lighten-1';
    },
  },
};
</script>

<template>
  <v-row>
    <v-col
      cols="12"
      md="6"
      class="text-center align-self-center hidden-sm-and-down"
    >
      <WidgetPrivacyPreview
        :startScreen="startScreen"
        :bgColor="bgColor"
        :widgetTitle="widgetTitle"
        :headerContrast="headerContrast"
        :avatarUrl="avatar"
      />
    </v-col>
    <v-col cols="12" md="6">
      <v-card-title>
        {{ $t('editor.widget.startscreen.title') }}
      </v-card-title>
      <v-row>
        <v-col>
          <div class="px-4 mb-4">
            <v-switch
              :input-value="startScreen"
              :color="startScreen ? 'green' : 'grey'"
              inset
              :label="$t('editor.widget.startscreen.switchLabel')"
              @change="setStartScreen($event)"
            />
          </div>
          <div v-if="startScreen" class="px-4">
            <v-text-field
              outlined
              :label="$t('editor.widget.startscreen.greetingLabel')"
              v-model="startScreen.greeting"
              @input="update()"
            />

            <v-textarea
              outlined
              :label="$t('editor.widget.startscreen.textLabel')"
              v-model="startScreen.text"
              @input="update()"
            ></v-textarea>

            <v-card-text class="pl-0">
              {{ $t('editor.widget.startscreen.linksLabel') }}
            </v-card-text>

            <WidgetPrivacyLinks
              class="mb-8"
              v-model="startScreen.links"
              :loading="isLoading"
              @input="update()"
            />
            <v-text-field
              outlined
              :label="'Button Label'"
              v-model="startScreen.buttonLabel"
              @input="update()"
            />
          </div>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
import WidgetPrivacyPreview from './WidgetPrivacyPreview';
import WidgetPrivacyLinks from './WidgetPrivacyLinks';

const emptyStartScreen = {
  greeting: 'Hi',
  text: '',
  links: [],
  buttonLabel: 'OK',
};

export default {
  name: 'WidgetPrivacy',
  components: {
    WidgetPrivacyPreview,
    WidgetPrivacyLinks,
  },
  props: {
    avatar: {
      required: true,
    },
    bgColor: {
      required: true,
    },
    widgetTitle: {
      required: true,
    },
    headerContrast: {
      required: true,
    },
  },
  data() {
    return {
      startScreen: null,
      startScreenLocal: null,
    };
  },
  created() {
    this.startScreen = this.gotConfig.startscreen;
  },
  computed: {
    ...mapGetters('channelsettings', [
      'gotConfig',
      'getThemeColors',
      'getDirty',
      'isLoading',
    ]),
  },
  methods: {
    setStartScreen(activated) {
      if (activated) {
        this.startScreen = this.startScreenLocal
          ? this.startScreenLocal
          : emptyStartScreen;
        this.update();
      } else {
        this.startScreenLocal = { ...this.startScreen };
        this.startScreen = null;
        this.update();
      }
    },
    update: _.debounce(function() {
      this.$store.commit(
        'channelsettings/setSettingStartScreen',
        this.startScreen
      );
    }, 1000),
  },
};
</script>

<template>
  <v-form>
    <v-container>
      <v-row>
        <v-col>
          <h1 class="text-h4 mb-4">
            {{ $t('login.completeInvite.title') }}
          </h1>
          <p class="text-h6">
            {{ $t('login.completeInvite.signInWith') }}
            <strong>{{ $route.params.username }}</strong>
          </p>
        </v-col>
      </v-row>
      <v-row >
        <v-col cols="12">
          <SSOLoginButton
            :provider="$route.params.botinvite"
            :invite="$route.params.token"
            :email-hint="$route.params.username"
            class="full-width"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import SSOLoginButton from '@/components/common/SSOLoginButton.vue';

export default {
  name: 'CompleteSSOBotInvite',
  components: {
    SSOLoginButton,
  },
};
</script>

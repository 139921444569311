<template>
  <v-fade-transition>
    <v-card outlined>
      <v-row class="pa-4">
        <v-col>
          <div class="pt-2 text-center">
            <v-avatar color="primary lighten-1">
              <v-icon color="white">check</v-icon>
            </v-avatar>
          </div>
          <div class="text-h6 text-center mt-4">
            {{ $t('intentNew.intentCreated.title') }}
          </div>
          <div
            class="text-body-2 primary--text text--lighten-4 text-center mt-4"
          >
            {{ $t('intentNew.intentCreated.body') }}
          </div>
        </v-col>
      </v-row>
      <v-row class="pa-4">
        <v-col class="text-right">
          <v-btn
            outlined
            color="primary lighten-2"
            block
            class="primary--text"
            @click="closeSidebar()"
          >
            {{ $t('common.close') }}
            <v-icon right>close</v-icon>
          </v-btn>
        </v-col>
        <v-col>
          <v-btn
            class="white--text"
            block
            depressed
            color="secondary darken-2"
            @click="goToIntent()"
          >
            {{ $t('intentNew.intentCreated.btnPrimaryLabel') }}
            <v-icon right>mdi-folder-text</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-fade-transition>
</template>

<script>
import { mapGetters } from 'vuex';
import { TrackingService } from '@/services/tracking';

export default {
  name: 'IntentNewCreated',
  computed: {
    ...mapGetters('bots', ['currentBotId']),
    ...mapGetters('intentNew', ['createdIntent']),
  },
  methods: {
    goToIntent() {
      this.trackIt('created--on-edit');
      // navigate to intent
      this.$store.commit('intentNew/clearSearch');
      this.$router.push({
        name: 'intentsedit',
        params: { name: this.createdIntent },
      });
    },
    closeSidebar() {
      this.trackIt('created--on-close');
      this.$store.commit('intentNew/clearSearch');
    },
    trackIt(type = 'created--undefined') {
      TrackingService.trackNewIntent({
        botId: this.currentBotId,
        type,
        source: 'IntentNewCreated',
        payload: {
          createdIntent: this.createdIntent,
        },
      });
    },
  },
};
</script>

<template>
  <v-app style="max-height:100vh;height:100vh">
    <HubMenu />

    <Appbar></Appbar>

    <v-main class="fill-height">
      <v-row dense class="fill-height">
        <v-col
          lg="3"
          md="4"
          cols="12"
          class="fill-height flex-column"
          :class="{ 'd-none': chatVisible, 'd-md-flex': chatVisible }"
        >
          <v-list two-line class="overflow-y-auto">
            <v-subheader class="text-h6">{{$t('conversation.title')}}</v-subheader>
            <template>
              <v-divider></v-divider>
              <v-list-item
                active-class="accent--text"
                v-for="item in filteredDialogs"
                :key="item.uniqueUserId"
                :to="chatRoute(item.uniqueUserId)"
              >
                <v-list-item-avatar color="primary">
                  <span class="white--text text-h5">{{ firstLetter(item.uniqueUserId) }}</span>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    <i>{{ computeTitle(item) }}</i>
                  </v-list-item-title>
                  <v-list-item-subtitle>{{lastMessage(item)}}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-list-item-action-text>{{formatDate(item.userLastInput)}}</v-list-item-action-text>
                  <v-icon :color="online(item)" x-small>fiber_manual_record</v-icon>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-list>
        </v-col>
        <router-view />
      </v-row>
    </v-main>
  </v-app>
</template>


<script>
// @ is an alias to /src
import HubMenu from '@/components/hub/HubMenu.vue';
import Appbar from '@/components/Appbar.vue';

import { mapGetters } from 'vuex';
import NumbersUtil from '../utils/numbers';

export default {
  name: 'Chats',
  components: {
    HubMenu,
    Appbar,
  },
  created() {
    if (this.currentBot && this.currentBot.uniqueBotId) {
      this.refresh();
    }
    this.refreshInterval = setInterval(() => {
      this.refresh();
    }, 5000);
  },
  computed: {
    ...mapGetters('bots', [
      // create properties from auth store
      'currentBot',
      'viewableBotId',
      'currentChannels',
      'areChannelsBlocked',
    ]),
    ...mapGetters('dialogs', [
      // create properties from auth store
      'dialogs',
    ]),
    filteredDialogs() {
      if (!this.dialogs) {
        return [];
      }

      if (this.areChannelsBlocked) {
        const filteredIds = this.currentChannels
          .filter((channel) => channel.blocked)
          .map((channel) => channel.channelId);
        let dialogs = this.dialogs.filter(
          (dialog) => !filteredIds.includes(dialog.bot.channelId)
        );
        return dialogs;
      }

      return this.dialogs;
    },
    chatVisible() {
      return this.$route.params && this.$route.params.uuid;
    },
  },
  data() {
    return {
      fab: false,
      refreshInterval: null,
    };
  },
  beforeDestroy() {
    clearInterval(this.refreshInterval);
  },
  methods: {
    formatDate(value) {
      return NumbersUtil.formatDate(this, value);
    },
    refresh() {
      this.$store.dispatch('dialogs/get', this.viewableBotId);
    },
    online(item) {
      if (item && item.status && item.status.value === 'normal') {
        return 'success';
      } else {
        return 'error';
      }
    },
    chatRoute(id) {
      return '/chats/messaging/' + id;
    },
    firstLetter(title) {
      return title.charAt(0);
    },
    formatChatTime(s) {
      return new Date(s).toLocaleDateString();
    },
    computeTitle(item) {
      if (
        item.currentMessage &&
        item.currentMessage.data &&
        item.currentMessage.data.message &&
        item.currentMessage.data.message.text
      ) {
        return `"${item.currentMessage.data.message.text}"`;
      } else {
        return item.uniqueUserId;
      }
    },
    lastMessage(item) {
      if (item.context) {
        let ctx = item.context.find((ctx) => {
          return ctx.name === 'widget_host';
        });
        if (ctx) {
          return ctx.parameters;
        } else {
          return '';
        }
      } else {
        return '';
      }
    },
    randomAvatarColor() {
      return 'blue';
    },
    chatStatusColor() {
      return 'success';
    },
    onScroll(e) {
      if (e.srcElement.scrollTop > 20) {
        this.fab = true;
      } else {
        this.fab = false;
      }
    },
    toTop() {
      this.$refs.scrollContainer.$el.scrollTop = 0;
    },
  },
  watch: {
    '$route.params.uuid': function () {
      if (!this.$route.params.uuid) {
        this.$store.dispatch('dialogs/clearChat');
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>

import { ChangelogService } from '@/services';

const state = {
  changelogs: [], // possible keys: intent
  isContentSaved: false,
  loading: false,
};

const getters = {
  changelogs: (state) => state.changelogs,
  changelogListSorted: (state) => {
    if (!state.changelogs) return [];

    return state.changelogs
      .sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
  },
};

const actions = {
  /**
   * Set the saved status in the changelog store.
   *
   * @param {Object} context - The Vuex action context.
   * @param {Object} context.commit - A function to commit mutations in the store.
   * @param {Object} context.dispatch - A function to dispatch other actions in the store.
   * @param {Object} context.getters - An object containing module-specific getters.
   * @param {Object} context.rootGetters - An object containing getters available in the root store.
   * @param {Object} context.rootState - The state of the root store.
   * @param {Object} context.state - The local state of the current module.
   * @param {boolean} isSaved - The flag to indicate whether content is saved or not.
   * @returns {void}
   */
  setSavedStatus(context, isSaved) {
    const { commit } = context;
    commit('set', { key: 'isContentSaved', value: isSaved });
  },
  /**
   * Save the changelog.
   * @param context
   * @param changelog
   * @returns {Promise<ChangelogEntity[]|{error: *}>}
   */
  save(context, changelog) {
    return ChangelogService.createChangelog(changelog);
  },
  async fetchChangelogs({ state, commit }, params) {
    const { changelogType, ...rest } = params;
    const changelogs = await ChangelogService.searchChangelogByParams(rest);
    commit('set', { key: 'changelogs', value: changelogs || [] });
    return state.changelogs;
  },
};

const mutations = {
  set(state, { key, value }) {
    state[key] = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

<template>
  <div class="file-card px-1" :class="[border && 'file-card--border']">
    <div class="d-flex items-center">
      <div class="flex-grow-1">
        <div class="file-card-title d-flex">
          <v-icon small color="primary lighten-4" class="mr-1">
            {{ icon }}
          </v-icon>
          <span class="text-body-2 grey--text text--darken-2">
            {{ $t(`common.fileCard.title.${this.type}`) }}
          </span>
          <span class="text-body-2 grey--text">&nbsp;({{ fileSize }} KB)</span>
        </div>
        <div class="file-card-body pr-1">
          {{ text }}
        </div>
      </div>
      <v-btn
        text
        class="pa-0 ml-1"
        style="min-width: 26px"
        color="primary lighten-4"
        @click="handleDownload"
      >
        <v-icon small>mdi-download</v-icon>
      </v-btn>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import axios from 'axios';

export const fileCardTypes = {
  attachment: 'attachment',
  image: 'image',
};

export default {
  name: 'FileCard',
  props: {
    // Object {size, mime, originalFilename, generatedFilename, secret, date}
    item: {
      type: Object,
      required: true,
    },
    border: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return { fileCardTypes };
  },
  computed: {
    ...mapGetters('bots', ['viewableBotId']),
    type() {
      switch (this.item.mime) {
        case 'image/jpeg':
        case 'image/jpg':
        case 'image/png':
        case 'image/gif':
          return fileCardTypes.image;
        default:
          return fileCardTypes.attachment;
      }
    },
    icon() {
      switch (this.type) {
        case fileCardTypes.image:
          return 'mdi-image';
        default:
          return 'mdi-paperclip';
      }
    },
    text() {
      if (this.item.originalFilename.length > 30) {
        let newText = this.item.originalFilename.substring(0, 20);
        newText += '...';
        newText += this.item.originalFilename.substring(
          this.item.originalFilename.length - 10,
          this.item.originalFilename.length
        );
        return newText;
      }
      return this.item.originalFilename;
    },
    fileSize() {
      const size = this.item.size / 1024;
      return size.toFixed(0);
    },
  },
  methods: {
    handleDownload() {
      let botId = this.viewableBotId;
      axios
        .get(
          `/files/download/${botId}/${this.item.secret}/${this.item.generatedFilename}/${this.item.originalFilename}`,
          { responseType: 'blob' }
        )
        .then((response) => {
          const blob = new Blob([response.data], { type: this.item.mime });
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = this.item.originalFilename;
          link.click();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.file-card {
  display: inline-block;
  background: white;
  border-radius: $spacer;
}
.file-card--border {
  border: 1px solid rgb(230, 230, 230);
}
.file-card-title {
  position: relative;
  top: 2px;
  height: $spacer * 5;
}
.file-card-body {
  position: relative;
  top: -2px;
  height: $spacer * 6;
}
</style>

import { calcPercentage } from './calc';

export function parseSteps({started, steps}, formSteps) {
  const parsedSteps = [];
  let lastBufferStarted = 100;

  steps.forEach((step) => {
    const formStep = formSteps.find((s) => s.box === step.name);

    const parsedStep = {
      started: step.started,
      finished: step.finished,
      finishedUsers: step.finishedUsers,
      finishedPercentage: calcPercentage(step.finished, started),
      bufferPercentage: lastBufferStarted,
      box: step.name,
      displayName: formStep.displayName,
      template: formStep.template,
    };
    
    lastBufferStarted = calcPercentage(step.finished, started);
    
    if (step.values?.length) {
      parsedStep.values = step.values.map((v) => ({
        intent: v.value,
        chosenPercentage: calcPercentage(v.chosen, step.finished),
        chosen: v.chosen,
      }));
    }
    parsedSteps.push(parsedStep);
  });
  return parsedSteps;
}

const TOKEN_KEY = 'botcast_mobile_token';

/**
 * Manage the how Access Tokens are being stored and retreived from storage.
 *
 * Current implementation stores to localStorage. Local Storage should always be
 * accessed through this instace.
**/
const TokenService = {
    getToken() {
        return localStorage.getItem(TOKEN_KEY)
    },
    getSSONonce(provider) {
      const token = localStorage.getItem(`sso_${provider}_nonce`);
      if (!token) return { nonce: null, extraData: [] };
      const [nonce, ...extraData] = token.split('::');
      return { nonce, extraData };
    },

    saveToken(accessToken) {
        localStorage.setItem(TOKEN_KEY, accessToken)
    },
    saveSSONonce(provider, nonce, extraData=[]) {
        if (extraData) nonce += `::${extraData.join('::')}`;
        localStorage.setItem(`sso_${provider}_nonce`, nonce);
    },

    removeToken() {
        localStorage.removeItem(TOKEN_KEY)
    },
    removeSSONonce(provider) {
        localStorage.removeItem(`sso_${provider}_nonce`);
    }
}

export { TokenService }
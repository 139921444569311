<template>
  <div class="pulse-boeble"></div>
</template>
<script>
export default {
  name: 'PulseBoeble',
  components: {},
};
</script>
<style lang="scss" scoped>
.pulse-boeble {
  position: relative;
  width: $spacer * 3;
  height: $spacer * 3;
  border-radius: 50%;
  background: $moin-color-indicator-new;
}

@keyframes pulse {
  0% {
    transform: scale(0.5);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.pulse-boeble::after {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  margin-left: $spacer * -1.5;
  margin-top: $spacer * -1.5;
  width: $spacer * 6;
  height: $spacer * 6;
  background: $moin-color-indicator-new;
  opacity: 0.5;
  border-radius: 50%;
  animation: pulse 1s infinite;
}
</style>

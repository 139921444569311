<template>
  <v-tab-item transition="none" color="black" reverse-transition="none" value="infos" style="height: 100%">
    <v-card v-if="getChannelID" class="px-8 pt-4 pb-4" color="#f0f0f0" elevation="0" height="100%">
      <h2>{{$t('channelSettings.config.infos.headline')}}</h2>
      <p style="word-break: break-all;">
        Channel: {{getChannelID}}
      </p>
      <p>
        {{$t('channelSettings.config.infos.description')}}
      </p>
      <v-card>
        <v-card-actions class="d-flex">
          Embedd Code:
            <v-btn
              class="ml-2"
              outlined
              small 
              @click="copyCodeToClipboard">
            Copy Code
            <v-icon>mdi-code-tags</v-icon>
          </v-btn>
        </v-card-actions>
        <code class="mt-2 pa-4 elevation-0" style="word-break: break-all;">{{code(getBotId, getChannelID)}}</code>
      </v-card>
    </v-card>
  </v-tab-item>
</template>

<script>
import { createHelpers } from 'vuex-map-fields';
import { mapGetters } from 'vuex'

const { mapFields } = createHelpers({
  getterType: 'channelsettings/getField',
  mutationType: 'channelsettings/updateField',
});

export default {
  computed: {
    colorKeys() {
      return Object.keys(this.themecolors);
    },
    getBotId() {
      return this.$route.params.botId;
    },
    getChannelID() {
      return this.$route.params.channelid;
    },
    ...mapGetters('bots', [
        'currentBot', 'currentChannels'
    ]),
    ...mapGetters('channelsettings', ['getThemeColors']),
    ...mapGetters('bots', ['getCurrentChannelConfig']),
    ...mapFields({
      themetype: 'appeareance.themetype',
      widgetbutton: 'appeareance.button',
      widgetheader: 'appeareance.widgetheader',
      usermessage: 'appeareance.usermessage',
      themecolors: 'appeareance.themecolors',
    })
  },
  methods: {
    code(botId, channelId) {
      let script = 'script';
      const snippet = `<${script} type="text/javascript" id="moinloader" src="https://widget.moin.ai/moin-loader.js?id=${botId}&channelid=${channelId}"></${script}>`;
      return snippet;
    },
    copyCodeToClipboard() {
      const snippetcode = this.code(this.getBotId, this.getChannelID)
      window.navigator.clipboard.writeText(snippetcode).then(function() {
        console.log('Async: Copying to clipboard was successful!');
      }, function(err) {
        console.error('Async: Could not copy text: ', err);
      });
    },
  },
};
</script>
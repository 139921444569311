<template>
  <div class="moin-fresh">
    <v-divider class="mb-4" />
    <v-row>
      <v-col cols="12" sm="6" md="8">
        <v-card-title class="pa-0 primary--text text--lighten-2">
          <v-icon color="primary lighten-2" left>speed</v-icon>
          {{ $t('welcome.stats.title') }}
        </v-card-title>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-select
          class="mt-n4"
          :items="datePeriods"
          :value="statsDatePeriod"
          @change="onChangePeriod"
          item-value="value"
          item-text="label"
        >
          <template v-slot:prepend-inner>
            <v-icon color="primary lighten-4">mdi-calendar</v-icon>
          </template>
        </v-select>
      </v-col>
    </v-row>

    <!-- Stats for onboarding -->
    <v-row v-if="currentBotStage === stageTypes.ONBOARDING">
      <v-col cols="12" sm="4">
        <IconNumberTeaser
          :title="$t('welcome.stats.intentsCreated')"
          :newValue="10"
          :oldValue="8"
          :loading="loading"
          :isPercent="true"
          icon="source"
        />
      </v-col>
      <v-col cols="12" sm="4">
        <IconNumberTeaser
          :title="$t('welcome.stats.intentsEdited')"
          :newValue="89"
          :oldValue="87"
          :loading="loading"
          icon="edit"
        />
      </v-col>
      <v-col cols="12" sm="4">
        <IconNumberTeaser
          :title="$t('welcome.stats.conversationsTested')"
          :newValue="100"
          :oldValue="80"
          icon="forum"
          :loading="loading"
          isPercent
        />
      </v-col>
    </v-row>

    <!-- Stats for Live -->
    <v-row v-if="currentBotStage === stageTypes.LIVE">
      <v-col cols="12" sm="6" md="3">
        <IconNumberTeaser
          :title="$t('welcome.stats.conversations')"
          icon="chat"
          :newValue="StatsChartConversations.new"
          :oldValue="StatsChartConversations.old"
          :loading="StatsChartConversations.isLoading"
          :noData="StatsChartConversations.isFailed"
        />
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <IconNumberTeaser
          :title="$t('welcome.stats.automations')"
          icon="memory"
          :newValue="StatsChartAutomation.new"
          :oldValue="StatsChartAutomation.old"
          :loading="StatsChartAutomation.isLoading"
          :isPercent="true"
          :noData="StatsChartAutomation.isFailed"
        />
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <IconNumberTeaser
          :title="$t('welcome.stats.feedbackPositive')"
          icon="thumb_up"
          :newValue="StatsChartFeedbackPos.new"
          :oldValue="StatsChartFeedbackPos.old"
          :loading="StatsChartFeedbackPos.isLoading"
          :noData="StatsChartFeedbackPos.isFailed"
        />
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <IconNumberTeaser
          :title="$t('welcome.stats.feedbackNegative')"
          icon="thumb_down"
          :newValue="StatsChartFeedbackNeg.new"
          :oldValue="StatsChartFeedbackNeg.old"
          :loading="StatsChartFeedbackNeg.isLoading"
          :lessIsBetter="true"
          :noData="StatsChartFeedbackNeg.isFailed"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import IconNumberTeaser from '../stats/components/IconNumberTeaser.vue';
import { mapGetters } from 'vuex';
import { stageTypes } from '@/store/modules/welcome';

export default {
  components: { IconNumberTeaser },
  name: 'WelcomeStats',
  data() {
    return {
      loading: false,
      stageTypes,
    };
  },
  computed: {
    ...mapGetters('bots', [
      'currentBot',
      'viewableBotId',
      'currentBotStage',
      'currentChannels',
    ]),
    ...mapGetters('welcome', [
      'StatsChartFeedbackPos',
      'StatsChartFeedbackNeg',
      'StatsChartConversations',
      'StatsChartAutomation',
      'statsDatePeriod',
    ]),
    datePeriods: function() {
      return [
        { label: this.$t('welcome.datePeriods.months3'), value: 90 },
        { label: this.$t('welcome.datePeriods.weeks4'), value: 28 },
        { label: this.$t('welcome.datePeriods.week1'), value: 7 },
      ];
    },
  },
  methods: {
    onChangePeriod(periodDays) {
      this.$store.commit('welcome/setStatsLoading');
      this.$store.commit('welcome/setStatsDatePeriod', periodDays);
      this.fetchStats();
    },
    async fetchStats() {
      if (this.currentBotStage === stageTypes.ONBOARDING) {
        // TODO - currently no stats data from backend
      } else if (this.currentBotStage === stageTypes.LIVE) {
        await this.$store.dispatch('welcome/fetchStatsLive', {
          botId: this.viewableBotId,
        });
      }
    },
  },
  mounted() {
    this.$store.commit('welcome/setStatsLoading');
    this.fetchStats();
  },
};
</script>

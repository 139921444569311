<template>
  <div>
    <v-row>
      <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="12" md="6">
        <div
          class="
            mx-auto
            pt-10
            pa-4
            text-body-2
            grey--text
            text--darken-2 text-center
          "
          style="max-width: 300px"
        >
          {{ $t('editor.widget.integrationCode.subTitle') }}
        </div>
      </v-col>
      <v-col cols="12" md="6">
        <v-card-title>
          {{ $t('editor.widget.integrationCode.title') }}
        </v-card-title>
        <div class="mx-4">
          <v-btn outlined color="primary" @click="isDialogOpen = true">
            <span>{{ $t('editor.widget.integrationCode.btnGenerate') }}</span>
            <v-icon right>integration_instructions</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-divider />
      </v-col>
    </v-row>
    <HubDialog
      :title="$t('editor.widget.integrationCode.title')"
      v-model="isDialogOpen"
      :hideSecondary="true"
      :primaryLabel="$t('common.close')"
      primaryIcon="close"
    >
      <div class="">
        <SshPre language="js" copy-button @copied="codeCopied = 'check'">
          <template>{{ code }}</template>
          <template #copy-button>
            <v-icon small>{{ codeCopied }}</v-icon>
          </template>
        </SshPre>
      </div>
      <div class="pt-3">
        <div v-html="$t('editor.widget.integrationCode.dialogInfo')" />
        <div v-html="$t('editor.widget.integrationCode.dialogInfo2')" class="mb-n6" />
      </div>
    </HubDialog>
  </div>
</template>
<script>
import SshPre from 'simple-syntax-highlighter';
import 'simple-syntax-highlighter/dist/sshpre.css';
import HubDialog from '@/components/hub/HubDialog.vue';

export default {
  components: { SshPre, HubDialog },
  name: 'WidgetIntegrationCode',
  data() {
    return {
      isDialogOpen: false,
      codeCopied: 'content_copy',
    };
  },
  props: {
    botId: {
      type: String,
      required: true,
    },
    channelId: {
      type: String,
      required: true,
    },
  },
  computed: {
    code() {
      const channelId = this.channelId ? `&channelid=${this.channelId}` : '';

      const code = `<script type="text/javascript" id="moinloader" src="${process.env.VUE_APP_WIDGET_URL}/moin-loader.js?id=${this.botId}${channelId}"><\/script>`;

      return code;
    },
  },
};
</script>
<template>
  <div class="action-line d-flex justify-center">
    <div
      v-if="!hideLine"
      class="action-line-line"
      :class="[`action-line-line--${type}`]"
    ></div>
    <!-- LOADING -->
    <div
      v-if="
        blockType === blockTypes.CONTENT_LOADING ||
        blockType === blockTypes.START_LOADING ||
        blockType === blockTypes.END_LOADING
      "
      class="action-line-btn action-line-icon-loader"
    >
      <v-skeleton-loader
        class="relative"
        type="image"
        :width="28"
        :height="28"
      />
    </div>
    <div
      v-else-if="blockType === blockTypes.DELAY"
      class="action-line-delay action-line-btn--content d-flex justify-center items-center mt-2"
    >
      <v-icon small color="primary">mdi-progress-clock</v-icon>
    </div>
    <!-- <div
      v-else-if="blockType === blockTypes.VARIABLE"
      class="action-line-delay action-line-btn--content d-flex justify-center items-center mt-2"
    >
      <v-icon small color="primary">code</v-icon>
    </div> -->
    <DropDown v-else :items="menuItems" :disabled="!isClickable" :width="180" auto-close>
      <template #actuator="{ toggle }">
        <v-hover v-slot:default="{ hover }">
          <v-btn
            @click="toggle"
            small
            class="asd action-line-btn handle-action-list"
            :class="{
              'no-top-margin': options?.readMode,
              [draggableClass]: true,
              [`action-line-btn--${type}`]: true,
              'action-line-btn--rotated': iconRotated,
              'moin-cursor-default': !isClickable,
            }"
            :color="hover ? hoverBtnColor : btnColor"
            :ripple="isClickable"
            icon
          >
            <v-icon small>
              {{ hover && isClickable ? hoverIcon : icon }}
            </v-icon>
          </v-btn>
        </v-hover>
      </template>
    </DropDown>
  </div>
</template>
<script>
import { blockTypes } from '@/components/editor/actionConsts';

import { useEventBus } from '@vueuse/core';
import { actionEditorEvents } from '@/utils/actionEditor';
import DropDown from '@/components/common/dropdown/DropDown.vue';
import { styleTypes } from '@/utils/notifications';

const lineTypes = {
  START: 'start',
  CONTENT: 'content',
  INPUT: 'input',
  SYSTEM: 'system',
  END: 'end',
  TREE: 'tree',
};

export default {
  name: 'ActionLine',
  components: {
    DropDown,
  },
  props: {
    blockType: String,
    draggableClass: String,
    index: Number,
    property: String,
    box: String,
    deletable: Boolean,
    options: Object,
    items: {
      type: Array,
      default: () => null,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    hideLine: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      blockTypes,
      actionDeletedEmitter: useEventBus(actionEditorEvents.actionDeleted),
      actionMovedEmitter: useEventBus(actionEditorEvents.actionMoved),
      contentCopiedEmitter: useEventBus(actionEditorEvents.contentCopied),
    };
  },
  computed: {
    isClickable() {
      // if clickable is set to true and showOnlyAction is set to false
      return this.clickable && !this.options.showOnlyAction;
    },
    type() {
      if (!this.blockType) return '';
      const [type] = this.blockType.split('_');
      return type || null;
    },
    menuItems() {
      let items = [
                {
            label: this.$t('common.moveUp'),   // Zum Beispiel: 'Element nach Oben bewegen'
            icon: 'move_up',             // Ein allgemein verständliches Symbol für "nach oben bewegen"
            onClick: () => this.moveUp(),
            disabled:  this.options?.moveableUpAllowed !== true,          // Das oberste Element kann nicht weiter nach oben verschoben werden
          },
          {
            label: this.$t('common.moveDown'), // Zum Beispiel: 'Element nach unten bewegen'
            icon: 'move_down',           // Ein allgemein verständliches Symbol für "nach unten bewegen"
            onClick: () => this.moveDown(),
            disabled: this.options?.moveableDownAllowed !== true // index === this.items.length - 1, // Das unterste Element kann nicht weiter nach unten verschoben werden
          },
                  {
          label: this.$t('common.copy'),
          icon: 'copy_all',
          onClick: () => {
            this.copy();
            this.$store.dispatch('notifications/addNotification', {
              name: 'editor.notifications.copiedRow',
              styleType: styleTypes.SUCCESS,
              messageString: this.$t("editor.notifications.copiedRow"),
              permanent: false,
            });
          },
        },
        {
          label: this.$t('common.delete'),
          icon: 'delete',
          onClick: () => this.delete(),
          disabled: !this.deletable,
        },
      ];

      if (Array.isArray(this.items)) {
        items = _.cloneDeep(this.items);
      }

      // if this component has the event onEdit assigned add edit item at first position of items
      if (this.options?.onEdit) {
        items.unshift({
          label: this.$t('common.edit'),
          icon: 'edit',
          onClick: () => this.$emit('onEdit'),
        });
      }

      return items;
    },
    btnColor() {
      switch (this.type) {
        case lineTypes.CONTENT:
          return '#BDBDBD';
        case lineTypes.INPUT:
          return 'primary lighten-2';
        case lineTypes.SYSTEM:
          return 'primary lighten-4';
        case lineTypes.END:
          return 'secondary';
        case lineTypes.TREE:
          return 'grey lighten-2';
        default:
          return 'white';
      }
    },
    hoverBtnColor() {
      return this.isClickable ? 'primary lighten-2' : this.btnColor;
    },
    isTemplateStart() {
      switch (this.blockType) {
        case blockTypes.START_SMALLTALKS:
          if (this.options?.channels?.length > 0)  {
            return true;
          }
      }

      return false;
    },
    icon() {
      switch (this.blockType) {
        case blockTypes.START_TAG_QUESTION:
          return 'mdi-check-circle';
        case blockTypes.START_YES_NO:
          return 'mdi-call-split';
        case blockTypes.START_FREE_TEXT:
          return 'mdi-message-processing-outline';
        case blockTypes.START_EMAIL:
          return 'mdi-email';
        case blockTypes.START_VALIDABLE:
          return 'mdi-message-check-outline';
        case blockTypes.START_FOLLOWUP:
          return 'mdi-share-circle';
        case blockTypes.START_MULTIPLE_CHOICE:
          return 'mdi-format-list-bulleted';
        case blockTypes.START_FALLBACK:
          return 'mdi-help';
        case blockTypes.START_CANCEL:
          return 'mdi-close-circle';
        case blockTypes.START_NEXT:
          return 'mdi-emoticon-happy-outline';
        case blockTypes.START_SERVICE:
          return 'mdi-emoticon-sad-outline';
        case blockTypes.START_SMALLTALKS:
          return 'coffee';
        case blockTypes.START_ANSWER:
          return 'mdi-comment-check';
        case blockTypes.START_LIVECHAT:
          return 'mdi-chat';
        case blockTypes.START_FORM:
          return 'mdi-file-document-edit-outline';
        case blockTypes.SYSTEM_WEBHOOK:
          return 'cable';
        case blockTypes.SYSTEM_NOTIFICATION:
          return 'email';
        case blockTypes.SYSTEM_VALIDABLE:
          return 'mdi-code-tags';
        case blockTypes.END_AHEAD:
        case blockTypes.TREE_PARENTS:
          return 'mdi-arrow-down';
        case blockTypes.END_TURN:
          return 'mdi-arrow-right-bottom';
        case blockTypes.END_GO_UP:
          return 'mdi-reload';
        case blockTypes.CONTENT_TEXT:
          return 'mdi-text';
        case blockTypes.CONTENT_TEXT_VARIANTS:
          return 'mdi-text';
        case blockTypes.CONTENT_MEDIA:
          return 'mdi-image';
        case blockTypes.CONTENT_SLIDES:
          return 'view_carousel';
        case blockTypes.SYSTEM_VARIABLE:
          return 'code';
        case lineTypes.INPUT:
          return 'mdi-help-circle';

        default:
          return 'mdi-checkbox-marked-circle-outline';
      }
    },
    hoverIcon() {
      return this.isClickable ? 'more_vert' : this.icon;
    },
    iconRotated() {
      return this.blockType === blockTypes.START_YES_NO;
    },
  },
  methods: {
    delete() {
      this.actionDeletedEmitter.emit({
        index: this.index,
        property: this.property,
        box: this.box,
      })
    },
    copy() {
      this.contentCopiedEmitter.emit({
        index: this.index,
        property: this.property,
        box: this.box,
        type: 'action'
      });
    },
    moveUp() {
      this.actionMovedEmitter.emit({
        index: this.index,
        property: this.property,
        box: this.box,
        direction: -1,
      });
    },
    moveDown() {
      this.actionMovedEmitter.emit({
        index: this.index,
        property: this.property,
        box: this.box,
        direction: 1,
      });
    },
  },
};
</script>
<style scoped lang="scss">
.action-line-btn.no-top-margin {
  margin-top: 0;
}
.action-line {
  position: relative;
  width: $spacer * 10;
  height: 100%;
}

.action-line-line {
  position: absolute;
  width: 1px;
  background: #e0e0e0;
  top: 0;
  left: 50%;
  bottom: 0;
}

.action-line-line--selected {
  background: var(--v-grey-darken1);
}

.action-line-line--start {
  top: $spacer * 4;
}

.action-line-line--end {
  height: $spacer * 4;
}

.action-line-btn {
  margin-top: $spacer;
}

.action-line-btn--start {
  background: var(--v-secondary-base);
}

.action-line-btn--content {
  background: $moin-color-grey-lightest;
  border: 1px solid $moin-color-grey-light;
}

.action-line-btn--input {
  background: #e7e9ed;
  border: 1px solid #e7e9ed;
}

.action-line-btn--system {
  background: white;
  border: 1px solid var(--v-primary-lighten4);
}

.action-line-btn--tree {
  background: white;
  border: 1px dashed $moin-color-grey-light;
  width: 24px;
  height: 24px;
}

.action-line-btn--end {
  background: white;
  border: 1px solid var(--v-secondary-base);
}

.action-line-btn--rotated {
  transform: rotate(180deg);
}

.action-line-btn--selected {
  border-color: var(--v-grey-darken1);
  background: var(--v-grey-darken1);
  color: white;

  .v-icon {
    color: white;
  }
}

.action-line-icon-loader {
  overflow: hidden;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: white;
  position: relative;
}

.action-line-delay {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  position: relative;
}
</style>

<template>
  <div v-if="content">
    <v-timeline-item hide-dot color="grey lighten-3" fill-dot v-if="content">
      <v-row>
        <v-col>
          <v-text-field
            outlined
            class="font-weight-medium ma-0"
            dense
            v-model="content.displayName"
            :label="$t('botFormEdit.whatIsAsked')"
          ></v-text-field>
        </v-col>
        <v-col>
          <div v-if="isAdmin" class="moin-admin-box pa-1">
            <v-text-field
              outlined
              v-model="content.context"
              dense
              :label="$t('botFormEdit.technicalName')"
              hide-details
            />
          </div>
        </v-col>
      </v-row>
    </v-timeline-item>

    <ActionList
      v-if="content"
      :actions="content.question.actions"
      @hook:mounted="updateName"
      @onUpdate="update"
      :channelType="channelType"
    ></ActionList>

    <v-timeline-item color="white">
      <template v-slot:icon>
        <v-icon small>account_tree</v-icon>
      </template>
      <v-chip-group>
        <v-chip
          :color="active(answer) ? 'primary' : ''"
          @click="select(answer)"
          v-for="(answer, index) of answers"
          :key="index"
        >
          {{ getLabel(answer.label).data }}
        </v-chip>
      </v-chip-group>
    </v-timeline-item>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ActionList from '@/components/ActionList.vue';
import ChannelsUtil from '@/utils/channels';
export default {
  name: 'BotFormYesNoEdit',
  components: {
    ActionList,
  },
  props: ['box', 'intent', 'channel', 'name'],
  data: () => ({
    tab: null,
  }),
  computed: {
    ...mapGetters('intents', ['intents']),
    ...mapGetters('bots', ['currentBotId', 'currentChannels']),
    ...mapGetters('auth', ['isAdmin']),
    content() {
      return this.$store.getters['content/getContent'](
        this.currentBotId,
        this.intent,
        this.box,
        true,
        this.channel
      );
    },
    answers() {
      let actions = this.content.question.actions.filter((a) => {
        return a.content;
      });

      return actions[actions.length - 1].content.quick;
    },
    selectedPath() {
      return this.$store.getters['formsEditor/getSelectedPath'](this.box);
    },
    channelType() {
      return ChannelsUtil.getChannelType(
        this.content?.channel,
        this.currentChannels
      );
    },
  },
  methods: {
    active(answer) {
      return this.selectedPath === answer.intent;
    },
    select(answer) {
      this.$store.commit('formsEditor/selectPath', {
        box: this.box,
        intent: answer.intent,
      });
      this.$store.dispatch(
        'formsEditor/updateRenderedSteps',
        this.currentBotId
      );
    },
    getLabel(name) {
      let actions = this.content.question.actions.filter((a) => {
        return a.content;
      });

      return actions[actions.length - 1].content.strings.find((str) => {
        return `{${str.name}}` === name;
      });
    },
    updateName() {
      if (this.content && !this.content.displayName) {
        this.content.displayName = this.name;
      }
    },
    update() {
      this.$emit('onUpdated');
    },
    tabChanged() {
      this.$emit('tabChanged', {
        intent: this.answers[this.tab].intent,
        box: this.box,
      });
    },
  },
  watch: {
    name() {
      this.content.displayName = this.name;
    },
  },
};
</script>

<template>
  <HubBox
    icon="list_alt"
    :title="$t('intents.fallbackKeywords.title')"
    :boxId="boxId"
    @onExpand="(isExpanded) => isExpanded && trackIt('hub-box-expanded')"
  >
    <div
      class="text-body-2 mb-8 pl-2 primary--text text--lighten-2"
      v-if="!keywords.length"
    >
      {{ $t('intents.fallbackKeywords.noKeywords') }}
    </div>
    <template v-else>
      <v-card
        v-for="(keyword, index) in keywords"
        :key="index"
        class="rounded-xl elevation-0 ma-1"
        color="d-inline-block grey lighten-3"
      >
        <v-card-text class="pa-1 px-3 primary--text">
          {{ keyword }}
        </v-card-text>
      </v-card>
    </template>
    <InfoBox class="my-4" :text="$t('intents.fallbackKeywords.infoBox')" />

    <div class="pt-5">
      <v-btn
        outlined
        color="primary lighten-3"
        @click="
          () => {
            editDialogOpen = true;
            trackIt('open-edit-dialog');
          }
        "
      >
        {{ $t('common.edit') }}
        <v-icon right>edit</v-icon>
      </v-btn>
    </div>
    <HubDialog
      :value="editDialogOpen"
      :primary-label="$t('common.save')"
      primary-icon="save"
      :secondary-label="$t('common.cancel')"
      secondary-icon="close"
      :title="$t('intents.fallbackKeywords.editTitle')"
      @onPrimary="saveKeywords()"
      @onSecondary="cancelEdit()"
      :disabled="!isChanged"
      modal
      asyncDialog
    >
      <div class="mb-8">
        <p class="text-body-1">
          {{ $t('intents.fallbackKeywords.desciptionOverlay') }}
        </p>
      </div>
      <div class="d-flex">
        <v-text-field
          class="mr-2"
          v-model="newKeyword"
          dense
          outlined
          :label="$t('intents.fallbackKeywords.newInputLabel')"
          :rules="validateRules ? validateRules : inputRules"
          @update:error="(v) => (isValid = !v)"
          @change="validateRules = null"
        />
        <v-btn
          height="40"
          color="primary lighten-2"
          depressed
          @click="addKeyword"
          :disabled="!isValid"
        >
          <v-icon>add</v-icon>
        </v-btn>
      </div>
      <div class="mb-4">
        <v-chip-group column>
          <v-chip
            class="moin-cursor-default"
            v-for="(keywordEdit, indexEdit) in keywordsDraft"
            :key="indexEdit"
            close
            close-icon="clear"
            @click:close="deleteKeyword(keywordEdit)"
            :ripple="false"
          >
            {{ keywordEdit }}
          </v-chip>
        </v-chip-group>
      </div>
      <InfoBox :text="$t('intents.fallbackKeywords.infoBoxOverlay')" />
    </HubDialog>
  </HubBox>
</template>
<script>
import deepEqual from 'fast-deep-equal/es6';
import KeywordService from '@/services/keywords';
import { TrackingService } from '@/services/tracking';
import HubBox from '@/components/hub/HubBox.vue';
import InfoBox from '@/components/common/InfoBox.vue';
import HubDialog from '@/components/hub/HubDialog.vue';

export default {
  name: 'IntentKeywords',
  components: {
    HubBox,
    InfoBox,
    HubDialog,
  },
  props: {
    intent: {
      type: Object,
      required: true,
    },
    botId: {
      type: String,
      required: true,
    },
    // Optional in case there are more IntentKeywords widgets used
    boxId: {
      type: String,
      default: 'intentKeywords',
    },
  },
  data() {
    return {
      editDialogOpen: false,
      newKeyword: '',
      keywords: [],
      keywordsDraft: [],
      validateRules: null,
      valMinChars: 2,
      valMaxChars: 50,
      valMaxWords: 3,
      valMaxKeywords: 10,
      isValid: true,
    };
  },
  created() {
    this.init();
  },
  computed: {
    inputRules() {
      const rules = [];

      const ruleNotSame = (v) =>
        (!this.keywords.includes(v) && !this.keywordsDraft.includes(v)) ||
        this.$t(`intents.fallbackKeywords.validation.existsSame`);
      rules.push(ruleNotSame);

      const tooManyKeywords = (v) =>
        !v.length ||
        (v.length && this.keywordsDraft.length < this.valMaxKeywords) ||
        this.$t(`intents.fallbackKeywords.validation.tooManyKeywords`, {
          maxKeywords: String(this.valMaxKeywords),
        });
      rules.push(tooManyKeywords);

      return rules;
    },
    isChanged() {
      return !deepEqual(this.keywords, this.keywordsDraft);
    },
  },
  methods: {
    init() {
      if (!this.intent?.keywords?.length) return;
      this.keywords = this.intent?.keywords;
      this.keywordsDraft = [...this.keywords];
    },
    async saveKeywords() {
      const response = await KeywordService.updateKeywords({
        botId: this.botId,
        intentName: this.intent.intent,
        keywords: this.keywordsDraft,
      });

      if (!response) {
        this.trackIt('keywords-update-error');
      }

      if (response?.status === 'OK') {
        this.keywords = [...response?.updateResult?.keywords];
        this.keywordsDraft = [...this.keywords];
        this.trackIt('keywords-saved', {
          keywords: this.keywords,
        });
      }
      this.editDialogOpen = false;
    },
    cancelEdit() {
      this.keywordsDraft = [...this.keywords];
      this.editDialogOpen = false;
    },
    async addKeyword() {
      // Check length
      if (this.keywordsDraft.length >= 10) {
        this.validateRules = [
          () =>
            this.$t(`intents.fallbackKeywords.validation.tooManyKeywords`, {
              maxKeywords: String(this.valMaxKeywords),
            }),
        ];
        return;
      }

      const response = await KeywordService.validateKeyword({
        intentName: this.intent.intent,
        botId: this.botId,
        keyword: this.newKeyword,
      });

      if (!response.valid || response.deficiencies?.length) {
        // Used for existsOther
        const otherIntent = response?.existingMatchIntent
          ? this.$store.getters['intents/displayName'](
              response?.existingMatchIntent
            )
          : '';

        // Show first invalid deficiency
        switch (response.deficiencies[0]) {
          case 'existsSame':
            this.validateRules = [
              () => this.$t(`intents.fallbackKeywords.validation.existsSame`),
            ];
            break;
          case 'existsOther':
            this.validateRules = [
              () =>
                this.$t(`intents.fallbackKeywords.validation.existsOther`, {
                  otherIntent,
                }),
            ];
            break;
          case 'tooLong':
            this.validateRules = [
              () =>
                this.$t(`intents.fallbackKeywords.validation.tooLong`, {
                  maxLength: String(this.valMaxChars),
                }),
            ];
            break;
          case 'tooShort':
            this.validateRules = [
              () =>
                this.$t(`intents.fallbackKeywords.validation.tooShort`, {
                  minLength: String(this.valMinChars),
                }),
            ];
            break;
          case 'tooManyWords':
            this.validateRules = [
              () =>
                this.$t(`intents.fallbackKeywords.validation.tooManyWords`, {
                  maxWords: String(this.valMaxWords),
                }),
            ];
            break;
        }
        return;
      }

      this.keywordsDraft.push(this.newKeyword);
      this.newKeyword = '';

      this.trackIt('keyword-added');
    },
    deleteKeyword(keyword) {
      const listIndex = this.keywordsDraft.findIndex((k) => k === keyword);
      this.keywordsDraft.splice(listIndex, 1);

      this.trackIt('keyword-deleted');
    },
    trackIt(type, payload) {
      TrackingService.trackKeywords({
        botId: this.botId,
        type,
        source: 'IntentKeywords',
        payload: payload || {
          keywordsDraft: this.keywordsDraft,
        },
      });
    },
  },
};
</script>
<template>
  <HubBox
    :title="$t('intents.types.feedback.label')"
    icon="mdi-thumbs-up-down"
    :boxId="boxId"
    @onExpand="(isExpanded) => isExpanded && trackIt('hub-box-expanded')"
  >
    <IntentStatistic
      class="mb-4"
      :progress-title="$t('intents.details.rating.title')"
      :chart-title="$t('intents.details.often.title')"
      :chart-subline="$t('intents.details.rating.subline')"
      :loading="loading"
      :channel="channel"
    >
      <template #progressBar>
        <v-progress-linear
          class="v-progress-linear--outer-value"
          :value="progressValue"
          buffer-value="100"
          :background-color="noData || loading ? null : 'error'"
          :color="noData || loading ? 'grey lighten-2' : 'success'"
          height="10"
        >
          <template v-slot:default="{ value }">
            <strong class="text-caption" :style="getProgressValueStyle(value)">
              {{ Math.ceil(value) }}%
            </strong>
          </template>
        </v-progress-linear>
      </template>

      <template #chart>
        <apexchart
          v-if="!noData && chart.series.length >= 2"
          type="bar"
          :options="chart.options"
          :series="chart.series"
          height="140px"
        />
        <div v-else-if="!loading && noData" class="d-flex justify-center">
          <v-card-subtitle>
            <v-icon small left>warning</v-icon>
            {{ $t('intents.details.often.nodata') }}
          </v-card-subtitle>
        </div>
      </template>
    </IntentStatistic>
  </HubBox>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import { getProgressValueStyle, baseBarChartOptions } from '@/utils/charts';
import { lastTwelveWeekDates } from '@/utils/numbers';
import { StatsService } from '@/services/stats';
import HubBox from '@/components/hub/HubBox.vue';
import { TrackingService } from '@/services/tracking';

import IntentStatistic from './IntentStatistic';

export default {
  name: 'IntentFeedback',
  components: {
    IntentStatistic,
    HubBox,
  },
  props: {
    intent: {
      type: String,
      required: true,
    },
    channel: {
      type: String,
    },
    botId: {
      type: String,
      required: true,
    },
    boxId: {
      type: String,
      default: 'IntentFeedback',
    },
  },
  data() {
    return {
      loading: false,
      noData: false,
      getProgressValueStyle,
      positiveString: '',
      negativeString: '',
      progressValue: 0,
      chart: {
        series: [],
        options: Object.assign(
          JSON.parse(JSON.stringify(baseBarChartOptions)),
          {
            colors: ['#00C28A', '#E45966'],
            tooltip: {
              enabled: true,
              x: {},
              y: {},
            },
            xaxis: {
              type: 'datetime',
              categories: lastTwelveWeekDates,
              labels: {
                rotateAlways: true,
                offsetY: 3,
              },
            },
            yaxis: {
              labels: {
                formatter: function(val) {
                  if (val === 0) return '';
                  return Math.round(val);
                },
              },
            },
          }
        ),
      },
    };
  },
  computed: {
    ...mapGetters('bots', ['currentBotId']),
  },
  created() {
    const calendarWeekStr = this.$t('common.calendarweek');
    const ratingStr = this.$t('intents.types.feedback.label');
    this.chart.options.tooltip.x.formatter = function(val) {
      return `${calendarWeekStr} ${moment(val).week()}`;
    };

    this.chart.options.tooltip.y.formatter = function(val) {
      return `${val} ${ratingStr}`;
    };
    this.chart.options.xaxis.labels.formatter = function(_, timestamp) {
      return `${calendarWeekStr} ${moment(timestamp).week()}`;
    };

    this.positiveString = this.$t('intents.types.feedback.positive.label');
    this.negativeString = this.$t('intents.types.feedback.negative.label');
  },
  beforeMount() {
    this.loading = true;
    StatsService.getIntentFeedback(this.botId, this.intent, this.channel).then(
      ({ pos, neg, history }) => {
        if (pos === 0 && neg === 0) {
          this.noData = true;
          this.loading = false;
          return;
        }
        const posArray = history.map(({ pos }) => pos);
        const negArray = history.map(({ neg }) => neg);

        this.chart.series.push({
          name: this.positiveString.value,
          data: posArray,
        });
        this.chart.series.push({
          name: this.negativeString.value,
          data: negArray,
        });
        this.chart.options.yaxis.tickAmount =
          Math.max(...posArray, ...negArray) === 1 ? 1 : 2;
        this.chart.options.yaxis.max = Math.max(...posArray, ...negArray);

        this.progressValue = (pos / (pos + neg)) * 100;
        this.loading = false;
      }
    );
  },
  methods: {
    trackIt(type) {
      TrackingService.trackIntentsView({
        botId: this.currentBotId,
        type,
        source: 'IntentFeedback',
      });
    },
  },
};
</script>

<template>
  <div class="fill-height">
    <v-card outlined class="fill-height pa-4">
      <h2 class="text-h6">{{ trainingData.length }} neue Varianten (letzten 30 Tagen)</h2>
      <v-divider class="my-2" />
      <v-data-table :headers="headers" :items="trainingData" sort-by="item.createdAt">
        <template v-slot:[`item.name`]="{ item }">
          {{displayName(item.name, 'de') }}
        </template>
        <template v-slot:[`item.createdBy.user`]="{ item }">
          {{shortenUser(item.createdBy) }}
        </template>
        <template v-slot:[`item.createdAt`]="{ item }">
          {{getDateFormated(item.createdAt) }}
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import moment from 'moment';

export default {
  name: 'QmDashboardListVariants',
  data() {
    return {
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Text', value: 'text' },
        { text: 'Von', value: 'createdBy.user' },
        { text: 'Am', value: 'createdAt' },
      ],
    };
  },
  computed: {
    ...mapGetters('qm', ['trainingData']),
    ...mapGetters('intents', ['displayName']),
  },
  methods: {
    getDateFormated(dateString) {
      const date = new Date(dateString);
      return moment(date).format('DD.MM.YY');
    },
    shortenUser(email) {
      if (!email || !email.user) {
        return 'no user set';
      }

      return email.user.replace('@moin.ai', '');
    }
  },
};
</script>

<template>
  <div>
    <v-data-table
      :value="[]"
      item-key="id"
      :headers="headers"
      :items="channels"
      @click:row="(item) => $emit('onSelect', item)"
      single-select
      hide-default-footer
    >
      <template v-slot:[`item.channel`]="{ item }">
        <v-icon left>{{ typeIcons[item.channel] }}</v-icon>
        {{ $t(`channels.${item.channel}`) }}
      </template>
      <template v-slot:[`item.languages`]="{ item }">
        <v-chip color="primary lighten-3" text-color="white" small>
          <v-icon left small>mdi-web</v-icon>
          {{ $t(`languages.${getLanguage(item)}`) }}
        </v-chip>
      </template>
      <template v-if="isAdmin && false" v-slot:[`item.action`]="{ item }">
        <BtnSquare
          v-if="isAdmin"
          class="mr-1"
          :to="`/settings/channels/${item.channelId}`"
          icon="edit"
          colorBtn="admin"
          colorIcon="admin"
        />
        <DropMenu :items="dropMenuItems" isDisabled>
          <BtnSquare
            v-if="isAdmin"
            icon="mdi-dots-horizontal"
            colorBtn="admin"
            colorIcon="admin"
            disabled
          />
        </DropMenu>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import BtnSquare from '@/components/common/BtnSquare.vue';
import DropMenu from '@/components/common/DropMenu.vue';
export default {
  name: 'ChannelsList',
  components: {
    BtnSquare,
    DropMenu,
  },
  data() {
    return {
      isDeleteDialogOpen: false,
      teaserToDelete: null,
      typeIcons: {
        widget: 'mdi-comment-processing-outline',
        facebook: 'mdi-facebook',
        userlike: 'mdi-comment-processing-outline',
        whatsapp: 'mdi-whatsapp',
      },
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...mapGetters('auth', ['isAdmin']),
    ...mapGetters('bots', ['currentBotId']),
    ...mapGetters('channels', ['channels']),
    headers() {
      return [
        {
          text: 'Channel',
          align: 'start',
          value: 'displayName',
        },
        {
          text: 'Type',
          align: 'start',
          value: 'channel',
        },
        {
          text: this.$t('common.language'),
          align: 'start',
          value: 'languages',
        },
        {
          align: 'end',
          value: 'action',
        },
      ];
    },
    dropMenuItems() {
      // onClick, text, icon, isDisabled, target
      return [
        {
          text: 'Duplicate',
          icon: 'add',
          onClick: () => {
            console.log('Duplicate Channel ...');
          },
        },
      ];
    },
  },
  methods: {
    async init() {
      this.isLoading = true;
      await this.$store.dispatch('channels/loadChannels');
      this.isLoading = false;
    },
    getLanguage(item) {
      // no language set, return ['de']
      if (!item.languages || item.languages.length === 0) {
        return 'de';
      }
      
      return item.languages;
    },
  },
};
</script>

<template>
  <div class="question-item">
    <div class="d-flex pa-4" :style="{ opacity: question.checked ? 1 : 0.6 }">
      <div v-if="isAdmin" class="flex-shrink-0 moin-cursor-pointer" style="width: 40px" @click="$emit('onAnswer')">
        <v-icon color="primary lighten-3">mdi-message-text-outline</v-icon>
      </div>

      <div class="flex-grow-1">
        <span :class="[!question.checked && 'text-decoration-line-through']" style="overflow-wrap: anywhere;">
          {{ question.text }}
        </span>
        <div class="relative">
          <div
            v-if="!question.checked"
            class="grey--text"
            style="position: absolute; top: -2px; left: 0; font-size: 0.7em"
          >
            Wird für nächsten Schritt ignoriert.
          </div>
        </div>
      </div>
      <div class="flex-shrink-0 d-flex items-center pl-4" @click="onToggle()">
        <Checkbox :value="question.checked" />
      </div>
    </div>
  </div>
</template>
<script>
import Checkbox from '@/components/common/Checkbox.vue';
export default {
  name: 'DemoQuestion',
  props: {
    // { id: String, text: String, checked: Boolean }
    question: Object,
  },
  components: {
    Checkbox,
  },
  computed: {
    isAdmin() {
      return this.$store.getters['auth/isAdmin'];
    },
  },
  methods: {
    onToggle() {
      this.$emit('onToggle');
    },
  },
};
</script>
<style lang="scss" scoped>
.question-item {
  border-radius: $spacer;
  border: 1px solid #e0e0e0;
}
</style>

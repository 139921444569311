<template>
  <div class="relative">
    <div
      class="relative start-chart"
      :class="(isLoading || isNoData) && 'start-chart--hidden'"
    >
      <apexchart
        width="100%"
        :height="height"
        :type="type"
        :options="chartOptions"
        :series="series"
      />
      <div
        v-if="timeFormat"
        class="text-right text-body-2 grey--text text--darken-1"
        style="position: absolute; left: 15px; top: 0px;"
      >
        <v-icon small color="primary lighten-4">mdi-calendar-range</v-icon>
        {{ $t(`stats.timeFormat.${timeFormat}`) }}
      </div>
    </div>
    <div
      v-if="isNoData"
      class="text-center text-body-2"
      style="position: absolute; top: 150px; left: 0; right: 0;"
    >
      <div v-if="isDateRangeTooSmall" class="text-center mt-n4">
        <v-icon color="primary lighten-3" class="mb-2">
          mdi-eye-off-outline
        </v-icon>
        <div class="text-center primary--text text--lighten-3">
          {{ $t('stats.minTimeRange') }}
        </div>
      </div>
      <span v-else class="grey--text">
        {{ $t('common.nodata') }}
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DateRangeUtil from '@/utils/dateRange';
import { accentColors } from '@/utils/accentColors';

const gridColor = 'rgba(0, 0, 0, 0.2)';

export default {
  name: 'StatsChart',
  props: {
    type: {
      type: String,
      default: 'line',
    },
    dateRange: {
      type: Array,
      default: null,
    },
    dateRangeCompare: {
      type: Array,
      default: null,
    },
    seriesData: {
      type: Array,
    },
    seriesDataCompare: {
      type: [Array, null],
    },
    // black | green | red
    colorSchema: {
      type: String,
      default: 'black',
    },
    isLoading: {
      type: Boolean,
    },
    max: {
      type: Number,
      default: null,
    },
    opposite: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: '300px',
    },
    smooth: {
      type: Boolean,
      default: false,
    },
    fixedNumbers: {
      type: Boolean,
      default: false,
    },
    withPercentage: {
      type: Boolean,
      default: false,
    },
    timeFormat: {
      type: String,
      default: 'day',
    },
    isScalaHundred: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      series: [],
      gridColor,
    };
  },
  computed: {
    ...mapGetters('auth', ['user']),
    colors() {
      switch (this.colorSchema) {
        case 'green':
          return ['rgba(0, 194, 138, 1)', 'rgba(0, 194, 138, 0.4)'];
        case 'red':
          return ['rgba(228, 89, 102, 1)', 'rgba(228, 89, 102, 0.4)'];
        case 'black':
        default:
          return ['rgba(47, 63, 85, 1)', accentColors[0]];
      }
    },
    fillColors() {
      switch (this.colorSchema) {
        case 'green':
          return ['rgba(0, 194, 138, 0.3)'];
        case 'red':
          return ['rgba(rgba(228, 89, 102, 0.3)'];
        case 'black':
        default:
          return ['rgba(47, 63, 85, 0.3)'];
      }
    },
    chartOptions() {
      const options = {
        chart: {
          toolbar: {
            show: false,
          },
          animations: {
            enabled: false,
          },
          zoom: {
            enabled: false,
          },
        },
        colors: this.colors,
        legend: {
          show: true,
          height: 50,
          markers: {
            width: this.type === 'line' ? 32 : 16,
            height: this.type === 'line' ? 4 : 12,
            radius: 0,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          labels: {
            show: false,
          },
          tooltip: {
            enabled: false,
          },
          type: 'category',
          axisTicks: {
            show: true,
            borderType: 'solid',
            color: gridColor,
            height: 8,
            offsetX: 0,
            offsetY: 0,
          },
          axisBorder: {
            show: true,
            color: gridColor,
          },
        },
        yaxis: {
          min: 0,
        },
        tooltip: {
          y: {
            title: {
              formatter: () => '',
            },
          },
        },
        grid: {
          borderColor: gridColor,
        },
      };

      if (this.opposite) options.yaxis.opposite = true;
      if (this.isScalaHundred) {
        options.yaxis.min = 0;
        options.yaxis.max = 100;
      }

      if (this.max) options.yaxis.max = this.max;
      if (this.fixedNumbers) {
        options.yaxis.labels = {
          formatter: function(value = 0) {
            return value.toFixed(0);
          },
        };
      }
      if (this.withPercentage) {
        options.yaxis.labels = {
          formatter: function(value = 0) {
            return value + '%';
          },
        };
      }
      if (this.type === 'line') {
        options.stroke = {
          width: 2,
        };
        if (this.smooth) {
          options.stroke.curve = 'smooth';
        }
      }

      if (this.type === 'line') {
        options.fill = {
          type: 'solid',
          colors: this.fillColors,
        };
      }

      return options;
    },
    isNoData() {
      if (this.series[0]?.data?.length < 2) return true;
      return false;
    },
    isDateRangeTooSmall() {
      return this.dateRange[0].format() === this.dateRange[1].format();
    },
  },
  mounted() {
    this.renderSeries();
  },
  watch: {
    seriesData() {
      this.renderSeries();
    },
    seriesDataCompare() {
      this.renderSeries();
    },
  },
  methods: {
    renderSeries() {
      let newSeries = [{ data: [] }];

      // names
      newSeries[0].name = this.dateRange
        ? DateRangeUtil.getDateRangeFormatted({
            dateRange: this.dateRange,
            timeFormat: this.timeFormat,
            language: this.user.language,
          })
        : '...';

      // data
      if (this.seriesData) {
        newSeries[0].data = this.seriesData;
      }

      if (this.seriesDataCompare) {
        newSeries[1] = {};
        newSeries[1].name = this.dateRangeCompare
          ? DateRangeUtil.getDateRangeFormatted({
              dateRange: this.dateRangeCompare,
              timeFormat: this.timeFormat,
              language: this.user.language,
            })
          : '...';
        newSeries[1].data = this.seriesDataCompare;
      }

      if (this.type === 'line') {
        newSeries[0].type = 'area';
        if (this.seriesDataCompare) {
          newSeries[1].type = 'line';
        }
      }

      this.series = [...newSeries];
    },
  },
};
</script>

<style lang="scss" scoped>
.start-chart {
  opacity: 1;
}

.start-chart--hidden {
  opacity: 0;
  transition: opacity 300ms;
  transition-delay: 500ms;
}

:deep(.apexcharts-legend) {
  flex-direction: column;
  justify-content: start;
  gap: $spacer * 2;
  .apexcharts-legend-marker {
    margin-right: $spacer * 2;
  }
}
:deep(.apexcharts-legend-text) {
  font-size: 14px !important;
}
</style>

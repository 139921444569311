<template>
  <v-main class="grey lighten-2 fill-height">
    <v-container fluid class="fill-height white">
      <!-- frame -->
      <v-row class="fill-height white">
        <!-- content -->
        <v-col cols="12" class="fill-height flex-column d-flex py-0">
          <!-- fix top bar -->
          <v-row class="flex-grow-0">
            <v-col cols="12" class="pa-0">
              <!-- title -->
              <v-card-title class="pa-0" style="height: 60px;">
                <v-col cols="8" class="px-3 flex-nowrap">
                  <v-row class="pa-0 ma-0 flex-nowrap">
                    <!-- Title -->
                    <span :class="isAdminOnly && 'admin--text'">
                      <slot name="title" :title="title">
                        <span v-if="title" :key="title" class="text-truncate text-h5 ml-1 mt-1 mb-auto">{{ title }}</span>
                        <v-skeleton-loader v-else boilerplate class="pt-2 grow" type="heading" />
                      </slot>
                    </span>
                    <v-chip-group
                      v-if="filter.length > 0 && !showFilterAboveListHeader"
                      class="ml-4"
                      :value="selectedFilter"
                      multiple
                      show-arrows
                      active-class="text--accent-4"
                      @change="changeFilter"
                    >
                      <template v-for="(currentFilter, index) in filter">
                        <v-tooltip :key="index + '-filter'" bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                              :disabled="currentFilter.disabled"
                              v-bind="attrs"
                              class="primary lighten-4"
                              text-color="primary lighten-3"
                              outlined
                              v-on="on"
                            >
                              <v-icon
                                small
                                left
                                :color="currentFilter.selected ? 'primary lighten-2' : 'grey lighten-2'"
                              >
                                {{ currentFilter.icon }}
                              </v-icon>
                              <span :class="{ 'hidden-sm-and-down': currentFilter.icon }">
                                {{ $t(currentFilter.name) }}
                              </span>
                            </v-chip>
                          </template>
                          <span v-if="currentFilter.selected">{{ $t("common.filter.off") }}</span>
                          <span v-else>{{ $t("common.filter.on") }}</span>
                        </v-tooltip>
                      </template>
                    </v-chip-group>
                  </v-row>
                </v-col>
                <!-- action icons toolbar -->
                <v-col cols="4" class="text-right pt-3 pb-3">
                  <slot name="toolbar" :disabled="isLoading" />
                </v-col>
              </v-card-title>
              <v-divider />
            </v-col>
          </v-row>

          <!-- content container (scrollable) -->
          <v-row class="flex-grow-1 flex-nowrap" style="height: 0%">
            <!-- scroll container hack -->
            <v-col
              class="pa-0 fill-height moin-bg-grey-lightest"
              :class="contentClass"
              :cols="isSelected ? 12 - sidebarWidth : '12'"
            >
              <v-divider class="grey lighten-4" />
              <v-progress-linear v-if="isLoading" indeterminate color="secondary darken-2" />

              <v-row ref="contentContainer" class="overflow-y-auto ma-0 pa-0" style="height: 0%">
                <v-col class="pa-0" cols="12">
                  <v-card color="transparent" class="d-flex flex-column px-3 pt-2 pb-3 fill-height">
                    <v-card v-if="filter.length > 0 && showFilterAboveListHeader" flat tile>
                      <v-card-text>
                        <!-- <p class="caption primary--text text--lighten-2">
                          <v-icon size="14" class="mr-2 mb-1" color="primary lighten-2">mdi-filter</v-icon> Filter
                        </p> -->
                        <v-chip-group :value="selectedFilter" multiple column @change="changeFilter">
                          <template v-for="(currentFilter, index) in filter">
                            <v-tooltip :key="index + '-filter'" bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-chip
                                  :disabled="currentFilter.disabled"
                                  v-bind="attrs"
                                  :color="currentFilter.selected ? 'primary lighten-3' : 'grey lighten-1'"
                                  :text-color="currentFilter.selected ? 'white' : 'grey'"
                                  :outlined="!currentFilter.selected"
                                  v-on="on"
                                >
                                  <v-icon
                                    small
                                    :color="
                                      currentFilter.selected ? 'white' : currentFilter.iconColor || 'primary lighten-3'
                                    "
                                    class="mr-2"
                                  >
                                    {{ currentFilter.icon }}
                                  </v-icon>
                                  <span :class="{ 'hidden-sm-and-down': currentFilter.icon }">
                                    {{ $t(currentFilter.name) }}
                                  </span>
                                  <span v-if="currentFilter.countItems()" class="ml-sm-2">
                                    {{ currentFilter.countItems() }}
                                  </span>
                                </v-chip>
                              </template>
                              <span v-if="currentFilter.selected">{{ $t("common.filter.off") }}</span>
                              <span v-else>{{ $t("common.filter.on") }}</span>
                            </v-tooltip>
                          </template>
                        </v-chip-group>
                      </v-card-text>
                    </v-card>

                    <slot name="list-header" />

                    <v-list class="pt-0 pb-0 pr-0 pl-0" two-line>
                      <v-list-item-group
                        :value="selectedKey"
                        color="primary"
                        :active-class="noActiveBackground ? 'bg-active' : ''"
                        @change="changeItem"
                      >
                        <template v-for="(item, index) in items">
                          <v-hover v-show="filterItem(item)" :key="'item-' + item[itemKey]" v-slot="{ hover }">
                            <v-list-item
                              :ref="item[itemKey]"
                              v-intersect="(entries) => onIntersect(entries, item)"
                              :value="item[itemKey]"
                              :class="{ selectborder: isSelecteItem(item), 'pl-5': !isSelecteItem(item) }"
                              :to="!isSidebar ? { path: item[itemKey] } : null"
                              :append="!isSidebar"
                            >
                              <v-list-item-icon v-if="!hideIcon" class="mt-auto mb-auto">
                                <slot name="item-icon" :item="item" :selected="isSelecteItem(item)" />
                              </v-list-item-icon>

                              <slot name="item-avatar" :item="item" />

                              <v-list-item-content>
                                <slot name="item" :item="item" :selected="isSelecteItem(item)">
                                  <v-list-item-title v-text="item.displayName" />
                                </slot>
                              </v-list-item-content>
                              <v-list-item-action v-if="!hideActions" class="align-self-center">
                                <slot
                                  name="item-action"
                                  :hover="hover"
                                  :item="item"
                                  :noEdit="noEdit"
                                  :itemKey="itemKey"
                                  :selected="isSelecteItem(item)"
                                >
                                  <BtnSquare v-if="!noEdit" :to="{ path: item[itemKey] }" icon="edit" append/>
                                </slot>
                              </v-list-item-action>
                            </v-list-item>
                          </v-hover>
                          <slot name="item-append" :item="item" :selected="isSelecteItem(item)" />
                          <v-divider
                            v-if="filterItem(item) && index < items.length - 1"
                            :key="'div-' + index"
                            :inset="inset"
                          />
                        </template>
                      </v-list-item-group>
                    </v-list>

                    <slot name="list-footer" />
                  </v-card>
                </v-col>
              </v-row>
            </v-col>

            <!-- Sidebar md and above -->
            <!-- Hier auf die HubContentSidebar gewechselt. Dafür die Slots einfach "durchgereicht". -->
            <HubContentSidebar
              :items="sidebarItems"
              :width="sidebarWidth"
              :value="value"
              :open-sidebar="openSidebar"
              @input="closeSidebar"
            >
              <template v-for="sidebarItem in sidebarItems" v-slot:[sidebarItem.name]="{ item }">
                <slot v-if="sidebarItem.name" :name="`sidebar.${sidebarItem.name}`" :item="item" />
              </template>

              <template v-for="(sidebarItem, index) in sidebarItems" v-slot:[index]="{ item }">
                <slot v-if="!sidebarItem.name" :name="`sidebar.${index}`" :item="item" />
              </template>
            </HubContentSidebar>
            <!-- Alter Sidebar Code -->
            <!--<transition name="slide-left">
              <v-col v-if="isSelected" cols="12" :md="sidebarWidth" class="flex-column d-flex fill-height pa-0">
                <div class="overflow-y-auto mx-0">
                  <v-tabs
                    v-if="sidebarItems.length > 0"
                    v-model="sidebarContent"
                    slider-color="primary lighten-2"
                    background-color="grey lighten-2"
                    grow
                    center-active
                  >
                    <template v-for="(sidebarItem, index) in sidebarItems">
                      <v-tab
                        v-if="isTabActive(sidebarItem, value)"
                        :key="index + '-sidebar'"
                        class="primary--text text--lighten-2"
                      >
                        <v-icon left color="primary lighten-3">{{ sidebarItem.icon }}</v-icon>
                        {{ sidebarItem.title }}
                      </v-tab>
                    </template>
                    <v-btn v-if="!openSidebar" tile icon class="mr-2 mt-auto mb-auto" @click="closeSidebar">
                      <v-icon color="primary lighten-2">close</v-icon>
                    </v-btn>
                    <v-tabs-items v-model="sidebarContent">
                      <template v-for="(sidebarItem, index) in sidebarItems">
                        <v-tab-item
                          v-if="isTabActive(sidebarItem, value)"
                          :key="index + '-sidebar-content'"
                          reverse-transition="none"
                          transition="none"
                        >
                          <slot :name="`sidebar.${sidebarItem.name || index}`" :item="value" />
                        </v-tab-item>
                      </template>
                    </v-tabs-items>
                  </v-tabs>
                </div>
              </v-col>
            </transition>-->
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import { mapGetters } from 'vuex'
import _ from 'lodash'
import HubContentSidebar from '@/components/hub/HubContentSidebar'
import BtnSquare from '@/components/common/BtnSquare.vue'

export default {
  name: 'HubList',
  components: {
    HubContentSidebar,
    BtnSquare,
  },
  props: {
    filter: {
      type: Array,
      default: () => {
        return []
      },
    },
    title: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => {
        return []
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    sidebar: {
      type: Boolean,
      default: true,
    },
    sidebarItems: {
      type: Array,
      default: () => {
        return [{ title: 'Info', icon: 'info', name: 'info' }]
      },
    },
    noEdit: {
      type: Boolean,
      default: false,
    },
    sidebarWidth: {
      type: Number,
      default: 4,
    },
    value: null,
    itemKey: {
      type: String,
      default: 'intent',
    },
    inset: {
      type: Boolean,
      default: true,
    },
    hideIcon: {
      type: Boolean,
      default: false,
    },
    hideActions: {
      type: Boolean,
      default: false,
    },
    noActiveBackground: {
      type: Boolean,
      default: false,
    },
    openSidebar: {
      type: Boolean,
      default: false,
    },
    showFilterAboveListHeader: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showNewFormDialog: false,
      newFormular: '',
      newFormularAlert: false,
      sidebarContent: null,
      visibleMap: {},
    }
  },
  computed: {
    selectedKey() {
      if (!this.value) return null

      return this.value[this.itemKey]
    },
    ...mapGetters('forms', [
      // create properties from auth store
      'forms',
    ]),
    ...mapGetters('auth', ['isAdmin']),
    currentRouteName() {
      return this.$route.name
    },
    formsLoading() {
      return this.forms === false
    },
    contentClass() {
      if (this.isLoading && !this.isSelected) {
        return 'flex-column d-flex fill-height flex-grow-1'
      } else if (this.isSelected && this.isLoading) {
        return 'flex-md-column d-md-flex flex-md-grow-1 hidden-sm-and-down slide-width' // sm and xs hide content
      } else if (this.isSelected) {
        return 'flex-md-column d-md-flex flex-md-grow-1 hidden-sm-and-down slide-width' // sm and xs hide content
      } else {
        return 'flex-column d-flex fill-height flex-grow-1'
      }
    },
    selectedFilter() {
      const filter = this.filter
        .map((f, index) => {
          const newFilter = _.cloneDeep(f)
          newFilter.index = index
          if (newFilter.selected && typeof newFilter.countItems === 'function' && newFilter.countItems() === 0) {
            newFilter.selected = false // if no items are available deselect filter
            f.selected = false // deselect in parent filter
          }
          return newFilter
        })
        .filter(f => {
          return f.selected
        })
        .map(f => {
          return f.index
        })
      return filter
    },
    isLoading() {
      return this.loading === true
    },
    isSidebar() {
      // Sidebar availabe
      return this.sidebar
    },
    isSelected() {
      return this.isSidebar && (this.openSidebar || (this.value !== null && this.value !== undefined))
    },
    isAdminOnly () {
      return this.$route.meta?.admin || false;
    },
  },
  watch: {
    items: function() {
      this.visibleMap = {}
    },
  },
  updated() {},
  created() {
    document.addEventListener('keydown', this.onKeyEvent);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.onKeyEvent);
  },
  methods: {
    onKeyEvent(event) {
      if (event.key === 'ArrowUp') {
        this.listUp();
      }
      if (event.key === 'ArrowDown') {
        this.listDown();
      }
    },
    onIntersect(entries, item) {
      this.visibleMap[item[this.itemKey]] = entries[0].isIntersecting
    },
    listUp() {
      let index = this.itemIndex(this.value) - 1

      if (index < -1) {
        return this.changeItem(undefined, this.items.length - 1)
      }

      while (index >= 0 && !this.filterItem(this.items[index])) {
        index--
      }

      if (index >= 0) {
        this.changeItem(undefined, index)
      }
    },
    listDown() {
      let index = this.itemIndex(this.value) + 1

      while (index < this.items.length && !this.filterItem(this.items[index])) {
        index++
      }

      if (index < this.items.length) {
        this.changeItem(undefined, index)
      }
    },
    closeSidebar() {
      this.$emit('input', null)
    },
    async newForm() {
      const response = await this.$store.dispatch('forms/add', {
        botId: this.currentBotId,
        displayName: this.newFormular,
      })
      if (response.status === 'error') {
        this.newFormularAlert = true
      }
      this.newFormular = ''
    },
    filterItem(item) {
      const currentFilter = this.filter.filter(f => {
        return f.selected
      })

      if (currentFilter.length > 0) {
        const filtered = currentFilter.find(f => {
          return f.func(item)
        })

        return !!filtered
      }
      return true
    },
    isSelecteItem(item) {
      return item === this.value
    },
    isTabActive(sidebarItem, value) {
      if (typeof sidebarItem.active === 'function') {
        return sidebarItem.active(value)
      }

      return true
    },
    changeItem(item, index) {
      if ((item === undefined && index === undefined) || (index === undefined && item === this.selectedKey)) {
        return this.$emit('input')
      }

      if (index === undefined) {
        item = this.items.findIndex(i => {
          return i[this.itemKey] === item
        })
      } else {
        item = index
      }

      if (item < 0) {
        return this.$emit('input')
      }

      this.$emit('input', this.items[item])

      // is item visible?
      const idx_low = Math.max(item - 1, 0)
      const idx_high = Math.min(item + 1, this.items.length - 1)
      if (!this.visibleMap[this.items[item][this.itemKey]]) {
        this.$vuetify.goTo(this.$refs[this.items[item][this.itemKey]][0], { container: this.$refs.contentContainer })
      } else if (this.filterItem(this.items[idx_high]) && !this.visibleMap[this.items[idx_high][this.itemKey]]) {
        this.$vuetify.goTo(this.$refs[this.items[idx_high][this.itemKey]][0], {
          container: this.$refs.contentContainer,
        })
      } else if (this.filterItem(this.items[idx_low]) && !this.visibleMap[this.items[idx_low][this.itemKey]]) {
        this.$vuetify.goTo(this.$refs[this.items[idx_low][this.itemKey]][0], { container: this.$refs.contentContainer })
      }
    },
    itemIndex(item) {
      return this.items.findIndex(i => {
        return _.isEqual(item, i)
      })
    },
    changeFilter(filter) {
      this.filter.forEach(f => {
        f.selected = false
      })

      filter.forEach(f => {
        this.filter[f].selected = true
      })

      const filterStore = {}
      this.filter.forEach(filter => {
        return (filterStore[filter.id] = filter.selected)
      })

      this.$store.dispatch('behaviour/setHublistFilters', filterStore)

      if (this.value && !this.filterItem(this.value)) {
        this.closeSidebar()
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    next(true)
    // called before the route that renders this component is confirmed.
    // does NOT have access to `this` component instance,
    // because it has not been created yet when this guard is called!
  },
}
</script>
<style scoped scss>
.selectborder {
  border-left: 4px solid var(--v-secondary-darken2) !important;
}

.bg-active::before {
  background-color: white !important;
}

.slide-width {
  transition: max-width 0.2s ease-in 0.05s;
  -webkit-transition: max-width 0.2s ease-in 0.05s;
  -o-transition: max-width 0.2s ease-in 0.05s;
  -moz-transition: max-width 0.2s ease-in 0.05s;
}

/* slide in */
.slide-left-enter-active {
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -moz-transition-delay: 0.05s;
  -webkit-transition-delay: 0.05;
  -o-transition-delay: 0.05s;
  transition-delay: 0.05s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-left-enter,
.slide-left-leave-to {
  margin-right: -100%;
}
</style>

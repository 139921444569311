<template>
  <div v-if="text" class="">
    <div class="d-flex flex-column items-center justify-end pb-4" style="height: 150px;">
      <div class="avatar-bubble">
        <div class="mx-auto avatar-bubble-bubble text-body-2" v-html="text"></div>
        <div class="avatar-bubble-zipple mx-auto"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'DemoMoinAvatarBubble',
  components: {},
  props: {
    text: String,
  },
};
</script>
<style lang="scss" scoped>

@keyframes bubble-in {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.avatar-bubble {
  animation: bubble-in 0.3s forwards;
}

.avatar-bubble-bubble {
  display: inline-block;
  padding: $spacer * 4 $spacer * 5;
  border-radius: $spacer;
  background: white;
  max-width: 240px;
}

.avatar-bubble-zipple {
  position: relative;
  width: 20px;

  // Triangle with border-radius
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 $spacer * 3 $spacer * 3 0;
    border-color: transparent transparent white transparent;
    transform: rotate(90deg);
  }
}
</style>

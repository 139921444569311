<template>
  <div
    v-if="notification"
    class="d-flex rounded pa-2 justify-space-between align-center w-full"
    :class="[`notification--${notification.styleType}`]"
    style="height: 52px"
  >
    <div class="overflow-hidden d-flex align-center">
      <v-icon v-if="icon" color="primary" class="mr-2">
        {{ icon }}
      </v-icon>
      <div class="text-body-2 primary--text" style="line-height: 1.1">
        <span v-if="notification.messageType">
          {{ $t(`notifications.${notification.messageType}.message`) }}
        </span>
        <span v-else>
          {{ notification.messageString }}
        </span>
      </div>
    </div>
    <div v-if="notification.action || notification.actionSecondary">
      <v-btn
        v-if="notification.actionSecondary"
        outlined
        class="mr-2"
        color="primary"
        depressed
        @click="notification.actionSecondary"
      >
        {{
          $t(`notifications.${notification.messageType}.actionSecondaryTitle`)
        }}
      </v-btn>

      <v-btn color="primary" depressed @click="notification.action">
        <span v-if="notification.messageType">
          {{ $t(`notifications.${notification.messageType}.actionTitle`) }}
        </span>
        <span v-else>{{ $t('common.ok') }}</span>
      </v-btn>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { styleTypes } from '@/utils/notifications';

export default {
  name: 'HubNotification',
  data() {
    return {
      styleTypes,
    };
  },
  computed: {
    ...mapGetters('notifications', ['notification']),
    icon() {
      const iconString = this.$t(
        `notifications.${this.notification.messageType}.icon`
      );
      if (iconString.includes('.')) return null;
      return (
        this.$t(`notifications.${this.notification.messageType}.icon`) || null
      );
    },
  },
};
</script>
<style lang="scss">
.notification--warning {
  background: $moin-color-panel-warning;
  border: 1px solid var(--v-warning-base);
}
.notification--error {
  background: $moin-color-panel-error;
  border: 1px solid var(--v-error-base);
}
.notification--success {
  background: $moin-color-panel-success;
  border: 1px solid var(--v-success-base);
}
.notification--info {
  background: var(--v-white-base);
  color: var(--v-primary-lighten4);
  border: 1px solid var(--v-primary-lighten4);
}
</style>

/* import 'material-design-icons-iconfont/dist/material-design-icons.css' */
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import de from 'vuetify/es5/locale/de';
import en from 'vuetify/es5/locale/en';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  lang: {
    locales: { de, en },
    current: 'en',
  },
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: '#222C50',
        secondary: '#A5E1DE',
        secondaryFresh: '#0EAB9A',
        moinblue: '#405BA3', // TBD temporary name, find better one!!
        accent: '#00C6B0',
        error: '#E45966',
        warning: '#FBBA04',
        success: '#00C28A',
        info: '#A5E1DE',
        admin: '#d638b2',
        grey: '#9E9E9E',
        accentA: '#1867C0',
        accentB: '#5CBBF6',
        accentC: '#742ED5',
        accentD: '#2E50A8',
        accentE: '#C75CD9',
        accentF: '#58E1D1',
        assistant: '#5700D0',
      },
    },
  },
});

<template>
  <v-fade-transition>
    <v-app>
      <div class="app-bot-select moin-bg-grey-lightest">
        <v-card class="pa-6 ma-4">
          <div class="d-flex justify-center">
            <div style="height: 40px" class="mb-8">
              <v-img src="@/assets/moin-logo.svg" />
            </div>
          </div>
          <v-select
            :placeholder="$t('common.chooseABot')"
            style="width: 300px"
            outlined
            :items="bots"
            :value="currentBot"
            item-text="name"
            item-value="uniqueBotId"
            @change="changeBot($event)"
            data-cy="app-bot-select"
          ></v-select>
        </v-card>
      </div>
    </v-app>
  </v-fade-transition>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AppBotSelect',
  computed: {
    ...mapGetters('bots', ['bots', 'currentBot']),
  },
  methods: {
    changeBot(uniqueBotId) {
      let query = Object.assign({}, this.$route.query);
      query.bid = uniqueBotId;
      this.$router.replace({ query }).catch(() => true);
      this.$emit('onBotChange', uniqueBotId);
    },
  },
};
</script>
<style scoped lang="scss">
.app-bot-select {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000; // Above loading
}
</style>

import ProductService from '@/services/product';
import deepEqual from 'fast-deep-equal/es6';
import moment from 'moment';
/*
    Hält Origins der Inhalte einzelner Bot Antworten im State.
*/

// initial state
const state = {
  contentOrigins: {}, // store for origin versions
};

const getters = {
  getContentBoxes: (state) => (botId, intent, channel) => {
    if (!state.contentOrigins[botId] || !state.contentOrigins[botId][intent]) {
      return [];
    }

    let allBoxes = Object.keys(state.contentOrigins[botId][intent])
      .map((box) => {
        return state.contentOrigins[botId][intent][box];
      })
      .filter((box) => {
        if (channel === false) {
          return true;
        } else if (!channel && box.channel) {
          return false;
        } else if (!channel && !box.channel) {
          return true;
        } else if (channel && box.channel && channel === box.channel) {
          return true;
        }

        return false;
      });

    return allBoxes;
  },
  getContent: (state) => (botId, intent, box) => {
    // get origins
    if (
      !state.contentOrigins[botId] ||
      !state.contentOrigins[botId][intent] ||
      !state.contentOrigins[botId][intent][box]
    ) {
      return null;
    }
    return state.contentOrigins[botId][intent][box];
  },
  isContentChanged: (state, getters, rootState, rootGetters) => (
    botId,
    intent,
    channel
  ) => {
    if (!rootGetters) return false;

    let originBoxes = getters['getContentBoxes'](botId, intent, channel);
    if (originBoxes.length === 0) return false;

    // get version number from intent and channel
    // is version number same global version number dann return cache

    //

    let allBoxes = rootGetters['content/getContentBoxes'](
      botId,
      intent,
      channel
    );
    let notEqual = false;
    for (let box of allBoxes) {
      if (
        !state.contentOrigins[botId] ||
        !state.contentOrigins[botId][intent] ||
        !state.contentOrigins[botId][intent][box.box]
      ) {
        notEqual = true;
        break;
      }
      if (!deepEqual(state.contentOrigins[botId][intent][box.box], box)) {
        notEqual = true;
        break;
      }
    }
    return notEqual;
  },
  isUpdatedContentAvailable: (state, getters, rootState, rootGetters) => (
    botId,
    intent,
    channel
  ) => {
    let draftBoxes = rootGetters['content/getContentBoxes'](
      botId,
      intent,
      channel
    );
    let modificationDate = false;
    draftBoxes.forEach((b) => {
      if (modificationDate === false && b.modificationDate) {
        modificationDate = b.modificationDate;
      }

      if (
        moment(new Date(modificationDate)).isBefore(
          moment(new Date(b.modificationDate))
        )
      ) {
        modificationDate = b.modificationDate;
      }
    });

    let originModificationDate = false;

    if (!state.contentOrigins[botId] || !state.contentOrigins[botId][intent]) {
      return false;
    }

    for (let box of draftBoxes) {
      if (
        state.contentOrigins[botId][intent][box.box] &&
        state.contentOrigins[botId][intent][box.box].modificationDate
      ) {
        if (
          originModificationDate === false ||
          moment(new Date(originModificationDate)).isBefore(
            moment(
              new Date(
                state.contentOrigins[botId][intent][box.box].modificationDate
              )
            )
          )
        ) {
          originModificationDate =
            state.contentOrigins[botId][intent][box.box].modificationDate;
        }
      }
    }

    if (modificationDate === false || originModificationDate === false) {
      return false;
    }

    return moment(new Date(modificationDate)).isBefore(
      moment(new Date(originModificationDate))
    );
  },
};
const actions = {
  async getContent(
    { commit, rootGetters },
    { step, botId, intent, template, box, channel, preloading }
  ) {
    /**
     * it fetches the content from api and it saves it to the origin content store.
     *
     * if preloading is set, all follow up boxes will be queued and processed.
     */

    // returns queue promise
    return new Promise(async (done) => {
      let cache = []; // caches results and commit when done
      let parentBox = false;

      let finishFetching = async () => {
        await commit('contentQueue/removeWorker', null, { root: true });
        setTimeout(nextTask, 100);
      };

      let fetching = async (params, doneFetching) => {
        if (!params.template || !params.intent || !params.botId) {
          return doneFetching();
        }

        let originContent = await ProductService.getContent(
          params.botId,
          params.template,
          params.intent,
          params.box,
          params.channel
        );
        if (
          originContent.result &&
          originContent.result.template &&
          originContent.result.box !== false
        ) {
          if (params.step !== undefined && params.step !== null) {
            originContent.result.step = params.step;
          }

          // Wenn kein Origin Content im State liegt, oder das Template identisch ist und es nicht nur flüchtig ist (onlyDraft).
          if (!originContent.result.onlyDraft) {
            cache.push({
              content: originContent.result,
              botId: params.botId,
              intent: params.intent,
              box: originContent.result.box,
            });

            // Pre loading aller Inhalte
            if (
              params.preloading &&
              originContent.result.followup &&
              originContent.result.followup.box
            ) {
              await commit(
                'contentQueue/addQueue',
                {
                  element: {
                    step: params.step + 1,
                    botId: params.botId,
                    template: originContent.result.followup.template,
                    intent: params.intent,
                    box: originContent.result.followup.box,
                    channel: params.channel,
                    preloading: params.preloading,
                  },
                },
                { root: true }
              );
            } else if (
              params.preloading &&
              Array.isArray(originContent.result.answers)
            ) {
              for (let answer of originContent.result.answers) {
                if (answer.box) {
                  await commit(
                    'contentQueue/addQueue',
                    {
                      element: {
                        step: params.step + 1,
                        botId: params.botId,
                        template: answer.template,
                        intent: params.intent,
                        box: answer.box,
                        channel: params.channel,
                        preloading: params.preloading,
                      },
                    },
                    { root: true }
                  );
                }
              }
            } else if (
              params.preloading &&
              Array.isArray(originContent.result.childBoxes)
            ) {
              for (let childbox of originContent.result.childBoxes) {
                await commit(
                  'contentQueue/addQueue',
                  {
                    element: {
                      botId: params.botId,
                      template: childbox.template,
                      intent: params.intent,
                      box: childbox.box,
                      channel: params.channel,
                      preloading: params.preloading,
                    },
                  },
                  { root: true }
                );
              }
            } else if (
              params.box === undefined &&
              originContent &&
              originContent.result &&
              originContent.result.box
            ) {
              params.box = originContent.result.box;
            }
          }

          if (
            (originContent.result.onlyDraft && !params.box) ||
            params.channel
          ) {
            params.box = originContent.result.box;
          }
          // set box of first/parent request
          if (parentBox === false) {
            parentBox = params.box || originContent.result.box;
          }

          return doneFetching();
        } else {
          return doneFetching();
        }
      };

      let nextTask = async () => {
        if (rootGetters['contentQueue/isSyncing']) {
          if (rootGetters['contentQueue/workerSlotsAvailable']) {
            // work to do
            let queues = rootGetters['contentQueue/getQueues'];
            await commit('contentQueue/addWorker', null, { root: true });
            let element = rootGetters['contentQueue/getQueue'](queues[0]);
            await commit('contentQueue/removeQueue', queues[0], { root: true });
            fetching(element, finishFetching);
            setTimeout(nextTask, 100);
            /*  nextTask(); */
          } else if (rootGetters['contentQueue/isEmpty']) {
            // finish queue and commit cache
            await commit('contentQueue/finish', null, { root: true });
            await commit('setContentOrigins', cache);
            done(parentBox);
          }
        }
        // nothing todo
      };

      // add element to queue
      await commit(
        'contentQueue/addQueue',
        {
          element: { step, botId, template, intent, box, channel, preloading },
        },
        { root: true }
      );

      if (!rootGetters['contentQueue/isSyncing']) {
        await commit('contentQueue/start', null, { root: true });
        setTimeout(nextTask, 10);
      }
    });
  },
  async copyOrigin({ commit, state, rootGetters }, { botId, name, channel }) {
    /**
     * it is called when switching to the edit mode.
     * it handles the origin -> draft copy, if no draft available
     *
     * TODO draft outdated feature
     */
    let draftBoxes = rootGetters['content/getContentBoxes'](
      botId,
      name,
      channel
    );

    if (draftBoxes.length > 0) {
      return;
    }
    /* if (state.contentDrafts && state.contentDrafts[botId] && state.contentDrafts[botId][name]) {
            // all draft boxes from channel
            let allBoxes = Object.keys(state.contentDrafts[botId][name]).map(box => {
                return state.contentDrafts[botId][name][box];
            }).filter(box => {
                if (!channel && box.channel) {
                    return false;
                } else if (!channel && !box.channel) {
                    return true && (box.template === 'SimpleInformation');
                } else if (channel && box.channel && channel === box.channel) {
                    return true && (box.template === 'SimpleInformation');
                }

                return false;
            })

            if (allBoxes.length > 0) {
                return;
            }
        } */
    if (
      state.contentOrigins &&
      state.contentOrigins[botId] &&
      state.contentOrigins[botId][name]
    ) {
      let allBoxes = Object.keys(state.contentOrigins[botId][name])
        .map((box) => {
          return state.contentOrigins[botId][name][box];
        })
        .filter((box) => {
          /*   if (state.contentDrafts && state.contentDrafts[botId] && state.contentDrafts[botId][name] && state.contentDrafts[botId][name][box.box]) {
                    return false;
                } */

          if (!channel && box.channel) {
            return false;
          } else if (!channel && !box.channel) {
            return true;
          } else if (channel && box.channel && channel === box.channel) {
            return true;
          }

          return false;
        });
      commit(
        'content/setContentDrafts',
        { drafts: allBoxes, botId },
        { root: true }
      );
    }
  },
};

const mutations = {
  resetOrigins(state, { botId, name, channel }) {
    // if their is no local changes remove unchanged draft too.
    if (channel === undefined) {
      channel = false;
    }

    if (
      state.contentOrigins &&
      state.contentOrigins[botId] &&
      state.contentOrigins[botId][name]
    ) {
      // all draft boxes from channel
      let allBoxes = Object.keys(state.contentOrigins[botId][name])
        .map((box) => {
          return state.contentOrigins[botId][name][box];
        })
        .filter((box) => {
          if (channel === false) {
            return true;
          } else if (!channel && box.channel) {
            return false;
          } else if (!channel && !box.channel) {
            return true;
          } else if (channel && box.channel && channel === box.channel) {
            return true;
          }

          return false;
        });

      if (allBoxes.length > 0) {
        // delete all drafts
        for (let box of allBoxes) {
          delete state.contentOrigins[botId][name][box.box];
        }
      }
    }
  },
  setContentOrigin(state, { content, botId, intent, box }) {
    const clone = JSON.parse(JSON.stringify(content));
    if (!state.contentOrigins[botId]) {
      state.contentOrigins = { ...state.contentOrigins, [botId]: {} };
    }

    if (!state.contentOrigins[botId][intent]) {
      state.contentOrigins[botId] = {
        ...state.contentOrigins[botId],
        [intent]: {},
      };
    }

    state.contentOrigins[botId][intent] = {
      ...state.contentOrigins[botId][intent],
      [box]: clone,
    };
  },
  setContentOrigins(state, list) {
    for (let l of list) {
      if (!l.content.createChannel) {
        const clone = JSON.parse(JSON.stringify(l.content));

        if (!state.contentOrigins[l.botId]) {
          state.contentOrigins = { ...state.contentOrigins, [l.botId]: {} };
        }

        if (!state.contentOrigins[l.botId][l.intent]) {
          state.contentOrigins[l.botId] = {
            ...state.contentOrigins[l.botId],
            [l.intent]: {},
          };
        }

        state.contentOrigins[l.botId][l.intent] = {
          ...state.contentOrigins[l.botId][l.intent],
          [l.box]: clone,
        };
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

<template>
  <div class="moin-fresh" v-if="knowledge && knowledge.length > 0">
    <v-divider class="mb-4" />
    <v-card flat>
      <v-card-title class="pa-0 primary--text text--lighten-2">
        <v-icon color="primary lighten-2" left>school</v-icon>
        {{ $t('welcome.knowledge.title') }}
      </v-card-title>
      <v-hover
        v-for="(item, index) in knowledge"
        :key="index"
        v-slot="{ hover }"
      >
        <v-card
          class="pa-0"
          :href="item.link"
          target="_blank"
          flat
          :color="hover ? 'grey lighten-4' : ''"
        >
          <v-row class="mt-0 mt-sm-4 mb-2 mb-sm-n4 px-2 py-0">
            <v-col
              cols="12"
              sm="3"
              md="2"
              :class="{ 'pr-0 mr-0': $vuetify.breakpoint.mdAndUp }"
            >
              <v-img
                class="ma-0 rounded-lg ml-0"
                :class="{ 'pr-0 mr-0': $vuetify.breakpoint.mdAndUp }"
                height="auto"
                max-height="160"
                width="280"
                :src="item.image"
              />
            </v-col>
            <v-col cols="12" sm="9" md="10">
              <v-card-title class="pl-0 pl-sm-4 pt-0 primary--text">
                {{ item.title }}
              </v-card-title>
              <v-card-subtitle
                class="pl-0 pl-sm-4 pb-0 pb-sm-2 text-subtitle-1 primary--text"
              >
                {{ item.description }}
              </v-card-subtitle>
            </v-col>
          </v-row>
        </v-card>
      </v-hover>
    </v-card>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'WelcomeKnowledge',
  computed: {
    ...mapGetters('welcome', ['knowledge']),
    ...mapGetters('bots', ['currentBotStage']),
    ...mapGetters('auth', ['user']),
  },
  mounted() {
    this.$store.dispatch('welcome/getKnowledge', {
      locale: this.user.language,
      stage: this.currentBotStage,
    });
  },
};
</script>

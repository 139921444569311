<template>
    <v-card>
        <v-alert type="error" v-if="showError">Varianten ist bereits vorhanden.</v-alert>
        <v-card-title>Varianten hinzufügen</v-card-title>
        <v-card-text>
            <v-row>
                <v-col cols="12">
                    <v-textarea label="Varianten" v-model="newText" row-height="20" rows="10"></v-textarea>
                     <span>Varianten: {{variantCount}}</span>
                </v-col>
                <v-col cols="12">
                    <v-autocomplete
                        v-model="newIntent"
                        :items="allIntents"
                        item-text="displayName"
                        item-value="intent"
                        cache-items
                        flat
                        hide-no-data
                        @change="changeIntent"
                        label="Thema auswählen"                        
                    ></v-autocomplete>

                </v-col>
            </v-row>
            <v-row v-if="trainingdata.length > 0" class="mt-0">
               <v-list dense class="mt-0">
                   <v-list-item v-for="(data, index) in trainingdata" :key="index">{{data.text}}</v-list-item>
               </v-list>
            </v-row>
        </v-card-text>
            
              <v-divider class="mt-2"></v-divider>

              <v-card-actions class="flex-column flex-sm-row justify-space-between align-stretch">
                <v-btn @click="$emit('onClose');" outlined color="primary lighten-2" class="px-4 primary--text d-block d-sm-inline-block my-4 my-sm-0">Abbrechen <v-icon right>close</v-icon></v-btn>
                <v-btn @click="addVariant" :disabled="variantCount === 0 || !newIntent" class="px-4 white--text ml-0 ml-sm-2 d-block d-sm-inline-block" depressed color="primary lighten-2">Hinzufügen<v-icon right>add</v-icon></v-btn></v-card-actions>

    </v-card>
</template>

<script>
    // import { mapActions } from "vuex";
    // import BotService from '../services/bot';
   /*  import _ from 'lodash';

    import ProductService from '../services/product';*/
    import { mapGetters } from "vuex";

    export default {
        name: 'VariantDialog',
        props: ['text', 'intent'],
        created() {
            if (this.intent) {
                this.newIntent = this.intent;
            }

            if (this.text && !Array.isArray(this.text)) {
                this.newText = this.text;
            } else if (Array.isArray(this.text)) {
                this.newText = this.text.map(text => {
                    return text.replace('\n', ' ');
                }).join('\n');
            }

            this.showError = false;
        },
        computed: {
             allIntents: function() {
                if (this.intents) {
                    
                    return this.intents.concat(this.suggested).concat([{displayName: this.$t('ai.tool.unknown'), intent: 'unknown'}, {displayName: 'Smalltalk', intent: 'smalltalk'}]);
                } else {
                    return []
                }
            },
            variantCount: function() {
                if (!this.newText) return 0;

                return this.newText.split('\n').filter(text => {
                    return text && text.trim().length > 0;
                }).length;
            },
              ...mapGetters('bots', [ // create properties from auth store
                'currentBotId'
            ]),
             ...mapGetters('intents', [ // create properties from auth store
                'intents', 'bookedIntents', 'suggested'
            ]),
        },
        data() {
            return {
                 newIntent: null,
                 newText: null,
                 showError: false,
                 trainingdata: []
            };
        },
        methods: {
            async addVariant() {
                console.log('ADD VARIANT');
                if (this.newIntent && this.newText) {
                    await this.$store.dispatch('trainingdata/addData', {name: this.newIntent, uniqueBotId: this.currentBotId, trainingdata: this.newText.split('\n').filter(text => {
                    return text && text.trim().length > 0;
                })});
                    this.$emit('onClose');
                    //}
                }
            },
            async changeIntent() {
                let data = await this.$store.dispatch('trainingdata/get', {uniqueBotId: this.currentBotId, name: this.newIntent});
                if (data && Array.isArray(data)) {
                     this.trainingdata = data.filter(d => {
                        return d.reference === true;
                    });

                    if (this.trainingdata.length === 0) {
                        this.trainingdata = data.slice(0, 5);
                    }
                } else {
                    this.trainingdata = [];
                }
            }
        },
}
</script>
<template>
  <div class="fill-height">
    <v-card outlined class="fill-height pa-4">
      <h2 class="text-h6">Info</h2>
      <v-divider class="my-2" />
      <div class="d-flex text-body-2 mb-2">
        <div class="moin-label mr-2 moin-col-label">Themen:</div>
        <div style="width: 100px;">
          <div>{{ intents.length }}</div>
        </div>
      </div>
      <div class="d-flex text-body-2 mb-2">
        <div class="moin-label mr-2 moin-col-label">In Review:</div>
        <div style="width: 100px;">
          <div>{{ intentsInReview.length }}</div>
        </div>
      </div>
      <div class="d-flex text-body-2 mb-2">
        <div class="moin-label mr-2 moin-col-label">Vorschläge:</div>
        <div style="width: 100px;">
          <div>{{ suggested.length }}</div>
        </div>
      </div>
      <div class="d-flex text-body-2 mb-2">
        <div class="moin-label mr-2 moin-col-label">Smalltalk:</div>
        <div style="width: 100px;">
          <div>{{ smalltalks.length }}</div>
        </div>
      </div>
      <div class="d-flex text-body-2 mb-2">
        <div class="moin-label mr-2 moin-col-label">Unterhaltungen:</div>
        <div style="width: 100px;">
          <div>{{ conversations }}</div>
        </div>
      </div>
    </v-card>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { StatsService } from '@/services/stats';
import moment from 'moment';

export default {
  name: 'QmDashboardAmounts',
  data() {
    return {
      conversations: 0,
    };
  },
  computed: {
    ...mapGetters('bots', ['currentBotId']),
    ...mapGetters('intents', [
      'intents',
      'suggested',
      'smalltalks',
      'isOriginIntent',
      'currentIntent',
      'displayName',
      'description',
      'isAutoTranslated',
    ]),
    intentsInReview() {
      if (!this.intents.length) return 0;
      return this.intents.filter((intent) => intent?.payload?.inReview);
    },
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      const dateRange = [
        moment().add(-30, 'days'),
        moment(),
      ];
      this.conversations = (
        await StatsService.getKpi(this.currentBotId, 'conversations', dateRange)
      ).conversations;
    },
  },
};
</script>
<style scoped>
.moin-label {
  color: var(--v-primary-lighten3);
}
.moin-col-label {
  width: 200px;
}
</style>

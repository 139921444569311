<template>
  <div>
    <ActionRowStart :blockType="blockTypes.START_LOADING" :options="options" />
    <ActionRow v-if="showTextRow">
      <template #line>
        <ActionLine :blockType="blockTypes.content_loading" :options="options" />
      </template>
      <template #content>
        <div class="pb-4 pr-2">
          <v-skeleton-loader
            class="relative"
            type="image"
            width="100%"
            :height="112"
          />
        </div>
      </template>
    </ActionRow>

    <ActionUserRow v-if="showUserInput" :loading="true" :noLine="!showRowEndLoading" />
    <ActionRowEndLoading v-if="showRowEndLoading" />
  </div>
</template>
<script>
import ActionRowStart from '@/components/editor/components/ActionRowStart.vue';
import ActionRowEndLoading from '@/components/editor/components/ActionRowEndLoading.vue';
import ActionUserRow from '@/components/editor/components/ActionUserRow.vue';
import ActionLine from '@/components/editor/components/ActionLine.vue';
import ActionRow from '@/components/editor/components/ActionRow.vue';
import { blockTypes, contentTypes } from '@/components/editor/actionConsts';

export default {
  name: 'ContentTemplateLoaderLoading',
  props: {
    contentType: {
      type: String,
      default: contentTypes.SIMPLE_INFORMATION,
    },
    options: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    ActionRowStart,
    ActionRowEndLoading,
    ActionUserRow,
    ActionLine,
    ActionRow,
  },
  data() {
    return {
      blockTypes,
    };
  },
  computed: {
    showTextRow() {
      if (
        this.contentType === contentTypes.SIMPLE_INFORMATION ||
        this.contentType === contentTypes.YES_NO_QUESTION ||
        this.contentType === contentTypes.MULTIPLE_CHOICE ||
        this.contentType === contentTypes.ANSWER_INFO
      ) {
        return true;
      }
      return false;
    },
    showUserInput() {
      if (
        this.contentType === contentTypes.SIMPLE_INFORMATION ||
        this.contentType === contentTypes.YES_NO_QUESTION ||
        this.contentType === contentTypes.MULTIPLE_CHOICE ||
        this.contentType === contentTypes.FEEDBACK ||
        this.contentType === contentTypes.NONE
      ) {
        return true;
      }
      return false;
    },
    showRowEndLoading() {
      this.contentType === contentTypes.SIMPLE_INFORMATION ||
      this.contentType === contentTypes.YES_NO_QUESTION ||
      this.contentType === contentTypes.MULTIPLE_CHOICE
    },
  },
};
</script>

/**
 * @typedef {string} LivechatProvidersEnum
 * @enum {LivechatProvidersEnum}
 */
const LivechatProvidersEnum = {
  crisp: 'crisp',
  freshchat: 'freshchat',
  greyhound: 'greyhound',
  livechat: 'livechat',
  moinLivechat: 'moin_livechat',
  salesforce: 'salesforce',
  snapengage: 'snapengage',
  userLike: 'userlike',
  zChat: 'zChat',
};

module.exports = { LivechatProvidersEnum };

<template>
  <div>
    <div>
      <div class="loading">
        <div class="star star-1" style="animation-delay: 0s">
          <DemoStar :fill="assistant ? 'var(--moin-color-accent-1)' : null" />
        </div>
        <div class="star star-2" style="animation-delay: 1s">
          <DemoStar :fill="assistant ? 'var(--moin-color-accent-2)' : null" />
        </div>
        <div class="star star-3" style="animation-delay: 1.5s">
          <DemoStar :fill="assistant ? 'var(--moin-color-accent-5)' : null" />
        </div>
        <div
          class="spark spark-1"
          :style="{
            backgroundColor: assistant ? 'var(--moin-color-accent-5)' : '',
          }"
          style="animation-delay: 0s"
        ></div>
        <div
          class="spark spark-2"
          :style="{
            backgroundColor: assistant ? 'var(--moin-color-accent-1)' : '',
          }"
          style="animation-delay: 1.6s"
        ></div>
        <div class="spark spark-3" style="animation-delay: 0.8s"></div>
        <div class="spark spark-4" style="animation-delay: 1.2s"></div>
        <div
          class="spark spark-5"
          :style="{
            backgroundColor: assistant ? 'var(--moin-color-accent-2)' : '',
          }"
          style="animation-delay: 0.4s"
        ></div>
      </div>
    </div>
  </div>
</template>
<script>
import DemoStar from '@/components/common/DemoStar.vue';
export default {
  name: 'DemoStepLoad',
  components: {
    DemoStar,
  },
  props: {
    assistant: Boolean,
  },
};
</script>
<style lang="scss">
.loading {
  margin: 50px auto;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.star {
  width: 50px;
  height: 50px;
  position: absolute;
  opacity: 0;
  animation: spark 1.5s ease-in-out infinite;
}

.star-1 {
  top: 0px;
  left: -20px;
}

.star-2 {
  top: 20px;
  left: 45px;
}

.star-3 {
  top: 60px;
  left: 20px;
}

.spark {
  width: 20px;
  height: 20px;
  opacity: 0;
  border-radius: 50%;
  background-color: var(--v-secondary-base);
  position: absolute;
  animation: spark 1.2s ease-in-out infinite;
}

.spark-1 {
  top: -20px;
  left: -10px;
}

.spark-2 {
  top: -10px;
  left: 30px;
}

.spark-3 {
  top: 10px;
  right: -10px;
}

.spark-4 {
  bottom: -10px;
  right: 10px;
}

.spark-5 {
  bottom: 10px;
  left: -10px;
}

@keyframes spark {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  50% {
    opacity: 0.9;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
</style>

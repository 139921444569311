<template>
  <v-card :outlined="!noBorder" :flat="noBorder" class="pa-4" :class="[fillHeight && 'fill-height']">
    <v-progress-linear
      class="hub-box-simple-loading-bar"
      v-if="isLoading"
      absolute
      indeterminate
      color="secondary darken-2"
    />
    <div :class="[fillHeight && 'd-flex flex-column fill-height']">
      <!-- Title -->
      <slot name="title">
        <div v-if="icon || title || titleSub" class="d-flex shrink pt-1 pb-4" :class="[titleHeight && 'hub-box-simple--title-height']">
          <div
            class="d-flex align-start flex-shrink-0 flex-grow-0"
            v-if="icon"
            style="width: 28px"
          >
            <v-icon
              color="primary lighten-3"
              size="20"
              style="margin-top: -2px"
            >
              {{ icon }}
            </v-icon>
          </div>
          <div>
            <h2 class="moin-line-height-1 text-h6 primary--text mr-2">
              {{ title }}
            </h2>
            <h3
              v-if="titleSub"
              class="
                mt-2
                moin-line-height-1
                text-body-2
                primary--text
                text--lighten-2
              "
            >
              {{ titleSub }}
            </h3>
          </div>
        </div>
      </slot>
      <!-- Content -->
      <div :class="[fillHeight && 'grow']">
        <slot name="content"></slot>
      </div>
      <!-- Footer -->
      <div>
        <slot name="footer"></slot>
      </div>
    </div>
  </v-card>
</template>
<script>
export default {
  name: 'HubBoxSimple',
  props: {
    icon: {
      type: String,
    },
    title: {
      type: String,
    },
    titleSub: {
      type: String,
    },
    boxId: {
      type: String,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    fillHeight: {
      type: Boolean,
      default: false,
    },
    titleHeight: {
      type: Boolean,
      default: false,
    },
    noBorder: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
.hub-box-simple-loading-bar {
  position: absolute;
  top: 0;
  left: 0;
}

.hub-box-simple--title-height {
  height: $spacer * 17;
}
</style>
<template>
  <div class="d-flex">
    <div v-if="icon">
      <v-icon size="20" class="mt-n2 mr-3" :color="iconColor">
        {{ icon }}
      </v-icon>
    </div>
    <div class="text-body-2 primary--text text--lighten-4" v-html="text">
    </div>
  </div>
</template>

<script>

export default {
  name: 'InfoBox',
  props: {
    text: {
      type: String,
      default: '...',
    },
    icon: {
      type: String,
      default: 'mdi-lightbulb',
    },
    iconColor: {
      type: String,
      default: 'secondary darken-2',
    }
  },
};
</script>

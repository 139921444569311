<template>
  <v-app>
    <HubBg>
      <HubPanelLogo>
        <RequestResetForm v-if="requestReset" />
        <SetNewPasswordForm v-if="setNewPassword" />
        <CompleteSSOBotInvite v-if="completeSSOBotInvite" />
        <CompleteBotInviteForm v-else-if="completeBotInvite" />
      </HubPanelLogo>
    </HubBg>
  </v-app>
</template>

<script>
import HubBg from '@/components/hub/HubBg.vue';
import HubPanelLogo from '@/components/hub/HubPanelLogo.vue';
import RequestResetForm from '@/components/password/RequestResetForm.vue';
import SetNewPasswordForm from '@/components/password/SetNewPasswordForm.vue';
import CompleteBotInviteForm from '@/components/password/CompleteBotInvite.vue';
import CompleteSSOBotInvite from '@/components/password/CompleteSSOBotInvite.vue';

export default {
  name: 'Password',
  components: {
    CompleteSSOBotInvite,
    HubBg,
    HubPanelLogo,
    RequestResetForm,
    SetNewPasswordForm,
    CompleteBotInviteForm,
  },
  computed: {
    requestReset() {
      return !this.$route.params.username && !this.$route.params.token;
    },
    setNewPassword() {
      return (
        this.$route.params.username &&
        this.$route.params.token &&
        !this.$route.params.botinvite
      );
    },
    completeSSOBotInvite() {
      return (
        this.$route.params.username &&
        this.$route.params.token &&
        this.$route.params.botinvite !== 'true'
      );
    },
    completeBotInvite() {
      return (
        this.$route.params.username &&
        this.$route.params.token &&
        this.$route.params.botinvite
      );
    },
  },
};
</script>

<style></style>

import moment from 'moment';
import { ProductService } from '../../services/product';
import { IntentService } from '../../services/intents';
import store from '../index';
import Vue from 'vue';

function getTranslation(intent, key, targetLanguage) {
  if (targetLanguage === false) {
    return intent[key];
  }

  // only if intent is not in category 'own' or origin is not 'own'
  const categoryIsOwnOrSuggestion = Array.isArray(intent.categories) && intent.categories.filter((category) => category === 'own' || category === 'suggestion').length > 0;
  const originIsNotOwn = Array.isArray(intent.origin) && intent.origin.filter((origin) => origin !== 'own').length > 0;
  if (
    targetLanguage &&
    intent.translation &&
    (!categoryIsOwnOrSuggestion || originIsNotOwn)
  ) {
    if (intent?.translation[targetLanguage][key] &&
      (!Array.isArray(intent.translation[targetLanguage][key]) || intent.translation[targetLanguage][key].length >= intent[key].length)) {
      return intent.translation[targetLanguage][key];
    }
  }

  return intent[key];
}

const state = {
  intents: false, // all faq intents
  currentIntent: false,
  botAnswer: false,
  botQuestion: false,
  smalltalks: [],
  suggested: [],
  forms: [],
  currentBotId: false,
  intentLibrary: {},
  intentsLoading: false,
  fallbackFaqIntent: null, // default FAQ Intent
};

const getters = {
  intents: (state) => {
    // return Object.keys(state.intentLibrary).map((key) => state.intentLibrary[key]);
    return state.intents;
  },
  smalltalks: (state) => {
    return state.smalltalks;
  },
  forms: (state) => {
    return state.forms;
  },
  intentsLoading: (state) => {
    return state.intentsLoading;
  },
  // gibt ein intent mit dem identifier name zurück.
  intent: (state) => (name) => {
    if (
      !state.intents ||
      !Array.isArray(state.intents) ||
      !state.smalltalks ||
      !Array.isArray(state.smalltalks) ||
      !state.suggested ||
      !Array.isArray(state.suggested)
    ) {
      return null;
    }
    return state.intents
      .concat(state.suggested)
      .concat(state.smalltalks)
      .find((i) => {
        return i.intent === name;
      });
  },
  suggested: (state) => {
    return state.suggested;
  },
  bookedIntents: (state) => {
    return state.intents
      ? state.intents.filter((i) => {
          return !i.suggested;
        })
      : [];
  },
  currentIntent: (state) => {
    return state.currentIntent;
  },
  fetchedBotId: (state) => {
    return state.currentBotId;
  },
  isStandardIntent: (state) => (name) => {
    if (state.intentLibrary[name]) {
      return state.intentLibrary[name].standard;
    }

    return false;
  },
  isOriginIntent: (state) => (name, category = 'common') => {
    if (state.intentLibrary[name]) {
      return state.intentLibrary[name].origin?.find((o) => o === category);
    }
    return false;
  },
  description: (state) => (name, language = false) => {
    if (state.intentLibrary[name]?.description) {
      return getTranslation(state.intentLibrary[name], 'description', language);
    }

    if (state.intents?.length > 0) {
      const found = state.intents.find((i) => i.intent === name);

      return found?.description || '';
    }

    if (state.smalltalks?.length > 0) {
      const found = state.intents.find((i) => i.intent === name);
      
      return found?.description || '';
    }

    if (state.suggested?.length > 0) {
      const found = state.suggested.find((i) => i.intent === name);

      return found?.description || '';
    }

    return '';
  },
  displayName: (state, getters) => (name, language = false) => {
    if ( getters.fallbackFaqIntentEnabled) {
      if (state.fallbackFaqIntent?.intent && state.fallbackFaqIntent.intent === name) {
        return 'Knowledge Base';
      }

      if (state.fallbackFaqIntent === name) {
        return 'Knowledge Base';
      }
    }
    // use intent library for display name
    if (state.intentLibrary[name]?.displayName) {
      return getTranslation(state.intentLibrary[name], 'displayName', language);
    }

    if (state.intents?.length > 0) {
      const found = state.intents.find((i) => i.intent === name);

      if (found) {
        return found.displayName || found.intent;
      }
    }

    if (state.smalltalks?.length > 0) {
      const found = state.intents.find((i) => i.intent === name);

      if (found) {
        return found.displayName || found.intent;
      }
    }

    if (state.suggested?.length > 0) {
      const found = state.suggested.find((i) => i.intent === name);

      if (found) {
        return found.displayName || found.intent;
      }
    }

    if (state.forms?.length > 0) {
      const found = state.forms.find((i) => i.name === name);

      if (found) {
        return found.displayName || found.name;
      }
    }

    return name;
  },
  samples: (state) => (name, language = false) => {
    if (state.intentLibrary[name] && Array.isArray(state.intentLibrary[name].samples)) {
      return getTranslation(state.intentLibrary[name], 'samples', language);
    }

    return [];
  },
  isAutoTranslated: (state, getters) => (name, language = false) => {
    if (!state.intentLibrary[name]?.description) {
      return false;
    }
    const descriptionDe = getters.description(name, 'de');
    const descriptionEn = getters.description(name, 'en');

    const descriptionOriginial = getters.description(name, null);

    if (language === 'en' && descriptionEn !== descriptionOriginial) {
      return true;
    }
    if (language === 'de' && descriptionDe !== descriptionOriginial) {
      return true;
    }
    return false;
  },
  getIntentLibraryMap: (state) => {
    return state.intentLibrary;
  },
  getIntentsFromLibrary: (state) => {
    return Object.entries(state.intentLibrary).map((value) => {
      return value[1];
    });
  },
  getIntentsFromLibraryAndExclude: (state) => (categories, standard = true) => {
    const intents = Object.entries(state.intentLibrary)
      .map((value) => {
        return value[1];
      })
      .filter((intent) => {
        if (standard && !intent.standard) return false;
        if (Array.isArray(categories) && categories.length > 1) {
          if (!intent.categories) return true;
          return !intent.categories.find((category) => {
            return categories.includes(category);
          });
        } else {
          return (
            !intent.categories ||
            !intent.categories.find((cat) => {
              return cat === categories;
            })
          );
        }
      });

    intents.sort((a, b) => {
      const aDisplay = (a.displayName || a.intent).trim();
      const bDisplay = (b.displayName || b.intent).trim();

      return aDisplay.localeCompare(bDisplay);
    });
    return intents;
  },
  getIntentsFromLibraryByCategory: (state) => (category, standard = true) => {
    const intents = Object.entries(state.intentLibrary)
      .map((value) => {
        return value[1];
      })
      .filter((intent) => {
        if (standard && !intent.standard) return false;
        return (
          intent.categories &&
          intent.categories.find((cat) => {
            return cat === category;
          })
        );
      });

    intents.sort((a, b) => {
      const aDisplay = (a.displayName || a.intent).trim();
      const bDisplay = (b.displayName || b.intent).trim();

      return aDisplay.localeCompare(bDisplay);
    });

    return intents;
  },
  getIntentFromLibraryByName: (state) => (name) => {
    if (!state.intentLibrary[name]) {
      return {
        displayName: name,
        intent: name,
        description: '',
        categories: [],
      };
    }
    return state.intentLibrary[name];
  },
  fallbackFaqIntent: (state) => {
    return state.fallbackFaqIntent;
  },
  /**
   * Check if the default FAQ Intent is enabled
   * @returns {boolean}
   */
  fallbackFaqIntentEnabled: (state, _, __, rootGetters) => {
    if (!state.fallbackFaqIntent) return false;

    const faq = rootGetters['bots/currentBot']?.fallbackFaqIntent;
    if (!faq) return false;
    if (typeof faq === 'string') return true;

    return faq.active;
  }
};

const actions = {
  // add new topic
  async add(
    { commit },
    { botId, samples, displayName, description, suggested, forward }
  ) {
    const result = await ProductService.addIntent(
      botId,
      samples,
      displayName,
      description,
      suggested,
      forward
    );

    if (!result.error && result.intent) {
      commit('addIntent', result.intent);
    }
    return result;
  },
  // convert suggestion to real topic
  async addSuggestion({ commit, getters }, { botId, intent }) {
    const result = await ProductService.changeIntentSuggestion(botId, intent);
    if (!result.error) {
      await new Promise((resolve) => setTimeout(resolve, 500));
      const faqIntent = getters.suggested.find((i) => {
        return i.intent === intent;
      });
      faqIntent.suggested = false;

      commit('addIntent', faqIntent);
      commit('removeSuggested', faqIntent);
      return true;
    } else {
      return false;
    }
  },
  // DEPRECATED use addIntentFromSource instead
  async addSmalltalkFromStandard({ commit }, { botId, intent }) {
    // ProductService add smalltalk from template
    const result = await ProductService.addSmalltalk(botId, intent);
    if (result && result.intent) {
      commit('addIntent', result.intent);
    }

    return false;
  },
  async addIntentFromSource({ commit }, { botId, intent, sourceBot }) {
    const result = await ProductService.addIntentFromSource(botId, intent, sourceBot);
    if (result && result.intent) {
      commit('addIntent', result.intent);

      return result.intent;
    }

    return false;
  },
  async deleteIntent({ commit }, { botId, intent }) {
    const result = await ProductService.deleteIntent(botId, intent);
    if (result.status === 'ok') {
      commit('removeIntent', { intent });
      commit('removeSuggested', { intent });
    }
  },
  async get({ commit, state, dispatch, rootGetters }, uniqueBotId) {
    commit('setIntentsLoading', true);

    // Get bot
    let bot = rootGetters['bots/getBot'](uniqueBotId);
    const generalFaqCallbackName = bot?.fallbackFaqIntent && typeof bot.fallbackFaqIntent === 'string'
     ? bot.fallbackFaqIntent
     : bot?.fallbackFaqIntent?.intent

    let intents = await ProductService.getIntents(uniqueBotId);
    const formResult = await ProductService.getForms(uniqueBotId);
    const allForms = await ProductService.getAllForms(uniqueBotId);

    // In case we need to use that later:
    if (generalFaqCallbackName) {
      state.fallbackFaqIntent = intents.find((i) => i.intent === generalFaqCallbackName) || null;
       // Remove "General FAQ Topic" Intent from all intents
      intents = intents.filter((i) => i.intent !== generalFaqCallbackName);
    } else {
      state.fallbackFaqIntent = null;
    } 

    state.currentBotId = uniqueBotId;

    const onlyIntents = Array.isArray(intents)
      ? intents.filter((i) => {
          return !i.intent.startsWith('smalltalk_') && !i.suggested;
        })
      : [];

    onlyIntents.sort((a, b) => {
      if (a.displayName.toLowerCase() < b.displayName.toLowerCase()) {
        return -1;
      }

      if (a.displayName.toLowerCase() > b.displayName.toLowerCase()) {
        return 1;
      }
      return 0;
    });

    const suggestedIntents = Array.isArray(intents)
      ? intents.filter((i) => {
          return !i.intent.startsWith('smalltalk_') && i.suggested;
        })
      : [];

    suggestedIntents.sort((a, b) => {
      if (a.displayName.toLowerCase() < b.displayName.toLowerCase()) {
        return -1;
      }

      if (a.displayName.toLowerCase() > b.displayName.toLowerCase()) {
        return 1;
      }
      return 0;
    });

    const onlySmalltalks = Array.isArray(intents)
      ? intents.filter((i) => {
          return i.intent.startsWith('smalltalk_');
        })
      : [];

    // add to library
    commit('setLibrary', { intents: onlyIntents.slice(), category: 'own' }); // add intents to library
    commit('setLibrary', {
      intents: suggestedIntents.slice(),
      category: 'suggestion',
    }); // add suggestions to library
    commit('setLibrary', {
      intents: onlySmalltalks.slice(),
      category: 'smalltalks',
    }); // add suggestions to library
    await dispatch('getStandard', { botId : uniqueBotId }); // add standard intent to library

    commit('setIntents', onlyIntents);
    commit('setSmalltalks', onlySmalltalks);
    commit('setSuggested', suggestedIntents);

    // set forms and merge results
    const forms = [];
    if (allForms?.forms) {
      allForms.forms.forEach((form) => {
        form.name = form.intent;
        forms.push(form);
      });
    }

    if (formResult && formResult.status === 'ok' && formResult.forms) {
      formResult.forms.forEach((form) => {
        if (!forms.find((f) => f.name === form.name)) {
          forms.push(form);
        }
      });
      commit('setForms', forms);
    }

    commit('setIntentsLoading', false);
    return true;
  },
  async updateIntent({ state }, name) {
    const intent = state.intents.concat(state.suggested).find((intent) => {
      return intent.intent === name;
    });

    if (intent) {
      await ProductService.updateIntent(intent);
      return true;
    } else {
      return false;
    }
  },
  setCurrentIntent({ commit, state }, intent) {
    const currentIntent = state.intents ? state.intents?.find((i) => i.intent === intent) : null;

    if (currentIntent) {
      commit('setCurrentIntent', currentIntent);
      return;
    }

    const smalltalkIntent = state.smalltalks?.find((i) => i.intent === intent);
    if (smalltalkIntent) {
      commit('setCurrentIntent', smalltalkIntent);
      return;
    }

  },
  clear({ commit }) {
    commit('setIntents', false);
    commit('setCurrentIntent', false);
    commit('setSmalltalks', []);
    commit('setSuggested', []);
    commit('setForms', []);
    commit('clearLibrary');
  },
  // fetch standard and smalltalk sets
  async getStandard({ commit, rootGetters }, { botId }) {
    
    const [standards, smalltalks, templates] = await Promise.all([
      IntentService.getDefaults('standard', botId),
      IntentService.getDefaults('smalltalks', botId),
      rootGetters['bots/getNLPTemplate'] 
        ? IntentService.getDefaults('template', botId)
        : Promise.resolve(null)
    ]);

    
    if (
      standards &&
      Array.isArray(standards.intents)
    ) {
      commit('setLibrary', {
        intents: standards.intents.map((intent) => {
          intent.standard = true;
          return intent;
        }),
        category: 'common',
      });
    }
   
    if (
      smalltalks &&
      Array.isArray(smalltalks.intents)
    ) {
      commit('setLibrary', {
        intents: smalltalks.intents.map((intent) => {
          intent.standard = true;
          return intent;
        }),
        category: 'smalltalks',
      });
    }

    let nlpTemplate = rootGetters['bots/getNLPTemplate'];
    if (nlpTemplate && templates && Array.isArray(templates.intents)) {
      
      commit('setLibrary', {
        intents: templates.intents.map((intent) => {
          intent.standard = true;
          return intent;
        }),
        category: nlpTemplate.botId,
      });
    }
  },
  async fetchIntent({commit}, intent ) {
    const result = await ProductService.getIntentDetails(
      intent.botId,
      intent.template,
      intent.intent
    );


    if (!result.state?.channels) return;
    
    commit('setIntentState', {
      intent: intent,
      status: result.state.channels,
    });
  },
  async saveK2Names({}, payload) {
    console.log('saveK2Names', payload);
  }
};

const mutations = {
  setForms(state, forms) {
    state.forms = forms;
  },
  setIntents(state, intents) {
    state.intents = intents;
  },
  removeSuggested(state, intent) {
    const index = state.suggested.findIndex((i) => {
      return i.intent === intent.intent;
    });

    if (index === -1) return;

    state.suggested.splice(index, 1);
  },
  removeIntent(state, intent) {
    const index = state.intents.findIndex((i) => {
      return i.intent === intent.intent;
    });

    if (index === -1) return;

    state.intents.splice(index, 1);
  },
  addIntent(state, intent) {
    if (!state.intents && !Array.isArray(state.intents)) {
      return;
    }
    if (intent.template === 'Smalltalks') {
      state.smalltalks.push(intent);
    } else {
      state.intents.push(intent);
    }
    state.intents.sort((a, b) => {
      if (a.displayName.toLowerCase() < b.displayName.toLowerCase()) {
        return -1;
      }

      if (a.displayName.toLowerCase() > b.displayName.toLowerCase()) {
        return 1;
      }

      return 0;
    });

    if (
      state.intentLibrary[intent.intent] &&
      state.intentLibrary[intent.intent].standard
    ) {
      state.intentLibrary[intent.intent].standard = false;
    }
  },
  setIntent(state, intent) {
    if (!intent || !intent.intent || !Array.isArray(state.intents)) {
      return;
    }

    const index = state.intents.findIndex((i) => {
      return i.intent === intent.intent;
    });

    if (index === -1) return;

    state.intents.splice(index, 1, intent);
    const libraryIntent = state.intentLibrary[intent.intent];
    if (libraryIntent) {
      state.intentLibrary[intent.intent] = {
        ...libraryIntent,
        ...intent,
      };
    }
  },
  setIntentState(state, { intent, status }) {
    if (state.intentLibrary[intent.intent]) {
      Vue.set(state.intentLibrary[intent.intent], 'state', status);
    }
  },
  setSmalltalks(state, smalltalks) {
    state.smalltalks = smalltalks;
  },
  setSuggested(state, suggested) {
    state.suggested = suggested;
  },
  setIntentsLoading(state, loading) {
    state.intentsLoading = loading;
  },
  setCurrentIntent(state, current) {
    state.currentIntent = current;
  },
  clearLibrary(state) {
    state.intentLibrary = {};
  },
  setLibrary(state, { intents, category }) {
    for (const intent of intents) {
      if (category) {
        if (!intent.categories) {
          intent.categories = [];
        }
        intent.categories.push(category);
      }

      if (!state.intentLibrary[intent.intent]) {
        state.intentLibrary = {
          ...state.intentLibrary,
          [intent.intent]: intent,
        };
      } else {
        state.intentLibrary[intent.intent].origin = intent.categories;
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

// This component is for positive and negative ratings
<template>
  <HubBoxSimple :icon="''" :title="''" fillHeight>
    <template v-slot:content>
      <div>
        <IconNumberTeaser
          :title="
            positive
              ? $t('stats.feedbackPositive')
              : $t('stats.feedbackNegative')
          "
          :icon="positive ? 'thumb_up' : 'thumb_down'"
          :oldValue="sumOld"
          :newValue="sumNew"
          :loading="isLoading"
          :compareDisabled="
            !dateRangeCompare ? $t('dateRange.selectComparePeriod') : ''
          "
          titleHeading
          :lessIsBetter="!positive"
        />
      </div>
      <div class="relative my-4 mx-n4">
        <v-divider />
        <v-progress-linear
          v-if="isLoading"
          indeterminate
          style="position: absolute; top: 0; left: 0; width: 100%;"
          color="secondary darken-2"
        ></v-progress-linear>
      </div>
      <StatsChart
        :isLoading="isLoading"
        :seriesData="seriesData"
        :seriesDataCompare="seriesDataCompare"
        :colorSchema="chartColorSchema"
        :dateRange="dateRange"
        :dateRangeCompare="dateRangeCompare"
        :max="max"
        :timeFormat="timeFormat"
        type="bar"
        fixedNumbers
        opposite
        :height="height"
      />
    </template>
    <!-- <template v-slot:footer>
      <v-btn
        class="mt-8"
        small
        outlined
        color="primary"
        :href="`statistics/${statsTypes.ratings}`"
      >
        {{ $t('stats.reports.title') }}
        <v-icon right>query_stats</v-icon>
      </v-btn>
    </template> -->
  </HubBoxSimple>
</template>
<script>
import { mapGetters } from 'vuex';
import HubBoxSimple from '@/components/hub/HubBoxSimple.vue';
import { statsTypes } from '@/components/stats/statsTypes';
import IconNumberTeaser from '@/components/stats/components/IconNumberTeaser.vue';
import StatsService from '@/services/stats';
import StatsChart from '@/components/stats/components/StatsChart.vue';
import { parseSeriesFeedback } from '@/utils/stats';

export default {
  name: 'StatsChartFeedback',
  components: {
    HubBoxSimple,
    IconNumberTeaser,
    StatsChart,
  },
  props: {
    positive: {
      type: Boolean,
    },
    negative: {
      type: Boolean,
    },
    height: {
      type: String,
    },
  },
  data() {
    return {
      statsTypes,
      isLoading: true,
      isFailed: false,
      sumOld: 0,
      sumNew: 0,
      seriesData: [],
      seriesDataCompare: null,
      chartColorSchema: this.positive ? 'green' : 'red',
      max: null,
      timeFormat: 'day',
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...mapGetters('auth', ['user']),
    ...mapGetters('bots', ['viewableBotId']),
    ...mapGetters('stats', ['dateRange', 'dateRangeCompare', 'channel']),
  },
  watch: {
    dateRange() {
      this.init();
    },
    dateRangeCompare() {
      this.init();
    },
    viewableBotId() {
      this.init();
    },
    channel() {
      this.init();
    },
  },
  methods: {
    async init() {
      this.max = 0;
      if (this.dateRange) {
        await this.fetchData();
      }
      if (this.dateRangeCompare) {
        await this.fetchDataCompare();
      } else {
        this.seriesDataCompare = null;
      }
    },
    async fetchData() {
      this.isLoading = true;
      const {
        posCount,
        negCount,
        series,
        timeFormat,
      } = await StatsService.getFeedbackSeries(
        this.viewableBotId,
        this.dateRange,
        this.channel
      );

      if (!Array.isArray(series)) return;

      this.sumNew = this.positive ? posCount : negCount;
      this.calcMax(series);
      this.seriesData = parseSeriesFeedback({
        series,
        isPositive: this.positive,
        timeFormat,
        language: this.user.language,
      });
      this.timeFormat = timeFormat;
      this.isLoading = false;
    },
    async fetchDataCompare() {
      this.isLoading = true;
      const {
        posCount,
        negCount,
        series,
        timeFormat,
      } = await StatsService.getFeedbackSeries(
        this.viewableBotId,
        this.dateRangeCompare,
        this.channel
      );

      if (!Array.isArray(series)) return;

      this.sumOld = this.positive ? posCount : negCount;
      this.calcMax(series);
      this.seriesDataCompare = parseSeriesFeedback({
        series,
        isPositive: this.positive,
        timeFormat,
        language: this.user.language,
      });

      this.isLoading = false;
      return true;
    },
    calcMax(series) {
      let maxVal = this.max;

      if (!series.length) {
        this.max = 1;
        return;
      }

      const posValues = series.map((s) => s?.pos || 0);
      const mPos = Math.max(...posValues);
      if (mPos > maxVal) maxVal = mPos;

      const negValues = series.map((s) => s?.neg || 0);
      const mNeg = Math.max(...negValues);
      if (mNeg > maxVal) maxVal = mNeg;

      if (maxVal <= 10) {
        this.max = maxVal;
        return;
      }

      const remaind = maxVal % 10;
      maxVal += 10 - remaind;
      this.max = maxVal;
    },
  },
};
</script>

<template>
  <div>
    <v-skeleton-loader
      v-if="loading"
      class="relative"
      type="image"
      width="100%"
      :height="80"
    />
    <div v-else class="action-user-row">
      <div :class="{ 'pa-2 action-user-row-box': !readMode }">
        <div>
          <slot name="title" />
        </div>
        <div class="d-flex justify-space-between max-width-full">
          <div class="flex-grow-1 max-width-full">
            <slot name="left" />
          </div>
          <div>
            <slot name="right" />
          </div>
        </div>
        <div>
          <slot name="content" />
        </div>
      </div>
    </div>
    <div v-if="!noLine && !readMode" style="height: 20px">
      <ActionLine />
    </div>
    <ActionGapSeparator v-if="readMode" :double-height="true" />
  </div>
</template>
<script>
import ActionLine from '@/components/editor/components/ActionLine.vue';
import ActionGapSeparator from '@/components/editor/components/ActionGapSeparator.vue';

export default {
  name: 'ActionUserRow',
  components: {
    ActionGapSeparator,
    ActionLine,
  },
  props: {
    noLine: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    readMode: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style scoped lang="scss">
.action-user-row-box {
  background: $moin-color-grey-lighter;
  border-radius: 4px;
}
</style>

import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import auth from './modules/auth'; // state for authentication
import bots from './modules/bots';
import behaviour from './modules/behaviour';
import integrations from './modules/integrations';
import intents from './modules/intents';
import intentsList from './modules/intentsList';
import intentNew from './modules/intentNew';
import dashboard from './modules/dashboard';
import dialogs from './modules/dialogs';
import stats from './modules/stats';
import channelsettings from './modules/channelSettings';
import content from './modules/content';
import trainingdata from './modules/trainingdata';
import VuexPersistence from 'vuex-persist';
import ChannelPlugin from '../plugins/channels';
import contentQueue from './modules/contentQueue';
import contentStore from './modules/contentStore';
import formsEditor from './modules/formsEditor';
import conversational from './modules/conversational';
import forms from './modules/forms';
import search from './modules/search';
import preview from './modules/preview';
import welcome from './modules/welcome';
import copyPaste from './modules/copyPaste';
import hubBoxExpand from './modules/hubBoxExpand';
import exportStats from './modules/exportStats';
import notifications from './modules/notifications';
import channels from './modules/channels';
import qm from './modules/qm';
import teasers from './modules/teasers';
import dateRange from './modules/dateRange';
import livechat from './modules/livechat';
import demo from './modules/demo';
import assistant from './modules/assistant';
import notificationNative from './modules/notificationNative';
import audio from './modules/audio';
import hubUi from './modules/hubUi';
import preferences from "./modules/preferences";
import { messageTypes, styleTypes } from '@/utils/notifications';
import OsUtil from '@/utils/os';
import { UserService } from '@/services/user';
import clipboard from './modules/clipboard';
import componentData from './modules/componentData';
import { changelog } from './modules';
import livechatManagement from './modules/livechatManagement';
import productAdvisor from './modules/productAdvisor';
import knowledgeBase from './modules/knowledgeBase';

Vue.use(Vuex);
Vue.use(ChannelPlugin);

const vuexSession = new VuexPersistence({
  storage: window.sessionStorage,
  reducer: (state) => ({ 
    bots: state.bots,
    behaviour: state.behaviour,
    livechat: state.livechat,
    clipboard: state.clipboard,
    integrations: state.integrations
  }),
});

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => ({
    assistant: state.assistant,
    auth: state.auth,
    content: state.content,
    dateRange: state.dateRange,
    hubBoxExpand: state.hubBoxExpand,
    intentNew: state.intentNew,
    intentsList: state.intentsList,
    livechat: state.livechat,
    notifications: state.notifications,
    preferences: state.preferences,
    search: state.search,
    knowledgeBase: {
      // Only the drafts
      resourceDrafts: state.knowledgeBase?.resourceDrafts ?? {},
      markdownDrafts: state.knowledgeBase?.markdownDrafts ?? {},
    },
  }),
});

export default new Vuex.Store({
  state: {
    navigationDrawer: null, // state of the navigation drawer
    connectionLost: null,
    globalSnackbar: null,
    appVersion: process.env.APP_VERSION || '1.0.0',
    originAppVersion: false,
  },
  mutations: {
    setConnectionLost(currentState, lost) {
      currentState.connectionLost = lost;
    },
    addSnackbarMessage(state, message) {
      state.globalSnackbar = message;
    },
    setOriginAppVersion(state, version) {
      state.originAppVersion = version;
    },
  },
  getters: {
    globalSnackbar: (state) => {
      return state.globalSnackbar;
    },
    connectionLost: (state) => {
      return state.connectionLost;
    },
    appVersion: (state) => {
      return state.appVersion;
    },
    appVersionUptodate: (state) => {
      if (state.originAppVersion) {
        return state.originAppVersion === state.appVersion;
      }

      return true;
    },
  },
  actions: {
    setNavigationDrawer({ commit }, { show }) {
      commit('setNavigationDrawer', show);
    },
    async connectionLost({ commit, dispatch }) {

      // Double check if connection is really lost
      const {data} = await UserService.getUser();

      if (data) {
        commit('setConnectionLost', false);
        return;
      }

      commit('setConnectionLost', true);

      const name = 'app-connection-lost';

      const notification = {
        name,
        messageType: messageTypes.NO_CONNECTION,
        styleType: styleTypes.ERROR,
        action: function() {
          setTimeout(() => {
            window.location.reload(true);
          }, 500);
        },
        actionSecondary: () => {
          commit('notifications/removeNotification', name);
        },
      };

      dispatch('notifications/addNotification', notification);
    },
    checkVersion({ commit, getters, dispatch }) {
      const currentTimestamp = new Date().getTime();
      axios
        .get(
          `${process.env.VUE_APP_HUB_URL}/version.txt?ts=${currentTimestamp}`
        )
        .then(function(response) {
          commit('setOriginAppVersion', response.data);

          const queryString = window.location.search;
          const urlParams = new URLSearchParams(queryString);
          const isSecondTry = urlParams.has('newVersion');

          if (!getters['appVersionUptodate']) {
            dispatch(
              'notifications/addNotification',
              {
                name: 'new-version',
                messageType: !isSecondTry
                  ? messageTypes.NEW_VERSION
                  : OsUtil.getOs() === 'windows'
                    ? messageTypes.NEW_VERSION_PC
                    : messageTypes.NEW_VERSION_MAC,
                styleType: !isSecondTry ? styleTypes.WARNING : styleTypes.ERROR,
                action: () => {
                  window.location = `${window.location.origin}${window.location.pathname}?newVersion=true&ts=${currentTimestamp}`;
                  // reload current page
                  setTimeout(() => {
                    window.location.reload(true);
                  }, 500);
                },
              }
            );
          }
        })
        .catch(() => {
          // do nothing
        });
    },
  },
  plugins: [vuexLocal.plugin, vuexSession.plugin],
  modules: {
    auth,
    bots,
    dashboard,
    integrations,
    intents,
    intentsList,
    intentNew,
    dialogs,
    glimpseContent: content,
    channelsettings,
    conversational,
    trainingdata,
    stats,
    behaviour,
    contentQueue,
    content: contentStore,
    changelog,
    formsEditor,
    forms,
    search,
    preview,
    welcome,
    copyPaste,
    hubBoxExpand,
    exportStats,
    notifications,
    channels,
    qm,
    teasers,
    clipboard,
    dateRange,
    livechat,
    livechatManagement,
    demo,
    assistant,
    audio,
    notificationNative,
    hubUi,
    preferences,
    componentData,
    productAdvisor,
    knowledgeBase,
  },
});

<template>
  <div v-if="quota && false">
    <div class="moin-admin-box" style="display: inline-block">
      <v-btn depressed color="admin" @click="dialogIsOpen = true" disabled>
        {{ $t('common.add') }}
        <v-icon right>mdi-playlist-plus</v-icon>
      </v-btn>
    </div>
    <HubDialog
      :value="dialogIsOpen"
      :title="$t('channelManagement.createDialog.title')"
      asyncDialog
      primaryIcon="save"
      :primaryLabel="$t('channelManagement.createDialog.btnSubmit')"
      :secondaryLabel="$t('common.cancel')"
      @onSecondary="dialogIsOpen = false"
      @onPrimary="createNewTeaser"
      :isLoading="isLoading"
      :disabled="isDisabled"
      modal
    >
      <div class="text-body-1">
        {{ $t('channelManagement.createDialog.description') }}
      </div>
      <v-text-field
        v-model="displayNameInput"
        class="mt-8"
        :label="$t('channelManagement.createDialog.nameLabel')"
        type="text"
      />

      <div class="mb-6">
        <v-select
          outlined
          :label="$t('channelManagement.createDialog.typeLabel')"
          :items="typeOptions"
          v-model="selectedType"
          item-text="text"
          item-value="value"
          dense
          hide-details
        />
      </div>
      <div class="mb-6">
        <v-select
          outlined
          :label="$t('common.language')"
          :items="langOptions"
          v-model="selectedLang"
          item-text="text"
          item-value="value"
          dense
          hide-details
        />
      </div>
    </HubDialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import HubDialog from '@/components/hub/HubDialog.vue';
export default {
  name: 'ChannelsNewChannel',
  components: {
    HubDialog,
  },
  data() {
    return {
      dialogIsOpen: false,
      isLoading: false,
      displayNameInput: '',
      selectedType: null,
      selectedLang: null,
    };
  },
  computed: {
    ...mapGetters('bots', [
      'currentBotId',
      'currentChannels',
      'getBotSettings',
    ]),
    isDisabled() {
      if (
        this.displayNameInput.length < 3 ||
        !this.selectedType ||
        !this.selectedLang
      ) {
        return true;
      }

      return false;
    },
    typeOptions() {
      return [
        {
          value: 'widget',
          text: this.$t('channelManagement.types.widget'),
        },
        {
          value: 'facebook',
          text: this.$t('channelManagement.types.facebook'),
        },
        {
          value: 'userlike',
          text: this.$t('channelManagement.types.userlike'),
        },
      ];
    },
    quota() {
      const { quota } = this.getBotSettings(this.currentBotId);
      return quota;
    },
    langOptions() {
      if (!this.quota?.languages) return;
      const languages = this.$t('languages');

      const items = this.quota.languages.map((code) => ({
        value: code,
        text: languages[code],
      }));
      return items;
    },
  },
  methods: {
    async createNewTeaser() {
      this.isLoading = true;

      const newChannel = {
        displayName: this.displayNameInput,
        type: this.selectedType,
        language: this.selectedLang,
      };

      await this.$store.dispatch('channels/createChannel', newChannel);

      this.displayNameInput = '';
      this.isLoading = false;
      this.dialogIsOpen = false;
    },
  },
};
</script>

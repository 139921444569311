import { StatsService } from '@/services/stats';
import moment from 'moment';
import {
  createCSVDownload,
  getNameWithDateRange,
  createDownloadWithCSV,
} from '@/utils/exportStats';

const state = {
  exportsPerformance: [],
  exportsAnswers: [],
  isExporting: false,
  isPerformanceDone: false,
  isAnswersDone: false,
};

const getters = {
  exportsPerformance: (state) => state.exportsPerformance,
  exportsAnswers: (state) => state.exportsAnswers,
  isExporting: (state) => state.isExporting,
  isPerformanceDone: (state) => state.isPerformanceDone,
  isAnswersDone: (state) => state.isAnswersDone,
};

const actions = {
  async startPerformanceExport({ commit, rootGetters }) {
    const botId = rootGetters['bots/currentBotId'];
    const dateRange = rootGetters['stats/dateRange'];
    const channel = rootGetters['stats/channel'];

    commit('setValue', { key: 'isExporting', value: true });
    commit('setExportsPerformance', { exports: [] });

    // get for each day in dateRange [startDate, endDate] a moment date range
    let dateRanges = [];
    let startDate = dateRange[0];
    let endDate = dateRange[1];
    let days = endDate.diff(startDate, 'days');
    for (let i = 0; i <= days; i++) {
      dateRanges.push(moment(startDate).add(i, 'days'));
    }

    for (let date of dateRanges) {
      const exportConversations = await StatsService.getKpi(
        botId,
        'conversations',
        [date, date],
        channel
      );
      const exportAutomation = await StatsService.getKpi(
        botId,
        'automation',
        [date, date],
        channel
      );
      const exportFeedbacksResult = await StatsService.getFeedbacks(
        botId,
        [date, date],
        channel
      );
      const exportPositiveFeedback = {
        positive: exportFeedbacksResult.find(
          (feedback) => feedback.type === 'pos'
        )?.count,
      };
      const exportNegativeFeedback = {
        negative: exportFeedbacksResult.find(
          (feedback) => feedback.type === 'neg'
        )?.count,
      };
      let exports = {};
      exports.date = date.format('YYYY-MM-DD');
      exports = {
        ...exports,
        ...exportConversations,
        ...exportAutomation,
        ...exportPositiveFeedback,
        ...exportNegativeFeedback,
      };
      commit('addExportsPerformance', { exports });
    }
    commit('setValue', { key: 'isExporting', value: false });
    commit('setValue', { key: 'isPerformanceDone', value: true });

    return true;
  },
  async startAnswersExport({ commit, rootGetters }) {
    const botId = rootGetters['bots/currentBotId'];
    const dateRange = rootGetters['stats/dateRange'];
    const intents = rootGetters['intents/intents'];
    const channel = rootGetters['stats/channel'];
    commit('setValue', { key: 'isExporting', value: true });
    commit('setExportsAnswers', { exports: [] });

    // get for each day in dateRange [startDate, endDate] a moment date range
    let dateRanges = [];
    let startDate = dateRange[0];
    let endDate = dateRange[1];
    let days = endDate.diff(startDate, 'days');
    for (let i = 0; i <= days; i++) {
      dateRanges.push(moment(startDate).add(i, 'days'));
    }
    // array of intents to json map where key is the intent name and value is the intent id
    let intentsMap = {};
    intents.forEach((intent) => {
      intentsMap[intent.intent] = intent.displayName;
    });

    // get all intents for each day
    for (let date of dateRanges) {
      let answers = await StatsService.getAnswers(botId, [date, date], channel);
      for (let answer of answers.answers) {
        if (!intentsMap[answer.name]) continue;
        let exports = {};
        exports.date = date.format('YYYY-MM-DD');
        exports.intent = intentsMap[answer.name];
        exports.count = answer.count;
        exports.positive = answer.pos;
        exports.negative = answer.neg;
        commit('addExportsAnswers', { exports });
      }
    }

    commit('setValue', { key: 'isExporting', value: false });
    commit('setValue', { key: 'isAnswersDone', value: true });

    return true;
  },
  async downloadCSVPerformance({ state, rootGetters }) {
    const csvPerformance = createCSVDownload(state.exportsPerformance);
    const dateRange = rootGetters['stats/dateRange'];
    let documentName = getNameWithDateRange(dateRange);
    documentName += '-performance';
    const anchor = createDownloadWithCSV(csvPerformance, documentName);
    anchor.click();
  },
  async downloadCSVAnswers({ state, rootGetters }) {
    const csvAnswers = createCSVDownload(state.exportsAnswers);
    const dateRange = rootGetters['stats/dateRange'];
    let documentName = getNameWithDateRange(dateRange);
    documentName += '-answers';
    const anchor = createDownloadWithCSV(csvAnswers, documentName);
    anchor.click();
  },
  clear({ commit }) {
    commit('setExportsPerformance', { exports: [] });
    commit('setExportsAnswers', { exports: [] });
    commit('setValue', { key: 'isPerformanceDone', value: false });
    commit('setValue', { key: 'isAnswersDone', value: false });
  },
};

const mutations = {
  setValue(state, { key, value }) {
    state[key] = value;
  },
  setExportsPerformance(state, { exports }) {
    state.exportsPerformance = exports;
  },
  addExportsPerformance(state, { exports }) {
    state.exportsPerformance.push(exports);
  },
  setExportsAnswers(state, { exports }) {
    state.exportsAnswers = exports;
  },
  addExportsAnswers(state, { exports }) {
    state.exportsAnswers.push(exports);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

<template>
  <div>
    <HubBox
      :title="'Status'"
      :titleSub="statusTitle"
      :icon="user.active ? 'mdi-account-check' : 'mdi-send-check'"
      expandedInitial
    >
      <v-divider class="mb-4" />
      <div class="mb-4" v-if="!user.active">
        <div class="text-body-2">
          {{ $t('botSettings.userStatus.validUntil') }}
        </div>
        <div class="text-body-1">{{ dateMaxValid }}</div>
      </div>

      <div class="mb-4" v-if="user.active">
        <div class="text-body-2">
          {{ $t('botSettings.userStatus.userCreated') }}
        </div>
        <div class="text-body-1">{{ dateCreated }}</div>
      </div>

      <v-divider class="my-4" />

      <div class="mb-4">
        <div class="text-body-2">{{ $t('botSettings.userStatus.status') }}</div>
        <div
          class="text-body-1"
          :class="[
            user.active ? 'green--text' : 'primary--text text--lighten-3',
          ]"
        >
          <strong>{{ statusTitle }}</strong>
          <template v-if="!user.active">
            <br />
            <span>{{ statusTitleSub }}</span>
          </template>
        </div>
      </div>
      <v-btn
        v-if="!user.active"
        class="mt-6"
        outlined
        color="primary lighten-2"
        @click="dialogIsOpen = true"
      >
        {{ 'erneut einladen' }}
        <v-icon right>mdi-send</v-icon>
      </v-btn>
    </HubBox>
    <HubDialog
      :value="dialogIsOpen"
      title="Einladung-Status"
      modal
      asyncDialog
      primaryIcon="mdi-send"
      :primaryLabel="$t('botSettings.userStatus.inviteBtn')"
      @onSecondary="dialogIsOpen = false"
      @onPrimary="sendAgain"
      :isLoading="isLoading"
    >
      <div class="py-4">
        <div v-if="!user.active" class="text-body-1">
          {{ $t('botSettings.userStatus.pendingSubLong', {userMail: user.username}) }}
        </div>
      </div>
      <InfoBox :text="$t('botSettings.userStatus.inviteHint')" />
    </HubDialog>
  </div>
</template>
<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import HubDialog from '@/components/hub/HubDialog.vue';
import InfoBox from '@/components/common/InfoBox.vue';
import HubBox from '@/components/hub/HubBox.vue';
import UserService from '@/services/user';

const dateFormat = 'DD.MM.YYYY, HH:mm';

export default {
  name: 'SettingsUserStatus',
  components: {
    HubDialog,
    InfoBox,
    HubBox,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialogIsOpen: false,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters('bots', ['currentBotId']),
    isChanged() {
      return this.switchActivatedInitial !== this.switchActivated;
    },
    isExpired() {
      return moment().isSameOrAfter(this.user.maxDate);
    },
    dateCreated() {
      if (!this.user?.created) return '–';
      return moment(this.user.created).format(dateFormat) + ' h';
    },
    dateMaxValid() {
      if (!this.user?.maxDate) return '–';
      return moment(this.user.maxDate).format(dateFormat) + ' h';
    },
    statusTitle() {
      if (this.user.active) return this.$t('botSettings.userStatus.active');
      if (!this.user.active && !this.isExpired) {
        return this.$t('botSettings.userStatus.pending');
      }
      if (!this.user.active && this.isExpired) {
        return this.$t('botSettings.userStatus.expired');
      }
      return null;
    },
    statusTitleSub() {
      if (!this.user.active && !this.isExpired) {
        return this.$t('botSettings.userStatus.pendingSub');
      }
      if (!this.user.active && this.isExpired) {
        return this.$t('botSettings.userStatus.expiredSub');
      }
      return null;
    },
  },
  methods: {
    async sendAgain() {
      await UserService.requestInvite(
        this.user.username,
        this.currentBotId,
        this.user.features
      );

      this.isLoading = false;
      this.dialogIsOpen = false;
      this.$emit('onChange');
    },
  },
};
</script>
 <template>
  <v-container class="fill-height px-0" fluid>
    <v-dialog
      style="position: relative"
      v-model="dialog"
      persistent
      max-width="70vw"
      :fullscreen="$vuetify.breakpoint.mdAndDown"
    >
      <v-card height="90vh" class="d-flex flex-column">
        <v-card-title class="pa-0">
          <v-toolbar flat>
            <v-toolbar-title>{{ $route.params.channelid }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="closeDialog()">
              <v-icon class="heading grey--text text--darken-4">close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-tabs
          v-if="!isLoading"
          v-model="currentSettingView"
          active-class="secondary--text text--darken-2"
          :vertical="$vuetify.breakpoint.mdAndUp"
        >
          <v-tab href="#infos">Infos </v-tab>
          <v-tab href="#editor" v-if="isConfigV3">Darstellung </v-tab>
          <v-tab href="#config" v-if="isConfigV3">Configuration </v-tab>
          <v-tabs-slider color="secondary darken-2"></v-tabs-slider>
          <v-tabs-items v-model="currentSettingView" style="display: grid" >
            <TabChannelInfos />
            <TabAppearance v-if="isConfigV3"/>
            <TabConfiguration v-if="isConfigV3"/>
          </v-tabs-items>
        </v-tabs>
        <div v-else style="display: grid; height: 100%; align-items:center; justify-items: center" class="">
          <v-progress-circular
          
          indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
      <v-banner
        elevation="3"
        sticky
        single-line
        bottom
        class="pa-0 d-flex justify-end"
        style="bottom: 0"
      >
      <div v-if="getDirty" class="d-flex align-center">
        <v-icon class="mr-2">mdi-alert-circle-outline</v-icon>
        <span >{{$t(`common.unsavedChanges`)}}</span>
      </div>
        <template v-slot:actions>
          <v-btn outlined color="primary" @click="saveChanges">{{$t('common.save')}}</v-btn>
        </template>
      </v-banner>
      </v-card>
      <v-snackbar v-model="snackbar" :color="snackbarType">
        {{ snackbarText }}
        <template v-slot:action="{ attrs }">
          <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-dialog>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
import TabAppearance from '@/components/channelSettings/TabAppearance.vue';
import TabConfiguration from '@/components/channelSettings/TabConfiguration.vue';
import TabChannelInfos from '@/components/channelSettings/TabChannelInfos.vue';

export default {
  name: 'ChannelSetting',
  components: {
    TabAppearance,
    TabConfiguration,
    TabChannelInfos,
  },
  mounted() {
    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchData(this.$route.params.botId, this.$route.params.channelid);
  },
  computed: {
    isConfigV3() {
      const channel = this.getChannelById(this.$route.params.botId, this.$route.params.channelid);
      if(channel && channel.config && channel.config.version){
        return channel.config.version === 3;
      }
      return false;
    },
    ...mapGetters('bots', [
      // create properties from auth store
      'currentBotId', 'getCurrentChannelConfig', 'getChannelById'
    ]),
    ...mapGetters('channelsettings', [
      'getDirty', 'getVersion', 'isLoading'
    ]),
  },
  methods: {
    closeDialog() {
      //check if unsaved changes and warn / prevent
      this.dialog = false;
      this.$router.push({ path: '/channels/' })
    },
    async saveChanges() {
      try {
        const botId = this.$route.params.botId;
        const channelId = this.$route.params.channelid;
        const result = await this.$store.dispatch('channelsettings/saveChannelConfig',
          { channelId, botId }
        );
        if(result.error) {
          throw new Error('Fehler');
        }
        this.snackbar = true;
        this.snackbarType = 'success';
        this.snackbarText = 'Änderungen erfolgreich gespeichert.';
      } catch (error) {
        this.snackbar = true;
        this.snackbarType = 'error';
        this.snackbarText = 'Fehler beim Speichern der Änderungen.';
      }
    },
    fetchData(botId, channelId) {
      this.$store
        .dispatch('channelsettings/fetchChannelConfig', { channelId, botId })
        .then((data) => {
          if(data.error) {
            throw(new Error(data.error))
          }
        })
        .catch(() => {
          this.snackbar = true;
          this.snackbarType = 'error';
          this.snackbarText = 'Fehler beim Laden der Konfiguration.';
        });
    },
  },
  data() {
    return {
      dialog: true,
      snackbar: false,
      snackbarText: '',
      snackbarType: '',
      channelId: this.$route.params.channelid,
      currentSettingView: null,
    };
  },
};
</script>
<template>
  <div>
    <IntentNewInitial v-if="status === statusTypes.initial" />
    <IntentNewWithTemplate v-if="status === statusTypes.creatingWithTemplate" />
    <IntentNewWithoutTemplate
      v-if="status === statusTypes.creatingWithoutTemplate"
    />
    <IntentNewCreated v-if="status === statusTypes.created" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import IntentNewInitial from '@/components/intentNew/IntentNewInitial.vue';
import IntentNewWithoutTemplate from './IntentNewWithoutTemplate.vue';
import IntentNewWithTemplate from './IntentNewWithTemplate.vue';
import IntentNewCreated from './IntentNewCreated.vue';
import { statusTypes } from '@/store/modules/intentNew';

export default {
  name: 'IntentNew',
  components: {
    IntentNewInitial,
    IntentNewWithoutTemplate,
    IntentNewWithTemplate,
    IntentNewCreated,
  },
  data() {
    return {
      statusTypes,
    };
  },
  computed: {
    ...mapGetters('intentNew', ['status', 'newIntent']),
  },
};
</script>

import ApiService from './api';

const ProductAdvisorService = {
  /**
   * Fetches all product advisors for the bot by ID, with all products populated in each advisor
   * @param {string} botId
   * @returns {Promise<Object[] | null>} `null` if an error occurred
   */
  async getAdvisors(botId) {
    try {
      const r = await ApiService.get(`/schaltzentrale/product-advisor/bot/${botId}/advisors`);
      return r.data;
    } catch(e) {
      console.error(e);
      return null;
    }
  },
  /**
   * Creates a new Product Advisor, along with its related intent
   * @param {string} botId
   * @param {Object} intentPayload
   * @param {string} name
   * @param {string} description
   * @param {boolean} mock
   */
  async createAdvisor(botId, intentPayload, name, description, mock = false) {
    if (mock === true) {
      return {
        "advisor": {
          "_id": "664cc710f2ac6f87a05198bc",
          "botId": "aBVwAoAF",
          "intent": "faq_test_f",
          "purpose": "Testin again, test F",
          "company": "Waterkant Touren",
          "features": [],
          "__v": 0
        },
        "intent": {
          "createdBy": {
            "user": "martin.haslien@moin.ai"
          },
          "translation": {
            "de": {
              "displayName": "Prüfung F",
              "description": "Erneut testen, Test F"
            },
            "en": {
              "displayName": "Test F",
              "description": "Testin again, test F"
            }
          },
          "staticIntentSuggestions": [],
          "k2names": [],
          "_id": "664cc70ef2ac6f87a051989b",
          "relations": [],
          "intent": "faq_test_f",
          "displayName": "Test F",
          "template": "ProductAdvisor",
          "description": "Testin again, test F",
          "box": "faq_test_f",
          "botId": "aBVwAoAF",
          "language": "de",
          "createdAt": "2024-05-21T16:08:47.515Z",
          "updatedAt": "2024-05-21T16:08:47.515Z",
          "__v": 0,
          "samples": []
        }
      };
    }

    try {
      const r = await ApiService.post(`/schaltzentrale/product-advisor/bot/${botId}/advisors`, {
        name,
        description,
        intent: intentPayload,
      });
      return r.data;
    } catch(e) {
      console.error(e);
      return null;
    }
  },
  /**
   * Overwrites one or more fields of the product advisor document
   * @param {string} botId
   * @param {string} advisorId
   * @param {Object} newFields
   * @returns {Promise<Object>} New state of the advisor. It does not contain products.
   */
  async updateAdvisor(botId, advisorId, newFields) {
    try {
      const r = await ApiService.patch(`/schaltzentrale/product-advisor/bot/${botId}/advisors/${advisorId}`, newFields);
      return r.data;
    } catch(e) {
      console.error(e);
      return null;
    }
  },
  /**
   * Creates a new product for the given advisor
   * @param {string} botId
   * @param {string} advisorId
   * @param {Object} productPayload
   */
  async createProduct(botId, advisorId, productPayload) {
    try {
      const r = await ApiService.post(`/schaltzentrale/product-advisor/bot/${botId}/advisors/${advisorId}/products`, productPayload);
      return r.data;
    } catch(e) {
      console.error(e);
      return null;
    }
  },

  /**
   * Update a product
   * @param {string} botId
   * @param {string} advisorId
   * @param {string} productId
   * @param {Object} newFields
   */
  async updateProduct(botId, advisorId, productId, newFields) {
    try {
      const r = await ApiService.patch(`/schaltzentrale/product-advisor/bot/${botId}/advisors/${advisorId}/products/${productId}`, newFields);
      return r.data;
    } catch(e) {
      console.error(e);
      return null;
    }
  },
  /**
   * Delete a product
   * @param {string} botId
   * @param {string} advisorId
   * @param {string} productId
   */
  async deleteProduct(botId, advisorId, productId) {
    try {
      await ApiService.delete(`/schaltzentrale/product-advisor/bot/${botId}/advisors/${advisorId}/products/${productId}`);
    } catch(e) {
      console.error(e);
    }
  },
};

export default ProductAdvisorService;
export { ProductAdvisorService };
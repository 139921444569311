<template>
  <div>
    <v-timeline-item hide-dot color="grey lighten-3" fill-dot v-if="content">
    </v-timeline-item>
    <ActionList
      v-if="content"
      :actions="content.actions.actions"
      @hook:mounted="updateName"
      @onUpdate="update"
      :channelType="channelType"
    ></ActionList>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ActionList from '@/components/ActionList.vue';
import ChannelsUtil from '@/utils/channels';

export default {
  name: 'BotFormFreeTextEdit',
  components: {
    ActionList,
  },
  props: ['box', 'intent', 'channel', 'name'],
  data: () => ({}),
  async created() {
  },
  computed: {
    ...mapGetters('intents', ['intents']),
    ...mapGetters('bots', ['currentBotId', 'currentChannels']),
    ...mapGetters('auth', ['isAdmin']),
    ...mapGetters('forms', ['forms']),
    content() {
      return this.$store.getters['content/getContent'](
        this.currentBotId,
        this.intent,
        this.box,
        true,
        this.channel
      );
    },
    isLivechatRating() {
      const currentForm = this.forms.find((f) => f.intent === this.intent);

      return (
        currentForm?.template === 'BotFormRating' &&
        this.content?.context === 'rating_comment'
      );
    },
    channelType() {
      return ChannelsUtil.getChannelType(
        this.content?.channel,
        this.currentChannels
      );
    },
  },
  methods: {
    updateName() {
      if (this.content && !this.content.displayName) {
        this.content.displayName = this.name;
      }
    },
    update() {
      this.$emit('onUpdated');
    },
  },
  watch: {
    name() {
      if (this.content) {
        this.content.displayName = this.name;
      }
    },
  },
};
</script>
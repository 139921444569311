<template>
  <v-app style="max-height: 100vh; height: 100vh">
    <MenuPreview
      :botAvailable="!!botAvailable"
      :loggedIn="isLoggedIn"
      :channelId="theChannelId"
      :botId="botId"
    ></MenuPreview>
    <AppbarPreview
      :loggedIn="isLoggedIn"
      :botId="botId"
      :channelId="theChannelId"
    ></AppbarPreview>

    <v-main>
      <v-container fluid class="fill-height">
        <!-- frame -->
        <v-row no-gutters class="fill-height white">
          <!-- content -->
          <v-col cols="12" class="fill-height flex-column d-flex">
            <!-- fix top bar -->

            <!-- content container (scrollable) -->
            <v-row class="flex-grow-1">
              <!-- scroll container hack -->
              <v-col
                style="background-color: #f5f5f5"
                class="fill-height d-none d-sm-flex flex-sm-column d-sm-flex"
                cols="4"
                :sm="6"
                :md="4"
                v-if="isLoggedIn && botAvailable"
              >
                <v-row class="overflow-y-auto" style="height: 0%">
                  <v-col class cols="12">
                    <v-card flat color="#f5f5f5" class="pl-3 pr-3 pt-2 pb-3">
                      <v-list class="pt-0 pb-0 pr-0 pl-0" two-line>
                        <template v-for="(item, index) in intents">
                          <v-list-item
                            :key="index"
                            :class="{
                              selectborder: isSelecteItem(item),
                              'pl-5': !isSelecteItem(item),
                            }"
                          >
                            <v-list-item-content>
                              <v-list-item-title
                                class="py-2"
                                :class="{
                                  'primary--text text--lighten-3':
                                    item.payload && item.payload.inReview,
                                }"
                              >
                                {{ displayName(item.intent, $vuetify.lang.current) }}
                                <template
                                  v-if="item.payload && item.payload.inReview"
                                >
                                  <v-tooltip bottom>
                                    <template
                                      v-slot:activator="{ on: onReview }"
                                    >
                                      <v-icon
                                        color="primary lighten-3"
                                        size="16"
                                        style="
                                          position: relative;
                                          top: -8px;
                                          right: -4px;
                                        "
                                        v-on="onReview"
                                      >
                                        hourglass_top
                                      </v-icon>
                                    </template>
                                    <span>
                                      {{
                                        isDemoLicence
                                          ? $t(
                                              'intents.inReview.tooltipInReviewDemo'
                                            )
                                          : $t(
                                              'intents.inReview.tooltipInReview'
                                            )
                                      }}
                                    </span>
                                  </v-tooltip>
                                </template>
                              </v-list-item-title>
                            </v-list-item-content>
                            <v-tooltip bottom color="primary lighten-2">
                              <template v-slot:activator="{ on, attrs }">
                                <BtnSquare
                                  v-on="on"
                                  v-bind="attrs"
                                  :disabled="
                                    teaserButtons.length > 2 ||
                                    (item.payload && item.payload.inReview)
                                  "
                                  @click="
                                    addTeaserButton({
                                      intent: item.intent,
                                      text: displayName(item.intent, $vuetify.lang.current),
                                    })
                                  "
                                  colorIcon="grey darken-1"
                                  icon="control_point"
                                ></BtnSquare>
                              </template>
                              <span>{{ $t('preview.teaser.addbutton') }}</span>
                            </v-tooltip>
                            <v-tooltip bottom color="primary lighten-2">
                              <template v-slot:activator="{ on, attrs }">
                                <BtnSquare
                                  v-on="on"
                                  v-bind="attrs"
                                  @click="sendIntent(item)"
                                  icon="play_circle"
                                  colorIcon="primary lighten-2"
                                ></BtnSquare>
                              </template>
                              <span>{{ $t('preview.intent') }}</span>
                            </v-tooltip>
                          </v-list-item>

                          <v-divider
                            inset
                            v-if="index < intents.length - 1"
                            :key="'div-' + index"
                          ></v-divider>
                        </template>
                      </v-list>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>

              <!-- Sidebar md and above -->

              <transition name="slide-left">
                <v-col
                  cols="12"
                  :sm="isLoggedIn && botAvailable ? 6 : 12"
                  :md="isLoggedIn && botAvailable ? 8 : 12"
                  class="flex-column d-flex fill-height white"
                >
                  <v-container fluid class="fill-height py-0">
                    <v-row class="fill-height">
                      <v-col cols="12" class="d-none d-md-block">
                        <v-card color="fill-height flex-column d-flex" flat>
                          <v-row class="flex-grow-0 grey lighten-2">
                            <v-col cols="2" md="1" offset="1">
                              <v-sheet
                                class="d-flex"
                                color="grey lighten-4"
                                height="32"
                              ></v-sheet>
                            </v-col>
                            <v-col cols="4" md="7">
                              <v-sheet
                                class="d-flex"
                                color="grey lighten-4"
                                height="32"
                              ></v-sheet>
                            </v-col>
                            <v-col cols="2" md="1">
                              <v-sheet
                                class="d-flex"
                                color="grey lighten-4"
                                height="32"
                              ></v-sheet>
                            </v-col>
                            <v-col cols="2" md="1">
                              <v-sheet
                                class="d-flex"
                                color="grey lighten-4"
                                height="32"
                              ></v-sheet>
                            </v-col>
                          </v-row>
                          <v-row class="flex-grow-1 grey lighten-2">
                            <v-col cols="12" class="grey lighten-3"></v-col>
                          </v-row>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
              </transition>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>


<script>
import { mapGetters, mapActions } from 'vuex';
import AppbarPreview from '@/components/AppbarPreview.vue';
import MenuPreview from '@/components/MenuPreview.vue';
import BtnSquare from '@/components/common/BtnSquare.vue';

export default {
  name: 'Preview',
  components: {
    AppbarPreview,
    MenuPreview,
    BtnSquare,
  },
  props: {
    botId: String,
    channelId: String,
  },
  data() {
    return {
      selectedIntent: null,
      pubsub: null,
      topics: null,
      config: null,
      channel: null,
    };
  },
  created() {
    // initialize store
    this.initPreviewStore({ botId: this.botId, channelId: this.theChannelId });
    this.initLang();
  },
  methods: {
    initLang() {
      // HACK for getting the right language
      if (this.user?.language) {
        this.$i18n.locale = this.user.language;
        this.$vuetify.lang.current = this.user.language;
      } else {
        this.$i18n.locale = process.env.VUE_APP_I18N_LOCALE;
        this.$vuetify.lang.current = process.env.VUE_APP_I18N_LOCALE;
      }
    },
    isSelecteItem(item) {
      return item === this.selectedIntent;
    },
    ...mapActions('preview', [
      'addTeaserButton',
      'connectDock',
      'showTeaser',
      'initWidget',
      'sendIntent',
      'resetWidget',
      'initPreviewStore',
    ]),
  },
  computed: {
    ...mapGetters('auth', [
      // create properties from auth store
      'user',
      'isAdmin',
    ]),
    ...mapGetters('preview', [
      'teaserButtons',
      'widgetConfig',
      'dock',
      'getBotId',
    ]),
    ...mapGetters('bots', ['bots', 'currentBot', 'currentChannels']),
    ...mapGetters('dashboard', ['topAnswers']),
    ...mapGetters('intents', ['intents', 'displayName']),
    isLoggedIn() {
      return this.user !== false;
    },
    botAvailable() {
      return this.intents && this.intents.length > 0;
    },
    isDemoLicence: function () {
      const currLicence = this.$store.getters['bots/currentLicence'];
      return currLicence?.package === 'demo';
    },
    theChannelId() {
      if (!this.channelId && this.currentChannels.length > 0 && this.currentChannels[0].blocked === true) {
        // return first not blocking channel
        const notBlockedChannel = this.currentChannels.find((channel) => channel.blocked === false);
        if (notBlockedChannel) {
          return notBlockedChannel.channelId;
        }
      }
      return this.channelId;
    }
  },
  beforeRouteLeave(from, to, next) {
    // remove widget form code
    window.knowhere = undefined;
    let element = window.document.getElementById('knw_widget');
    element?.parentNode?.removeChild(element);

    return next(true);
  },
  watch: {
    getBotId(newBotId) {
      this.initWidget({ botId: newBotId, channelId: this.theChannelId });
    },
    channelId(newChannelId) {
      this.resetWidget({ botId: this.getBotId, channelId: newChannelId });
      // reload page
      this.$router.go();
    },
  },
};
</script>

<template>
  <div class="hub-frame" :class="[isFooterActive && 'hub-frame--show-footer']">
    <div class="hub-frame-wrapper">
      <div class="hub-frame-content white relative">
        <v-progress-linear
          class="hub-frame-progress"
          v-if="isLoading"
          indeterminate
          absolute
          color="secondary darken-2"
        />
        <template v-if="$slots.title || $slots.actions">
          <div
            class="pa-4 align-center d-flex"
            :class="[
              $vuetify.breakpoint.xs && 'flex-column flex-column-reverse',
              $vuetify.breakpoint.smAndUp && 'justify-space-between',
            ]"
            v-intersect="onHeaderIntersect"
          >
            <div class="flex-grow-1 hub-frame-full-width">
              <span :class="isAdminOnly && 'admin--text'">
                <slot name="title"></slot>
              </span>
            </div>
            <div class="text-right flex-grow-1 hub-frame-full-width">
              <slot name="actions"></slot>
            </div>
          </div>
          <v-divider class="grey lighten-4" />
        </template>
        <div class="pa-4">
          <slot name="content"></slot>
        </div>
      </div>
    </div>
    <v-fade-transition>
      <div v-if="isFooterActive" class="hub-frame-footer">
        <div class="white">
          <v-divider class="grey lighten-4" />
          <div class="pa-4 d-flex justify-end">
            <slot name="actions"></slot>
          </div>
        </div>
      </div>
    </v-fade-transition>
  </div>
</template>

<script>
export default {
  name: 'HubFrame',
  components: {},
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isFooterActive: false,
    };
  },
  computed: {
    isAdminOnly () {
      return this.$route.meta?.admin || false;
    },
  },
  methods: {
    onHeaderIntersect(entries) {
      if (entries?.length > 0) {
        this.isFooterActive = !entries[0].isIntersecting;
      }
    },
  },
};
</script>
<style lang="scss">
$footer-height: $spacer * 17;
$border-radius: 4px;
$spacing: 3 * $spacer;

.hub-frame {
  position: relative;
  min-height: 100%;
  max-height: 100%;
}

.hub-frame--show-footer {
  height: $footer-height;
  padding-bottom: $footer-height;

  .hub-frame-wrapper {
    padding-bottom: 0 !important;
  }

  .hub-frame-content {
    border-radius: $border-radius $border-radius 0 0;
  }
}

.hub-frame-wrapper {
  min-height: 100%;
  max-height: 100%;
  overflow: hidden;
  overflow-y: auto;
  padding: $spacing;
}

.hub-frame-content {
  border-radius: $border-radius;
}

.hub-frame-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0 $spacing;
  height: $footer-height;
}

.hub-frame-progress {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.hub-frame-full-width {
  width: 100%;
}
</style>
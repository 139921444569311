import ApiService from './api';
import { TokenService } from './token';

class AuthenticationError extends Error {
  constructor(errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
  }
}

const UserService = {
  /**
   * Login the user and store the access token to TokenService.
   *
   * @returns access_token
   * @throws AuthenticationError
   **/
  login: async function(username, password) {
    const requestData = {
      method: 'post',
      url: '/auth/login',
      data: {
        username,
        password,
        noBots: true,
      },
    };

    try {
      const response = await ApiService.customRequest(requestData);
      if (!response.data.two_fa) {
        TokenService.saveToken(response.data.token);
        ApiService.setHeader();
        ApiService.mount401Interceptor();
      }

      return {
        token: response.data.token,
        two_fa: response.data.two_fa,
      };
    } catch (error) {
      if (error.response) {
        throw new AuthenticationError(
          error.response.status,
          error.response.data.detail
        );
      }
    }
  },
  twofaLogin: async function(twofa_token, usertoken) {
    const requestData = {
      method: 'post',
      url: '/auth/twofaLogin',
      data: {
        twofa_token,
        usertoken,
        noBots: true,
      },
    };

    try {
      const response = await ApiService.customRequest(requestData);
      if (response.data && response.data.token) {
        TokenService.saveToken(response.data.token);
        ApiService.setHeader();
        ApiService.mount401Interceptor();

        return response.data.token;
      } else {
        return null;
      }
    } catch (error) {
      if (error.response) {
        throw new AuthenticationError(
          error.response.status,
          error.response.data.detail
        );
      }
    }
  },
  async requestPasswordReset(username) {
    return await ApiService.post('/auth/forgot', {
      username,
    });
  },

  async setNewPassword(username, password, token) {
    const response = await ApiService.post('/auth/changeforgotten', {
      username,
      password,
      token,
    });
    return response;
  },

  async completeInvite(username, password, token) {
    const response = await ApiService.post(
      `/auth/invite/validate/${username}/${token}`,
      {
        username,
        password,
        token,
      }
    );
    return response;
  },

  async requestInvite(email, botId, features) {
    const response = await ApiService.post(
      `/auth/invite/generate/${email}/${botId}`,
      {
        email,
        botId,
        features,
      }
    );
    return response;
  },

  /**
   * Logout the current user by removing the token from storage.
   *
   * Will also remove `Authorization Bearer <token>` header from future requests.
   **/
  logout() {
    // Remove the token and remove Authorization header from Api Service as well
    TokenService.removeToken();
    ApiService.removeHeader();
    ApiService.unmount401Interceptor();
  },

  decodeToken(token) {
    if (!token) {
      return null;
    }
    const base64Url = token.split('.')[1];
    return JSON.parse(window.atob(base64Url));
  },

  /**
   * Checks if user has provided feature or one of features
   * @param {any} user - User object
   * @param {string|string[]} feature - feature name to check
   * @returns 
   */
  hasFeature(user, feature) {
    if (user && user.features && Array.isArray(user.features)) {
      if (Array.isArray(feature)) {
        return user.features.some((f) => feature.includes(f));
      }
      return user.features.some((f) => f === feature);
    }

    return false;
  },
  async getUser() {
    try {
      const response = await ApiService.get(`/auth/me`);
      return response;
    } catch (e) {
      return {
        data: null,
      };
    }
  },

  // @data  {username: [email], language: 'en' | 'de'}
  async saveLanguage(data) {
    if (!data?.username || !data?.language) return null;
    try {
      const response = await ApiService.post(
        `/schaltzentrale/bot/language/`,
        data
      );
      return response;
    } catch (error) {
      return {};
    }
  },

  async handleSSOCallback(provider, data={}, options={}) {
    try {
      const response = await ApiService.post(
        `/auth/sso/callback/${provider}`,
        data
      );

      if (options.autoLogin && !response.data.two_fa) {
        TokenService.saveToken(response.data.token);
        ApiService.setHeader();
        ApiService.mount401Interceptor();
      }

      return response.data;
    } catch (e) {
      throw new Error(e.response?.data?.error || e.message);
    }
  }
};

export default UserService;

export { UserService, AuthenticationError };

<template>
  <div>
    <v-fade-transition>
      <v-app v-if="isBotSelectShown">
        <AppBotSelect @onBotChange="loadBot($event)" />
      </v-app>
    </v-fade-transition>
    <v-fade-transition>
      <v-app v-if="isLoading">
        <HubLoader fixed />
      </v-app>
    </v-fade-transition>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import AppBotSelect from '@/components/app/AppBotSelect.vue';
import HubLoader from '@/components/hub/HubLoader.vue';

export default {
  name: 'AppLoader',
  components: {
    AppBotSelect,
    HubLoader,
  },
  data() {
    return {
      isLoading: true,
      isBotSelectShown: false,
    };
  },
  computed: {
    ...mapGetters('bots', ['bots', 'currentBot', 'currentBotId']),
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      await this.initBots();
    },
    async initBots() {
      await this.$store.dispatch('bots/clear');
      await this.$store.dispatch('bots/get');

      if (!this.bots.length) {
        console.error('No bots found');
      }

      // User has more bots
      if (this.bots.length > 1) {
        const { bid } = this.$route.query;
        const lastUsedBotId = this.$store.getters['auth/lastUsedBotId'];

        let botId = bid || lastUsedBotId || null;

        if (this.$route.name === 'preview') {
          botId = this.$route.params.botid;
        }
      
        if (botId) {
          const bot = this.bots.find((b) => {
            if (b.uniqueBotId === botId) return true;
            if (b.stagingBot === botId) return true;
            return false;
          });
          this.loadBot(botId);
          return;
        }
        this.isBotSelectShown = true;
        await new Promise((resolve) => setTimeout(resolve, 300));
        this.isLoading = false;
        return;
      }
      // User has 1 bot
      else if (this.bots.length === 1) {
        await this.loadBot(this.bots[0].uniqueBotId);
      }
    },
    async loadData() {
      // 🚀 Load data for bot (intents, forms ...)
      const botId1 = this.currentBot.stagingBot;
      const botId2 = this.currentBot.uniqueBotId;
      const currentBotId = this.currentBotId;
      await this.$store.dispatch('bots/getBotById', botId2);
      
      // Verwenden von Promise.all, um alle Requests parallel auszuführen
      await Promise.all([
        this.$store.dispatch('bots/getBotById', botId1),
        this.$store.dispatch('intents/get', currentBotId),
        this.$store.dispatch('forms/get', currentBotId),
        this.$store.dispatch('content/getDeployment', { botId: currentBotId }),
        this.$store.dispatch('knowledgeBase/fetchResources', { botId: currentBotId }),
      ]);

      // Temporary until Jan 2025: Migrate Knowledge Base drafts in your browser from old to the new system.
      await this.$store.dispatch('knowledgeBase/migrateLegacyDrafts', { testMode: false });
    },
    async loadBot(botId) {
      this.isBotSelectShown = false;

      const bot = this.bots.find((b) => {
        if (b.uniqueBotId === botId) return true;
        if (b.stagingBot === botId) return true;
        return false;
      });

      if (!bot) {
        console.error(`No Bot with id "${botId}" found`);
        if (this.bots.length > 1) {
          this.isBotSelectShown = true;
        }
        return;
      }

      this.$store.commit('bots/setCurrentBot', bot);

      if (!this.currentBotId) {
        console.error('No currentBotId found');
        return;
      }
      // 🚀 Load data for bot (intents, forms ...)
      await this.loadData();

      this.$store.commit('auth/setLastUsedBotId', this.currentBotId);
      
      await this.$nextTick();
      
      this.$emit('onBotLoaded');

      // Wait to prevent flickering
      await new Promise((resolve) => setTimeout(resolve, 300));
      this.isLoading = false;
    },
  },
};
</script>


<template>
  <v-alert
    outlined
    :color="color === colorTypes.warning ? 'warning' : 'primary lighten-3'"
    class="info-box-outline"
    :class="[
      color === colorTypes.warning
        ? 'warning--text'
        : 'primary--text text--lighten-3',
      block ? 'd-block' : 'd-inline-block',
    ]"
  >
    <div class="d-flex">
      <div class="mr-2">
        <v-icon :color="colorClasses" size="20" class="mt-n1">
          {{ icon }}
        </v-icon>
      </div>
      <div>
        <div v-if="title" class="text-body-2 font-weight-bold">
          {{ title }}
        </div>
        <div class="text-body-2" v-html="body" style="word-break: break-word;"></div>
      </div>
    </div>
  </v-alert>
</template>

<script>
export default {
  name: 'InfoBoxOutline',
  props: {
    title: {
      type: String,
    },
    body: {
      type: String,
      default: '...',
      required: true,
    },
    icon: {
      type: String,
      default: 'mdi-lightbulb',
    },
    color: {
      type: String,
      default: 'default',
    },
    colorClasses: {
      type: String,
      default: '',
    },
    block: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      colorTypes: {
        default: 'default',
        warning: 'warning',
      },
    };
  },
};
</script>
<style scoped>
.v-alert :deep(a) {
  color: inherit;
}
</style>

// Stores clipboard data.
// 'multipleChoiceItem' item is used in components/editor/templates/MultipleChoiceInfo.vue
// it is used specially for the MultipleChoiceInfo component.
// Add other items for different contexts if needed. (So user can copy/paste between different contexts.)
import _ from 'lodash';

const state = {
  multipleChoiceItem: null,
  actionContent: null,
};

const getters = {
  clipboardMultipleChoiceItem: (state) => state.multipleChoiceItem,
  clipboardAction: (state) => state.actionContent,
};

const actions = {
  clearMultipleChoiceItem({ commit }) {
    commit('setMultipleChoiceItem', null);
  }
};

const mutations = {
  setMultipleChoiceItem(state, payload) {
    state.multipleChoiceItem = payload;
  },
  saveAction(state, payload) {
    if (!payload) {
      state.actionContent = null;
      return;
    }
    
    state.actionContent = _.cloneDeep(payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

import DateUtil from '@/utils/dateRange';
import { formatNumberFixed } from '@/utils/formatNumber';

// Used in Stats Chart components (e.g. StatsChartConversation.vue)
export function parseSeries({ series, timeFormat, language }) {
  if (!series?.length) {
    return [];
  }
  const newSeries = series.map((s) => ({
    x: DateUtil.getDateFormatted(s.x, timeFormat, language),
    y: s.y || 0,
  }));

  return newSeries;
}

// Get pos or neg values as y
export function parseSeriesFeedback({
  series,
  isPositive,
  timeFormat,
  language,
}) {
  const newSeries = series.map((s) => {
    let y = isPositive ? s.pos : s.neg;
    return {
      x: s.x,
      y,
    };
  });

  return parseSeries({ series: newSeries, timeFormat, language });
}

// Get y in % and rounded
export function parseSeriesAutomation({ series, timeFormat, language }) {
  const newSeries = series.map((s) => {
    let y = s.y * 100;
    y = formatNumberFixed(y) || 0;
    return {
      x: s.x,
      y,
    };
  });

  return parseSeries({ series: newSeries, timeFormat, language });
}

<template>
  <div>
    <v-row class="mt-4">
      <v-col cols="12" v-if="newIntent.displayName">
        <p class="body-2 grey--text mb-0">
          {{ $t('intentNew.stepSummary.titleName') }}
        </p>
        <p class="mb-0" v-if="status === statusTypes.creatingWithoutTemplate">
          <span>{{ $t('intentNew.stepName.displayName.title') }}:</span>
          "{{ newIntent.displayName }}"
        </p>
        <p class="mb-0" v-else>
          {{ displayName(newIntent.intent, $vuetify.lang.current) }}
        </p>
      </v-col>

      <v-col cols="12" v-if="newIntent.description">
        <p class="body-2 grey--text mb-0">
          {{ $t('intentNew.stepSummary.titleDescription') }}
        </p>
        <p class="mb-0" v-if="status === statusTypes.creatingWithoutTemplate">
          <span>
            {{ $t('intentNew.stepName.description.title') }}
          </span>
          {{ newIntent.description }}
        </p>
        <p class="mb-0" v-else>
          {{ description(newIntent.intent, $vuetify.lang.current) }}
        </p>
      </v-col>

      <v-col cols="12" v-if="newIntent.scenario">
        <p class="body-2 grey--text mb-0">
          {{ $t('intentNew.stepSummary.titleScenario') }}
        </p>
        <p class="mb-0">
          <span v-if="status === statusTypes.creatingWithoutTemplate">
            {{ $t('intentNew.stepName.scenario.title') }}
          </span>
          {{ newIntent.scenario }}
        </p>
      </v-col>

      <v-col v-if="newIntent.samples && newIntent.samples.length > 0" cols="12">
        <p class="body-2 grey--text mb-3">
          {{ $t('intentNew.stepSummary.typicalIntents') }}
        </p>
        <template v-if="status === statusTypes.creatingWithoutTemplate">
          <p
            v-for="(sample, index) in newIntent.samples"
            :key="index"
            class="mb-2"
          >
            <v-chip color="grey lighten-3" text-color="primary lighten-3">
              {{ sample }}
            </v-chip>
          </p>
        </template>
        <template v-else>
          <p
            class="mb-2"
            v-for="(sample, index) in samples(
              newIntent.intent,
              $vuetify.lang.current
            ).filter((sample) => sample !== '')"
            :key="index"
          >
            <v-chip color="grey lighten-3" text-color="primary lighten-3">
              {{ sample }}
            </v-chip>
          </p>
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { statusTypes } from '@/store/modules/intentNew';

export default {
  name: 'IntentNewStepSummary',
  data() {
    return { statusTypes };
  },
  computed: {
    ...mapGetters('intentNew', ['newIntent', 'status']),
    ...mapGetters('intents', ['displayName', 'description', 'samples']),
  },
};
</script>

import TeasersService from '@/services/teasers';
import _ from 'lodash';

function remapTeaserObjects(teasers) {
  teasers = teasers.map((t) => {
    t.payload.quickActions = t.payload.quickActions.map((q) => {
      if (q.targetIntent) {
        q.intent = q.targetIntent;
      }
      return q;
    });
    return t;
  });
  return teasers;
}

const state = {
  teasers: [],
  teaser: { id: '' },
  teaserInitial: { id: '' },
  isDirty: false,
  isValid: true,
  selectedChannel: null,
};

const getters = {
  teasers: (state) => state.teasers,
  teaser: (state) => state.teaser,
  isDirty: (state) => state.isDirty,
  isValid: (state) => state.isValid,
  selectedChannel: (state) => state.selectedChannel,
};

const actions = {
  async loadTeasers({ state, rootGetters }, { botId }) {
    const currentChannels = rootGetters['bots/currentChannels'];
    const loadedTeasers = [];
    for (let c of currentChannels) {
      const teasers = await TeasersService.getTeasers(botId, c.channelId);
      loadedTeasers.push(...teasers);
    }
    state.teasers = loadedTeasers;
  },
  async loadTeaser({ state, dispatch }, { botId, teaserId }) {
    await dispatch('loadTeasers', { botId });
    if (!state.teasers?.length) {
      return false;
    }
    const t = state.teasers.find((t) => t.id === teaserId);
    if (!t) return false;
    
    if (!t?.payload?.displayName) {
      t.payload.displayName = '';
    }

    state.teaser = _.cloneDeep(t);
    state.teaserInitial = _.cloneDeep(t);

    return true;
  },
  async saveTeasers({ state, rootGetters }, { botId, teasers }) {
    teasers = remapTeaserObjects(teasers);
    const currentChannels = rootGetters['bots/currentChannels'];
    const savedTeasers = [];

    for (const c of currentChannels) {
      const channelTeasers = teasers.filter((t) => t.payload.channelId === c.channelId);
     
      let savedChannelTeasers = await TeasersService.saveTeasers({
        botId,
        channelId: c.channelId,
        teasers: channelTeasers,
      });
      
      savedTeasers.push(...savedChannelTeasers);
    }

    state.teasers = savedTeasers;
  },
  async saveTeaser({ state, commit, dispatch }, { botId }) {
    let teasers = _.cloneDeep(state.teasers);

    // Cleanup empty lists
    if (state.teaser?.allowList && state.teaser?.allowList.length === 0) {
      delete state.teaser.allowList;
    }

    if (
      state.teaser?.payload?.blockList &&
      state.teaser?.payload?.blockList.length === 0
    ) {
      delete state.teaser.payload.blockList;
    }

    // Cleanup lists
    if (state.teaser?.allowList?.length) {
      state.teaser.allowList = state.teaser.allowList.map((url) => {
        url = url.replace('https://', '');
        url = url.replace('http://', '');
        return url;
      });
    }

    if (state.teaser?.blockList?.length) {
      state.teaser.blockList = state.teaser.blockList.map((url) => {
        url = url.replace('https://', '');
        url = url.replace('http://', '');
        return url;
      });
    }

    const index = teasers.findIndex((t) => t.id === state.teaser.id);
    teasers[index] = state.teaser;
    dispatch('saveTeasers', { botId, teasers });

    state.teaserInitial = _.cloneDeep(state.teaser);
    commit('checkIsDirty');

    return true;
  },
  async createTeaser(
    { state, dispatch },
    { botId, channelId, displayName = '', message, quickAction }
  ) {
    const newTeaser = {
      id: `teaser_${new Date().getTime()}_${channelId}`,
      device: 'both',
      payload: {
        showTeaser: true,
        showAvatar: true,
        message,
        timeout: 1000,
        quickActions: [quickAction],
        displayName,
        channelId,
      },
      active: false,
    };

    let teasers = _.cloneDeep(state.teasers);

    teasers.push(newTeaser);
    dispatch('saveTeasers', { botId, teasers });
    return true;
  },
  async deleteTeaser({ state, dispatch}, { botId, teaserId }) {
    let teasers = _.cloneDeep(state.teasers);

    const index = teasers.findIndex((t) => t.id === teaserId);
    if (index < 0) return;
    teasers.splice(index, 1);
    dispatch('saveTeasers', { botId, teasers });
  },
  resetToInitial({ state, commit }) {
    state.teaser = _.cloneDeep(state.teaserInitial);
    commit('checkIsDirty');
  },
  checkIsBlocked({ state, commit, rootGetters }) {
    const intents = rootGetters['intents/intents'] || [];
    const channelId = state?.teaser?.payload?.channelId;
    let isIntentsLive = true;

    state.teaser?.payload?.quickActions?.forEach((action) => {
      const intent = intents.find((i) => i.intent === action.intent);
      if (action?.intent === 'start') return;
      
      if (intent) {
        const state = intent?.state[channelId];
        if (state.state !== 'success') {
          isIntentsLive = false;
          return;
        }
      }
    });
   
    if (
      !isIntentsLive ||
      !state.teaser?.payload?.channelId ||
      !state.teaser?.payload?.message ||
      state.teaser?.payload.quickActions?.find((a) => !a.text) ||
      state.teaser?.payload.quickActions?.find((a) => !a.targetIntent)
    ) {
      commit('setIsValid', false);
      // state.teaser.isBlocked = true;
      // state.teaserInitial.isBlocked = true;
      return;
    }
    commit('setIsValid', true);
    // state.teaser.isBlocked = false;
    // state.teaserInitial.isBlocked = false;
  },
};

const mutations = {
  checkIsDirty(state) {
    if (
      !_.isEqual(state.teaser, state.teaserInitial) ||
      !_.isEqual(state.teaser?.payload, state.teaserInitial?.payload) ||
      !_.isEqual(
        state.teaser?.payload?.quickActions,
        state.teaserInitial?.payload?.quickActions
      ) ||
      !_.isEqual(state.teaser?.allowList, state.teaserInitial?.allowList) ||
      // TODO device key name correct?!
      !_.isEqual(
        state.teaser?.device,
        state.teaserInitial?.device
      )
    ) {
      state.isDirty = true;
      return;
    }
    state.isDirty = false;
  },
  setTeaser(state, teaser) {
    state.teaser = teaser;
  },
  setTimeout(state, newTimeout) {
    const newTeaser = state.teaser;
    if (!newTeaser?.payload) return;
    newTeaser.payload.timeout = newTimeout;
    state.teaser = newTeaser;
  },
  setSelectedChannel(state, channelId) {
    state.selectedChannel = channelId;
  },
  addAction(state) {
    if (!state.teaser?.payload) return;
    state.teaser.payload.quickActions.push({
      id: `teaser_action_${new Date().getTime()}_${state.teaser.payload.channelId}`,
      text: '',
      intent: '',
    });
  },
  setIsValid(state, isValid) {
    state.isValid = isValid;
  },
  deleteAction(state, actionId) {
    const index = state.teaser.payload.quickActions.findIndex(
      (qa) => qa.id === actionId
    );
    if (index >= 0) state.teaser.payload.quickActions.splice(index, 1);
  },
  updateAllowList(state, allowList) {
    delete state.teaser.blockList;
    state.teaser.allowList = allowList;
  },
  updateBlockList(state, blockList) {
    delete state.teaser.allowList;
    state.teaser.blockList = blockList;
  },
  deleteIntegrationLists(state) {
    delete state.teaser.allowList;
    delete state.teaser.blockList;
  },
  setQuickActions(state, actions) {
    state.teaser.payload.quickActions = _.cloneDeep(actions);
  },
  sendTeaserToTop(state, teaserId) {
    const index = state.teasers.findIndex((t) => t.id === teaserId);
    if (index < 0) return;
    const teaser = _.cloneDeep(state.teasers.find((t) => t.id === teaserId));

    state.teasers.splice(index, 1);
    state.teasers.unshift(teaser);
  },
  moveTeaserDown(state, teaserId) {
    const index = state.teasers.findIndex((t) => t.id === teaserId);
    if (index < 0) return;
    const teaser = _.cloneDeep(state.teasers.find((t) => t.id === teaserId));

    state.teasers.splice(index, 1);
    state.teasers.splice(index + 1, 0, teaser);
  },
  setTeaserActive(state, { teaserId, isActive }) {
    state.teasers.find((t) => t.id === teaserId).active = isActive;
  },
  setTeaserDevice(state, deviceType) {
    const newTeaser = state.teaser;
    if (!newTeaser) return;
    newTeaser.device = deviceType;
    state.teaser = newTeaser;
  },
  duplicateTeaser(state, { teaser, lang }) {
    const newTeaser = _.cloneDeep(teaser);
    newTeaser.active = false;
    newTeaser.id = `teaser_${new Date().getTime()}_${teaser.payload.channelId}`;
    newTeaser.payload.displayName += ` (${lang === 'en' ? 'copy' : 'Kopie'})`;
    // change teaserAction
    newTeaser.payload.quickActions.forEach((action, index) => {
      const uniqueTime = new Date().getTime() + index;
      action.id = `teaser_action_${uniqueTime}_${teaser.payload.channelId}`;
    });
    state.teasers.push(newTeaser);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

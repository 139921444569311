import Vue from 'vue';
import VueRouter from 'vue-router';
import Welcome from '../views/Welcome.vue';
import Login from '../views/Login.vue';
import Password from '../views/Password.vue';
import Intents from '../views/Intents.vue';
import Forms from '../views/Forms.vue';
import Stats from '../views/Stats.vue';
import LivechatStats from '../views/LivechatStats.vue';
import StatsReport from '../views/StatsReport.vue';
import Chats from '../views/ChatInsights.vue';
import Conversations from '../views/Conversations.vue';
import ChatHistory from '../views/ChatHistory.vue';
import ChatView from '../views/Chats';
import BotForms from '../views/BotForms.vue';
import Channels from '../views/Channels.vue';
import BotNotifications from '../views/BotNotifications.vue';
import ChannelSetting from '../views/ChannelSetting.vue';
import AITooling from '../views/AITooling.vue';
import Preview from '../views/Preview.vue';
import { TokenService } from '../services/token';
import UserService from '../services/user';
import Widget from '../views/Widget.vue';
import Twofa from '../views/Twofa.vue';
import OAuth from '../views/OAuth.vue';
import Settings from '../views/Settings.vue';
import SettingsRoles from '../views/SettingsRoles';
import SettingsPrivacy from '../views/SettingsPrivacy';
import SettingsFiles from '../views/SettingsFiles';
import SettingsChannels from '../views/SettingsChannels';
import SettingsChannelsDetail from '../views/SettingsChannelsDetail';
import UserProfile from '../views/UserProfile.vue';
import WidgetTeaser from '../views/WidgetTeaser.vue';
import TeasersDetail from '../views/TeasersDetail.vue';
import NotFound404 from '../views/NotFound404.vue';
import QmDashboard from '../views/QmDashboard.vue';
import DemoCreateBot from '../views/DemoCreateBot.vue';
import SearchAndReplace from '../views/SearchAndReplace';
import { eventStrings } from '@/components/demo/eventStrings';
import settingsAI from '@/views/SettingsAI.vue';
import Maintenance from '@/views/Maintenance.vue';
import SSOCallback from '@/views/SSOCallback.vue';
import { isAdminOnlyIntegration } from '../constants';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: {
      name: 'welcome',
    },
  },
  {
    path: '/welcome',
    name: 'welcome',
    component: Welcome,
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      public: true, // Allow access to even if not logged in
      onlyWhenLoggedOut: true,
    },
  },
  {
    path: '/login/sso/:idProvider/:type?',
    name: 'sso-callback',
    component: SSOCallback,
    meta: {
      public: true, // Allow access to even if not logged in
      onlyWhenLoggedOut: true,
    },
  },
  {
    path: '/maintenance',
    name: 'maintenance',
    component: Maintenance,
    meta: {
      public: true,
    },
  },
  {
    path: '/2fa/:token',
    name: 'twofa',
    component: Twofa,
    meta: {
      public: true, // Allow access to even if not logged in
      onlyWhenLoggedOut: true,
    },
  },
  {
    path: '/password',
    name: 'password',
    component: Password,
    meta: {
      public: true, // Allow access to even if not logged in
      onlyWhenLoggedOut: true,
    },
  },
  {
    path: '/preview/:botid/:channelid?',
    name: 'preview',
    component: Preview,
    meta: {
      public: true,
    },
    props: (route) => ({
      botId: route.params.botid,
      channelId: route.params.channelid,
    }),
  },
  {
    path: '/password/:username/:token/:botinvite?',
    name: 'setpassword',
    component: Password,
    meta: {
      public: true, // Allow access to even if not logged in
      onlyWhenLoggedOut: true,
    },
  },
  {
    path: '/intents',
    name: 'intents',
    component: Intents,
    meta: {
      simpleEdit: true,
    },
  },
  {
    path: '/forms',
    name: 'Forms',
    component: Forms,
    meta: {
      formView: true,
      simpleEdit: true,
    },
  },
  {
    path: '/forms/:name',
    name: 'forms',
    component: BotForms,
    meta: {
      formView: true,
      simpleEdit: true,
    },
  },
  // TODO comment in if you want to use the new forms view
  // {
  //   path: '/forms/:name',
  //   name: 'forms',
  //   component: () => import('@/views/GenericHubWrapper.vue'),// BotForms,
  //   redirect: { name: "Forms" },
  //   meta: {
  //     formView: true,
  //     simpleEdit: true,
  //   },
  //   children: [
  //   {
  //     name: "form-view",
  //     path: ":view?",
  //     component: () => import('@/components/intent/views/ContentEditorContainer.vue'),
  //   }
  //   ]
  // },
  {
    path: '/conversational',
    name: 'conversational',
    component: () => import('@/views/GenericHubWrapper.vue'),
    redirect: { name: "conversational-overview" },
    meta: {
      conversationalView: true,
      simpleEdit: true,
    },
    children: [
      {
        path: "",
        name: "conversational-overview",
        component: () => import('@/views/Conversational.vue'),
      },
      {
        path: ":name",
        name: "conversationaledit",
        component: () => import('@/components/intent/views/ContentEditorContainer.vue'),
      }
    ]
  },
  {
    path: "/advisors",
    name: "advisors",
    component: () => import('@/views/ProductAdvisor.vue'),
    redirect: { name: 'advisor-list' },
    children: [
      {
        path: "",
        name: "advisor-list",
        component: () => import('@/components/productAdvisor/ProductAdvisorOverview.vue'),
      },
      {
        path: ":advisorId",
        name: "advisor",
        // Empty <RouterView> needed to display children
        component: { render: h => h('router-view') },
        children: [
          {
            path: "",
            name: "edit-advisor",
            component: () => import('@/components/productAdvisor/EditProductAdvisor.vue'),
          },
          {
            path: "features",
            name: "advisor-features",
            component: () => import('@/components/productAdvisor/ProductAdvisorFeatures.vue'),
            meta: {
              owner: true,
            },
          },
          {
            path: "products",
            name: "advisor-products",
            component: () => import('@/components/productAdvisor/ProductAdvisorProducts.vue'),
            meta: {
              admin: true
            }
          },
          {
            path: "product/:productId?",
            name: "advisor-edit-product",
            component: () => import('@/components/productAdvisor/ProductAdvisorProduct.vue'),
          },
        ],
      },
    ],
    meta: {
      rag: true,
    },
  },
  {
    path: '/licence',
    name: 'licence',
    component: () => 
      import(/* webpackChunkName: "licence-view" */ '@/views/Licence.vue'),
    meta: {
      admin: true,
      routeOnBotChange: 'licence',
    },
  },
  {
    path: '/licences',
    name: 'licences',
    component: () => 
      import(/* webpackChunkName: "licences-view" */ '@/views/Licences.vue'),
    meta: {
      admin: true,
      routeOnBotChange: 'licences',
    },
  },
  {
    path: '/validator',
    name: 'validator',
    component: () => 
      import(/* webpackChunkName: "bot-validator-view" */ '@/views/BotValidator.vue'),
    meta: {
      admin: true,
    },
  },
  {
    path: '/bot-settings',
    name: 'bot-settings',
    component: () => 
      import(/* webpackChunkName: "bot-setting-view" */ '@/views/BotSetting.vue'),
    meta: {
      admin: true,
    },
  },
  {
    path: '/bot',
    name: 'bot',
    component: () => 
      import(/* webpackChunkName: "bot-setting-view" */ '@/views/BotSetting.vue'),
    meta: {
      admin: true,
    },
  },
  {
    path: '/search',
    name: 'search',
    component: SearchAndReplace,
    meta: {
      simpleEdit: true,
      admin: true,
    }
  },
  {
    path: '/settings',
    name: 'settings',
    component: Settings,
    meta: {
      owner: true,
    },
  },
  {
    path: '/settings/contingents',
    name: 'settings-contingents',
    component: () => 
      import(/* webpackChunkName: "settings-contingents-view" */ '@/views/SettingsContingents.vue'),
    meta: {
      admin: true, // For now, issue #177
    },
  },
  {
    path: '/livechat',
    name: 'livechat',
    component: () =>
      import(/* webpackChunkName: "livechat-view" */ '@/views/Livechat.vue'),
    meta: {
      livechat: true,
    },
  },
  {
    path: '/settings/livechat',
    name: 'settings-livechat',
    component: () => 
      import(/* webpackChunkName: "settings-livechat-view" */ '@/views/SettingsLivechat.vue'),
    meta: {
      livechatAdmin: true,
    },
  },
  {
    path: '/settings/livechat/agent/:uuid',
    name: 'settings-livechat-agent',
    component: () => 
      import(/* webpackChunkName: "settings-livechat-agent-view" */ '@/views/SettingsLivechatAgent.vue'),
    meta: {
      livechatAdmin: true,
    },
  },
  {
    path: '/settings/livechat/groups',
    name: 'settings-livechat-groups',
    component: () => 
      import(/* webpackChunkName: "settings-livechat-groups-view" */ '@/views/SettingsLivechatGroups.vue'),
    meta: {
      livechatAdmin: true,
    },
  },
  {
    path: '/settings/livechat/groups/:uuid',
    name: 'settings-livechat-group-details',
    component: () => 
      import(/* webpackChunkName: "settings-livechat-group-details-view" */ '@/views/SettingsLivechatGroupDetails.vue'),
    meta: {
      livechatAdmin: true,
    },
  },
  {
    name: 'knowledge-base',
    path: '/knowledge-base',
    component: () => import('@/views/KnowledgeBase.vue'),
    redirect: { name: 'knowledge-base-management' },
    meta: {
      simpleEdit: true,
      rag: true,
    },
    children: [
      {
        name: 'knowledge-base-management',
        path: 'manage',
        component: () => import('@/components/knowledgeBase/KnowledgeBaseView.vue'),
      },
      {
        name: 'knowledge-base-edit-document',
        path: 'documents/:resourceId/edit',
        component: () => import('@/components/knowledgeBase/EditDocumentView.vue'),
      },
    ]
  },
  {
    path: '/settings/roles',
    name: 'settings-roles',
    component: SettingsRoles,
    meta: {
      owner: true,
    },
  },
  {
    path: '/settings/privacy',
    name: 'settings-privacy',
    component: SettingsPrivacy,
    meta: {
      owner: true,
    },
  },
  {
    path: '/settings/files',
    name: 'settings-files',
    component: SettingsFiles,
    meta: {
      admin: true,
    },
  },
  {
    path: '/settings/channels',
    name: 'settings-channels',
    component: SettingsChannels,
    meta: {
      owner: true,
    },
  },
  {
    path: '/settings/ai',
    name: 'settings-ai',
    component: settingsAI,
    children: [
      {
        path: 'tone-of-voice',
        name: 'ai-tone-settings',
        component: () => import('@/components/settingsAi/channels/ToneOfVoiceSettings.vue'),
      }
    ],
    meta: {
      admin: true,
    }
  },
  {
    path: '/settings/channels/:id',
    name: 'settings-channels-detail',
    component: SettingsChannelsDetail,
    meta: {
      admin: true,
    },
  },
  {
    path: '/user-profile',
    name: 'user-profile',
    component: UserProfile,
  },
  {
    path: '/wt',
    name: 'wt',
    component: WidgetTeaser,
    props: () => ({
    }),
    meta: {
      simpleEdit: true,
    },
  },
  {
    path: '/wt/widget',
    name: 'widget',
    component: Widget,
    meta: {
      widgetEditor: true,
      simpleEdit: true,
    },
  },
  {
    path: '/wt/teaser',
    name: 'teaser',
    component: () => 
      import(/* webpackChunkName: "teasers-view" */ '@/views/Teasers.vue'),

    meta: {
      simpleEdit: true,
    },
  },
  {
    path: '/wt/teaser/:id',
    name: 'teaser-detail',
    component: TeasersDetail,
    meta: {
      simpleEdit: true,
    },
  },
  {
    path: '/statistics',
    name: 'statistics',
    component: Stats,
  },
  {
    path: '/livechat-statistics',
    name: 'livechat-statistics',
    component: LivechatStats,
  },
  {
    path: '/statistics/:type',
    name: 'statsReport',
    component: StatsReport,
    props: (route) => ({
      statsType: route.params.type,
    }),
  },
  {
    path: '/conversations',
    name: 'conversations',
    component: Conversations,
    props: (route) => ({
      channel: route.query.channel,
      from: route.query.from,
      to: route.query.to,
      filter: route.query.filter,
      intent: route.query.intent,
    }),
  },
  {
    path: '/chats',
    name: 'chats',
    children: [
      {
        path: 'messaging/:uuid',
        name: 'chat-history',
        component: ChatHistory,
        props: (route) => ({
          uniqueUserId: route.params.uuid,
          jumpto: route.query.jumpto,
        }),
      },
    ],
    component: ChatView, // Chats
  },
  {
    path: '/chats/insights',
    name: 'chats-insights',
    component: Chats, // Chats
  },
  {
    path: '/intent/:name',
    name: 'intentsedit',
    component: () => import('@/views/GenericHubWrapper.vue'),
    redirect: { name: 'intent-view' },
    children: [
      {
        name: "intent-view",
        path: ":view?",
        component: () => import('@/components/intent/views/ContentEditorContainer.vue'),
      },
      //{
      //  meta: {
      //    admin: true,
      //  },
      //  path: 'training',
      //  name: 'training',
      //  component: TrainingdataManagement,
      //},
    ],
    meta: {
      simpleEdit: true,
    },
  },
  {
    path: '/ai',
    name: 'ai',
    component: AITooling,
    props: (route) => ({
      text: route.query.text,
    }),
  },
  {
    path: '/bots',
    name: 'bots',
    component: () => 
      import(/* webpackChunkName: "bots-view" */ '@/views/Bots.vue'),
    meta: {
      admin: true,
    },
  },
  {
    path: '/models',
    name: 'models',
    component: () => 
      import(/* webpackChunkName: "nlp-models-view" */ '@/views/NLPModels.vue'),
    meta: {
      admin: true,
    },
  },
  {
    path: '/channels',
    name: 'channles',
    component: Channels,
    meta: {
      admin: true,
    },
    children: [
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: 'setting/:botId/:channelid',
        components: {
          default: Channels,
          dialog: ChannelSetting,
        },
      },
    ],
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: BotNotifications,
    meta: {
      admin: true,
    },
  },
  {
    path: '/tools',
    name: 'tools',
    component: () => 
      import(/* webpackChunkName: "tools-view" */ '@/views/ToolView.vue'),
    meta: {
      admin: true,
    },
  },
  {
    path: '/oauth/:provider',
    name: 'oauth',
    component: OAuth,
    props: (route) => ({
      code: route.query.code,
    }),
  },
  {
    path: '/integrations',
    name: 'integrations',
    component: () => 
      import(/* webpackChunkName: "integrations-view" */ '@/views/Integrations.vue'),
    props: () => ({}),
    meta: {
      adminOrOwnerIntegration: true,
    },
  },
  {
    path: '/integrations/:type_id/settings',
    name: 'integrations-settings',
    component: () =>
        import(/* webpackChunkName: "integrations-config-view" */ '@/views/IntegrationsSettings.vue'),
    props: () => ({}),
    meta: {
      adminOrOwnerIntegration: true,
    },
  },
  {
    path: '/integrations/:type_id',  // View integration versions
    name: 'integrations-detail',
    component: () => 
      import(/* webpackChunkName: "integrations-detail-view" */ '@/views/IntegrationsDetail.vue'),
    props: () => ({}),
    meta: {
      adminOrOwnerIntegration: true,
    },
  },
  {
    path: '/integrations/:type_id/:key', // Edit integration
    name: 'integrations-config',
    component: () => 
      import(/* webpackChunkName: "integrations-config-view" */ '@/views/IntegrationsConfig.vue'),
    props: () => ({}),
    meta: {
      adminOrOwnerIntegration: true,
    },
  },
  {
    path: '/qm',
    name: 'admin-qm',
    component: QmDashboard,
    meta: {
      admin: true,
    },
  },
  {
    path: '/chatbot-erstellen',
    name: 'demo-onboarding',
    component: DemoCreateBot,
    meta: {
      public: true,
    },
  },
  {
    path: '/ccw',
    name: 'demo-event-ccw',
    component: DemoCreateBot,
    meta: {
      public: true,
    },
    props: { event: eventStrings.ccw2024 }
  },
  {
    path: '/ccw2024',
    name: 'demo-event-ccw2024',
    component: DemoCreateBot,
    meta: {
      public: true,
    },
    props: { event: eventStrings.ccw2024 }
  },
  {
    path: '/internorga',
    name: 'demo-event-internorga',
    component: DemoCreateBot,
    meta: {
      public: true,
    },
    props: { event: eventStrings.internorga }
  },
  {
    path: '/omr',
    name: 'demo-event-omr',
    component: DemoCreateBot,
    meta: {
      public: true,
    },
    props: { event: eventStrings.omr }
  },
  {
    path: '/sesu',
    name: 'demo-event-sesu',
    component: DemoCreateBot,
    meta: {
      public: true,
    },
    props: { event: eventStrings.sesu }
  },
  {
    path: '/demo-creation',
    name: 'demo-creation',
    component: () => 
      import(/* webpackChunkName: "demo-creation-view" */ '@/views/DemoCreation.vue'),
    meta: {
      admin: true,
    },
  },
  { path: '*', component: NotFound404 },
];

// add route for testing component if enviroment is development
if (process.env.NODE_ENV === 'development') {
  routes.push({
    path: '/hub-test',
    name: 'hub-test',
    component: () => 
      import(/* webpackChunkName: "hub-test-view" */ '@/views/HubTest.vue'),
  });
  routes.push({
    path: '/hub-test-bg',
    name: 'hub-test-bg',
    component: () => 
      import(/* webpackChunkName: "hub-test-bg-view" */ '@/views/HubTestBg.vue'),
  });

  routes.push({
    path: '/hub-test-actions',
    name: 'hub-test-actions',
    component: () =>
      import(/* webpackChunkName: "hub-test-actions-view" */ '@/views/HubTestActions'),
  });

  routes.push({
    path: '/hub-test-action-list',
    name: 'hub-test-action-list',
    component: () =>
      import(/* webpackChunkName: "hub-test-actions-view" */ '@/views/HubTestActionList'),
  });

  routes.push({
    path: '/hub-test-templates',
    name: 'hub-test-templates',
    component: () =>
      import(/* webpackChunkName: "hub-test-actions-view" */ '@/views/HubTestTemplates'),
  });

  routes.push({
    path: '/hub-test-editor-frame',
    name: 'hub-test-editor-frame',
    component: () =>
      import(/* webpackChunkName: "hub-test-editor-frame-view" */ '@/views/HubTestEditorFrame'),
  });

  routes.push({
    path: "/hub-test-frame",
    name: "hub-test-frame",
    component: () => import(/* webpackChunkName: "hub-test-frame" */ '@/views/GenericHubWrapper.vue'),
    children: [
      {
        path: "wysiwyg",
        name: "text-wysiwyg",
        component: () => import(/* webpackChunkName: "hub-test-wysiwyg" */ '@/components/hubTest/frame/HubTestWYSIWYG.vue'),
      },
      {
        path: "layout",
        name: "hub-test-frame_layout",
        component: () => import(/* webpackChunkName: "hub-test-frame_layout" */ '@/components/hubTest/frame/HubTestHubLayout.vue'),
      },
      {
        path: "frame",
        name: "hub-test-frame_frame",
        meta: { admin: true },
        component: () => import(/* webpackChunkName: "hub-test-frame_frame" */ '@/components/hubTest/frame/HubTestFrameBasic.vue'),
      },
    ]
  });

  routes.push({
    path: '/hub-test-action-followup',
    name: 'hub-test-action-followup',
    component: () =>
      import(/* webpackChunkName: "hub-test-action-followup-view" */ '@/views/HubTestActionFollowup.vue'),
  });

  routes.push({
    path: '/hub-test-dropdown',
    name: 'hub-test-dropdown',
    component: () =>
      import(/* webpackChunkName: "hub-test-dropdown-view" */ '@/views/HubTestDropdown.vue'),
  });

  const LiveChatAgent = require('@/views/LivechatAgent.vue').default;
  const LiveChatAgentProfile = require('@/views/LivechatAgentProfile.vue').default;

  routes.push({
    path: '/livechat-agent',
    name: 'livechat-agent',
    component: LiveChatAgent,
  });

  routes.push({
    path: '/livechat-agent/profile',
    name: 'livechat-agent-profile',
    component: LiveChatAgentProfile,
  });
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {

  if (process.env.VUE_APP_MAINTENANCE === 'true' && to.name !== 'maintenance') {
    return next({
      path: '/maintenance',
    });
  }

  if (to.name === 'maintenance' && process.env.VUE_APP_MAINTENANCE !== 'true') {
    return next({
      path: '/',
    });
  }

  const loggedIn = !!TokenService.getToken();
  const isPublic = to.matched.some((record) => record.meta.public);

  if (isPublic && (!loggedIn || to.name !== 'login')) {
    return next();
  }
  
  const onlyWhenLoggedOut = to.matched.some(
    (record) => record.meta.onlyWhenLoggedOut
  );
  const onlyOwner = to.matched.some((record) => record.meta.owner);
  const onlyAdmin = to.matched.some((record) => record.meta.admin);
  const onlyRAG = to.matched.some((record) => record.meta.rag);
  const onlySimpleEdit = to.matched.some((record) => record.meta.simpleEdit);
  const formView = to.matched.some((record) => record.meta.formView);
  const onlyLivechat = to.matched.some((record) => record.meta.livechat);
  const livechatAdmin = to.matched.some((record) => record.meta.livechatAdmin);
  const adminOrOwnerIntegration = to.matched.some((record) => record.meta.adminOrOwnerIntegration);
  // const chatInsights = to.matched.some(record => record.meta.chatInsights)
  const widgetEditorView = to.matched.some(
    (record) => record.meta.widgetEditor
  );
  const conversationalView = to.matched.some(
    (record) => record.meta.conversationalView
  );

  // legacy compatibility with # urls/links
  if (to.redirectedFrom && to.redirectedFrom.substr(0, 2) === '/#') {
    const path = to.redirectedFrom.substr(2);
    next({ path, replace: true });
  } else {
    next();
  }

  // redirect for admins to new chat insights view
  // if (to.name === 'chats' && (UserService.hasFeature(UserService.decodeToken(TokenService.getToken()), 'admin') || UserService.hasFeature(UserService.decodeToken(TokenService.getToken()), 'chatInsights')) && !to.query.old) {
  if (to.name === 'chats' && !to.query.old) {
    return next({
      path: '/chats/insights',
    });
  }

  try {
    // admin user need feature
    if (
      onlyAdmin &&
      !UserService.hasFeature(
        UserService.decodeToken(TokenService.getToken()),
        'admin'
      )
    ) {
      return next({
        path: '/',
      });
    }

    // owner rule needed
    if (
      onlyOwner &&
      !UserService.hasFeature(
        UserService.decodeToken(TokenService.getToken()),
        'owner'
      ) &&
      !UserService.hasFeature(
        UserService.decodeToken(TokenService.getToken()),
        'admin'
      )
    ) {
      return next({
        path: '/',
      });
    }

    if (
      onlySimpleEdit &&
      !UserService.hasFeature(
        UserService.decodeToken(TokenService.getToken()),
        'simpleEdit'
      ) &&
      !UserService.hasFeature(
        UserService.decodeToken(TokenService.getToken()),
        'owner'
      ) &&
      !UserService.hasFeature(
        UserService.decodeToken(TokenService.getToken()),
        'admin'
      )
    ) {
      return next({
        path: '/',
      });
    }

    if (
      onlyRAG && !UserService.hasFeature(
        UserService.decodeToken(TokenService.getToken()),
        'rag'
      ) &&
      !UserService.hasFeature(
        UserService.decodeToken(TokenService.getToken()),
        'admin'
      )
    ) {
      return next({
        path: '/',
      });
    }

    if (
      widgetEditorView &&
      !UserService.hasFeature(
        UserService.decodeToken(TokenService.getToken()),
        'widgetEditorView'
      ) &&
      !UserService.hasFeature(
        UserService.decodeToken(TokenService.getToken()),
        'admin'
      )
    ) {
      return next({
        path: '/',
      });
    }

    if (
      adminOrOwnerIntegration &&
      !UserService.hasFeature(
        UserService.decodeToken(TokenService.getToken()),
        'owner'
      ) &&
      !UserService.hasFeature(
        UserService.decodeToken(TokenService.getToken()),
        'admin'
      ))
     {
      return next({ path: '/' });
    }

    if (
      conversationalView &&
      !UserService.hasFeature(
        UserService.decodeToken(TokenService.getToken()),
        'conversationalView'
      ) &&
      !UserService.hasFeature(
        UserService.decodeToken(TokenService.getToken()),
        'admin'
      )
    ) {
      return next({
        path: '/',
      });
    }

    if (
      formView &&
      !UserService.hasFeature(
        UserService.decodeToken(TokenService.getToken()),
        'formView'
      ) &&
      !UserService.hasFeature(
        UserService.decodeToken(TokenService.getToken()),
        'admin'
      )
    ) {
      return next({
        path: '/',
      });
    }

    // Livechat feature check for /livechat
    if (
      onlyLivechat &&
      !UserService.hasFeature(
        UserService.decodeToken(TokenService.getToken()),
        'livechat')
    ) {
      return next({
        path: '/',
      });
    }

    // Livechat feature check for Livechat Settings
    if (
      livechatAdmin &&
      (!UserService.hasFeature(
        UserService.decodeToken(TokenService.getToken()),
        ['admin','owner'] // If no features, then Redirect to Welcome page
      ) ||
      !UserService.hasFeature(
        UserService.decodeToken(TokenService.getToken()),
        'livechat'))
    ) {
      return next({
        path: '/',
      });
    }

    if (!isPublic && !loggedIn) {
      // Hack: when urls containing /#/, they get already redirected to welcome /welcome#
      let redirect = to.fullPath;
      redirect = redirect.replace(/\/welcome#/g, '');
      return next({
        path: '/login',
        query: {
          redirect,
        }, // Store the full path to redirect the user to after login
      });
    }
    
    // Do not allow user to visit login page or register page if they are logged in
    if (loggedIn && onlyWhenLoggedOut) {
      return next('/');
    }
    
    next();
    
  } catch (e) {
    return next({
      path: '/login',
      query: {
        redirect: to.fullPath,
      },
    });
  }
});

export default router;

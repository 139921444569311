<template>
  <v-row>
    <v-col cols="3">
      <v-card class="pa-4 d-flex justify-center" color="#fafafa">
        <ThemeSVG width="72" :colors="this.theme[this.selected]" />
      </v-card>
    </v-col>
    <v-col cols="7">
      <v-card color="transparent" flat>
        <v-card-title class="pt-0">{{$t('channelSettings.appearance.themetype.title')}}</v-card-title>
        <v-card-subtitle>{{$t('channelSettings.appearance.themetype.subtitle')}}</v-card-subtitle>
        <v-card-text>{{$t('channelSettings.appearance.themetype.explanantion')}}</v-card-text>
        <v-card-actions class="px-16 d-flex flex-column justify-start align-start">
          <v-radio-group v-model="selected" column @change="selectedTheme">
            <label class="d-flex justify-start align-center mb-4">
              <ThemeSVG class="mr-4" :colors="this.theme['regular']" width="20" />
              <v-radio :label="$t(`channelSettings.appearance.themetype.themename.regular`)" value="regular"></v-radio>
            </label>
<!--             <label class="d-flex justify-start align-center mb-4">
              <ThemeSVG class="mr-4" :colors="this.theme['regularinverted']" width="20" />
              <v-radio
                :label="$t(`appearance.themetype.themename.regularinverted`)"
                value="regularinverted"
              ></v-radio>
            </label>
            <label class="d-flex justify-start align-center mb-4">
              <ThemeSVG class="mr-4" :colors="this.theme['light']" width="20" />
              <v-radio :label="$t(`appearance.themetype.themename.light`)" value="light"></v-radio>
            </label>
            <label class="d-flex justify-start align-center mb-4">
              <ThemeSVG class="mr-4" :colors="this.theme['dark']" width="20" />
              <v-radio :label="$t(`appearance.themetype.themename.dark`)" value="dark"></v-radio>
            </label> -->
          </v-radio-group>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import ThemeSVG from '@/components/channelSettings/ThemeSVG.vue';
export default {
  components: {
    ThemeSVG
  },
  props: ['value'],
  data() {
    return {
      selected: this.value,
      theme: {
        regular: {
          bg: '#D9D9D9',
          header: '#004977',
          bot: '#ffffff',
          user: '#004977',
          footer: '#A7A7A7'
        },
        regularinverted: {
          bg: '#A7A7A7',
          header: '#004977',
          bot: '#ffffff',
          user: '#004977',
          footer: '#D9D9D9'
        },
        light: {
          bg: '#ffffff',
          header: '#004977',
          bot: '#D9D9D9',
          user: '#004977',
          footer: '#D9D9D9'
        },
        dark: {
          bg: '#292929',
          header: '#004977',
          bot: '#5B5B5B',
          user: '#004977',
          footer: '#6f6f6f'
        }
      }
    };
  },
  methods: {
    selectedTheme() {
      this.$emit('input', this.selected);
    }
  }
};
</script>

<style>
</style>
// Bounded to userRoles in locales/de.json | locales/en.json
export const rolesTypes = {
  TESTER: 'tester',
  VIEWER: 'viewer',
  EDITOR: 'editor',
  OWNER: 'owner',
  INDIVIDUAL: 'individual',
  ADMIN: 'admin',
  ASSISTANT: 'assistant',
  RAG: 'rag',
};

const featuresTester = ['preview'];

const featuresViewer = [
  'dashboard',
  'analytics',
  'dialogs',
  'suggestion',
  'conversationalView',
  'formView',
  'widgetEditorView',
];

const featuresEditor = [...featuresViewer, 'simpleEdit'];
const featuresOwner = [...featuresViewer, 'owner'];

const featuresLists = {
  [rolesTypes.TESTER]: featuresTester,
  [rolesTypes.VIEWER]: featuresViewer,
  [rolesTypes.EDITOR]: featuresEditor,
  [rolesTypes.OWNER]: featuresOwner,
};

export function getFeaturesByRole(roleType) {
  if (!roleType || !featuresLists[roleType]) return null;
  return [...featuresLists[roleType]];
}

export function getRoleByFeatures(features) {
  if (!features) return undefined;

  if (features.includes('preview')) return rolesTypes.TESTER;

  if (features.includes('admin')) {
    return rolesTypes.ADMIN;
  }
  if (features.includes('owner')) {
    return rolesTypes.OWNER;
  }
  if (features.includes('simpleEdit')) {
    return rolesTypes.EDITOR;
  }
  return rolesTypes.VIEWER;
}

// TODO: Löschen wenn nicht mehr gebraucht
export function getRoleByFeaturesDeprecated(features) {
  // features to test without '2FA' and 'admin'
  const featuresOriginal = [...features];
  deleteFeatureInList('2FA', featuresOriginal);
  deleteFeatureInList('admin', featuresOriginal);
  // 2nd list to delete features while testing
  const featuresTest = [...featuresOriginal];
  function deleteFeatureInList(feature, list) {
    const index = list.findIndex((f) => f === feature);
    if (index >= 0) {
      list.splice(index, 1);
    }
  }

  function deleteFeaturesAndTest(roleType) {
    featuresLists[roleType].map((f) => deleteFeatureInList(f, featuresTest));
    if (
      featuresOriginal.length === featuresLists[roleType].length &&
      featuresTest.length === 0
    ) {
      return true;
    }
    return false;
  }

  if (deleteFeaturesAndTest(rolesTypes.VIEWER)) {
    return rolesTypes.VIEWER;
  }
  if (deleteFeaturesAndTest(rolesTypes.EDITOR)) {
    return rolesTypes.EDITOR;
  }
  if (deleteFeaturesAndTest(rolesTypes.OWNER)) {
    return rolesTypes.OWNER;
  }

  return rolesTypes.INDIVIDUAL;
}

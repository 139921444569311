<template>
  <HubBox icon="list_alt" :title="'K2 Namen'" boxId="'intentK2Names'" adminOnly>
    <div class="text-body-2 mb-6 primary--text text--lighten-2">
      K2 Namen hinzufügen, bearbeiten und löschen.
    </div>

    <div v-if="k2Names.length" class="primary--text text--lighten-2">
      <div v-for="(item, index) in k2Names" :key="index">- {{ item }}</div>
    </div>
    <div v-else class="text-body-2 primary--text text--lighten-3">
      Noch keine K2 Namen vorhanden.
    </div>

    <div class="pt-5">
      <v-btn outlined color="primary lighten-3" @click="startEditing()">
        {{ $t('common.edit') }}
        <v-icon right>edit</v-icon>
      </v-btn>
    </div>
    <HubDialog
      :value="editDialogOpen"
      :primary-label="$t('common.save')"
      primary-icon="save"
      :secondary-label="$t('common.cancel')"
      secondary-icon="close"
      :title="'K2 Namen bearbeiten'"
      @onPrimary="saveK2Names()"
      @onSecondary="cancelEdit()"
      :disabled="!isChanged || !isValid"
      modal
      asyncDialog
    >
      <div>
        <div v-for="(item, index) in k2NamesDraft" :key="`item-${index}`">
          <div class="d-flex">
            <v-text-field
              :key="`item-${index}`"
              v-model="k2NamesDraft[index]"
              outlined
              dense
              class="full-width"
            />
            <div class="ml-1">
              <BtnSquare icon="mdi-trash-can" @click="removeName(index)" />
            </div>
          </div>
        </div>
      </div>
      <div>
        <v-btn outlined color="primary lighten-3" @click="addName()">
          Neu
          <v-icon right>mdi-plus</v-icon>
        </v-btn>
      </div>
    </HubDialog>
  </HubBox>
</template>
<script>
import { mapGetters } from 'vuex';
import deepEqual from 'fast-deep-equal/es6';
import HubBox from '@/components/hub/HubBox.vue';
import HubDialog from '@/components/hub/HubDialog.vue';
import BtnSquare from '@/components/common/BtnSquare.vue';
import IntentService from '@/services/intents';

export default {
  name: 'IntentK2Names',
  props: {
    intent: Object,
  },
  components: {
    HubBox,
    HubDialog,
    BtnSquare,
  },
  data() {
    return {
      editDialogOpen: false,
      k2Names: [],
      k2NamesDraft: [],
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...mapGetters('bots', ['currentBotId']),
    ...mapGetters('intents', ['intents']),
    isChanged() {
      return !deepEqual(this.k2Names, this.k2NamesDraft);
    },
    isValid() {
      if (
        this.k2NamesDraft.length &&
        this.k2NamesDraft.filter((item) => item.length < 3).length > 0
      ) {
        return false;
      }
      return true;
    },
  },
  methods: {
    async init() {
      this.k2Names = this.intent?.k2names || [];
      this.k2NamesDraft = [...this.k2Names];
    },
    async saveK2Names() {
      if (!this.intent?.intent || !this.currentBotId) {
        return;
      }

      // check if k2 names are valid and unique over all intents
      let allIntents = this.intents.filter((item) => item.intent !== this.intent.intent);
      allIntents = allIntents.map((item) => ({
        ...item,
        k2names: item.k2names || [],
      }));

      const allK2Names = allIntents.reduce((acc, item) => [...acc, ...item.k2names], []);
      const invalidNames = this.k2NamesDraft.filter((item) => item.length < 3);
      const duplicateNames = this.k2NamesDraft.filter((item) => allK2Names.includes(item));

      if (invalidNames.length || duplicateNames.length) {
        this.$store.commit('addSnackbarMessage', {
            show: true,
            message: 'K2 Namen sind nicht gültig oder bereits vergeben.'
          });
     
        this.editDialogOpen = false;
        return;
      }

      await IntentService.saveK2Names({
        botId: this.intent.botId || this.currentBotId,
        k2names: this.k2NamesDraft,
        intent: this.intent.intent,
      });

      // set k2 names to intent, so that the intent is updated in the store and the reference object doesnt change
      this.intent.k2names = this.k2NamesDraft;
      this.k2Names = this.k2NamesDraft;

      this.editDialogOpen = false;
    },
    cancelEdit() {
      this.editDialogOpen = false;
    },
    startEditing() {
      this.editDialogOpen = true;
      this.k2NamesDraft = [...this.k2Names];
    },
    addName() {
      this.k2NamesDraft.push('');
    },
    removeName(index) {
      this.k2NamesDraft.splice(index, 1);
    },
  },
};
</script>

import { conversationStateTypes, messageTypes } from '@/utils/assistant';
import { AssistantService } from '@/services/assistant';

import { useEventBus } from '@vueuse/core';
import { actionEditorEvents } from '@/utils/actionEditor';

import _ from 'lodash';

const state = {
  state: conversationStateTypes.new,
  conversationId: null,
  chat: [],
  loading: true,
  isAssistantOpen: false,
  isAssistantDirty: false,
  isAssistantInfo: false,
  intentId: null,
  suggestions: [
    {
      text: 'Worum geht es in der Antwort?',
    },
    {
      text: 'Mach aus Links / E-Mails / Telefonnummern Buttons.',
    },
    {
      text: 'Erstelle aus den Bildern ein Kartenmenü.',
    },
    {
      text: 'Erstelle aus dem Text eine passende Antwort. Nutze Multiple Choice um die Antwort besser zu strukturieren.',
    },
  ],
  // {[botId]: {[intent]: {[boxId]: box}}
  drafts: {},
  holdTheLineIndex: 0,
  holdTheLineTimeout: null,
  completedCounter: 0,
  abortController: null,
};

const getters = {
  chat: (state) => state.chat,
  state: (state) => state.state,
  loading: (state) => state.loading,
  isAssistantOpen: (state) => state.isAssistantOpen,
  isAssistantDirty: (state) => state.isAssistantDirty,
  isAssistantInfo: (state) => state.isAssistantInfo,
  suggestions: (state) => [
    {
      text: 'Worum geht es in der Antwort?',
    },
    {
      text: 'Mach aus Links / E-Mails / Telefonnummern Buttons.',
    },
    {
      text: 'Erstelle aus den Bildern ein Kartenmenü.',
    },
    {
      text: 'Erstelle aus dem Text eine passende Antwort. Nutze Multiple Choice um die Antwort besser zu strukturieren.',
    },
  ],
};

const actions = {
  async init({ state, dispatch, rootGetters }) {
    state.loading = true;
    const intentId = rootGetters['intents/currentIntent']?.intent;

    if (state.intentId !== intentId) {
      state.intentId = intentId;
      dispatch('resetAssistant');
    }
    if (state.state === conversationStateTypes.new) {
      dispatch('startAssistant');
    }
    await new Promise((resolve) => setTimeout(resolve, 500));
    state.loading = false;
  },

  async resetAssistant({ state }) {
    state.state = conversationStateTypes.new;
    state.chat = [];
    state.loading = false;
    state.isAssistantDirty = false;
    state.isAssistantInfo = false;
    state.drafts = {};
    state.conversationId = null;
    state.holdTheLineIndex = 0;
  },

  async startAssistant({ state }) {
    state.loading = true;

    await new Promise((resolve) => setTimeout(resolve, 500));

    let greeting = 'moin';

    if (state.completedCounter === 1) {
      greeting = 'moin2';
    } else if (state.completedCounter > 1) {
      greeting = 'moin3';
    }

    state.chat = [
      {
        type: messageTypes.assistant,
        t: `assistant.chatAssistant.${greeting}`,
      },
    ];
    state.loading = false;
  },
  sendMessage({ state, dispatch }, {message, intent, content, channel}) {
    state.isAssistantInfo = false;
    switch (state.state) {
      case conversationStateTypes.new:
        dispatch('handleNewConversation', { message, intent, content, channel });
        break;
      case conversationStateTypes.open:
        dispatch('handleOpenConversation', { message, intent, content });
        break;
    }
  },

  async handleNewConversation({ state, rootGetters, dispatch }, { message, intent, content, channel }) {

    state.conversationId = null;
    state.loading = true;
    const uniqueBotId = rootGetters['bots/currentBotId'];
    const contents = _.cloneDeep(content);
  
    if (!uniqueBotId || !message || !intent || !contents) {
      console.error('handleNewConversation: something is missing');
      return;
    }

    const chat = [...state.chat];
    // Reset other chat items

    state.chat.forEach((item) => {
      if (item.type === messageTypes.user) {
        item.isLastUserInput = false;
      }
    });

    chat.push({
      type: messageTypes.user,
      text: message,
      userInputIndex: 0,
      isLastUserInput: true,
    });

    state.chat = chat;

    dispatch('sendHoldTheLineMessage', {delay: 8000, intent});

    state.state = conversationStateTypes.running;
    state.abortController = new AbortController();
    const { conversation, error } = await AssistantService.startConversation({
      message,
      uniqueBotId,
      intent,
      contents,
      signal: state.abortController.signal,
      channel
    });

    // get only the conversation ID

    if (error || !conversation) {
      dispatch('handleChatError');
      return false;
    }

    if (!conversation) {
      state.loading = false;
      state.state = conversationStateTypes.new;
      return;
    }

    state.conversationId = conversation?.conversationId;
    state.state = conversation?.state;
  },
  async handleOpenConversation({ state, rootGetters, dispatch }, { message }) {
    console.log('handleOpenConversation');
    state.loading = true;
    const uniqueBotId = rootGetters['bots/currentBotId'];
    let stateBefore = null;

    dispatch('resetHoldTheLine');

    // Add user message to chat
    // userInputIndex: position of the user message in the chat
    const userChatItems = state.chat.filter((item) => item.type === 'user');
    const userInputIndex = userChatItems.length;

    state.chat.forEach((item) => {
      if (item.type === messageTypes.user) {
        item.isLastUserInput = false;
      }
    });

    state.chat = [
      ...state.chat,
      {
        type: messageTypes.user,
        text: message,
        userInputIndex,
        isLastUserInput: true,
      },
    ];


    stateBefore = state.state;
    state.state = conversationStateTypes.running;
    state.abortController = new AbortController();
    const conversationId = state.conversationId;
    state.conversationId = null;

    dispatch('sendHoldTheLineMessage', 2000);

    const { error, conversation } = await AssistantService.continueConversation(
      {
        message,
        conversationId: conversationId,
        uniqueBotId,
        signal: state.abortController.signal,
      }
    );

    state.conversationId = conversation?.conversationId;

  },

  async handleConversationCompleted(
    { state, rootGetters, dispatch },
    conversation
  ) {
    state.loading = true;
 
    dispatch('resetHoldTheLine');

    const intent = conversation.intent;
    const uniqueBotId = conversation.botId;
    // "content" aka. contentStore.js
    // const oldDraftIntent = rootGetters['content/getContentBoxes'](
    //   uniqueBotId,
    //   intent
    // );

    // if (oldDraftIntent) {
    //   console.log('oldDraftIntent', oldDraftIntent);
    //   dispatch('setDraft', {
    //     botId: uniqueBotId,
    //     intent,
    //     boxes: oldDraftIntent,
    //   });
    // }

    // const boxIds = oldDraftIntent.map((box) => box.box);

    // // TODO 😭
    // dispatch(
    //   'content/deleteContentDrafts',
    //   {
    //     botId: uniqueBotId,
    //     intentId: intent,
    //     boxIds,
    //   },
    //   { root: true }
    // );

    // await dispatch(
    //   'content/setContentDraftIntent',
    //   {
    //     botId: uniqueBotId,
    //     intent: intent,
    //     boxes: conversation.content,
    //     channel: currentIntent.channel,
    //   },
    //   { root: true }
    // );

    //state.isAssistantDirty = true;
    // state.completedCounter += 1;
    const contentUpdatedEmitter = useEventBus(actionEditorEvents.contentUpdated);
    contentUpdatedEmitter.emit({
      content: conversation.content,
      event: actionEditorEvents.contentOverwritten,
    });

    state.chat = [
      ...state.chat,
      {
        type: messageTypes.system,
        t: 'assistant.chatSystem.suggestionPasted',
      },
    ];

    state.chat = [
      ...state.chat,
      {
        type: messageTypes.assistant,
        text: conversation.output,
      },
    ];

    state.state = conversation.state;
    state.loading = false;
  },

  async handleConversationOpen({ state, dispatch }, conversation) {

    dispatch('resetHoldTheLine');

    state.chat = [
      ...state.chat,
      {
        type: messageTypes.assistant,
        text: conversation.output,
      },
    ];
    state.state = conversation.state;
    state.loading = false;
  },

  async handleConversationCanceled({ state, dispatch }, conversation) {
    state.loading = true;

    dispatch('resetHoldTheLine');

    state.chat = [
      ...state.chat,
      {
        type: messageTypes.system,
        t: 'assistant.chatSystem.suggestionCanceled',
      },
    ];

    state.chat = [
      ...state.chat,
      {
        type: messageTypes.assistant,
        text: conversation.output,
      },
    ];

    state.state = conversationStateTypes.canceled;
    state.loading = false;
  },

  async handleChatError({ state, dispatch }) {
    console.log('assistant.handleChatError');

    dispatch('resetHoldTheLine');

    state.chat = [
      ...state.chat,
      {
        type: messageTypes.system,
        t: 'assistant.chatSystem.error',
      },
      {
        type: messageTypes.assistant,
        t: 'assistant.chatAssistant.error',
      },
    ];
    state.state = conversationStateTypes.error;
    state.loading = false;
    return false;
  },

  async cancelRequest({ state, dispatch }) {
    state.state = conversationStateTypes.new;
    state.loading = true;

    dispatch('resetHoldTheLine');
    if (state.abortController?.abort) {
      state.abortController.abort();
    }

    state.chat = [
      {
        type: messageTypes.system,
        t: 'assistant.chatSystem.requestCaceled',
      },
      {
        type: messageTypes.assistant,
        t: 'assistant.chatAssistant.afterRequestCanceled',
      },
    ];

    // TODO Chat reset
    // Show Btn neu versuchen
    state.state = conversationStateTypes.new;
    state.loading = false;
  },

  async resetDraft({ state, rootGetters, dispatch }, { withMessage }) {
    console.log('assistant.resetDraft');

    dispatch('resetHoldTheLine');

    state.loading = true;
    state.isAssistantDirty = false;
    state.state = conversationStateTypes.new;
    const currentIntent = rootGetters['intents/currentIntent'];

    const boxes =
      state.drafts[currentIntent.botId] &&
      state.drafts[currentIntent.botId][currentIntent.intent]
        ? _.cloneDeep(state.drafts[currentIntent.botId][currentIntent.intent])
        : [];

    dispatch(
      'content/setContentDraftIntent',
      {
        botId: currentIntent.botId,
        intent: currentIntent.intent,
        boxes,
        channel: currentIntent.channel,
      },
      { root: true }
    );

    if (withMessage) {
      await new Promise((resolve) => setTimeout(resolve, 1000));

      state.chat = [
        {
          type: messageTypes.system,
          t: 'assistant.chatSystem.suggestionReseted',
        },
        {
          type: messageTypes.assistant,
          t: 'assistant.chatAssistant.afterCanceled',
        },
      ];
    }

    state.loading = false;
    return true;
  },

  async acceptDraft({ state }) {
    console.log('assistant.acceptDraft');
    state.chat = [
      {
        type: messageTypes.system,
        t: 'assistant.chatSystem.suggestionAccepted',
      },
    ];
    state.state = conversationStateTypes.new;
    state.isAssistantDirty = false;
    await new Promise((resolve) => setTimeout(resolve, 200));
    state.isAssistantOpen = false;
  },

  setDraft({ state }, { botId, intent, boxes }) {
    console.log('assistant.setDraft');
    if (!state.drafts[botId]) {
      state.drafts[botId] = {};
    }
    state.drafts[botId][intent] = { ...boxes };
  },

  async startChangeInput({ state, dispatch }, item) {
    console.log('assistant.startChangeInput');

    const itemIndex = state.chat.findIndex(
      (i) =>
        i.type === messageTypes.user && i.userInputIndex === item.userInputIndex
    );

    let newChat = state.chat.slice(0, itemIndex);

    if (item.userInputIndex === 0) {
      await dispatch('resetDraft', { withMessage: false });
    } else {
      // TODO user input change from other index
    }

    await new Promise((resolve) => setTimeout(resolve, 500));

    newChat.push({
      type: messageTypes.system,
      t: 'assistant.chatSystem.suggestionReseted',
    });

    newChat.push({
      type: messageTypes.assistant,
      t: 'assistant.chatAssistant.afterStartChange',
    });

    state.chat = newChat;
    state.state = conversationStateTypes.new;
    state.loading = false;
  },

  async sendRequestAgain({ state, dispatch }) {
    state.loading = true;

    // Delete chat after last user input
    const itemIndex = state.chat.findIndex((i) => i.isLastUserInput);

    let newChat = state.chat.slice(0, itemIndex - 1);

    const message = state.chat[itemIndex]?.text || null;

    if (!message) {
      console.error('sendRequestAgain: no message found');
      state.state = conversationStateTypes.new;
      dispatch('init');
      return;
    }
    state.chat = newChat;
    dispatch('handleOpenConversation', { message });
  },

  async sendHoldTheLineMessage({ state, dispatch, rootGetters }, {delay = 8000, intent}) {

    if (state.conversationId) {
      const uniqueBotId = rootGetters['bots/currentBotId'];
      // check if conversations still running
      const { error, conversation } = await AssistantService.getConversation(
        {
          conversationId: state.conversationId,
          uniqueBotId: uniqueBotId,
        }
      );

      if (conversation && conversation.state !== 'running') {
        state.state = conversation?.state;
        
        if (conversation.state === conversationStateTypes.completed) {
          dispatch('handleConversationCompleted', conversation);
          return;
        }
    
        if (conversation.state === conversationStateTypes.canceled) {
          dispatch('handleConversationCanceled', conversation);
          return;
        }
    
        if (conversation.state === conversationStateTypes.open) {
          dispatch('handleConversationOpen', conversation);
          return;
        }

        state.loading = false;
        return;
      }
    }


    clearTimeout(state.holdTheLineTimeout);
    state.holdTheLineTimeout = setTimeout(() => {
      const index = state.holdTheLineIndex;
      state.chat = [
        ...state.chat,
        {
          type: messageTypes.assistant,
          t: `assistant.holdTheLine[${index}]`,
        },
      ];
      state.holdTheLineIndex = index + 1;
      dispatch('sendHoldTheLineMessage', {delay, intent});
    }, delay);
  },

  resetHoldTheLine({ state }) {
    console.log('assistant.resetHoldTheLine');
    clearTimeout(state.holdTheLineTimeout);
    state.holdTheLineIndex = 0;
  },
};

const mutations = {
  setValue(state, { key, value }) {
    state[key] = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

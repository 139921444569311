const state = {
  notifications: [],
};

const getters = {};

const actions = {
  async initNotificationNative() {
    const status = Notification?.permission;

    if (status === 'granted' || status === 'denied') {
      return status;
    }

    const permission = await Notification.requestPermission();
    return permission;
  },
  // @notification: {title: String, body: String }
  async showNotificationNative({ dispatch }, notification) {
    if (!notification) return false;

    const status = await dispatch('initNotificationNative');
    
    if (status !== 'granted') return false;
    
    const _notification = new Notification(notification.title, {
      body: notification.body,
    });

    _notification
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

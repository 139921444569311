<template>
  <div>
    <v-icon
      style="position: absolute; top: -8px; left: -12px; opacity: 0.7"
      v-if="!isCurrentTabActive && botHasLivechat"
      small
      color="primary lighten-4"
    >
      mdi-bell-off
    </v-icon>
    <PulseBoeble v-if="isShown" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import PulseBoeble from '@/components/common/PulseBoeble.vue';
export default {
  name: 'LivechatMenuBoeble',
  components: {
    PulseBoeble,
  },
  computed: {
    ...mapGetters('livechat', [
      'incomeType',
      'isCurrentTabActive',
      'botHasLivechat',
    ]),
    isShown() {
      if (
        this.incomeType === 'newMessage' ||
        (this.incomeType === 'newChat' && this.isCurrentTabActive)
      ) {
        return true;
      }

      return false;
    },
  },
};
</script>

<template>
  <v-chip label small :color="color">
    <v-icon left color="white" size="16">{{ icon }}</v-icon>
    <span class="text-uppercase text-body-2 white--text">{{ text }}</span>
  </v-chip>
</template>

<script>
const colorTypes = {
  default: 'default',
  warning: 'warning',
};

export default {
  name: 'ChipIcon',
  props: {
    text: {
      type: String,
      default: '...',
      required: true,
    },
    icon: {
      type: String,
      default: 'mdi-lightbulb',
    },
    color: {
      type: String,
      default: colorTypes.default,
    },
  },
};
</script>

<template>
  <v-app>
    <HubBg>
      <HubPanelLogo :loading="authenticating">
        <v-form @submit.prevent>
          <v-container>
            <v-row>
              <v-col>
                <h1 class="text-h5 mb-4">{{ $t('login.2fa.title') }}</h1>
                <p class="text-body-1">
                  {{ $t('login.2fa.subTitle') }}
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  outlined
                  hide-details
                  :label="$t('login.2fa.inputLabel')"
                  name="twofa_token"
                  v-model="twofa_token"
                  type="text"
                  v-on:keyup.enter.prevent="handleSubmit()"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="text-right">
                <v-btn
                  depressed
                  color="primary"
                  large
                  v-bind:loading="authenticating"
                  v-on:click="handleSubmit()"
                >
                  {{ $t('login.btnLabel') }}
                </v-btn>
              </v-col>
              <v-col v-if="authenticationError">
                <v-alert outlined type="error">
                  <span v-html="$t('login.2fa.errorMessage')"></span>
                </v-alert>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </HubPanelLogo>
    </HubBg>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import HubBg from '@/components/hub/HubBg.vue';
import HubPanelLogo from '@/components/hub/HubPanelLogo.vue';

export default {
  name: 'twofa',
  components: {
    HubBg,
    HubPanelLogo,
  },
  created() {
    this.loginReset();
  },
  data() {
    return {
      twofa_token: '',
    };
  },

  computed: {
    ...mapGetters('auth', ['authenticating', 'authenticationError']),
  },

  methods: {
    ...mapActions('auth', ['twofaLogin', 'loginReset']),
    ...mapActions('bots', ['clear']),

    async handleSubmit() {
      if (this.$route.params.token === '' || this.twofa_token === '') return;

      await this.twofaLogin({
        usertoken: this.$route.params.token,
        twofa_token: this.twofa_token,
      });
      this.clear();
      this.twofa_token = '';
    },
  },
};
</script>

<template>
  <div>
    <VueDraggable v-model="links" @end="updateLinks" handle=".drag-item">
      <v-card
        outlined
        v-for="(link, index) in links"
        :key="link.id"
        class="mb-2"
      >
        <v-progress-linear
          v-if="loading"
          indeterminate
          color="secondary darken-2"
          absolute
        ></v-progress-linear>
        <div class="d-flex align-center px-2">
          <div class="pt-1 drag-item">
            <v-icon>mdi-drag-vertical</v-icon>
          </div>
          <div
            class="flex-grow-1 text-body-1 pt-1 grey--text text-darken-2 primary--text text--lighten-2"
          >
            {{ `Link ${index + 1}:` }}
          </div>
          <BtnSquare
            class="mt-2"
            icon="mdi-delete"
            @click="deleteLink(index)"
          />
        </div>
        <div class="py-2 px-4">
          <v-text-field
            class="mb-2"
            v-model="link.title"
            outlined
            dense
            hide-details
            type="text"
            :label="`Text`"
            @keyup="updateLinks"
          ></v-text-field>
          <v-text-field
            class="mb-2"
            v-model="link.href"
            outlined
            dense
            hide-details
            type="text"
            :label="`URL`"
            @keyup="updateLinks"
          ></v-text-field>
        </div>
        <div class="d-flex"></div>
      </v-card>
    </VueDraggable>
    <v-btn outlined @click="addLink">
      {{ $t('editor.widget.startscreen.addLink') }}
      <v-icon outlined right>mdi-plus</v-icon>
    </v-btn>
  </div>
</template>
<script>
import VueDraggable from 'vuedraggable';
import BtnSquare from '@/components/common/BtnSquare.vue';

export default {
  name: 'WidgetPrivacyLinks',
  components: {
    VueDraggable,
    BtnSquare,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      links: this.value,
    };
  },
  methods: {
    updateLinks() {
      this.$emit('input', this.links);
    },
    deleteLink(index) {
      if (index >= 0) this.links.splice(index, 1);
    },
    addLink() {
      this.links.push({
        title: '',
        href: '',
        id: 'link-' + Math.random().toString(36).substr(2, 9),
      });
    },
  },
};
</script>

<template>
    <div>
        <v-row v-for="(button, index) in buttons" :key="index" data-cy="action-button">
        <v-col cols="12" md="6">
            <v-text-field
                label="Button Text"
                placeholder="Name"
                v-model="button.title.data"
                @input="update"
                data-cy="button-text-input"
            ></v-text-field>
        </v-col>
        <v-col cols="12"  md="6">
            <v-text-field
                label="Link"
                placeholder="Url"
                v-model="button.url.data"
                @input="update"
                append-icon="delete" @click:append="removeButton(index)" 
                data-cy="button-link-input"
            ></v-text-field>
        </v-col>
        </v-row>
         <v-row v-if="buttons.length < 3">
            <v-col cols="12">
               <v-btn text @click="addButton"><v-icon small>add</v-icon> Link</v-btn>
            </v-col>
         </v-row>
    </div>
                    
</template>

<script>
    
    export default {
        name: 'ActionButtonList',
        props: {
            buttons: Array,
            channelType: {
                type: String,
                default: 'widget'
            }
        },
        computed: {
            isDeletable: function() {
                if (this.deletable !== undefined) {
                    return 'delete';
                } else  return null;
            },
        },
        methods: {
            update: function() {
                this.$emit('onUpdated');
            },
            addButton: function() {
                this.$emit('addButton');
            },
            removeButton: function(index) {
                this.$emit('removeButton', index);
            }
        }
    }
</script>
<style scoped scss>

.warning-border {
   
   border-left: var(--v-warning-base) solid 5px;
}
</style>
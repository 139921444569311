<template>
  <!-- A blanko error message line. E.g. used under checkbox component. -->
  <div>
    <div class="v-messages v-messages__message message-transition-enter-to error--text">{{ text }}</div>
  </div>
</template>
<script>
export default {
  name: 'ErrorMessage',
  components: {},
  props: {
    text: {
      type: String,
      required: true,
    },
  },
};
</script>

<template>
  <div>
    <v-card>
      <v-card-title>Hubspot authorisieren via OAuth</v-card-title>
      <v-card-subtitle>
        HubSpot authorisieren damit via Botformulare die Daten in einen Hubspot
        Lead übertragen werden können
      </v-card-subtitle>

      <v-form ref="form">
        <v-col>
          <v-text-field
            label="OAuth-URL"
            placeholder="OAuth-URL"
            v-model="url"
            :rules="rules.url"
            :disabled="true"
          />
          <p class="primary--text">Format: https://app.hubspot.com</p>
        </v-col>

        <!-- Uncomment if needed -->
        <!-- <v-col>
          <v-text-field
            :label="$t('integrations.hubspot.appId')"
            placeholder="App ID"
            v-model="appId"
            :rules="rules.appId"
            :disabled="isLoading"
          />
          <p class="primary--text">{{ $t('integrations.hubspot.appIdInfo') }}</p>
        </v-col> -->

        <v-col>
          <v-text-field
            :label="$t('integrations.hubspot.scope')"
            placeholder="scope"
            v-model="scope"
            :disabled="isLoading"
          />
          <p class="primary--text" v-html="$t('integrations.hubspot.scopeHint')"></p>
        </v-col>
      </v-form>

      <v-card-actions>
        <v-spacer></v-spacer>
        <!-- <v-btn v-if="isConfigured" color="primary lighten-2" outlined small @click="resetOriginalConfig()" :disabled="!isChanged">
          {{ $t('common.reset') }}
          <v-icon right dark>refresh</v-icon>
        </v-btn> -->
        <v-btn color="success" dark small @click="oauth()" :disabled="isLoading">
          Auth
          <v-icon right dark>mdi-robot-love-outline</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-snackbar v-model="savingToast" :timeout="6000">
      <div class="d-flex align-center">
        <v-icon class="mr-2" color="green" type="success">check</v-icon>
        <div>Oauth success</div>
      </div>
    </v-snackbar>

    <v-snackbar v-model="savingToastError" :timeout="6000">
      <div class="d-flex align-center">
        <v-icon class="mr-2" color="red">mdi-alert-circle</v-icon>
        <div>{{ message || $t('common.notSavedError') }}</div>
      </div>
    </v-snackbar>
</div>
</template>
<script>

import { mapGetters } from 'vuex'
import { _rootConfig } from '@/components/integrations/integrationConstants';
import integrationTypeConsts from '@/components/integrations/integrationTypeConsts';
import SettingsService from "@/services/settings";


export default {
  name: "HubspotAuth",
  computed: {
    ...mapGetters('bots', ['currentBotId']),
    localProviderConfigByKey() {
      return this.$store.getters['integrations/localProviderConfigByKey']({ provider: this.provider, key: this.key });
    }
  },
  data() {
    return {
      provider: integrationTypeConsts.hubspot,

      key: 'default',
      message: "",
      savingToast: false,
      savingToastError: false,
      isChanged: false,

      originConfig: {},
      currConfig: {},

      url: '',
      appId: '',
      scope: '',

      isConfigured: false,
      isLoading: false,

      rules: {
        appId: [
          // Optional field, can be empty
					(v) => (v || '').length <= 32 || this.$t("integrations.rules.maxLength", {count: 32}),
        ],
        url: [
          (v) => /^(https):\/\/[^ "]+$/.test(v) || this.$t('integrations.rules.url'),
          (v) => /[A-z0-9]$/i.test(v) || `Not valid url. Url can't ends with special symbol!`,
        ],
      },
    };
  },
  methods: {
    async checkProvider() {
      this.isConfigured = await this.$store.dispatch('integrations/checkIfProviderExists', {
        provider: this.provider
      });
    },
    async fetchConfig() {
      this.originConfig = await this.$store.dispatch('integrations/fetchProviderVersions', {
        provider: this.provider, key: _rootConfig // Another key. Because this logic collects all root in reserved key
      });
    },
    async init() {
      await this.checkProvider();

      this.currConfig = this.localProviderConfigByKey || {}; // computed getter

      if (this.isConfigured && !Object.keys(this.originConfig).length) {
        await this.fetchConfig();
        if (!Object.keys(this.currConfig).length) this.currConfig = { ...this.originConfig };
      }

      this.url = this.currConfig?.url || 'https://app.hubspot.com'; // Use default URL
      this.appId = this.currConfig?.appId || '';
      this.scope = this.currConfig?.scope || 'crm.objects.contacts.write'; // Default scope for create contact
    },
    /**
     * We need to save clientId and clientSecret because BackEnd auth shall use that in token verification/redirect logic.
     * It can be redone, when we will refactor BackEnd to accept and object instead of params.
     * Because atm if we will put another data it will be very complicated.
		 */
		async saveConfig() {
			const { key, ...config } = this.parseConfig();
      let response = {};

      if (!this.isConfigured) {
        response = await SettingsService.setProviderSettings({
          botId: this.currentBotId,
          provider: this.provider,
          config,
        });
      } else {
        response = await SettingsService.updateProviderVersionSettings({
          botId: this.currentBotId,
          provider: this.provider,
          config,
          // This will indicate that we target root config
          key: _rootConfig,
        });
      }

      return response; // response: { success: boolean, data: any, error: any }
		},
    async oauth() {
      this.isLoading = true;

      const HUB_URL = process.env.VUE_APP_HUB_URL || 'https://hub.moin.ai';
      const redirectUrl = `${HUB_URL}/oauth/hubspot`;

      const config = this.parseConfig();
      const connectionSymbol = config.scope.startsWith('&') ? '' : ' ' ; // Adds ability to use 'optional_scope' query param (and others if needed)

      const scope = `oauth${config.scope.length ? `${connectionSymbol}${config.scope}` : ''}`;

      // Just in case save config locally
      this.saveConfigLocally();

      const { success, data, error } = await this.saveConfig();

      if (!success) {
        // Add extra validation info
        let extraInfo = '';
        if (error?.data?.result?.[0]?.message) extraInfo = error.data.result[0].message;

        this.message = `${(error.message || error)} ${extraInfo}`;
        this.savingToastError = true;
        this.isLoading = false;
        return;
      }

      const baseUrl = this.url;
      const clientId = '05fed4a8-2d6d-4c45-b699-a00c4c120970';

      const oauthUrl = `${baseUrl}/oauth/authorize?client_id=${clientId}&redirect_uri=${encodeURI(redirectUrl)}&scope=${encodeURI(scope)}`;

      window.open(oauthUrl)

      // We don't know 100% here if it is success, because the logic will run at the BackEnd. But at least config will be saved
      // But we will show success message for better UX
      this.savingToast = true;
      this.isLoading = false;
    },
    /**
     * Parses config to the proper format
     */
    parseConfig() {
      return {
        key: this.key,
        url: this.url?.trim(),
        appId: this.appId?.trim(),
        scope: this.scope?.trim(),
      };
    },
    /**
     * Saves config to local storage
     * @method saveConfigLocally
     */
    saveConfigLocally: _.debounce(function () {
      this.$store.commit('integrations/setProviderConfigLocal', {
        provider: this.provider, config: this.parseConfig()
      });
    }, 500),
    checkForLocalChange(val, oldVal) {
      if (!this.isInitialized) return;
      if (val === oldVal) return;
      this.isChanged = true;
      this.saveConfigLocally();
    },
  },
  watch: {
    url(val, oldVal) { this.checkForLocalChange(val, oldVal); },
    appId(val, oldVal) { this.checkForLocalChange(val, oldVal); },
    scope(val, oldVal) { this.checkForLocalChange(val, oldVal); },
  },
  created() {
    this.init();
  },
};
</script>
<template>
  <div class="d-flex pt-1">
    <v-tooltip bottom color="primary lighten-3">
      <template v-slot:activator="{ on: onGoals, attrs: attrsGoals }">
        <div class="d-flex" style="width: 130px; position: relative; top: -3px">
          <v-icon size="16" class="mr-1">outlined_flag</v-icon>
          <div class="primary--text" v-on="onGoals" v-bind="attrsGoals">
            {{ calcPro(countGoals, countAll) }}
          </div>
        </div>
      </template>
      <span>
        {{
          $t('stats.conversionsCountGoals', {
            goals: countGoals,
            count: countAll,
          })
        }}
      </span>
    </v-tooltip>

    <div class="bar relative">
      <v-skeleton-loader
        v-if="isLoading"
        type="image"
        height="20"
        width="100%"
      />
      <v-tooltip bottom v-if="!isLoading" color="primary lighten-3">
        <template v-slot:activator="{ on: onAll, attrs: attrsAll }">
          <div
            class="bar-all grey lighten-2"
            v-on="onAll"
            v-bind="attrsAll"
          ></div>
        </template>
        <span>
          {{
            $t('stats.conversionsCountGoalsNot', {
              goals: countAll - countGoals,
            })
          }}
        </span>
      </v-tooltip>
      <v-tooltip bottom v-if="!isLoading" color="primary lighten-3">
        <template v-slot:activator="{ on: onPos, attrs: attrsPos }">
          <div
            class="bar-inline relative primary darken-1"
            :style="{ width: goalsPro }"
            v-on="onPos"
            v-bind="attrsPos"
          ></div>
        </template>
        <span>
          {{
            $t('stats.conversionsCountGoalsOf', {
              goals: countGoals,
              count: countAll,
            })
          }}
        </span>
      </v-tooltip>
    </div>
  </div>
</template>
<script>
import { formatNumberFixedOne } from '@/utils/formatNumber';

export default {
  name: 'StatsBarCountConversion',
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    countAll: {
      type: Number,
      default: 1000,
    },
    countGoals: {
      type: Number,
      default: 0,
    },
    language: {
      type: String,
      default: 'de',
    },
  },
  computed: {
    goalsPro() {
      let goalsPro = (this.countGoals / this.countAll) * 100;
      goalsPro = formatNumberFixedOne(goalsPro);
      if (goalsPro > 100) goalsPro = 100;
      goalsPro = `${goalsPro}%`;
      return goalsPro;
    },
  },
  methods: {
    getCommaString(value) {
      if (this.language === 'de') {
        return String(value).replace('.', ',');
      }
      return value;
    },
    calcPro(count, countAll) {
      let pro = (count / countAll) * 100;
      pro = formatNumberFixedOne(pro);
      return `${pro}%`;
    },
  },
};
</script>
<style lang="scss" scoped>
$bar-height: 16px;
.bar {
  height: $bar-height;
  width: 100%;
}
.bar-all {
  height: $bar-height;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.bar-inline {
  height: $bar-height;
}
</style>

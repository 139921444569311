<template>
  <v-app style="max-height: 100vh; height: 100vh">
    <HubMenu />
    <Appbar />
    <HubContent :isSidebarOpen="isSidebarOpen" fixedHeight>
      <template v-slot:navbar>
        <HubContentNavbar
          :title="$t('botSettings.roles.title')"
          backTo="/settings"
          :isLoading="isLoading"
        >
          <template v-slot:toolbar>
            <BtnSquare
              icon="mdi-cog"
              :title="$t('botSettings.roles.generalSettings.title')"
              @click="toggleSettingSidebar()"
            />
          </template>
        </HubContentNavbar>
      </template>
      <template v-slot:sidebar>
        <HubContentSidebarSimple
          :isOpen="isSidebarOpen"
          @onClose="isSidebarOpen = false"
          :title="
            selectedUser[0].username
              ? $t('botSettings.roles.userSettings.userTitle')
              : $t('botSettings.roles.generalSettings.sidebarTitle')
          "
          :icon="selectedUser[0].username ? 'info' : 'mdi-cog'"
        >
          <div class="pa-4">
            <div v-if="selectedUser[0].username">
              <h3 class="text-h5 my-3 text-truncate">
                {{ selectedUser[0].username }}
              </h3>
              <div class="text-body-2 mb-8">
                <span
                  v-html="$t('botSettings.roles.userSettings.sidebarInfo')"
                ></span>
              </div>
              <SettingsUserStatus
                class="mb-4"
                :user="selectedUser[0]"
                @onChange="
                  fetchUsers();
                  trackIt('user-reinvited');
                "
              />
              <SettingsUserRole
                v-if="
                  !selectedUser[0].isItself &&
                    selectedUser[0] &&
                    selectedUser[0].active
                "
                class="mb-4"
                :user="selectedUser[0]"
                @onChanged="fetchUsers()"
              />
              <SettingsDeleteUser
                v-if="
                  !selectedUser[0].isItself &&
                    selectedUser[0] &&
                    selectedUser[0].active
                "
                class="mb-4"
                :user="selectedUser[0]"
                :currentBotId="currentBotId"
                @onDeleted="fetchUsers"
              />
            </div>
            <div v-else>
              <h3 class="text-h5 my-3">
                {{ $t('botSettings.roles.generalSettings.sidebarTitle') }}
              </h3>
              <div class="text-body-2 mb-8">
                <span
                  v-html="$t('botSettings.roles.generalSettings.sidebarInfo')"
                ></span>
              </div>
              <div class="d-flex flex-col gap-4">
                <Settings2Auth
                  :activatedInitial="allUsersHave2FA"
                  @onChange="
                    fetchUsers();
                    trackIt('2auth-feature');
                  "
                />
                <SettingsSSO
                  v-if="ssoEnabled"
                  @onChange="
                    fetchUsers();
                    trackIt('sso-feature');
                  "
                />
              </div>
            </div>
          </div>
        </HubContentSidebarSimple>
      </template>
      <template v-slot:content>
        <HubFrame>
          <template v-slot:title>
            <h2 class="text-h5">{{ $t('botSettings.roles.allUsers') }}</h2>
          </template>
          <template v-slot:actions>
            <SettingsNewUser
              :with2FA="allUsersHave2FA"
              @onInvited="
                fetchUsers();
                trackIt('user-invitation');
              "
            />
          </template>
          <template v-slot:content>
            <v-container fluid class="pa-4">
              <v-row>
                <v-col>
                  <div class="d-flex">
                    <v-icon color="primary lighten-3" size="16">
                      mdi-filter
                    </v-icon>
                    <div class="text-body-2 primary--text text--lighten-2 pl-1">
                      {{ $t('botSettings.roles.filterStatusLabel') }}
                    </div>
                  </div>
                  <ChipFilter
                    :options="statusFilterItems"
                    @onChange="changeStatusFilter"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-data-table
                    :value="selectedUser"
                    item-key="username"
                    :headers="usersHeaders"
                    :items="usersFiltered"
                    @click:row="selectUser"
                    single-select
                    :items-per-page="20"
                    :footer-props="{
                      'items-per-page-options': [10, 20, 50],
                    }"
                    :hide-default-footer="usersFiltered.length < 10"
                  >
                    <template v-slot:[`item.username`]="{ item }">
                      <span>{{ item.username }}</span>
                      <span
                        v-if="item.isItself"
                        class="primary--text text--lighten-4"
                      >
                        ({{ $t('botSettings.me') }})
                      </span>
                    </template>
                    <template v-slot:[`item.active`]="{ item }">
                      <span v-if="item.active">
                        {{ $t('botSettings.userStatus.active') }}
                      </span>
                      <span
                        v-if="!item.active && !item.isExpired"
                        class="grey--text"
                      >
                        {{ $t('botSettings.userStatus.pending') }}
                      </span>
                      <span v-if="item.isExpired" class="grey--text">
                        {{ $t('botSettings.userStatus.expired') }}
                      </span>
                    </template>
                    <template v-slot:[`item.role`]="{ item }">
                      <span v-if="!item.role">
                        {{ $t(`botSettings.roles.role.unknown`) }}
                      </span>
                      <span v-else>
                        {{ $t(`botSettings.roles.role.${item.role}`) }}
                      </span>
                    </template>
                    <template v-slot:[`item.edit`]>
                      <v-icon color="primary lighten-3">info</v-icon>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </template>
        </HubFrame>
      </template>
    </HubContent>
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import HubMenu from '@/components/hub/HubMenu.vue';
import Appbar from '@/components/Appbar.vue';
import HubContent from '@/components/hub/HubContent.vue';
import HubContentNavbar from '@/components/hub/HubContentNavbar.vue';
import HubContentSidebarSimple from '@/components/hub/HubContentSidebarSimple.vue';
import HubFrame from '@/components/hub/HubFrame.vue';
import BtnSquare from '@/components/common/BtnSquare.vue';
import SettingsNewUser from '@/components/settings/SettingsNewUser.vue';
import Settings2Auth from '@/components/settings/Settings2Auth.vue';
import SettingsDeleteUser from '@/components/settings/SettingsDeleteUser.vue';
import SettingsUserStatus from '@/components/settings/SettingsUserStatus.vue';
import SettingsUserRole from '@/components/settings/SettingsUserRole.vue';
import ChipFilter from '@/components/common/ChipFilter.vue';
import BotService from '@/services/bot';
import { getRoleByFeatures, rolesTypes } from '@/utils/userRoles';
import { TrackingService } from '@/services/tracking';
import SettingsSSO from '@/components/settings/SettingsSSO.vue';

const filterStatusTypes = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  EXPIRED: 'expired',
};

export default {
  name: 'SettingsRoles',
  components: {
    SettingsSSO,
    HubMenu,
    Appbar,
    HubContent,
    HubContentNavbar,
    HubContentSidebarSimple,
    HubFrame,
    BtnSquare,
    SettingsNewUser,
    Settings2Auth,
    SettingsUserStatus,
    SettingsDeleteUser,
    SettingsUserRole,
    ChipFilter,
  },
  data() {
    return {
      isLoading: true,
      isSidebarOpen: false,
      selectedUser: [{ username: null }],
      users: [],
      allUsersHave2FA: false,
      selectedFilterStatus: [],
      ssoEnabled: false,
    };
  },
  computed: {
    ...mapGetters('bots', ['currentBotId', 'getBotSettings']),
    ...mapGetters('auth', ['user']),
    usersHeaders() {
      return [
        {
          text: this.$t('botSettings.roles.table.userName'),
          align: 'start',
          value: 'username',
        },
        { text: this.$t('botSettings.roles.table.status'), value: 'active' },
        { text: this.$t('botSettings.roles.table.role'), value: 'role' },
        { text: '', value: 'edit', sortable: false, align: 'end' },
      ];
    },
    statusFilterItems() {
      const amountActive = this.users.filter((u) => u.active).length || 0;
      const amountInactive =
        this.users.filter((u) => !u.active && !u.isExpired).length || 0;
      const amountExpired = this.users.filter((u) => u.isExpired).length || 0;

      let items = [
        {
          name: this.$t('botSettings.userStatus.active'),
          value: filterStatusTypes.ACTIVE,
          icon: 'mdi-account-check',
          amount: amountActive,
          isDisabled: !amountActive,
        },
        {
          name: this.$t('botSettings.userStatus.pending'),
          value: filterStatusTypes.INACTIVE,
          icon: 'mdi-account-arrow-left-outline',
          amount: amountInactive,
          isDisabled: !amountInactive,
        },
        {
          name: this.$t('botSettings.userStatus.expired'),
          value: filterStatusTypes.EXPIRED,
          icon: 'mdi-account-cancel-outline',
          amount: amountExpired,
          isDisabled: !amountExpired,
        },
      ];

      // Get active status from chip-group
      items = items.map((item, index) => {
        item.isActive = this.selectedFilterStatus.includes(index);
        return item;
      });

      return items;
    },
    filterStatusActivated() {
      return !!this.statusFilterItems.find((i) => i.isActive);
    },
    filterStatusActive() {
      return this.statusFilterItems.find(
        (i) => i.value === filterStatusTypes.ACTIVE
      );
    },
    filterStatusInactive() {
      return this.statusFilterItems.find(
        (i) => i.value === filterStatusTypes.INACTIVE
      );
    },
    filterStatusExpired() {
      return this.statusFilterItems.find(
        (i) => i.value === filterStatusTypes.EXPIRED
      );
    },
    usersFiltered() {
      const users = [];
      this.users.forEach((u) => {
        if (!this.filterStatusActivated || this.filterStatusActive.isActive) {
          if (u.active) users.push(u);
        }
        if (!this.filterStatusActivated || this.filterStatusInactive.isActive) {
          if (!u.active && !u.isExpired) users.push(u);
        }
        if (!this.filterStatusActivated || this.filterStatusExpired.isActive) {
          if (u.isExpired) users.push(u);
        }
      });

      return users;
    },
  },
  created() {
    this.fetchUsers();
    this.fetchQuotas();
    this.trackIt();
  },
  methods: {
    async fetchQuotas() {
      const { quota } = this.getBotSettings(this.currentBotId);
      if (quota?.sso?.enabled) {
        this.ssoEnabled = true;
      }
    },
    async fetchUsers() {
      this.isLoading = true;
      this.selectedUser = [{ username: null }];
      let usersExisting = await BotService.getAccounts(this.currentBotId);
      let invites = await BotService.getBotInvites(this.currentBotId);
      const users = [];
      let usersHave2FA = false;

      // still used invites are filtered out
      if (Array.isArray(invites)) {
        invites = invites.filter((i) => !i.usedAt);
      }

      // sort invitations by maxDate
      if (Array.isArray(invites)) {
        invites.sort((a, b) => {
          return new Date(b.maxDate) - new Date(a.maxDate);
        });
      }

      // Ignore account if it's already in existing users, or admin
      invites.forEach((user) => {
        // No doubles
        const existingItem = users.find((i) => user.username === i.username);
        if (existingItem) return;

        user.role = getRoleByFeatures(user.features);
        if (user.role === rolesTypes.ADMIN) return;
        if (!user.role) return;

        const existingUser = usersExisting.find(
          (u) => u.username === user.username
        );
        if (existingUser) return;
        // No invites for user herself
        if (user.username === this.user.username) return;

        // No expired invitations older then 2 weeks
        const exDate2Weeks = moment(user.maxDate).add(2, 'weeks');
        if (moment().isSameOrAfter(exDate2Weeks)) return;
        users.push(user);
      });

      usersExisting.forEach((user) => {
        // No doubles
        const existingItem = users.find((i) => user.username === i.username);
        if (existingItem) return;

        user.role = getRoleByFeatures(user.features);

        if (user.role === rolesTypes.ADMIN) return;

        if (user.active) {
          // Will be false, if one user has no 2FA.
          usersHave2FA = user.features.includes('2FA');
          users.push(user);
        }
      });

      // Check expired status
      //  & Is user itself
      users.forEach((u) => {
        if (u.username === this.user?.username) {
          u.isItself = true;
        }
        if (!u.active && moment().isSameOrAfter(u.maxDate)) {
          u.isExpired = true;
        } else {
          u.isExpired = false;
        }
      });

      this.users = users;
      this.allUsersHave2FA = usersHave2FA;

      this.isLoading = false;
    },
    toggleSettingSidebar() {
      if (!this.isSidebarOpen) {
        this.selectedUser = [{ username: null }];
        this.isSidebarOpen = true;
        return;
      }
      if (this.isSidebarOpen && this.selectedUser[0].username) {
        this.selectedUser = [{ username: null }];
        return;
      }

      this.isSidebarOpen = false;
    },
    selectUser(user) {
      this.selectedUser = [user];
      this.isSidebarOpen = true;
    },
    changeStatusFilter(selectedItems) {
      this.selectedFilterStatus = selectedItems;
    },
    trackIt(type = 'view') {
      TrackingService.trackSettingsRoles({
        botId: this.currentBotId,
        type: type,
        source: 'SettingsRoles.vue',
      });
    },
  },
};
</script>

<template>
  <v-col cols="12" class="text-right">
    <v-btn
      :disabled="!isAssistantDirty"
      class="mr-4"
      text
      color="primary lighten-2"
      @click="resetAssistant()"
    >
      <slot name="discardbutton">
        {{ $t('editor.common.discard') }}
        <v-icon right>delete_outline</v-icon>
      </slot>
    </v-btn>
    <v-btn
      :disabled="!isAssistantDirty || loading"
      depressed
      color="assistant"
      :class="isAssistantDirty ? 'white--text' : ''"
      @click="acceptAssistant()"
    >
      <slot name="savebutton">
        {{ $t('assistant.btnAccept') }}
        <v-icon right>mdi-creation</v-icon>
      </slot>
    </v-btn>
  </v-col>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  name: 'AssistantHubBtns',
  components: {},
  computed: {
    ...mapGetters('assistant', ['loading', 'isAssistantDirty']),
  },
  methods: {
    resetAssistant() {
      this.$store.dispatch('assistant/resetDraft', { withMessage: true });
    },
    acceptAssistant() {
      this.$store.dispatch('assistant/acceptDraft');
    },
  },
};
</script>

<template>
  <v-app>
    <HubMenu />
    <Appbar />
    <HubContent>
      <template v-slot:navbar>
        <HubContentNavbar
          :title="$t('channels.headline')"
          :isLoading="intentsLoading"
        />
      </template>
      <template v-slot:content>
        <v-container fluid class="pa-6">
          <v-row>
            <v-col>
              <h3 v-if="isAdmin" class="mb-1">Production</h3>
              <v-simple-table class="fixed-table">
                <thead>
                  <tr>
                    <th width="60%" class="text-left">Channel</th>
                    <th width="20%" class="text-left">Type</th>
                    <th class="text-right"><!-- empty --></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="channel in getProductionChannel"
                    v-bind:key="channel.channelId"
                  >
                    <td>{{ displayName(channel) }}</td>
                    <td>
                      <v-icon>{{ channelTypeValues(channel).icon }}</v-icon>
                      {{ $t(channelTypeValues(channel).name) }}
                    </td>
                    <td class="text-right">
                      <v-btn
                        @click="
                          codeToClipboard(
                            currentBot.uniqueBotId,
                            channel.channelId
                          )
                        "
                        title="Copy Embedd Code"
                        icon
                      >
                        <v-icon>mdi-code-tags</v-icon>
                      </v-btn>
                      <v-btn
                        :to="`/channels/setting/${currentBot.uniqueBotId}/${channel.channelId}`"
                        icon
                      >
                        <v-icon>mdi-cog</v-icon>
                      </v-btn>
                      <!-- <div v-else style="display: inline-block; width: 36px; height: 24px">&nbsp;</div> -->
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>
          <v-row v-if="isAdmin">
            <v-col>
              <h3 class="mb-1">Preview</h3>
              <v-simple-table v-if="isAdmin" class="fixed-table">
                <thead class="blue lighten-2">
                  <tr>
                    <th width="60%" class="text-left">Channel</th>
                    <th width="20%" class="text-left">Type</th>

                    <th class="text-right"><!-- empty --></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="channel in getStagingChannel"
                    v-bind:key="channel.channelId"
                  >
                    <td>{{ displayName(channel) }}</td>
                    <td>
                      <v-icon>{{ channelTypeValues(channel).icon }}</v-icon>
                      {{ $t(channelTypeValues(channel).name) }}
                    </td>
                    <td class="text-right">
                      <v-btn
                        @click="
                          codeToClipboard(
                            currentBot.stagingBot,
                            channel.channelId
                          )
                        "
                        title="Copy Embedd Code"
                        icon
                      >
                        <v-icon>mdi-code-tags</v-icon>
                      </v-btn>
                      <v-btn
                        :to="`/channels/setting/${currentBot.stagingBot}/${channel.channelId}`"
                        icon
                      >
                        <v-icon>mdi-cog</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </HubContent>
    <!-- SUBVIEW FOR EDITING / DIALOG -->
    <router-view name="dialog" />
  </v-app>
</template>


<script>
import HubMenu from '@/components/hub/HubMenu.vue';
import Appbar from '@/components/Appbar.vue';
import { mapGetters } from 'vuex';
import HubContent from '@/components/hub/HubContent.vue';
import HubContentNavbar from '@/components/hub/HubContentNavbar.vue';

export default {
  name: 'Channels',
  components: {
    HubMenu,
    Appbar,
    HubContent,
    HubContentNavbar,
  },
  computed: {
    settingVisible() {
      return this.$route.params && this.$route.params.channelid;
    },
    ...mapGetters('bots', [
      // create properties from auth store
      'currentBot',
      'currentChannels',
      'getBot',
    ]),
    ...mapGetters('intents', [
      // create properties from auth store
      'intents',
      'bookedIntents',
    ]),
    intentsLoading: function () {
      return this.intents === false;
    },
    ...mapGetters('auth', ['isAdmin']),
    getProductionChannel() {
      const uniqueBotId = this.currentBot.uniqueBotId;
      return this.getBot(uniqueBotId).channels;
    },
    getStagingChannel() {
      const stagingBotID = this.currentBot.stagingBot;
      return this.getBot(stagingBotID).channels;
    },
  },
  data() {
    return {
      showNewIntentDialog: false,
      opendChannel: this.$route.params.channelid,
    };
  },
  methods: {
    displayName(channel) {
      return channel.displayName ? channel.displayName : channel.channelId;
    },
    channelTypeValues(channel) {
      switch (channel.channel) {
        case 'widget':
          return {
            icon: 'mdi-comment-processing-outline',
            name: 'channels.website',
          };
        case 'facebook':
          return { icon: 'mdi-facebook-messenger', name: 'channels.facebook' };
        case 'userlike':
          return { icon: 'support_agent', name: 'channels.userlike' };
      }
      return { icon: '', name: 'channel.unknown' };
    },
    codeToClipboard(botId, channelId) {
      let script = 'script';
      /* if (this.currentChannels && this.currentChannels.length > 1) {
            channelId = '&channelid=' + this.channel.channelId;
      } */
      const snippet = `<${script} type="text/javascript" id="moinloader" src="https://widget.moin.ai/moin-loader.js?id=${botId}&channelid=${channelId}"></${script}>`;
      window.navigator.clipboard.writeText(snippet).then(
        function () {
          console.log('Async: Copying to clipboard was successful!');
        },
        function (err) {
          console.error('Async: Could not copy text: ', err);
        }
      );
      /* return `<${script} type="text/javascript" id="moinloader" src="https://widget.moin.ai/moin-loader.js?id=${botId}${channelId}"></${script}>`; */
    },
  },
};
</script>
<style>
.fixed-table table {
  table-layout: fixed;
}
</style>

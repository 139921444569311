export const typeTypes = {
  DEFAULT: 'default',
  SMALLTALK: 'smalltalk',
  FORM: 'form',
  AI_TEMPLATE: 'aiTemplate',
  AI_SUGGESTION: 'aiSuggestion',
};

export const statusTypes = {
  ALL_LIVE: 'allLive',
  ALL_NOT_LIVE: 'allNotLive',
  LIVE: 'live',
  PREVIEW: 'preview',
  REDIRECTED: 'redirected',
  DEACTIVATED: 'deactivated',
  IN_REVIEW: 'inReview',
  UNKNOWN: 'unknown',
};

/**
 * @typedef {'unused'|'previewOnly'|'mixed'|'allBoth'|'liveOnly'} RagResourceIntentsStatus
 */
/**
 * Status similar to `statusType`, except this status
 * targets multiple intents per resource, in a single channel.
 */
export const ragResourceIntentsStatus = {
  /**
   * No intents use this resource.
   * For example,
   *
   * Live:
   *
   * Preview:
   */
  UNUSED: 'unused',
  /**
   * In the intents that use the resource, all of them have it in preview only.
   * For example,
   *
   * Live:
   *
   * Preview: Intent A, Intent B
   */
  PREVIEW_ONLY: 'previewOnly',
  /**
   * There's an inconsistent mix of intents that are in live and in preview.
   * For example,
   *
   * Live: Intent A, Intent C
   *
   * Preview: Intent A, Intent B, Intent D
   */
  MIXED: 'mixed',
  /**
   * The Live and Preview is identical.
   * For example,
   *
   * Live: Intent A, Intent B
   *
   * Preview: Intent A, Intent B
   */
  ALL_BOTH: 'allBoth',
  /**
   * All intents that use this resource are only in live.
   * For example,
   *
   * Live: Intent A, Intent B
   *
   * Preview:
   */
  LIVE_ONLY: 'liveOnly',
}

export const deployStatusTypes = {
  CHANNELS_CHANGED: 'channelsChanged',
  CHANNELS_NOT_CHANGED: 'channelsNotChanged',
  CHANGED: 'changed',
  NOT_CHANGED: 'notChanged',
};

const defaultIconColor = 'primary lighten-3';

/**
 * Where key is {@link RAGResourceType}
 */
export const ragTypeIcons = {
  undefined: 'mdi-question',
  PDF: 'mdi-file',
  WEBPAGE: 'mdi-link',
  MARKDOWN: 'mdi-format-text',
  QNA: 'mdi-comment-question-outline',
  TABLE: 'mdi-file-table',
}

export const typeIcons = {
  undefined: {
    icon: 'mdi-folder-text',
    iconColor: defaultIconColor,
  },
  [typeTypes.DEFAULT]: {
    icon: 'mdi-folder-text',
    iconColor: defaultIconColor,
  },
  [typeTypes.AI_TEMPLATE]: {
    icon: 'mdi-folder-text',
    iconColor: defaultIconColor,
  },
  [typeTypes.FORM]: {
    icon: 'list_alt',
    iconColor: defaultIconColor,
  },
  [typeTypes.SMALLTALK]: {
    icon: 'coffee',
    iconColor: defaultIconColor,
  },
  [typeTypes.AI_SUGGESTION]: {
    icon: 'auto_awesome',
    iconColor: 'secondary darken-2',
  },
};

export const statusIcons = {
  undefined: {
    icon: 'mdi-circle-outline',
    iconColor: 'warning',
  },
  [statusTypes.ALL_LIVE]: {
    icon: 'mdi-check-circle-outline',
    iconColor: 'green',
  },
  [statusTypes.ALL_NOT_LIVE]: {
    icon: 'mdi-circle-outline',
    iconColor: 'warning',
  },
  [statusTypes.LIVE]: {
    icon: 'mdi-play-circle',
    iconColor: 'green',
  },
  [statusTypes.PREVIEW]: {
    icon: 'mdi-play-circle',
    iconColor: 'warning',
  },
  [statusTypes.DEACTIVATED]: {
    icon: 'mdi-pause-circle',
    iconColor: 'grey',
  },
  [statusTypes.DEACTIVATED]: {
    icon: 'mdi-pause-circle',
    iconColor: 'grey',
  },
  [statusTypes.REDIRECTED]: {
    icon: 'mdi-directions',
    iconColor: 'blue darken-1',
  },
  [statusTypes.IN_REVIEW]: {
    icon: 'hourglass_top',
    iconColor: 'primary lighten-3',
  },
  // unknown type
  [statusTypes.UNKNOWN]: {
    icon: '',
    iconColor: '',
  },
  [ragResourceIntentsStatus.UNUSED]: {
    icon: 'mdi-circle-outline',
    iconColor: 'grey',
  },
  [ragResourceIntentsStatus.ALL_BOTH]: {
    icon: 'mdi-check-circle-outline',
    iconColor: 'green',
  },
  [ragResourceIntentsStatus.MIXED]: {
    icon: 'join_inner',
    iconColor: 'warning',
  },
  [ragResourceIntentsStatus.LIVE_ONLY]: {
    icon: 'mdi-check-circle',
    iconColor: 'green',
  },
  [ragResourceIntentsStatus.PREVIEW_ONLY]: {
    icon: 'mdi-play-circle',
    iconColor: 'grey',
  },
};

export const deployIcons = {
  [deployStatusTypes.CHANNELS_NOT_CHANGED]: {
    icon: 'mdi-cloud-check-outline',
    iconColor: 'grey',
  },
  [deployStatusTypes.CHANNELS_CHANGED]: {
    icon: 'mdi-cloud-upload-outline',
    iconColor: 'black',
  },
  [deployStatusTypes.NOT_CHANGED]: {
    icon: 'mdi-cloud-check',
    iconColor: 'grey',
  },
  [deployStatusTypes.CHANGED]: {
    icon: 'mdi-cloud-upload',
    iconColor: 'black',
  },
};

export function getStatus(channels, selectedChannelId) {
  let allLive = true;
  let channelNameList;

  // error fallback, channels undefined or empty or {}
  if (!channels || channels.length === 0 || Object.keys(channels).length === 0) {
    return { status: statusTypes.UNKNOWN };
  }

  if (selectedChannelId === 'all') {
    channelNameList = Object.keys(channels);
  } else {
    // if channel information available, suggestions have only on first channel this info.
    if (channels[selectedChannelId]) {
      channelNameList = [selectedChannelId];
    } else {
      // take first channel
      channelNameList = [Object.keys(channels)[0]];
    }
  }

  channelNameList.forEach((k) => {
    if (channels[k].state !== 'success') {
      allLive = false;
    }
  });
  // More channels
  if (channelNameList.length > 1) {
    if (allLive) {
      return { status: statusTypes.ALL_LIVE };
    } else {
      return { status: statusTypes.ALL_NOT_LIVE };
    }
  }

  const channel = channels[channelNameList[0]];
  if (channel.state === 'success') {
    return { status: statusTypes.LIVE };
  }
  if (channel.state === 'error' && channel.forward) {
    return { status: statusTypes.REDIRECTED, forward: channel.forward };
  }
  if (channel.state === 'error') {
    return { status: statusTypes.DEACTIVATED };
  }
  if (channel.state === 'warning' && channel.forward) {
    return { status: statusTypes.REDIRECTED, forward: channel.forward };
  }
  if (channel.state === 'warning') {
    return { status: statusTypes.PREVIEW };
  }

  return { status: null };
}

export function getDeployStatus(intent) {
  return deployStatusTypes.CHANNELS_NOT_CHANGED;
  // if (intent.channels && !intent.isChanged) {
  // }
  // if (intent.channels && intent.isChanged) {
  //   return deployStatusTypes.CHANNELS_CHANGED;
  // }
  // if (!intent.channels && !intent.isChanged) {
  //   return deployStatusTypes.NOT_CHANGED;
  // }
  // if (!intent.channels && intent.isChanged) {
  //   return deployStatusTypes.CHANGED;
  // }
}

export function getIntentDisplayName(intent, intents) {
  if (!intents?.length) return '';

  const found = intents.find((i) => i.intent === intent);

  if (found) {
    return found.displayName;
  } else {
    return '';
  }
}

export default {
  typeTypes,
  statusTypes,
  deployStatusTypes,
  typeIcons,
  statusIcons,
  deployIcons,
  getStatus,
  getDeployStatus,
  getIntentDisplayName,
};

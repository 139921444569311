import { BotService } from '../../services/bot';

const state = {
  teaserConfig: {
    // default teaser config
    showTeaser: true,
    showAvatar: true,
    message: '',
    quickActions: [],
  },
  botId: false,
  channels: [],
  selectedChannel: 0,
  previewChannelId: false,
  dock: {},
};

const getters = {
  teaser: (state) => state.teaserConfig,
  teaserButtons: (state) => state.teaserConfig.quickActions,
  previewChannelId: (state) => state.previewChannelId,
  widgetConfig: (state) => {
    if (state.channels.length <= state.selectedChannel) {
      return {};
    }

    return state.channels[state.selectedChannel].config;
  },
  dock: (state) => state.dock,
  getBotId: (state) => state.botId,
  selectedChannel: (state) => {
    if (state.channels.length >= 0) {
      return state.channels[state.selectedChannel];
    }

    return false;
  },
  getWidgetConfig: (state) => (channelId) => {
    let channel = state.channels.find(
      (channel) => channel.channelId === channelId
    );

    if (!channel && state.channels.length > 0) {
      channel = state.channels[0];
    }

    if (channel && channel.channel === 'widget') {
      return channel.config;
    }

    return false;
  },
  channels: (state) => state.channels,
};

const actions = {
  async initPreviewStore(
    { commit, dispatch, rootGetters },
    { botId, channelId }
  ) {
    if (rootGetters['auth/user'] !== false && rootGetters['auth/user'] !== null) {
      let bot = await BotService.getBot(botId); // await dispatch('bots/getBotById', botId, { root: true });
      let isLiveBot = false;
      // if bot is staging bot, fetch the real bot
      if (bot.stagingBotFor) {

        // get preview widget channelId
        let previewChannel = bot.channels.find((channel) => channel.channel === 'widget');
        if (previewChannel) {
          commit('setPreviewChannel', previewChannel.channelId);
        }

        bot = await dispatch('bots/getBotById', bot.stagingBotFor, {
          root: true,
        });
      } else {
        isLiveBot = true;
        await dispatch('bots/getBotById', botId, {
          root: true,
        });
      }

      // live channels
      const channelIndex = bot.channels.findIndex(
        (channel) => channel.channelId === channelId
      );

      if (channelIndex > -1) {
        commit('setSelectedChannel', channelIndex);
      }
  
      if (isLiveBot) {
        commit('setPreviewChannel', channelId);
      }

      if (bot.channels.length > 0) {
        commit('setChannels', bot.channels);
      }
    }

    // set bot id
    commit('setBotId', botId);
    return true;
  },
  setTeaserMessage({ commit, dispatch }, message) {
    commit('setTeaserMessage', message);
    dispatch('showTeaser');
  },
  addTeaserButton({ commit, dispatch }, { intent, text }) {
    commit('addTeaserButton', { intent, text });
    dispatch('showTeaser');
  },
  removeTeaserButton({ commit, dispatch }, intent) {
    commit('removeTeaserButton', intent);
    dispatch('showTeaser');
  },
  setTeaserButtonText({ commit, dispatch }, { intent, text }) {
    commit('setTeaserButtonText', { intent, text });
    dispatch('showTeaser');
  },
  connectDock({ commit }, { pubsub, topics, config, botId }) {
    commit('setDock', { pubsub, topics, config, botId });
  },
  async resetWidget({ dispatch }, { botId, channelId }) {
  
    await window.moin.unmount();
    
    let storageKey = false;
    for (let i = 0; i < localStorage.length && storageKey === false; i++) {
      let key = localStorage.key(i);
      if (key.startsWith(`knowhere_jwt`) && key.endsWith(`${botId}`)) {
        storageKey = key;
      }
    }

    if (storageKey) {
      localStorage.removeItem(storageKey);
    }

    // dispatch('initWidget', { botId, channelId });
    // we do a full reload here, because the widget is not unmounted correctly
  },
  async initWidget({ state, dispatch, getters }, { botId, channelId }) {
    if (state.botId !== botId) {
      dispatch('resetStore');
    }

    if (!channelId) {
      await dispatch('initPreviewStore', { botId, channelId });
      channelId = state.channels[0]?.channelId;
    }

    // need to proxy the moin object to intercept when addContext is ready
    const proxyWindow = {};
    proxyWindow.moin = window.moin;
    Object.defineProperty(window, 'moin', {
      set: function(value) {
        if (value === null) return true; // because of unmount

        const moinProxy = new Proxy(value, {
          get: function(target, prop) {
            return target[prop];
          },
          set: function(target, prop, value) {
            if (prop === 'addContext') {
              if (channelId) {
                window.chatWidgetReady = function() {
                  value({ [`channel_${channelId}`]: true });
                };
              }
            }
            target[prop] = value;
            return true;
          },
        });
        proxyWindow.moin = moinProxy;
        if (channelId) {
          const widgetConfig = getters.getWidgetConfig(channelId);
          if (widgetConfig) {
            widgetConfig.channelId = getters['previewChannelId'];
            proxyWindow.moin.config = widgetConfig;
          }
        }
        return true;
      },
      get: function() {
        return proxyWindow.moin;
      },
      configurable: true,
    });

    const dummyScriptTag = document.createElement('script');
    dummyScriptTag.setAttribute('id', 'moinloader');
    dummyScriptTag.setAttribute('async', false);
    dummyScriptTag.setAttribute(
      'src',
      `${process.env.VUE_APP_WIDGET_URL}/moin-loader.js?id=` + botId
    );
    window.document.head.appendChild(dummyScriptTag);
  },
  showTeaser({ state }) {
    window.moin.close();
    const qr = [];
    state.teaserConfig.quickActions.forEach((qa) => {
      qr.push(qa);
    });

    const teaserObj = {
      showTeaser: state.teaserConfig.showTeaser,
      showAvatar: state.teaserConfig.showAvatar,
      message: state.teaserConfig.message,
      quickActions: qr,
      forceShow: true,
    };
    window.moin.teaser(teaserObj);
  },
  hideTeaser() {
    const teaserObj = {
      showTeaser: false,
      showAvatar: false,
      message: state.teaserConfig.message,
      quickActions: [],
      forceShow: true,
    };
    window?.moin?.teaser(teaserObj);
  },
  sendIntent(_, { intent }) {
    window.moin.open();
    window.moin.clearQR();
    window.moin.sendIntent({ intent });
  },
  resetStore({ commit }) {
    // TODO language support
    // de, en

    commit('setTeaser', {
      // default teaser config
      showTeaser: true,
      showAvatar: true,
      message: 'Wie kann ich Ihnen helfen?',
      quickActions: [
        {
          text: 'Frage stellen',
          intent: 'start',
        },
      ],
    });
    commit('setDock', {
      pubsub: undefined,
      topics: undefined,
      config: undefined,
      botId: false,
    });
  },
};

const mutations = {
  setInitialTexts(state, { messageText, actionText }) {
    state.teaserConfig.message = messageText;
    state.teaserConfig.quickActions.push({
      text: actionText,
      intent: 'start',
    });
  },
  setChannels(state, channels) {
    state.channels = channels;
  },
  setSelectedChannel(state, index) {
    state.selectedChannel = index;
  },
  setPreviewChannel(state, channelId) {
    state.previewChannelId = channelId;
  },
  setBotId(state, botId) {
    state.botId = botId;
  },
  setTeaser(state, teaser) {
    state.teaserConfig = teaser;
  },
  setTeaserMessage(state, message) {
    state.teaserConfig.message = message;
  },
  addTeaserButton(state, { intent, text }) {
    if (
      !state.teaserConfig.quickActions.find(
        (action) => action.intent === intent
      )
    ) {
      state.teaserConfig.quickActions.push({ intent, text });
    }
  },
  removeTeaserButton(state, intent) {
    const idx = state.teaserConfig.quickActions.findIndex(
      (action) => action.intent === intent
    );

    if (idx >= 0) {
      state.teaserConfig.quickActions.splice(idx, 1);
    }
  },
  setTeaserButtonText(state, { intent, text }) {
    const action = state.teaserConfig.quickActions.find(
      (action) => action.intent === intent
    );

    if (action) {
      action.text = text;
    }
  },
  setDock(state, { pubsub, topics, config, botId }) {
    state.dock = {
      config,
      pubsub,
      topics,
    };

    state.botId = botId;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

<template>
  <div v-if="item">
    <v-list-item-title>{{ i18nDateFormat(item.created, $vuetify.lang.current, $t('common.timeFormatShort')) }}
      <span v-if="!selected" class="pl-2">
        <template v-for="t in item.all">
          <AnalysisItem :key="t._id" :item="t" type="label" />
        </template>
      </span>
    </v-list-item-title>
  </div>
</template>

<script>
    import AnalysisItem from '@/components/conversation/AnalysisItem.vue'
    import { i18nDateFormat } from '@/utils/formatDateTime'

    export default {
      name: 'InsightsListItem',
      components: {
        AnalysisItem,
      },
      props: {
        item: {
          type: Object,
          default: null,
        },
        selected: Boolean,
      },
      data() {
        return {
          i18nDateFormat,
        }
      },
    }
</script>

<style scoped scss>
</style>

/**
 * 
 * @param {Array<{
 *  md?: string,
 *  page?: string,
 * }>} array - An array of objects where each object represents a markdown page.
 * @param {Object} [options] - An options object to control the output.
 * @param {boolean} [options.pageIdentification=false] - Whether to include page identification headers.
 * @param {boolean} [options.pageText='Page'] - Page identification text.
 * @param {string} [options.separator=''] - The string to use to separate each markdown object in the final string.
 * @returns {string} - Resulted markdown string.
 */
export function parseMarkdownArrayToString(array, { pageIdentification = false, pageText = 'Page', separator = ''  }) {
  const markdownString = array?.map((m, i) => {
    let pageIdent = '';
    if (pageIdentification && m.page) {
      pageIdent = i === 0 ? '' : '\n___\n'; // Horizontal line
      pageIdent += `*${pageText} ${m.page}*` + '\n\n';
    }

    return `${pageIdent}${m.md || ''}`;
  })?.join(separator) || '# <Markdown_is_empty>';

  return markdownString;
}

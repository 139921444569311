import { StatsService } from '@/services/stats';
import { ProductService } from '@/services/product';
import moment from 'moment';
import _ from 'lodash';

export const stageTypes = {
  DEMO: 'demo',
  ONBOARDING: 'onboarding',
  LIVE: 'live',
  UNDEFINED: 'undefined',
};

const state = {
  tasks: [],
  knowledge: [],
  bestPractices: [],
  statsDatePeriod: 90,
  StatsChartFeedbackPos: {
    old: 0,
    new: 0,
    isLoading: true,
    isFailed: false,
  },
  StatsChartFeedbackNeg: {
    old: 0,
    new: 0,
    isLoading: true,
    isFailed: false,
  },
  StatsChartConversations: {
    old: 0,
    new: 0,
    isLoading: true,
    isFailed: false,
  },
  StatsChartAutomation: {
    old: 0,
    new: 0,
    isLoading: true,
    isFailed: false,
  },
};

const getters = {
  tasks: (state) => {
    return state.tasks;
  },
  knowledge: (state) => {
    return state.knowledge;
  },
  bestPractices: (state) => {
    return state.bestPractices;
  },
  StatsChartConversations: (state) => {
    return state.StatsChartConversations;
  },
  StatsChartAutomation: (state) => {
    return state.StatsChartAutomation;
  },
  StatsChartFeedbackPos: (state) => {
    return state.StatsChartFeedbackPos;
  },
  StatsChartFeedbackNeg: (state) => {
    return state.StatsChartFeedbackNeg;
  },
  statsDatePeriod: (state) => {
    return state.statsDatePeriod;
  },
};

const actions = {
  fetchStatsLive({ state, dispatch }, { botId }) {
    // Old: (days * 2) + 1 -> days ( + 1 day)
    // New: days -> today
    const dateRanges = {
      old: [
        moment().add(state.statsDatePeriod * 2 * -1, 'days'),
        moment().add(state.statsDatePeriod * -1, 'days'),
      ],
      new: [moment().add(state.statsDatePeriod * -1, 'days'), moment()],
    };

    dispatch('fetchAutomation', { botId, dateRanges });
    dispatch('fetchConversations', { botId, dateRanges });
    dispatch('fetchFeedbacks', { botId, dateRanges });
  },
  async fetchAutomation({ commit }, { botId, dateRanges }) {
    const automation = { isLoading: true };
    commit('setStatsChartAutomation', automation);

    try {
      // Old range
      const dataOld = await StatsService.getKpi(
        botId,
        'automation',
        dateRanges.old
      );
      automation.old = dataOld.automation * 100 || 0;

      // New range
      const dataNew = await StatsService.getKpi(
        botId,
        'automation',
        dateRanges.new
      );
      automation.new = dataNew.automation * 100 || 0;

      if (
        !_.isUndefined(dataOld?.automation) &&
        !_.isUndefined(dataNew?.automation)
      ) {
        automation.isLoading = false;
        commit('setStatsChartAutomation', automation);
      } else {
        commit('setStatsChartAutomation', { isLoading: false, isFailed: true });
      }

      return {};
    } catch (error) {
      return {};
    }
  },
  async fetchConversations({ commit }, { botId, dateRanges }) {
    const conversations = { isLoading: true };
    commit('setStatsChartConversations', conversations);

    try {
      // Old range
      const dataOld = await StatsService.getKpi(
        botId,
        'conversations',
        dateRanges.old
      );
      conversations.old = dataOld.conversations || 0;

      // New range
      const dataNew = await StatsService.getKpi(
        botId,
        'conversations',
        dateRanges.new
      );
      conversations.new = dataNew.conversations || 0;

      if (
        !_.isUndefined(dataOld?.conversations) &&
        !_.isUndefined(dataNew?.conversations)
      ) {
        conversations.isLoading = false;
        commit('setStatsChartConversations', conversations);
      } else {
        commit('setStatsChartConversations', { isLoading: false, isFailed: true });
      }

      return {};
    } catch (error) {
      commit('setStatsChartConversations', { isLoading: false, isFailed: true });
      return {};
    }
  },
  async fetchFeedbacks({ commit }, { botId, dateRanges }) {
    const feedbacksNeg = { isLoading: true };
    const feedbacksPos = { isLoading: true };

    try {
      // Old range
      const resultOld = await StatsService.getFeedbacks(botId, dateRanges.old);
      feedbacksPos.old = resultOld[0].count;
      feedbacksNeg.old = resultOld[1].count;

      // New range
      const resultNew = await StatsService.getFeedbacks(botId, dateRanges.new);
      feedbacksPos.new = resultNew[0].count;
      feedbacksNeg.new = resultNew[1].count;

      if (resultOld && resultNew) {
        feedbacksPos.isLoading = false;
        feedbacksNeg.isLoading = false;
        commit('setStatsChartFeedbacksPos', feedbacksPos);
        commit('setStatsChartFeedbacksNeg', feedbacksNeg);
      } else {
        commit('setStatsChartFeedbacksPos', { isLoading: false, isFailed: true });
        commit('setStatsChartFeedbacksNeg', { isLoading: false, isFailed: true });
      }

      return {};
    } catch (error) {
      commit('setStatsChartFeedbacksPos', { isLoading: false, isFailed: true });
      commit('setStatsChartFeedbacksNeg', { isLoading: false, isFailed: true });
      return {};
    }
  },
  async getKnowledge({ commit }, { locale, stage }) {
    const knowledgeItems = await ProductService.getKnowledgeBaseItems(
      locale,
      stage
    );
    commit('setKnowledge', knowledgeItems);
    return true;
  },
  async getBestPractices({ commit }, { locale, stage }) {
    const tips = await ProductService.getExpertItems(locale, stage);

    commit(
      'setBestPractices',
      tips?.filter((tip) => {
        return tip.tipBody && tip.tipBody.length > 0;
      })
    );
    return true;
  },
};

const mutations = {
  setTasks(state, tasks) {
    state.tasks = tasks;
  },
  setKnowledge(state, knowledge) {
    state.knowledge = knowledge;
  },
  setBestPractices(state, practices) {
    state.bestPractices = practices;
  },
  setStatsLoading(state) {
    const resetObj = {
      old: 0,
      new: 0,
      isLoading: true,
    };
    state.StatsChartFeedbackPos = resetObj;
    state.StatsChartFeedbackNeg = resetObj;
    state.StatsChartConversations = resetObj;
    state.StatsChartAutomation = resetObj;
  },
  setStatsChartFeedbacksPos(state, feedbacksPos) {
    state.StatsChartFeedbackPos = feedbacksPos;
  },
  setStatsChartFeedbacksNeg(state, feedbacksNeg) {
    state.StatsChartFeedbackNeg = feedbacksNeg;
  },
  setStatsChartConversations(state, conversations) {
    state.StatsChartConversations = conversations;
  },
  setStatsChartAutomation(state, automation) {
    state.StatsChartAutomation = automation;
  },
  setStatsDatePeriod(state, newDays) {
    state.statsDatePeriod = newDays;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

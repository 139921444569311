<template>
  <v-app>
    <HubMenu />
    <Appbar />

    <v-main v-if='this.code' class='grey lighten-2'>
      <p class='text-h5 mt-4 ml-4'>OAuth</p>
      <v-col>
        <v-card>
          <v-card-title>{{ this.provider ? `${`${this.provider[0]}`.toUpperCase()}${this.provider.slice(1)} ` : '' }} authorized via OAuth</v-card-title>
          <v-col>Code: {{ this.code }}</v-col>
        </v-card>
      </v-col>
    </v-main>

    <v-main v-else class='grey lighten-2'>
      <p class='text-h5 mt-4 ml-4'>Tools</p>
      <v-col v-if='this.provider === "zendesk"'>
        <ZendeskAuth></ZendeskAuth>
      </v-col>
      <v-col v-if='this.provider === "facebook"'>
        <FacebookAuth></FacebookAuth>
      </v-col>
      <v-col v-if='this.provider === "hubspot"'>
        <HubspotAuth></HubspotAuth>
      </v-col>
      <v-col v-if='this.provider === "livechat"'>
        <LiveChatAuth :client-id="clientId"></LiveChatAuth>
      </v-col>
      <v-col v-if='this.provider === "shopware"'>
        <ShopwareAuth />
      </v-col>
    </v-main>
  </v-app>
</template>
<script>
import HubMenu from '@/components/hub/HubMenu.vue';
import Appbar from '@/components/Appbar.vue';
import SettingService from '../services/settings.js';
import ZendeskAuth from '@/components/tools/ZendeskAuth.vue';
import FacebookAuth from '@/components/FacebookAuth.vue';
import HubspotAuth from '@/components/tools/HubspotAuth.vue';
import ShopwareAuth from '@/components/tools/ShopwareAuth.vue';

import { mapGetters } from 'vuex';
import LiveChatAuth from "@/components/tools/LiveChatAuth.vue";
import integrationTypeConsts from "@/components/integrations/integrationTypeConsts";

export default {
  name: 'OAuth',
  data() {
    return {
      clientId: this.$route.query.clientId,
      provider: this.$route.params.provider,
      code: this.$route.query.code,
      url: '',
    };
  },
  computed: {
    ...mapGetters('bots', ['currentBotId']),
    localProviderConfigByKey() {
      return this.$store.getters['integrations/localProviderConfigByKey']({ provider: this.provider, key: 'default' });
    }
  },
  watch: {
    currentBotId: {
      deep: true,
      async handler(newVal) {
        if (newVal && this.code) {
          // Use last used oauthURL
          const config = this.localProviderConfigByKey;
          if (config) {
            this.url = config.url || '';
          }

          await SettingService.saveOAuthToken(
            this.currentBotId,
            this.provider,
            this.code,
            this.url,
          );

          /**
           * If we completed OAuth callback and provider is LiveChat,
           * direct user back to their LiveChat integration overview
           */
          if (this.provider === 'livechat') {
            return this.$router.push({
              name: 'integrations-detail',
              params: { type_id: integrationTypeConsts.livechat }
            });
          }
        }
      },
      immediate: true, // make this watch function is called when component created
    },
  },

  components: {
    LiveChatAuth,
    HubMenu,
    Appbar,
    ZendeskAuth,
    FacebookAuth,
    HubspotAuth,
    ShopwareAuth,
  },
};
</script>

<template>
  <v-col
    v-if="isOpenFadeA"
    cols="12"
    :md="width"
    class="pa-0 fill-height white"
  >
    <!-- Sidebar header -->
    <div class="d-flex flex-column" style="max-height: 100%">
      <div>
        <div
          class="d-flex pa-1"
          :class="isProminent ? 'secondary darken-2' : 'grey lighten-2'"
        >
          <div class="grow d-flex align-center text-center justify-center">
            <slot name="title">
              <div class="d-flex">
                <v-icon v-if="icon" :color="isProminent ? 'white' : 'primary lighten-3'">
                  {{ icon }}
                </v-icon>
                <div v-if="title" class="ml-2" :class="isProminent ? 'white--text' : 'primary--text text--lighten-3'">
                  {{ title }}
                </div>
              </div>
            </slot>
          </div>
          <BtnSquare @click="onClose" icon="close" :colorIcon="isProminent ? 'white' : 'primary lighten-3'" />
        </div>
        <div :class="isProminent ? 'secondary darken-3' : 'primary lighten-3'" style="height: 3px"></div>
      </div>
      <div
        class="white mx-0 fill-height"
        style="overflow: hidden; overflow-y: auto"
      >
        <!-- Sidebar content -->
        <v-fade-transition>
          <div v-if="isOpenFadeB">
            <slot></slot>
          </div>
        </v-fade-transition>
      </div>
    </div>
  </v-col>
</template>

<script>
import BtnSquare from '@/components/common/BtnSquare.vue';

export default {
  name: 'HubContentSidebarSimple',
  components: {
    BtnSquare,
  },
  props: {
    width: {
      type: Number,
      default: 4,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    openSidebar: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    icon: {
      type: String,
    },
    isProminent: {
      type: Boolean,
    },
  },
  data() {
    return {
      isOpenFadeA: false,
      isOpenFadeB: false,
      fadeDuration: 300,
    };
  },
  watch: {
    isOpen() {
      // open -> close
      if (!this.isOpen) {
        this.isOpenFadeB = false;
        setTimeout(() => (this.isOpenFadeA = false), this.fadeDuration);
        return;
      }
      // close -> open
      else {
        this.isOpenFadeA = true;
        setTimeout(() => (this.isOpenFadeB = true), 100);
      }
    },
  },
  methods: {
    onClose() {
      this.$emit('onClose');
    },
  },
};
</script>
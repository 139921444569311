/**
 * Internal use only.
 * Represents a task in the TaskScheduler's queue.
 */
class Task {
  // Index in the task queue, as an incremental counter
  #index = 0;
  // The function to be executed, we assume a promise
  #callback = null;
  // If none passed, we simply log errors to console
  #errorHandler = console.error;

  // Holds the promise
  #resultPromise = undefined;
  // Holds resolution function
  #resolve = null;
  // Holds rejection function
  #reject = null;  
  // Holds the returned value of original callback
  #resultValue = undefined;

  constructor(callback, globalIndex, errorHandler) {
    this.#index = globalIndex;
    this.#callback = callback;
    if (errorHandler) this.#errorHandler = errorHandler;

    this.#resultPromise = new Promise((r,rj) => {
      this.#resolve = r;
      this.#reject = rj;
    });
  }

  /**
   * Returns original callback function
   * @returns {() => Promise<unknown>}
   */
  get cb() {
    return this.#callback;
  }

  /**
   * The actual value of the callback, once it's resolved
   * @returns {unknown}
   */
  get result() {
    return this.#resultValue;
  }

  /**
   * Returns the promise that will eventually resolve with the value of the callback,
   * or optionally reject.
   * @returns {Promise<unknown>}
   */
  get resultPromise() {
    return this.#resultPromise;
  }

  /**
   * Manually rejects the promise
   * @param {string|Error|any} reason
   */
  reject(reason) {
    this.#reject(reason);
  }

  /**
   * Executes the task, which can resolve or reject
   * @returns {Promise<any>}
   */
  async run() {
    try {
      const r = await this.#callback();
      this.#resultValue = r;
      this.#resolve(r);
    } catch(e) {
      this.#errorHandler(e);
      this.#reject(e);
    }
  }
}

/**
 * An arbitrary task scheduler that lets you process "up to N" functions at 
 * the same time.
 * 
 * Once a task completes, it will automatically queue the next one, so that it's 
 * always at max capacity.
 * @example
 * const maxAtTheSameTime = 2;
 * const scheduler = new TaskScheduler(maxAtTheSameTime);
 * 
 * scheduler.on('taskCompleted', (value) => console.log("Task completed with value", value));
 * scheduler.on('taskFailed', (error) => console.error("Task failed with error", error));
 * scheduler.on('end', () => console.log("All tasks completed!"));
 * scheduler.on('depleted', (processingTasks) => console.log("Queue depleted, but some are still processing", processingTasks));
 * 
 * scheduler.addToQueue(() => new Promise((r) => setTimeout(() => r(1), 1000)));
 * scheduler.addToQueue(someFunction)
 * 
 * const getContentCB = () => {
 *   return ContentService.getContent(botId, template, intent, undefined, channelId);
 * }
 * 
 * let result;
 * try {
 *   // Result will be the result of 'ContentService#getContent'
 *   result = await scheduler.addToQueue(getContentCB);
 * } catch(e) {
 *   console.error("Failed to get content", e);
 * }
 * 
 * // Perform cleanup
 * scheduler.destroy();
 */
export default class TaskScheduler {
  // Internal check for destroyed state
  #destroyed = false;

  #indexesUsed = 0;

  // The tasks that are waiting in line
  #queue = [];
  // The tasks that are currently being processed
  #processing = [];
  #counter = 0;

  // Event handlers
  #handlers = {
    "end": [],
    "taskCompleted": [],
    "taskFailed": [],
    "depleted": [],
  }

  constructor(maxParallel = 1) {
    this.cap = maxParallel;
    this.#indexesUsed = 0;
  }

  /**
   * Set up an event handler
   * @param {"end"|"taskCompleted"|"taskFailed"|"depleted"} event
   * @param callback
   */
  on(event, callback) {
    if (!this.#handlers[event]) {
      throw new Error('Invalid event');
    }

    this.#handlers[event].push(callback);
  }

  /**
   * Removes an event listener
   * @param {"end"|"taskCompleted"|"taskFailed"|"depleted"} event
   * @param callback
   */
  off(event, callback) {
    if (!this.#handlers[event]) return;

    this.#handlers[event] = this.#handlers[event].filter(cb => cb !== callback);
  }

  /**
   * Destroys the task manager.
   * Removes all event listeners, queue, and processing items.
   *
   * Should be used if you set up event handlers.
   */
  destroy() {
    this.#destroyed = true;

    for (const key in this.#handlers) {
      this.#handlers[key] = [];
    }
    
    for (const task of this.#queue) {
      task.reject(new Error('Task manager destroyed'));
    }

    for (const task of this.#processing) {
      task.reject(new Error('Task manager destroyed'));
    }

    this.#queue = [];
    this.#processing = [];
  }

  /**
   * Check if we have capacity to process more tasks at the same time
   * @returns {boolean}
   */
  get hasCapacity() {
    return this.#processing.length < this.cap;
  }

  createDummyQueueItem() {
    const randomTimeout = Math.floor(Math.random() * 1000) + 1;
    const randomJobId = Math.random().toString(16).slice(2);

    return () =>
      new Promise((r) => {
        setTimeout(() => {
          console.log('[%s] Job completed: %s', randomTimeout, randomJobId);
          return r(randomJobId);
        }, randomTimeout);
      });
  }

  /**
   * Add the task to the queue
   * @template {any} T Return value of asyncFunction or the value the promise resolves to
   * @param {() => Promise<T> | T} asyncFunction The function/async function to be executed
   * @param {?((e: Error) => Promise<any>|any)} [errorHandler] An optional handler if this specific `promiseFunction` throws an error
   * @returns {Promise<T>} Returns a promise that will resolve with the callback value (T) or reject
   */
  addToQueue(asyncFunction, errorHandler) {
    if (this.#destroyed) throw new Error("Cannot add tasks to destroyed manager destroyed");

    const task = new Task(asyncFunction, errorHandler);
    this.#queue.push(task);

    // If there's room for more, process it right away
    if (this.hasCapacity) {
      this.#next();
    }

    // Gives you back a promise that will eventually 
    // reject or resolve the value when the task is processed
    return task.resultPromise;
  }

  /**
   * Triggers processing of the next task in the queue.
   */
  #next() {
    if (this.#destroyed) return;

    // Get the next Task to process
    const task = this.#queue.shift();

    // No more tasks...
    if (!task) {
      // The queue is depleted, AND all are processed.
      if (!this.#processing.length) {
        return this.#handlers.end.forEach(cb => cb());
      }

      // Queue depleted, but more are still processing
      return this.#handlers.depleted.forEach(cb => cb(this.#processing));
    }

    // Add the task itself to the list of processing tasks
    this.#processing.push(task);

    // ... then execute its task, and once it's resolved / rejected...
    task
      .run()
      .then(() => {
        /**
         * To obtain the value, you should already have received a promise
         * from the `addToQueue` handler. The promise will return the
         * value of the callback.
         * 
         * The value is also emitted to every event handler.
         */

        // When task resolves, emit to handlers 
        this.#handlers.taskCompleted.forEach(cb => cb(task.result, task.cb));

        // Remove it from the list of tasks currently processing
        this.#processing = this.#processing.filter(task => task !== task);

        // Then check if there are other tasks that can takt this one's place
        this.#next();
      })
      .catch(e => {
        /**
         * The promise you received from `addToQueue` will reject,
         * so you get the error there too.
         *
         * The error is also emitted to every event handler.
         */

        // Emit to every callback
        this.#handlers.taskFailed.forEach(cb => cb(e, task.cb));

        // The show must go on; 
        // remove the task from the processing list and have another one take its place
        this.#processing = this.#processing.filter(task => task !== task);
        this.#next();
      });
  }
}
<template>
  <div class="moin-fresh">
    <v-dialog :value="open" scrollable persistent max-width="600px">
      <v-card
        flat
        class="d-flex flex-cols answer-dialog"
        style="min-height: 80vh; background: #e2e2e2"
      >
        <div class="answer-dialog-header text-right pa-4">
          <BtnSquare icon="close" @click="$emit('onClose')" />
        </div>
        <div class="answer-dialog-content relative">
          <div class="answer-dialog-message-bot">
            <div>
              Moin, ich bin dein neuer Chatbot. Du kannst mir gerne eine Frage
              stellen!
            </div>
          </div>
          <div v-if="questionLocal" class="answer-dialog-message-human">
            <div>
              {{ questionLocal }}
            </div>
          </div>
          <div
            style="position: absolute; bottom: 8px; left: 8px"
            v-if="loadingMessageSend"
          >
            <TypingIndicator />
          </div>
          <div
            v-if="answer && !loadingMessageSend"
            class="answer-dialog-message-bot"
          >
            <div>
              {{ answer }}
            </div>
          </div>
        </div>
        <div class="answer-dialog-footer pa-4">
          <div class="d-flex align-end">
            <div class="answer-dialog-input">
              {{ input }}
            </div>
            <v-btn
              icon
              class="primary lighten-2 ml-2"
              color="white"
              @click="sendMessage"
              :disabled="loadingMessageSend"
            >
              <v-icon>mdi-send</v-icon>
            </v-btn>
          </div>
        </div>
        <template v-if="loading">
          <div class="answer-dialog-loading moin-fresh">
            <v-card flat class="pa-4 pb-6 relative" style="width: 320px">
              <v-progress-linear
                absolute
                indeterminate
                style="top: 0; left: 0; right: 0"
                v-if="loading"
                color="secondary"
              />
              <DemoStars style="transform: scale(0.5)" />
              <div class="mt-4">
                <h1
                  class="text-h6 moinblue--text my-4"
                  v-html="$t(`demo.answerDialog.loading.title`)"
                  style="line-height: 1"
                ></h1>
                <div
                  class="text-body-2"
                  v-html="$t(`demo.answerDialog.loading.body`)"
                ></div>
              </div>
            </v-card>
          </div>
        </template>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import BtnSquare from '@/components/common/BtnSquare.vue';
import DemoService from '@/services/demo';
import DemoStars from '@/components/common/DemoStars.vue';
import TypingIndicator from '@/components/common/TypingIndicator.vue';

export default {
  name: 'DemoAnswerDialog',
  props: {
    open: Boolean,
    question: String,
  },
  data() {
    return {
      loading: false,
      loadingMessageSend: false,
      input: '',
      questionLocal: '',
      answer: '',
      textareaHeight: 36,
      createdAnswers: [],
    };
  },
  components: {
    BtnSquare,
    DemoStars,
    TypingIndicator,
  },
  watch: {
    open() {
      if (this.open) {
        this.createAnswer();
      } else {
        this.resetAll();
      }
    },
  },
  computed: {
    ...mapGetters('demo', ['fingerprintId', 'companyUrl']),
  },
  methods: {
    resetAll() {
      this.input = '';
      this.questionLocal = '';
      this.answer = '';
    },
    async createAnswer() {
      this.loading = true;
      this.input = this.question;

      const existingAnswer = this.createdAnswers.find(
        (a) => a.question === this.question
      );

      if (existingAnswer) {
        this.loading = false;
        return;
      }

      const res = await DemoService.getAnswerWithQuestion({
        question: this.question,
        site: this.companyUrl,
        fingerprintId: this.fingerprintId,
      });

      this.createdAnswers.push({ answer: res.answer, question: this.question });
      this.sendMessage();
      this.loading = false;
    },
    async sendMessage() {
      this.questionLocal = this.input;
      this.input = '';
      this.loadingMessageSend = true;
      const existingAnswer = this.createdAnswers.find(
        (a) => a.question === this.question
      );
      await new Promise((resolve) => setTimeout(resolve, 1500));
      this.answer = existingAnswer.answer;
      this.loadingMessageSend = false;
    },
  },
};
</script>

<style scoped lang="scss">
.answer-dialog {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.answer-dialog-header {
  flex-shrink: 0;
}

.answer-dialog-content {
  flex-grow: 1;
  overflow: hidden;
  overflow-y: auto;
  padding: $spacer * 4;
}

.answer-dialog-footer {
  background: rgba(255, 255, 255, 0.2);
  flex-shrink: 0;
}

.answer-dialog-message-bot,
.answer-dialog-message-human {
  margin-bottom: $spacer * 2;
}

.answer-dialog-message-bot {
  text-align: left;
}

.answer-dialog-message-bot > div {
  display: inline-block;
  background: #fff;
  border-radius: $spacer;
  padding: $spacer * 2;
  text-align: left;
}

.answer-dialog-message-human {
  text-align: right;
}

.answer-dialog-message-human > div {
  display: inline-block;
  background: #19293e;
  border-radius: $spacer;
  padding: $spacer * 2;
  color: white;
  text-align: left;
}

.answer-dialog-input {
  position: relative;
  border-radius: 20px;
  background: white;
  outline: none;
  border: 1px solid transparent;
  width: 100%;
  min-height: 36px;
  padding: $spacer $spacer * 2;
}

.answer-dialog-loading {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
}
</style>

import ApiService from './api'

const DashboardService = {
    
    fetch: async function(botId) {
        try {
            const response = await ApiService.get('/bot/faq/performancekpis/' + botId);
            return response.data;
        } catch (error) {
            return {};
        }
    },

    topAnswers: async function(botId) {
        try {
            const response = await ApiService.get('/schaltzentrale/dashboard/topanswers/' + botId);
            return response.data;
        } catch (error) {
            return {};
        }
    }
}

export default DashboardService

export { DashboardService }
const ActionUtil = {
  /**
   *
   * @param {String} key
   * @param {Array} strings
   * @returns the string object with the key out of strings
   *
   * @example
   * ActionUtil.getStringObjectFrom(card.title, this.action.content.strings);
   */
  getStringObjectFrom(key, strings = []) {
    if (!Array.isArray(strings)) return undefined;

    return strings.find((str) => `{${str.name}}` === key?.trim());
  },
  /**
   *
   * @param {String} key
   * @param {Array} strings
   * @returns true if the key is in the strings and is removed, false otherwise
   */
  removeStringObjectFrom(key, strings = []) {
    if (!Array.isArray(strings)) return false;

    const objIndex = strings.findIndex(
      (str) => `{${str.name}}` === key?.trim()
    );
    if (objIndex === -1) return false;

    strings.splice(objIndex, 1);
    return true;
  },
  /**
   *
   * @param {Array} actions
   * @returns returns only the actions that are actions with its own content
   */
  filterContentActions(actions = []) {
    if (!Array.isArray(actions)) return [];
    return actions.filter((action) => action.content);
  },
  /**
   *
   * @param {Array} actions
   * @returns the action from type next
   */
  findNextAction(actions = []) {
    if (!Array.isArray(actions)) return undefined;
    return actions.find((action) => action.name === 'next');
  },
  /**
   *
   * @param {Array} actions
   * @returns the quick replies from the last content action
   */
  getQuickReplies(action) {
    if (!action) return [];
    // if action is object then convert it to an array
    if (typeof action === 'object') action = [action];
    if (!Array.isArray(action) || action.length === 0) {
      return [];
    }

    const lastContentAction = action[action.length - 1];
    return lastContentAction.content.quick || [];
  },
  /**
   *
   * @param {Array} actions
   * @returns the quick replies from the last content action
   * formated as {intent, label} item
   */
  getQuickRepliesItems(action) {
    const quickReplies = this.getQuickReplies(action);
    const items = quickReplies.map((qr) => {
      const stringObj = ActionUtil.getStringObjectFrom(
        qr.label,
        action.content.strings,
      );

      return {
        intent: qr.intent,
        label: stringObj || { data: qr.label },
      };
    });

    return items;
  },
  /**
   *
   * @param {Array} actions
   * @param {String} intent (optional)
   * @returns the next action
   */
  updateOrCreateNextAction(actions = [], intent) {
    let nextAction = this.findNextAction(actions);
    if (nextAction) {
      if (intent) {
        nextAction.parameters.intent = intent;
      } else {
        delete nextAction.parameters.intent;
      }
    } else {
      nextAction = {
        name: 'next',
        parameters: {
          intent,
        },
      };
      actions.push(nextAction);
    }

    return nextAction;
  },
  /**
   *
   * @param {Array} actions
   * @returns true if next action is removed
   */
  removeNextAction(actions = []) {
    if (!Array.isArray(actions)) return false;
    const nIndex = actions.findIndex((action) => action.name === 'next');
    if (nIndex > -1) {
      actions.splice(nIndex, 1);
      return true;
    }
    return false;
  },
  /**
   *
   * @param {Object} action
   * @returns true if quick replies are removed, false otherwise
   */
  clearQuickReplies(action) {
    if (
      !action.content ||
      !action.content.quick ||
      !Array.isArray(action.content.quick)
    )
      return false;

    for (let qr of action.content.quick) {
      this.removeStringObjectFrom(qr.label, action.content.strings);
    }
    action.content.quick = [];
    return true;
  },
  findContentActions(content) {
    if (!content) return [];
    const actions = [];

    // each property of content could have an actions property. Each of those has to be checked 
    for (let prop in content) {
      if (content[prop] && content[prop].actions) {
        const contentActions = this.filterContentActions(content[prop].actions).map(
          (action) => {return  { action, prop }; },
        );
        
        actions.push(...contentActions);
      }
    }

    return actions;
  },
  createAction(type, botId) {

    if (type === 'webhook') {
      return { name: 'webhook', parameters: {type: ''}}
    }

    if (type === 'context') {
      return { name: 'context', parameters: {name: 'user_var1', value: '' }}
    }

    if (type === 'notification') {
      return { name: 'notification', parameters: {type: 'default'}}
    }

    const newActionName =
      'new_' + Date.now() + Math.floor(Math.random() * 1000);

    const newAction = {
      name: newActionName,
      content: {
        name: newActionName,
        bot: botId,
        displayName: 'New Action',
        quick: [],
        type,
        context: [],
      },
    };

    switch (type) {
      case 'text':
        newAction.content.strings = [
          {
            name: 'text',
            data: '',
          },
        ];
        newAction.content.data = '{text}';
        break;
      case 'image':
        newAction.content.strings = [
          {
            name: 'url',
            data: '',
          },
        ];
        newAction.content.data = '{url}';
        break;
      case 'video':
        newAction.content.strings = [
          {
            name: 'url',
            data: '',
          },
        ];
        newAction.content.data = '{url}';
        break;
      case 'cards':
        newAction.content.strings = [
          {
            name: 'title1',
            data: 'Slide 1',
          },
          {
            name: 'subtitle1',
            data: '',
          },
          {
            name: 'image1',
            data: '',
          },
        ];
        newAction.content.data = [
          {
            title: '{title1}',
            subtitle: '{subtitle1}',
            image: '{image1}',
            buttons: [],
          },
        ];
        break;
    }

    return newAction;
  },
};

export default ActionUtil;

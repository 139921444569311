const initPrimus = (apiUrl, query) => {
  return new window.Primus(`${apiUrl}/primus?${query}`, {
    reconnect: {
      max: Infinity,
      min: 500,
      retries: 10,
    },
  })
}


export function connectPrimus({ apiUrl, query }) {
  const script = document.createElement('script');

  const primusScriptId = 'primus-script';
  script.id = primusScriptId;
  script.src = `${apiUrl}primus/primus.js`;

  if (!document.getElementById(primusScriptId)) {
    document.body.appendChild(script);
    return new Promise((resolve) => {
      script.onload = () => {
        const primus = initPrimus(apiUrl, query);
        resolve(primus);
      };
    });
  } else if (window.Primus) {
    return initPrimus(apiUrl, query);
  } else {
    console.error('error loading primus');
  }
}

import { ProductService } from '../../services/product'
// initial state for authentication
const state =  {
   data: false
}

const getters = {
    trainingdata: (state) => {
        return state.data;
    }
}

const actions = {
    async get({ commit }, {uniqueBotId, name}) {
        const trainingdata = await ProductService.getTrainingdata(uniqueBotId, name);
        if (trainingdata && Array.isArray(trainingdata.result)) {
            await commit('setTrainingdata', trainingdata.result);
            return trainingdata.result;

        } else {
            return false;
        }
    },
    async addData(state, {uniqueBotId, name, trainingdata}) {
        // filter empty strings
        trainingdata = trainingdata.filter(td => td.trim() !== '');
        let response = await ProductService.addTrainingdata(uniqueBotId, name, trainingdata);
        return response;
    },
    async deleteData(state, {uniqueBotId, text}) {
        await ProductService.deleteTrainingdata(uniqueBotId, text);
        return true;
    },
    async updateQualityCheck({commit}, {variant}) {
        let td = await ProductService.updateQualityCheck(variant);
        if (td.variant) {
            commit('setVariant', td.variant);
        }

        return td.variant;
    },
    async update({commit}, {variant}) {
        let td = await ProductService.updateTrainingdata(variant);
        if (td.variant) {
            commit('setVariant', td.variant);
            return td.variant;
        } else {
            return false;
        }
    },
    clear({commit}) {
        commit('setTrainingdata', false);
    }
}

const mutations = {
    setTrainingdata(state, trainingdata) {
        state.data = trainingdata;
        state.data.sort((a, b) => {
            return a.text.localeCompare(b.text);
        })
    },
    setVariant(state, variant) {
        //console.log('VAR', variant._id, state.data);
        let index = state.data.findIndex(td => {
            return td._id === variant._id
        });
        state.data.splice(index, 1, variant);
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
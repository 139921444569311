<template>
  <v-menu
    offset-y
    nudge-top="-20px"
    left
    bottom
    absolute
    :min-width="width"
    :max-width="width"
    @click.stop=""
    :value="isOpen"
    @input="handleOpen"
    :disabled="isDisabled"
  >
    <template v-slot:activator="{ on, attrs }">
      <span class="d-inline-block" v-bind="attrs" v-on="on">
        <slot></slot>
      </span>
    </template>
    <v-list class="pa-0">
      <template v-for="(item, index) in items" @click.stop="">
        <v-list-item
          v-if="!item.subitems"
          link
          :key="index"
          :disabled="isDisabled || item.isDisabled"
          :to="item.to ? item.to : ''"
          :target="item.target ? item.target : ''"
          @click.stop="handleClick(item)"
        >
          <!-- <div
            class="d-flex flex-grow-1 justify-space-between full-width"
            @click.stop="handleClick(item)"
          > -->
          <v-list-item-content>
            {{ item.text }}
          </v-list-item-content>
          <v-list-item-action v-if="item.icon">
            <v-icon right :color="item.iconColor">{{ item.icon }}</v-icon>
          </v-list-item-action>
          <!-- </div> -->
        </v-list-item>
        <v-menu v-else offset-y :key="index" open-on-hover nudge-right="200">
          <template v-slot:activator="{ on, attrs }">
            <v-list-item v-bind="attrs" v-on="on">
              <v-list-item-content>
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon>mdi-chevron-right</v-icon>
              </v-list-item-action>
            </v-list-item>
          </template>

          <v-list class="pa-0">
            <v-list-item-group>
              <template v-for="(subitem, subindex) in item.subitems" @click.stop="">
                <v-list-item :key="subindex" @click.stop="handleClick(subitem)">
                  <v-list-item-content>
                    <v-list-item-title>{{ subitem.text }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon v-if="subitem.icon" right :color="subitem.iconColor">{{ subitem.icon }}</v-icon>
                  </v-list-item-action>
                </v-list-item>
                <v-divider :key="`${subindex}-divider`" />
              </template>
            </v-list-item-group>
          </v-list>
        </v-menu>
        <v-divider :key="`${index}-divider`" />
      </template>
    </v-list>
  </v-menu>
</template>
<script>
export default {
  name: 'DropMenu',
  props: {
    // @ items: {onClick, text, icon, isDisabled?, to?, target?}
    items: {
      type: Array,
      required: true,
    },
    isDisabled: {
      type: Boolean,
    },
    width: {
      type: Number,
      default: 240,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    // Hack: Had to chose this complicated approach, because
    // of sideeffect in src/views/ChatInsights.vue
    // A click always closed the parent element there.
    handleOpen() {
      this.isOpen = true;
    },
    handleClose() {
      this.isOpen = false;
    },
    handleClick(item) {
      if (!item.isDisabled || item.onClick) {
        item.onClick();
      }
      this.handleClose();
    },
  },
};
</script>
<style scoped >
.v-list-item--disabled :deep(.v-icon) {
  opacity: 0.38;
}
</style>
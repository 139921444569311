<template>
  <HubBoxSimple
    :title="$t('stats.reports.titlePlural')"
    icon="poll"
    titleHeight
  >
    <template v-slot:content>
      <v-list class="mt-n8 mx-n4">
        <v-divider />
        <v-list-item-group>
          <div v-for="(item, index) in items" :key="index">
            <v-list-item
              class="px-2 py-0"
              :to="item.to"
              :exact-path="item.excact"
            >
              <v-list-item-icon>
                <v-icon v-if="!item.isAnualReview" small color="primary lighten-3">
                  {{ item && item.icon ? item.icon : 'query_stats' }}
                </v-icon>
                <div v-else class="relative">
                  <v-icon small color="accentE">
                    mdi-calendar-blank-outline
                  </v-icon>
                  <v-icon
                    small
                    color="accentE"
                    style="position: absolute; top: 6px; left: 1px; shadow: 0 0 0 2px #fff;"
                  >
                    show_chart
                  </v-icon>
                </div>
              </v-list-item-icon>
              <v-list-item-content class="py-0">
                <v-list-item-title>
                  {{ item.title }} <span v-if="item.isAnualReview" class="accentE--text">{{ anualReviewYear }}</span>
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon color="primary lighten-3">navigate_next</v-icon>
              </v-list-item-action>
            </v-list-item>
            <v-divider />
          </div>
        </v-list-item-group>
      </v-list>
    </template>
  </HubBoxSimple>
</template>
<script>
import { mapGetters } from 'vuex';
import HubBoxSimple from '@/components/hub/HubBoxSimple.vue';
import InfoBox from '@/components/common/InfoBox.vue';
import { statsTypes } from '@/components/stats/statsTypes';
import { stageTypes } from '@/store/modules/welcome';

export default {
  name: 'StatsSidebarReportsList',
  components: {
    HubBoxSimple,
    InfoBox,
  },
  computed: {
    ...mapGetters('stats', [
      'channel',
      'intentId',
      'dateRange',
      'anualReviewYear',
    ]),
    ...mapGetters('bots', ['currentLicence', 'currentBotStage']),
    items() {
      const items = [
        {
          title: `${this.$t('stats.conversation')}`,
          to: `/statistics/${statsTypes.conversation}`,
        },
        {
          title: `${this.$t('stats.intents')}`,
          to: `/statistics/${statsTypes.intents}`,
        },
        {
          title: `${this.$t('stats.userRequests')}`,
          to: `/conversations?channel=${this.channel ||
            ''}&from=${this.dateRange[0]?.format(
            'YYYY-MM-DD' || ''
          )}&to=${this.dateRange[1]?.format('YYYY-MM-DD') || ''}`,
        },
      ];

      if (this.$route.name !== 'statistics') {
        items.unshift({
          title: `${this.$t('common.backToOverview')}`,
          icon: 'show_chart',
          to: `/statistics`,
          excact: true,
        });
      }

      if (this.currentBotStage === stageTypes.LIVE) {
        items.push({
          title: `${this.$t('stats.anualReview')}`,
          to: `/statistics/${statsTypes.anualReview}`,
          isAnualReview: true,
        });
      }

      return items;
    },
  },
};
</script>
<style scoped>
.v-list-item__icon:first-child {
  margin-right: 0;
}
</style>

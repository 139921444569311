<script>
import BtnSquare from '@/components/common/BtnSquare.vue';
import DropDown from '@/components/common/dropdown/DropDown.vue';
/**
 * @typedef {Object} DropDownItem
 * @property {string} label The display label
 * @property {string} [icon] The name of the icon to use
 * @property {"left"|"right"} [iconSide="right"] Which side the icon should appear on
 * @property {boolean} [disabled=false] Whether this item should be disabled
 * @property {DropDownItem[]} [items] Child items, allowing you to create nested sub-menus
 * @property {string} [iconColor] CSS Classes/color property to pass to the icon. Will override default color from Options.
 * @property {(buttonData: DropdownButtonClicked) => any|Promise<any>} [onClick] Function to execute when clicked
 */

export default {
  name: 'DropDownItem',
  components: { DropDown, BtnSquare },
  props: {
    "label": {
      type: String,
      required: true,
    },
    /**
     * @typedef {(buttonData: DropdownButtonClicked) => any|Promise<any>} DropdownButtonCallback
     */
    /**
     * Function to execute when you click this button.
     * Nested dropdown buttons does not need this, but you can attach it regardless
     * @type {DropdownButtonCallback}
     */
    "onClick": {
      type: Function,
      default: () => (event, buttonData) => {},
    },
    "id": {
      type: [String, Number],
      default: null,
    },
    "icon": {
      type: String,
      default: undefined,
    },
    "iconSide": {
      type: String,
      default: 'right',
    },
    "disabled": {
      type: Boolean,
      default: false,
    },
    "iconColor": {
      type: String,
    },
    /** @type {DropDownItem[]} */
    "items": {
      type: Array,
      default: null,
    },
    /**
     * Internal use:
     * - allows nested dropdowns to inherit parent options
     * - tells each dropdown item what level of nesting it is in
     * - carries preferences for buttons/dropdown down to them and any children
     * ... and more
     */
    "options": Object,
  },
  data() {
    return {
      DropDown
    }
  },
  methods: {
    /**
     * Gives an automatic sub-menu icon if none is provided,
     * and it is not explicitly set not to have one (null / false)
     * @param {string|null|boolean} [icon]
     * @returns {null|string|boolean}
     */
    giveAutomaticIcon(icon) {
      if (icon  || icon===null || icon===false) return icon;
      return 'chevron_right';
    },
    /**
     * @typedef {Object} DropdownButtonClicked
     * @property {string} id The ID of the button
     * @property {string} label The label of the button
     * @property {number} level Which nesting level button was in (zeo-indexed)
     * @property {boolean} menu Whether this button has children
     * @property {() => void} toggle A function that that lets you toggle the open/closed state of the dropdown
     * @property {(isOpen: boolean) => void} setOpen A function that lets you force the dropdown to be open or closed
     */
    /**
     *
     * @param item
     * @returns {{level, id, label}}
     */
    toEmitPayload(item) {
      return {
        id: item.id,
        label: item.label,
        level: this.options?.level,
        menu: false,
        toggle: this.options?.toggle,
        setOpen: this.options?.setOpen,
      };
    },
    /**
     * @param {(arg?: any) => void} toggleCallback
     */
    handleDropdownClick(toggleCallback) {
      toggleCallback();
      this.onClick({...this.toEmitPayload(this.$props), menu: true});
    },
    handleButtonClick() {
      this.onClick(this.toEmitPayload(this.$props));

      if (this.options.autoClose && this.options?.setOpen) {
        this.options.setOpen(false)
      }
    },
  }
};
</script>

<template>
  <!-- Normal button / item -->
  <BtnSquare
    v-if="!items?.length"
    :id="id"
    :disabled="disabled"
    :with-slot="true"
    :tile="true"
    :color-btn="options?.colorBtn"
    :color-icon="iconColor || options?.colorIcon"
    class="DropDownItem"
    @click="handleButtonClick"
  >
    <v-icon
      small
      v-if="icon && iconSide === 'left'"
      :color="iconColor || options?.colorIcon">
      {{ icon }}
    </v-icon>
    {{ label }}
    <v-icon
      small
      v-if="icon && iconSide === 'right'"
      :color="iconColor || options?.colorIcon">
      {{ icon }}
    </v-icon>
  </BtnSquare>

  <!--
  Nested menu item
    It inlines the dropdown so it opens on the side rather than overlaying.
    The rest of the properties of the parent is inherited through the Options prop.
  -->
  <component v-else
             :is="DropDown"
             :items="items"
             :options="options"
             inlined
             style="width: 100%">
    <template #actuator="{ toggle }">
      <BtnSquare
        :id="id"
        :disabled="disabled"
        :with-slot="true"
        :tile="true"
        :color-btn="options?.colorBtn"
        :color-icon="options?.colorIcon"
        class="DropDownItem"
        @click="handleDropdownClick(toggle)"
      >
        <v-icon
          :small="true"
          v-if="giveAutomaticIcon(icon) && iconSide === 'left'"
          :color="iconColor || options?.colorIcon">
          {{ giveAutomaticIcon(icon) }}
        </v-icon>
        {{ label }}
        <v-icon
          :small="true"
          v-if="giveAutomaticIcon(icon) && iconSide === 'right'"
          :color="iconColor || options?.colorIcon">
          {{ giveAutomaticIcon(icon) }}
        </v-icon>
      </BtnSquare>
    </template>
  </component>
</template>

<style scoped>
.DropDownItem {
  width: 100%;
  justify-content: space-between;
}
.DropDownItem::v-deep > span {
  gap: 1rem;
  padding: 0.5rem 1rem;
  width: 100%;
  font-weight: 400;
}
</style>
<template>
  <v-btn
    :text="text"
    class="pa-0"
    :class="[colorBtn === 'admin' && 'moin-admin-box']"
    :style="small ? 'min-width: 24px' : 'min-width: 36px'"
    v-on="$listeners"
    :disabled="disabled"
    :to="to"
    :append="append"
    :exact="exact"
    :color="colorBtn"
    :small="small"
    :outlined="outlined"
    :tile="tile"
  >
    <slot v-if="withSlot"></slot>
    <v-icon :small="small" v-else :color="colorIcon">{{ icon }}</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'BtnSquare',
  props: {
    icon: {
      type: String,
      default: 'navigate_next',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    to: {
      type: [String, Object],
      default: null,
    },
    append: {
      type: Boolean,
      default: false,
    },
    exact: {
      type: Boolean,
      default: false,
    },
    colorIcon: {
      type: String,
      default: 'primary lighten-3',
    },
    colorBtn: {
      type: String,
    },
    withSlot: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
		text: {
			type: Boolean,
			default: true,
		},
    tile: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<template>
  <div class="fill-height">
    <v-card outlined class="fill-height pa-4">
      <h2 class="text-h6">Sprachen</h2>
      <v-divider class="my-2" />
      <div
        v-for="lang in languages"
        :key="lang"
        class="text-body-2 mb-2"
      >
        <v-icon small color="primary lighten-4 mr-2">mdi-web</v-icon>&nbsp;{{ $t(`languages.${lang}`) }}
      </div>
    </v-card>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'QmDashboardLanguages',
  computed: {
    ...mapGetters('bots', ['currentChannels']),
    languages() {
      const usedLanguages = [];

      // Get languages from each channel
      this.currentChannels.forEach((channel) => {
        channel.languages.forEach((lang) => {
          if (!usedLanguages.includes(lang)) {
            usedLanguages.push(lang);
          }
        });
      });

      if (!usedLanguages.length) {
        usedLanguages.push('de');
      }

      return usedLanguages;
    },
  },
};
</script>
<style scoped>
.moin-label {
  color: var(--v-primary-lighten3);
}
.moin-col-label {
  width: 200px;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{staticStyle:{"max-height":"100vh","height":"100vh"}},[_c('HubMenu'),_c('Appbar'),_c('v-main',{staticClass:"fill-height"},[_c('div',{staticClass:"not-found px-6",class:[
          _vm.$vuetify.breakpoint.smAndUp ?
            'd-flex flex-column align-center justify-center fill-height' : 'pt-10',
        ]},[_c('v-container',{staticStyle:{"max-width":"1000px !important"},attrs:{"data-cy":"404-page"}},[_c('v-row',[_c('v-col',[_c('div',{style:({ width: _vm.textWidth })},[_c('h1',{staticClass:"text-uppercase primary--text text--lighten-2 not-found-h1"},[_vm._v(" "+_vm._s(_vm.$t('pageNotFound.title'))+" ")]),_c('h2',{staticClass:"primary--text text--lighten-2 mb-4"},[_vm._v(" "+_vm._s(_vm.$t('pageNotFound.subTitle'))+" ")]),_c('p',{staticClass:"text-body-1",domProps:{"innerHTML":_vm._s(
                    _vm.currentBotContactId
                      ? _vm.$t('pageNotFound.textCsm')
                      : _vm.$t('pageNotFound.textEmail')
                  )}}),(_vm.currentBotContactId)?_c('ContactItem',{attrs:{"contact-id":_vm.currentBotContactId,"large":""}}):_vm._e()],1)]),_c('v-col',[_c('div',{staticClass:"d-flex justify-end"},[_c('div',{style:({ width: _vm.imageWidth })},[_c('v-img',{attrs:{"max-width":"100%","src":require("@/assets/page-not-found.png")}})],1)])])],1)],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
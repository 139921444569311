<template>
 <v-card-text class="pa-0">
    <v-row v-if="detectedIntent && isSame && !isSmalltalk">
      <v-col class="text-subtitle-2 mb-2" v-if="detectedIntent"> <v-icon small left>school</v-icon> Erkanntes Thema: <strong>{{detectedIntent}}</strong></v-col>
    </v-row>
     <v-row v-if="!isSame && !isSmalltalk && detectedIntent">
      <v-col cols="12" class="pt-0 pb-0 text-subtitle-2 mb-2 secondary--text text--darken-2" v-if="detectedIntent"> <v-icon color="secondary darken-2" small left>school</v-icon> Veränderte Zusweisung: <strong>{{detectedIntent}}</strong></v-col>
      <v-col class="pt-0 pb-0 text-subtitle-2 mb-2 secondary--text text--darken-2"><strong>Richtig? <v-btn color="secondary darken-2" class="ml-2" icon tile><v-icon small>check</v-icon></v-btn><v-btn color="secondary darken-2" icon tile><v-icon small>highlight_off</v-icon></v-btn></strong></v-col>
    </v-row>

 </v-card-text>
</template>

<script>
    import { mapGetters } from "vuex";
import ProductService from '../../services/product';

    export default {
        name: 'NlpQuick',
        created() {
          let $this = this;
          ProductService.nlpCheck(this.currentBot.uniqueBotId, this.text).then(result => {
            $this.nlp = result;
          });
        },
        components: {

        },
        props: {
            text: {
                type: String,
                default: null
            },
            intent: {
              type: String,
              default: null
            }
        },
        data: () => ({
          nlp: null
        }),
        watch: {

        },
        computed: {
            ...mapGetters("intents", ["intents", "displayName"]),
            ...mapGetters("bots", ["currentBot"]),
            isSame() {
              if (this.nlp === null) return true;

              return (!this.nlp || !this.nlp.check || !this.nlp.check.nlp || this.nlp.check.nlp.intent === this.intent);
            },
            isSmalltalk() {
              if (this.nlp === null) return false;

              return (this.nlp && this.nlp.check && this.nlp.check.nlp && this.nlp.check.nlp.intent && this.nlp.check.nlp.intent.startsWith('smalltalk_'));
            },
            detectedIntent() {
               if (this.nlp === null || !this.nlp.check || !this.nlp.check.nlp) return null;

               if ( this.nlp.check.nlp.intent === 'unknown' || this.nlp.check.nlp.confidence < this.nlp.check.nlp.confidenceLimit) {
                 return false;

               } else {
                 return this.displayName(this.nlp.check.nlp.intent, this.$vuetify.lang.current);
               }
            }
        },
        methods: {}
    }
</script>

<style scoped scss>
</style>

<template>
  <v-card flat class="pa-0">
    <!-- Kanäle -->
    <v-card-subtitle v-if="isChannelFilterAvailable" class="pb-1 pt-0">
      <v-icon left small>filter_alt</v-icon>
      {{ $t('chats.filter.channels') }}
    </v-card-subtitle>
    <v-card-text v-if="isChannelFilterAvailable" class="pb-0">
      <v-chip-group v-model="selectedChannel" column active-class="primary lighten-2" mandatory>
        <v-chip
          v-for="channel in channels"
          :key="channel.channelId"
          :disabled="channelsCount[channel.channelId] === 0 || channelFilterDisable || channel.blocked"
          :value="channel.channelId"
          small
          outlined
        >
          {{ channel.displayName || channel.channelId || channel.channel }}
          <v-avatar right v-if="!channel.blocked">{{ channelsCount[channel.channelId] }}</v-avatar>
        </v-chip>
      </v-chip-group>
    </v-card-text>
    <!-- Analyse -->
    <v-card-subtitle class="mt-2 pb-1 pt-0">
      <v-icon left small>query_stats</v-icon>
      {{ $t('chats.filter.analysis.title') }}
    </v-card-subtitle>
    <v-card-text class="pb-0">
      <v-chip-group v-model="selectedEvents" column active-class="primary lighten-2" multiple>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              v-bind="attrs"
              :disabled="suggestCount === 0 || eventFilterDisable"
              small
              outlined
              value="suggest"
              v-on="on"
            >
              <v-icon small color="secondary darken-2">auto_awesome</v-icon>
              <v-avatar right>{{ suggestCount }}</v-avatar>
            </v-chip>
          </template>
          <span v-if="!isEvent('suggest')">{{ $t('chats.filter.dreaming') }}</span>
          <span v-else>{{ $t('chats.filter.takeback') }}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              v-bind="attrs"
              :disabled="thumbUpCount === 0 || eventFilterDisable"
              small
              outlined
              value="pos"
              v-on="on"
            >
              <v-icon small color="success">thumb_up</v-icon>
              <v-avatar right>{{ thumbUpCount }}</v-avatar>
            </v-chip>
          </template>
          <span v-if="!isEvent('pos')">{{ $t('chats.filter.positive') }}</span>
          <span v-else>{{ $t('chats.filter.takeback') }}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              v-bind="attrs"
              :disabled="thumbDownCount === 0 || eventFilterDisable"
              small
              outlined
              value="neg"
              v-on="on"
            >
              <v-icon small color="error">thumb_down</v-icon>
              <v-avatar right>{{ thumbDownCount }}</v-avatar>
            </v-chip>
          </template>
          <span v-if="!isEvent('neg')">{{ $t('chats.filter.negative') }}</span>
          <span v-else>{{ $t('chats.filter.takeback') }}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              v-bind="attrs"
              :disabled="goalsCount === 0 || eventFilterDisable"
              small
              outlined
              value="goal"
              v-on="on"
            >
              <v-icon small color="primary">flag</v-icon>
              <v-avatar right>{{ goalsCount }}</v-avatar>
            </v-chip>
          </template>
          <span v-if="!isEvent('goal')">{{ $t('chats.filter.goals') }}</span>
          <span v-else>{{ $t('chats.filter.takeback') }}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              v-bind="attrs"
              :disabled="unknownCount === 0 || eventFilterDisable"
              small
              outlined
              value="unknown"
              v-on="on"
            >
              <v-icon small color="grey lighten-2">warning</v-icon>
              <v-avatar right>{{ unknownCount }}</v-avatar>
            </v-chip>
          </template>
          <span v-if="!isEvent('unknown')">{{ $t('chats.filter.unknown') }}</span>
          <span v-else>{{ $t('chats.filter.takeback') }}</span>
        </v-tooltip>
      </v-chip-group>
    </v-card-text>
    <v-card-subtitle class="mt-2 pb-1 pt-0">
      <v-icon left small>mdi-folder-text</v-icon>
      {{ $t('chats.filter.intents') }}
    </v-card-subtitle>
    <v-card-text class="pb-0">
      <v-chip-group v-model="selectedIntents" column multiple active-class="primary lighten-2">
        <v-chip
          v-for="intent in intentsToFilter.slice(0, intentFilterLimit)"
          :key="intent.intent"
          :disabled="intentFilterDisable"
          :value="intent.intent"
          outlined
          small
        >
          {{ displayName(intent.intent, $vuetify.lang.current) }}
          <v-avatar right>{{ intent.count }}</v-avatar>
        </v-chip>
        <v-btn
          v-if="intentFilterLimit < intentsToFilter.length"
          class="text-caption my-auto"
          color="grey"
          text
          small
          @click="intentFilterLimit += 5"
        >{{ $t('common.more') }}...</v-btn>
      </v-chip-group>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';

export default {
  name: 'ChatInsightsFilter',
  components: {},
  props: {
    chats: {
      type: Array,
      default: () => {
        return [];
      },
    },
    channels: {
      type: Array,
      default: () => {
        return [];
      },
    },
    value: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data: () => ({
    filteredChats: [],
    selectedChannel: null,
    selectedEvents: [],
    selectedIntents: [],
    intentsToFilter: [],
    channelsCount: {},
    suggestCount: 0,
    thumbDownCount: 0,
    thumbUpCount: 0,
    goalsCount: 0,
    unknownCount: 0,
    filterLoading: false,
    eventFilterDisable: false,
    channelFilterDisable: false,
    intentFilterDisable: false,
    intentFilterLimit: 5,
  }),
  computed: {
    ...mapGetters('intents', ['intents', 'displayName']),
    ...mapGetters('bots', ['currentBot', 'areChannelsBlocked']),
    isChannelFilterAvailable() {
      return this.channels.length > 1;
    },
    isSame() {
      if (this.nlp === null) return true;

      return (
        !this.nlp ||
        !this.nlp.check ||
        !this.nlp.check.nlp ||
        this.nlp.check.nlp.intent === this.intent
      );
    },
    isSmalltalk() {
      if (this.nlp === null) return false;

      return (
        this.nlp &&
        this.nlp.check &&
        this.nlp.check.nlp &&
        this.nlp.check.nlp.intent &&
        this.nlp.check.nlp.intent.startsWith('smalltalk_')
      );
    },
    detectedIntent() {
      if (this.nlp === null || !this.nlp.check || !this.nlp.check.nlp) {
        return null;
      }
      if (
        this.nlp.check.nlp.intent === 'unknown' ||
        this.nlp.check.nlp.confidence < this.nlp.check.nlp.confidenceLimit
      ) {
        return false;
      } else {
        return this.displayName(this.nlp.check.nlp.intent, this.$vuetify.lang.current);
      }
    },
  },
  watch: {
    chats() {
      this.$emit('loading', true);
      this.channelFilterDisable = true;
      this.eventFilterDisable = true;
      this.intentFilterDisable = true;
      this.recalculateFilter();
    },
    selectedChannel() {
      this.$emit('loading', true);
      this.eventFilterDisable = true;
      this.intentFilterDisable = true;
      this.recalculateFilter();
    },
    selectedEvents() {
      this.$emit('loading', true);
      this.channelFilterDisable = true;
      this.intentFilterDisable = true;
      this.recalculateFilter();
    },
    selectedIntents() {
      this.$emit('loading', true);
      this.eventFilterDisable = true;
      this.channelFilterDisable = true;
      this.recalculateFilter();
    },
  },
  created() {
    if (this.areChannelsBlocked) {
      const notBlockedIndex = this.channels.findIndex(
        (channel) => !channel.blocked
      );

      this.selectedChannel = this.channels[notBlockedIndex].channelId;
    }
    this.resetChannelCount();
  },
  methods: {
    resetChannelCount() {
      this.channels.forEach((channel) => {
        this.$set(this.channelsCount, channel.channelId, 0);
      });
    },
    resetEventsCount() {
      this.thumbUpCount = 0;
      this.goalsCount = 0;
      this.thumbDownCount = 0;
      this.suggestCount = 0;
      this.unknownCount = 0;
      this.intentsToFilter.length = 0;
    },
    recalculateFilter: _.debounce(
      async function () {
        this.filterLoading = true;
        this.resetChannelCount();
        this.resetEventsCount();

        const thumpUpFilterActive = this.isEvent('pos');
        const thumpDownFilterActive = this.isEvent('neg');
        const goalFilterActive = this.isEvent('goal');
        const unknownFilterActive = this.isEvent('unknown');
        const suggestionFilterActive = this.isEvent('suggest');

        const eventFilterNotActive = !(
          thumpDownFilterActive ||
          thumpUpFilterActive ||
          goalFilterActive ||
          unknownFilterActive ||
          suggestionFilterActive
        );
        const intentFilterNotActive = this.selectedIntents.length === 0;
        const allIntents = {};

        this.selectedIntents.forEach((intent) => {
          allIntents[intent] = {
            filter: true,
            count: 0,
          };
        });

        this.filteredChats = this.chats.filter((chat) => {
          let filter = false; // meet no criteria
          const channelFilter =
            !this.selectedChannel || this.selectedChannel === chat.channelId; // true -> meet criteria
          let eventFilter = false;
          const chatIntents = {};
          let intentFilter = false;

          chat.all.forEach((event) => {
            // intents
            let thisIntentFilter = false;
            event.intents.forEach((intent) => {
              if (!intent.startsWith('smalltalk_') && intent !== 'unknown') {
                if (!chatIntents[intent]) chatIntents[intent] = { count: 0 };
                if (allIntents[intent] && allIntents[intent].filter)
                  thisIntentFilter = true;
                chatIntents[intent].count++;
              }
            });

            /*  event.suggest.forEach(intent => {
                if (intent !== false && !intent.startsWith('smalltalk_') && intent !== 'unknown') {
                  if (!chatIntents[intent]) chatIntents[intent] = { count: 0 }
                  if (allIntents[intent] && allIntents[intent].filter) thisIntentFilter = true
                  chatIntents[intent].count++
                }
              }) */

            if (thisIntentFilter) intentFilter = thisIntentFilter;

            // Events
            if (event.pos > 0 && thumpUpFilterActive) eventFilter = true; // meet one  criteria
            if (
              event.pos > 0 &&
              channelFilter &&
              (intentFilterNotActive || thisIntentFilter)
            ) {
              this.thumbUpCount++;
            }

            if (event.neg > 0 && thumpDownFilterActive) eventFilter = true; // meet one  criteria
            if (
              event.neg > 0 &&
              channelFilter &&
              (intentFilterNotActive || thisIntentFilter)
            ) {
              this.thumbDownCount++;
            }

            if (event.goal > 0 && goalFilterActive) eventFilter = true; // meet one  criteria
            if (
              event.goal > 0 &&
              channelFilter &&
              (intentFilterNotActive || thisIntentFilter)
            ) {
              this.goalsCount++;
            }

            if (event.unknown > 0 && unknownFilterActive) eventFilter = true; // meet one  criteria
            if (
              event.unknown > 0 &&
              channelFilter &&
              (intentFilterNotActive || thisIntentFilter)
            ) {
              this.unknownCount++;
            }

            const suggestions = event.suggest.filter((s) => {
              return (
                s !== false && s !== 'unknown' && !s.startsWith('smalltalk_')
              );
            });
            if (suggestions.length > 0 && suggestionFilterActive)
              eventFilter = true; // meet one  criteria
            if (
              suggestions.length > 0 &&
              channelFilter &&
              (intentFilterNotActive || thisIntentFilter)
            ) {
              this.suggestCount++;
            }
          });

          if ((eventFilter || eventFilterNotActive) && channelFilter) {
            Object.keys(chatIntents).forEach((intent) => {
              if (!allIntents[intent]) allIntents[intent] = { count: 0 };
              allIntents[intent].count += chatIntents[intent].count;
            });
          }

          if (
            (eventFilter || eventFilterNotActive) &&
            (intentFilter || intentFilterNotActive)
          ) {
            this.channelsCount[chat.channelId]++;
          }

          filter =
            (eventFilter || eventFilterNotActive) &&
            channelFilter &&
            (intentFilter || intentFilterNotActive);

          return filter;
        });

        this.intentsToFilter.push(
          ...Object.keys(allIntents).map((intent) => {
            return { intent, count: allIntents[intent].count };
          })
        );

        this.intentsToFilter.sort((a, b) => {
          if (b.count === a.count) return a.intent.localeCompare(b.intent);
          return b.count - a.count;
        });

        if (this.thumbDownCount === 0 && thumpDownFilterActive) {
          this.removeEvent('neg');
        }

        if (this.intentsToFilter.length > 0) {
          const removeIntents = [];
          let smallestSelectedIntent = {
            count: this.intentsToFilter[0].count + 1,
            idx: -1,
          };
          this.selectedIntents.forEach((intent, n) => {
            if (allIntents[intent].count === 0) removeIntents.push(n);
            else if (
              smallestSelectedIntent.count > allIntents[intent].count ||
              (smallestSelectedIntent.count === allIntents[intent].count &&
                (smallestSelectedIntent.idx === -1 ||
                  this.selectedIntents[
                    smallestSelectedIntent.idx
                  ].localeCompare(intent) < 0))
            )
              smallestSelectedIntent = {
                count: allIntents[intent].count,
                idx: n,
              };
          });

          if (smallestSelectedIntent.idx >= 0) {
            const limitSelectedIdx = this.intentsToFilter.findIndex((i) => {
              return (
                i.intent === this.selectedIntents[smallestSelectedIntent.idx]
              );
            });

            if (limitSelectedIdx >= 5)
              this.intentFilterLimit = limitSelectedIdx + 1;
            else this.intentFilterLimit = 5;
          } else {
            this.intentFilterLimit = 5;
          }

          removeIntents.forEach((n) => {
            this.selectedIntents.splice(n, 1);
          });
        } else this.intentFilterLimit = 5;

        this.filterLoading = false;
        this.eventFilterDisable = false;
        this.intentFilterDisable = false;
        this.channelFilterDisable = false;
        this.$emit('loading', false);
        return this.$emit('input', this.filteredChats);
      },
      1000,
      { leading: false }
    ),
    isEvent(event) {
      return this.selectedEvents.find((e) => {
        return e === event;
      });
    },
    removeEvent(event) {
      const index = this.selectedEvents.findIndex((e) => {
        return e === event;
      });

      if (index >= 0) {
        this.selectedEvents.splice(index);
      }
    },
  },
};
</script>

<style scoped scss>
</style>

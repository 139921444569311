// Bounded to de.json / en.json common.months
export const monthSlugs = [
  'jan',
  'feb',
  'mar',
  'apr',
  'may',
  'jun',
  'jul',
  'aug',
  'sep',
  'oct',
  'nov',
  'dec',
];

// Bounded to de.json / en.json common.weekdays
export const weekdaysSlugs = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
export function getTimeLabel(milliseconds, vueThis) {
  const days = Math.floor(milliseconds / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (milliseconds - days * (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor(
    (milliseconds - days * (1000 * 60 * 60 * 24) - hours * (1000 * 60 * 60)) /
      (1000 * 60)
  );
  const seconds = Math.floor(
    (milliseconds - days * (1000 * 60 * 60 * 24) - hours * (1000 * 60 * 60) - minutes * (1000 * 60)) /
      1000
  );

  let result = '';

  if (days === 1) {
    result += `${days} ${vueThis.$t('common.day')}`;
  } else if (days > 1) {
    result += `${days} ${vueThis.$t('common.days')}`;
  }

  if (hours === 1) {
    result += ` ${hours} ${vueThis.$t('common.hour')}`;
  } else if (hours > 1) {
    result += ` ${hours} ${vueThis.$t('common.hours')}`;
  }

  if (minutes === 1) {
    result += ` ${minutes} ${vueThis.$t('common.minute')}`;
  } else if (minutes > 0) {
    result += ` ${minutes} ${vueThis.$t('common.minutes')}`;
  }

  if (seconds === 1) {
    result += ` ${seconds} ${vueThis.$t('common.second')}`;
  } else if (seconds > 0) {
    result += ` ${seconds} ${vueThis.$t('common.seconds')}`;
  }

  return result.trim();
}

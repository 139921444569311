<template>
    <v-avatar class="align-center" :size="size"  :style="style">
        <v-img :src="profilePicture">
            <template v-slot:placeholder>
            <v-icon :large="!small && !large"  :x-large="large">{{ placeholderIcon }}</v-icon>
            </template>
        </v-img>
    </v-avatar>
</template>
<script>
export default {
    name: 'ProfileAvatar',
    props: {
        profilePicture: {
            type: String,
            default: '',
        },
        placeholderIcon: {
            type: String,
            default: 'mdi-face-agent',
        },
        border: {
            type: String,
            default: '2px solid var(--v-primary-base)',
        },
        small: {
            type: Boolean,
            default: false,
        },
        large: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        style() {
            return {
                border: this.border,
            };
        },
        size() {
            if (this.small) {
                return 32;
            } else if (this.large) {
                return 64;
            } else {
                return 48;
            }
        }
    }
};
</script>
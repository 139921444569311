/**
 * @typedef {string} BotSettingKeyEnum
 * @enum {BotSettingKeyEnum}
 */
const BotSettingKeyEnum = {
  backup: 'backup',
  cleanup: 'cleanup',
  files: 'files',
  notifications: 'notifications',
};

module.exports = { BotSettingKeyEnum };

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-app',{staticStyle:{"overflow":"hidden","height":"100vh","max-height":"100vh"}},[_c('v-app-bar',{staticClass:"moin-fresh",attrs:{"app":"","color":"white"}},[_c('div',{staticClass:"pa-2"},[_c('a',{attrs:{"href":"https://moin.ai"}},[_c('img',{attrs:{"src":require("@/assets/moin-logo.svg"),"height":"38px"}})])]),_c('div',{staticClass:"ml-3 mr-4",staticStyle:{"height":"30px"}},[_c('v-divider',{attrs:{"vertical":""}})],1),_c('h1',{staticClass:"primary--text",class:_vm.$vuetify.breakpoint.smAndDown
              ? 'text-body-1 font-weight-bold'
              : 'text-h6',staticStyle:{"line-height":"1"}},[_vm._v(" "+_vm._s(_vm.$t('demo.appTitle'))+" ")]),(_vm.event)?_c('div',{staticClass:"d-flex items-center",staticStyle:{"position":"absolute","right":"8px","top":"0px","height":"100%"}},[(_vm.event === _vm.eventStrings.ccw)?[_c('div',{staticClass:"demo-ccw-logo",class:[
                _vm.$vuetify.breakpoint.xs ? 'demo-ccw-logo--xs' : 'mr-3 mt-1',
              ]},[_c('img',{attrs:{"height":"44","width":"auto","src":"/demo-event/ccw-logo.png"}})])]:_vm._e(),(_vm.event === _vm.eventStrings.ccw2024)?[_c('div',{staticClass:"demo-ccw-logo",class:[
                _vm.$vuetify.breakpoint.xs ? 'demo-ccw-logo--xs' : 'mr-3 mt-1',
              ]},[_c('img',{attrs:{"height":"44","width":"auto","src":"/demo-event/logo_ccw_2024.png"}})])]:_vm._e(),(_vm.event === _vm.eventStrings.internorga)?[_c('img',{attrs:{"height":_vm.$vuetify.breakpoint.xs ? 20 : 40,"width":"auto","src":"/demo-event/internorga-logo.png"}})]:_vm._e(),(_vm.event === _vm.eventStrings.omr)?[_c('img',{staticClass:"mr-2",attrs:{"height":"38","width":"auto","src":"/demo-event/omr-logo.png"}})]:_vm._e(),(_vm.event === _vm.eventStrings.sesu)?[_c('img',{staticClass:"mr-2",attrs:{"height":"38","width":"auto","src":"/demo-event/sesu-logo.png"}})]:_vm._e()],2):_vm._e(),(_vm.loading)?_c('v-progress-linear',{staticStyle:{"bottom":"0","left":"0","right":"0"},attrs:{"absolute":"","indeterminate":"","color":"secondary"}}):_vm._e()],1),_c('v-main',{staticClass:"demo-main moin-fresh",attrs:{"app":""}},[_c('div',{staticClass:"demo d-flex"},[(_vm.step === _vm.stepSlugs.stepCompany)?[_c('DemoStepCompany')]:[(_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"demo-sidebar"},[_c('DemoSidebar')],1):_vm._e(),_c('div',{staticClass:"demo-content flex-grow-1"},[_c('div',{staticClass:"demo-slider"},[_c('div',{attrs:{"id":"demo-slider-top"}}),_c('DemoContent')],1)])]],2)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="widget-btn-preview">
    <div class="widget-btn-preview-boebbles">
      <div class="boebble"></div>
      <div class="boebble"></div>
      <div class="boebble"></div>
    </div>
    <div class="widget-btn-preview-address">      
    </div>
    <div class="widget-btn-preview-container" :class="[alignLeft && 'widget-btn-preview-container--left']">
      <svg
        width="100px"
        height="99px"
        viewBox="0 0 100 99"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <defs>
          <filter
            x="-65.0%"
            y="-65.0%"
            width="230.0%"
            height="230.0%"
            filterUnits="objectBoundingBox"
            id="filter-1"
          >
            <feOffset
              dx="0"
              dy="0"
              in="SourceAlpha"
              result="shadowOffsetOuter1"
            ></feOffset>
            <feGaussianBlur
              stdDeviation="10"
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
            ></feGaussianBlur>
            <feColorMatrix
              values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.25 0"
              type="matrix"
              in="shadowBlurOuter1"
              result="shadowMatrixOuter1"
            ></feColorMatrix>
            <feMerge>
              <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
              <feMergeNode in="SourceGraphic"></feMergeNode>
            </feMerge>
          </filter>
          <circle id="path-2" cx="30" cy="30" r="30"></circle>
        </defs>
        <g
          id="2---Molecules"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <g
            id="molecules/widget"
            transform="translate(-158.000000, -258.000000)"
          >
            <g
              id="2-molecule/widget/widget-button"
              transform="translate(178.000000, 277.500000)"
            >
              <g id="widget-button" filter="url(#filter-1)">
                <mask id="mask-3" fill="white">
                  <use xlink:href="#path-2"></use>
                </mask>
                <use fill="#BEBEBE" xlink:href="#path-2"></use>
                <g id="Group" mask="url(#mask-3)" :fill="color">
                  <g id="1-atom/color/branding/_nobranding_1">
                    <rect id="Color" x="0" y="0" width="60" height="60"></rect>
                  </g>
                </g>
              </g>
              <g
                id="Chatbubble"
                transform="translate(14.000000, 17.500000)"
                :fill="contrast === 'light' ? '#ffffff' : '#484848'"
              >
                <path
                  d="M27.4436004,0 C29.9288818,3.19750463e-14 31.9436004,2.01471863 31.9436004,4.5 L31.9436004,23.6666155 L32,27 L25.3862834,23.7357795 L4.48773996,23.6788422 C2.00725429,23.6720842 -2.45652227e-14,21.6593537 -2.48689958e-14,19.1788589 L-2.48689958e-14,4.5 C-2.51733549e-14,2.01471863 2.01471863,4.56538782e-16 4.5,0 L27.4436004,0 Z M7.41530443,10.0986822 C5.77160977,10.0986822 4.43913198,11.4139794 4.43913198,13.0364807 C4.43913198,14.658982 5.77160977,15.9742792 7.41530443,15.9742792 C9.05899908,15.9742792 10.3914769,14.658982 10.3914769,13.0364807 C10.3914769,11.4139794 9.05899908,10.0986822 7.41530443,10.0986822 Z M16.2859518,10.0986822 C14.6422571,10.0986822 13.3097793,11.4139794 13.3097793,13.0364807 C13.3097793,14.658982 14.6422571,15.9742792 16.2859518,15.9742792 C17.9296464,15.9742792 19.2621242,14.658982 19.2621242,13.0364807 C19.2621242,11.4139794 17.9296464,10.0986822 16.2859518,10.0986822 Z M25.1565991,10.0986822 C23.5129044,10.0986822 22.1804266,11.4139794 22.1804266,13.0364807 C22.1804266,14.658982 23.5129044,15.9742792 25.1565991,15.9742792 C26.8002938,15.9742792 28.1327715,14.658982 28.1327715,13.0364807 C28.1327715,11.4139794 26.8002938,10.0986822 25.1565991,10.0986822 Z"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WidgetButtonPreview',
  props: {
    color: {
      type: String,
      default: '#FF0000',
    },
    contrast: {
      type: String,
      default: 'light',
    },
    alignLeft: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.widget-btn-preview {
  background: white;
  box-shadow: 0 0 8px rgb(0 0 0 / 25%);
  width: 100%;
  max-width: 375px;
}

.widget-btn-preview-boebbles {
  display: flex;
  height: 20px;
  padding: $spacer * 2;

  .boebble {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #dedede;
    margin-right: $spacer;
  }
}

.widget-btn-preview-address {
  height: $spacer * 5;
  margin: $spacer * 2;
  border: 1px solid #e8e8e8;
  background: #f5f5f5;
  border-radius: 20px;
}

.widget-btn-preview-container {
  display: flex;
  align-items: flex-end;
  justify-content: right;
  height: 200px;
  width: 100%;
  padding-top: 5 * $spacer;
  background: #dedede;

  &--left {
    justify-content: flex-start;
  }
}
</style>

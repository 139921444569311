<template>
  <svg
    :width="this.width"
    viewBox="0 0 72 119"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" width="71" height="108" :fill="this.colors.bg" />
    <rect x="0.5" width="71" height="13" :fill="this.colors.header" />
    <rect x="0.5" y="13" width="71" height="13" :fill="this.colors.bot" />
    <rect x="0.5" y="108" width="71" height="11" :fill="this.colors.footer" />
    <rect x="14.8145" y="65" width="52.6774" height="16" :fill="this.colors.user" />
    <rect x="3.93555" y="87" width="52.6774" height="16" :fill="this.colors.bot" />
    <rect x="3.93555" y="43" width="52.6774" height="16" :fill="this.colors.bot" />
  </svg>
</template>

<script>
export default {
  props: ['colors', 'width']
}
</script>

<template>
  <div class="moin-fade-in mt-10 d-flex justify-space-between">
    <div>
      <v-btn
        outlined
        class="mr-2"
        @click="$emit('onBack')"
        :disabled="disabled"
      >
        {{ $t('common.back') }}
      </v-btn>
      <v-btn
        v-if="!hideNextBtn"
        color="secondary"
        depressed
        @click="$emit('onNext')"
        :disabled="disabled"
      >
        {{ $t('common.next') }}
      </v-btn>
    </div>
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: 'DemoBtns',
  components: {},
  props: {
    disabled: Boolean,
    hideNextBtn: Boolean,
  },
};
</script>

const state = {
  selectedPath: {},
  renderedSteps: [],
  currentIntent: null,
  currentBox: null,
  currentChannel: null,
  isEventTrackingAvailable: false,
};

const getters = {
  formSteps: (state) => state.renderedSteps,
  getSelectedPath: (state) => (box) => state.selectedPath[box],
  selectedPath: (state) => state.selectedPath,
  isEventTrackingAvailable: (state) => state.isEventTrackingAvailable,
};

const actions = {
  updateRenderedSteps({ state, commit, rootGetters }, currentBotId) {
    let content = rootGetters['content/getContent'](
      currentBotId,
      state.currentIntent,
      state.currentBox,
      true,
      this.currentChannel
    );
    let renderedSteps = [];

    if (!content || !content.childBoxes || content.childBoxes.length === 0) {
      commit('setSteps', { steps: renderedSteps });
      return;
    }

    // find event tracking in completion
    if (content.completion && content.completion.actions && content.completion.actions.length > 0) {
      let eventTracking = content.completion.actions.find((action) => action.name === '$event'
        && action.parameters && action.parameters.name === 'botform_finish');
      commit('setEventTracking', { isAvailable: !!eventTracking });
    }

    function crawl(boxes, next) {
      let box = content.childBoxes.find((b) => {
        return b.box === next;
      });

      if (box) {
        boxes.push(box);

        if (Array.isArray(box.values) && box.values.length > 0) {
          if (state.selectedPath[box.box]) {
            let value = box.values.find((v) => {
              return v.value === state.selectedPath[box.box];
            });
            if (value) {
              return crawl(boxes, value.box);
            } else {
              return boxes;
            }
          } else {
            commit('selectPath', { box: box.box, intent: box.values[0].value });
            return crawl(boxes, box.values[0].box);
          }
        } else {
          return crawl(boxes, box.next);
        }
      }

      return boxes;
    }

    renderedSteps = crawl([], content.start.next);

    commit('setSteps', { steps: renderedSteps });
  },
  async selectPath({ commit }, { box, intent }) {
    await commit('selectedPath', { box, intent });
  },
};

const mutations = {
  setForm(state, { intent, box, channel }) {
    state.currentIntent = intent;
    state.currentBox = box;
    state.currentChannel = channel;
  },
  setSteps(state, { steps }) {
    state.renderedSteps = steps;
  },
  selectPath(state, { box, intent }) {
    if (box && intent) {
      state.selectedPath = { ...state.selectedPath, [box]: intent };
    }
  },
  resetPath(state) {
    state.selectedPath = {};
  },
  resetState(state) {
    state.selectedPath = {};
    state.renderedSteps = [];
    state.currentIntent = null;
    state.currentBox = null;
    state.currentChannel = null;
  },
  setEventTracking(state, { isAvailable }) {
    state.isEventTrackingAvailable = isAvailable;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

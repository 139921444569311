<template>
  <div>
    <InfoBox class="mb-4" :text="$t('intentNew.stepName.infoNameScenario')" />

    <v-row class="mt-4">
      <v-col cols="12">
        <div></div>
        <v-text-field
          class="mb-2"
          v-model="displayName"
          outlined
          color="secondary darken-2"
          autofocus
          @input="validateInputs"
          counter
          maxlength="50"
        />
        <div class="mt-n8 pr-6">
          <InfoBox
            :text="$t('intentNew.stepName.displayName.hint')"
            :icon="null"
          />
        </div>
        <div v-if="displayNameDuplicate">
          <v-alert
            outlined
            color="red darken-2"
            class="mt-2"
            dense
          >
            <div class="text-body-2 error--text">
              {{ $t('intentNew.stepName.displayName.error') }}
            </div>
          </v-alert>
        </div>
      </v-col>
    </v-row>
    <v-row class="mt-4" v-if="false">
      <v-col cols="12">
        <v-text-area
          class="mb-2"
          v-model="description"
          outlined
          color="secondary darken-2"
          hide-details
          @input="validateInputs"
        />
        <InfoBox
          :text="$t('intentNew.stepName.description.hint')"
          :icon="null"
        />
      </v-col>
    </v-row>
    <v-row class="mt-4">
      <v-col cols="12">
        <v-textarea
          class="mb-2"
          v-model="scenario"
          outlined
          color="secondary darken-2"
          hide-details
          @input="validateInputs"
        />
        <InfoBox :text="$t('intentNew.stepName.scenario.hint')" :icon="null" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import InfoBox from '@/components/common/InfoBox.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'IntentNewStepName',
  components: {
    InfoBox,
  },
  computed: {
    ...mapFields('intentNew', [
      'newIntent.displayName',
      'newIntent.description',
      'newIntent.scenario',
      'searchResults'
    ]),
    ...mapGetters('intents', ['getIntentsFromLibrary']),
    displayNameDuplicate() {
      console.log(this.getIntentsFromLibrary);
      return this.getIntentsFromLibrary.find(intent => intent.displayName.toLowerCase() === this.displayName.toLowerCase());
    },
  },
  created() {
    if (!this.displayName && this.searchResults.newTopic?.title) {
      this.displayName = this.searchResults.newTopic.title;
    }
    if (!this.scenario && this.searchResults.newTopic?.description) {
      this.scenario = this.searchResults.newTopic.description;
    }
    
    this.validateInputs();
  },
  methods: {
    validateInputs() {
      if (
        !this.displayName.length ||
        !this.scenario.length || this.displayNameDuplicate
      ) {
        this.$emit('onDisableChange', {
          isDisabled: true,
          message: this.$t('intentNew.stepName.errorMessage'),
        });
        return false;
      }
      this.$emit('onDisableChange', { isDisabled: false, message: null });
      return false;
    },
  },
};
</script>

<script>
import { mapGetters } from 'vuex';
import Settings from '@/services/settings';

export default {
  name: 'ShopwareAuth',
  props: {},
  data() {
    return {
      loading: false,
      message: '',
      savingToast: false,
      savingToastError: false,
      basicAuth: false,

      url: '',
      identifier: '',
      secret: '',

      urlRules: [
        (v) => {
          return (v||'').startsWith('https://') || this.$t('integrations.rules.url');
        },
      ],
    };
  },
  methods: {
    async oauth() {
      this.savingToastError = false;
      this.loading = true;

      try {
        const saveResult = await Settings.saveOAuthToken(
          this.currentBotId,
          'shopware',
          undefined,
          undefined,
          {
            authType: this.basicAuth ? 'user' : 'integration',
            url: this.normalizedUrl,
            identifier: this.identifier,
            secret: this.secret,

          }
        );

        if (saveResult.error) throw new Error(saveResult.error);
        if (saveResult !== true)
          throw new Error(this.$t('integrations.shopware.alerts.failed'));
      } catch (e) {
        this.message = e.message || e.toString();
        this.savingToastError = true;
      } finally {
        this.savingToast = true;
        return (this.loading = false);
      }
    },
  },
  computed: {
    ...mapGetters('bots', ['currentBotId']),
    /**
     * The base URL without a trailing slash
     * @returns {string}
     */
    normalizedUrl() {
      if (!this.url) return 'https://missing.site.url';
      return this.url.endsWith('/') ? this.url.slice(0, -1) : this.url;
    },
  },
  watch: {
    basicAuth() {
      this.identifier = '';
      this.secret = '';
    },
  },
};
</script>

<template>
  <div>
    <v-card>
      <v-card-title>{{ $t('integrations.shopware.title') }}</v-card-title>
      <p class="px-4">
        {{ $t('integrations.shopware.description') }}
      </p>
      <v-btn-toggle
        class="pb-4 mx-4"
        v-model="basicAuth"
        mandatory
        borderless
        color="primary lighten-2"
      >
        <v-btn style="min-width: 120px" :value="false">
          <span>{{ $t('integrations.shopware.integration.label') }}</span>
        </v-btn>

        <v-btn style="min-width: 120px" :value="true">
          <span>{{ $t('integrations.shopware.user.label') }}</span>
        </v-btn>
      </v-btn-toggle>
      <div class="mx-4 d-flex gap-4">
        <v-text-field
          :label="$t(`integrations.shopware.${basicAuth ? 'user' : 'integration'}.identifier`)"
          v-model="identifier"
          :placeholder="$t(`integrations.shopware.${basicAuth ? 'user' : 'integration'}.placeholder`)"
          outlined
        />
        <v-text-field
          :label="$t(`integrations.shopware.${basicAuth ? 'user' : 'integration'}.secret`)"
          v-model="secret"
          type="password"
          outlined
        />
      </div>
      <v-text-field
        class="px-4"
        :label="$t('integrations.shopware.url.label')"
        v-model="url"
        :rules="urlRules"
        :placeholder="$t('integrations.shopware.url.placeholder')"
        outlined
      />
      <div class="d-flex justify-end">
        <v-btn
          color="success"
          class="ma-4"
          :disabled="!url || !identifier || !secret || loading"
          @click="oauth()"
        >
          {{$t('common.connect')}}
          <v-icon right dark>mdi-link-variant</v-icon>
        </v-btn>
      </div>
    </v-card>

    <v-snackbar v-model="savingToast" :timeout="6000">
      <v-icon type="success" left>check</v-icon>
      {{ $t('integrations.shopware.alerts.success') }}
    </v-snackbar>

    <v-snackbar v-model="savingToastError" :timeout="6000">
      <v-icon color="red" type="error" left>mdi-alert-circle</v-icon>
      {{ message }}
    </v-snackbar>
  </div>
</template>
import moment from 'moment';
import ApiService from './api';

const DialogService = {
  getDialogs: async function(botId) {
    try {
      const response = await ApiService.get('/schaltzentrale/dialogs/' + botId);
      return response.data;
    } catch (error) {
      return {};
    }
  },
  getFilteredDialogs: async function(
    uniqueBotId,
    from,
    to,
    intent,
    filter,
    channel,
    unknown
  ) {
    try {
      const body = {
        uniqueBotId,
        from,
        to,
      };

      if (unknown) {
        body.unknown = true;
      } else {
        if (intent) {
          body.intent = intent;
        }

        if (filter) {
          body.filter = filter;
        }

        if (channel) {
          body.channel = channel;
        }
      }

      const response = await ApiService.post(`/schaltzentrale/dialogs`, body);
      return response.data;
    } catch (error) {
      return {};
    }
  },
  getChat: async function(uniqueUserId, botId) {
    try {
      const response = await ApiService.get(
        '/schaltzentrale/dialogs/chat/' + botId + '/' + uniqueUserId
      );
      return response.data;
    } catch (error) {
      return {};
    }
  },
  getChats: async function(
    botId,
    date = moment()
      .startOf('day')
      .toDate()
      .toISOString()
  ) {
    date = moment(date);
    try {
      const response = await ApiService.get(
        '/schaltzentrale/chats/' + botId + '/' + date.format('YYYY-MM-DD')
      );
      return response.data;
    } catch (error) {
      return {};
    }
  },
  getChatHistory: async function(botId, uuid) {
    try {
      const response = await ApiService.get(
        '/schaltzentrale/chats/history/' + botId + '/' + uuid
      );
      return response.data;
    } catch (error) {
      return {};
    }
  },
  deleteConversation: async function(botId, uniqueUserId) {
    try {
      const response = await ApiService.post(
        `/messages/delete/${botId}/${uniqueUserId}`
      );
      return response.data;
    } catch (error) {
      return {};
    }
  },
};

export default DialogService;

export { DialogService };

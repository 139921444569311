<template>
  <v-select
    :items="bots"
    label="Bots"
    item-text="name"
    return-object
    :value="currentBot"
    @change="onChangeBot($event)"
    outlined
    v-if="bots.length > 1"
  />
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'BotPicker',
  computed: {
    ...mapGetters('bots', ['bots', 'currentBot']),
  },
  methods: {
    onChangeBot(bot) {
      this.$store.dispatch('bots/changeBot', {
        uniqueBotId: bot.uniqueBotId,
        router: this.$router,
      });
    },
  },
};
</script>

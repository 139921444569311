<template>
  <v-app style="max-height: 100vh; height: 100vh">
    <HubMenu />
    <Appbar />
    <HubContent :isSidebarOpen="isSidebarOpen">
      <template v-slot:navbar>
        <HubContentNavbar :title="reportTitle" :backTo="{ path: '/statistics' }">
          <template v-slot:toolbar>
            <BtnSquare
              icon="view_sidebar"
              @click="() => (isSidebarOpen = !isSidebarOpen)"
            />
          </template>
        </HubContentNavbar>
      </template>
      <template v-slot:sidebar>
        <HubContentSidebarSimple
          :isOpen="isSidebarOpen"
          @onClose="() => (isSidebarOpen = false)"
        >
          <StatsReportSidebar :statsType="statsType" :title="reportTitle" />
        </HubContentSidebarSimple>
      </template>
      <template v-slot:content>
        <div class="pa-3">
          <StatsHeader :compareDisabled="isCompareDisabled" />
          <!-- Content -->
          <component :is="currentReport" />
        </div>
      </template>
    </HubContent>
  </v-app>
</template>

<script>
import HubMenu from '@/components/hub/HubMenu.vue';
import Appbar from '@/components/Appbar.vue';
import HubContent from '@/components/hub/HubContent.vue';
import StatsHeader from '@/components/stats/components/StatsHeader.vue';
import HubContentNavbar from '@/components/hub/HubContentNavbar.vue';
import HubContentSidebarSimple from '@/components/hub/HubContentSidebarSimple.vue';
import BtnSquare from '@/components/common/BtnSquare.vue';
import StatsReportSidebar from '@/components/stats/reports/StatsReportSidebar.vue';
import { statsTypes } from '@/components/stats/statsTypes';

export default {
  name: 'StatsReport',
  components: {
    HubMenu,
    Appbar,
    HubContent,
    HubContentNavbar,
    HubContentSidebarSimple,
    BtnSquare,
    StatsHeader,
    StatsReportSidebar,
    StatsReportConversation: () =>
      import('@/components/stats/reports/StatsReportConversation.vue'),
    StatsReportIntents: () =>
      import('@/components/stats/reports/StatsReportIntents.vue'),
    StatsReportAnualReview: () =>
      import('@/components/stats/reports/StatsReportAnualReview.vue'),
    // StatsReportAnswers: () =>
    //   import('@/components/stats/reports/StatsReportAnswers.vue'),
    // StatsReportAutomation: () =>
    //   import('@/components/stats/reports/StatsReportAutomation.vue'),
    // StatsReportConversions: () =>
    //   import('@/components/stats/reports/StatsReportConversions.vue'),
    // StatsReportRatings: () =>
    //   import('@/components/stats/reports/StatsReportRatings.vue'),
    // StatsReportTakeovers: () =>
    //   import('@/components/stats/reports/StatsReportTakeovers.vue'),
  },
  props: {
    statsType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isSidebarOpen: false,
      componentMapping: {
        [statsTypes.conversation]: 'StatsReportConversation',
        [statsTypes.intents]: 'StatsReportIntents',
        [statsTypes.anualReview]: 'StatsReportAnualReview',
        // [statsTypes.answers]: 'StatsReportAnswers',
        // [statsTypes.automation]: 'StatsReportAutomation',
        // [statsTypes.conversions]: 'StatsReportConversions',
        // [statsTypes.ratings]: 'StatsReportRatings',
        // [statsTypes.takeovers]: 'StatsReportTakeovers',
      },
    };
  },
  computed: {
    currentReport() {
      return this.componentMapping[this.statsType];
    },
    reportTitle() {
      return `${this.$t('stats.reports.title')}: ${this.$t(
        `stats.${this.statsType}`
      )}`;
    },
    isCompareDisabled() {
      return this.statsType === statsTypes.intents;
    },
  },
  methods: {},
};
</script>

<template>
  <v-app style="max-height: 100vh; height: 100vh">
    <HubMenu />
    <Appbar />
    <HubContent>
      <template v-slot:navbar>
        <HubContentNavbar
          :title="'Dashboard'"
          :isLoading="isLoading"
        ></HubContentNavbar>
      </template>
      <template v-slot:content v-if="currentBotId">
        <HubFrameSimple>
          <template v-slot:header>
            <WelcomeHeaderBar />
          </template>
          <template v-slot:content>
            <WelcomeAvatar class="pt-8 pb-14" />
            <WelcomeSupport class="pb-14" />
            <WelcomeStats
              class="pb-14"
              v-if="currentBotStage === stageTypes.LIVE"
            />
            <WelcomeKnowledge class="pb-5" />
          </template>
        </HubFrameSimple>
      </template>
    </HubContent>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import { TrackingService } from '@/services/tracking';

import { stageTypes } from '@/store/modules/welcome';
import HubMenu from '@/components/hub/HubMenu.vue';
import Appbar from '@/components/Appbar.vue';
import WelcomeHeaderBar from '@/components/welcome/WelcomeHeaderBar.vue';
import HubContent from '@/components/hub/HubContent.vue';
import HubContentNavbar from '@/components/hub/HubContentNavbar.vue';
import HubFrameSimple from '@/components/hub/HubFrameSimple.vue';
// import WelcomeTask from '@/components/welcome/WelcomeTask.vue';
import WelcomeStats from '@/components/welcome/WelcomeStats.vue';
import WelcomeAvatar from '@/components/welcome/WelcomeAvatar.vue';
import WelcomeSupport from '@/components/welcome/WelcomeSupport.vue';
import WelcomeKnowledge from '@/components/welcome/WelcomeKnowledge.vue';

export default {
  name: 'Welcome',
  components: {
    HubMenu,
    Appbar,
    HubContent,
    HubFrameSimple,
    HubContentNavbar,
    WelcomeHeaderBar,
    WelcomeAvatar,
    WelcomeSupport,
    // WelcomeTask,
    WelcomeStats,
    WelcomeKnowledge,
  },
  beforeRouteEnter(to, from, next) {
    next(true);
    // called before the route that renders this component is confirmed.
    // does NOT have access to `this` component instance,
    // because it has not been created yet when this guard is called!
  },
  computed: {
    ...mapGetters('welcome', ['tasks', 'knowledge']),
    ...mapGetters('bots', ['currentBotStage', 'currentBotId']),
  },
  data() {
    return {
      isLoading: false,
      stageTypes,
    };
  },
  methods: {
    trackIt() {
      TrackingService.track({
        botId: this.currentBotId,
        name: 'welcome',
        type: 'view',
        source: 'Welcome',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.slide-width {
  transition: max-width 0.3s ease;
}
</style>

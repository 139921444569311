<template>
  <v-app style="max-height: 100vh; height: 100vh">
    <HubMenu />

    <Appbar />

    <HubContent :isSidebarOpen="isSidebarOpen" fixedHeight>
      <template v-slot:navbar>
        <HubContentNavbar :title="$t('widgetTeaser.title')" />
      </template>
      <template v-slot:content>
        <v-container fluid>
          <v-row>
            <v-col>
              <v-list-item-group
                class="white"
                color="primary"
                rounded
              >
                <template v-for="(subPage, index) in subPages">
                  <v-list-item
                    :value="subPage"
                    :key="subPage.slug"
                    :to="`/wt/${subPage.slug}`"
                    :disabled="subPage.isDisabled"
                  >
                    <v-list-item-content>
                      <div class="d-sm-flex">
                        <div class="flex-grow-auto d-flex align-center">
                          <v-card
                            outlined
                            class="
                              flex-shrink-0
                              d-flex
                              a-lign-center
                              justify-center
                            "
                            style="height: 70px; width: 116px"
                          >
                            <v-icon color="primary lighten-3">
                              {{ subPage.icon }}
                            </v-icon>
                          </v-card>
                        </div>
                        <div class="flex-grow-auto d-flex align-center pa-4">
                          <div class="pt-2">
                            <h3 class="text-h5 text-left">
                              {{ subPage.title }}
                            </h3>
                            <p
                              class="
                                primary--text
                                text--lighten-3 text-body-2 text-left
                              "
                            >
                              <span v-html="subPage.description"></span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider v-if="index < subPages.length - 1" :key="index" />
                </template>
              </v-list-item-group>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </HubContent>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import HubMenu from '@/components/hub/HubMenu.vue';
import Appbar from '@/components/Appbar.vue';
import HubContent from '@/components/hub/HubContent.vue';
import HubContentNavbar from '@/components/hub/HubContentNavbar.vue';
import { TrackingService } from '@/services/tracking';

export default {
  name: 'WidgetTeaser',
  components: {
    HubMenu,
    Appbar,
    HubContent,
    HubContentNavbar,
  },
  data() {
    return {
      isSidebarOpen: false,
      selectedSubpage: null,
    };
  },
  computed: {
    ...mapGetters('auth', ['isSimpleEdit']),
    subPages() {
      const pageList = [
        {
          title: this.$t('widgetTeaser.widget.title'),
          icon: 'mdi-robot-outline',
          slug: 'widget',
          description: this.$t('widgetTeaser.widget.description'),
        },
        {
          title: this.$t('widgetTeaser.teaser.title'),
          icon: 'mdi-message-text-outline',
          slug: 'teaser',
          description: this.$t('widgetTeaser.teaser.description'),
          isDisabled: !this.isSimpleEdit,
        },
      ];

      return pageList;
    },
  },
  methods: {
    selectSubpage(value) {
      if (!value) this.closeSidebar();
      this.selectedSubpage = value;
      if (this.selectedSubpage) this.isSidebarOpen = true;
    },
    closeSidebar() {
      this.selectedSubpage = null;
      this.isSidebarOpen = false;
    },
    trackIt(type = 'view') {
      TrackingService.track({
        name: 'wt',
        botId: this.currentBotId,
        type: type,
        source: 'WidgetTeaser.vue',
      });
    },
  },
  watch: {
    currentBotId() {
      this.init();
      this.trackIt();
    },
  },
};
</script>
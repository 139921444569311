<template>
  <v-row v-if="teaser && teaser.payload && teaser.payload.quickActions">
    <v-col cols="12">
      <h3 class="text-h6">{{ $t('teasers.actions.title') }}</h3>
      <div class="text-body-1">{{ $t('teasers.actions.info') }}</div>
    </v-col>
    <v-col cols="12" sm="6">
      <v-card class="fill-height d-flex align-center pa-4" outlined>
        <TeasersTeaserPreview :teaser="teaser" />
      </v-card>
    </v-col>
    <v-col cols="12" sm="6">
      <div>
        <v-textarea
          :label="$t('teasers.actions.teaserText')"
          v-model="teaser.payload.message"
          outlined
          row-height="20"
          auto-grow
          @input="checkDirty"
          :rules="teaserTextRules"
        />
      </div>
      <VueDraggable v-model="actions" @end="updateTeaserActions" handle=".drag-item">
        <v-card
          outlined
          v-for="(action, index) in actions"
          :key="action.id"
          class="mb-2"
        >
          <v-progress-linear
            v-if="isLoading"
            indeterminate
            color="secondary darken-2"
            absolute
          ></v-progress-linear>
          <div class="d-flex align-center px-2">
            <div class="pt-1 drag-item">
              <v-icon>mdi-drag-vertical</v-icon>
            </div>
            <div
              class="flex-grow-1 text-body-1 pt-1 grey--text text-darken-2 primary--text text--lighten-2"
            >
              {{ `${$t('teasers.actions.action')} ${index + 1}:` }}
            </div>
            <BtnSquare
              class="mt-2"
              icon="mdi-delete"
              @click="deleteAction(action.id)"
              :disabled="
                index === 0 && teaser.payload.quickActions.length === 1
              "
            />
          </div>
          <div class="py-2 px-4">
            <v-text-field
              class="mb-2"
              v-model="action.text"
              outlined
              dense
              hide-details
              type="text"
              :label="`${$t('teasers.actions.text')}`"
              @keyup="updateTeaserActions"
            ></v-text-field>
            <v-select
              v-if="intents"
              :items="targetOptionsFiltered"
              v-model="action.targetIntent"
              :label="`${$t('teasers.actions.target')}`"
              @change="updateTeaserActions"
              item-text="displayName"
              item-value="intent"
              :disabled="isLoading"
            ></v-select>
          </div>
          <div class="d-flex"></div>
        </v-card>
      </VueDraggable>
      <v-btn outlined @click="addAction">
        {{ $t('teasers.actions.addAction') }}
        <v-icon outlined right>mdi-plus</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
import BtnSquare from '@/components/common/BtnSquare.vue';
import { mapGetters } from 'vuex';
import _ from 'lodash';
import VueDraggable from 'vuedraggable';
import TeasersTeaserPreview from '@/components/teasers/TeasersTeaserPreview.vue';

export default {
  name: 'TeasersActions',
  components: {
    BtnSquare,
    VueDraggable,
    TeasersTeaserPreview,
  },
  data() {
    return {
      isLoading: false,
      actions: [],
      targetOptionsFiltered: [],
    };
  },
  computed: {
    ...mapGetters('bots', ['currentBotId']),
    ...mapGetters('teasers', ['teaser']),
    ...mapGetters('intents', ['intents']),
    ...mapGetters('forms', ['forms']),
    targetOptions() {
      const startIntent = {
        displayName: this.$t('preview.teaser.standard'),
        intent: 'start',
      };
      return [startIntent, ...(this.forms || []), ...(this.intents || [])];
    },
    teaserTextRules() {
      return [
        (v) =>
          (v || '').length >= 2 || this.$t('teasers.validation.actionText'),
      ];
    },
  },
  created() {
    this.init();
  },
  watch: {
    'teaser.payload.quickActions'() {
      this.init();
    },
  },
  methods: {
    init() {
      this.updateTargetOptionsFiltered();

      if (!this.teaser?.payload?.quickActions) return;
      this.actions = _.cloneDeep(this.teaser.payload.quickActions);
      this.checkDirty();
    },
    addAction() {
      this.$store.commit('teasers/addAction');
      this.checkDirty();
    },
    deleteAction(actionId) {
      this.$store.commit('teasers/deleteAction', actionId);
      this.checkDirty();
    },
    updateTeaserActions() {
      this.$store.commit('teasers/setQuickActions', this.actions);
      this.checkDirty();
    },
    checkDirty() {
      this.$store.commit('teasers/checkIsDirty');
      this.$store.dispatch('teasers/checkIsBlocked');
    },
    async updateTargetOptionsFiltered() {
      this.isLoading = true;
      const resultList = [];

      for (const intent of this.targetOptions) {
        const isLive = await this.checkIntentStatus(intent);
        if (isLive) {
          resultList.push(intent);
        }
      }

      this.targetOptionsFiltered = resultList;
      this.isLoading = false;
    },
    async checkIntentStatus(intent) {
      if (intent.template === 'BotForm') return true;
      if (!intent.state) return true;

      if (intent.state[this.teaser?.payload?.channelId]?.state === 'success') {
        return true;
      }

      return false;
    },
  },
};
</script>

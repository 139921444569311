export function secondsToMinString(seconds) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds - minutes * 60;
  return `${minutes}m ${remainingSeconds.toFixed(0)}s`;
}

export function getRidOfQuotes(string) {
  return string.replace(/(^"|"$)/g, '');
}

export function getRidOfTabs(string) {
  return string.replace(/\t/g, '');
}

/**
 * Recursively flattens an object into one level, where keys are separated by dot.
 * **WARNING**: Be wary not to use it on circular objects.
 * @param {object} object
 * @return {Record<string, any>}
 */
export function flattenObject(object) {
  const flat = {};

  for (const [key,value] of Object.entries(object)) {
    // You can have keys with undefined (esp. since it's more efficient than `delete obj.key`) but we don't want the included
    if (value===undefined) continue;

    // If it's an object, flatten it recursively...
    if (_.isObject(value)) {
      const ref = flattenObject(value);
      for (const [k,v] of Object.entries(ref)) flat[`${key}.${k}`] = v;

      continue; // Guard clause
    }

    // ... else it's some value
    flat[key] = value;
  }

  return flat;
}

/**
 * @typedef {Object} TransformerOptions
 * @property {string} key The key that is associated with the current value
 * @property {string|null} path The path leading to the given key, if any
 */
/**
 * @typedef {Object} TransformerResult
 * @property {boolean} skip Whether to skip the value
 * @property {boolean} [useValue] Whether to use the value as is.
 * If not skipping, whether to replace value
 * @property {any} [value] The exact value to use.
 * It will not be processed further (e.g., if you return an object, it won't be flattened)
 */
/**
 * Recursively flattens an object into one level, where keys are separated by dot.
 * **WARNING**: Be wary not to use it on circular objects.
 * @param {object} object
 * @param {(value: any, opts: TransformerOptions) => TransformerResult} transformer
 * @param {string} [_path] Internal use: The path leading to the current value
 * @return {Record<string, any>}
 */
export function flattenObjectTransformer(object, transformer, _path = "") {
  const flat = {};

  for (const [key,value] of Object.entries(object)) {
    if (_path) _path += `.${key}`;

    const result = transformer(value, {
      key,
      path: _path,
    });

    // Handlers if you wanted to transform...
    if (result.skip) continue;
    if (result.useValue) {
      flat[key] = result.value;
      continue;
    }
    // ... and fallthrough if not

    // If it's an object, flatten it recursively...
    if (_.isObject(value)) {
      const ref = flattenObjectTransformer(value, transformer, _path);

      for (const [k,v] of Object.entries(ref)) {
        const result = transformer(v, {
          key: k,
          path: `${key}.${k}`,
        });

        // Handlers if you wanted to transform...
        if (result.skip) continue;
        if (result.useValue) {
          flat[`${key}.${k}`] = result.value;
          continue;
        }
        // ... and fallthrough if not

        flat[`${key}.${k}`] = v;
      }

      continue;
    }

    // ... else it's some value
    flat[key] = value;
  }

  return flat;
}
<template>
  <div class="d-flex pt-1">
    <v-tooltip bottom color="primary lighten-3">
      <template v-slot:activator="{ on: onGoals, attrs: attrsGoals }">
        <div class="d-flex" style="width: 130px; position: relative; top: -3px">
          <v-icon size="16" class="mr-1">mdi-thumbs-up-down</v-icon>
          <div class="primary--text" v-on="onGoals" v-bind="attrsGoals">
            {{ calcPro(countPos + countNeg, countAll) }}
          </div>
        </div>
      </template>
      <span>
        {{
          $t('stats.feedbackCount', {
            answers: countPos + countNeg,
            count: countAll,
            answersPro: calcPro(countPos + countNeg, countAll),
          })
        }}
      </span>
    </v-tooltip>
    <div class="bar relative d-flex">
      <v-skeleton-loader
        v-if="isLoading"
        type="image"
        height="16"
        width="100%"
      />
      <v-tooltip bottom v-if="!isLoading" color="primary lighten-3">
        <template v-slot:activator="{ on: onAll, attrs: attrsAll }">
          <div
            class="bar-all grey lighten-2"
            v-on="onAll"
            v-bind="attrsAll"
          ></div>
        </template>
        <span>
          {{
            $t('stats.feedbackNotAnswered', {
              count: countAll - (countPos + countNeg),
            })
          }}
        </span>
      </v-tooltip>
      <v-tooltip bottom v-if="!isLoading" color="primary lighten-3">
        <template v-slot:activator="{ on: onPos, attrs: attrsPos }">
          <div
            class="bar-inline relative bar-pos"
            :style="{ width: posPro }"
            v-on="onPos"
            v-bind="attrsPos"
          ></div>
        </template>
        <span>
          {{
            $t('stats.feedbackCountPos', {
              answers: countPos + countNeg,
              countPos,
              posPro: getCommaString(posProVotes),
            })
          }}
        </span>
      </v-tooltip>
      <v-tooltip bottom v-if="!isLoading" color="primary lighten-3">
        <template v-slot:activator="{ on: onNeg, attrs: attrsNeg }">
          <div
            class="bar-inline relative bar-neg"
            :style="{ width: negPro }"
            v-on="onNeg"
            v-bind="attrsNeg"
          ></div>
        </template>
        <span>
          {{
            $t('stats.feedbackCountNeg', {
              answers: countPos + countNeg,
              countNeg,
              negPro: getCommaString(negProVotes),
            })
          }}
        </span>
      </v-tooltip>
    </div>
  </div>
</template>
<script>
import { formatNumberFixedOne } from '@/utils/formatNumber';

export default {
  name: 'StatsBarCountFeedback',
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    countAll: {
      type: Number,
      default: 0,
    },
    countPos: {
      type: Number,
      default: 0,
    },
    countNeg: {
      type: Number,
      default: 0,
    },
    language: {
      type: String,
      default: 'de',
    },
  },
  computed: {
    posPro() {
      let posPro = (this.countPos / this.countAll) * 100;
      return this.getProString(posPro);
    },
    negPro() {
      let negPro = (this.countNeg / this.countAll) * 100;
      return this.getProString(negPro);
    },
    posProVotes() {
      let posPro = (this.countPos / (this.countPos + this.countNeg)) * 100;
      return this.getProString(posPro);
    },
    negProVotes() {
      let negPro = (this.countNeg / (this.countPos + this.countNeg)) * 100;
      return this.getProString(negPro);
    },
  },
  methods: {
    getProString(pro) {
      pro = formatNumberFixedOne(pro);
      if (pro > 100) pro = 100;
      return `${pro}%`;
    },
    getCommaString(value) {
      if (this.language === 'de') {
        return String(value).replace('.', ',');
      }
      return value;
    },
    calcPro(count, countAll) {
      let pro = (count / countAll) * 100;
      pro = formatNumberFixedOne(pro);
      return `${pro}%`;
    },
  },
};
</script>
<style lang="scss" scoped>
$bar-height: 16px;
.bar {
  height: $bar-height;
  width: 100%;
}
.bar-all {
  height: $bar-height;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.bar-pos {
  background: rgba(0, 194, 138, 1);
}
.bar-neg {
  background: rgba(228, 89, 102, 1);
}
.bar-inline {
  height: $bar-height;
}
</style>

const state = {
  selectedFilterChannel: 'all',
  selectedFilterType: [],
  selectedFilterStatus: [],
  tableOptions: {
    page: 1,
    itemsPerPage: 20,
    sortBy: ['displayName'],
    sortDesc: [false],
    groupBy: [],
    groupDesc: [],
    mustSort: false,
    multiSort: false,
  },
  currentPage: 1,
};

const getters = {
  selectedFilterChannel: (state) => state.selectedFilterChannel,
  selectedFilterType: (state) => state.selectedFilterType,
  selectedFilterStatus: (state) => state.selectedFilterStatus,
  tableOptions: (state) => state.tableOptions,
  currentPage: (state) => state.currentPage,
};

const actions = {};

const mutations = {
  setValue(state, { key, value }) {
    state[key] = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

import moment from 'moment';

export function formatDateTime(value, format = 'DD.MM.YY HH:mm') {
  if (value instanceof Date) {
    return moment(value.toISOString()).format(format);
  } else if (moment.isMoment(value)) {
    return value.format(format);
  }
  if (value) {
    return moment(value).format(format);
  }
  return '';
}

export function i18nDateFormat(value, lang = 'de', format = 'DD. MMMM YYYY HH:mm') {
  if (moment.isMoment(value)) {
    value.locale(lang);
    return value.format(format);
  } else if (value) {
    const newValue = moment(value);
    newValue.locale(lang);
    return newValue.format(format);
  }

  return value || '';
}

export function formatTime(value, format = 'HH:mm:ss') {
  if (moment.isMoment(value)) {
    return value.format(format);
  } else if (value) {
    return moment(value).format(format);
  }
  return value || '';
}

export function formatDuration(value, language = 'de') {
  if (typeof value !== 'string') {
    return moment
      .duration(value - moment())
      .locale(language)
      .humanize();
  } else if (value) {
    return moment
      .duration(moment(String(value)) - moment())
      .locale(language)
      .humanize();
  }
  return '';
}

<template>
  <v-app style="max-height: 100vh; height: 100vh">
    <HubMenu />
    <Appbar />
    <HubEditorFrame
      v-if="teaser && teaser.payload"
      ref="hubeditor"
      v-model="isSidebarOpen"
      :title="teaser.payload.displayName"
      :loading="isLoading"
      :draft="isDirty"
      saveIcon="cloud_upload"
      @onReset="resetTeaser"
      @onSave="saveTeaser"
      back="/wt/teaser"
      :sidebar-items="[{ title: '', icon: 'info', name: 'info' }]"
      :disabled="!isValid"
    >
      <template>
        <v-container fluid class="white pa-6 pb-8">
          <v-row>
            <v-col cols="12" md="6">
              <h3 class="text-h6">
                {{ $t('teasers.status') }}
              </h3>
              <v-switch
                inset
                v-model="teaser.active"
                :color="teaser.active ? 'green' : 'grey'"
                :label="$t('teasers.statusSwitchLabel')"
                :disabled="!isValid"
                @change="checkDirty"
              ></v-switch>
              <div style="height: 40px">
                <InfoBox v-if="!isValid" icon="mdi-publish-off" iconColor="error" :text="$t('teasers.statusBlockedInfo')" />
              </div>
            </v-col>
            <v-col cols="12" md="6">
              <template v-if="channels.length > 1">
                <h3 class="text-h6">
                  {{ $t('teasers.channel') }}
                </h3>
                <v-select
                  :label="$t('teasers.channelLabel')"
                  :items="channels"
                  item-text="displayName"
                  item-value="channelId"
                  v-model="selectedChannel"
                  @change="changeTeaserChannel"
                ></v-select>
              </template>
            </v-col>
          </v-row>
          <v-divider class="my-6" />
          <TeasersActions />
          <v-divider class="my-6" />
          <TeasersIntegrations :teaser="teaser" />
          <v-divider class="my-6" />
          <TeasersTriggers />
          <v-divider class="my-6" />
          <TeasersName />
        </v-container>
      </template>
    </HubEditorFrame>
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex';
import HubMenu from '@/components/hub/HubMenu.vue';
import Appbar from '@/components/Appbar.vue';
import HubEditorFrame from '@/components/hub/HubEditorFrame.vue';
import TeasersActions from '@/components/teasers/TeasersActions.vue';
import TeasersIntegrations from '@/components/teasers/TeasersIntegrations.vue';
import TeasersTriggers from '@/components/teasers/TeasersTriggers.vue';
import TeasersName from '@/components/teasers/TeasersName.vue';
import InfoBox from '@/components/common/InfoBox.vue';

export default {
  name: 'TeasersDetail',
  components: {
    Appbar,
    HubMenu,
    HubEditorFrame,
    TeasersActions,
    TeasersIntegrations,
    TeasersTriggers,
    TeasersName,
    InfoBox,
  },
  data() {
    return {
      teaserId: null,
      isLoading: false,
      isSidebarOpen: false,
      selectedChannel: null,
    };
  },
  created() {
    this.teaserId = this.$router.currentRoute?.params?.id;
    this.init();
  },
  computed: {
    ...mapGetters('teasers', ['teasers', 'teaser', 'isDirty', 'isValid']),
    ...mapGetters('intents', ['intents']),
    ...mapGetters('bots', ['currentBotId', 'currentChannels']),
    channels() {
      return this.currentChannels.filter((c) => !c.blocked);
    }
  },
  methods: {
    async init() {
      this.isLoading = true;
      
      const isTeaserLoaded = await this.$store.dispatch('teasers/loadTeaser', {
        botId: this.currentBotId,
        channelId: this.currentChannels[0].channelId,
        teaserId: this.teaserId,
      });

      if (!isTeaserLoaded) {
        this.$router.push('/wt/teaser');
        return;
      }

      this.selectedChannel = this.teaser?.payload?.channelId || null;
      this.isLoading = false;
    },
    resetTeaser() {
      this.$store.dispatch('teasers/resetToInitial');
    },
    async saveTeaser() {
      await this.$store.dispatch('teasers/saveTeaser', {
        botId: this.currentBotId,
        channelId: this.currentChannels[0].channelId,
      });
    },
    changeTeaserChannel(channelId) {
      this.teaser.payload.channelId = channelId;
      this.$store.commit('teasers/setTeaser', this.teaser);
      this.checkDirty();
    },
    checkDirty() {
      this.$store.commit('teasers/checkIsDirty');
      this.$store.dispatch('teasers/checkIsBlocked');
    },
  },
};
</script>
<template>
  <div class="moin-bg">
    <div class="moin-bg-key-a">
      <div class="moin-bg-key-a-box">
        <HubBraces :color="colors[0]" />
      </div>
    </div>
    <div class="moin-bg-key-b">
      <div class="moin-bg-key-b-box">
        <HubBraces :color="colors[1]" />
      </div>
    </div>
    <div class="moin-bg-key-c">
      <div class="moin-bg-key-c-box">
        <HubBraces :color="colors[2]" />
      </div>
    </div>
    <slot />
  </div>
</template>
<script>
import HubBraces from '@/components/hub/HubBraces.vue';
export default {
  name: 'HubBg',
  data() {
    return {
      colors: ['#6FAAA7', '#EF7457', '#EFF2FF'],
    };
  },
  created() {
    this.shuffleArray(this.colors);
  },
  components: {
    HubBraces,
  },
  methods: {
    shuffleArray(array) {
      for (var i = array.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.moin-bg {
  z-index: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $moin-color-brand-bg;
  overflow: hidden;
  overflow-y: auto;
}

@keyframes moinFade {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.moin-bg-key-a {
  position: fixed;
  top: -100px;
  left: 0;
  z-index: 1;
  opacity: 0;
  animation: moinFade 0.3s ease-out forwards;
  animation-delay: 0.2s;
}

.moin-bg-key-b {
  position: fixed;
  top: 0;
  right: 4vw;
  z-index: 1;
  opacity: 0;
  animation: moinFade 0.3s ease-out forwards;
  animation-delay: 0.2s;
}

.moin-bg-key-b-box {
  transform: rotate(90deg);
}

.moin-bg-key-c {
  position: fixed;
  bottom: -150px;
  right: 20vw;
  z-index: 1;
  opacity: 0;
  animation: moinFade 0.3s ease-out forwards;
  animation-delay: 0.2s;
}

.moin-bg-key-c-box {
  transform: rotate(180deg);
}
</style>

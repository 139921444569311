<template>
  <div>
    <v-app-bar
      :extended="$vuetify.breakpoint.smAndDown"
      app
      class="white"
      elevation="0"
      :extension-height="$vuetify.breakpoint.smAndDown ? 50 : 64"
    >
      <template v-if="$vuetify.breakpoint.mdAndDown">
        <BtnSquare class="mt-2 mr-2" @click="changeDrawer()" icon="menu" />
        <div class="mt-4 mr-4">
          <img src="@/assets/moin-logo.svg" height="38px" />
        </div>
      </template>

      <v-toolbar-title class="hidden-sm-and-down text-h5 mr-2 mt-2 pl-0">
        <v-btn
          v-if="loggedIn && false"
          to="/"
          class="mr-1 mb-1 hidden-sm-and-down"
          small
          tile
          icon
        >
          <span class="pl-2 hidden-xs-only">Teaser</span>
          <v-icon right class="pl-0 mx-2">play_circle</v-icon>
        </v-btn>
        Preview
      </v-toolbar-title>

      <v-spacer />

      <v-btn
        v-if="false"
        class="mx-2 px-2 primary--text font-weight-bold"
        color="primary"
        outlined
      >
        <span class="hidden-sm-and-down pl-2">Teilen</span>
        <v-icon class="pl-0 mx-2">mdi-share</v-icon>
      </v-btn>
      <v-btn
        class="mx-2 px-2 white--text font-weight-bold hidden-sm-and-down"
        color="secondary darken-2"
        @click="showTeaser"
        depressed
      >
        <span class="pl-2">Teaser</span>
        <v-icon right class="pl-0 mx-2">play_circle</v-icon>
      </v-btn>
      <v-btn
        class="mx-2 px-2 white--text font-weight-bold hidden-sm-and-down"
        color="primary"
        @click="restart"
        depressed
      >
        <span class="pl-2">{{ $t('preview.restart') }}</span>
        <v-icon class="pl-0 mx-2">mdi-replay</v-icon>
      </v-btn>

      <v-btn
        v-if="!isNoBeamer"
        class="mr-0 ml-2 mr-md-2 notification-trigger text-grey text-lighten-3"
        elevation="0"
      >
        <span class="pl-2 hidden-xs-only">{{ $t('beamer.label') }}</span>
        <span class="hidden-md-and-up pl-2" />
        <v-icon right color="primary lighten-2" class="pl-0 mx-1">
          notifications
        </v-icon>
      </v-btn>

      <template
        v-slot:extension
        v-if="
          $vuetify.breakpoint.smAndDown ||
          ($vuetify.breakpoint.mdAndUp && notification)
        "
      >
        <template v-if="$vuetify.breakpoint.smAndDown">
          <v-toolbar-title class="hidden-md-and-up subline mr-2 mt-0 pl-0">
            <v-btn
              v-if="loggedIn && false"
              to="/"
              class="mr-1 mb-1 hidden-sm-and-down"
              small
              fab
              rounded-4
              icon
            >
              <v-icon color="primary lighten-2">arrow_back</v-icon>
            </v-btn>
            Preview
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            class="mx-2 px-2 mb-2 white--text font-weight-bold hidden-md-and-up"
            color="secondary darken-2"
            @click="showTeaser"
          >
            <span class="pl-2 hidden-xs-only">Teaser</span>
            <v-icon right class="pl-0 mx-2">play_circle</v-icon>
          </v-btn>
          <v-btn
            class="mr-0 ml-2 mx-2 px-2 mb-2 white--text font-weight-bold"
            depressed
            color="primary"
            @click="restart"
          >
            <span class="pl-2 hidden-xs-only">{{ $t('preview.restart') }}</span>
            <v-icon class="pl-0 mx-2">mdi-replay</v-icon>
          </v-btn>
        </template>
        <div v-else class="white flex-grow-1 mt-n4 mx-n2 pt-2 px-2">
          <HubNotification />
        </div>
      </template>
    </v-app-bar>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import BotService from '../services/bot';
import OsUtil from '@/utils/os';
import HubNotification from '@/components/hub/HubNotification.vue';
import BtnSquare from '@/components/common/BtnSquare.vue';

export default {
  name: 'AppbarPreview',
  components: { BtnSquare, HubNotification },
  props: {
    loggedIn: {
      type: Boolean,
      default: false,
    },
    botId: {
      type: String,
      default: '',
    },
    channelId: String,
  },
  methods: {
    restart() {
      this.resetWidget({ botId: this.botId, channelId: this.channelId });
      // reload page
      this.$router.go();
    },
    changeDrawer() {
      // change navigation drawer state
      this.$store.commit('hubUi/setValue', { key: 'isMenuOpen', value: true });
    },
    ...mapActions('preview', ['resetWidget', 'showTeaser']),
    initializeBeamer() {
      if (!this.isNoBeamer) {
        let beamerScript = document.createElement('script');
        beamerScript.setAttribute('id', 'beamer_script');
        beamerScript.setAttribute(
          'src',
          'https://app.getbeamer.com/js/beamer-embed.js'
        );
        beamerScript.setAttribute('defer', 'defer');

        let user_id = undefined;

        if (this.user) {
          user_id = this.isAdmin
            ? this.user.username
            : this.user.username.split('@')[0] + this.currentBotId;
        }

        const elem = document.getElementById('beamer_script');
        if (elem) elem.parentNode.removeChild(elem);

        window.beamer_config = {
          product_id: 'BcbAFjeZ25353', //DO NOT CHANGE: This is your product code on Beamer
          selector:
            '.notification-trigger' /*Optional: Id, class (or list of both) of the HTML element to use as a button*/,
          //display : 'right', /*Optional: Choose how to display the Beamer panel in your site*/
          //top: 0, /*Optional: Top position offset for the notification bubble*/
          //right: 0, /*Optional: Right position offset for the notification bubble*/
          //bottom: 0, /*Optional: Bottom position offset for the notification bubble*/
          //left: 0, /*Optional: Left position offset for the notification bubble*/
          //button_position: 'bottom-right', /*Optional: Position for the notification button that shows up when the selector parameter is not set*/
          //icon: 'bell_lines', /*Optional: Alternative icon to display in the notification button*/
          language:
            this.$vuetify.lang
              .current /*Optional: Bring news in the language of choice*/,
          //filter: 'admin', /*Optional : Bring the news for a certain role as well as all the public news*/
          //lazy: false, /*Optional : true if you want to manually start the script by calling Beamer.init()*/
          //alert : true, /*Optional : false if you don't want to initialize the selector*/
          //delay : 0, /*Optional : Delay (in milliseconds) before initializing Beamer*/
          //embed : false, /*Optional : true if you want to embed and display the feed inside the element selected by the 'selector' parameter*/
          //mobile : true, /*Optional : false if you don't want to initialize Beamer on mobile devices*/
          //notification_prompt : 'sidebar', /*Optional : override the method selected to prompt users for permission to receive web push notifications*/
          //callback : test, /*Optional : Beamer will call this function, with the number of new features as a parameter, after the initialization*/
          //onclick : your_onclick_function(url, openInNewWindow), /*Optional : Beamer will call this function when a user clicks on a link in one of your posts*/
          //onopen : your_onopen_function, /*Optional : Beamer will call this function when opening the panel*/
          //onclose : your_onclose_function, /*Optional : Beamer will call this function when closing the panel*/
          //---------------Visitor Information---------------
          //user_firstname : "firstname", /*Optional : Input your user firstname for better statistics*/
          //user_lastname : "lastname", /*Optional : Input your user lastname for better statistics*/
          //user_email : "email", /*Optional : Input your user email for better statistics*/
          user_id /*Optional : Input your user ID for better statistics*/,
        };

        document.head.appendChild(beamerScript);
      }
    },
  },
  data: () => ({
  }),
  mounted() {
    this.initializeBeamer();
  },
  created() {
    if (this.isOnlyStaging) {
      this.$store.dispatch('bots/setViewStaging', true);
    } else {
      this.$store.dispatch('bots/setViewStaging', false);
    }
  },
  watch: {
    userLang() {
      this.initializeBeamer();
    },
  },
  computed: {
    ...mapGetters('auth', ['isAdmin', 'isOnlyStaging', 'isNoBeamer', 'user']),
    ...mapGetters('notifications', ['notification']),
    userLang() {
      return this.$vuetify.lang.current;
    },
    getOS() {
      return OsUtil.getOs();
    },
    ...mapGetters('bots', [
      // create properties from auth store
      'currentBot',
      'viewStaging',
      'bots',
    ]),
    facebookChannel() {
      if (!this.currentBot) {
        return false;
      }

      return BotService.linkToChannel(this.currentBot, 'facebook');
    },
    webchatChannel() {
      if (!this.currentBot) {
        return false;
      }

      return BotService.linkToChannel(this.currentBot, 'widget');
    },
    hasFacebookChannel() {
      if (!this.currentBot) {
        return false;
      }

      return BotService.hasActiveChannel(this.currentBot, 'facebook');
    },
    hasWebchatChannel() {
      if (!this.currentBot) {
        return false;
      }

      return BotService.hasActiveChannel(this.currentBot, 'widget');
    },
  },
};
</script>
<style scoped>
.v-app-bar::after {
  content: ' ';
  display: block;
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
  height: 1px;
  background: rgba(0, 0, 0, 0.12);
}
</style>

<template>
  <div class="pt-6 pb-2">
    <div class="text-body-2 primary--text text--lighten-3">
      {{ $t('demo.stepCompany.labelCompanyUrl') }}
    </div>
    <div class="text-body-1" style="word-break: break-all">
      {{ companyUrl }}
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'DemoCompanyUrl',
  components: {},
  computed: {
    ...mapGetters('demo', ['companyUrl']),
  },
};
</script>

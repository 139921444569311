<template>
    <v-app style="max-height: 100vh; height: 100vh">
      <HubMenu />
      <Appbar />
      <HubContent :isSidebarOpen="isSidebarOpen">
        <template v-slot:content>
            <HubFrame>
                <template v-slot:content>
                    <div>
                        <!-- Search field -->
                        <div class="d-flex">
                            <v-text-field 
                                class="mr-2" 
                                v-model="searchQuery" 
                                label="Search" 
                                outlined 
                                dense
                                @keyup.enter="search"
                            ></v-text-field>
                            <v-btn @click="search" depressed color="success" :disabled="isLoading">
                                <v-icon left>
                                    mdi-magnify
                                </v-icon>
                                {{ $t('searchAndReplace.searchButtonLabel') }}
                            </v-btn>
                        </div>
                        <!-- Replace expansion panel -->
                        <div class="mx-n3">
                          <v-divider class="my-1"></v-divider>
                          <v-expansion-panels accordion flat>
                            <v-expansion-panel>
                              <v-expansion-panel-header>
                                <span class="text-body-2 primary--text text--lighten-2" color="primary">
                                  {{ $t('searchAndReplace.replacePanelTitle') }}
                                </span>
                              </v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <v-text-field 
                                  class="mr-2" 
                                  v-model="replaceText" 
                                  :label="$t('searchAndReplace.replaceTextPlaceholder')" 
                                  outlined 
                                  dense
                              ></v-text-field>
                              <v-alert
                                v-model="replaceAlert"
                                close-text="Close Alert"
                                dismissible
                                dense
                                close-icon="mdi-close"
                                icon="mdi-lightbulb"
                                color="primary lighten-3"
                                class="text-body-2"
                                text
                              >
                                {{ $t('searchAndReplace.replaceAlertText') }}
                              </v-alert>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                          <v-divider class="mt-1 mb-5"></v-divider>
                        </div>
                        <!-- Chip filters -->
                        <div>
                            <div class="my-2">
                                <div class="d-flex">
                                <v-icon color="primary lighten-3" size="16">mdi-filter</v-icon>
                                <div class="text-body-2 primary--text text--lighten-2 pl-1">
                                    {{ $t('searchAndReplace.filterActionTypeLabel') }}
                                </div>
                                </div>
                                <div style="height: 48px">
                                <ChipFilter
                                    v-if="!isLoading"
                                    :options="filterActionTypeItems"
                                    @onChange="changeFilterActionType"
                                />
                                </div>
                            </div>
                        </div>
                        <!-- Loading indicator -->
                        <v-progress-linear
                          v-if="isLoading"
                          indeterminate
                          absolute
                          color="secondary"
                        />
                        <!-- Search results -->
                        <div v-if="searchResultFor" :class="{'loadingTransparent': isLoading}">
                            <div>
                                <v-treeview 
                                    :items="treeData"
                                    open-all
                                    open-on-click
                                    ref="searchResultsTree"
                                    activatable
                                    :active.sync="activeSearchResults"
                                >
                                    <template v-slot:prepend="{ item }">
                                        <v-icon v-if="item.children">
                                            mdi-folder-text
                                        </v-icon>
                                        <v-icon v-else>
                                            {{ item.icon }}
                                        </v-icon>
                                    </template>
                                </v-treeview>
                            </div>
                        </div>
                        <div v-if="searchResultFor && treeData.length === 0">
                            <div class="text-h5 primary--text text--lighten-2 py-6">
                                {{ $t('searchAndReplace.noSearchResults') }}
                            </div>
                        </div>
                    </div>
                </template>
            </HubFrame>
        </template>
        <template v-slot:sidebar>
          <HubContentSidebarSimple
            :isOpen="isSidebarOpen"
            icon="mdi-pencil"
            @onClose="onQuickEditClose"
          >
            <h2 class="ma-4">{{ selectedIntentName }}</h2>
            <v-card outlined class="ma-4">
              <v-card-title>
                <v-icon color="primary lighten-3">mdi-pencil</v-icon>
                <span>{{ $t('searchAndReplace.searchResultQuickEdit') }}</span>
              </v-card-title>
              <v-card-text v-if="!reload">
                <ContentTemplateLoader
                  :content="selectedContent"
                  :box="selectedBoxName"
                  :template="selectedContent[selectedBoxName]?.template"
                  :options="actionEditorOptions"
                  :contentTree="selectedTree"
                />
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn 
                  v-if="!hasReplaceText || actionEditorUpdated"
                  depressed 
                  color="success" 
                  :disabled="!actionEditorUpdated"
                  @click="onQuickEditSave"
                >
                  {{ $t('searchAndReplace.saveButtonLabel') }}
                  <v-icon right>mdi-content-save</v-icon>
                </v-btn>
                <v-btn 
                v-if="hasReplaceText && !actionEditorUpdated"
                depressed 
                color="success" 
                @click="onQuickEditReplace"
                >
                  <v-icon right>mdi-check</v-icon>
                  {{ $t('searchAndReplace.replaceButtonLabel') }}
                </v-btn>
              </v-card-actions>
            </v-card>
            <v-btn 
              class="ma-4"
              outlined
              @click="navigateToIntent(selectedSearchResult.intent)"
            >
              <v-icon left>mdi-pencil</v-icon>
              {{ $t('searchAndReplace.editIntentButtonLabel') }}
            </v-btn>
          </HubContentSidebarSimple>
        </template>
      </HubContent>
    </v-app>
</template>

<style>
  .loadingTransparent {
    opacity: 0.5;
  }
</style>

<script>
    import { mapGetters } from 'vuex';
    import { useEventBus } from '@vueuse/core';
    import HubMenu from '@/components/hub/HubMenu.vue';
    import Appbar from '@/components/Appbar.vue';
    import HubContent from '@/components/hub/HubContent.vue';
    import HubContentNavbar from '@/components/hub/HubContentNavbar.vue';
    import HubContentSidebarSimple from '@/components/hub/HubContentSidebarSimple.vue';
    import HubFrame from '@/components/hub/HubFrame.vue';
    import ChipFilter from '@/components/common/ChipFilter.vue';
    import ContentTemplateLoader from '@/components/editor/components/ContentTemplateLoader.vue';
    import { actionEditorEvents } from '@/utils/actionEditor';
    import { Tree } from '@/utils/tree';
    import deepEqual from 'fast-deep-equal/es6';
    import _ from 'lodash';

    import BotService from '@/services/bot';

    export default {
        name: 'SearchAndReplace',
        components: {
            HubMenu,
            Appbar,
            HubContent,
            HubContentNavbar,
            HubContentSidebarSimple,
            HubFrame,
            ChipFilter,
            ContentTemplateLoader,
        },
        data() {
            return {
                searchQuery: '',
                replaceText: '',
                searchResult: [],
                searchResultFor: '',
                replaceAlert: true,
                isLoading: false,
                selectedActionTypeFilters: [],
                isSidebarOpen: false,
                activeSearchResults: [],
                selectedIntentName: '',
                selectedBoxName: '',
                selectedEditorAction: {},
                originalActionData: {},
                selectedContent: {},
                actionEditorOptions: {
                  showDelays: false,
                },
                actionEditorUpdated: null,
                actionUpdatedListener: useEventBus(actionEditorEvents.actionUpdated),
                reload: false,
            };
        },
        created () {
          this.actionUpdatedListener.on(this.handleActionUpdated);
        },
        destroyed() {
          this.actionUpdatedListener.off();
        },
        computed: {
            stagingBotId() {
              return this.currentBot.stagingBot
            },
            ...mapGetters('bots', ['currentBot', 'currentBotId']),
            ...mapGetters('intents', ['intents']),
            actionStringTypes() {
              return {
                  'text': {
                      name: 'text',
                      icon: 'mdi-text',
                      color: 'primary lighten-3'
                  },
                  'button': {
                      name: 'urls',
                      icon: 'mdi-link',
                      color: 'primary lighten-3'
                  },
                  'quickReply': {
                      name: 'quickReply',
                      icon: 'mdi-message-outline',
                      color: 'primary lighten-3'
                  },
                  'cards': {
                      name: 'cards',
                      icon: 'mdi-view-carousel',
                      color: 'primary lighten-3'
                  },
              };
            },
            treeData() {
              const treeDataMap = {};
              const searchRegex = new RegExp(this.searchResultFor, 'i');

              this.searchResult
                .sort((a, b) => a.intent >= b.intent ? 1 : -1)
                .reduce((map, result) => {
                  const foundMatches = result.action.strings
                    .filter(s => searchRegex.test(s.data)) //find matching strings
                    .map(s => ({ //determine type
                      actionString: s,
                      determinedType: this.determineActionStringType(result.action, s),
                    }))
                    .filter(({ determinedType }) => this.filteredActionTypes.includes(determinedType)); //filter by selected chip filters

                  if (foundMatches.length) {
                    //build the action string to be shown in the results
                    const resultActionString = this.buildResulstActionString(foundMatches);

                    if (!map[result.intent]) map[result.intent] = [];

                    map[result.intent].push({
                      id: result.action._id,
                      actionName: result.action.name,
                      intentName: result.intent,
                      name: resultActionString.data,
                      determinedType: resultActionString.type,
                      icon: resultActionString.icon,
                    });
                  }

                  return map;
                }, treeDataMap);
                
              return Object.keys(treeDataMap).map(key => ({
                id: key,
                name: `${this.getIntentDisplayName(key)} (${treeDataMap[key].length})`,
                children: treeDataMap[key],
              }));
            },
            filterActionTypeItems() {
              let items = Object.values(this.actionStringTypes).map((item, index) => ({
                ...item,
                name: this.$t(`searchAndReplace.actionTypeFilterLabel.${item.name}`),
                isActive: this.selectedActionTypeFilters.includes(index),
              }));

              return items;
            },
            filteredActionTypes() {
                const actionStringTypesArray = Object.keys(this.actionStringTypes);
                const filteredActionTypes = this.selectedActionTypeFilters.map((index) => actionStringTypesArray[index]);
                return filteredActionTypes.length ? filteredActionTypes : actionStringTypesArray;
            },
            selectedSearchResult() {
                if (!this.activeSearchResults.length) return undefined
                const id = this.activeSearchResults[0];
                return this.searchResult.find(r => r.action._id === id);
            },
            hasReplaceText() {
                return this.replaceText?.length > 0;
            },
            selectedTree() {
              const contentTree = new Tree();
              contentTree.setRoot(this.selectedContent[this.selectedBoxName]);
              contentTree.buildTree((box) => {
                return this.selectedContent[box];
              });

              return contentTree;
            }
        },
        watch: {
          selectedSearchResult: 'openQuickEdit',
          replaceText: 'replaceTextInActionString',
        },
        methods: {
            async search() {
                if (this.searchQuery?.length > 1) {
                  this.isLoading = true;
                    const result = await BotService.searchBot(this.stagingBotId, this.searchQuery);
                    if (result) {
                      this.searchResult = result;
                    }
                    this.searchResultFor = this.searchQuery;
                    this.isLoading = false;
                }
            },
            buildResulstActionString(foundMatches) {
              return foundMatches.length > 1 
                       ? {
                          data: `(${foundMatches.length}) ${foundMatches.map(m => m.actionString.data).join(' // ')}`,
                          type: 'multiple',
                          icon: 'mdi-note-multiple-outline',
                        }
                      : {
                          data: foundMatches[0].actionString.data,
                          type: foundMatches[0].determinedType,
                          icon: this.actionStringTypes[foundMatches[0].determinedType].icon,
                        };
            },
            determineActionStringType(action, stringObj) {
              
              switch (action.type) {
                case 'text':
                  return action.quick.find((q) => q.label === `{${stringObj.name}}`) ? 'quickReply' : 'text';
                case 'button':
                  return 'button';
                case 'cards':
                  return 'cards';
                default:
                  return stringObj.name;
              }
            },
            getIntentDisplayName(intent) {
                return this.intents.find((i) => i.intent === intent)?.displayName || intent;
            },
            changeFilterActionType(item) {
                this.selectedActionTypeFilters = item;
            },
            onQuickEditClose() {
                this.isSidebarOpen = false;
                this.resetQuickEdit();
            },
            async openQuickEdit() {
                if (!this.selectedSearchResult) {
                  this.onQuickEditClose();
                } else {
                  this.isSidebarOpen = true;
                  const actionsBox = await BotService.getContentByAction(this.currentBotId, this.selectedSearchResult?.action.name);

                  if (!actionsBox) return;

                  this.selectedIntentName = this.getIntentDisplayName(this.selectedSearchResult?.intent);
                  this.selectedBoxName = actionsBox.box;
                  const actionName = this.selectedSearchResult?.action.name;
                  for (const property in actionsBox) {
                      if (actionsBox[property]?.actions && actionsBox[property]?.actions.length > 0) {
                        // check if the action is the one we are looking for and select it
                        const action = actionsBox[property].actions.find((a) => a.name === actionName);
                        if (action) {
                          this.selectedEditorAction = action;
                          this.originalActionData = _.cloneDeep(this.selectedEditorAction);
                          this.actionEditorOptions.showOnlyAction = this.selectedEditorAction.name;
                          break;
                        }
                      }
                    }

                  this.replaceTextInActionString();

                  this.selectedContent[this.selectedBoxName] = actionsBox; 
                  this.rerender();
              }
            },
            replaceTextInActionString() {
              if (this.hasReplaceText && this.selectedEditorAction?.content?.strings) {
                this.selectedEditorAction.content.strings = this.originalActionData.content.strings.map((s) => {
                  return {
                    ...s,
                    data: s.data.replace(new RegExp(this.searchResultFor, 'gi'), this.replaceText),
                  };
                })

                this.rerender();
              }
            },
            navigateToIntent(intent) {
                this.$router.push({
                    name: 'intentsedit',
                    params: {
                        name: intent,
                    },
                });
            },
            checkDeepEqual (a, b) {
              return deepEqual(a, b);
            },
            handleActionUpdated(eventPayload) {
              if (!this.checkDeepEqual(eventPayload.actionOrigin.content, eventPayload.contentUpdated)) {
                this.actionEditorUpdated = {
                  ...eventPayload.actionOrigin,
                  content: eventPayload.contentUpdated,
                };
              } else {
                this.actionEditorUpdated = null;
              }
            },
            resetQuickEdit() {
              this.selectedIntentName = '';
              this.selectedEditorAction = {};
              this.originalActionData = {};
              this.actionEditorUpdated = null;
              this.activeSearchResults = [];
              this.selectedBoxName = '';
              this.selectedContent = {};
              this.loadingSidebar = false;
            },
            updateEditedSearchResultItem(id, updatedAction) {
              this.searchResult = this.searchResult.map((r) => {
                if (r.action._id === id) {
                  return {
                    ...r,
                    action: updatedAction,
                  };
                }
                return r;
              });
            },
            async onQuickEditSave() {
              const result = await BotService.updateEditedAction(this.currentBotId, this.actionEditorUpdated);

              if (result) {
                // this.searchResult = this.searchResult.filter((r) => r.action._id !== this.actionEditorUpdated.content._id);
                this.updateEditedSearchResultItem(this.actionEditorUpdated.content._id, this.actionEditorUpdated.content);
                this.onQuickEditClose();
              }
            },
            async onQuickEditReplace() {
              
              const result = await BotService.updateEditedAction(this.currentBotId, this.selectedEditorAction);
              const { name: actionName, content } = this.selectedEditorAction;
              if (result) {
                this.onQuickEditClose();

                //find next tree leaf to select
                let nextSelectedId = '';
                if (this.treeData.length > 0) {
                  outerLoop:
                  for (const [intentIndex, intent] of this.treeData.entries()) {
                    for (const [actionIndex, child] of intent.children.entries()) {
                      if (child.actionName === actionName) {
                        if (intent.children[actionIndex + 1]) { //if not last child after delete
                          nextSelectedId = intent.children[actionIndex + 1].id
                        } else {
                          nextSelectedId = this.treeData[intentIndex + 1]?.children[0].id;
                        }
                        break outerLoop;
                      }
                    }
                  }
                }

                this.updateEditedSearchResultItem(content._id, content);
                
                if (nextSelectedId) {
                  this.activeSearchResults.push(nextSelectedId);
                }
              }
            },
            rerender() {
            this.reload = true;
            this.$nextTick(() => {
              this.reload = false;
            });
          },
        }
    };
</script>
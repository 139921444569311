<template>
  <div class="question-item d-flex">
    <div style="width: 36px;">
      <v-icon color="primary lighten-3">mdi-folder-text</v-icon>
    </div>

    <div class="font-weight-bold text-body-1 flex-grow-1 primary--text" style="padding-top: 2px;">
      {{ intent.displayName }}
    </div>

  </div>
</template>
<script>
export default {
  name: 'DemoIntent',
  props: {
    // { id: String, text: String }
    intent: Object,
  },
  components: {},
};
</script>
<style lang="scss" scoped>
.question-item {
  border-radius: $spacer;
  padding: $spacer * 3 $spacer * 4;
  border: 1px solid #E0E0E0;
}
</style>

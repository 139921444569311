<template>
  <div v-if="content">
    <v-timeline-item hide-dot color="grey lighten-3" fill-dot v-if="content">
      <!-- <template v-slot:icon>
                                <v-icon small>space_bar</v-icon>
      </template>-->
      <v-row>
        <v-col>
          <v-text-field
            outlined
            class="font-weight-medium ma-0"
            dense
            v-model="content.displayName"
            :label="$t('botFormEdit.whatIsAsked')"
            :disabled="isLivechatRating"
            @input="update"
          ></v-text-field>
        </v-col>
        <v-col>
          <div v-if="isAdmin" class="moin-admin-box pa-1">
            <v-text-field
              outlined
              v-model="content.context"
              dense
              :label="$t('botFormEdit.technicalName')"
              :disabled="isLivechatRating"
              hide-details
              @input="update"
            />
          </div>
        </v-col>
      </v-row>
    </v-timeline-item>
    <ActionList
      v-if="content"
      :actions="content.question.actions"
      @onUpdate="update"
      @hook:mounted="updateName"
      :channelType="channelType"
    ></ActionList>
    <!-- <v-timeline-item color="white" v-if="content && isAdmin">
                <template v-slot:icon>
                    <v-icon small>space_bar</v-icon>
                </template>
                <v-text-field v-model="content.context" :label="$t('botFormEdit.technicalName')"></v-text-field>
    </v-timeline-item>-->
    <v-timeline-item color="white">
      <template v-slot:icon>
        <v-icon small>account_tree</v-icon>
      </template>
      <v-chip-group>
        <v-chip
          close-icon="mdi-delete"
          :close="answers.length > 1 && !isLivechatRating"
          @click:close="removeAnswer(answer)"
          :color="active(answer) ? 'primary': ''"
          @click="select(answer)"
          v-for="(answer, index) of answers"
          :key="index"
        >{{getLabel(answer.label).data}}</v-chip>
        <v-chip v-if="!isLivechatRating && isAdmin" @click="addAnswer" outlined>
          <v-avatar left>
            <v-icon>add</v-icon>
          </v-avatar>{{ $t('botFormEdit.newAnswer') }}
        </v-chip>
      </v-chip-group>
      <v-text-field label="Label" v-model="getLabel(selectedAnswer.label).data" @input="update" :disabled="isLivechatRating"></v-text-field>
      <!-- <v-tabs hide-slider v-model="tab">
                                <v-tab class="v-chip v-size--default mr-4 text-none" active-class="white--text primary lighten-2" v-for="answer of answers" :key="answer.value">{{getLabel(answer.label).data}}</v-tab>
                                <v-btn v-if="isAdmin" @click="addAnswer" icon class="align-self-center ml-4 mb-4 mr-4"><v-icon color="primary">add</v-icon></v-btn>
                                <v-btn v-if="isAdmin" :disabled="answers.length === 1" @click="removeAnswer" icon class="align-self-center ml-4 mb-4 mr-4"><v-icon color="primary">remove</v-icon></v-btn>
                            
                            </v-tabs>
                            <v-tabs-items v-model="tab">
                               <v-tab-item v-for="(answer, index) of answers" :key="index">
                                   <v-col md="4">
                                    <v-text-field label="Label" v-model="getLabel(answer.label).data"></v-text-field>
                                   </v-col>
                               </v-tab-item>
      </v-tabs-items>-->
    </v-timeline-item>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ActionList from '@/components/ActionList.vue';
import ChannelsUtil from '@/utils/channels';
/* import ActionEdit from '@/components/ActionEdit.vue';
// import FollowupEdit from '@/components/editor/FollowupEdit.vue';
import AnswerTemplate from '@/components/editor/AnswerTemplate.js';
import AnswerInfoEdit from '@/components/editor/AnswerInfoEdit.vue';
import YesNoQuestionTransEdit from '@/components/editor/YesNoQuestionTransEdit.vue'; */

// import MultipleChoiceInfoEdit from '@/components/editor/MultipleChoiceInfoEdit.vue';

export default {
  name: 'BotFormMultipleChoiceEdit',
  components: {
    ActionList,
  },
  props: ['box', 'intent', 'channel', 'name'],
  data: () => ({
    tab: null,
  }),
  async created() {},
  computed: {
    ...mapGetters('intents', ['intents']),
    ...mapGetters('bots', ['currentBotId', 'currentChannels']),
    ...mapGetters('auth', ['isAdmin']),
    ...mapGetters('forms', ['forms']),
    content() {
      return this.$store.getters['content/getContent'](
        this.currentBotId,
        this.intent,
        this.box,
        true,
        this.channel
      );
    },
    answers() {
      let actions = this.content.question.actions.filter((a) => {
        return a.content;
      });

      return actions[actions.length - 1].content.quick;
    },
    selectedAnswer() {
      if (!this.selectedPath) {
        return this.answers[0];
      } else {
        return this.answers.find((a) => {
          return a.intent === this.selectedPath;
        });
      }
    },
    selectedPath() {
      return this.$store.getters['formsEditor/getSelectedPath'](this.box);
    },
    isLivechatRating() {
      const currentForm = this.forms.find((f) => 
        f.intent === this.intent
      );
      
      return currentForm?.template === 'BotFormRating' && this.content?.context === 'rating';
    },
    channelType() {
      return ChannelsUtil.getChannelType(
        this.content?.channel,
        this.currentChannels
      );
    },
  },
  methods: {
    active(answer) {
      return this.selectedPath === answer.intent;
    },
    select(answer) {
      this.$store.commit('formsEditor/selectPath', {
        box: this.box,
        intent: answer.intent,
      });
      this.$store.dispatch(
        'formsEditor/updateRenderedSteps',
        this.currentBotId
      );
    },
    getLabel(name) {
      let actions = this.content.question.actions.filter((a) => {
        return a.content;
      });

      return actions[actions.length - 1].content.strings.find((str) => {
        return `{${str.name}}` === name;
      });
    },
    addAnswer() {
      let label = `qr${Date.now()}`;
      let actions = this.content.question.actions.filter((a) => {
        return a.content;
      });
      actions[actions.length - 1].content.strings.push({
        data: 'Value X',
        name: label,
      });
      let intent = 'value' + Date.now();
      this.answers.push({ label: `{${label}}`, intent });

      this.$emit('addMultiStep', { intent, box: this.box });
    },
    removeAnswer(answer) {
      let intent = answer.intent;
      let actions = this.content.question.actions.filter((a) => {
        return a.content;
      });

      let qrIndex = actions[actions.length - 1].content.quick.findIndex((q) => {
        return q.intent === intent;
      });

      let qr = actions[actions.length - 1].content.quick[qrIndex];

      let strIndex = actions[actions.length - 1].content.strings.findIndex(
        (str) => {
          return `{${str.name}}` === qr.label;
        }
      );

      if (
        this.$store.getters['formsEditor/getSelectedPath'](this.box) === intent
      ) {
        let otherIndex = actions[actions.length - 1].content.quick.findIndex(
          (q) => {
            return q.intent !== intent;
          }
        );

        this.$store.commit('formsEditor/selectPath', {
          box: this.box,
          intent: this.answers[otherIndex].intent,
        });
      }

      actions[actions.length - 1].content.quick.splice(qrIndex, 1);
      actions[actions.length - 1].content.strings.splice(strIndex, 1);

      this.$emit('removeMultiStep', { intent, box: this.box });
    },
    updateName() {
      if (this.content && !this.content.displayName) {
        this.content.displayName = this.name;
      }
    },
    update() {
      this.$emit('onUpdated');
    },
    // addAnswer() {
    //     this.content.answers.push({template: 'feedback', qr: 'Label'});
    //     this.update();
    // },
    // removeAnswer() {
    //     this.content.answers.splice(this.answerTab, 1);
    //     this.answerTab = this.content.answers.length - 1;
    //     this.update();
    // }
  },
  watch: {
    name() {
      this.content.displayName = this.name;
    },
    tab() {
      // this.$emit('tabChanged', {intent: this.answers[this.tab].intent, box: this.box});
    },
  },
};
</script>
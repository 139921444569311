<template>
  <v-app style="max-height: 100vh; height: 100vh">
    <HubMenu />
    <Appbar></Appbar>
    <HubEditorFrame
      :value="false"
      :title="$t('menu.myProfile')"
      :draft="isChanged"
      :loading="isLoading"
      @onReset="resetInputs"
      @onSave="saveConfig"
      noChannelHandling
    >
      <template v-slot:toolbar>
        <small class="grey--text">Version {{ appVersion }}</small>
      </template>
      <UserProfileComp ref="userProfileComp" @onChange="isChanged = $event" />
    </HubEditorFrame>
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex';
import Appbar from '@/components/Appbar.vue';
import HubMenu from '@/components/hub/HubMenu.vue';
import UserProfileComp from '@/components/userProfile/UserProfileComp.vue';
import HubEditorFrame from '@/components/hub/HubEditorFrame.vue';

export default {
  name: 'UserProfile',
  components: {
    Appbar,
    HubMenu,
    UserProfileComp,
    HubEditorFrame,
  },
  data() {
    return {
      isLoading: false,
      isChanged: false,
    };
  },
  computed: {
    ...mapGetters(['appVersion']),
  },
  methods: {
    resetInputs() {
      this.$refs.userProfileComp.resetInputs();
    },
    async saveConfig() {
      this.isLoading = true;
      await this.$refs.userProfileComp.saveConfig();
      this.isLoading = false;
    },
  },
};
</script>

<template>
  <div>
    <HubDialog
      v-model="createDialog"
      :title="$t('botFormEdit.addFormPart')"
      :primary-label="$t('common.ok')"
      primary-icon="check"
      :secondary-label="$t('common.cancel')"
      secondary-icon="close"
      @onPrimary="addStep()"
      @onSecondary="
        createDialog = false;
        $refs.form.reset();
      "
    >
      <div class="my-4" v-if="stepAfter">
        <div class="text-body-1">
          {{ $t('botFormEdit.pasteAfter') }}
          <span class="font-weight-bold">"{{ stepAfter.displayName }}"</span>
        </div>
      </div>
      <div>
        <v-form ref="form" v-model="createDialogValid">
          <v-text-field
            v-model="newName"
            :label="$t('botFormEdit.whatIsAsked')"
            required
            :rules="newNameRules"
            :counter="20"
          />

          <v-select
            v-model="newType"
            :label="$t('botFormEdit.typeOfInput')"
            item-text="name"
            item-value="template"
            item-disabled="isDisabled"
            :items="formChildTypes"
          />
        </v-form>
      </div>
    </HubDialog>
    <v-card
      v-if="
        isAdmin &&
        !isLoading &&
        content &&
        !noChannelContent &&
        content.childBoxes &&
        content.childBoxes.length === 0
      "
      flat
      class="ma-0 rounded-0"
    >
      <v-card-subtitle class="admin--text">
        {{ $t('botFormEdit.noFormPart') }}
      </v-card-subtitle>
      <v-card-text>
        {{ $t('botFormEdit.formPartInfo') }}
        <ul>
          <li>{{ $t('botFormEdit.freeTextInfo') }}</li>
          <li>{{ $t('botFormEdit.eMailInfo') }}</li>
          <li>{{ $t('botFormEdit.multipleInfo') }}</li>
        </ul>
      </v-card-text>
      <v-card-actions>
        <v-btn
          class="mr-2"
          color="primary"
          outlined
          small
          @click="createDialog = true"
        >
          {{ $t('botFormEdit.createStep') }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-list v-if="noChannelContent" class="mt-0" color="#f5f5f5">
      <!--  <v-list-item v-if="isChannelEdit && editable && channels.length > 1 && channel"> {{channel}} </v-list-item> -->
      <v-list-item class="pt-0">
        <v-list-item-content class="pt-0">
          <v-card outlined>
            <!-- Alert shows up if no channel content is present -->
            <v-alert color="primary lighten-3" dark type="error">
              <span>
                {{ $t('editor.common.noContentFound') }}
              </span>
            </v-alert>

            <!-- Error message if no channel contnet is present -->
            <v-row v-if="noChannelContent" no-gutters justify="center">
              <v-col cols="12" md="6">
                <v-img
                  src="https://uploads-ssl.webflow.com/5e71ed18ed23ac1f5bb16bc5/5fd9c953ae22b9695c62f833_404_roboter.png"
                />
              </v-col>
            </v-row>
          </v-card>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-list v-if="content && !noChannelContent" class="py-0" color="#f5f5f5">
      <v-list-item
        v-for="(step, index) of formSteps"
        :key="step.box"
        :ref="step.box"
        class="px-0"
      >
        <v-list-item-content class="pt-0">
          <v-card flat class="rounden-0">
            <v-timeline align-top dense class="mr-6">
              <v-timeline-item fill-dot class="mb-3 pb-3">
                <template v-slot:icon>
                  <span class="white--text text-h6">{{ index + 1 }}</span>
                </template>
                <v-btn
                  v-if="isAdmin"
                  class="float-right"
                  depressed
                  color="admin"
                  outlined
                  small
                  :disabled="!stepDeletable(step)"
                  @click="deleteStep(step, index)"
                >
                  {{ $t('botFormEdit.deleteStep') }}
                  <v-icon class="ml-2" small>delete</v-icon>
                </v-btn>
                <span class="text-h6 text-h5">{{ step.displayName }}</span>
                <p class="text-body-2">{{ stepNames(step.template).name }}</p>
              </v-timeline-item>

              <BotFormYesNoEdit
                v-if="step.template === 'BotFormYesNo'"
                :key="step.box"
                :name="step.displayName"
                :box="step.box"
                :channel="channel"
                :intent="content.intent"
                @onUpdated="updateAnswer"
              />
              <BotFormMultipleChoiceEdit
                v-if="step.template === 'BotFormMultipleChoice'"
                :key="step.box"
                :box="step.box"
                :name="step.displayName"
                :channel="channel"
                :intent="content.intent"
                @onUpdated="updateAnswer"
                @addMultiStep="addMultiStep"
                @removeMultiStep="removeMultiStep"
              />
              <BotFormMultipleChoiceSliderEdit
                v-if="step.template === 'BotFormMultipleChoiceSlider'"
                :key="step.box"
                :box="step.box"
                :name="step.displayName"
                :channel="channel"
                :intent="content.intent"
                @onUpdated="updateAnswer"
                @addMultiStep="addMultiStep"
                @removeMultiStep="removeMultiStep"
              />
              <BotFormFreeTextEdit
                v-if="step.template === 'BotFormFreeText'"
                :key="step.box"
                :name="step.displayName"
                :box="step.box"
                :channel="channel"
                :intent="content.intent"
                @onUpdated="updateAnswer"
              />
              <BotFormEmailEdit
                v-if="step.template === 'BotFormEmail'"
                :key="step.box"
                :name="step.displayName"
                :box="step.box"
                :channel="channel"
                :intent="content.intent"
                @onUpdated="updateAnswer"
              />
              <BotFormValidableInputEdit
                v-if="step.template === 'BotFormValidableInput'"
                :key="step.box"
                :name="step.displayName"
                :box="step.box"
                :channel="channel"
                :intent="content.intent"
                @onUpdated="updateAnswer"
              />
              <BotFormFileEdit
                v-if="step.template === 'BotFormFile'"
                :key="step.box"
                :name="step.displayName"
                :box="step.box"
                :channel="channel"
                :intent="content.intent"
                @onUpdated="updateAnswer"
              />
              <BotFormActionView
                v-if="step.template === 'BotFormActionView'"
                :key="step.box"
                :name="step.displayName"
                :box="step.box"
                :channel="channel"
                :intent="content.intent"
                @onUpdated="updateAnswer"
              />

              <BotFormConditionalSwitch
                v-if="step.template === 'BotFormConditionalSwitch'"
                :key="step.box"
                :name="step.displayName"
                :box="step.box"
                :channel="channel"
                :intent="content.intent"
                @onUpdated="updateAnswer"
                @addMultiStep="addMultiStep"
              />

              <!-- add missing condition for multiple choice slider -->
              <v-timeline-item
                v-if="
                  isAdmin &&
                  (step.template === 'BotFormMultipleChoice' ||
                    step.template === 'BotFormYesNo' ||
                    step.template === 'BotFormMultipleChoiceSlider' ||
                    step.template === 'BotFormConditionalSwitch') &&
                  content.template === 'BotForm'
                "
                hide-dot
              >
                <v-select
                  :label="$t('botFormEdit.nextStep')"
                  :items="getNextStepSelect(step)"
                  item-value="box"
                  :value="getSelectedValue(step).box"
                  item-text="displayName"
                  :disabled="!isBoxUsedMultiple(step)"
                  @change="
                    (e) => {
                      changeNextStepSelect(step, e);
                    }
                  "
                />
              </v-timeline-item>
              <v-timeline-item
                color="white"
                fill-dot
                class="mb-3 pb-3"
                v-if="
                  step.next &&
                  ((step.next.startsWith('completion') && isAdmin) ||
                    step.next.startsWith('completion('))
                "
              >
                <p>
                  Soll das Formular beendet werden oder zu einem anderen Thema
                  oder Formular verlassen werden.
                </p>
                <v-row>
                  <v-col cols="2">
                    <v-checkbox
                      :disabled="!isAdmin"
                      :input-value="completionIntent !== 'none'"
                      label="Verlassen"
                      @change="changeCompletionMode"
                    ></v-checkbox>
                  </v-col>
                  <v-col>
                    <v-select
                      v-show="completionIntent !== 'none'"
                      :disabled="!isAdmin"
                      label="Formular oder Thema"
                      :items="otherIntents"
                      item-value="intent"
                      :value="completionIntent"
                      item-text="displayName"
                      @change="changeCurrentCompletionIntent"
                    />
                  </v-col>
                </v-row>
              </v-timeline-item>
            </v-timeline>
          </v-card>
          <v-row
            v-if="isAdmin && content.template === 'BotForm'"
            class="mt-2 ml-2 pb-2"
          >
            <v-btn
              color="admin white--text"
              depressed
              small
              @click="
                stepAfter = step;
                createDialog = true;
              "
            >
              {{ $t('botFormEdit.newStep') }}
              <v-icon class="ml-2" small>add</v-icon>
            </v-btn>
          </v-row>
          <v-row
            v-else-if="
              isAdmin &&
              content.template === 'BotFormRating' &&
              formSteps.length === 1
            "
            class="mt-2 ml-2 pb-2"
          >
            <v-btn
              color="primary text--lighten-3"
              depressed
              small
              @click="
                stepAfter = step;
                addRatingCommentStep();
              "
            >
              {{ $t('botFormEdit.addCommentStep') }}
              <v-icon class="ml-2" small>add</v-icon>
            </v-btn>
          </v-row>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-card
      v-if="content && !noChannelContent && !isLoading && completionIntent === 'none'"
      flat
      class="rounded-0"
    >
      <v-timeline align-top dense class="mr-6">
        <!-- Timeline: Header -->
        <v-timeline-item color="success" fill-dot>
          <template v-slot:icon>
            <v-icon color="white">check</v-icon>
          </template>
          <span class="text-h6 text-h5">{{ $t('botFormEdit.endForm') }}</span>
        </v-timeline-item>

        <!-- Timeline: Add response options -->
        <ActionList
          v-if="content && content.completion"
          :actions="content.completion.actions"
          @onUpdate="updateAnswer"
          :channelType="channelType"
        />

        <!-- Timeline: Select webhook -->
        <v-timeline-item
          color="white"
          v-if="isAdmin && webhookConfigsList.length && false"
        >
          <template v-slot:icon>
            <v-icon color="admin">cable</v-icon>
          </template>
          <v-select
            :label="$t('botFormEdit.chooseWebhook')"
            :items="webhookConfigsList"
            item-text="displayName"
            item-value="key"
            :value="selectedWebhook"
            @change="selectWebhook"
          />
        </v-timeline-item>

        <!-- Timeline: Where to forward (email, livechat takeover) -->
        <v-timeline-item v-if="isAdmin" fill-dot>
          <template v-slot:icon>
            <v-icon color="admin">forward_to_inbox</v-icon>
          </template>

          <div class="moin-admin-box pa-1">
            <v-select
              :label="$t('botFormEdit.finalizeForm')"
              :items="notificationTypes"
              item-text="displayName"
              item-value="name"
              item-disabled="isDisabled"
              :value="notificationType.name"
              @input="updateNotifcation"
            />
            <v-select
              v-if="notificationType.name === 'webchat_cmd'"
              :label="$t('botFormEdit.livechatVendor')"
              :items="livechatTypes"
              item-text="displayName"
              item-value="name"
              :disabled="isHighPrivacy"
              :value="notificationType.parameters.payload.type"
              @input="updateLivechatType"
            />
            <v-select
              v-if="
                notificationType.name === 'greyhound' ||
                notificationType.name === 'zChat' ||
                notificationType.name === 'livechat' ||
                notificationType.name === 'salesforce' ||
                notificationType.name === 'freshchat' ||
                notificationType.name === 'crisp'
              "
              :label="$t('botFormEdit.livechatVendor')"
              :items="livechatTypes"
              item-text="displayName"
              item-value="name"
              :disabled="isHighPrivacy"
              :value="notificationType.name"
              @input="updateLivechatType"
            />

            <!-- Select group for moinAI Livechat -->
            <v-select
              v-if="notificationType.name === 'moin_livechat'"
              v-model="toMoinLivechatGroups"
              :items="possibleLivechatGroups"
              :rules="chatGroupRules"
              :label="$t('botFormEdit.directToLivechatGroups')"
              multiple
              chips
              @input="updateLivechatGroup"
            >
              <template #selection="{ item }">
                <div
                  class="group-list-item"
                  :class="{ deletedGroup: item.isDeletedGroup }"
                >
                  {{ item.text }}
                </div>
              </template>
            </v-select>

            <v-text-field
              v-if="isAdmin && notificationType.name === 'notification'"
              v-model="notificationType.parameters.type"
              :label="$t('botFormEdit.eMailTemplate')"
              @input="updateAnswer"
            />
            <v-row
              v-if="isAdmin && notificationType.name === 'salesforce_case'"
              class="ma-0 d-flex justify-start align-center"
            >
              <label class="mr-3">
                {{ $t('botFormEdit.fileUpload.maxFilesLabel') }}
              </label>
              <v-text-field
                v-model="notificationType.parameters.maxFiles"
                :label="$t('botFormEdit.fileUpload.maxFilesTemplate')"
                type="number"
                step="1"
                min="0"
                max="10"
                @input="updateAnswer"
              />
            </v-row>

            <v-row
              v-if="isAdmin && notificationType.name === '$zendesk'"
              class="ma-0 d-flex justify-start align-center"
            >
              <v-text-field
                v-model="notificationType.parameters.id"
                :label="$t('botFormEdit.zendesk.id')"
                @input="updateAnswer"
              />
            </v-row>
            <v-text-field
              v-if="notificationType.name === '$handover' && isUserlike"
              v-model="notificationType.parameters.group"
              :label="$t('botFormEdit.userLikeGroup')"
              @input="updateAnswer"
            />
            <v-combobox
              v-if="
                notificationType.name === 'webchat_cmd' &&
                notificationType.parameters.payload.type === 'zendesk'
              "
              v-model="notificationType.parameters.payload.tags"
              multiple
              label="Tags"
              append-icon
              chips
              deletable-chips
              @input="updateAnswer"
            />
            <v-text-field
              v-if="
                notificationType.name === 'webchat_cmd' &&
                notificationType.parameters.payload.type === 'zendesk'
              "
              v-model.number="
                notificationType.parameters.payload.default_department
              "
              label="Department Id"
              type="number"
              @input="updateAnswer"
            />
            <v-text-field
              v-if="
                notificationType.name === 'webchat_cmd' &&
                notificationType.parameters.payload.type === 'zendesk'
              "
              v-model="notificationType.parameters.payload.account_key"
              label="Account Key"
              @input="updateAnswer"
            />
          </div>
        </v-timeline-item>

        <!-- Timeline Livechat: Header, Agent available -->
        <v-timeline-item v-if="content && isLivchatType" fill-dot>
          <template v-slot:icon>
            <v-icon color="white">support_agent</v-icon>
          </template>
          <span class="text-h6 text-h5">
            {{ $t('botFormEdit.agentAvailable') }}
          </span>
        </v-timeline-item>

        <!-- Timeline Livechat: Set up replies to user -->
        <ActionList
          v-if="content && content.livechatStart && isLivchatType"
          :actions="content.livechatStart.actions"
          @onUpdate="updateAnswer"
          :channelType="channelType"
        />

        <!-- Timeline Livechat: Header, Agent available -->
        <v-timeline-item v-if="content && isLivchatType" fill-dot>
          <template v-slot:icon>
            <v-icon color="white">hourglass_top</v-icon>
            <!-- <v-icon color="white">schedule</v-icon> -->
          </template>
          <span class="text-h6 text-h5">{{ $t('botFormEdit.agentWaiting') }}</span>
        </v-timeline-item>

        <!-- Timeline Livechat: Agent waiting -->
        <ActionList
          v-if="content && content.livechatAgentWaiting && isLivchatType"
          :actions="content.livechatAgentWaiting.actions"
          quickreplies
          @onUpdate="updateAnswer"
          :channelType="channelType"
        />

        <!-- Timeline Livechat: Header, No agent available --->
        <v-timeline-item v-if="content && isLivchatType" fill-dot>
          <template v-slot:icon>
            <v-icon color="white">headset_off</v-icon>
          </template>
          <span class="text-h6 text-h5">
            {{ $t('botFormEdit.noAgentAvailable') }}
          </span>
        </v-timeline-item>

        <!-- Timeline Livechat: Set up replies to user -->
        <ActionList
          v-if="content && content.livechatNoAgent && isLivchatType"
          :actions="content.livechatNoAgent.actions"
          quickreplies
          @onUpdate="updateAnswer"
          :channelType="channelType"
        />

        <!-- Timeline Livechat: Header, Agent hands back to chatbot -->
        <v-timeline-item v-if="livechatHasHandoverToBot" fill-dot>
          <template v-slot:icon>
            <v-icon color="white">keyboard_return</v-icon>
          </template>
          <span class="text-h6 text-h5">
            {{ $t('botFormEdit.agentGivesBack') }}
          </span>
        </v-timeline-item>

        <!-- Timeline Livechat: Set up replies to user -->
        <LivechatHandoverToBot
          v-if="livechatHasHandoverToBot"
          :actions="content.livechatFinish.actions"
          @onUpdate="updateAnswer"
        ></LivechatHandoverToBot>
      </v-timeline>
    </v-card>
    <v-card
      v-if="content && !noChannelContent && !isLoading"
      flat
      class="rounded-0"
      :class="contentChanged ? '' : 'mb-4'"
    >
      <v-timeline align-top dense class="mr-6">
        <v-timeline-item color="error" fill-dot>
          <template v-slot:icon>
            <v-icon color="white">close</v-icon>
          </template>
          <span class="title text-h5">
            {{ $t('botFormEdit.textAfterCancel') }}
          </span>
        </v-timeline-item>
        <ActionList
          v-if="content && content.cancel"
          :actions="content.cancel.actions"
          @onUpdate="updateAnswer"
          :channelType="channelType"
        />
      </v-timeline>
    </v-card>
    <v-card v-if="content && !noChannelContent && isLoading">
      <v-skeleton-loader type="article" />
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
/*   import SimpleInformationEdit from '@/components/editor/SimpleInformationEdit.vue';
    // import TransactionalEdit from '@/components/editor/TransactionalEdit.vue';
    import IntentUtil from '../../utils/intents';
import ProductService from '../../services/product'; */
import ChannelsUtil from '@/utils/channels';
import IntentUtil from '../../utils/intents';
import ActionList from '@/components/ActionList.vue';
import BotFormFreeTextEdit from '@/components/editor/BotFormFreeTextEdit.vue';
import BotFormEmailEdit from '@/components/editor/BotFormEmailEdit.vue';
import BotFormFileEdit from '@/components/editor/BotFormFileEdit.vue';
import BotFormMultipleChoiceEdit from '@/components/editor/BotFormMultipleChoiceEdit.vue';
import BotFormMultipleChoiceSliderEdit from '@/components/editor/BotFormMultipleChoiceSliderEdit.vue';
import BotFormYesNoEdit from '@/components/editor/BotFormYesNoEdit.vue';
import BotFormValidableInputEdit from '@/components/editor/BotFormValidableInputEdit.vue';
import BotFormActionView from '@/components/editor/BotFormActionView.vue';
import BotFormConditionalSwitch from '@/components/editor/BotFormConditionalSwitch.vue';
import LivechatHandoverToBot from '../forms/LivechatHandoverToBot.vue';
import HubDialog from '@/components/hub/HubDialog.vue';
import { messageTypes, styleTypes } from '@/utils/notifications';
import { LivechatProvidersEnum } from '@/constants';

const livechatProviders = new Set(Object.values(LivechatProvidersEnum));
/**
 * @typedef {Object} ToneOfVoiceExample
 * @property {string} scenario
 * @property {string} answer
 */
/**
 * @typedef {Object} ToneOfVoice
 * @property {boolean} enabled
 * @property {ToneOfVoiceExample[]} examples
 */
/**
 * @typedef {Object} ChannelConfig
 * @property {number} version
 * @property {string} channelId
 */
/**
 * @typedef {Object} Channel
 * @property {string[]} languages
 * @property {string} displayName
 * @property {string} channelId
 * @property {string} channel
 * @property {ChannelConfig} config
 */
/**
 * @typedef {Object} Member
 * @property {string[]} languages
 * @property {string} _id
 * @property {string} name
 * @property {string} email
 * @property {string} status
 * @property {string} organizationUuid
 * @property {number} maxNumberOpenChats
 */
/**
 * @typedef {Object} Group
 * @property {string} uuid
 * @property {string} name
 * @property {string} description
 * @property {string} channelId
 * @property {string[]} shortcodes
 * @property {string[]} files
 * @property {Member[]} members
 * @property {Channel} channel
 */
export default {
  name: 'BotFormEdit',
  components: {
    BotFormFreeTextEdit,
    BotFormEmailEdit,
    BotFormFileEdit,
    BotFormMultipleChoiceEdit,
    BotFormMultipleChoiceSliderEdit,
    BotFormYesNoEdit,
    BotFormActionView,
    BotFormValidableInputEdit,
    BotFormConditionalSwitch,
    ActionList,
    LivechatHandoverToBot,
    HubDialog,
  },
  emits: ['save', 'setFormValid'],
  props: ['intent', 'channel', 'loading', 'options', 'draft'],
  data() {
    return {
      selectedChannelId: null,
      boxName: null,
      saving: false,
      createDialog: false,
      createDialogValid: true,
      stepAfter: undefined,
      newName: null,
      newNameRules: [
        (v) => !!v || this.$t('botFormEdit.ruleNameNecessary'),
        (v) => (v && v.length <= 20) || this.$t('botFormEdit.ruleNameMax'),
      ],
      newType: null,
      contentSaving: false,
      savingToast: false,
      hideFloatingSaveButton: true,
      editable: false,
      editability: this.intent?.template === 'SimpleInformation',
      performance: null,
      changeTypeDialog: null,
      changeTypeDialogText: null,
      changeTypeCancel: null,
      changeTypeSubmit: null,
      changesDialog: null,
      renderedSteps: [],
      toMoinLivechatGroups: [],
      chatGroupRules: [
        (v) => (v || []).length > 0 || this.$t('botFormEdit.chatGroupRequired'),
        (v) => (v || []).every(id => this.groups.some(group => group.uuid === id )) || this.$t('botFormEdit.hasDeletedChatGroups')
      ],
    };
  },
  computed: {
    getSelectedPath() {
      return this.$store.getters['formsEditor/selectedPath'];
    },
    ...mapGetters('bots', ['currentBotId', 'currentChannels', 'getBotSettings']),
    ...mapGetters('auth', ['isChannelEdit', 'isAdmin']),
    ...mapGetters('contentQueue', ['isSyncing']),
    ...mapGetters('intents', ['intents', 'forms']),
    ...mapGetters('formsEditor', ['formSteps', 'isEventTrackingAvailable']),
    ...mapGetters('integrations', ['webhookConfigsList']),
    ...mapGetters('livechatManagement', ['groups']),

    /**
     * Returns a list of MoinAI Livechat groups available that can take over the chat
     * @returns {Array<{text: string, value: string, isDeletedGroup?: boolean}>}
     */
    possibleLivechatGroups() {
      const items = (this.groups || []).map((group) => ({
        text: group.name,
        value: group.uuid,
      }));

      /**
       * Check if IDs of groups that no longer exist are used. Add them as deleted group options.
       * The item will automatically disappear from the options when un-selected.
       */
      for (let i=0; i<(this.content?.livechatProvider?.payload?.groups || []).length; i++) {
        if (!items.find((item) => item.value === this.content.livechatProvider.payload.groups[i])) {
          items.push({
            text: this.$t('botFormEdit.deletedGroupLabel'),
            value: this.content.livechatProvider.payload.groups[i],

            isDeletedGroup: true,
          });
        }
      }

      return items;
    },
    intentType() {
      return IntentUtil.getIntentType(this, this.intent);
    },
    livechatHasHandoverToBot() {
      // userlike has no hand over to bot
      return (
        this.content?.livechatFinish &&
        (this.notificationType.name === '$handover' ||
          this.notificationType.name === 'webchat_cmd' ||
          this.notificationType.name === 'livechat' ||
          this.notificationType.name === 'zChat' ||
          this.notificationType.name === 'salesforce' ||
          this.notificationType.name === 'greyhound' ||
          this.notificationType.name === 'moin_livechat' ||
          this.notificationType.name === 'userlike' ||
          this.notificationType.name === 'freshchat' ||
          this.notificationType.name === 'crisp') &&
        !this.isUserlike
      );
    },
    content() {
      let contentObj = null;
      if (this.boxName) {
        contentObj = this.$store.getters['content/getContent'](
          this.currentBotId,
          this.intent,
          this.boxName
        );
      }
      if (!contentObj) {
        this.$store.dispatch('glimpseContent/copyOrigin', {
          botId: this.currentBotId,
          name: this.intent,
          channel: this.channel,
        });
      }

      return contentObj;
    },
    contentChanged() {
      return this.$store.getters['content/isContentChanged'](
        this.currentBotId,
        this.intent,
        this.channel
      );
    },
    formChildTypes() {
      const freeTextItem = { ...this.$t('forms.formChildTypes.freeText') };
      const eMailItem = { ...this.$t('forms.formChildTypes.eMail') };
      const validableItem = { ...this.$t('forms.formChildTypes.validable') };
      const fileItem = { ...this.$t('forms.formChildTypes.file') };
      const actionView = { ...this.$t('forms.formChildTypes.actionView') };
      const conditionalSwitch = {
        ...this.$t('forms.formChildTypes.conditionalSwitch'),
      };

      if (this.isHighPrivacy) {
        freeTextItem.isDisabled = true;
        eMailItem.isDisabled = true;
        fileItem.isDisabled = true;
      }

      const formChildTypesList = [
        freeTextItem,
        eMailItem,
        validableItem,
        this.$t('forms.formChildTypes.multipleChoice'),
        this.$t('forms.formChildTypes.yesNo'),
        fileItem,
        actionView,
        conditionalSwitch,
      ];
      if (this.isUserlike) {
        formChildTypesList.push(
          this.$t('forms.formChildTypes.multipleChoiceSlider')
        );
      }
      return formChildTypesList;
    },
    selectedChannel() {
      return this.content ? this.content.channel : this.channel;
    },
    noChannelContent() {
      return (
        this.content &&
        !this.isLoading &&
        this.channel &&
        this.content.channel !== this.channel
      );
    },
    isLoading() {
      return this.loading === true;
    },
    selectedChannelIndex() {
      const $this = this;
      return this.channels.findIndex((c) => {
        return c.channelId === $this.channel;
      });
    },
    channels() {
      let i = 0;
      const channels = this.currentChannels.filter((c) => !c.blocked);
      return channels.map((channel) => {
        const clonedChannel = _.cloneDeep(channel);
        if (i === 0) {
          clonedChannel.channelId = null;
        }
        i++;
        return clonedChannel;
      });
    },
    isUserlike() {
      return this.channels[this.selectedChannelIndex]?.channel === 'userlike';
    },
    livechatTypes() {
      let types = [
        { name: 'moin_livechat', displayName: 'moinAI Chat' },
        { name: 'userlike', displayName: 'Userlike' },
        { name: 'greyhound', displayName: 'Greyhound' },
        { name: 'salesforce', displayName: 'Salesforce' },
        { name: 'zChat', displayName: 'Zendesk v2' },
        { name: 'livechat', displayName: 'Livechat.com' },
        { name: 'freshchat', displayName: 'Freshchat' },
        { name: 'crisp', displayName: 'Crisp' },
      ];

      if (this.notificationType.name === 'webchat_cmd') {
        types.unshift({ name: 'zendesk', displayName: 'Zendesk' });
      }

      return types;
    },
    isLivchatType() {
      return (
        this.notificationType.name === 'webchat_cmd' ||
        this.notificationType.name === 'greyhound' ||
        this.notificationType.name === 'zChat' ||
        this.notificationType.name === 'salesforce' ||
        this.notificationType.name === 'livechat' ||
        this.notificationType.name === 'freshchat' ||
        this.notificationType.name === 'moin_livechat' ||
        this.notificationType.name === 'userlike' ||
        this.notificationType.name === 'crisp'
      );
    },
    notificationTypes() {
      const website = [
        { name: 'notification', displayName: this.$t('common.eMail') },
      ];

      if (this.content.template === 'BotFormRating') {
        website.push({
          name: 'none',
          displayName: this.$t('botFormEdit.noAction'),
        });
        return website;
      }

      const facebook = [
        { name: 'notification', displayName: this.$t('common.eMail') },
        {
          name: '$handover',
          displayName: this.$t('botFormEdit.facebookHandover'),
        },
      ];
      const userlike = [
        { name: 'notification', displayName: this.$t('common.eMail') },
        {
          name: '$handover',
          displayName: this.$t('botFormEdit.userlikeAgent'),
        },
      ];

      // Livechat
      const livechat = { name: 'webchat_cmd', displayName: 'Livechat Agent' };

      // Salesforce Ticket case
      // Condition to show salesforce case = ?
      if (true) {
        website.push({
          name: 'salesforce_case',
          displayName: 'Salesforce Case',
        });
      }

      // Zendesk Ticket
      // Condition to show zendesk ticket = ?
      if (true) {
        website.push({ name: '$zendesk', displayName: 'Zendesk Ticket' });
      }

      if (this.isHighPrivacy) {
        livechat.isDisabled = true;
      }

      if (this.notificationType.name === 'webchat_cmd') {
        if (
          this.notificationType.name !== 'greyhound' &&
          this.notificationType.name !== 'zChat' &&
          this.notificationType.name !== 'salesforce' &&
          this.notificationType.name !== 'livechat' &&
          this.notificationType.name !== 'moin_livechat' &&
          this.notificationType.name !== 'userlike' &&
          this.notificationType.name !== 'freshchat' &&
          this.notificationType.name !== 'crisp'
        ) {
          website.push(livechat);
        }
      }

      if (
        this.notificationType.name === 'greyhound' ||
        this.notificationType.name === 'zChat' ||
        this.notificationType.name === 'salesforce' ||
        this.notificationType.name === 'livechat' ||
        this.notificationType.name === 'moin_livechat' ||
        this.notificationType.name === 'userlike' ||
        this.notificationType.name === 'freshchat' ||
        this.notificationType.name === 'crisp'
      ) {
        const livechatS2S = {
          name: this.notificationType.name,
          displayName: 'Livechat Agent',
        };

        if (this.notificationType.name === 'moin_livechat') {
          livechatS2S.displayName = 'moinAI Chat';
        }

        if (this.notificationType.name === 'userlike') {
          livechatS2S.displayName = 'Userlike';
        }

        if (this.isHighPrivacy) {
          livechatS2S.isDisabled = true;
        }
        website.push(livechatS2S);
      } else if (this.isAdmin && this.notificationType.name !== 'webchat_cmd') {
        const livechatS2S = {
          name: 'greyhound',
          displayName: 'Livechat Agent',
        };
        if (this.isHighPrivacy) {
          livechatS2S.isDisabled = true;
        }
        website.push(livechatS2S);
      }

      if (
        this.channels &&
        this.channels[this.selectedChannelIndex] &&
        this.channels[this.selectedChannelIndex].channel === 'facebook'
      ) {
        return facebook;
      }

      if (
        this.channels &&
        this.channels[this.selectedChannelIndex] &&
        this.channels[this.selectedChannelIndex].channel === 'userlike'
      ) {
        return userlike;
      }

      return website;
    },
    notificationType() {
      let defaultType = {};
      if (this.content.template === 'BotFormRating') {
        defaultType = {
          name: 'none',
          displayName: this.$t('botFormEdit.noAction'),
        };
      }

      if (
        this.content &&
        this.content.completion &&
        Array.isArray(this.content.completion.actions)
      ) {
        const email = this.content.completion.actions.find((a) => {
          return a.name === 'notification';
        });

        if (email && email.parameters) {
          return email;
        }

        if (email) {
          return { name: 'notification', parameters: { type: 'default' } };
        }

        if (
          this.channels &&
          this.channels[this.selectedChannelIndex] &&
          this.channels[this.selectedChannelIndex].channel === 'facebook'
        ) {
          const facebook = this.content.completion.actions.find((a) => {
            return a.name === '$handover';
          });

          if (facebook) return facebook;
          return {};
        }

        if (
          this.channels &&
          this.channels[this.selectedChannelIndex] &&
          this.channels[this.selectedChannelIndex].channel === 'userlike'
        ) {
          const userlike = this.content.completion.actions.find((a) => {
            return a.name === '$handover';
          });

          if (userlike) return userlike;
          return defaultType;
        }

        // All other actions
        const zendesk = this.content.completion.actions.find((a) => {
          return (
            a.name === 'webchat_cmd' &&
            a.parameters &&
            a.parameters.cmd === 'init_livechat_takeover' &&
            a.parameters.payload &&
            a.parameters.payload.type === 'zendesk'
          );
        });

        if (zendesk) return zendesk;

        const greyhound = this.content.completion.actions.find((a) => {
          return (
            a.name === 'greyhound' &&
            a.parameters &&
            a.parameters.type === 'check'
          );
        });

        if (greyhound) return greyhound;

        const zChat = this.content.completion.actions.find((a) => {
          return (
            a.name === 'zChat' &&
            a.parameters &&
            a.parameters.type === 'check'
          );
        });

        if (zChat) return zChat;

        const salesforceCase = this.content.completion.actions.find((a) => {
          return a.name === 'salesforce_case';
        });

        if (salesforceCase) return salesforceCase;

        const zendeskTicket = this.content.completion.actions.find((a) => {
          return a.name === '$zendesk';
        });

        if (zendeskTicket) return zendeskTicket;

        const salesforce = this.content.completion.actions.find((a) => {
          return (
            a.name === 'salesforce' &&
            a.parameters &&
            a.parameters.type === 'check'
          );
        });

        if (salesforce) return salesforce;

        const livechat = this.content.completion.actions.find((a) => {
          return (
            a.name === 'livechat' &&
            a.parameters &&
            a.parameters.type === 'check'
          );
        });

        if (livechat) return livechat;

        const freshchat = this.content.completion.actions.find((a) => {
          return (
            a.name === 'freshchat' &&
            a.parameters &&
            a.parameters.type === 'check'
          );
        });

        if (freshchat) return freshchat;

        const moin_livechat = this.content.completion.actions.find((a) => {
          return (
            a.name === 'moin_livechat' &&
            a.parameters &&
            a.parameters.type === 'check'
          );
        });

        if (moin_livechat) return moin_livechat;

        const userilke = this.content.completion.actions.find((a) => {
          return (
            a.name === 'userlike' &&
            a.parameters &&
            a.parameters.type === 'check'
          );
        });

        if (userilke) return userilke;

        const crisp = this.content.completion.actions.find((a) => {
          return (
              a.name === 'crisp' &&
              a.parameters &&
              a.parameters.type === 'check'
          );
        });

        if (crisp) return crisp;

        return defaultType;
      }

      return defaultType;
    },
    selectedWebhook() {
      const webhook = this.content?.completion?.actions?.find(
        (a) => a.name === 'webhook'
      );
      return webhook?.parameters?.type || null;
    },
    channelType() {
      return ChannelsUtil.getChannelType(
        this.content?.channel,
        this.currentChannels
      );
    },
    isHighPrivacy() {
      const botSettings = this.getBotSettings(this.currentBotId);
      return botSettings?.highPrivacy?.active || false;
    },
    otherIntents() {
      const otherForms = this.$store.getters['forms/forms'];
      let result = [...this.intents];

      if (!result) {
        return [];
      }

      if (otherForms) {
        for (let form of otherForms) {
          // if (form.intent !== this.intent) {
          result.unshift(form);
          // }
        }
      }

      return result;
    },
    completionIntent() {
      // get last step from formSteps
      const lastStep = this.formSteps[this.formSteps.length - 1];
      if (!lastStep || !lastStep.next) {
        return 'none';
      }
      if (lastStep) {
        if (lastStep.next === 'completion') {
          return 'none';
        } else if (lastStep.next.startsWith('completion({')) {
          const paramMatch = lastStep.next.match(/(.+)\((\{.+\})\)/);
          if (paramMatch && paramMatch.length > 2) {
            const params = JSON.parse(paramMatch[2]);
            return params.intent;
          }
        }
      }
      return 'none';
    },
  },
  methods: {
    ...mapActions('livechatManagement', ['init']),
    async fetchContent() {
      if (this.options && this.intent) {
        const newBox = await this.$store.dispatch('glimpseContent/getContent', {
          botId: this.currentBotId,
          intent: this.intent,
          template: this.options.template,
          box: this.options.box,
          channel: this.channel,
          preloading: true,
        });
        if (
          this.options.box !== newBox &&
          newBox !== false &&
          newBox !== undefined
        ) {
          this.boxName = newBox;
          this.$set(this.options, 'box', newBox);
        }

        await this.$store.dispatch('content/update', {});
        this.$store.commit('formsEditor/setForm', {
          intent: this.intent,
          box: this.boxName,
          channel: this.channel,
        });
        this.updateRenderedSteps();
        this.$store.dispatch('integrations/fetchWebhookConfigs');
      }
    },
    updateLivechatType(type) {
      const notificationType = this.notificationType;
      if (notificationType.name === 'webchat_cmd') {
        notificationType.parameters.payload.type = type;
        if (
          type === 'greyhound' ||
          type === 'zChat' ||
          type === 'livechat' ||
          type === 'moin_livechat' ||
          type === 'userlike' ||
          type === 'salesforce' ||
          type === 'freshchat' ||
          type === 'crisp'
        ) {
          delete notificationType.parameters.payload.account_key;
          delete notificationType.parameters.payload.tags;
          delete notificationType.parameters.payload.default_department;

          this.content.completion.actions =
            this.content.completion.actions.filter((a) => {
              return (
                a.name !== 'greyhound' &&
                a.name !== 'zChat' &&
                a.name !== 'salesforce' &&
                a.name !== 'livechat' &&
                a.name !== 'moin_livechat' &&
                a.name !== 'userlike' &&
                a.name !== 'freshchat' &&
                a.name !== 'crisp'
              );
            });

          this.content.completion.actions.push({
            name: type,
            parameters: {
              type: 'check',
            },
          });
        } else {
          notificationType.parameters.payload.account_key = undefined;
          notificationType.parameters.payload.tags = ['moinAI'];
          notificationType.parameters.payload.default_department = 0;
        }
      } else {
        if (
          type === 'greyhound' ||
          type === 'zChat' ||
          type === 'salesforce' ||
          type === 'livechat' ||
          type === 'moin_livechat' ||
          type === 'userlike' ||
          type === 'freshchat' ||
          type === 'crisp'
        ) {
          this.content.completion.actions =
            this.content.completion.actions.filter((a) => {
              return (
                a.name !== 'greyhound' &&
                a.name !== 'zChat' &&
                a.name !== 'livechat' &&
                a.name !== 'salesforce' &&
                a.name !== 'moin_livechat' &&
                a.name !== 'userlike' &&
                a.name !== 'freshchat' &&
                a.name !== 'crisp'
              );
            });

          const webchatCmd = this.content.completion.actions.find((a) => {
            return a.name === 'webchat_cmd';
          });

          if (webchatCmd) {
            webchatCmd.parameters.payload.type = type;
          } else {
            this.content.completion.actions.push({
              name: 'webchat_cmd',
              parameters: {
                cmd: 'init_livechat_takeover',
                payload: {
                  type: type,
                },
              },
            });
          }

          this.content.livechatProvider = { type };
          this.content.completion.actions.push({
            name: type,
            parameters: {
              type: 'check',
            },
          });
        }
      }

      this.updateAnswer();
      this.validateLivechatPayloads();
    },
    updateLivechatGroup(groupIds) {
      if (!groupIds) groupIds = this.toMoinLivechatGroups;
      this.content.livechatProvider.payload = { groups: groupIds };

      this.updateAnswer();
      this.validateLivechatPayloads();
    },
    /**
     * Performs validation on the livechat payloads.
     * Emits an event to disable save if invalid, and returns a boolean dictating if form is valid or not
     * @returns {boolean} Valid if `true`.
     */
    validateLivechatPayloads() {
      let valid = true;

      switch(this.notificationType.name) {
        case "moin_livechat": {
          // Must have at least one group
          if (!this.content.livechatProvider.payload?.groups?.length) {
            valid = false;
            break;
          }

          // Cannot have deleted groups
          if (this.possibleLivechatGroups.some(item => item.isDeletedGroup)) {
            valid = false;
            break;
          }
        }
      }

      this.$emit('setFormValid', valid);
      return valid;
    },
    updateNotifcation(type) {
      if (type !== this.notificationType.name) {
        this.content.completion.actions =
          this.content.completion.actions.filter((a) => {
            return (
              a.name !== 'notification' &&
              a.name !== '$handover' &&
              a.name !== 'webchat_cmd' &&
              !livechatProviders.has(a.name) &&
              a.name !== 'salesforce_case' &&
              a.name !== '$zendesk'
            );
          });

        const livechatType = 'greyhound';
        const livechatinit = {
          name: 'greyhound',
          parameters: {
            type: 'check',
          },
        };

        switch (type) {
          case 'notification':
            this.content.completion.actions.unshift({
              name: 'notification',
              parameters: { type: 'default' },
            });
            break;
          case '$handover':
            this.content.completion.actions =
              this.content.completion.actions.filter((a) => {
                return a.name !== 'next';
              });

            if (
              this.channels &&
              this.channels[this.selectedChannelIndex] &&
              this.channels[this.selectedChannelIndex].channel === 'userlike'
            ) {
              this.content.completion.actions.push({
                name: '$handover',
                parameters: { group: '' },
              });
            } else {
              this.content.completion.actions.push({
                name: '$handover',
                parameters: { value: true },
              });
            }

            if (!this.content.livechatFinish) {
              this.content.livechatFinish = { actions: [] };
            }
            break;
          case 'greyhound':
          case 'webchat_cmd':
            this.content.completion.actions =
              this.content.completion.actions.filter((a) => {
                return a.name !== 'next';
              });
            if (!this.content.livechatFinish) {
              this.$set(this.content, 'livechatFinish', {
                actions: [
                  {
                    name: 'webchat_cmd',
                    parameters: { cmd: 'stop_livechat_takeover' },
                  },
                ],
              });
            }

            if (!this.content.livechatAgentWaiting) {
              this.$set(this.content, 'livechatAgentWaiting', { actions: [] });
            }

            if (!this.content.livechatNoAgent) {
              this.$set(this.content, 'livechatNoAgent', { actions: [] });
            }

            if (!this.content.livechatStart) {
              this.$set(this.content, 'livechatStart', {
                actions: [
                  {
                    name: 'webchat_cmd',
                    parameters: { cmd: 'start_livechat_takeover' },
                  },
                ],
              });
            }

            if (livechatType === 'zendesk') {
              if (!livechatinit.parameters.payload) {
                this.$set(livechatinit, 'parameters.payload', {});
              }
              livechatinit.parameters.payload.tags = ['moinAI'];
              livechatinit.parameters.payload.default_department = 0;
            }

            this.content.completion.actions.push(livechatinit);
            break;
          case 'salesforce_case':
            this.content.completion.actions.push({
              name: 'salesforce_case',
              parameters: {},
            });
            break;
          case '$zendesk':
            this.content.completion.actions.push({
              name: '$zendesk',
              parameters: { id: '' },
            });
            break;
        }
      }

      this.updateAnswer();
    },
    stepDeletable(step) {
      // if form is a rating form and step is rating step.
      if (this.options.template === 'BotFormRating') {
        // get content for step box
        const boxContent = this.$store.getters['content/getContent'](
          this.currentBotId,
          this.intent,
          step.box
        );
        if (boxContent?.context === 'rating') return false;
      }

      if (!step.values) return true;

      const followUpBoxes = new Set();
      step.values.forEach((v) => {
        followUpBoxes.add(v.box);
      });
      return followUpBoxes.size === 1;
    },
    updateRenderedSteps() {
      this.$store.dispatch(
        'formsEditor/updateRenderedSteps',
        this.currentBotId
      );
    },
    deleteBranch(step) {
      const idx = step.values.findIndex((v) => {
        return (
          v.value ===
          this.$store.getters['formsEditor/getSelectedPath'][step.box]
        );
      });

      step.values.splice(idx, 1, 0);
      this.updateRenderedSteps();
      this.updateAnswer();
    },
    deleteStep(step, index) {
      let next = 'completion';

      if (step.values) {
        const value = step.values.find((b) => {
          return (
            b.value ===
            this.$store.getters['formsEditor/getSelectedPath'](step.box)
          );
        });

        next = value.box;
      } else {
        next = step.next;
      }

      if (index > 0) {
        const prevBox = this.formSteps[index - 1];
        if (prevBox.values) {
          const value = prevBox.values.find((b) => {
            return (
              b.value ===
              this.$store.getters['formsEditor/getSelectedPath'](prevBox.box)
            );
          });

          value.box = next;
        } else {
          prevBox.next = next;
        }
      } else {
        this.content.start = { next };
      }
      this.updateAnswer();
      this.updateRenderedSteps();
    },
    changeNextStepSelect(step, e) {
      const childBox = this.content.childBoxes.find((c) => {
        return c.box === step.box;
      });

      const value = childBox.values.find((v) => {
        return (
          v.value ===
          this.$store.getters['formsEditor/getSelectedPath'](step.box)
        );
      });

      value.box = e;
      this.updateRenderedSteps();
    },
    addMultiStep(e) {
      const step = this.formSteps.find((step) => {
        return step.box === e.box;
      });

      if (step) {
        step.values.push({ box: 'completion', value: e.intent });
      }

      this.$store.commit('formsEditor/selectPath', {
        box: step.box,
        intent: e.intent,
      });
      this.updateRenderedSteps();
      this.updateAnswer();
    },
    removeMultiStep(e) {
      const step = this.formSteps.find((step) => {
        return step.box === e.box;
      });

      if (step) {
        const valueIndex = step.values.findIndex((v) => {
          return v.value === e.intent;
        });

        if (valueIndex > -1) {
          step.values.splice(valueIndex, 1);
        }
      }

      this.updateRenderedSteps();
      this.updateAnswer();
    },
    getSelectedValue(step) {
      const selectedValue = this.getSelectedPath[step.box];

      const obj = step.values.find((s) => {
        return s.value === selectedValue;
      });

      if (obj) {
        return obj;
      }
      return { box: '' };
    },
    getNextStepSelect(step) {
      const values = step.values.map((v) => {
        const box = this.content.childBoxes.find((c) => {
          return c.box === v.box;
        });
        let displayName = box ? box.displayName : 'NO DISPLAY NAME';
        if (v.box === 'completion') {
          displayName = this.$t('botFormEdit.finishForm');
        }
        return { box: v.box, displayName: displayName };
      });

      let otherSteps = this.content.childBoxes.map((c) => {
        return { box: c.box, displayName: c.displayName };
      });

      /* let boxes = this.steps.map(s => {
              return s.box;
          }) */
      const _this = this;

      otherSteps = otherSteps.filter((s) => {
        const cycles = {};
        // no circle check
        function nextCheck(nstep) {
          if (nstep === 'completion') {
            return false;
          }

          if (nstep === step.box) {
            return true;
          }
          const sbox = _this.content.childBoxes.find((c) => {
            return c.box === nstep;
          });

          if (!sbox) {
            return false;
          }

          if (cycles[sbox.box]) {
            return false;
          } else {
            cycles[sbox.box] = true;
          }

          if (sbox.values) {
            return !!sbox.values.find((v) => {
              return nextCheck(v.box);
            });
          } else {
            return nextCheck(sbox.next);
          }
        }

        return !nextCheck(s.box);
      });

      return values.concat(otherSteps).concat({
        box: 'completion',
        displayName: this.$t('botFormEdit.finishForm'),
      });
    },
    async resetContent() {
      this.$store.commit('formsEditor/resetPath');
      await this.$store.dispatch('content/resetDraft', {
        botId: this.currentBotId,
        name: this.intent,
        channel: this.channel,
      });
      this.updateRenderedSteps();
    },
    save() {
      this.$store.commit('formsEditor/setEventTracking', { isAvailable: true });
      this.$emit('onSave');
    },
    // async trySave() {
    //   this.save();
    // },
    // async save() {
    //   this.saving = true;
    //   await this.$store.dispatch('content/save', {
    //     botId: this.currentBotId,
    //     name: this.intent,
    //     channel: this.channel,
    //   });
    //   this.saving = false;
    //   this.boxName = await this.$store.dispatch('glimpseContent/getContent', {
    //     botId: this.currentBotId,
    //     intent: this.intent,
    //     template: 'BotForm',
    //     editable: false,
    //     preloading: true,
    //     channel: this.channel,
    //   });
    //   await this.$store.dispatch('content/update', {});
    //   this.updateRenderedSteps();
    // },
    stepNames(template) {
      const type = this.formChildTypes.find((s) => {
        return s.template === template;
      });

      if (type) {
        return type;
      } else {
        return { name: '', description: '' };
      }
    },
    isBoxUsedMultiple(step) {
      const selectedBox = this.getSelectedValue(step);

      let count = 0;
      this.content.childBoxes.forEach((c) => {
        if (c.values) {
          c.values.forEach((v) => {
            if (v.box === selectedBox.box) {
              count++;
            }
          });
        } else if (c.next === selectedBox.box) {
          count++;
        }
      });

      return count > 1;
    },
    async addStep() {
      const current = this.stepAfter;
      this.$refs.form.validate();

      if (this.createDialogValid) {
        this.createDialog = false;

        let nextBox = 'completion';
        const box = await this.$store.dispatch('content/createBox', {
          botId: this.currentBotId,
          intent: this.intent,
          template: this.newType,
          channel: this.channel,
        });
        const newBox = {
          box,
          template: this.newType,
          displayName: this.newName,
        };

        // 1. wird am Anfang eingefügt
        if (!current) {
          // setze start box
          this.content.start.next = `${newBox.box}`;
        } else if (
          current.template === 'BotFormMultipleChoice' ||
          current.template === 'BotFormMultipleChoiceSlider' ||
          current.template === 'BotFormYesNo' ||
          current.template === 'BotFormConditionalSwitch'
        ) {
          const value = current.values.find((v) => {
            return (
              v.value ===
              this.$store.getters['formsEditor/getSelectedPath'](current.box)
            ); // this.selectedPath[current.box];
          });

          if (value) {
            nextBox = value.box;
            value.box = newBox.box;
          }
        } else {
          nextBox = current.next;
          current.next = newBox.box;
        }

        // create new box
        if (
          newBox.template === 'BotFormMultipleChoice' ||
          newBox.template === 'BotFormMultipleChoiceSlider' ||
          newBox.template === 'BotFormYesNo' ||
          newBox.template === 'BotFormConditionalSwitch'
        ) {
          // get value from content
          const newContent = this.$store.getters['content/getContent'](
            this.currentBotId,
            this.intent,
            box,
            true,
            this.channel
          );

          if (nextBox.startsWith('completion')) {
            nextBox = 'completion';
          }

          newBox.values = [];
          if (
            newContent &&
            newContent.question &&
            newContent.question.actions
          ) {
            const contentActions = newContent.question.actions.filter((a) => {
              return a.content;
            });

            // create new values from quick replies only if type is multiple choice
            if (newBox.template !== 'BotFormMultipleChoiceSlider') {
              for (const qr of contentActions[contentActions.length - 1].content
                .quick) {
                newBox.values.push({ box: nextBox, value: qr.intent });
              }
            } else {
              // create new values from card button only if type is multiple choice slider
              for (const card of contentActions[contentActions.length - 1]
                .content.data) {
                newBox.values.push({
                  box: nextBox,
                  value: card.buttons[0].payload.name,
                });
              }
            }
          } else if (newBox.template === 'BotFormConditionalSwitch') {
            newBox.values.push({ box: nextBox, value: 'qr0' });
          }
        } else {
          newBox.next = nextBox;
        }

        this.content.childBoxes.push(newBox);
        this.stepAfter = null;

        this.$refs.form.reset();
        this.updateAnswer();
        this.updateRenderedSteps();
      }
    },
    async addRatingCommentStep() {
      const current = this.stepAfter;
      const template = 'BotFormFreeText';

      const box = await this.$store.dispatch('content/createBox', {
        botId: this.currentBotId,
        intent: this.intent,
        template,
        channel: this.channel,
      });
      const newBox = {
        box,
        template,
        displayName: this.$t('botFormEdit.ratingComment'),
        next: 'completion',
      };

      const newContent = this.$store.getters['content/getContent'](
        this.currentBotId,
        this.intent,
        box,
        true,
        this.channel
      );

      newContent.context = 'rating_comment';

      for (let value of current.values) {
        value.box = newBox.box;
      }

      this.content.childBoxes.push(newBox);
      this.stepAfter = null;

      this.updateAnswer();
      this.updateRenderedSteps();
    },
    async edit(ed) {
      await this.$store.dispatch('content/edit', ed);
      this.editable = ed;

      if (ed) {
        this.performance = this.intentType.value;
        this.$store.dispatch('content/copyOrigin', {
          botId: this.currentBotId,
          name: this.intent.intent,
          channel: this.channel,
        });
      }
    },
    updateEditable(ed) {
      if (ed !== this.editability) {
        this.editability = ed;
      }
    },
    updateAnswer: _.debounce(
      async function () {
        this.$store.dispatch('content/update', {
          botId: this.currentBotId,
          name: this.content.intent,
          channel: this.channel,
        });
      },
      1000,
      { leading: true }
    ),
    onloaded() {
      this.$emit('onLoaded', {
        content: this.content,
        noChannelContent: this.noChannelConten,
      });
    },
    channelLanguage(languages) {
      if (Array.isArray(languages) && languages.length > 0) {
        switch (languages[0]) {
          case 'de':
            return this.$t('common.langGerman');
          case 'en':
            return this.$t('common.langEnglish');
        }
      }
      return 'n.a.';
    },
    channelTypeDescription(type) {
      switch (type) {
        case 'widget':
          return 'Website';
        case 'facebook':
          return 'Facebook Messenger';
      }

      return '';
    },
    onSaveButtonIntersect(entries) {
      if (Array.isArray(entries) && entries.length > 0) {
        this.hideFloatingSaveButton = entries[0].isIntersecting;
      }
    },
    selectWebhook(webhookName) {
      // delete existing
      const actionIndex = this.content.completion?.actions.findIndex(
        (a) => a.name === 'webhook'
      );
      this.content.completion.actions.splice(actionIndex, 1);

      const webhookAction = {
        name: 'webhook',
        parameters: {
          type: webhookName,
        },
      };

      this.content.completion?.actions?.unshift(webhookAction);
      this.updateAnswer();
    },
    async checkEventTracking() {
      if (this.content?.template === 'BotFormRating') {
        return;
      }
      const name = `form-tracking-${this.intent}`;

      if (this.isEventTrackingAvailable) {
        this.$store.commit('notifications/removeNotification', name);
        return;
      }

      if (!this.isEventTrackingAvailable && !this.draft) {
        this.$store.dispatch('notifications/addNotification', {
          name,
          messageType: messageTypes.PERFORMANCE_TRACKING,
          styleType: styleTypes.WARNING,
          routeFullPath: `/forms/${this.intent}`,
          action: () => {
            this.save();
          },
        });
        return;
      }
      if (!this.isEventTrackingAvailable && this.isAdmin && this.draft) {
        this.$store.dispatch('notifications/addNotification', {
          name,
          messageType: messageTypes.PERFORMANCE_TRACKING_DRAFT,
          styleType: styleTypes.WARNING,
          routeFullPath: `/forms/${this.intent}`,
        });
        return;
      }
    },
    changeCurrentCompletionIntent(intent) {
      // not available for multiple choice types
      const lastStep = this.formSteps[this.formSteps.length - 1];
      if (!lastStep.next) {
        return;
      }

      if (intent === 'none') {
        lastStep.next = 'completion';
      } else {
        lastStep.next = `completion({"intent": "${intent}"})`;
      }

      this.updateAnswer();
    },
    changeCompletionMode(mode) {
      if (mode) {
        this.changeCurrentCompletionIntent(this.otherIntents[0].intent);
      } else {
        this.changeCurrentCompletionIntent('none');
      }
    },
    /**
     * Prepares the moinAI Livechat groups for the UI
     */
    populateLivechatGroups() {
      this.toMoinLivechatGroups = [];
      if (!this.groups?.length) return;

      // If we have anything previously selected in content, load that
      const isUsingMoinAI = this.content?.livechatProvider?.type === 'moin_livechat';
      const hasGroupsSet = !!this.content?.livechatProvider?.payload?.groups?.length;

      if (isUsingMoinAI && hasGroupsSet) {
        this.toMoinLivechatGroups = this.content.livechatProvider.payload.groups;
      }

      this.validateLivechatPayloads();
    }
  },
  watch: {
    channel() {
      this.boxName = null;
      this.fetchContent().then(this.populateLivechatGroups);
    },
    'options.box'() {
      this.boxName = this.options.box;
    },
    isEventTrackingAvailable() {
      this.checkEventTracking();
    },
    draft() {
      this.checkEventTracking();
    },
  },
  async created() {
    if (this.options.box) {
      this.boxName = this.options.box;
    }

    if (
      this.$store.getters['content/isContentChanged'](
        this.currentBotId,
        this.intent,
        this.channel
      ) === false
    ) {
      this.$store.dispatch('content/resetDraft', {
        botId: this.currentBotId,
        name: this.intent,
        channel: this.channel,
      });
    }

    await this.fetchContent();
    this.checkEventTracking();
    await this.init(this.currentBotId);

    this.populateLivechatGroups();
  },
  beforeDestroy() {
    // reset queue after leaving
    if (this.isSyncing) {
      this.$store.dispatch('contentQueue/resetQueue');
    }
  },
};
</script>
<style scoped scss>
.fade-enter-active {
  transition: all 1s ease;
}
.fade-enter {
  opacity: 0;
}

.group-list-item {
  padding: .6rem 1rem;
  margin: .2rem .2rem .2rem 0;
  border-radius: 4rem;
  background: #e1e1e1;
}
.group-list-item.deletedGroup {
  background-color: rgba(201, 14, 14, 0.2);
  color: #c90e0e;
}
</style>

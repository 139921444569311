<template>
  <v-app>
    <HubMenu />

    <Appbar></Appbar>

    <v-main>
      <v-dialog v-model="addVariantDialog" v-if="isAdmin" max-width="800px">
        <VariantDialog
          v-if="addVariantDialog"
          :text="selectedTexts"
          @onClose="addVariantDialog = false"
        ></VariantDialog>
      </v-dialog>
      <v-dialog
        :fullscreen="$vuetify.breakpoint.mobile"
        v-model="dreamingDialog"
        max-width="500px"
        persistent
      >
        <v-card>
          <v-progress-linear
            v-if="dreaming"
            indeterminate
            color="secondary darken-2"
          ></v-progress-linear>
          <v-card-title class="text-h5 pt-6" style="word-break: break-word">
            Dreaming
          </v-card-title>
          <v-card-text v-if="result.length > 500">
            Es können aus maximal 400 Anfragen Themen gebildet werden.
          </v-card-text>
          <v-card-text v-if="result.length <= 500">
            <v-slider
              v-model="dreamingCluster"
              :disabled="dreaming"
              class="align-center"
              :max="Math.min(150, result.length - 1)"
              :min="Math.min(10, result.length - 1)"
              label="Anzahl der neuen Themen"
              hide-details
            >
              <template v-slot:append>
                <v-text-field
                  :disabled="dreaming"
                  v-model="dreamingCluster"
                  class="mt-0 pt-0"
                  hide-details
                  single-line
                  type="number"
                  style="width: 60px"
                ></v-text-field>
              </template>
            </v-slider>
          </v-card-text>
          <v-divider class="mt-2"></v-divider>
          <v-card-actions
            class="flex-column flex-sm-row justify-space-between align-stretch"
          >
            <v-btn
              :disabled="dreaming"
              @click="dreamingDialog = false"
              text
              class="px-4 grey--text d-block d-sm-inline-block my-4 my-sm-0"
            >
              {{ $t('common.cancel') }}
              <v-icon class="ml-2 grey--text">cancel</v-icon>
            </v-btn>
            <v-btn
              :disabled="result.length > 500 || dreaming"
              @click="cluster"
              class="px-4 white--text ml-0 ml-sm-2 d-block d-sm-inline-block"
              elevation="0"
              color="primary"
            >
              Start
              <v-icon class="ml-2">scatter_plot</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        :fullscreen="$vuetify.breakpoint.mobile"
        v-model="aiFeedback"
        max-width="480px"
        scrollable
      >
        <v-card>
          <v-card-title>
            Feedback
            <v-spacer></v-spacer>
            <v-btn icon @click="aiFeedback = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-card-title>
          <AiFeedback
            embedded
            :key="jumpto"
            :text="currentText"
            @close="aiFeedback = false"
          ></AiFeedback>
        </v-card>
      </v-dialog>

      <v-dialog
        :fullscreen="$vuetify.breakpoint.mobile"
        v-model="dialog"
        max-width="480px"
        scrollable
      >
        <v-card>
          <v-card-title>
            {{ $t('conversation.singletitle') }}
            <v-btn icon link :to="'/chats/messaging/' + uniqueUserId">
              <v-icon small>open_in_new</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn icon @click="dialog = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-card-title>
          <ChatHistory
            :key="jumpto"
            :uniqueUserId="uniqueUserId"
            :jumpto="jumpto"
            embedded
          ></ChatHistory>
        </v-card>
      </v-dialog>
      <v-card flat>
        <v-card-title class="text-h5">
          {{ $t('conversation.insgights.title') }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="2">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-chip v-on="on">
                    {{ formatDate(from) }} - {{ formatDate(to) }}
                  </v-chip>
                  <!--  <v-btn v-on="on" text icon color="secondary"><v-icon>event</v-icon></v-btn>
                         <a @click="menu = true">
                  {{formatDate(from)}} - {{formatDate(to)}}</a>-->
                </template>
                <v-date-picker
                  @input="changeDates"
                  :value="selectedDateRange"
                  no-title
                  range
                  color="secondary darken-2"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    {{ $t('common.cancel') }}
                  </v-btn>
                  <v-btn text color="primary" @click="newDateRange">
                    {{ $t('common.ok') }}
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>

            <v-col
              align-self="center"
              v-if="isAdmin && selectedRows.length >= 1"
            >
              <v-btn
                small
                color="admin"
                class="mr-2"
                outlined
                @click="toggleMultipleTranslations"
              >
                <v-icon left>translate</v-icon>
                Auswahl übersetzen
              </v-btn>
            </v-col>

            <v-col cols="12" md="7">
              <v-chip :disabled="unknowns" @click="toggleFilter()">
                <v-avatar left>
                  <v-icon small>{{ feedbackFilter }}</v-icon>
                </v-avatar>
                Feedback
              </v-chip>

              <v-chip
                class="ml-2"
                :disabled="unknowns"
                v-if="selectedIntent"
                close
                @click:close="deleteIntent()"
              >
                <v-avatar left>
                  <v-icon small>mdi-folder-text</v-icon>
                </v-avatar>
                {{ selectedIntent }}
              </v-chip>

              <v-chip v-if="isAdmin" class="ml-2" color="admin lighten-1" @click="toggleUnknowns()">
                <v-avatar left>
                  <v-icon small v-if="!unknowns">
                    check_box_outline_blank
                  </v-icon>
                  <v-icon small v-if="unknowns">check_box</v-icon>
                </v-avatar>
                Nicht Verstandene Anfragen
              </v-chip>
            </v-col>

            <v-col
              cols="12"
              md="3"
              v-if="currentChannels && currentChannels.length > 1"
            >
              <v-select
                hide-details
                :value="channel"
                menu-props="auto"
                :label="$t('channels.filter')"
                append-icon="call_split"
                :placeholder="$t('channels.select-all')"
                :items="currentChannels"
                item-text="displayName"
                item-value="channelId"
                @change="changeChannel"
                :clearable="!areChannelsBlocked"
                outlined
                item-disabled="blocked"
              >
                <template v-slot:item="{ item }">
                  <v-icon color="primary lighten-2" class="mr-2 mt-1">
                    {{ channelIcon(item) }}
                  </v-icon>
                  {{ item.displayName }}
                </template>
              </v-select>
            </v-col>
            <v-col>
              <v-btn
                v-if="unknowns"
                @click="dreamingDialog = true"
                color="primary"
                class="mx-2 px-2 white--text font-weight-bold"
              >
                <span class="hidden-xs-only pl-2">Dreaming</span>
                <v-icon class="pl-0 mx-2">scatter_plot</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <!--  <v-card-text><v-chip>Feedback</v-chip></v-card-text> -->
        <v-fab-transition>
          <v-btn
            v-if="isAdmin && selectedTexts.length > 0"
            color="primary lighten-2"
            dark
            bottom
            fixed
            right
            @click="addVariantDialog = true"
            fab
          >
            <v-icon>add_comment</v-icon>
          </v-btn>
        </v-fab-transition>

        <v-data-table
          :headers="headers"
          :items="result"
          :show-select="isAdmin"
          item-key="uuid"
          :items-per-page="initialItemsPerPage"
          :footer-props="footerPropsItemsPerPage"
          :group-by="groupBy"
          :no-data-text="$t('conversation.filter.nodata')"
          :loading="dataLoading"
          loading-text
          v-model="selectedRows"
        >
          <template #[`item.actions`]="{ item }">
            <v-row>
              <v-btn
                icon
                @click="
                  uniqueUserId = item.uniqueUserId;
                  jumpto = item.uuid;
                  dialog = true;
                "
              >
                <v-icon small class="text-decoration-none">message</v-icon>
              </v-btn>
              <v-btn
                v-if="item.text || item.forward"
                icon
                @click="
                  jumpto = item.uuid;
                  currentText = item.text || item.messageText;
                  aiFeedback = true;
                "
              >
                <v-icon small class="text-decoration-none">school</v-icon>
              </v-btn>
            </v-row>
          </template>
          <template #[`group.header`]="{ group, toggle }">
            <v-card flat>
              <v-card-title>
                <span v-if="group == 1000">Sonstige</span>
                <v-avatar
                  class="pt-1"
                  v-if="group !== 1000"
                  color="primary"
                  size="36"
                >
                  <span class="white--text text-h5">
                    {{ parseInt(group) + 1 }}
                  </span>
                </v-avatar>
              </v-card-title>
            </v-card>
          </template>
          <template #[`item.intent`]="{ item }">
            <v-chip small @click="filterIntent(item.intent)">
              {{ displayName(item.intent, $vuetify.lang.current) }}
            </v-chip>
          </template>
          <template #[`item.date`]="{ item }">
            <span class="text-caption">{{ formatDate(item.date) }}</span>
          </template>

          <template #[`item.text`]="{ item }">
            <div class="d-inline-flex align-center">
              <v-tooltip bottom v-if="isAdmin && (item.text || item.forward)">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    :class="{
                      'secondary--text text--darken-2':
                        translations[item._id] && translations[item._id].isOpen,
                    }"
                    @click="
                      toggleTranslation(item._id, item.text || item.messageText)
                    "
                  >
                    <v-icon small v-bind="attrs" v-on="on">
                      mdi-translate
                    </v-icon>
                  </v-btn>
                </template>
                <span>Übersetzen</span>
              </v-tooltip>

              <div>
                <v-chip class="mr-2" v-if="item.forward" outlined label small>
                  {{ $t('common.forwarded') }}
                </v-chip>
                <span
                  v-if="
                    !translations[item._id] ||
                      (translations[item._id] && !translations[item._id].isOpen)
                  "
                >
                  {{ item.text }}
                </span>
                <v-progress-circular
                  v-if="
                    translations[item._id] && translations[item._id].isLoading
                  "
                  indeterminate
                  size="12"
                  width="2"
                  color="grey"
                  class="mx-2"
                />
                <span
                  class="secondary--text text--darken-2"
                  v-if="translations[item._id] && translations[item._id].isOpen"
                >
                  {{ translations[item._id].text }}
                </span>
              </div>
            </div>

            <span
              v-if="
                item.forward &&
                  (!translations[item._id] ||
                    (translations[item._id] && !translations[item._id].isOpen))
              "
              v-text="item.messageText"
              class="text-caption"
            ></span>
            <v-chip outlined label small v-if="!item.text && !item.forward">
              Button
            </v-chip>
          </template>
        </v-data-table>
      </v-card>
    </v-main>
  </v-app>
</template>

<script>
import HubMenu from '@/components/hub/HubMenu.vue';
import Appbar from '@/components/Appbar.vue';
import ChatHistory from '@/views/ChatHistory.vue';
import AiFeedback from '@/components/AiFeedback.vue';
import NumbersUtil from '../utils/numbers';
import VariantDialog from '@/components/ai/VariantDialog.vue';

import deepEqual from 'fast-deep-equal/es6';
import moment from 'moment';

import { mapGetters } from 'vuex';
import DialogService from '../services/dialogs';

import ProductService from '../services/product';
import TranslationService from '@/services/translation';
import {
  initialItemsPerPage,
  footerPropsItemsPerPage,
} from '@/utils/dataTable';
import { TrackingService } from '@/services/tracking';

export default {
  name: 'Conversations',
  props: ['from', 'to', 'filter', 'intent', 'channel'],
  components: {
    HubMenu,
    Appbar,
    ChatHistory,
    AiFeedback,
    VariantDialog,
  },
  async created() {
    if (this.areChannelsBlocked) {
      this.channel = this.currentChannels.filter(
        (channel) => !channel.blocked
      )[0].channelId;
    }

    this.refreshData();
    this.trackIt();
  },
  computed: {
    ...mapGetters('bots', [
      'currentBot',
      'viewableBotId',
      'currentChannels',
      'areChannelsBlocked',
    ]),
    ...mapGetters('auth', ['isAdmin']),
    ...mapGetters('intents', [
      'intents',
      'bookedIntents',
      'suggested',
      'displayName',
    ]),
    selectedDateRange() {
      if (this.newDates) {
        return this.newDates;
      }

      return [this.from, this.to];
    },
    selectedIntent() {
      if (this.intent && this.intents) {
        let found = this.intents.find((i) => i.intent === this.intent);

        if (found) {
          return this.displayName(this.intent, this.$vuetify.lang.current);
        } else {
          return this.intent;
        }
      } else {
        return false;
      }
    },
    selectedTexts() {
      return this.selectedRows
        .filter((row) => row.text || row.messageText)
        .map((row) => row.text || row.messageText);
    },
    selectedChannel() {
      if (this.channel) {
        return this.channel;
      } else {
        return false;
      }
    },
    feedbackFilter() {
      if (this.filter === 'feedback-pos') {
        return 'thumb_up';
      } else if (this.filter === 'feedback-neg') {
        return 'thumb_down';
      } else {
        return 'thumbs_up_down';
      }
    },
    headers() {
      let dateHeader = this.$t('common.dateName');
      let topicHeader = this.$t('intents.topicName');
      let requestHeader = this.$t('conversation.request');
      let headers = [
        { text: dateHeader, value: 'date', width: '120px', sortable: true },
        {
          text: topicHeader,
          value: 'intent',
          width: '200px',
        },
        {
          text: requestHeader,
          value: 'text',
          sortable: false,
        },
        { text: '', value: 'actions', sortable: false, width: '100px' },
      ];

      if (this.intent || this.unknowns) {
        headers = headers.filter((i) => i.value !== 'intent');
      }

      return headers;
    },
  },
  data() {
    return {
      menu: null,
      dialog: false,
      unknowns: false,
      uniqueUserId: false,
      currentText: null,
      jumpto: false,
      result: [],
      groupBy: null,
      dataLoading: false,
      aiFeedback: false,
      dreaming: false,
      dreamingDialog: false,
      dreamingCluster: 75,
      addVariantDialog: false,
      selectedRows: [],
      newDates: false,
      translations: {},
      initialItemsPerPage,
      footerPropsItemsPerPage,
    };
  },
  methods: {
    cluster: async function() {
      this.dreaming = true;
      this.groupBy = null;
      let count = new Set();
      let texts = this.result
        .filter((r) => r.text && r.text.trim().length > 1)
        .map((r) => {
          count.add(r.intent);
          return r.text;
        });
      let clusters = await ProductService.nlpCluster(
        this.currentBot.uniqueBotId,
        texts,
        this.dreamingCluster /* count.size */ /* Math.floor(texts.length / 5) */
      );
      if (clusters.error) {
        this.dreaming = false;
      } else {
        this.result.forEach((r) => {
          let cluster = clusters.body.find(
            (f) => f.text && r.text && f.text === r.text
          );
          r.cluster = cluster ? cluster.name : 500;
        });

        this.groupBy = 'cluster';
        this.dreaming = false;
        this.dreamingDialog = false;
      }
    },
    channelIcon: function(channel) {
      switch (channel.channel) {
        case 'widget':
          return 'mdi-comment-processing-outline';
        case 'facebook':
          return 'mdi-facebook-messenger';
      }

      return '';
    },
    filterIntent(intent) {
      let newQuery = { query: { ...this.$route.query } };
      newQuery.query.intent = intent;

      this.result = [];
      this.$router.replace(newQuery);
    },
    deleteIntent() {
      let newQuery = { query: { ...this.$route.query } };
      delete newQuery.query.intent;

      this.result = [];
      this.$router.replace(newQuery);
    },
    changeChannel(channel) {
      let newQuery = { query: { ...this.$route.query } };

      if (channel) {
        newQuery.query.channel = channel;
      } else {
        delete newQuery.query.channel;
      }

      this.result = [];
      this.$router.replace(newQuery);
    },
    toggleFilter() {
      let newQuery = { query: { ...this.$route.query } };
      if (this.filter === 'feedback-pos') {
        newQuery.query.filter = 'feedback-neg';
      } else if (this.filter === 'feedback-neg') {
        newQuery.query.filter = '';
      } else {
        newQuery.query.filter = 'feedback-pos';
      }

      this.result = [];
      this.$router.replace(newQuery);
    },
    toggleUnknowns() {
      this.unknowns = !this.unknowns;
      this.refreshData();
    },
    async refreshData() {
      this.groupBy = null;

      if (
        !this.from ||
        !this.to ||
        !moment(this.from).isValid() ||
        !moment(this.to).isValid() ||
        new Date(this.to) - new Date(this.from) < 0
      ) {
        let newQuery = { query: { ...this.$route.query } };

        newQuery.query.from = moment()
          .startOf('day')
          .add(-7, 'days')
          .format('YYYY-MM-DD');
        newQuery.query.to = moment()
          .startOf('day')
          .format('YYYY-MM-DD');

        this.result = [];
        this.$router.replace(newQuery);
      } else {
        let to = moment(this.to).format('YYYY-MM-DD');

        this.dataLoading = true;
        const dialogs = await DialogService.getFilteredDialogs(
          this.viewableBotId,
          this.from,
          to,
          this.intent,
          this.filter,
          this.channel,
          this.unknowns
        );
        this.result = dialogs.dialogs;
        this.dreamingCluster = Math.min(75, this.result.length - 1);
        this.dataLoading = false;
      }
    },
    formatDate(date) {
      if (!date) {
        return '';
      }

      return NumbersUtil.formatDate(this, date, true);
    },
    newDateRange: function() {
      if (this.newDates === false) {
        return;
      }

      if (this.newDates.length < 2) {
        this.newDates.push(this.newDates[0]);
      }

      if (new Date(this.newDates[1]) - new Date(this.newDates[0]) < 0) {
        this.newDates.reverse();
      }

      let newQuery = { query: { ...this.$route.query } };
      newQuery.query.from = this.newDates[0];
      newQuery.query.to = this.newDates[1];

      this.result = [];
      this.$router.replace(newQuery);

      this.menu = false;
      this.newDates = false;
    },
    changeDates: function(e) {
      this.newDates = e;
    },
    toggleMultipleTranslations() {
      const translatableRows = this.selectedRows.filter((row) => row.text);
      translatableRows.forEach((row) => {
        this.toggleTranslation(row._id, row.text);
      });
    },
    toggleTranslation(id, text) {
      if (!this.translations[id] || !this.translations[id].text) {
        this.loadTranslation(id, text);
        return;
      }
      this.translations[id].isOpen = !this.translations[id].isOpen;
      this.translations = { ...this.translations };
      return;
    },
    async loadTranslation(id, text) {
      this.translations[id] = {
        isLoading: true,
      };
      const res = await TranslationService.getTranslation(
        this.currentBot.uniqueBotId,
        text
      );

      this.translations = {
        ...this.translations,
        [id]: {
          isOpen: true,
          text: res.translation?.text || 'Fehler bei der Übersetzung',
        },
      };
    },
    trackIt() {
      const body = {
        botId: this.viewableBotId,
        type: 'stats-conversations-view',
        source: 'Conversations.vue',
      };
      TrackingService.trackStatsView(body);
    },
  },
  watch: {
    $route() {
      this.refreshData();
    },
    viewableBotId: function() {
      let newQuery = { query: { ...this.$route.query } };
      delete newQuery.query.intent;
      delete newQuery.query.channel;

      this.result = [];

      if (!deepEqual(this.$route.query, newQuery.query)) {
        this.$router.replace(newQuery);
      } else {
        this.refreshData();
      }
    },
  },
};
</script>

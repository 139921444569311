<template>
  <div class="knw_typingIndicator">
    <div id="wave">
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TypingIndicator',
};
</script>
<style lang="scss" scoped>
.knw_typingIndicator {
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background: transparent;
  padding: 12px 10px 2px 0px;
}
.dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 3px;
  background: #999999;
  animation: wave 1.3s linear infinite;
  &:nth-child(2) {
    animation-delay: -1.1s;
  }
  &:nth-child(3) {
    animation-delay: -0.9s;
  }
}
@keyframes wave {
  0%,
  60%,
  100% {
    transform: initial;
  }
  30% {
    transform: translateY(-9px);
  }
}
</style>

<template>
  <v-fade-transition>
    <v-list-item v-if="contact">
      <v-list-item-avatar :size="avatarSize" class="mr-4">
        <v-img :src="contact.profileImage" />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title v-if="title" class="primary--text text--lighten-2 font-weight-bold mb-2" v-html="title"></v-list-item-title>
        <v-list-item-title class="primary--text">{{ contact.name }}</v-list-item-title>
        <v-list-item-title v-if="large" class="primary--text">
          <a :href="'mailto:' + contact.eMail">{{ contact.eMail }}</a>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-fade-transition>
</template>

<script>
import ProductService from '@/services/product'

export default {
  name: 'ContactItem',
  props: {
    large: {
      type: Boolean,
      default: false,
    },
    contactId: {
      type: String,
      default: null,
    },
    contactObj: {
      type: Object,
      default: null,
    },
    title: {
      type: String,
      default: null,
    }
  },
  data() {
    return {
      contact: null,
    }
  },
  computed: {
    avatarSize() {
      if (this.large) return 80
      return 40
    },
  },
  created() {
    if (this.contactObj) {
      this.contact = this.contactObj
    } else {
      ProductService.getContact(this.contactId).then(contact => {
        this.contact = contact
      })
    }
  },
}
</script>

<style lang="scss" scoped>
</style>

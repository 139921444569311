import ApiService from './api';

function getLocalToken() {
  return localStorage.getItem('botcast_mobile_token');
}

function getApiUrl() {
  return process.env.VUE_APP_LIVECHAT_API_URL;
}

/**
 * @typedef {Object} Agent
 * @property {string} uuid
 * @property {string} name
 * @property {string} email
 * @property {string} profilePicture
 * @property {string} organizationUuid
 * @property {string} createdAt
 * @property {string} updatedAt
 * @property {string[]} groups
 * @property {object} hubSettings
 */

/**
 * @typedef {Object} Group
 * @property {string} uuid
 * @property {string} name
 * @property {string} description
 * @property {string} channelId
 */

/**
 * @typedef {Object} Organization
 * @property {string} uuid
 * @property {string} uniqueBotId
 * @property {string} maxNumberAgents
 * @property {string} stagingBotId
 * @property {string} productionBotId
 * @property {Agent[]} agents
 * @property {Group[]} groups
 */

const LivechatService = {

  async agentFromToken() {
    // documentation: Create a new agent
    // TODO this method is the same as createAgent, merge them
    const requestData = {
      method: 'post',
      url: `${getApiUrl()}agent`,
      data: {
        token: getLocalToken(),
      },
    };
    try {
      const response = await ApiService.customRequest(requestData);
      return response.data;
    } catch (error) {
      return { error };
    }
  },

  async agentFromUuid(agentUuid) {
    const requestData = {
      method: 'get',
      url: `${getApiUrl()}agent/${agentUuid}`
    };
    try {
      const response = await ApiService.customRequest(requestData);
      return response.data;
    } catch (error) {
      return { error };
    }
  },

  async agentExists() {
    // documentation: Check if a Hub user exits as a livechat_agent
    const requestData = {
      method: 'get',
      url: `${getApiUrl()}agent/exists`,
      data: {
        token: getLocalToken(),
      },
    };
    try {
      const response = await ApiService.customRequest(requestData);
      return response.data;
    } catch (error) {
      return { error };
    }
  },

  async getAgentByToken() {
    // documentation: Check if a Hub user exits as a livechat_agent
    const requestData = {
      method: 'get',
      url: `${getApiUrl()}agent/by-token`,
      data: {
        token: getLocalToken(),
      },
    };
    try {
      const response = await ApiService.customRequest(requestData);
      return response.data;
    } catch (error) {
      return { error };
    }
  },
  async getAgents(organizationUuid) {
    try {
      const response = await ApiService.get(
        `${getApiUrl()}organization/${organizationUuid}/agents`
      );
      return response.data;
    } catch (error) {
      return { error };
    }
  },
  async addAgent(agentUuid, organizationUuid) {
    try {
      const data = {
        agentUuid
      }
      const response = await ApiService.post(
        `${getApiUrl()}organization/${organizationUuid}/addAgent`, data
      );
      return response.data;
    } catch (error) {
      return { error };
    }
  },
  /**
   * 
   * @param {string} agentUuid 
   * @param {string} groupUuid 
   * @returns {Promise<object>} Promise<any> {error: string} or {data: object}
   */
  async removeAgentGroup(agentUuid, groupUuid) {
    try {
      const data = {
        groups: [groupUuid]
      };

      const response = await ApiService.delete(
        `${getApiUrl()}agent/${agentUuid}/groups`, data
      );
      return response.data;
    } catch (error) {
      return { error };
    }
  },
  /**
   * 
   * @param {string} agentUuid 
   * @param {string[]} groupUuids 
   * @returns {Promise<object>} Promise<any> {error: string} or {data: object}
   */
  async addAgentGroups(agentUuid, groupUuids) {
    try {
      const data = {
        groups: groupUuids
      };
      const response = await ApiService.post(
        `${getApiUrl()}agent/${agentUuid}/groups`, data
      );
      return response.data;
    } catch (error) {
      return { error };
    }
  },
  async getBotHasLivechat(uniqueBotId) {
    try {
      const response = await ApiService.get(
        `${getApiUrl()}organization/exists?uniqueBotId=${uniqueBotId}`
      );
      return response.data;
    } catch (error) {
      return { error };
    }
  },

  async updateAgent(agent) {
    const { uuid, name, profilePicture, hubSettings, title, languages, maxNumberOpenChats, isAgentSelf = false } = agent;
    const changeset = Object.assign({}, {name, profilePicture, hubSettings, title, languages, maxNumberOpenChats});

    const requestData = {
      method: 'put',
      url: `${getApiUrl()}agent/${uuid}${isAgentSelf ? '/edit-profile' : ''}`,
      data: {
        changeset,
      },
    };
    try {
      const response = await ApiService.customRequest(requestData);
      return response.data;
    } catch (error) {
      return { error };
    }
  },

  async createAgent() {
    try {
      const res = await ApiService.post(`${getApiUrl()}agent`, {
        token: getLocalToken(),
      });
      const agent = res.data || null;
      return agent;
    } catch (error) {
      return { error };
    }
  },

  async createAndAddAgent(email, organizationUuid, groups) {
    try {
      const res = await ApiService.post(`${getApiUrl()}agent/create-and-add`, {
        name: email,
        email,
        organizationUuid,
        groups,
      });
      const agent = res.data || null;
      return agent;
    } catch (error) {
      return { error };
    }
  },

  async deleteAgent(agentUuid) {
    try {
      const res = await ApiService.delete(`${getApiUrl()}agent/${agentUuid}`);
      return res.data;
    } catch (error) {
      return { error };
    }
  },

  async getAllNonAgentUsers(uniqueBotId) {
    try {
      const response = await ApiService.customRequest({
        method: 'get',
        url: `${getApiUrl()}management/non-agent-users`,
        params: {
          botId: uniqueBotId,
        },
      });
      return response.data;
    } catch (error) {
      return { error };
    }
  },

  async createOrganization({ uniqueBotId }) {
    try {
      const res = await ApiService.post(`${getApiUrl()}organization`, {
        uniqueBotId,
        maxNumberAgents: 10,
      });
      return res.data;
    } catch (error) {
      return { error };
    }
  },
  /**
   * 
   * @param {string} organizationUuid 
   * @param {Group} group 
   * @returns Promise<any> {error: string} or {data: object}
   */
  async addGroupToOrganization(organizationUuid, group) {
    try {
      const res = await ApiService.post(
        `${getApiUrl()}organization/${organizationUuid}/group`,
        group
      );
      return res.data;
    } catch (error) {
      return { error };
    }
  },

  /**
   * 
   * @param {string} organizationUuid 
   * @param {Group} group 
   * @returns Promise<any> {error: string} or {data: object}
   */
  async updateGroup(organizationUuid, group) {
    try {
      const res = await ApiService.post(
        `${getApiUrl()}organization/${organizationUuid}/group/${group.uuid}`,
        group
      );
      return res.data;
    } catch (error) {
      return { error };
    }
  },

  /**
   * Safely updates non-critical organization data.
   * 
   * @param {string} organizationUuid 
   * @param {Object} organizationData 
   * @returns {Promise<any>} {error: string} or {data: object}
   */
  async updateOrganizationLimited(organizationUuid, organizationData) {
    const { maxNumberAgents, overbookingCoefficient } = organizationData;
    try {
      const res = await ApiService.patch(
        `${getApiUrl()}organization/${organizationUuid}`,
        {
          maxNumberAgents,
          overbookingCoefficient,
        }
      );
      return {
        uuid: organizationUuid,
        overbookingCoefficient: res.data.overbookingCoefficient,
        maxNumberAgents: res.data.maxNumberAgents,
      };
    } catch (error) {
      return { error };
    }
  },

  async getOpenChats({ agentUuid }) {
    try {
      const response = await ApiService.customRequest({
        method: 'get',
        url: `${getApiUrl()}agent/${agentUuid}/chats/open`,
      });
      return response.data;
    } catch (error) {
      return { error };
    }
  },

  async getUnassignedChats({ uniqueBotId }) {
    try {
      const response = await ApiService.customRequest({
        method: 'get',
        url: `${getApiUrl()}chat/unassigned`,
        params: {
          uniqueBotId,
        },
      });
      return response.data;
    } catch (error) {
      return { error };
    }
  },

  async getClosedChats({ agentUuid, uniqueBotId }) {
    try {
      const response = await ApiService.customRequest({
        method: 'get',
        url: `${getApiUrl()}agent/${agentUuid}/chats/closed`,
        params: {
          uniqueBotId,
          limit: 50,
        },
      });
      return response.data;
    } catch (error) {
      return { error };
    }
  },

  async getAllChats({ agentUuid, uniqueBotId }) {
    try {
      const response = await ApiService.customRequest({
        method: 'get',
        url: `${getApiUrl()}agent/${agentUuid}/chats/all`,
        params: {
          uniqueBotId,
          limit: 50,
        },
      });
      return response.data;
    } catch (error) {
      return { error };
    }
  },

  async getAllChatsOrganization({ organizationUuid, status }, { limit } = { limit: 10 }) {
    const type = status || 'all'; // Chat statuses 

    try {
      const response = await ApiService.customRequest({
        method: 'get',
        url: `${getApiUrl()}organization/${organizationUuid}/chats/${type}`,
        params: {
          limit: Number(limit) || 10,
        },
      });
      return { success: true, data: response.data?.data };
    } catch (error) {
      return { success: false, error };
    }
  },

  async assignChat({ chatUuid, agentUuid, uniqueUserId }) {
    try {
      const response = await ApiService.customRequest({
        method: 'post',
        url: `${getApiUrl()}chat/${chatUuid}/assign`,
        data: {
          agentUuid,
          chatUuid,
          uniqueUserId,
        },
      });
      return response.data;
    } catch (error) {
      return { error };
    }
  },

  async closeChat({ chatUuid, uniqueUserId, isStaging }) {
    try {
      const response = await ApiService.customRequest({
        method: 'put',
        url: `${getApiUrl()}chat/${chatUuid}/status`,
        data: {
          status: 'closed',
          uniqueUserId,
          isStaging,
        },
      });
      return response.data;
    } catch (error) {
      return { error };
    }
  },

  async addBotSettings({ botId, defaultGroup }) {
    try {
      await ApiService.post(
        `/schaltzentrale/bot/settings/${botId}/moin_livechat`,
        {
          webhook: `${process.env.VUE_APP_BOT_URL}/incoming/moin-livechat`,
          initMessage:
            'Dies ist ein Chatbot Takeover. Conversation_id (ignore): {ctxval:moin_livechat_conversation_id} Bisherige Unterhaltung öffnen: {ctxval:link}',
          groups: [defaultGroup.uuid],
        }
      );
      return true;
    } catch (error) {
      return false;
    }
  },

  async getOrganizatioByBotId(uniqueBotId) {
    try {
      const response = await ApiService.get(
        `${getApiUrl()}organization/?uniqueBotId=${uniqueBotId}`
      );
      return response.data;
    } catch (error) {
      return { error };
    }
  },
  /**
   * 
   * @param {string} uuid 
   * @returns {Promise<object>} Promise<any> {error: string} or {data: object | null}
   */
  async getOrganizatioByUuid(uuid) {
    try {
      const response = await ApiService.get(
        `${getApiUrl()}organization/${uuid}`
      );
      return response.data;
    } catch (error) {
      return { error };
    }
  },

  /**
   * 
   * @param {string} organizationUuid 
   * @param {string | null} start - The start date.
   * @param {string | null} end - The end date.
   * @param {string | null} botId - The bot ID.
   * @returns 
   */
  async getStatsForOrganization(organizationUuid, start, end, botId) {
    const botIdQuery = botId ? `&botId=${botId}` : '';

    try {
      const response = await ApiService.get(
        `${getApiUrl()}organization/${organizationUuid}/stats?start=${start}&end=${end}${botIdQuery}`
      );
      return response.data;
    } catch (error) {
      return { error };
    }
  }
};

export default LivechatService;

import ApiService from './api';

const IntegrationService = {
  getAllTypes: async function(locale = 'de') {
    try {
      const response = await ApiService.get(
        `/schaltzentrale/cms/integrations/${locale}`
      );
      return response.data;
    } catch (e) {
      return [];
    }
  },
  postWebhookTest: async function(botId, body) {
    try {
      const response = await ApiService.post(
        `/schaltzentrale/integrations/webhook/${botId}/`,
        body
      );

      return response;
    } catch (e) {
      return false;
    }
  },
};

export default IntegrationService;

export { IntegrationService };

export const actionEditorEvents = {
  contentUpdated: 'contentUpdated',
  contentOverwritten: 'contentOverwritten',
  contentCopied: 'contentCopied',
  contentDeleted: 'contentDeleted',
  actionAdded: 'actionAdded',
  actionUpdated: 'actionUpdated',
  actionDeleted: 'actionDeleted',
  actionMoved: 'actionMoved',
  userInputChanged: 'userInputChanged',
  userInputLabelChanged: 'userInputLabelChanged',
  userInputOrderChanged: 'userInputOrderChanged',
  userInputAdded: 'userInputAdded',
  userInputDeleted: 'userInputDeleted',
  actionsSelected: 'actionsSelected',
  followupChanged: 'followupChanged',
  editorModeChanged: 'editorModeChanged',
  pathChanged: 'pathChanged',
  scrollTo: 'scrollTo',
  generateSampleAnswer: 'generateSampleAnswer',
  editorIsLoading: 'editorIsLoading',
  setPlaygroundQuestion: 'setPlaygroundQuestion',
};

import ApiService from './api';

const KeywordsService = {
  updateKeywords: async function({ botId, intentName, keywords }) {
    try {
      const body = {
        keywords,
        intent: intentName,
      };
      const response = await ApiService.put(
        `/schaltzentrale/keywords/${botId}/`,
        body
      );
      return response?.data;
    } catch (error) {
      return {};
    }
  },
  validateKeyword: async function({ botId, intentName, keyword }) {
    try {
      const body = {
        keyword: keyword,
        intent: intentName,
      };
      const response = await ApiService.post(
        `/schaltzentrale/keywords/${botId}/validate`,
        body
      );

      return response?.data;
    } catch (error) {
      return {};
    }
  },
};

export default KeywordsService;

<template>
  <v-app style="max-height: 100vh; height: 100vh">
    <HubMenu />

    <Appbar />
    <HubEditorFrame
      ref="formEditorScrollContainer"
      v-model="sidebar"
      :loading="isLoading"
      :draft="contentChanged"
      back="/forms"
      :channels="channels"
      :content="intentName"
      :title="displayName"
      :sidebar-items="[
        {
          title: $t('forms.steps'),
          name: 'steps',
          icon: 'list_alt',
          padlessItem: true,
        },
        {
          title: $t('forms.analysis.title'),
          name: 'insights',
          icon: 'mdi-chart-timeline-variant-shimmer',
        },
      ]"
      :sidebar-width="4"
      :disabled="!formValid"
      @onChannelChanged="channelChanged"
      @onReset="reset"
      @onSave="save"
    >
      <template v-slot="{ channelId, isLoading }">
        <div :key="intentName">
          <BotFormEdit
            :key="version"
            ref="BotFomEdit"
            :loading="isLoading"
            :intent="intentName"
            :channel="channelId"
            :options="{ template: 'BotForm' }"
            :draft="contentChanged"
            @setFormValid="formValid = $event"
            @onSave="save"
          />
        </div>
      </template>
      <template v-slot:back-button>
        <span>
          {{ $t('forms.title') }}
        </span>
      </template>

      <template v-slot:toolbar="{ disabled }">
        <v-tooltip>
          <template v-slot:activator="{ on: onTooltip, attrs: attrsTooltip }">
            <BtnSquare
              class="ml-4"
              :disabled="disabled"
              v-bind="attrsTooltip"
              icon="view_sidebar"
              @click="sidebar = !sidebar"
              v-on="onTooltip"
            />
          </template>
          <span>{{ $t('forms.steps') }}</span>
        </v-tooltip>
      </template>

      <template #[`sidebar.steps`]>
        <v-col cols="12" class="pa-0 ma-0">
          <div class="pa-6">
            <h2 class="headline primary--text mb-3">
              <v-icon left class="mr-2" color="primary lighten-3">
                list_alt
              </v-icon>
              <span>{{ $t('forms.steps') }}</span>
            </h2>
            <p class="text-body-2 primary--text">{{ $t('forms.stepsInfo') }}</p>

            <v-list dense two-line class="mx-0 mt-0 pa-0">
              <template v-for="step of formSteps">
                <div :key="step.box">
                  <v-list-item @click="goto(step)">
                    <v-list-item-icon>
                      <v-icon
                        v-if="step.template === 'BotFormFreeText'"
                        color="primary lighten-3"
                      >
                        mdi-text
                      </v-icon>
                      <v-icon
                        v-if="step.template === 'BotFormYesNo'"
                        class="rotate-180"
                        color="primary lighten-3"
                      >
                        mdi-call-split
                      </v-icon>
                      <v-icon
                        v-if="step.template === 'BotFormMultipleChoice' || step.template === 'BotFormConditionalSwitch'"
                        color="primary lighten-3"
                      >
                        mdi-format-list-bulleted-square
                      </v-icon>
                      <v-icon
                        v-if="step.template === 'BotFormMultipleChoiceSlider'"
                        color="primary lighten-3"
                      >
                        view_carousel
                      </v-icon>
                      <v-icon
                        v-if="step.template === 'BotFormEmail'"
                        color="primary lighten-3"
                      >
                        mdi-email
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content class="mt-0 pt-0">
                      <v-list-item-title class="pt-0 pb-1 font-weight-bold">
                        <span class="text-truncate">
                          {{ step.displayName }}
                        </span>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ stepNames(step.template).name }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-if="
                      step.template === 'BotFormYesNo' ||
                        step.template === 'BotFormMultipleChoice' ||
                        step.template === 'BotFormMultipleChoiceSlider' ||
                        step.template === 'BotFormConditionalSwitch'
                    "
                  >
                    <v-list-item-content class="pt-0">
                      <span class="d-inline-block mx-0 mt-0 mb-2">
                        <v-chip
                          @click.stop="changePath(step, answer)"
                          :outlined="!active(step, answer)"
                          color="primary lighten-2"
                          label
                          small
                          class="ml-2 mb-1"
                          v-for="(answer, index) in answers(step)"
                          :key="index"
                        >
                          {{ getLabel(answer.label, step).data }}
                        </v-chip>
                      </span>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider />
                </div>
              </template>
            </v-list>
          </div>
        </v-col>
      </template>
      <template #[`sidebar.insights`]>
<div>
  <BotFormsInsights :form="form" :channelId="getChannelIdByIndex(selectedChannelIndex)" />
</div>
      </template>
    </HubEditorFrame>
    <HubDialog
      v-model="draftDialog"
      :primary-label="$t('common.ok')"
      :secondary-label="$t('common.cancel')"
      modal
      :title="$t('editor.dialogs.hint.title')"
      @onSecondary="lostCancel"
      @onPrimary="lostOk"
    >
      {{ $t('editor.dialogs.hint.description') }}
      <v-list v-if="channels.length > 1" dense class="mt-2">
        <v-subheader>{{ $t('editor.dialogs.hint.channelchange') }}</v-subheader>
        <template v-for="channelChanged in channelContentChanged">
          <v-list-item
            v-if="channelChanged.changed"
            :key="channelChanged.channelId"
          >
            <v-list-item-icon>
              <v-icon small color="secondary darken-2">edit</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ channelChanged.name }}</v-list-item-title>
          </v-list-item>
        </template>
      </v-list>
    </HubDialog>
  </v-app>
</template>

<script>
import HubMenu from '@/components/hub/HubMenu.vue';
import Appbar from '@/components/Appbar.vue';
import BotFormEdit from '@/components/editor/BotFormEdit.vue';
import IntentUtil from '../utils/intents';
import { mapGetters } from 'vuex';
import _ from 'lodash';
import HubEditorFrame from '../components/hub/HubEditorFrame.vue';
import BotFormsInsights from '@/components/botForms/BotFormsInsights.vue';
import HubDialog from '@/components/hub/HubDialog.vue';
import BtnSquare from '@/components/common/BtnSquare.vue';

export default {
  name: 'BotForms',
  components: {
    HubMenu,
    Appbar,
    BotFormEdit,
    HubEditorFrame,
    BotFormsInsights,
    HubDialog,
    BtnSquare,
  },
  props: ['viewTab'],
  data() {
    return {
      trainingDataDialog: false,
      channelId: null,
      version: 0,
      trainingDataDialogProcessing: false,
      trainingDataText: null,
      followupValue: null,
      updateLoading: false,
      variantDialog: false,
      currentVariant: {},
      referencefilter: false,
      contentSaving: false,
      currentEditView: this.viewTab,
      selectedIntent: this.currentIntent,
      deleteMode: null,
      moveTo: null,
      sidebar: false,
      nextUrl: null,
      draftDialog: false,
      formValid: true,
    };
  },
  computed: {
    ...mapGetters('bots', ['currentBot', 'currentBotId', 'currentChannels']),
    ...mapGetters('intents', ['intents', 'currentIntent']),
    ...mapGetters('trainingdata', ['trainingdata']),
    ...mapGetters('auth', ['isAdmin']),
    ...mapGetters('forms', ['forms']),
    ...mapGetters('formsEditor', ['formSteps']),
    ...mapGetters('contentQueue', ['isSyncing']),
    displayName: function() {
      if (!this.form) {
        return null;
      } else {
        return this.form.displayName;
      }
    },
    channels() {
      let i = 0;
      const firstChannelBlocked = this.currentChannels[0].blocked;
      const channels = this.currentChannels.filter((c) => !c.blocked);
      return channels.map((channel) => {
        const clonedChannel = _.cloneDeep(channel);
          // exception for first channel is blocked
        if (i === 0 && !firstChannelBlocked) {
          clonedChannel.channelId = null;
        }

        i++;
        return clonedChannel;
      });
    },
    form() {
      let formObj = this.forms.find((form) => form.intent === this.intentName);

      if (formObj) {
        return formObj;
      }

      // we need this for old forms
      const intentForms = this.$store.getters['intents/forms'];
      if (intentForms) {
        formObj = intentForms.find((form) => form.name === this.intentName);

        if (formObj) {
          formObj.intent = formObj.name;
        }
      }

      if (!formObj) {
        return null;
      }

      return formObj;
    },
    getintent() {
      return this.$store.getters['intents/intent'](this.$route.params.name);
    },
    actions() {
      return this.currentAnswer ? this.currentAnswer.actions : null;
    },
    followup() {
      return this.currentFollowup ? this.currentFollowup : null;
    },
    items() {
      return ['Leadgenerierung', 'Informationsantwort'];
    },
    intentName() {
      return this.$route.params.name;
    },
    isLoading() {
      return !this.form || this.isSyncing || this.contentSaving;
    },
    lastIntent() {
      if (!this.intents && this.intents.length < 2) {
        return null;
      }

      const i = this.intents.findIndex((i) => {
        return i.intent === this.currentIntent.intent;
      });

      if (i === 0) {
        return this.intents[this.intents.length - 1];
      }

      return this.intents[i - 1];
    },
    filteredItems() {
      return this.intents && this.currentIntent
        ? this.intents.filter((i) => i.intent !== this.currentIntent.intent)
        : null;
    },
    filteredTrainingdata() {
      return this.trainingdata && this.referencefilter
        ? this.trainingdata.filter((td) => td.reference === true)
        : this.trainingdata;
    },
    transactionActions() {
      return this.followup &&
        this.followup.payload &&
        this.followup.payload.actions
        ? this.followup.payload.actions
        : [];
    },
    editableIcon() {
      return this.editable ? 'close' : 'edit';
    },
    errorRate() {
      if (!Array.isArray(this.trainingdata)) return 0;

      let count = 0;
      const rates = {};

      this.trainingdata.forEach((td) => {
        if (td.lastQualityCheck && td.name !== td.lastQualityCheck.name) {
          if (!rates[td.lastQualityCheck.name]) {
            rates[td.lastQualityCheck.name] = 0;
          }

          rates[td.lastQualityCheck.name]++;
          count++;
        }
      });

      const ratesArray = [];

      for (const key in rates) {
        ratesArray.push({
          name: key,
          rate: rates[key] / this.trainingdata.length,
        });
      }

      return {
        error: (count / this.trainingdata.length) * 100,
        rates: ratesArray,
      };
    },
    intentType() {
      return IntentUtil.getIntentType(this, this.currentIntent);
    },
    contentChanged() {
      return this.$store.getters['content/isContentChanged'](
        this.currentBotId,
        this.intentName,
        this.channelId
      );
    },
    channelContentChanged() {
      const $this = this;
      return this.channels.map((channel) => {
        return {
          name: channel.displayName || channel.channelId || channel.channel,
          channelId: channel.channelId,
          changed: $this.$store.getters['content/isContentChanged'](
            $this.currentBotId,
            $this.intentName,
            channel.channelId
          ),
        };
      });
    },
    selectedChannelIndex() {
      if (!this.channels) return -1;
      return this.channels.findIndex((c) => c.channelId === this.channelId);
    },
    isUserlike() {
      return this.channels[this.selectedChannelIndex]?.channel === 'userlike';
    },
    formChildTypes() {
      const formChildTypesList = [
        this.$t('forms.formChildTypes.freeText'),
        this.$t('forms.formChildTypes.eMail'),
        this.$t('forms.formChildTypes.multipleChoice'),
        this.$t('forms.formChildTypes.yesNo'),
        this.$t('forms.formChildTypes.conditionalSwitch'),
      ];
      if (this.isUserlike) {
        formChildTypesList.push(
          this.$t('forms.formChildTypes.multipleChoiceSlider')
        );
      }
      return formChildTypesList;
    },
  },
  watch: {
    '$route.params.name': function() {
      this.setCurrentIntent();
    },
    '$route.query.view': function() {
      this.currentEditView = this.$route.query.view;
    },
    currentEditView: function() {
      this.$router.push({ query: { view: this.currentEditView } });
    },
  },
  async created() {
    this.setCurrentIntent();
  },

  methods: {
    async save() {
      this.contentSaving = true;
      await this.$store.dispatch('content/save', {
        botId: this.currentBotId,
        name: this.intentName,
        channel: this.channelId,
      });
      this.version++;
      this.contentSaving = false;
    },
    async reset() {
      await this.$store.dispatch('content/resetDraft', {
        name: this.intentName,
        botId: this.currentBotId,
        channel: this.channelId,
      });
      this.version++;
    },
    channelChanged(channelId) {
      this.channelId = channelId;
    },
    getChannelIdByIndex(index) {
      if (index >= 0 && this.currentChannels.length > index) {
        return this.currentChannels[index].channelId;
      }

      return null;
    },
    changePath(step, answer) {
      this.$store.commit('formsEditor/selectPath', {
        box: step.box,
        intent: answer.intent,
      });
      this.$store.dispatch(
        'formsEditor/updateRenderedSteps',
        this.currentBotId
      );
    },
    active(step, answer) {
      let selectedPath = this.$store.getters['formsEditor/getSelectedPath'](
        step.box
      );
      return selectedPath === answer.intent;
    },
    goto(step) {
      this.$vuetify.goTo(this.$refs.BotFomEdit.$refs[step.box][0], {
        container: this.$refs.formEditorScrollContainer.$refs.contentContainer,
      });
    },
    answers(step) {
      let content = this.$store.getters['content/getContent'](
        this.currentBotId,
        this.$route.params.name,
        step.box
      );
      if (content && content.question) {
        let actions = content.question.actions.filter((a) => a.content);

        if (step.template === 'BotFormMultipleChoiceSlider') {
          return actions[actions.length - 1].content.data.map((card) => ({
            label: card.title,
            intent: card.buttons[0].payload.name,
          }));
        }

        return actions[actions.length - 1].content.quick;
      } else {
        if (step.template === 'BotFormConditionalSwitch') {
          // TODO: add conditionalswitch
          console.log('STEP', content.answers[0]);
          return content.answers.map((answer) => ({
            label: answer.context,
            intent: answer.intent,
          }));
        };
        
        return [];
      }
    },
    getLabel(name, step) {
      const content = this.$store.getters['content/getContent'](
        this.currentBotId,
        this.$route.params.name,
        step.box
      );
      if (!content.question) {
        // TODO: add conditionalswitch
        try {
          const ctx = JSON.parse(name[0]);
          // get operator from object
          const operator = Object.keys(ctx).find((key) => key !== 'name');

         const operatorString = [
           { operator: 'eq', label: '=' },
           { operator: 'lt', label: '<' },
           { operator: 'gt', label: '>' },
           { operator: 'lte', label: '<=' },
           { operator: 'gte', label: '>=' },
           { operator: 'exists', label: 'existiert'},
           { operator: 'not_false', label: 'not false' }
          ].find((op) => op.operator === operator);
          return {data: `${ctx.name} ${operatorString.label} ${ctx[operator]}`};
        } catch (e) {
          return {data: name[0]};
        }
      }
      const actions = content.question.actions.filter((a) => {
        return a.content;
      });

      return actions[actions.length - 1].content.strings.find((str) => {
        return `{${str.name}}` === name;
      });
    },
    async tabChanged(box, intent) {
      await this.$store.dispatch('formsEditor/selectPath', { box, intent });
      this.$store.dispatch(
        'formsEditor/updateRenderedSteps',
        this.currentBotId
      );
    },
    stepNames(template) {
      const type = this.formChildTypes.find((s) => s.template === template);

      if (type) {
        return type;
      } else {
        return { name: '', description: '' };
      }
    },
    setCurrentIntent: async function() {
      if (this.currentBot && this.currentBot.uniqueBotId) {
        await this.$store.dispatch(
          'intents/setCurrentIntent',
          this.$route.params.name
        );
      }
    },
    deleteVariant: async function() {
      this.variantDialog = false;
      this.deleteMode = false;
      await this.$store.dispatch('trainingdata/deleteData', {
        uniqueBotId: this.currentBotId,
        text: this.currentVariant.text,
      });
      await this.$store.dispatch('trainingdata/get', {
        uniqueBotId: this.currentBot.uniqueBotId,
        name: this.currentIntent.intent,
      });
    },
    changeEdit() {
      this.editable = !this.editable;
    },
    async addTrainingdata() {
      const newData = this.trainingDataText.split('\n');
      this.trainingDataDialogProcessing = true;
      await this.$store.dispatch('trainingdata/addData', {
        name: this.currentIntent.intent,
        uniqueBotId: this.currentBotId,
        trainingdata: newData,
      });
      await this.$store.dispatch('trainingdata/get', {
        uniqueBotId: this.currentBot.uniqueBotId,
        name: this.currentIntent.intent,
      });
      this.trainingDataDialog = false;
      this.trainingDataText = null;
      this.trainingDataDialogProcessing = false;
    },
    updateAnswer() {
      this.$store.dispatch('content/update');
    },

    async updateQualityCheck(variant) {
      this.updateLoading = true;
      variant.updateLoading = true;
      await this.$store.dispatch('trainingdata/updateQualityCheck', {
        variant,
      });
      delete variant.updateLoading;
      this.updateLoading = false;
    },
    editVariant(variant) {
      this.currentVariant = JSON.parse(JSON.stringify(variant));
      this.variantDialog = true;
    },
    async saveVariant() {
      if (this.moveTo && this.moveTo !== this.currentVariant.intent) {
        this.currentVariant.name = this.moveTo;
      }

      const result = await this.$store.dispatch('trainingdata/update', {
        variant: this.currentVariant,
      });
      if (result !== false) {
        this.variantDialog = false;
      }

      if (this.moveTo) {
        await this.$store.dispatch('trainingdata/get', {
          uniqueBotId: this.currentBot.uniqueBotId,
          name: this.currentIntent.intent,
        });
        this.moveTo = null;
      }
    },
    lostOk() {
      if (this.nextUrl) {
        this.$router.push(this.nextUrl);
      }
    },
    lostCancel() {
      if (this.nextUrl) {
        this.nextUrl = null;
      }
    },
  },
  beforeRouteUpdate(to, from, next) {
    if (
      this.channelContentChanged.some((channel) => channel.changed) &&
      !this.nextUrl
    ) {
      this.nextUrl = to;
      this.draftDialog = true;
      return next(false);
    }

    return next(true);
  },
  beforeRouteLeave(to, from, next) {
    if (
      this.channelContentChanged.some((channel) => channel.changed) &&
      !this.nextUrl
    ) {
      this.nextUrl = to;
      this.draftDialog = true;
      return next(false);
    }

    return next(true);
  },
};
</script>
<style scoped scss>
.tester {
  height: 100%;
  border: none;
  margin-top: -80px;
  position: fixed;
}

.fade-enter-active {
  transition: all 2s ease;
}
.fade-enter {
  opacity: 0;
}
</style>

<template>
  <HubBox
    :title="$t('intents.details.relevance.title')"
    icon="mdi-chart-timeline-variant-shimmer"
    :boxId="boxId"
    @onExpand="(isExpanded) => isExpanded && trackIt('hub-box-expanded')"
  >
    <IntentStatistic
      class="mb-4"
      :progress-title="$t('intents.details.often.description')"
      :chart-title="$t('intents.details.often.title')"
      :chart-subline="$t('intents.details.often.subline')"
      :loading="loading"
      :channel="channel"
    >
      <!-- <span class='primary--text text--lighten-3 text-body-2'>(Kanal WEB DE)</span> -->
      <template #progressBar>
        <v-progress-linear
          :value="progressValue"
          buffer-value="100"
          :color="
            loading || progressValue === 0
              ? 'grey lighten-2'
              : 'secondary darken-2'
          "
          height="10"
        />
        <small class="primary--text">
          {{ $t('intents.details.often.rare') }}
        </small>
        <div class="float-right mr-1">
          <small>{{ $t('intents.details.often.frequent') }}</small>
        </div>
      </template>

      <template #chart>
        <div class="d-flex justify-center">
          <apexchart
            v-if="chart.series[0].data.length"
            class="flex-grow-1"
            type="bar"
            :options="chart.options"
            :series="chart.series"
            height="120px"
          />
          <v-card-subtitle v-else-if="!loading && progressValue === 0">
            <v-icon small left>warning</v-icon>
            {{ $t('intents.details.often.nodata') }}
          </v-card-subtitle>
        </div>
      </template>
    </IntentStatistic>
  </HubBox>
</template>

<script>
import moment from 'moment';
import { baseBarChartOptions } from '@/utils/charts';
import { lastTwelveWeekDates } from '@/utils/numbers';
import { StatsService } from '@/services/stats';
import HubBox from '@/components/hub/HubBox.vue';
import { TrackingService } from '@/services/tracking';
import IntentStatistic from './IntentStatistic.vue';

export default {
  name: 'IntentRelevance',
  components: {
    IntentStatistic,
    HubBox,
  },
  props: {
    intent: {
      type: String,
      required: true,
    },
    channel: {
      type: String,
    },
    botId: {
      type: String,
      required: true,
    },
    boxId: {
      type: String,
      default: 'IntentRelevance',
    },
  },
  created() {
    const oftenStr = this.$t('intents.details.often.frequent');
    const rareStr = this.$t('intents.details.often.rare');
    const calendarWeekStr = this.$t('common.calendarweek');
    this.chart.options.yaxis.labels.formatter = function(val) {
      if (val === 10) return oftenStr;
      if (val === 0) return rareStr;
      return '';
    };

    this.chart.options.tooltip.custom = function({ dataPointIndex }) {
      const lastTwelve = moment(lastTwelveWeekDates[dataPointIndex]).week();
      return `
        <div class="apexcharts-tooltip-title">
          ${calendarWeekStr} ${lastTwelve}
        </div>
      `;
    };

    this.chart.options.xaxis.labels.formatter = function(_, timestamp) {
      return `${calendarWeekStr} ${moment(timestamp).week()}`;
    };
  },
  data() {
    return {
      loading: false,
      progressValue: 0,
      chart: {
        series: [
          {
            name: 'A',
            data: [],
          },
        ],
        options: Object.assign(
          JSON.parse(JSON.stringify(baseBarChartOptions)),
          {
            plotOptions: {
              bar: {
                borderRadius: 0,
                columnWidth: '25%',
                barHeight: '100%',
              },
            },
            tooltip: {},
            stroke: {
              show: false,
            },
            yaxis: {
              tickAmount: 2,
              min: 0,
              max: 10,
              labels: {},
            },
            xaxis: {
              type: 'datetime',
              categories: lastTwelveWeekDates,
              labels: {
                rotateAlways: true,
                offsetY: 3,
              },
            },
          }
        ),
      },
    };
  },
  beforeMount() {
    this.loading = true;
    StatsService.getIntentRelevance(
      this.botId,
      this.intent,
      this.channel
    ).then(({ history, frequent, data }) => {
      if (data > 0) {
        this.chart.series[0].data = history;
        this.progressValue = frequent === 0 ? 1 : frequent * 10;
      }

      this.loading = false;
    });
  },
  methods: {
    trackIt(type) {
      TrackingService.trackIntentsView({
        botId: this.botId,
        type,
        source: 'IntentRelevance',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.chart {
  :deep(.apexcharts-tooltip-title) {
    border-bottom: 0;
    margin-bottom: 0;
  }  
}
</style>

import moment from 'moment';
import lodash from 'lodash';

export const dateRangeTypes = {
  yesterday: 'yesterday',
  last7days: 'last7days',
  last28days: 'last28days',
  beginMonth: 'beginMonth',
  beginYear: 'beginYear',
  ownPeriod: 'ownPeriod',
  periodBefore: 'periodBefore',
  periodYearBefore: 'periodYearBefore',
  emptyCompare: null,
};

const dateFormatDe = 'DD.MM.YY';
const dateFormatEn = 'DD/MM/YY';
const dateFormatMonthDe = 'MMMM YY';
const dateFormatYearDe = 'YYYY';

const DateRangeUtil = {
  dateRangeTypes,
  getRangeWithDates(dates) {
    if (!dates) return null;
    return [moment(dates[0]), moment(dates[1])];
  },
  getRangeYesterday() {
    return [moment().startOf('day').add(-1, 'days'), moment().startOf('day').add(-1, 'days')];
  },
  getRangeLastXDays(days) {
    days += 1;
    return [moment().startOf('day').add(days * -1, 'days'), moment().startOf('day').add(-1, 'days')];
  },
  getRangeBeginMonth() {
    return [moment().startOf('month'), moment().startOf('day')];
  },
  getRangeBeginYear() {
    return [moment().startOf('year'), moment().startOf('day')];
  },
  getRangeSameBefore(dateRange) {
    dateRange = lodash.cloneDeep(dateRange);
    const daysBetween = dateRange[1].diff(dateRange[0], 'days') + 1;
    const newDateRange = [
      dateRange[0].add(daysBetween * -1, 'days'),
      dateRange[1].add(daysBetween * -1, 'days'),
    ];
    return newDateRange;
  },
  getRangeSamePrevYear(dateRange) {
    dateRange = lodash.cloneDeep(dateRange);
    const newDateRange = [
      dateRange[0].add(-1, 'year'),
      dateRange[1].add(-1, 'year'),
    ];
    return newDateRange;
  },
  getDateRangeFormatted({ dateRange, timeFormat = 'day', language = 'en' }) {
    dateRange = lodash.cloneDeep(dateRange);

    if (!dateRange[0] || !dateRange[1]) return dateRange;

    const dateFormat = language === 'de' ? dateFormatDe : dateFormatEn;

    switch (timeFormat) {
      case 'month':
        return `${dateRange[0]
          .locale(language)
          .format(dateFormatMonthDe)} – ${dateRange[1]
          .locale(language)
          .format(dateFormatMonthDe)}`;
      case 'day':
      default:
        return `${dateRange[0]
          .locale(language)
          .format(dateFormat)} – ${dateRange[1]
          .locale(language)
          .format(dateFormat)}`;
    }
  },
  getDateFormatted(date, timeFormat = 'day', language = 'en') {
    const momDate = moment(date);
    if (!momDate.isValid) return '–';

    const dateFormat = language === 'de' ? dateFormatDe : dateFormatEn;

    switch (timeFormat) {
      case 'year':
        return momDate.locale(language).format(dateFormatYearDe);
      case 'month':
        return momDate.locale(language).format(dateFormatMonthDe);
      case 'day':
      default:
        return momDate.locale(language).format(dateFormat);
    }
  },
  compareDateRanges(rangeA, rangeB) {
    const DF = 'YYYY-MM-DD';
    if (
      rangeA[0].format(DF) === rangeB[0].format(DF) &&
      rangeA[1].format(DF) === rangeB[1].format(DF)
    ) {
      return true;
    }
    return false;
  },
  getRangeValueFromDates(dates) {
    const range = this.getRangeWithDates(dates);

    if (this.compareDateRanges(range, this.getRangeYesterday())) {
      return dateRangeTypes.yesterday;
    }
    if (this.compareDateRanges(range, this.getRangeLastXDays(6))) {
      return dateRangeTypes.last7days;
    }
    if (this.compareDateRanges(range, this.getRangeLastXDays(27))) {
      return dateRangeTypes.last28days;
    }
    if (this.compareDateRanges(range, this.getRangeBeginMonth())) {
      return dateRangeTypes.beginMonth;
    }
    if (this.compareDateRanges(range, this.getRangeBeginYear())) {
      return dateRangeTypes.beginYear;
    }
    // own period
    return `${dates[0]}–${dates[1]}`;
  },
  getRangeDatesFromValue(value) {
    let newDates = null;
    // Individual dates from datepicker
    if (value.includes('–')) {
      const datesSplit = value.split('–');
      newDates = DateRangeUtil.getRangeWithDates(datesSplit);
    } else {
      switch (value) {
        case dateRangeTypes.yesterday:
          newDates = DateRangeUtil.getRangeYesterday();
          break;
        case dateRangeTypes.last7days:
          newDates = DateRangeUtil.getRangeLastXDays(6);
          break;
        case dateRangeTypes.last28days:
          newDates = DateRangeUtil.getRangeLastXDays(27);
          break;
        case dateRangeTypes.beginMonth:
          newDates = DateRangeUtil.getRangeBeginMonth();
          break;
        case dateRangeTypes.beginYear:
          newDates = DateRangeUtil.getRangeBeginYear();
          break;
      }
    }

    return newDates;
  },
  getAnualReviewDateRange(year) {
    if (!year) year = moment().format('YYYY');
    return [moment(`${year}-01-01`), moment(`${year}-12-31`)];
  },
  getDatesFormatted(dateRange) {
    return `${dateRange[0].format('DD.MM.YY')} – ${dateRange[1].format(
      'DD.MM.YY'
    )}`;
  },
};

export default DateRangeUtil;

<template>
  <v-form>
    <v-container>
      <v-row>
        <v-col>
          <h1 class="text-h5 mb-4">{{ $t('login.passwordReset.requestToken.title') }}</h1>
          <p class="text-body-1">
            {{ $t('login.passwordReset.requestToken.subTitle') }}
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            outlined
            :label="$t('login.username')"
            name="login"
            v-model="username"
            type="text"
            v-on:keyup.enter="handleSubmit()"
            hide-details
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="text-right pt-6">
          <v-btn
            depressed
            color="primary"
            large
            data-cy="login"
            v-on:click="handleSubmit()"
          >
            {{ $t('login.passwordReset.requestToken.button') }}
          </v-btn>
        </v-col>
        <v-col v-if="messageError">
          <v-alert outlined type="error">
            {{ messageError }}
          </v-alert>
        </v-col>
        <v-col v-if="messageSuccess">
          <v-alert outlined type="success">
            {{ messageSuccess }}
          </v-alert>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
export default {
  data() {
    return {
      username: '',
      messageSuccess: '',
      messageError: '',
    };
  },
  components: {},
  methods: {
    async handleSubmit() {
      if (this.username === '') return;
      try {
        const response = await this.$store.dispatch(
          'auth/requestPasswordReset',
          { username: this.username }
        );
        if (response.status === 200) {
          this.messageSuccess = this.$t(
            'login.passwordReset.requestToken.success'
          );
        }
      } catch (e) {
        // this should only happen when the API is n/a
        this.messageError = this.$t('login.passwordReset.requestToken.error');
      }
    },
  },
};
</script>

<style></style>

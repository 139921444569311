import Vue from 'vue'

function generateHash(obj) {
  const str = JSON.stringify(obj);
  let hash = 0,
    i,
    chr;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

const state = {
  componentData: {},
};

const getters = {
  getComponentData: (state) => (props) => {
    const hash = generateHash(props);
    return state.componentData[hash] || {};
  },
};

const actions = {};

const mutations = {
  setComponentData(state, payload) {
    const hash = generateHash(payload.props);
    Vue.set(state.componentData, hash, payload.data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

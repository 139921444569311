import ApiService from './api';

/**
 * Represents the schema for a Changelog Entity.
 *
 * @typedef {Object} ChangelogEntity
 * @property {string} [_id] - Document ID from MongoDB (optional).
 * @property {string} type - The type of changelog entry.
 * @property {string} botId - The ID of the associated bot.
 * @property {string} name - The name of the changelog entry.
 * @property {string | null} channel - The channel for the changelog entry (nullable).
 * @property {string | null} comment - Additional comments (nullable).
 * @property {string} user - The user associated with the changelog entry.
 * @property {string | undefined} jsonPayload - JSON payload (optional).
 * @property {string} [__v] - Document version from MongoDB (optional).
 * 
 */

const ChangelogService = {
  /**
   * Create a new changelog entry.
   *
   * @param {Object} changelog - The changelog object to be created.
   * @returns {Promise<[ChangelogEntity] | { error: any }>} A promise that resolves to the response data or an error object.
   */
  createChangelog: async function(changelog) {
    /**
     * Send as FormData instead of JSON since payload can grow too big for API.
     */
    const form = new FormData();
    for (const key in changelog) {

      // Special handling: Channel/Comment may be null for default, but it gets converted to the string null
      if (['channel', 'comment'].includes(key) && changelog[key] === null) {
        form.append(key, '');
        continue;
      }

      form.append(key, changelog[key]);
    }

    try {
      const response = await ApiService.postData(`/schaltzentrale/changelog`, form);
      return response.data;
    } catch (error) {
      return { error };
    }
  },

  /**
   * Search for changelog entries based on specified parameters.
   *
   * @param {{
   *   type: string,
   *   botId: string,
   *   name: string,
   *   channel: string | null,
   *   user: string,
   *   jsonPayload?: string
   * }} searchParams - Search parameters for the changelog.
   * @returns {Promise} A promise that resolves to the response data or an error object.
   */
  searchChangelogByParams: async function(searchParams) {
    try {
      const response = await ApiService.post(`/schaltzentrale/changelog/searchByParams`, searchParams);
      return response.data;
    } catch (error) {
      console.log(error)
      return { error };
    }
  },
};

export default ChangelogService;

export { ChangelogService };

<template>
	<div>
		<ActionRow :style="fixedHeight ? 'height: 60px' : 'min-height: 60px'" :class="{'ml-6': indent}" :vertical-padding="!options?.readMode">
			<template #line>
				<ActionLine :blockType="blockType" :options="currenOptions" :items="items" @onEdit="onEdit" :clickable="isClickable" />
			</template>

			<template #content>
				<div class="pr-4">
					<template v-if="!loading">
						<div class="d-flex align-content justify-space-between">
							<h1
									class="text-body-1 font-weight-bold secondary--text mt-1"
							>
								{{ displayName }}
							</h1>
							<div>
								<slot name="meta"></slot>
							</div>
						</div>
						<div class="text-body-2 secondary--text">
							<slot name="description"></slot>
						</div>
					</template>
					<v-skeleton-loader
							class="mt-1"
							v-else
							:height="20"
							:width="176"
							type="image"
					/>

				</div>
			</template>
		</ActionRow>
    <HubDialog
      v-if="showDialog"
      modal
      :value="showDialog"
      title="Namen ändern"
      @input="(value) => showDialog = value"
      @onPrimary="updateName"
    >
      <!-- edit -->
      <v-text-field outlined label="Name" v-model="displayNameString" />
    </HubDialog>
	</div>
</template>
<script>
import ActionRow from '@/components/editor/components/ActionRow.vue';
import ActionLine from '@/components/editor/components/ActionLine.vue';
import DropMenu from '@/components/common/DropMenu.vue';
import HubDialog from '@/components/hub/HubDialog.vue';
import BtnSquare from '@/components/common/BtnSquare.vue';
import { blockTypes } from '@/components/editor/actionConsts';
import { useEventBus } from '@vueuse/core';
import { actionEditorEvents } from '@/utils/actionEditor';

export default {
  name: 'ActionRowStart',
  components: {
    ActionRow,
    ActionLine,
    DropMenu,
    BtnSquare,
    HubDialog
  },
  data() {
    return {
      blockTypes,
      showDialog: false,
      contentUpdatedEmitter: useEventBus(actionEditorEvents.contentUpdated),
      displayNameString: '',
    };
  },
  props: {
    blockType: {
      type: String,
      default: '',
    },
    options: Object,
    title: String,
    indent: { 
      type: Boolean,
      default: false,
    },
    deletable: {
      type: Boolean,
      default: false,
    },
    content: Object,
    clickable: {
      type: Boolean,
      default: false,
    },
    menuItems : {
      type: Array,
      default: () => [],
    },
    box: String,
    fixedHeight: {
      type: Boolean,
      default: true,
    }
  },
  computed: {
    isClickable() {
      return !!(this.box && this.content && this.content[this.box] && this.content[this.box].displayName) || this.clickable || this.menuItems.length > 0;
    },
    displayName() {
      
      if (this.box && this.content && this.content[this.box] && this.content[this.box].displayName) {
        return this.content[this.box].displayName;
      }

      if (!this.title) {
        return this.$t(`action.start.title.${this.blockType}`);
      }

      return this.title;
    },
    loading() {
      return this.blockType === blockTypes.START_LOADING;
    },
    currenOptions() {
      const options = {
        ...this.options,
      };

      if (this.$listeners.onEdit || this.content?.[this.box]?.displayName) {
        options.onEdit = true;
      }

      return options;
    },
    items() {
      let items = [];
      if (this.deletable) {
        items.push(
          {
            label: this.$t('common.delete'),
            icon: 'mdi-delete',
            onClick: () => {
              this.contentUpdatedEmitter.emit({
                payload: {
                  box: this.box
                },
                event: actionEditorEvents.contentDeleted,
              });
            },
          });
      }

      if (this.menuItems.length > 0) {
        items = items.concat(this.menuItems);
      }

      return items;
    }
  },
  methods: {
    onEdit() {
      // if event listenter onEdit is set on this component
      if (this.$listeners.onEdit) {
        this.$emit('onEdit');
      }
      // edit displayName
      else if (this.content?.[this.box]?.displayName) {
        this.displayNameString = this.displayName;
        this.showDialog = true;
      }
    },
    updateName() {
      this.showDialog = false;
      this.content[this.box].displayName = this.displayNameString;
      this.contentUpdatedEmitter.emit({
        event: actionEditorEvents.contentUpdated,
      });
    }
  },
};
</script>

import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import store from './store';
import router from './router/router';
import ApiService from './services/api';
import {TokenService} from './services/token';
import VueApexCharts from 'vue-apexcharts';
import { VTextField } from 'vuetify/lib';  //Globally import VTextField
import i18n from './i18n'

Vue.component('draggable', {
  // Props, data, methods, etc.
  render() {
    return this.$scopedSlots.default({})
  },
})
Vue.component('dropzone', {
  // Props, data, methods, etc.
  render() {
    return this.$scopedSlots.default({})
  },
})

Vue.config.productionTip = false // turn off annoying production tips

// Set the base URL of the API https://cli.vuejs.org/guide/mode-and-env.html#modes How to use env files.
ApiService.init(process.env.VUE_APP_BOTCAST_API)

// If token exists set header for api requests
if (TokenService.getToken()) {
  ApiService.setHeader()
  ApiService.mount401Interceptor()
}

Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

// register hub components
import HubDialog from '@/components/hub/HubDialog.vue'
Vue.component('hub-dialog', HubDialog)

Vue.component('v-text-field', VTextField)

new Vue({
  vuetify,
  store,
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')

// window.moin_timer = {total: 0, start: false, init: moment()}

// window.addEventListener('focus', () => {
//   console.log('GET FOCUS');
//   window.moin_timer.start = moment();
// });

// window.addEventListener('blur', () => {
//   if (window.moin_timer.start === false) {
//     window.moin_timer.start = window.moin_timer.init;
//   }

//   window.moin_timer.total += moment().diff(window.moin_timer.start, 'seconds');
//   window.moin_timer.start = false;

//   console.log('LOST FOCUS', window.moin_timer.total);
// });

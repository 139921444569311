<template>
  <div class="white">
    <v-container class="pa-6" style="max-width: 1137px">
      <v-row v-if="botHasLivechat && agent">
        <v-col cols="12">
          <HubBoxSimple :title="'Livechat Profil'">
            <template v-slot:content>
              <LivechatProfile
                ref="livechatProfileRef"
                @onDirty="livechatDirty = $event"
                :loading="isLoading"
              />
            </template>
          </HubBoxSimple>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" :md="currentBotContactId ? 6 : 12">
          <HubBoxSimple :title="$t('userRoles.title')" fillHeight>
            <template v-slot:content>
              <div class="pb-4">
                <v-chip-group class="mb-2">
                  <v-chip :ripple="false" style="cursor: default">
                    <v-icon color="primary lighten-2" size="20" left>
                      {{ userRole.icon }}
                    </v-icon>
                    {{ userRole.title }}
                  </v-chip>
                </v-chip-group>
                <div class="text-body-2 grey--text text--darken-2">
                  {{ userRole.info }}
                </div>
              </div>
              <div>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <v-switch
                        class="moin-cursor-default"
                        inset
                        v-model="is2FA"
                        color="green"
                        dense
                        :label="$t('2factorAuth.label')"
                        readonly
                      />
                    </div>
                  </template>
                  <span>
                    {{ $t('2factorAuth.tooltip') }}
                  </span>
                </v-tooltip>
              </div>
            </template>
          </HubBoxSimple>
        </v-col>
        <v-col v-if="currentBotContactId" cols="12" md="6">
          <HubBoxSimple :title="$t('welcome.supportContact.live')" fillHeight>
            <template v-slot:content>
              <ContactItem :contact-id="currentBotContactId" large />
            </template>
          </HubBoxSimple>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <HubBoxSimple :title="$t('common.hubLanguage')">
            <template v-slot:content>
              <v-select
                v-model="selectedLanguage"
                :items="languageOptions"
                item-text="text"
                item-value="value"
              >
                <template v-slot:item="{ item }">
                  <v-icon small class="mr-2">mdi-web</v-icon>
                  {{ item.text }}
                </template>
                <template v-slot:selection="{ item }">
                  <div>
                    <v-icon small class="mr-2" :color="item.state">
                      mdi-web
                    </v-icon>
                    {{ item.text }}
                  </div>
                </template>
              </v-select>
            </template>
          </HubBoxSimple>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <HubBoxSimple :title="$t('common.eMailAddress')">
            <template v-slot:content>
              <v-text-field
                :label="$t('common.eMailAddress')"
                :value="user.username"
                type="email"
                persistent-hint
                readonly
              />
            </template>
          </HubBoxSimple>
        </v-col>
      </v-row>
      <v-row v-if="false">
        <v-col>
          <HubBoxSimple title="Kontakt Daten">
            <template v-slot:content>
              <v-text-field :label="'Vorname'" persistent-hint />
              <v-text-field :label="'Nachname'" persistent-hint />
              <v-text-field :label="'Telefon-Nr.'" persistent-hint />
            </template>
          </HubBoxSimple>
        </v-col>
      </v-row>

      <v-row v-if="false">
        <v-col>
          <HubBoxSimple title="E-Mail Benachrichtigungen">
            <template v-slot:content>
              <v-switch
                inset
                color="green"
                dense
                label="Benachrichtigungen per E-Mail erhalten."
              />
              <v-switch
                inset
                color="green"
                dense
                label="Themen-Vorschläge per E-Mail erhalten."
              />
            </template>
          </HubBoxSimple>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import HubBoxSimple from '@/components/hub/HubBoxSimple.vue';
import ContactItem from '@/components/ContactItem.vue';
import { getRoleByFeatures } from '@/utils/userRoles';
import LivechatProfile from '@/components/livechat/LivechatProfile.vue';

export default {
  name: 'UserProfileComp',
  components: {
    ContactItem,
    HubBoxSimple,
    LivechatProfile,
  },
  data() {
    return {
      isLoading: false,
      selectedLanguage: 'de',
      livechatDirty: false,
      languageOptions: [
        {
          text: 'Deutsch',
          value: 'de',
        },
        {
          text: 'English',
          value: 'en',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('bots', ['currentBotContactId']),
    ...mapGetters('auth', ['user']),
    ...mapGetters(['appVersion']),
    ...mapGetters('livechat', ['botHasLivechat', 'agent']),
    userRole() {
      const role = getRoleByFeatures(this.user.features);
      return {
        title: this.$t(`userRoles.${role}.title`),
        icon: this.$t(`userRoles.${role}.icon`),
        info: this.$t(`userRoles.${role}.info`),
      };
    },
    is2FA() {
      return this.user.features.includes('2FA');
    },
    isChanged() {
      if (this.livechatDirty) return true;
      if (!this.user) return false;

      if (this.user.language !== this.selectedLanguage) {
        return true;
      }
      return false;
    },
  },
  watch: {
    user() {
      if (!this.user) return;
      this.resetInputs();
    },
    isChanged() {
      this.$emit('onChange', this.isChanged);
    },
  },
  created() {
    this.resetInputs();
  },
  methods: {
    async saveConfig() {
      this.isLoading = true;
      let needsReload = false;
      if (this.user?.language !== this.selectedLanguage) {
        await this.$store.dispatch('auth/saveLanguage', this.selectedLanguage);
        needsReload = true;
      }

      if (this.livechatDirty) {
        await this.$refs.livechatProfileRef.save();
      }

      if (needsReload) {
        await new Promise((resolve) => setTimeout(resolve, 200));
        location.reload();
      }
      this.isLoading = false;
    },
    async resetInputs() {
      this.selectedLanguage = this.user.language;
      this.$refs.livechatProfileRef?.reset();
    },
  },
};
</script>

<template>
  <v-form>
    <v-container>
      <template v-if="isDone">
        <v-row>
          <v-col>
            <h1 class="text-h5 mb-4">
              {{ $t('login.completeInviteFinal.title') }}
            </h1>
            <p class="text-body-1">
              {{ $t('login.completeInviteFinal.subTitle') }}
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-alert outlined type="success">
              {{ $t('login.completeInviteFinal.success') }}
            </v-alert>
          </v-col>
          <v-col cols="12" class="text-right">
            <v-btn depressed color="primary" large to="/login">
              {{ $t('login.completeInviteFinal.buttonToLogin') }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
      <template v-else>
        <v-row>
          <v-col>
            <h1 class="text-h4 mb-4">
              {{ $t('login.completeInvite.title') }}
            </h1>
            <p class="text-h6">
              {{ $t('login.completeInvite.subTitle') }}
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              outlined
              hide-details
              :label="$t('login.completeInvite.input')"
              name="login"
              v-model="password"
              type="password"
            />
            <PasswordValidationFeedback
              :password="password"
              @update:isValidPassword="handleIsValidPassword" 
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              outlined
              hide-details
              :label="$t('login.completeInvite.inputConfirm')"
              name="login"
              v-model="confirmedPw"
              @input="validation = false"
              :append-icon="passwordMatchesIcon"
              type="password"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="text-right pt-6">
            <v-btn
              depressed
              color="primary"
              large
              v-on:click="handleSubmit()"
              :loading="isLoading"
            >
              {{ $t('login.completeInvite.button') }}
            </v-btn>
          </v-col>
          <v-col v-if="validation">
            <v-alert outlined type="error">
              {{ validationMessage }}
            </v-alert>
          </v-col>
          <v-col v-if="errorMessage">
            <v-alert outlined type="error">
              {{ errorMessage }}
            </v-alert>
          </v-col>
        </v-row>
      </template>
    </v-container>
  </v-form>
</template>

<script>
import PasswordValidationFeedback from './PasswordValidationFeedback.vue';

export default {
  data() {
    return {
      password: '',
      confirmedPw: '',
      validation: false,
      validationMessage: '',
      isLoading: false,
      isDone: false,
      errorMessage: null,
      isValidPassword: false,
    };
  },
  components: {
    PasswordValidationFeedback,
  },
  computed: {
    passwordMatchesIcon() {
      if (
        this.password !== '' &&
        this.confirmedPw !== '' &&
        this.password === this.confirmedPw
      ) {
        return 'check';
      }
      return '';
    },
  },
  methods: {
    handleIsValidPassword(isValid) {
      this.isValidPassword = isValid;
    },
    async handleSubmit() {
      if (this.isValidPassword && this.password === this.confirmedPw) {
        try {
          this.isLoading = true;
          const res = await this.$store.dispatch('auth/completeInvite', {
            username: this.$route.params.username,
            token: this.$route.params.token,
            password: this.password,
          });

          this.isLoading = false;

          if (res.data.error) {
            this.errorMessage = this.$t('login.completeInvite.errorInvite');
            return;
          }

          this.isDone = true;
        } catch (error) {
          // Right now we don't differentiate between errors
          this.validation = true;
          this.validationMessage = this.$t('login.completeInvite.error');
        }
      } else {
        // Password input are not matching
        // alternatively we could disable the submit button
        this.validation = true;
        this.validationMessage = this.$t(
          this.isValidPassword ?
          'login.completeInvite.validationError' :
          'login.completeInvite.passNotStrong'
        );
      }
    },
  },
};
</script>

<style></style>

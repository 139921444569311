<template>
  <div>
    <div class="theme-header bg-secondary/10 p-4 rounded-lg mb-6">
    <h4 class="text-secondary text-xl font-bold mb-3">{{ newIntent.displayName }}</h4>
    <p class="text-gray-700">{{ newIntent.scenario }}</p>
  </div>

    <div class="mb-6">
      <InfoBox :text="$t('intentNew.stepSamples.infoSteps')" />
    </div>

    <v-row class="mt-4">
      <v-col v-for="(_, index) in samplesInput" :key="index" cols="12">
        <v-text-field
          v-model="samplesInput[index]"
          outlined
          persistent-placeholder
          color="secondary darken-2"
          hide-details
          :autofocus="index === 0"
          :label="
            `${$t('intentNew.stepSamples.labelTypicSample')} ${index + 1}`
          "
          clearable
          @input="updateSamplesDebounced(index)"
          :disabled="validStates[index]?.running"
        ></v-text-field>
        <div class="mt-2 text-caption d-flex align-center" v-if="!noK2">
          <v-progress-circular
            v-if="validStates[index]?.running"
            indeterminate
            color="primary"
            size="16"
            width="2"
            class="mr-2"
          ></v-progress-circular>
          <v-icon
            v-else-if="validStates[index]?.valid"
            color="green"
            small
            class="mr-2"
          >
            mdi-check-circle
          </v-icon>
          <v-icon
            v-else-if="validStates[index]?.valid === false"
            color="red"
            small
            class="mr-2"
          >
            mdi-alert-circle
          </v-icon>
          <span v-if="validStates[index]?.running">{{ $t('intentNew.stepSamples.validationRunning') }}</span>
          <span v-else-if="validStates[index]?.valid">
            {{ $t('intentNew.stepSamples.validationValid') }}
          </span>
          <span v-else-if="validStates[index]?.valid === false">
            {{ $t('intentNew.stepSamples.validationInvalid', { topic: displayName(validStates[index]?.label, $vuetify.lang.current) }) }}
          </span>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import InfoBox from '@/components/common/InfoBox.vue';

export default {
  name: 'IntentNewStepSamples',
  components: {
    InfoBox,
  },
  data() {
    return {
      samplesInput: [],
      validStates: [],
      noK2: false,
    };
  },
  computed: {
    ...mapGetters('intentNew', ['samples', 'searchRequest', 'newIntent', 'searchResults']),
    ...mapGetters('intents', ['displayName']),
    ...mapGetters('bots', ['currentBotId']),
    isAllSamplesValid() {
      return this.validStates.every((state, index) => (state.valid === true && !state.running) || (this.noK2 && this.samplesInput[index].length));
    },
  },
  created() {
    this.samplesInput = [...this.samples];
    this.updateSamples();

    for (let i = 0; i < this.samplesInput.length; i++) {
      if (!this.samples[i]?.length && this.searchResults.newTopic?.exampleQueries?.length > i) {
        this.samplesInput[i] = this.searchResults.newTopic.exampleQueries[i];
      } else if (i === 0) {
        this.samplesInput[i] = this.searchRequest;
      }

      this.validStates[i] = {
          running: false,
          valid: null,
          label: '',
      };

      this.validateSample(i);
    }
  },
  watch: {
    isAllSamplesValid: {
      handler(newValue) {
        this.$emit('onDisableChange', { 
          isDisabled: !newValue, 
          message: newValue ? null : this.$t('intentNew.stepSamples.allvalid')
        });
      },
      immediate: true
    }
  },
  methods: {
    updateSamplesDebounced: _.debounce(function(index) {
      this.validateSample(index);
      this.updateSamples();
    }, 1500),
    updateSamples() {
      this.$store.commit('intentNew/updateSamples', this.samplesInput);
    },
    async validateSample(index) {
      this.$set(this.validStates, index, {running: true, valid: false});
     
      const response = await this.$store.dispatch('intentNew/validateQuery', {
        botId: this.currentBotId,
        query: this.samplesInput[index],
      });
      
      if (response.valid === undefined) {
        this.noK2 = true; 
      }

      this.$set(this.validStates, index, {running: false, valid: response.valid, label: response.label});
    }
  },
};
</script>

<template>
  <div class="moin-fresh">
    <HubDialog
      :value="open"
      @input="handleClose()"
      @onPrimary="handleApproval()"
      title="Wirklich approven?"
      :loading="isLoading"
      primaryLabel="Approval"
      primary-icon="check"
    >
      <div class="moin-fresh">
        <div class="text-h3 mb-2">"{{ intent.displayName }}"</div>
        <div>
          <v-tabs>
            <v-tab @click="selectedTab = 'inclusive'">Inklusive</v-tab>
            <v-tab
              @click="
                selectedTab = 'exclusive';
                initPoll(true);
              "
            >
              Exklusive
            </v-tab>
          </v-tabs>
        </div>
        <div>
          <div v-for="item in inclusiveExclusive" :key="item.tab">
            <div class="py-4" v-show="item.tab === selectedTab">
              <div class="text-body-2">{{ item.advice }}</div>
              <div
                class="pt-10"
                v-if="item.refVariants && item.refVariants.length"
              >
                <v-divider />
                <v-expansion-panels flat>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <v-card-title class="pa-0">
                        <h2 class="text-h3">Varianten</h2>
                      </v-card-title>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-card
                        class="pa-3 mb-4"
                        outlined
                        v-for="(variant, variantIndex) in item.refVariants"
                        :key="variantIndex"
                      >
                        <div class="d-flex justify-space-between">
                          <div class="text-h4">
                            {{ variant.name }}
                          </div>
                          <div>
                            <v-chip
                              v-if="variant.isNewintent"
                              class="mt-n2"
                              x-small
                              color="green"
                              text-color="white"
                            >
                              NEU
                            </v-chip>
                          </div>
                        </div>
                        <v-card
                          class="rounded-lg elevation-0 ma-1"
                          color="grey lighten-3"
                        >
                          <v-card-text class="pa-2 primary--text">
                            {{ variant.text }}
                          </v-card-text>
                        </v-card>
                      </v-card>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
              <div v-if="item.newIntents && item.newIntents.length">
                <v-divider />
                <v-expansion-panels flat>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <v-card-title class="pa-0">
                        <h2 class="text-h3">
                          Intent Vorschläge
                          <v-chip x-small color="green" text-color="white">
                            {{ item.newIntents.length }}
                          </v-chip>
                        </h2>
                      </v-card-title>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-card
                        class="pa-3 mb-4"
                        outlined
                        v-for="(newIntent, intentIndex) in item.newIntents"
                        :key="intentIndex"
                      >
                        <div class="text-h4">
                          {{ newIntent.name }}
                        </div>
                        <div class="my-2 text-body-2">
                          {{ newIntent.description }}
                        </div>
                      </v-card>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <v-divider />
              </div>
            </div>
          </div>
        </div>
      </div>
    </HubDialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import HubDialog from '@/components/hub/HubDialog.vue';
import IntentService from '@/services/intents';

export default {
  name: 'IntentReviewAssistant',
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    intent: {
      type: Object,
      required: true,
    },
  },
  components: {
    HubDialog,
  },
  watch: {
    open() {
      if (!this.initialized) {
        this.initialized = true;
        this.initPoll();
      }
    },
  },
  data() {
    return {
      initialized: false,
      tab: 'inclusive',
      selectedTab: 'inclusive',
      inclusive: {
        tab: 'inclusive',
        loading: false,
        advice: 'Inclusive noch nicht geladen.',
        refVariants: [],
        loaded: false,
        intentReviewId: null,
        pollInterval: null,
        newIntents: [],
      },
      exclusive: {
        tab: 'exclusive',
        loading: false,
        advice: 'Exclusive noch nicht geladen.',
        refVariants: [],
        loaded: false,
        intentReviewId: null,
        pollInterval: null,
        newIntents: [],
      },
    };
  },
  computed: {
    ...mapGetters('bots', ['currentBotId']),
    inclusiveExclusive() {
      return [this.inclusive, this.exclusive];
    },
    isLoading() {
      return this.inclusive.loading || this.exclusive.loading;
    },
  },
  methods: {
    async initPoll(isExclusiveTarget = false) {
      // inclusive or exclusive
      const target = isExclusiveTarget ? this.exclusive : this.inclusive;
      if (target.loaded) return;
      target.loading = true;

      // Initialize Poll
      if (!target.intentInReview) {
        const res = await IntentService.initReviewAssist({
          uniqueBotId: this.currentBotId,
          intentInReview: this.intent.intent,
          excludeIntentsInReview: !!isExclusiveTarget,
        });
        if (res._id) {
          target.intentReviewId = res._id;
          target.pollInterval = setInterval(() => {
            this.checkPoll(target);
          }, 10000);
        }
      }
    },
    async checkPoll(target) {
      const res = await IntentService.checkReviewAssist({
        uniqueBotId: this.currentBotId,
        intentReviewId: target.intentReviewId,
      });

      if (res.progress === 'finished') {
        clearInterval(target.pollInterval);
        target.advice = res.review?.advice;
        target.refVariants = res.review?.refVariants;
        target.loaded = true;
        target.newIntents = res.review?.newIntents;
        target.loading = false;
      }
    },

    handleClose() {
      this.$emit('onClose');
    },

    handleApproval() {
      this.$emit('onApprove');
    },
  },
};
</script>

export function rgbToHex(rgbString) {
  try {
    let r,
      g,
      b = 0;
    let str = rgbString.split('(')[1].split(')')[0].replace(/\s/g, '');
    str = str.split(',');

    r = parseInt(str[0]);
    g = parseInt(str[1]);
    b = parseInt(str[2]);

    function componentToHex(c) {
      var hex = c.toString(16);
      return hex.length == 1 ? '0' + hex : hex;
    }

    return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b);
  } catch (e) {
    return '#ffffff';
  }
}

<template>
  <div>
    <HubBox :title="$t('botSettings.deleteUser.title')" icon="mdi-trash-can">
      <div class="mb-4">
        <div class="text-body-2">
          {{ $t('botSettings.deleteUser.description') }}
        </div>
      </div>

      <v-btn
        :disabled="isDisabled"
        class="mt-6"
        outlined
        color="primary lighten-2"
        @click="dialogIsOpen = true"
      >
        {{ $t('botSettings.deleteUser.title') }}
        <v-icon right>mdi-trash-can</v-icon>
      </v-btn>
    </HubBox>
    <HubDialog
      :value="dialogIsOpen"
      :title="$t('botSettings.deleteUser.title')"
      asyncDialog
      primaryIcon="mdi-trash-can"
      :primaryLabel="$t('botSettings.deleteUser.deleteBtn')"
      @onSecondary="dialogIsOpen = false"
      @onPrimary="deleteUser"
      :isLoading="isLoading"
      :disabled="!isValid"
      modal
    >
      <div class="text-body-1 mb-4">
        {{ $t('botSettings.deleteUser.infoText') }}
      </div>
      <div class="text-body-2 mt-8">
        {{ $t('botSettings.deleteUser.emailCheck') }}
        <strong class="error--text">{{ user.username }}</strong>
      </div>

      <div>
        <v-text-field
          v-model="mailInput"
          :label="$t('common.eMailAddress')"
          type="email"
          prepend-inner-icon="mdi-at"
        />
      </div>

      <InfoBox
        icon="mdi-alert"
        :text="$t('botSettings.deleteUser.infoBox')"
      />
    </HubDialog>
  </div>
</template>
<script>
import HubDialog from '@/components/hub/HubDialog.vue';
import InfoBox from '@/components/common/InfoBox.vue';
import HubBox from '@/components/hub/HubBox.vue';
import BotService from '@/services/bot';

export default {
  name: 'SettingsDeleteUser',
  components: {
    HubDialog,
    InfoBox,
    HubBox,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    currentBotId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dialogIsOpen: false,
      isLoading: false,
      mailInput: '',
    };
  },
  computed: {
    isDisabled() {
      if (!this.user?.active && this.user?.active !== false) return true;
      return false;
    },
    isValid() {
      return !!(
        this.mailInput.toLowerCase() === this.user.username.toLowerCase()
      );
    },
  },
  methods: {
    async deleteUser() {
      this.isLoading = true;

      await BotService.deleteUser(this.user.username, this.currentBotId);

      this.statusActive = true;
      this.isLoading = false;
      this.dialogIsOpen = false;

      this.$emit('onDeleted');
    },
  },
};
</script>
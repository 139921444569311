<template>
  <DemoComp :event="event" />
</template>
<script>
import DemoComp from '@/components/demo/DemoComp.vue';

export default {
  name: 'DemoCreateBot',
  components: {
    DemoComp,
  },
  props: {
    event: String,
  },
};
</script>
<script>
import HubBg from '@/components/hub/HubBg.vue';
import HubPanelLogo from '@/components/hub/HubPanelLogo.vue';
import { mapActions, mapGetters } from 'vuex';
import { TokenService } from '@/services/token';

export default {
  name: 'SSOCallback',
  components: { HubPanelLogo, HubBg },
  data() {
    return {
      loading: true,
      success: false,
      error: null,
    };
  },
  computed: {
    ...mapGetters('auth', ['authenticationError']),
    /**
     * When you get redirected from a provider back to our Hub, the URI will be
     * pre-populated with various data. The structure may depend on the provider.
     * @returns {{code?: string, state?: string, error?: string}}
     */
    parsedUri() {
      const data = {};

      switch(this.idProvider) {
        case "microsoft": {
          if (this.$route.query.error_description) {
            data.error = this.$route.query.error_description;
          }

          if (this.$route.hash && this.$route.hash.startsWith("#code=")) {
            const search = new URLSearchParams(this.$route.hash.slice(1));
            data.code = search.get("code");
            if (search.has("state")) data.sate = search.get("state");
          }
          break;
        }
      }

      return data;
    },
    /**
     * The ID provider being used for the login
     * @returns {"microsoft" | string}
     */
    idProvider() {
      return this.$route.params.idProvider;
    },
    /**
     * What sort of SSO callback we're handling.
     * @returns {null|'invite'}
     */
    callbackType() {
      /**
       * The /:type? route param can be anything you want.
       * It is used to distinguish types of action user was doing that required handling the callback.
       * Every new type must be registered as a valid redirect URL in the provider's whitelist of URLs.
       *
       * - <omitted>: User is doing normal login.
       * - 'invite': User accepting an invite to a bot that requires SSO
       */
      return this.$route.params.type || null;
    },
    /**
     * The callback URL the provider expected to send user back to
     * @returns {string}
     */
    redirectUri() {
      // Current URI without anchor or queries
      return `${window.location.origin}${window.location.pathname}`;
    }
  },
  methods: {
    ...mapActions('auth', ['handleSSOCallback']),
    async handleCallback() {
      if (this.parsedUri.error) {
        this.error = this.parsedUri.error;
        return this.loading = false;
      }

      const { nonce, extraData: [invite] } = TokenService.getSSONonce(this.idProvider);
      const autoRedirect = this.callbackType === null;

      if (!nonce) {
        this.error = this.$t('login.callback.invalidState');
        return this.loading = false;
      }

      const result = await this.handleSSOCallback(
        {
          provider: this.idProvider,
          data: {
            nonce,
            code: this.parsedUri.code,
            redirect_uri: this.redirectUri,
            state: this.parsedUri.state,

            invite, // Optional: Populated when you are logging in via an invite code
          },
          options: {
            // Stores token and preps the ApiService to use auth
            autoLogin: true,
            // After successful login && auto login, redirect automatically to dashboard
            redirect: autoRedirect,
          }
        },
      );

      if (result.success) {
        TokenService.removeSSONonce(this.idProvider);
        this.success = true;
      } else {
        this.success = false;
        this.error = result.error || this.$t('login.callback.couldNotCompleteFallback');
      }

      this.loading = false;
    },
  },
  mounted() {
    return this.handleCallback();
  },
};
</script>

<template>
  <v-app>
    <HubBg>
      <HubPanelLogo :loading="loading" :key="error">
        <p v-if="loading" class="text-center">
          {{ $t('login.callback.verifying') }}&hellip;
        </p>

        <p v-if="error" class="text-center error--text">
          {{ error }}
        </p>

        <template v-if="!loading && callbackType === 'invite' && success">
          <v-container>
            <v-row>
              <v-col>
                <h1 class="text-h5 mb-4">
                  {{ $t('login.completeInviteFinal.title') }}
                </h1>
                <p class="text-body-1">
                  {{ $t('login.completeInviteFinal.subTitle') }}
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-alert outlined type="success">
                  {{ $t('login.callback.success') }}
                </v-alert>
              </v-col>
              <v-col cols="12" class="text-right">
                <v-btn depressed color="primary" large to="/">
                  {{ $t('login.callback.gotoDashboard') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </template>
      </HubPanelLogo>
    </HubBg>
  </v-app>
</template>

<template>
  <div class="white">
    <div class="d-flex justify-space-between px-3" style="height: 60px;">
      <div class="d-flex align-center full-height">
        <!-- icon Back -->
        <v-tooltip v-if="backTo" bottom color="primary lighten-3">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on" class="pt-1">
              <BtnSquare icon="arrow_back" :to="backTo" :exact="true" />
            </span>
          </template>
          <slot name="back-button">
            <span>{{ $t('common.back') }}</span>
          </slot>
        </v-tooltip>
        <!-- title -->
        <div class="pl-2 pt-1" :class="isAdminOnly && 'admin--text'">
          <slot v-if="title" name="title" :title="title">
            <span v-if="title" :key="title" class="text-truncate text-h5">
              {{ title }}
            </span>
            <v-skeleton-loader
              v-else
              boilerplate
              class="pt-2 grow"
              type="heading"
            />
          </slot>
        </div>
      </div>
      <!-- action icons toolbar -->
      <div class="d-flex align-center justify-end">
        <slot name="toolbar" :disabled="isLoading" />
      </div>
    </div>
    <v-divider />
  </div>
</template>
<script>
import BtnSquare from '@/components/common/BtnSquare.vue';

export default {
  name: 'HubContentNavbar',
  components: {
    BtnSquare,
  },
  props: {
    title: {
      type: String,
    },
    backTo: {
      type: [String, Object],
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isAdminOnly() {
      return this.$route.meta?.admin || false;
    },
  }
};
</script>
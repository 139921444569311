<template>
  <div>
    <v-card>
      <v-card-title>Facebook authorisieren via OAuth</v-card-title>
      <v-card-subtitle>Facebook authorisieren damit es als Kanal benutzt werden kann</v-card-subtitle>

      <v-card-actions>
        <v-spacer />
        <v-btn v-if="channels.length == 0" color="success" dark small @click="logInWithFacebook()">
          Auth
          <v-icon right dark>mdi-robot-love-outline</v-icon>
        </v-btn>
        <v-btn v-if="channels.length > 0" color="success" dark small @click="chooseChannel()">
          Select this Channel
          <v-icon right dark>mdi-robot-love-outline</v-icon>
        </v-btn>
      </v-card-actions>
     
      <div v-if="channels">
        <v-list>
          <v-list-item-group v-model="selectedItem" :mandatory="true">
            <v-list-item v-for="item in channels" :key="item.id" @click="itemType(item)" :value="item">
              <v-list-item-avatar>
                <v-img :src="`https://graph.facebook.com/${item.id}/picture?app_id=${appId}`" />
              </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                  <v-list-item-subtitle>{{ item.category }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </div>
    </v-card>

    <v-snackbar v-model="savingToast" :timeout="6000">
      {{ message }}<v-spacer /><v-icon type="success">check</v-icon>
    </v-snackbar>
    <v-snackbar v-model="savingToastError" :timeout="6000">
      Fehler: {{ message }}<v-spacer /><v-icon color="red" type="error">mdi-alert-circle</v-icon>
    </v-snackbar>
  </div>
</template>


<script>
import SettingService from '../services/settings.js'
import BotService from '../services/bot.js'

import { mapGetters } from 'vuex'

export default {
  name: 'FacebookAuth',
  computed: {
    ...mapGetters('bots', ['currentBotId']),
    appId() {
      return process.env.VUE_APP_FACEBOOK_APP_ID
    }
  },
  mounted() {
    this.loadFacebookSDK(document, 'script', 'facebook-jssdk')
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: process.env.VUE_APP_FACEBOOK_APP_ID,
        cookie: true,
        version: 'v12.0',
      })
    }
  },
  methods: {
    itemType(item) {
      console.log('item', item)
    },
    async chooseChannel() {
      const channel = { uniqueBotId: this.currentBotId, channel: 'facebook', channelId: this.selectedItem.id, payload: this.selectedItem }
      await BotService.addChannel(channel)
      this.channels = []
      this.message = `Success connecting ${this.selectedItem.name}`
      this.savingToast = true
    },
    async logInWithFacebook() {
      window.FB.login(
        async (response) => {
          if (response.authResponse) {
            let tokenSet = await SettingService.saveOAuthToken(
              this.currentBotId,
              'facebook',
              undefined,
              undefined,
              response.authResponse
            )
            this.channels = await SettingService.getFBChannels(this.currentBotId)
          } else {
            console.log('User canceled login or did not fully authorize.')
          }
        },
        {
          scope: 'public_profile, email, pages_show_list,pages_messaging,pages_messaging_subscriptions',
        }
      )
      return false
    },
    async loadFacebookSDK(d) {
      const js = d.createElement('script')
      js.setAttribute('crossorigin', 'anonymous')
      js.src = 'https://connect.facebook.net/en_US/sdk.js'
      d.head.appendChild(js)
    },
  },
  data() {
    return {
      message: '',
      savingToast: false,
      savingToastError: false,
      channels: [],
      selectedItem: {},
    }
  },
}
</script>

<script>
import BtnSquare from '@/components/common/BtnSquare.vue';

export default {
  name: 'DropDownActuator',
  components: { BtnSquare },
  props: {
    "disabled": {
      type: Boolean,
      default: false,
    },
  }
};
</script>

<template>
  <BtnSquare :with-slot="true" @click="$emit('click', $event)" :disabled="disabled">
    <v-icon>mdi-dots-vertical</v-icon>
  </BtnSquare>
</template>

<style scoped lang="scss"></style>
<template>
  <DropMenu :items="dropMenuItems">
    <v-tooltip v-if="botHasLivechat && agent" bottom color="primary lighten-3">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-on="on"
          v-bind="attrs"
          class="mx-2 livechat-header-btn"
          :class="[
            agentStatus === statusTypes.otherTab
              ? 'livechat-header-btn--other-tab'
              : '',
          ]"
          color="white"
        >
          <v-icon
            v-if="agentStatus === statusTypes.otherTab"
            small
            color="primary lighten-4"
          >
            mdi-bell-off
          </v-icon>
          <v-icon v-if="agentStatus === statusTypes.online" color="green">
            mdi-face-agent
          </v-icon>
          <v-icon
            v-if="agentStatus === statusTypes.offline"
            color="primary lighten-4"
          >
            mdi-headphones-off
          </v-icon>
          <PulseBoeble v-if="hasNotifications" />
        </v-btn>
      </template>
      <span>
        {{ $t(`livechat.headerBtn.${agentStatus}`) }}
      </span>
    </v-tooltip>
  </DropMenu>
</template>
<script>
import { mapGetters } from 'vuex';
import DropMenu from '@/components/common/DropMenu.vue';
import PulseBoeble from '@/components/common/PulseBoeble.vue';
import { statusTypes } from '@/utils/livechat';

export default {
  name: 'LivechatHeaderBtn',
  components: {
    DropMenu,
    PulseBoeble,
  },
  data() {
    return {
      statusTypes,
    };
  },
  methods: {
    changeStatus(active) {
      if (active) {
        this.$store.dispatch('livechat/goOnline');
      } else {
        this.$store.dispatch('livechat/goOffline');
      }
    },
  },
  computed: {
    ...mapGetters('livechat', [
      'botHasLivechat',
      'incomeType',
      'agent',
      'agentStatus',
    ]),
    ...mapGetters('bots', ['currentBotId']),
    hasNotifications() {
      return this.incomeType === 'newChat' || this.incomeType === 'newMessage';
    },
    dropMenuItems() {
      return [
        {
          text: this.$t('livechat.headerBtn.btnGoOnline'),
          icon: 'mdi-face-agent',
          onClick: () => {
            this.changeStatus(true);
          },
          isDisabled: this.agentStatus !== statusTypes.offline,
        },
        {
          text: this.$t('livechat.headerBtn.btnGoOffline'),
          icon: 'mdi-headphones-off',
          onClick: () => {
            this.changeStatus(false);
          },
          isDisabled: this.agentStatus !== statusTypes.online,
        },
        {
          text: this.$t('livechat.headerBtn.btnTabActive'),
          icon: 'mdi-bell-ring',
          onClick: () => {
            this.changeStatus(true);
          },
          isDisabled: this.agentStatus !== statusTypes.otherTab,
        },
      ];
    },
  },
};
</script>
<style lang="scss" scoped>
.livechat-header-btn--other-tab {
  opacity: 0.7;
}

.v-btn:not(.v-btn--round).v-size--default {
  min-width: 50px;
  padding: 0 $spacer * 2;
}

.livechat-header-btn-pulse {
  position: absolute;
  top: 0;
  right: 0;
}
</style>

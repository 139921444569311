<template>
  <div>
    <div class="mb-2">
      {{
        $t('demo.stepsFrom', { current: currentStep + 1, total: stepsAmount })
      }}
    </div>
    <div class="d-flex">
      <div
        v-for="item in steps"
        class="stepper-item mr-2"
        :key="item.index"
        :class="[
          item.active && 'stepper-item--active',
          item.done && 'stepper-item--done',
        ]"
      ></div>
    </div>
    <DemoCompanyUrl />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { stepSlugs } from '@/components/demo/stepSlugs.js';
import DemoCompanyUrl from '@/components/demo/components/DemoCompanyUrl.vue';

export default {
  name: 'DemoStepper',

  components: { DemoCompanyUrl },
  computed: {
    ...mapGetters('demo', ['step', 'stepsAmount']),
    currentStep() {
      switch (this.step) {
        case stepSlugs.stepCompanyLoading:
          return 0;
        case stepSlugs.stepQuestions:
        case stepSlugs.stepQuestionsLoading:
          return 1;
        case stepSlugs.stepIntents:
          return 2;
        case stepSlugs.stepContact:
          return 3;
      }
    },
    steps() {
      return Array.from({ length: this.stepsAmount }, (v, i) => ({
        index: i,
        active: i === this.currentStep,
        done: i < this.currentStep,
      }));
    },
  },
};
</script>
<style lang="scss" scoped>
.stepper-item {
  width: 12 * $spacer;
  height: $spacer * 2;
  background-color: #e0e0e0;
  border-radius: 2px;
}

.stepper-item--active {
  background-color: var(--v-secondary-base);
  opacity: 0.5;
}
.stepper-item--done {
  background-color: var(--v-secondary-base);
}
</style>

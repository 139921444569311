<template>
  <v-hover
    v-if="isHistory"
    v-slot="{ hover }"
    open-delay="300"
    close-delay="5000"
  >
    <v-toolbar flat dense :extended="isExtended">
      <!-- Vorschlag (Tooltip Name) | Thema [weiterleitung icon]  --- Nicht Verstanden / Feedback / Goal / Takeover  -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-if="isSuggest"
            v-bind="attrs"
            class="mt-1"
            color="secondary darken-2"
            left
            v-on="on"
          >
            auto_awesome
          </v-icon>
        </template>
        <span>
          {{
            $t('chats.conversation.suggestions.dreaming', {
              name: displayName(suggest, $vuetify.lang.current),
            })
          }}
        </span>
      </v-tooltip>
      <v-divider class="ml-2 mr-2" />
      <span v-if="isSuggest && forward" class="ml-2">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              color="primary lighten-3"
              left
              x-small
              v-on="on"
            >
              directions
            </v-icon>
          </template>
          <span>{{ $t('chats.conversation.suggestions.forward') }}</span>
        </v-tooltip>
        <strong class="text-subtitle-2 primary--text text--lighten-2">
          {{ displayName(forward, $vuetify.lang.current) }}
        </strong>
      </span>
      <span v-else-if="isIntent" class="ml-2">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              color="primary lighten-3"
              left
              x-small
              v-on="on"
            >
              mdi-folder-text
            </v-icon>
          </template>
          <span>{{ $t('chats.conversation.intent.label') }}</span>
        </v-tooltip>
        <strong class="text-subtitle-2 primary--text text--lighten-2">
          {{ displayName(intent, $vuetify.lang.current) }}
        </strong>
      </span>
      <span v-else-if="isUnknown">
        <strong class="text-subtitle-2 primary--text text--lighten-2">
          {{ $t('chats.conversation.unknown.label') }}
        </strong>
      </span>
      <span v-else-if="isTakeover">
        <strong class="text-subtitle-2 primary--text text--lighten-2">
          {{ $t('chats.conversation.takeover.label') }}
        </strong>
      </span>
      <v-divider class="ml-2 mr-2" />
      <span v-if="isUnknown" class="primary--text text--lighten-2">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              color="grey lighten-1"
              class="mt-1"
              left
              v-on="on"
            >
              warning
            </v-icon>
          </template>
          <span>{{ $t('chats.conversation.unknown.label') }}</span>
        </v-tooltip>
      </span>
      <span v-else-if="isGoal" class="primary--text text--lighten-2">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" color="primary" class="mt-1" left v-on="on">
              flag
            </v-icon>
          </template>
          <span>{{ $t('chats.conversation.goal.label') }}</span>
        </v-tooltip>
      </span>
      <span v-else-if="isRatingPositive" class="primary--text text--lighten-2">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" color="success" class="mt-1" left v-on="on">
              thumb_up
            </v-icon>
          </template>
          <span>{{ $t('chats.conversation.rating.positive.label') }}</span>
        </v-tooltip>
      </span>
      <span v-else-if="isRatingNegative" class="primary--text text--lighten-2">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" color="error" class="mt-1" left v-on="on">
              thumb_down
            </v-icon>
          </template>
          <span>{{ $t('chats.conversation.rating.negative.label') }}</span>
        </v-tooltip>
      </span>
      <span v-else-if="isTakeover" class="primary--text text--lighten-2">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" color="primary" class="mt-1" left v-on="on">
              emoji_people
            </v-icon>
          </template>
          <span>{{ $t('chats.conversation.takeover.label') }}</span>
        </v-tooltip>
      </span>
      <span v-else class="primary--text text--lighten-2">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              color="grey lighten-2"
              class="mt-1"
              left
              v-on="on"
            >
              change_history
            </v-icon>
          </template>
          <span>{{ $t('chats.conversation.noreaction') }}</span>
        </v-tooltip>
      </span>
      <template v-if="(isIntent || forward) && isSimpleEdit" v-slot:extension>
        <v-fade-transition>
          <v-btn
            v-if="hover"
            :to="'/intent/' + intent"
            color="primary lighten-2"
            small
            tile
            class="mt-n6"
            :class="{ 'ml-10 ': isSuggest, 'ml-2': !isSuggest }"
            outlined
          >
            {{ $t('chats.history.intent.edit') }}
            <v-icon right small>edit</v-icon>
          </v-btn>
        </v-fade-transition>
        <v-fade-transition>
          <v-btn
            v-if="hover && isSuggest && isAdmin"
            disabled
            color="primary lighten-2"
            small
            tile
            class="mt-n6 ml-4"
            outlined
          >
            Vorschlag annehmen
            <v-icon right small>auto_awesome</v-icon>
          </v-btn>
        </v-fade-transition>
        <v-fade-transition>
          <v-btn
            v-if="hover && isAdmin"
            disabled
            color="primary lighten-2"
            small
            tile
            class="ml-4 mt-n6"
            outlined
          >
            Merken
            <v-icon right small>star</v-icon>
          </v-btn>
        </v-fade-transition>
      </template>
    </v-toolbar>
  </v-hover>
  <v-tooltip v-else-if="isLabel" bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-chip
        v-if="isRatingPositive"
        label
        color="success"
        x-small
        class="mr-1"
        style="max-height:8px"
        v-bind="attrs"
        v-on="on"
      />
      <v-chip
        v-else-if="isRatingNegative"
        label
        color="error"
        x-small
        class="mr-1"
        style="max-height:8px"
        v-bind="attrs"
        v-on="on"
      />
      <v-chip
        v-else-if="isGoal"
        label
        color="primary lighten-2"
        x-small
        class="mr-1"
        style="max-height:8px"
        v-bind="attrs"
        v-on="on"
      />
      <v-icon
        v-else-if="isSuggest"
        v-bind="attrs"
        class="mr-1"
        small
        color="secondary darken-2"
        v-on="on"
      >
        auto_awesome
      </v-icon>
      <v-chip
        v-else-if="isIntent || isUnknown"
        :outlined="isUnknown"
        color="grey lighten-1"
        class="mr-1"
        x-small
        label
        style="max-height:8px"
        v-bind="attrs"
        v-on="on"
      />
      <v-chip
        v-else-if="isTakeover"
        v-bind="attrs"
        style="max-height:8px"
        class="mr-1"
        x-small
        color="primary lighten-2"
        label
        v-on="on"
      />
    </template>
    <span v-if="isIntent">
      {{ displayName(intent, $vuetify.lang.current) }}
    </span>
    <span v-else-if="isSuggest">
      {{ displayName(suggest, $vuetify.lang.current) }}
    </span>
    <span v-else-if="isUnknown">
      {{ $t('chats.conversation.unknown.label') }}
    </span>
    <span v-else-if="isTakeover">
      {{ $t('chats.conversation.takeover.label') }}
    </span>
  </v-tooltip>
  <v-card
    v-else-if="isListItem"
    tile
    class="pt-0 pb-0 pl-6 pr-2 selectborder"
    flat
  >
    <v-card-text>
      <span v-if="isRatingPositive">
        <v-icon small left color="success">thumb_up</v-icon>
        {{ displayName(intent, $vuetify.lang.current) }}
      </span>
      <span v-else-if="isRatingNegative">
        <v-icon small left color="error">thumb_down</v-icon>
        {{ displayName(intent, $vuetify.lang.current) }}
      </span>
      <span v-else-if="isGoal">
        <v-icon left small color="primary lighten-2">flag</v-icon>
        {{ displayName(intent, $vuetify.lang.current) }}
      </span>
      <span v-else-if="isSuggest">
        <v-icon left small color="secondary darken-2">auto_awesome</v-icon>
        {{ displayName(suggest, $vuetify.lang.current) }}
        <span class="ml-2" v-if="isStandardIntent(suggest)">
          ({{ $t('chats.conversation.common.fromTemplate') }})
        </span>
      </span>
      <span v-else-if="isIntent">
        <v-icon left small color="grey lighten-2">mdi-folder-text</v-icon>
        {{ displayName(intent, $vuetify.lang.current) }}
      </span>
      <span v-else-if="isUnknown">
        <v-icon small left color="grey lighten-2">warning</v-icon>
        {{ $t('chats.conversation.unknown.label') }}
      </span>
      <span v-else-if="isTakeover">
        <v-icon small left color="primary lighten-2">emoji_people</v-icon>
        Takeover
      </span>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'AnalysisItem',
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    type: {
      type: String,
      default: 'history',
    },
  },
  computed: {
    ...mapGetters('intents', ['displayName', 'isStandardIntent']),
    ...mapGetters('auth', ['isAdmin', 'isSimpleEdit']),
    isUnknown() {
      return this.item.goal === 0 && this.item.unknown > 0;
    },
    intent() {
      if (this.isIntent) {
        return this.item.intents[0];
      } else if (this.isSuggest) {
        const intents = this.item.intents.filter((i) => {
          return i !== this.suggest;
        });

        if (intents.length > 0) {
          return intents[0];
        }
      }

      return null;
    },
    forward() {
      if (!this.unknown && this.isSuggest && this.suggest) {
        const suggest = this.suggest;
        const intents = this.item.intents.filter((i) => {
          return i !== suggest;
        });
        if (intents.length === 1) {
          return intents[0];
        }
      }

      return null;
    },
    suggest() {
      if (this.isSuggest) {
        const suggest = this.item.suggest.find((s) => {
          return s !== false && !s.startsWith('smalltalk_');
        });

        return suggest;
      }

      return null;
    },
    isIntent() {
      return this.item.intents.length === 1 && this.item.suggest.length <= 1;
    },
    isSuggest() {
      return (
        this.item.suggest.filter((s) => {
          return s !== false && !s.startsWith('smalltalk_');
        }).length >= 1
      );
    },
    isRatingPositive() {
      return !this.isUnknown && this.item.pos > 0;
    },
    isRatingNegative() {
      return !this.isUnknown && this.item.neg > 0;
    },
    isGoal() {
      return (
        !this.isUnknown &&
        (this.isIntent || this.isSuggest) &&
        this.item.goal > 0
      );
    },
    isTakeover() {
      return this.item.takeover > 0;
    },
    isHistory() {
      return (
        this.type === 'history' &&
        (this.isIntent || this.isUnknown || this.isSuggest || this.isTakeover)
      );
    },
    isLabel() {
      return this.type === 'label';
    },
    isListItem() {
      return (
        this.type === 'list' &&
        (this.isIntent || this.isUnknown || this.isSuggest || this.isTakeover)
      );
    },
    isExtended() {
      return !!(this.isIntent || this.forward);
    }
  },
};
</script>

<style scoped scss></style>

<template>
  <div>
    <v-card outlined class="pa-4">
      <div style="font-size: 40px; font-weight: bold;">
        {{ value }}
      </div>
      <div class="text-body-2 moin-label">
        {{ text }}
      </div>
    </v-card>
  </div>
</template>
<script>
export default {
  name: 'QmDashboardKpi',
  props: {
    value: {
      type: String,
      default: '76%',
    },
    text: {
      type: String,
      default: 'Hier könnte Text stehen ...',
    }
  }
};
</script>
<style scoped>
.moin-label {
  color: var(--v-primary-lighten3);
}
</style>

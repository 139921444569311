<template>
  <v-fade-transition>
    <div class="moin-fresh fill-height">
      <v-list-item-title
        class="primary--text text--lighten-2 font-weight-bold"
        style="padding-left: 64px"
      >
        {{ $t('welcome.expertTips') }}
      </v-list-item-title>
      <v-carousel
        :height="$vuetify.breakpoint.xs ? 200 : 110"
        hide-delimiter-background
        hide-delimiters
      >
        <template v-slot:prev="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            text
            v-on="on"
            color="primary"
            width="36"
            min-width="0"
            @click="trackIt('prev-tip')"
          >
            <v-icon>chevron_left</v-icon>
          </v-btn>
        </template>
        <template v-slot:next="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            text
            v-on="on"
            color="primary"
            width="36"
            min-width="0"
            @click="trackIt('next-tip')"
          >
            <v-icon>chevron_right</v-icon>
          </v-btn>
        </template>
        <v-carousel-item v-for="(current, i) in bestPractices" :key="i">
          <v-card class="fill-height px-12" flat>
            <v-card-text
              class="text-subtitle-1 primary--text py-1"
              style="max-width: 450px"
            >
              {{ current.tipBody }}
            </v-card-text>
          </v-card>
        </v-carousel-item>
      </v-carousel>
    </div>
  </v-fade-transition>
</template>

<script>
import { mapGetters } from 'vuex';
import { TrackingService } from '@/services/tracking';

export default {
  name: 'WelcomeExpertTipSlider',
  data() {
    return {
      currentPos: 0,
    };
  },
  computed: {
    ...mapGetters('auth', ['user']),
    ...mapGetters('welcome', ['bestPractices']),
    ...mapGetters('bots', ['currentBotStage', 'currentBotId']),
  },
  mounted() {
    this.$store.dispatch('welcome/getBestPractices', {
      locale: this.user.language,
      stage: this.currentBotStage,
    });
  },
  methods: {
    trackIt(type) {
      TrackingService.trackWelcomeView({
        botId: this.currentBotId,
        type,
        source: 'WelcomeExpertTipSlider',
        payload: {
          bestPracticesLength: this.bestPractices.length,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.v-window__prev,
.v-window__next {
  border-radius: 0px !important;
  background-color: transparent !important;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{staticStyle:{"max-height":"100vh","height":"100vh"}},[_c('HubMenu'),_c('Appbar'),_c('HubList',{attrs:{"value":_vm.selectedChat,"open-sidebar":_vm.$vuetify.breakpoint.mdAndUp,"sidebar-items":[
        { title: _vm.$t('chats.history.title'), icon: 'message', name: 'info' },
      ],"no-active-background":"","loading":_vm.isLoading,"hide-icon":"","item-key":"uuid","title":_vm.$t('conversation.title'),"items":_vm.filteredChats,"sidebar":true,"sidebar-width":7},on:{"input":(chat) => _vm.changeSelectedChat(chat)},scopedSlots:_vm._u([{key:"item",fn:function({ item, selected }){return [_c('InsightsListItem',{attrs:{"item":item,"selected":selected}})]}},{key:"toolbar",fn:function(){return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-chip',_vm._g(_vm._b({staticClass:"px-4",attrs:{"label":"","color":"secondary darken-2"},on:{"click":function($event){return _vm.trackIt('open-beta-dialog')}}},'v-chip',attrs,false),on),[_vm._v(" Beta Version ")])]}}]),model:{value:(_vm.isBetaMenuOpen),callback:function ($$v) {_vm.isBetaMenuOpen=$$v},expression:"isBetaMenuOpen"}},[_c('v-card',{attrs:{"max-width":"250px"}},[_c('v-card-text',[_vm._v(_vm._s(_vm.$t('conversation.beta.text1'))+".")]),_c('v-card-text',[_vm._v(_vm._s(_vm.$t('conversation.beta.text2'))+".")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"to":"/chats?old=true","text":""}},[_vm._v(" "+_vm._s(_vm.$t('conversation.beta.btn'))+" ")]),_c('v-spacer')],1)],1)],1)]},proxy:true},{key:"item-action",fn:function({ item, selected }){return [_c('span',[(selected && !_vm.isLoading)?_c('DropMenu',{attrs:{"isDisabled":_vm.isLoading,"items":[
              {
                text: _vm.$t('conversation.menu.protocol'),
                icon: 'open_in_new',
                to: `/chats/messaging/${item.uniqueUserId}?jumpto='${item.uuid}'`,
                target: '_blank',
              },
              {
                text: _vm.$t('conversation.menu.delete'),
                icon: 'delete',
                onClick: () => _vm.openDeleteDialog(item.uniqueUserId),
                isDisabled: !_vm.isAdmin && !_vm.isOwner,
              },
            ]}},[_c('BtnSquare',{attrs:{"icon":"mdi-dots-horizontal"},on:{"click":function($event){return _vm.trackIt('conversation-dropdown-menu')}}})],1):_c('v-icon',{attrs:{"color":"primary lighten-3","small":""}},[_vm._v(" chevron_right ")])],1)]}},{key:"item-append",fn:function({ item, selected }){return [_vm._l((item ? item.all : []),function(t){return [(selected)?_c('AnalysisItem',{key:'item' + t._id,attrs:{"type":"list","item":t}}):_vm._e()]})]}},{key:`sidebar.info`,fn:function({ item }){return [(!item)?_c('v-card',{staticClass:"mt-8",attrs:{"flat":""}},[_c('v-container',{staticClass:"pt-8"},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-icon',{attrs:{"color":"grey lighten-1"}},[_vm._v("speaker_notes_off")])],1),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-card-title',{staticClass:"grey--text"},[_vm._v(" "+_vm._s(_vm.$t('conversation.history.nochat.title'))+" ")])],1),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-card-subtitle',{staticClass:"grey--text"},[_vm._v(" "+_vm._s(_vm.$t('conversation.history.nochat.subtitle'))+" ")])],1)],1)],1):(_vm.isCleanUp(item))?_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('conversation.dataPrivacyNotice.title'))+" ")]),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$t('conversation.dataPrivacyNotice.body'))+" ")])],1):[_vm._l((_vm.structure.before),function(block){return [(block._id.name === 'conversation')?_c('v-card',{key:block._id.name + '_' + block._id.uuid,staticClass:"ma-4",attrs:{"flat":""}},[_c('v-card-subtitle',{staticClass:"text-center"},[_c('v-chip',{attrs:{"color":"grey","outlined":""}},[_vm._v(" "+_vm._s(_vm.$t('chats.conversation.previouslabel'))+" "+_vm._s(_vm.i18nDateFormat( block.created, _vm.$vuetify.lang.current ))+" ")])],1)],1):_vm._e()]}),_vm._l((_vm.blocks),function(block){return [(
                _vm.structure.uuids[block._id] &&
                  !_vm.structure.uuids[block._id].livechat
              )?_c('v-card',{key:block._id,staticClass:"ml-4 mr-4 mt-4 mb-0",attrs:{"color":"white","flat":""}},[(_vm.isConversationStart(_vm.structure.uuids[block._id].actions))?_c('v-card-subtitle',{staticClass:"text-center mt-4 white"},[_c('v-chip',{attrs:{"color":"primary","outlined":""}},[_vm._v(" "+_vm._s(_vm.$t('chats.conversation.startlabel'))+" "+_vm._s(_vm.i18nDateFormat( block.created, _vm.$vuetify.lang.current ))+" ")])],1):_vm._e(),_c('AnalysisItem',{attrs:{"item":block}}),_vm._l((_vm.structure.uuids[block._id].actions),function(action,idx){return [(
                    (action._id.name === 'interaction' ||
                      action._id.name === 'action') &&
                      _vm.userInput(action, idx, block._id) !== false
                  )?_c('v-card',{key:action._id.name + action._id.uuid,staticClass:"ml-6",attrs:{"color":"white","max-width":"600px","flat":""}},[_c('v-row',[_c('v-col',{staticClass:"pt-2 pb-1",attrs:{"cols":"8","offset":"1"}},[(
                          action._id.name === 'interaction' &&
                            !_vm.isUserInputButton(action)
                        )?_c('v-card-text',{staticClass:"primary lighten-3 white--text mb-2 rounded-custom"},[_vm._v(" "+_vm._s(_vm.userInput(action, idx, block._id))+" ")]):(action._id.name === 'interaction')?_c('v-chip',{staticClass:"float-right pl-4 pr-4 mb-2",attrs:{"color":"primary lighten-3"}},[_vm._v(" "+_vm._s(_vm.userInput(action, idx, block._id))+" ")]):_vm._e(),(
                          false &&
                            action._id.name === 'interaction' &&
                            !_vm.isUserInputButton(action)
                        )?_c('NlpQuick',{attrs:{"text":_vm.userInput(action),"intent":block.intents.length > 0
                            ? block.intents[0]
                            : 'unknown'}}):_vm._e()],1),_c('v-col',{staticClass:"pt-0 pb-1",attrs:{"cols":"3"}},[(action._id.name === 'interaction')?_c('v-card-text',{staticClass:"pt-1"},[_c('v-icon',{attrs:{"color":"primary lighten-3","left":""}},[_vm._v(" account_circle ")]),_vm._v(" "+_vm._s(_vm.formatTime(action.created, _vm.$t('common.timeFormatShort')))+" ")],1):_vm._e()],1)],1),(action._id.name === 'action')?[_c('v-row',{staticClass:"pb-4"},[_c('v-col',{staticClass:"pt-2 pb-2 pl-10",attrs:{"cols":"8"}},[_vm._l((action.protocols),function(protocol,outerIndex){return [_vm._l((protocol.outgoingMessages),function(message,index){return [_c('v-row',{key:'message-' + outerIndex + '-' + index},[_c('v-col',{staticClass:"pt-2 pb-2 pl-4"},[(message.message && message.message.text)?_c('v-textarea',{staticClass:"text-body-2 pt-0 pb-0",attrs:{"hide-details":"","dense":"","outlined":"","disabled":"","value":message.message.text,"auto-grow":"","rows":"1"}}):(
                                    message.message &&
                                      message.message.attachment &&
                                      message.message.attachment.type ===
                                        'button' &&
                                      message.message.attachment.payload &&
                                      message.message.attachment.payload.text
                                  )?_c('v-textarea',{staticClass:"text-body-2 pt-0 pb-0",attrs:{"hide-details":"","dense":"","outlined":"","disabled":"","value":message.message.attachment.payload.text,"auto-grow":"","rows":"1"}}):(
                                    message.message &&
                                      message.message.attachment &&
                                      message.message.attachment.type ===
                                        'cards'
                                  )?_c('v-card',{staticClass:"d-flex justify-center",attrs:{"min-height":"100px","flat":"","color":"grey lighten-3"}},[_c('v-card',{staticClass:"grey lighten-3 align-self-center",attrs:{"flat":""}},[_c('v-card-subtitle',[_c('v-icon',{attrs:{"left":""}},[_vm._v("view_carousel")]),_vm._v(" Slides ")],1)],1)],1):(
                                    message.message &&
                                      message.message.attachment &&
                                      message.message.attachment.type ===
                                        'image'
                                  )?_c('v-card',{staticClass:"d-flex justify-center",attrs:{"min-height":"100px","flat":"","color":"grey lighten-3"}},[_c('v-card',{staticClass:"grey lighten-3 align-self-center",attrs:{"flat":""}},[_c('v-card-subtitle',[_c('v-icon',{attrs:{"left":""}},[_vm._v("image")]),_vm._v(" Media ")],1)],1)],1):(
                                    message.message &&
                                      message.message.attachment &&
                                      message.message.attachment.type ===
                                        'video'
                                  )?_c('v-card',{staticClass:"d-flex justify-center",attrs:{"min-height":"100px","flat":"","color":"grey lighten-3"}},[_c('v-card',{staticClass:"grey lighten-3 align-self-center",attrs:{"flat":""}},[_c('v-card-subtitle',[_c('v-icon',{attrs:{"left":""}},[_vm._v("image")]),_vm._v(" Media ")],1)],1)],1):_vm._e(),(
                                    message.message &&
                                      message.message.attachment &&
                                      message.message.attachment.type ===
                                        'button' &&
                                      message.message.attachment.payload
                                  )?_vm._l((message.message
                                      .attachment.payload.buttons),function(btn,btnIndex){return _c('v-btn',{key:'btn-' + btnIndex,staticClass:"mt-2 mr-2",attrs:{"small":"","outlined":"","disabled":"","text":""}},[_vm._v(" "+_vm._s(btn.title)+" ")])}):_vm._e(),(
                                    message.quick_replies &&
                                      message.quick_replies.length > 0
                                  )?_c('span',_vm._l((message.quick_replies),function(quick,labelIndex){return _c('v-chip',{key:'quick-' + labelIndex,staticClass:"mt-2 mr-2",attrs:{"disabled":"","outlined":"","small":"","text-color":"primary"}},[_vm._v(" "+_vm._s(quick.label)+" ")])}),1):_vm._e()],2)],1)]})]})],2)],1)]:_vm._e()],2):_vm._e()]}),_vm._l((_vm.structure.after),function(after){return [(after._id.name === 'conversation')?_c('v-card',{key:after._id.name + '_' + after._id.uuid,staticClass:"ma-4",attrs:{"flat":""}},[_c('v-card-subtitle',{staticClass:"text-center"},[_c('v-chip',{attrs:{"outlined":""}},[_vm._v(" "+_vm._s(_vm.$t('chats.conversation.nextlabel'))+" "+_vm._s(_vm.i18nDateFormat(after.created, _vm.$vuetify.lang.current))+" ")])],1)],1):_vm._e()]})],2):(
                _vm.structure.uuids[block._id] &&
                  _vm.structure.uuids[block._id].livechat
              )?_c('v-card',{key:block._id,staticClass:"ml-4 mr-4 mt-4 mb-0",attrs:{"color":"white","flat":""}},[_c('AnalysisItem',{attrs:{"item":block}}),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12"}},[_c('v-card-subtitle',{staticClass:"text-center"},[_c('v-chip',{attrs:{"color":"secondary darken-2","to":'/chats/messaging/' +
                          item.uniqueUserId +
                          '?jumpto=' +
                          block._id,"target":"_blank"}},[_vm._v(" Livechat mit einem Agenten "),_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","right":""}},[_vm._v("open_in_new")])],1)],1)],1)],1)],1):_vm._e()]})]]}},{key:"list-header",fn:function(){return [_c('v-card',{staticClass:"pl-0 pr-0 pt-4 pb-0 ml-0 mr-0 mt-0 mb-0",attrs:{"flat":"","tile":""}},[_c('v-card-title',[_c('v-btn',{attrs:{"icon":"","tile":"","disabled":_vm.isLoading || _vm.dateFilterIsNotAllowed},on:{"click":function($event){return _vm.changeDay(-1)}}},[_c('v-icon',[_vm._v("chevron_left")])],1),_c('v-spacer'),_c('v-menu',{ref:"menu",attrs:{"disabled":_vm.isLoading,"close-on-content-click":false,"return-value":_vm.dateFilter,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.dateFilter=$event},"update:return-value":function($event){_vm.dateFilter=$event}},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","disabled":_vm.isLoading}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("event")]),_vm._v(" "+_vm._s(_vm.dateFilterString)+" ")],1)]}}]),model:{value:(_vm.datepickerMenu),callback:function ($$v) {_vm.datepickerMenu=$$v},expression:"datepickerMenu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","allowed-dates":_vm.allowedDates,"color":"secondary darken-2"},model:{value:(_vm.dateFilter),callback:function ($$v) {_vm.dateFilter=$$v},expression:"dateFilter"}},[(_vm.cleanupDate)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"primary lighten-2"}},'v-icon',attrs,false),on),[_vm._v(" verified_user ")])]}}],null,false,2096903956)},[_c('span',[_c('v-icon',{attrs:{"left":"","color":"white","x-small":""}},[_vm._v("verified_user")]),_c('span',{domProps:{"innerHTML":_vm._s(
                        _vm.$t('conversation.dataPrivacyActive', {
                          date: _vm.formatDateTime(_vm.cleanupDate.toISOString(), _vm.$t('common.dateFormatShort')),
                        })
                      )}})],1)]):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.datepickerMenu = false}}},[_vm._v(" "+_vm._s(_vm.$t('common.cancel'))+" ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.$refs.menu.save(_vm.dateFilter);
                    _vm.loadChats();}}},[_vm._v(" "+_vm._s(_vm.$t('common.ok'))+" ")])],1)],1),_c('v-spacer'),_c('v-btn',{attrs:{"disabled":_vm.dateFilterIsToday || _vm.isLoading,"tile":"","icon":""},on:{"click":function($event){return _vm.changeDay(+1)}}},[_c('v-icon',[_vm._v("chevron_right")])],1)],1),_c('ChatInsightsFilter',{attrs:{"channels":_vm.channels,"chats":_vm.chats},on:{"loading":_vm.onFilterLoading},model:{value:(_vm.filteredChats),callback:function ($$v) {_vm.filteredChats=$$v},expression:"filteredChats"}}),_c('v-card-title',{staticClass:"pb-1"},[(!_vm.isLoading)?_c('span',{class:{
                'ml-2': _vm.filteredChats.length < 10,
                'mr-3': _vm.filteredChats.length < 10,
                'mr-2': _vm.filteredChats.length >= 10,
              },domProps:{"textContent":_vm._s(_vm.filteredChats.length)}}):_c('v-progress-circular',{staticClass:"ml-1 mr-3",attrs:{"indeterminate":"","color":"darken secondary-2","size":16,"width":1}}),_vm._v(" "+_vm._s(_vm.$t('conversation.title'))+" ")],1)],1),_c('v-divider')]},proxy:true}],null,true)}),_c('HubDialog',{attrs:{"title":_vm.$t('conversation.deleteDialog.title'),"primary-label":_vm.$t('common.remove'),"primary-icon":"save","secondary-label":_vm.$t('common.cancel'),"secondary-icon":"close"},on:{"onPrimary":_vm.deleteConversation,"onSecondary":function($event){_vm.isDeleteDialogOpen = false}},model:{value:(_vm.isDeleteDialogOpen),callback:function ($$v) {_vm.isDeleteDialogOpen=$$v},expression:"isDeleteDialogOpen"}},[_c('div',{staticClass:"text-body-1 py-4"},[_vm._v(" "+_vm._s(_vm.$t('conversation.deleteDialog.description'))+" ")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<!-- Template for content editor, every template needs to have a corresponding template vue file in this folder. -->
<!-- This is implemented with the dynamic component pattern, https://medium.com/scrumpy/dynamic-component-templates-with-vue-js-d9236ab183bb -->
<template>
  <!-- component will be created if its content is loaded -->
  <!-- IMPORTANT: the component need to implement all properties and events defined here -->
  <div>
    <ActionRow v-if="parents.length > 1">
      <template #line>
        <ActionLine :blockType="blockType" hideLine :options="options" />
      </template>
      <template #content>
        <span class="grey--text">{{
            $t(`action.info.multipleParent${parents.length-1===1?'':'s'}`, {count: parents.length-1})
          }}</span>
        <BtnSquare outlined small icon="navigate_before" colorIcon="grey" class="ml-4" @click="prevParent"></BtnSquare>
        <BtnSquare outlined small icon="navigate_next" colorIcon="grey" class="ml-2" @click="nextParent"></BtnSquare>
      </template>
    </ActionRow>
    <component
      v-if="component && !contentLoading"
      :is="component"
      :content="content"
      :box="box"
      :options="options"
      :contentTree="contentTree"
    />
    <!-- loading state of a conent part -->
    <ContentTemplateLoaderLoading
      v-if="contentLoading"
      :contentType="template"
      :options="options"
    />
  </div>
</template>

<script>

import ContentTemplateLoaderLoading from '@/components/editor/components/ContentTemplateLoaderLoading.vue';
import ActionRow from '@/components/editor/components/ActionRow.vue';
import ActionLine from '@/components/editor/components/ActionLine.vue';
import BtnSquare from "@/components/common/BtnSquare.vue"
import { contentTreeProperties } from '@/mixins/content/contentTemplate';
import { blockTypes, contentTypes } from '@/components/editor/actionConsts';
import { useEventBus } from '@vueuse/core';
import { actionEditorEvents } from '@/utils/actionEditor';

export default {
  name: 'ContentTemplateLoader',
  props: {
    content: Object,
    template: String,
    box: String,
    options: Object,
    contentTree: Object,
    refName: {
      type: String,
      default: () => null,
    }
  },
  mixins: [contentTreeProperties],
  mounted() {
    this.init();
  },
  components: {
    ContentTemplateLoaderLoading,
    ActionRow,
    ActionLine,
    BtnSquare
  },
  data() {
    return {
      component: null,
      blockTypes,
      pathChangedEmitter: useEventBus(actionEditorEvents.pathChanged),
      scrollToListener: useEventBus(actionEditorEvents.scrollTo),
      scrollToListenerHandler: null,
    };
  },
  created() {
    this.scrollToListenerHandler = this.scrollToListener.on(this.scrollTo);
  },
  beforeDestroy() {
    if (this.scrollToListenerHandler) this.scrollToListenerHandler();
  },
  watch: {
    template() {
      this.init();
    },
    contentLoading() {
      if (!this.contentLoading) {
        this.$emit('onLoaded');
      }
    },
  },
  computed: {
    contentLoading() {
      if (!this.content || !this.content[this.box] || this.content[this.box].loading) {
        return true;
      }

      return false;
    },
    blockType() {
      return this.blockTypes.TREE_PARENTS;
    },
    parents() {
      return this.currentContentNode?.parents || [];
    },
    previousBox() {
      const currentPath = this.contentTree.getCurrentPath(this.box, this.options.selectedPathes);
      if (currentPath.length > 1) {
        const previousBox = currentPath[currentPath.length - 2];
        return previousBox;
      } 

      return null;
    },
    loader() {
      // template is mandatory
      if (!this.template) {
        return () => import('./contentTemplates/NotSupported');
      }

      // Some templates got no following content
      // TODO : feedback und none sind keine echten Templates, da keine Box dazu existiert und damut auch kein Content geladen wird
      // if (['feedback', 'none'].includes(this.template)) {
      //   return () => import(`./Empty`);
      // }
      // return () => { return { then: () => { return  {catch: () => {} }}}; };
      // try to load template, if template is not found, load NotSupported template
      return () => import(`./contentTemplates/${this.template}`);
    },
  },
  methods: {
    init() {
      this.loader()
        .then(() => {
          this.component = () => this.loader();
        })
        .catch((e) => {
          console.error(e);
          this.component = () => import('./contentTemplates/NotSupported'); // fallback to the default template 'NotSupported' for error handling
        });
    },
    prevParent() {
      const indexOfCurrent = this.parents.findIndex((p) => p.box === this.previousBox);
      let prevIndex = indexOfCurrent - 1;
      if (indexOfCurrent === -1) return;
      if (indexOfCurrent === 0) {
        prevIndex = this.parents.length - 1;
      }
      this.pathChangedEmitter.emit({
        box: this.box,
        pathChangeTo: this.parents[prevIndex],
      });
    },
    nextParent() {
      const indexOfCurrent = this.parents.findIndex((p) => p.box === this.previousBox);
      let nextIndex = indexOfCurrent + 1;
      if (indexOfCurrent === -1) return;
      if (indexOfCurrent === this.parents.length - 1) {
        nextIndex = 0;
      }
      this.pathChangedEmitter.emit({
        box: this.box,
        pathChangeTo: this.parents[nextIndex],
      });
    },
    scrollTo({box, template}) {
      if (box === this.box) {
        this.$el.scrollIntoView({ behavior: "smooth" });
      } else if (template === this.template) {
        // if no box is given, scroll to the given template
        this.$el.scrollIntoView({ behavior: "smooth" });
      }
    }
  },
};
</script>
import ApiService from './api';
import axios from 'axios';

async function checkFileAccess({ botId, uuId }) {
  return ApiService.get(`/schaltzentrale/chats/files/${botId}/${uuId}`);
}

export async function checkForFiles({ botId, uuId }) {
  const res = await checkFileAccess({ botId, uuId });
  const events = res?.data?.events;
  if (!events.length) return null;
  const results = [];
  for (const event of events) {
    const fileDownload = await axios.get(
      `/files/download/${botId}/${event.secret}/${event.generatedFilename}/${event.originalFilename}`,
      {
        responseType: 'arraybuffer',
      }
    );
    results.push(fileDownload);
  }
  return results;
}

export default { checkForFiles, checkFileAccess };

<template>
  <div class="moin-fresh">
    <v-row>
      <v-col class="d-flex">
        <div class="hidden-sm-and-down pl-1 mr-8" style="width: 280px;">
          <div
            class="avatar overflow-hidden"
            style="min-width: 100px; max-width: 140px; width: 85%; border-radius: 50%"
          >
            <Avatar
              circle-border-size="0"
              arm="#18293E"
              shirt="#A5E1DE"
              not-scaled
              default-avatar
              background="radial-gradient(50% 50% at 50% 50%, rgba(0, 64, 105, 0.6) 0%, rgba(238, 238, 238, 6e-05) 100%);"
            />
          </div>
        </div>
        <div class="pt-8 pt-sm-0">
          <h2 class="text-h1 mb-3 primary--text text--lighten-2" data-cy="welcome-title">Moin!</h2>
          <p class="mb-3 text-subtitle-1">
            <span v-html="$t(`welcome.welcomeText.${currentBotStage}`)" />
          </p>
          <p class="mb-0 text--lighten-2 text-subtitle-1">
            <span
              v-if="currentBotStage !== stageTypes.LIVE"
              v-html="$t('welcome.welcomeText.description')"
            />
          </p>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { stageTypes } from '@/store/modules/welcome';
import Avatar from '@/components/Avatar';

export default {
  name: 'WelcomeSupport',
  components: {
    Avatar,
  },
  data() {
    return {
      stageTypes,
    };
  },
  computed: {
    ...mapGetters('bots', ['currentBotStage']),
  },
};
</script>

<template>
  <v-row>
    <v-col cols="12" md="6">
      <div class="pa-4" :class="{ 'text-center': $vuetify.breakpoint.mdAndUp }">
        <div class="text-body-2 font-weight-bold">
          <v-icon size="32" v-if="widgetActive" class="green--text mr-1">
            visibility
          </v-icon>
          <v-icon v-else size="32" class="error--text mr-1">
            visibility_off
          </v-icon>
          <div>
            {{
              widgetActive
                ? $t('editor.widget.activation.on.title')
                : $t('editor.widget.activation.off.title')
            }}
          </div>
        </div>
        <span class="text-body-2 grey--text text--darken-2">
          {{
            widgetActive
              ? $t('editor.widget.activation.on.description')
              : $t('editor.widget.activation.off.description')
          }}
        </span>
      </div>
    </v-col>
    <v-col cols="12" md="6" :class="[$vuetify.breakpoint.smAndDown && 'mt-n4 pt-0']">
      <v-card-title>
        {{ $t('editor.widget.activation.title') }}
      </v-card-title>
      <div class="px-4">
        <v-switch
            :input-value="widgetActive"
            :color="widgetActive ? 'green' : 'grey'"
            inset
            :label="$t('editor.widget.activation.switchLabel')"
            @change="(v) => $emit('onChange', v)"
          />
      </div>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'WidgetActivation',
  props: {
    widgetActive: {
      type: Boolean,
    },
  },
};
</script>
<template>
  <v-container fluid class="white px-4" style="border-radius: 4px 4px 0 0">
    <v-row>
      <v-col cols="12" :lg="compareDisabled ? 6 : 4" v-if="hasChannels">
        <v-select
          :value="channel"
          :items="channels"
          :label="$t('stats.channelsFilter')"
          :placeholder="$t('channels.select-all')"
          prepend-inner-icon="call_split"
          item-text="displayName"
          item-value="channelId"
          @change="changeChannel"
        />
      </v-col>
      <v-col v-if="!isAnualReview" cols="12" sm="6" :lg="compareDisabled ? 6 : hasChannels ? 4 : 6">
        <DateRange
          v-model="rangeValue"
          @change="changeDateRange"
          :disabled="isAnualReview"
        />
      </v-col>
      <v-col
        v-if="!compareDisabled && !isAnualReview"
        cols="12"
        sm="6"
        :lg="hasChannels ? 4 : 6"
      >
        <v-select
          v-model="rangeCompareValue"
          :items="dateOptionsCompare"
          :label="$t('dateRange.labelDateRangeCompare')"
          prepend-inner-icon="date_range"
          item-text="text"
          item-value="value"
          @change="changeDateRangeCompare"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
import DateRangeUtil, { dateRangeTypes } from '@/utils/dateRange';
import DateRange from '@/components/common/DateRange.vue';
import { TrackingService } from '@/services/tracking';

export default {
  name: 'StatsHeader',
  components: {
    DateRange,
  },
  props: {
    compareDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rangeValue: dateRangeTypes.last28days,
      dateRangeTypes,
      rangeCompareValue: null,
      language: 'de',
    };
  },
  created() {
    this.init();
  },
  watch: {
    currentBot() {
      this.handleChange();
      this.setQuery();
    },
  },
  computed: {
    ...mapGetters('auth', ['user']),
    ...mapGetters('bots', ['currentBot', 'currentBotId', 'currentChannels']),
    ...mapGetters('stats', [
      'dateRange',
      'dateRangeCompare',
      'dateCompareValue',
      'channel',
      'isAnualReview',
    ]),
    channels() {
      const firstChannelBlocked = this.currentChannels[0].blocked;
      const channels = this.currentChannels.filter((c) => !c.blocked);
      
      if (firstChannelBlocked && channels.length === 1) {
        // HACK: if first channel is blocked and there is only one channel, we need to add the first channel to the list
        return [
          {
            displayName: this.$t('intents.details.channels.all'),
            channelId: null,
          },
          ...channels,
        ];
      }

      if (channels?.length <= 1) return channels;
      return [
        {
          displayName: this.$t('intents.details.channels.all'),
          channelId: null,
        },
        ...channels,
      ];
    },

    dateOptionsCompare() {
      return [
        {
          text: this.$t('dateRange.emptyComparePeriod'),
          value: dateRangeTypes.emptyCompare,
        },
        {
          text: this.$t('dateRange.periodBefore'),
          value: dateRangeTypes.periodBefore,
        },
        {
          text: this.$t('dateRange.periodYearBefore'),
          value: dateRangeTypes.periodYearBefore,
        },
      ];
    },
    hasChannels() {
      return this.channels?.length > 1;
    },
  },
  methods: {
    async init() {
      if (await this.takeQuery()) return;
      
      if (this.dateRange.length) {
        this.setValuesFromStore();
        this.setQuery();
        return;
      } else {
        this.changeDateRange(this.rangeValue);
      }

      this.trackIt('stats-init-without-query');
    },
    setValuesFromStore() {
      this.rangeValue = DateRangeUtil.getRangeValueFromDates([
        this.dateRange[0].format('YYYY-MM-DD'),
        this.dateRange[1].format('YYYY-MM-DD'),
      ]);

      if (this.dateCompareValue) {
        this.rangeCompareValue = this.dateCompareValue;
      }
    },
    changeDateRange(value) {
      this.handleChange();
      let newDates = DateRangeUtil.getRangeDatesFromValue(value);


      this.$store.commit('stats/setDateRange', {
        dateRange: newDates,
        language: this.user.language,
      });
      this.$nextTick(this.changeDateRangeCompare(this.rangeCompareValue));
      this.setQuery();

      this.trackIt('stats-change-date-range');
    },
    changeDateRangeCompare(value) {
      if (!value) {
        value = this.rangeCompareValue;
      }

      this.handleChange();

      let newDates;
      switch (value) {
        case dateRangeTypes.periodBefore:
          newDates = DateRangeUtil.getRangeSameBefore(this.dateRange);
          break;
        case dateRangeTypes.periodYearBefore:
          newDates = DateRangeUtil.getRangeSamePrevYear(this.dateRange);
          break;
        case dateRangeTypes.emptyCompare:
          newDates = null;
          break;
      }

      this.$store.commit('stats/setDateRangeCompare', {
        dateRange: newDates,
        language: this.user.language,
      });
      this.$store.commit('stats/setDateCompareValue', value);
      this.setQuery();

      this.trackIt('stats-change-date-range-compare');
    },
    changeChannel(selectedChannel) {
      this.handleChange();

      this.$store.commit('stats/setValue', {
        key: 'channel',
        value: selectedChannel,
      });

      this.setQuery();
    },
    handleChange() {
      this.$store.dispatch('exportStats/clear');
    },
    async takeQuery() {
      let isSet = false;
      // f= from, t= till
      if (this.$route.query.f && this.$route.query.t) {
        const newRange = [this.$route.query.f, this.$route.query.t];
        this.$store.commit('stats/setDateRange', {
          dateRange: DateRangeUtil.getRangeWithDates(newRange),
          language: this.user.language,
        });
        isSet = true;
        this.rangeValue = DateRangeUtil.getRangeValueFromDates(newRange);
        if (this.rangeValue.includes('–')) {
          this.addCustomDateItem(this.rangeValue);
        }
      }
      // r= rangeCompareValue
      if (this.$route.query.r) {
        this.rangeCompareValue = this.$route.query.r;
        this.$store.commit('stats/setDateCompareValue', this.$route.query.r);
        this.changeDateRangeCompare(this.rangeCompareValue);
      }

      // c= channel
      if (this.$route.query.c) {
        const channelId = this.$route.query.c;

        let channelsLoaded = !!this.currentChannels.length;

        while (!channelsLoaded) {
          await new Promise((resolve) => setTimeout(resolve, 100));
          channelsLoaded = !!this.currentChannels.length;
        }

        if (this.currentChannels.find((c) => c.channelId === channelId)) {
          this.changeChannel(channelId);
          isSet = true;
        } else {
          this.setQuery();
        }
      }

      if (isSet) this.trackIt('stats-init-with-query');

      return isSet;
    },
    setQuery() {
      let isChanged = false;
      const DF = 'YYYY-MM-DD';
      const query = {
        f: this.dateRange[0].format(DF),
        t: this.dateRange[1].format(DF),
        r: this.rangeCompareValue,
      };

      // dateRange
      if (
        this.$route.query.f !== this.dateRange[0].format(DF) ||
        this.$route.query.t !== this.dateRange[1].format(DF)
      ) {
        isChanged = true;
      }

      // dateRangeCompare
      if (
        this.rangeCompareValue &&
        this.$route.query.r !== this.rangeCompareValue
      ) {
        isChanged = true;
      }

      // channel
      if (this.channels.length > 1) {
        query.c = this.channel;
      } else {
        query.c = '';
      }

      if (this.$route.query.c !== this.channel) {
        isChanged = true;
      }

      if (isChanged) {
        this.$router
          .replace({
            query,
          })
          .catch(() => true);
      }
    },
    addCustomDateItem(value) {
      const dates = value.split('–');
      const dateRange = DateRangeUtil.getRangeWithDates(dates);
      this.datesFormated = DateRangeUtil.getDatesFormatted(dateRange);

      this.$store.commit('dateRange/addOwnDateRange', {
        text: this.datesFormated,
        value,
      });
    },
    trackIt(type = 'view') {
      const body = {
        botId: this.currentBotId,
        type,
        source: 'StatsOverview.vue',
        payload: {
          channel: this.channel,
          fullPath: this.$route.fullPath,
        },
      };

      TrackingService.trackStatsView(body);
    },
  },
};
</script>

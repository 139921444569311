import axios from 'axios';

// {
//   "uniqueBotId": string,
//   "intent": string,
//   "message": string,
//   "contents": {boxes}
// }

const timeoutAmount = 600000;

export const AssistantService = {
  async startConversation({ uniqueBotId, intent, message, contents, signal, channel }) {
    try {
      const data = {
        uniqueBotId,
        intent,
        message,
        contents,
        channel,
      };

      const res = await axios.post('/schaltzentrale/assistant/create', data, {
        timeout: timeoutAmount,
      });

      if (signal.aborted) {
        return false;
      }

      if (!res.data?.conversation) {
        return {
          error: 'No conversation in response?!',
        };
      }

      return res.data;
    } catch (error) {
      console.error('startConversation', error);
      return {
        error,
      };
    }
  },
  async continueConversation({ uniqueBotId, conversationId, message, signal }) {
    try {
      const data = {
        uniqueBotId,
        conversationId,
        message,
      };

      const res = await axios.post('/schaltzentrale/assistant/continue', data, {
        timeout: timeoutAmount,
      });

      if (signal.aborted) {
        return false;
      }


      return res.data;
    } catch (error) {
      console.error('continueConversation', error);
      return {
        error,
      };
    }
  },
  async getConversation({ uniqueBotId, conversationId }) {
    try {
      const res = await axios.get(`/schaltzentrale/assistant/get/${conversationId}/${uniqueBotId}`, {
        timeout: timeoutAmount,
      });
      
      return res.data;
    } catch (error) {
      console.error('getConversation', error);
      return {
        error,
      };
    }
  },
  async generateAnswers({ uniqueBotId, intent, channel, conceptIds }) {
    try {
      const data = {
        uniqueBotId,
        intent,
        channel,
        conceptIds,
      };

      const res = await axios.post('/schaltzentrale/assistant/generate/answers', data, {
        timeout: timeoutAmount,
      });

      return res.data;
    } catch (error) {
      return {
        error,
      };
    }
  },
  async generateConcepts({ uniqueBotId, intent, channel, websites, informations }) {
    try {
      const data = {
        uniqueBotId,
        intent,
        channel,
        websites,
        informations,
      };

      const res = await axios.post('/schaltzentrale/assistant/generate/concepts', data, {
        timeout: timeoutAmount,
      });

      return res.data;
    } catch (error) {
      console.error('generateConcepts', error);
      return {
        error,
      };
    }
  },
  async getConceptTask({ uniqueBotId, taskId }) {
    try {
      const res = await axios.get(`/schaltzentrale/assistant/concepts/getTask/${taskId}/${uniqueBotId}`, {
        timeout: timeoutAmount,
      });

      return res.data;
    } catch (error) {
      console.error('getConceptTask', error);
      return false;
    }
  },
  async getConcept({ uniqueBotId, conceptId }) {
    try {
      const res = await axios.get(`/schaltzentrale/assistant/concepts/get/${conceptId}/${uniqueBotId}`, {
        timeout: timeoutAmount,
      });

      return res.data;
    } catch (error) {
      console.error('getConcept', error);
      return false;
    }
  },
  async getConcepts({ uniqueBotId, intent }) {
    try {
      const res = await axios.get(`/schaltzentrale/assistant/concepts/getAll/${intent}/${uniqueBotId}`, {
        timeout: timeoutAmount,
      });

      return res.data;
    } catch (error) {
      console.error('getConcepts', error);
      return false;
    }
  }
};

<template>
	<div>
		<v-card>
			<v-card-title>LiveChat authentication via OAuth</v-card-title>
			<v-card-subtitle>
				Authorize connection setup with your LiveChat, to allow visitors to
				be able to be able to be transferred to chatting with live agents.
			</v-card-subtitle>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="success" dark small @click="oauth()">
					Auth
					<v-icon right dark>mdi-robot-love-outline</v-icon>
				</v-btn>
			</v-card-actions>
		</v-card>

		<v-snackbar v-model="savingToast" :timeout="6000">
			Oauth success
			<v-spacer></v-spacer>
			<v-icon type="success">check</v-icon>
		</v-snackbar>

		<v-snackbar v-model="savingToastError" :timeout="6000">
			Fehler: {{ message }}
			<v-spacer></v-spacer>
			<v-icon color="red"
							type="error">
				mdi-alert-circle
			</v-icon>
		</v-snackbar>
	</div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	name: "LiveChatAuth",
	computed: {
		...mapGetters('bots', ['currentBotId'])
	},
	props: {
		"clientId": {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			message: "",
			savingToast: false,
			savingToastError: false,
		};
	},
	methods: {
		async oauth() {

			let base_redirect = `${process.env.VUE_APP_HUB_URL}/oauth/livechat`;
			const url = `https://accounts.livechat.com/?response_type=code&client_id=${this.clientId}&redirect_uri=${encodeURI(base_redirect)}`;

			window.open(url)
		},
	},
}
</script>
import integrationTypeConsts from '@/components/integrations/integrationTypeConsts';
import IntegrationService from '@/services/integrations';
import SettingService from '@/services/settings';
import _ from 'lodash';
// Reserved field for root Configurations in provider setting/config
import { _rootConfig } from '../../components/integrations/integrationConstants';

const state = {
  integrationTypes: [],
  selectedIntegrationType: null,
  webhookConfigs: {},
  webhookConfigsLocal: {},
  // Provider configs stores all providers. F.e. providerConfigs: { freshchat: {...}, livechat: {...} }
  currentProvider: null,
  providerConfigs: {},
  providerConfigsLocal: {},
  loading: false,
};


const getters = {
  integrationTypes: (state) => {
    let types = [...state.integrationTypes];
    types = types.map((t) => {
      // not in const = not ready to use
      if (!integrationTypeConsts[t.name]) t.comingSoon = true;
      return t;
    });

    types.sort(function (a, b) {
      if (a.prio < b.prio) {
        return -1;
      }
      if (a.prio > b.prio) {
        return 1;
      }
      return 0;
    });

    return types;
  },
  selectedIntegrationType: (state) => state.selectedIntegrationType,
  webhookConfigs: (state) => state.webhookConfigs,
  webhookConfigsList: (state) => {
    const keys = Object.keys(state.webhookConfigs);
    const configsList = keys.map((k) => {
      let config = {
        ...state.webhookConfigs[k],
        key: k,
      };

      if (!config.displayName) {
        config.displayName = k;
      }

      return config;
    });
    return configsList || [];
  },
  webhookConfigsLocal: (state) => state.webhookConfigsLocal,
  webhookConfigsListLocal: (state) => {
    const keys = Object.keys(state.webhookConfigsLocal);
    const configsList = keys.map((k) => ({
      ...state.webhookConfigsLocal[k],
      key: k,
    }));
    return configsList || [];
  },
  webhookConfigsListSorted: (state, getters) => {
    let configList = getters.webhookConfigsList.filter((c) => {
      // Don't include local manipulated configs
      return !getters.webhookConfigsListLocal.find((lc) => lc.key === c.key);
    });

    if (getters.webhookConfigsListLocal.length) {
      const localConfigList = getters.webhookConfigsListLocal.map((config) => {
        return {
          ...config,
          local: true,
        };
      });
      configList = [...configList, ...localConfigList];
    }
    configList.sort(function (a, b) {
      if (a.key < b.key) {
        return -1;
      }
      if (a.key > b.key) {
        return 1;
      }
      return 0;
    });
    return configList;
  },
  localWebhookConfigByKey: (state) => (key) => {
    const localConfig = state.webhookConfigsLocal[key] || null;
    if (localConfig) return localConfig;

    return null;
  },

  providerConfigs: (state) => {
    const provider = state.currentProvider;
    if (!state.providerConfigs[provider]) {
      state.providerConfigs[provider] = {};
    }
    return state.providerConfigs[provider];
  },
  providerConfigsLocal: (state) => {
    const provider = state.currentProvider;
    if(!state.providerConfigsLocal[provider]) {
      state.providerConfigsLocal[provider] = {};
    }
    return state.providerConfigsLocal[provider];
  },
  providerConfigVersionList: (state) => {
    const providerConfig = getters.providerConfigs(state);
    const configurations = actions.formatConfigsObjectToStandard({}, providerConfig);
    const configsList = [];

    Object.keys(configurations).forEach((key) => {
      // At this step we sure that each value is an object, because of formatConfigsObjectToStandard
      const config = { ...configurations[key], key };

      if (!config.displayName) config.displayName = key;
      configsList.push(config);
    });

    return configsList;
  },
  providerConfigVersionListLocal: (state) => {
    const localConfigs = getters.providerConfigsLocal(state);
    return Object.keys(localConfigs).map((k) => ({
      ...localConfigs[k],
      key: k,
    }));
  },
  providerConfigVersionsListSorted: (state, getters) => {
    const configList = getters.providerConfigVersionList.filter((c) => {
      // Don't include local manipulated configs
      return !getters.providerConfigVersionListLocal.find((lc) => lc.key === c.key);
    });
    if (getters.providerConfigVersionListLocal.length) {
      getters.providerConfigVersionListLocal.forEach(
        (config) => configList.push({ ...config, local: true })
      );
    }
    configList.sort((a,b) => {
      if (a.key === _rootConfig || b.key === _rootConfig) return a.key === _rootConfig ? -1 : 1;
      return a.key > b.key
        ? 1 
        : a.key < b.key ? -1 : 0
    });
    return configList;
  },
  localProviderConfigByKey: (state) => ({ provider, key }) => {
    const providerConfig = state.providerConfigsLocal[provider] || {}
    return providerConfig[key] || null;
  },
};

const actions = {
  async fetchIntegrationTypes({ state, commit }, { language }) {
    // Already fetched
    if (state.integrationTypes.length) return;
    if (!language) language = 'de';
    const allTypes = await IntegrationService.getAllTypes(language);

    if (allTypes?.items) {
      commit('set', { key: 'integrationTypes', value: allTypes.items });
    }
  },
  async fetchWebhookConfigs({ state, commit, rootGetters }) {
    if (state.loading) return;
    state.loading = true;
    const botId = rootGetters['bots/currentBotId'];
    const notifications = await SettingService.getSettings(
        botId,
        'notifications'
    );

    if (!notifications.webhook) {
      commit('set', { key: 'webhookConfigs', value: [] });
      state.loading = false;
      return [];
    }

    commit('set', { key: 'webhookConfigs', value: notifications.webhook });
    state.loading = false;
    return notifications.webhook;
  },
  async fetchWebhookConfigByKey({ dispatch }, key) {
    const webhookConfigs = await dispatch('fetchWebhookConfigs');
    return webhookConfigs?.[key] || null;
  },
  async createNewWebhookLocal({ getters, commit }, newName) {
    const key = `${integrationTypeConsts.webhook_push}_${Date.now()}`;

    let allWebhookConfigs = [
      ...getters['webhookConfigsList'],
      ...getters['webhookConfigsListLocal'],
    ];
    // Find existing webhooks with new webhook
    allWebhookConfigs = allWebhookConfigs.filter((c) =>
        c.displayName.includes(newName)
    );

    const config = {
      key,
      displayName: `${newName} ${allWebhookConfigs.length + 1}`,
      method: 'post',
      url: '',
    };

    commit('setWebhookConfigLocal', config);
    return config;
  },
  async saveWebhookConfig({ commit, rootGetters }, { config, key }) {
    const botId = rootGetters['bots/currentBotId'];
    const notifications = await SettingService.getSettings(
        botId,
        'notifications'
    );

    if (!notifications.webhook) {
      notifications.webhook = {};
    }

    notifications.webhook[key] = config;

    const result = await SettingService.setSettings(
        botId,
        notifications,
        'notifications'
    );

    if (!result) return;

    commit('setWebhookConfig', config);
    commit('deleteWebhookConfigLocal', key);

    return result?.webhook ? config : false;
  },
  async deleteWebhookConfig({ rootGetters, commit }, key) {
    const botId = rootGetters['bots/currentBotId'];
    const notifications = await SettingService.getSettings(
        botId,
        'notifications'
    );

    delete notifications.webhook[key];

    const result = await SettingService.setSettings(
        botId,
        notifications,
        'notifications'
    );

    if (!result?.webhook) return;
    commit('deleteWebhookConfigLocal', key);

    return !!result?.webhook;
  },

  initProvider({ state, commit, rootGetters, dispatch }, { provider }) {
    // Initialize currentProvider.provider
    commit('set', { key: 'currentProvider', value: provider });

    // We don't pass the config, because we want to only initialize provider name in state.providerConfigs[provider] = {}
    if(!state.providerConfigs[provider]) {
      commit('setProviderConfig', { provider });
    }
    if(!state.providerConfigsLocal[provider]) {
      commit('setProviderConfigLocal', { provider });
    }
    return provider;
  },
  // General for Livechat (as feature) Integration
  async createNewProviderLocal({ getters, commit }, { provider, config }) {
    let allConfigs = [
      ...getters['providerConfigVersionList'],
      ...getters['providerConfigVersionListLocal']
    ];

    const hasDefaultConfig = allConfigs?.some((config) => ['default', 'newConfig_default'].includes(config.key));

    // Find existing webhooks with new webhook
    allConfigs = allConfigs.filter((c) =>c.displayName.includes(config?.displayName));

    const key = `newConfig_${Date.now()}`;
    const configuration = {
      ...config,
      key,
    }

    if (hasDefaultConfig) {
      configuration.displayName = `${config.displayName} ${allConfigs.length + 1}`;
    } else {
      // Init SetUp Default config
      configuration.key = `newConfig_default`;
      configuration.displayName = `default`;
    }

    commit('setProviderConfigLocal', { provider, config: configuration });
    return configuration;
  },
  async getProvidersVersionsOverview({ rootGetters }, provider) {
    const botId = rootGetters['bots/currentBotId'];
    const res = await SettingService.getSettingsOverview(botId);

    if(provider && res[provider]) return res[provider];
    return res;
  },
  /**
   *
   * @param {{[key:string]: any}} config
   * So far as the config must have one standard, where each key in bot.settings.provider should be valid config-version-object.
   * This function formats this to Standard to Manage this config. Field key ('version') will be added inside its config.
   * Each key that is not an object will be assigned to _badConfig key or _rootConfig.
   * We will match keys also to identify properties which were in root object 
   * @returns {{[key:string]: any}} Valid config where each field is and object.
   */
  formatConfigsObjectToStandard(context, config) {
    const configVersionRegex = /^v_\d+$/;
    const formattedConfigs = {};
    const rootConfig = { key: _rootConfig, displayName: 'General Settings' };
    Object.keys(config).forEach((key) => {
      const value = config[key];

      // Check if it is possible basic config property (nested object) | Like 'case' for salesforce { ..., case: {} }, like 'ticket' for zendesk { ..., ticket: {} }
      let isConfigProperty = false;

      if (_.isObject(value)) {
        if (!value.displayName) isConfigProperty = true;
        if (configVersionRegex.test(key) || key === _rootConfig) isConfigProperty = false; // in case if proper version doesn't include displayName
      }

      // If not object
      if(isConfigProperty || !_.isObject(value) || Array.isArray(value)) {
        rootConfig[key] = value;
        return;
      }

      const formattedConfig = { ...value, key };
      if (!formattedConfig.displayName) formattedConfig.displayName = key;
      formattedConfigs[key] = formattedConfig;
    });

    // If there is only one field { key } => there is no reason to show it atm
    if (Object.keys(rootConfig).length > 2) formattedConfigs[_rootConfig] = rootConfig;
    return formattedConfigs;
  },
  /**
   * @param {*} param0 
   * @param {{
   *  provider: string,
   *  key: string,
   * }} options If key not pass, then the whole config will returned. Field 'key' is a config 'version'.
   * @returns 
   */
  async fetchProviderVersions({ state, commit, rootGetters, dispatch }, { provider, key = null }) {
    // if (state.loading) return; // In Zendesk oauth on 1st call this func. init state.loading is true
    state.loading = true;
    const botId = rootGetters['bots/currentBotId'];

    let configurations = await SettingService.getProviderSettings({ botId, provider });
    // Will return 'null' if the bot does not have this provider, which can be the case when you first set it up
    if (configurations === null) configurations = {};
    else configurations = await dispatch('formatConfigsObjectToStandard', configurations);

    const allProviders = state.providerConfigs;
    allProviders[provider] = configurations || {};

    commit('set', { key: 'providerConfigs', value: allProviders });
    state.loading = false;

    // Get Bot Config for Provider by key - version
    if (key) return configurations[key];
    return configurations;
  },
  async fetchProviderConfigByKey({ dispatch }, { provider, key}) {
    const configs = await dispatch('fetchProviderVersions', { provider });
    return configs?.[key] || null;
  },
  async checkIfProviderExists({ rootGetters }, { provider }) {
    const botId = rootGetters['bots/currentBotId'];
    const configs = await SettingService.getProviderSettings({ botId, provider });
    return !!configs
  },
  /**
   * 
   * @param {*} context
   * @param {{
   *  provider: string,
   *  key: string,
   *  newKey: string,
   *  config: any,
   * }} param1. Where 'key' is a config 'version'. config - is valid provider config.
   * Provide newKey name if you want rename the version name of config.
   * @returns { Promise<{ config: any|false, key: string }>}
   */
  async saveProviderConfig({ commit, rootGetters }, { provider, key, config, isProviderSetuped  }) {
    const botId = rootGetters['bots/currentBotId'];
    const isNew =  key.startsWith('newConfig_');
    const isNewDefault = key === 'newConfig_default';

    const keyId = isNew
      ? !isNewDefault ? `v_${Date.now()}` : 'default'
      : key;

    const result = isNew
        ? await SettingService.addProviderVersionSettings({ botId, provider, key: keyId, config })
        : await SettingService.updateProviderVersionSettings({ botId, provider, key: keyId, config });

    if (!result) return;

    commit('setProviderConfig', { provider, config });
    commit('deleteProviderConfigLocal', { provider, key });

    return { config: config || false, key: keyId };
  },
  async setupProviderConfig({ commit, rootGetters }, { provider, config }) {
    const botId = rootGetters['bots/currentBotId'];
    config.displayName = 'default';

    const result = await SettingService.setProviderSettings({
      botId,
      provider,
      config: {
        default: config
      },
    });

    // Set key as default for cache
    config.key = 'default';
    commit('setProviderConfig', { provider, config });
    return result || false;
  },
  async deleteProviderConfig({ rootGetters, commit }, { provider, key }) {
    const botId = rootGetters['bots/currentBotId'];
    const result = await SettingService.deleteProviderVersionSettings({
      botId, provider, key
    });

    // If success => true
    return result === true;
  },
};

const mutations = {
  set(state, { key, value }) {
    state[key] = value;
  },
  setSelectedIntegrationType(state, value) {
    state.selectedIntegrationType = value;
  },
  setWebhookConfig(state, config) {
    const newWebhookConfigs = { ...state.webhookConfigs };
    newWebhookConfigs[config.key] = config;
    state.webhookConfigs = newWebhookConfigs;
  },
  setWebhookConfigLocal(state, config) {
    const newWebhookConfigsLocal = { ...state.webhookConfigsLocal };
    newWebhookConfigsLocal[config.key] = config;
    state.webhookConfigsLocal = newWebhookConfigsLocal;
  },
  deleteWebhookConfigLocal(state, key) {
    const newWebhookConfigsLocal = { ...state.webhookConfigsLocal };
    delete newWebhookConfigsLocal[key];
    state.webhookConfigsLocal = newWebhookConfigsLocal;
  },

  setProviderConfig(state, { provider, config}) {
    if (!provider) return;
    const allProviders = { ...state.providerConfigs };
    if (!allProviders[provider]) allProviders[provider] = {}; // if !config => init providerConfigs.provider
    if (config) allProviders[provider][config.key] = config;
    state.providerConfigs = allProviders;
  },
  setProviderConfigLocal(state, { provider, config }) {
    if (!provider) return;
    const allProviderslocal = { ...state.providerConfigsLocal };
    if (!allProviderslocal[provider]) allProviderslocal[provider] = {};
    if (config) allProviderslocal[provider][config.key] = config;
    state.providerConfigsLocal = allProviderslocal;
  },
  deleteProviderConfigLocal(state, { provider, key }) {
    if (!provider) return;
    const allProviderslocal = { ...state.providerConfigsLocal };
    delete allProviderslocal?.[provider]?.[key];
    state.providerConfigsLocal = allProviderslocal;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
<template>
  <v-col
    :lg="isEmbedded ? '12' : '9'"
    :md="isEmbedded ? '12' : '8'"
    cols="12"
    class="fill-height pt-0 pl-2 flex-column d-flex"
  >
    <v-row v-if="attachmentItems.length">
      <v-col class="py-6">
        <h2 class="text-h6">{{ $t('common.attachments') }}</h2>
        <div>
          <FileCard
            border
            v-for="(item, index) in attachmentItems"
            :key="index"
            :item="item"
          />
        </div>
      </v-col>
    </v-row>
    <v-row v-if="!isEmbedded">
      <v-btn class="d-flex d-md-none ml-2 mt-2" icon link to="/chats">
        <v-icon>keyboard_arrow_left</v-icon>
      </v-btn>
      <v-list-item v-if="!notFound && chat && chat.length > 0">
        <v-list-item-content class="text-center">
          <v-list-item-title>
            {{ $t('conversation.singletitle') }}
            {{ $t('conversation.started') }} {{ formatDateTime(chat[0].date, $t('common.dateTimeFormat')) }}
            {{ $t('common.clock') }}
          </v-list-item-title>
          <v-list-item-subtitle v-if="isAdmin">
            <span class="moin-text-admin">Chat {{ $t('common.on') }} </span>
            <span @click="copyLink" class="copy-link moin-text-admin" :data-url="chat[0].url" data-copied="false">
              {{ getHost(chat[0].url) }}
              <v-icon small right color="var(--v-admin-base)">mdi-content-copy</v-icon>
              <v-icon small right color="var(--v-admin-base)">mdi-check</v-icon>
            </span>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="notFound || (chat && chat.length === 0)">
        <v-list-item-content class="text-center">
          <v-list-item-title>
            {{ $t('conversation.singletitle') }}
            {{ $t('conversation.notfound') }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-row>
    <v-row v-if="chat === false">
      <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
    </v-row>
    <v-row
      v-if="!notFound"
      class="overflow-y-auto"
      id="chat-history"
      ref="scrollContainer"
    >
      <v-list
        two-line
        v-if="chat"
        v-scroll:#chat-history="onScroll"
        v-mutate.child="onMutate"
        @hook:mounted="jumpToUUID"
      >
        <v-list-item v-for="message in chat" :key="message.uuid" class="white">
          <v-list-item-icon v-if="message.user === false">
            <v-icon color="secondary" v-if="!message.livechat">
              chat_bubble
            </v-icon>
            <v-icon color="primary" v-if="message.livechat">headset_mic</v-icon>
          </v-list-item-icon>

          <v-list-item-content
            class="text-center"
            v-if="message.command === true"
          >
            <v-list-item-title v-if="message.type === 'livechat_started'">
              {{ $t('conversation.livechat.started') }}
            </v-list-item-title>
            <v-list-item-title v-if="message.type === 'livechat_ended'">
              {{ $t('conversation.livechat.ended') }}
            </v-list-item-title>
          </v-list-item-content>

          <v-list-item-content v-if="message.user === true" class="ml-12">
            <!-- <v-row>
            <v-col offset="4" cols="8">-->
            <v-card outlined dark color="primary">
              <v-card-text :ref="message.uuid">{{ message.text }}</v-card-text>
            </v-card>
            <v-list-item-subtitle class="mt-2">
              {{ formatTime(message.date) }} {{ $t('common.clock') }}
            </v-list-item-subtitle>
            <!-- </v-col>
            </v-row>-->
          </v-list-item-content>

          <v-list-item-content
            v-if="
              message.user === false &&
              message &&
              message.message &&
              message.message.message
            "
            class="mr-10"
            :ref="message.uuid"
          >
            <!-- <v-row>
            <v-col cols="8" md="8">-->
            <v-card
              outlined
              :class="{
                secondary: message.livechat,
                'darken-3': message.livechat,
              }"
              :dark="message.livechat"
            >
              <v-card-text v-if="message.message.message.text">
                {{ message.message.message.text }}
              </v-card-text>
              <v-card-text
                v-else-if="
                  message.message.message.attachment &&
                  message.message.message.attachment.type === 'button'
                "
              >
                {{ message.message.message.attachment.payload.text }}
              </v-card-text>
              <template
                v-if="
                  message.message.message.attachment &&
                  message.message.message.attachment.type === 'button'
                "
              >
                <!-- <v-col cols="12" v-for="btn in message.message.message.attachment.payload.buttons" :key="btn.title"> -->
                <v-btn
                  class="ml-4 mb-2"
                  v-for="btn in message.message.message.attachment.payload
                    .buttons"
                  :key="btn.title"
                  outlined
                  disabled
                >
                  {{ btn.title }}
                  <v-icon v-if="btn.type === 'web_url'" small class="ml-2 mb-4">
                    link
                  </v-icon>
                </v-btn>
                <!-- </v-col> -->
              </template>

              <v-carousel
                :continuous="false"
                :show-arrows="true"
                hide-delimiters
                light
                v-if="
                  message.message.message.attachment &&
                  message.message.message.attachment.type === 'cards'
                "
              >
                <v-carousel-item
                  v-for="(card, index) in message.message.message.attachment
                    .payload.elements"
                  :key="index"
                >
                  <v-sheet height="100%" tile>
                    <v-img
                      :aspect-ratio="16 / 9"
                      max-height="220"
                      :src="card.image_url"
                    ></v-img>
                    <v-card-title>{{ card.title }}</v-card-title>
                    <v-card-subtitle>{{ card.subtitle }}</v-card-subtitle>

                    <v-btn
                      class="ml-4 mb-2"
                      v-for="btn in card.buttons"
                      :key="btn.title"
                      outlined
                      disabled
                    >
                      {{ btn.title }}
                      <v-icon
                        v-if="btn.type === 'web_url'"
                        small
                        class="ml-2 mb-4"
                      >
                        link
                      </v-icon>
                    </v-btn>
                  </v-sheet>
                  <!-- <v-img :src="card.image_url"></v-img> -->
                  <!-- <v-card-title>{{card.title}}</v-card-title>
                  <v-card-subtitle>{{card.subtitle}}</v-card-subtitle>-->
                </v-carousel-item>
              </v-carousel>

              <!-- <v-chip-group class="ml-4"> -->
              <v-chip
                class="ml-4 mb-2"
                disabled
                v-for="qr in message.message.quick_replies"
                :key="qr.label"
              >
                {{ qr.label }}
              </v-chip>
              <!-- </v-chip-group> -->
            </v-card>

            <v-list-item-subtitle class="mt-2">
              {{ formatTime(message.date) }} {{ $t('common.clock') }}
              <span v-if="isAdmin" class="moin-text-admin">
                {{ $t('common.on') }}
                <span @click="copyLink" class="copy-link moin-text-admin" :data-url="message.url" data-copied="false">
                {{ getHost(message.url) }}
                <v-icon small right color="var(--v-admin-base)">mdi-content-copy</v-icon>
                <v-icon small right color="var(--v-admin-base)">mdi-check</v-icon>
              </span>
              </span>
            </v-list-item-subtitle>
            <!-- </v-col> </v-row> -->
          </v-list-item-content>

          <v-list-item-icon v-if="message.user === true">
            <v-icon color="primary">person</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <!-- <v-list-item>
                       <v-list-item-icon>
                      <v-icon color="secondary">chat_bubble</v-icon>
                    </v-list-item-icon>
                      <v-list-item-content>
                        <v-row>
                          <v-col cols="8">
                         <v-card outlined>
                          <v-card-text>
                          Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget condimentu
                          </v-card-text>
                          
                        </v-card>
                     
                     <v-list-item-subtitle  class="mt-2">asdkadasd </v-list-item-subtitle>
                       </v-col> </v-row></v-list-item-content>
                      
                    </v-list-item>
                    
                    <v-list-item>
                    
                      <v-list-item-content>
                         <v-row>
                          <v-col offset="4" cols="8">
                        <v-card outlined dark color="primary">
                          <v-card-text>
                          Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget condimentu
                          </v-card-text>
                        </v-card>
                      <v-list-item-subtitle class="mt-2">asdkadasd </v-list-item-subtitle>
                        </v-col> </v-row>
                      </v-list-item-content>
                         <v-list-item-icon>
                      <v-icon color="primary">person</v-icon>
                    </v-list-item-icon>

                    </v-list-item>

                     <v-list-item>
                       <v-list-item-icon>
                      <v-icon color="secondary">chat_bubble</v-icon>
                    </v-list-item-icon>
                      <v-list-item-content>
                        <v-row>
                          <v-col cols="8">
                         <v-card outlined>
                          <v-card-text>
                          Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget condimentu
                          </v-card-text>
                          
                        </v-card>
                     
                     <v-list-item-subtitle  class="mt-2">asdkadasd </v-list-item-subtitle>
                       </v-col> </v-row></v-list-item-content>
                      
                    </v-list-item>
                    <v-divider class="mt-8"></v-divider>
                    
                    <v-list-item>
                      <v-list-item-content class="text-center">
                        <v-list-item-title>Unterhaltung fortgeführt 23 Minuten später</v-list-item-title>
                        <v-list-item-subtitle>
                         https://test.de/blablub
                     </v-list-item-subtitle>
                      </v-list-item-content>

                  
                    </v-list-item>
                       
                    <v-list-item>
                    
                      <v-list-item-content>
                         <v-row>
                          <v-col offset="4" cols="8">
                        <v-card outlined dark color="primary">
                          <v-card-text>
                          Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget condimentu
                          </v-card-text>
                        </v-card>
                      <v-list-item-subtitle class="mt-2">asdkadasd </v-list-item-subtitle>
                        </v-col> </v-row>
                      </v-list-item-content>
                         <v-list-item-icon>
                      <v-icon color="primary">person</v-icon>
                    </v-list-item-icon>

        </v-list-item>-->

        <v-list-item ref="lastItem" v-intersect="onIntersect">
          <!-- <v-list-item-content v-intersect="onIntersect">sASSD</v-list-item-content> -->
        </v-list-item>
      </v-list>
      <!-- <v-toolbar dense elevation="0" bottom absolute style="width:100%">
                      <v-text-field solo flat hide-details append-icon="send"></v-text-field>
      </v-toolbar>-->

      <!--</VuePerfectScrollbar>-->
    </v-row>
    <v-tooltip v-if="!isEmbedded" color="secondary" left :value="newMessages">
      <template v-slot:activator="{}">
        <v-btn
          :v-show="true"
          fab
          dark
          fixed
          bottom
          small
          right
          color="primary"
          @click="toTop"
        >
          <v-icon v-if="!newMessages && fab">keyboard_arrow_up</v-icon>
          <v-icon v-if="newMessages || !fab">keyboard_arrow_down</v-icon>
        </v-btn>
      </template>
      <span class="primary--text">
        <v-icon small>message</v-icon>
        Neue Nachrichten...
      </span>
    </v-tooltip>
  </v-col>
</template>
<script>
import { mapGetters } from 'vuex';
import NumbersUtil from '../utils/numbers';
import FilesService from '../services/files';
import FileCard from '@/components/common/FileCard';
import { formatDateTime, formatTime } from '@/utils/formatDateTime';

export default {
  name: 'ChatHistory',
  components: {
    FileCard,
  },
  props: ['uniqueUserId', 'embedded', 'jumpto'],
  async created() {
    this.init();
  },
  computed: {
    ...mapGetters('bots', [
      // create properties from auth store
      'currentBotId',
      'viewableBotId',
      'currentChannels',
      'currentBot',
      'bots',
    ]),
    ...mapGetters('dialogs', [
      // create properties from auth store
      'chat',
    ]),
    ...mapGetters('auth', [
      'isAdmin'
    ]),
    isEmbedded() {
      return this.embedded !== undefined;
    },
  },
  data() {
    return {
      fab: false,
      isIntersecting: false,
      newMessages: false,
      notFound: false,
      attachmentItems: [],
      formatDateTime,
      formatTime,
      refreshInterval: null,
    };
  },
  beforeDestroy() {
    clearInterval(this.refreshInterval);
  },
  methods: {
    async init() {
      await this.validateChat();
      await this.getChat();
      await this.checkForFiles();

      this.refreshInterval = setInterval(() => {
        this.refresh();
      }, 5000);
    },
    async validateChat(switchBot = true) {
      this.notFound = false;

      if (this.uniqueUserId) {
        let channelId = this.uniqueUserId.split('.');
        if (channelId.length === 3) {
          if (this.currentBot && this.currentBot.channels) {
            let channelAvailable = this.currentChannels.find((c) => {
              return c.channelId === channelId[0];
            });

            if (!channelAvailable) {
              let bot = this.bots.find((b) => {
                return b.channels.find((c) => {
                  return c.channelId === channelId[0];
                });
              });

              if (switchBot && bot) {
                await this.$store.dispatch('bots/getBotById', bot.uniqueBotId);
              } /* else {
                //this.notFound = true;
                console.log('NOT FOUND');
              } */
            } else if (channelAvailable.blocked) {
              this.notFound = true;
            }
          } else {
            this.notFound = true;
          }
        } else {
          this.notFound = true;
        }
      } else {
        this.notFound = true;
      }
    },
    onMutate() {
      setTimeout(
        function () {
          if (!this.isIntersecting) {
            this.newMessages = true;
          }
        }.bind(this),
        500
      );
    },
    onIntersect(entries) {
      // More information about these options
      // is located here: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
      //console.log('INTERSECTING', entries);
      this.isIntersecting = entries[0].isIntersecting;
      if (this.isIntersecting) {
        this.newMessages = false;
      }
    },
    refresh() {
      console.log('REFRESH CHAT');
      if (!this.notFound) {
        this.getChat();
      }
    },
    async getChat() {
      await this.$store.dispatch('dialogs/chat', {
        uniqueUserId: this.uniqueUserId,
        uniqueBotId: this.currentBotId,
      });
    },
    getHost(url) {
      try {
        return new URL(url).hostname;
      } catch (e) {
        return 'n.a.';
      }
    },
    onScroll(e) {
      if (e.srcElement.scrollTop > 20) {
        this.fab = true;
      } else {
        this.fab = false;
      }
    },
    toTop() {
      if (this.newMessages || !this.fab) {
        this.$vuetify.goTo(this.$refs.lastItem, {
          container: this.$refs.scrollContainer,
        });
      } else {
        this.$vuetify.goTo(0, { container: this.$refs.scrollContainer });
      }
    },
    jumpToUUID() {
      if (!this.jumpto) return;
      let r = this.$refs[this.jumpto];

      if (r === undefined) {
        let jt = Object.keys(this.$refs).find((key) => {
          return key.startsWith(this.jumpto);
        });

        r = this.$refs[jt];
      }

      if (Array.isArray(r) && r.length > 0) {
        r = r[0];
      }
      
      // catch silent error if goTo fails
      try {
        this.$vuetify.goTo(r, { container: this.$refs.scrollContainer });
      } catch (e) {}
    },
    async checkForFiles() {
      if (!this.uniqueUserId || !this.viewableBotId) return;
      const filesList =
        (
          await FilesService.checkFileAccess({
            botId: this.viewableBotId,
            uuId: this.uniqueUserId,
          })
        )?.data?.events || [];

      this.attachmentItems = filesList;
    },
    /**
     * Copies the data-url from the event's target
     * @param {PointerEvent} event
     */
    copyLink(event) {
      const url = event.target.dataset.url;
      if (!url) return;

      window.navigator.clipboard.writeText(url).then(
        () => {
          console.log("Async: Copying to clipboard was successful!");
          event.target.dataset.copied = "true";
          setTimeout(() => event.target.dataset.copied = "false", 1000);
        },
        (err) => {
          console.error("Async: Could not copy text: ", err);
        }
      );
    },
  },
  watch: {
    uniqueUserId: function () {
      this.$store.dispatch('dialogs/clearChat');
      this.validateChat();
      this.getChat();
      this.checkForFiles();
    },
    currentBotId: function (a, b) {
      this.validateChat(b === null);
      if (!this.notFound) {
        this.getChat();
      }
    },
    chat: function (val, oldval) {
      if (this.jumpto && oldval === false && Array.isArray(val)) {
        this.$nextTick(() => {
          let r = this.$refs[this.jumpto];

          if (r === undefined) {
            let jt = Object.keys(this.$refs).find((key) => {
              return key.startsWith(this.jumpto);
            });

            r = this.$refs[jt];
          }

          if (Array.isArray(r) && r.length > 0) {
            r = r[0];
          }
          
          // catch silent error if goTo fails
          try {
            this.$vuetify.goTo(r, { container: this.$refs.scrollContainer });
          } catch (e) {}
        });
      }
    },
    viewableBotId: function () {
      this.checkForFiles();
    },
  },
};
</script>
<style scoped>
.copy-link {
  font-weight: 800;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}
.copy-link i:last-child {
  display: none;
}
.copy-link[data-copied="true"] .v-icon:first-child {
  display: none;
}
.copy-link[data-copied="true"] .v-icon:last-child {
  display: initial;
}
</style>
<template>
  <HubBoxSimple
    :title="'Tipps / Hilfe'"
    icon="help_outline"
    titleHeightAuto
    v-if="false"
  >
    <template v-slot:content>
      <p class="text-body-2">
        Dieser Bericht zielt darauf ab, die Themen zu finden, die deine
        Aufmerksamkeit erfodert. Nutze die Ansicht für die Themen mit dem
        größten Impact, um diese weiter zu optimieren. Die Ansicht “Themen mit
        dem größten Potential, zeigt dir, bei welchen Themen noch Verbesserungen
        vorgenommen werden können.
      </p>
    </template>
  </HubBoxSimple>
</template>
<script>
import HubBoxSimple from '@/components/hub/HubBoxSimple.vue';

export default {
  name: 'StatsSidebarInfoText',
  data() {
    return {};
  },
  components: {
    HubBoxSimple,
  },
};

</script>

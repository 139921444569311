import { ProductService } from '@/services/product';
import { ContentService } from '@/services/content';
import moment from 'moment';
/*
    Hält Drafts der Inhalte einzelner Bot Antworten im State.

    updated ist notwendig zum Synchronisieren des States,
    da direkt an den State Objekten die Bindings gemacht werden.
*/

// initial state
const state =  {
    contentDrafts: {}, // cache for drafts
    updated: 0,
    updates: {},
    deployment: {
    },
    isAssistant: false,
}

const getters = {
    isDeployable: (state) => (botId) => {
        if (!state.deployment[botId]) {
            return false;
        }
        
        return state.deployment[botId].isDeployable;
    },
    lastPublishDate: (state) => (botId) => {
        if (!state.deployment[botId]) {
            return false;
        }

        return state.deployment[botId].lastPublishDate;
    },
    lastChangeDate: (state) => (botId) => {
        if (!state.deployment[botId]) {
            return false;
        }

        return state.deployment[botId].lastChangeDate;
    },
    getContentBoxes: (state) => (botId, intent, channel) => {
        if (!state.contentDrafts?.[botId]?.[intent]) {
            return [];
        }

        const allBoxes = Object.values(state.contentDrafts[botId][intent])
            .filter(box => {
                if (!channel && box.channel) return false;
                if (!channel && !box.channel) return true;
                if (channel && box.channel && channel === box.channel) return true;
                return false;
            });

        return allBoxes;
    },
    isContentChanged: (state, getter, rootState, rootGetter) => (botId, intent, channel) => {
        if (channel === false) {
            return false;
        } else if (!state.updates[botId] || !state.updates[botId][intent] || !state.updates[botId][intent][''+channel]) {
            return rootGetter['glimpseContent/isContentChanged'](botId, intent, channel);
        } else {
            return state.updates[botId][intent][''+channel].changed;
        }
    },
    hasContent: (state) => (botId, intent) => {
        if (!state.contentDrafts[botId] || !state.contentDrafts[botId][intent]) {
            return false;
        }

        return Object.keys(state.contentDrafts[botId][intent]).length > 0;
    },
    getContent: (state) => (botId, intent, box) => {
        // get drafts
        if (!state?.contentDrafts?.[botId]?.[intent]?.[box]) {
            return null;
        }
        return state.contentDrafts[botId][intent][box];
    },
    isAssistant: (state) => state.isAssistant,
}
const actions = {
    async getDeployment({commit, state}, {botId}) {
        let result = await ProductService.getDeployment(botId);

        if (result.status === 'ok' && result.deployable) {
            if (state.deployment[botId]) {
                // wenn aktuelle Publishdate > lastChangeDate
                if (moment(state.deployment[botId].lastPublishDate).isAfter(result.deployable.lastChangeDate)) {
                    result.deployable.lastPublishDate = state.deployment[botId].lastPublishDate;
                    result.deployable.isDeployable = false;
                }
            }
            commit('setDeployment', {deployment: result.deployable, botId});
        }
    },
    async deploy(notused, {botId}) {
        await ProductService.deploy(botId);
    },
    async fakeDeploy({commit}, {botId, lastChangeDate}) {
       // send to slack!
       await ProductService.fakeDeploy(botId, lastChangeDate);
       commit('setDeployment', {deployment: {
        isDeployable: false,
        lastPublishDate: new Date(),
        lastChangeDate: false
       }, botId});
    },
    // need to update the state
    update({commit, rootGetters}, {botId, name, channel}) {
        // wenn origin vom draft abweicht, content Change Flag true
        let changed = null;

        if (botId && name) {
            changed = rootGetters['glimpseContent/isContentChanged'](botId, name, channel);
        }

        commit('update', {botId, name, channel, changed});
    },
    async save({commit, dispatch, getters}, {botId, name, channel}) {
        // only save if something available to save :)
        let draftBoxes = getters['getContentBoxes'](botId, name, channel);

        for (const box of draftBoxes) {
            // clone box json
            const body = structuredClone(box);
            body.intent = name;

            await ContentService.saveContent(botId, box, body);
        }

        commit('glimpseContent/resetOrigins', {botId, name, channel}, {root: true});
        commit('resetDrafts', {botId, name, channel});
        commit('update', {botId, name, channel, changed: false});
        dispatch('getDeployment', {botId});
        
    },
    async createBox({commit}, {step, botId, intent, template, channel}) {
        
        let originContent = await ContentService.getContent(botId, template, intent, undefined, channel);
        if (originContent.result && originContent.result.template && originContent.result.box !== false && originContent.result.onlyDraft) {
            if (step !== undefined && step !== null) {
                originContent.result.step = step;
            }

            // TODO remove not reachable boxes

            originContent.result.onlyDraft = false;
            commit('setContentDraft',  {content: originContent.result, botId, intent, box: originContent.result.box});

            return originContent.result.box;
        }

        return false;
    },
    async updateBox({commit}, {step, botId, intent, template, channel, box}) {
        
      let originContent = await ContentService.getContent(botId, template, intent, box, channel);
      if (originContent.result && originContent.result.template && originContent.result.box !== false && originContent.result.onlyDraft) {
          if (step !== undefined && step !== null) {
              originContent.result.step = step;
          }

          originContent.result.onlyDraft = false;
          commit('setContentDraft',  {content: originContent.result, botId, intent, box: originContent.result.box});

          return originContent.result.box;
      }

      return false;
  },
    async createChannel(commit, {botId, template, intent, channel}) {
     
       await ProductService.createChannelContent(botId, template, intent, channel);
      
       return true;
    },
    async copyOrigin({commit, getters, rootGetters}, {botId, name, channel}) {
        /**
         * it is called when switching to the edit mode. 
         * it handles the origin -> draft copy, if no draft available
         * 
         * TODO draft outdated feature
         */
       
        let draftBoxes = getters['getContentBoxes'](botId, name, channel);
        if (draftBoxes.length > 0) {
            return;
        }
       
        let allBoxes = rootGetters['glimpseContent/getContentBoxes'](botId, name, channel);
        commit('setContentDrafts', {drafts: allBoxes, botId});
    },
    async resetDraft({commit, dispatch}, {botId, name, channel}) {
        commit('resetDrafts', {botId, name, channel});
        dispatch('copyOrigin', {botId, name, channel});   
        commit('update', {botId, name, channel, changed: false});
    },
    async resetAll({commit}, {botId, name}) {
        commit('resetDrafts', {botId, name});
        commit('resetUpdate', {botId, name});
        commit('glimpseContent/resetOrigins',  {botId, name}, {root: true});
       /*  dispatch('copyOrigin', {botId, name, channel});    */
        //commit('update', {botId, name, channel, changed: false});
    },
    setContentDraftIntent({commit}, {botId, intent, boxes, channel}) {
        let keys = Object.keys(boxes);
        let drafts = keys.map(key => {
            return boxes[key];
        });
      
        commit('setContentDrafts', {botId, drafts});
        channel = channel || null;
        commit('update', {botId, name: intent, channel, changed: true});
      return true;
    },
    deleteContentDrafts({state}, {botId, intentId, boxIds}) {
        for (let boxId of boxIds) {
            if (boxId === intentId) {
                // Set initial box to empty object to avoid side effects
                state.contentDrafts[botId][intentId][boxId] = {};
                continue;
            }
            delete state.contentDrafts[botId][intentId][boxId];
        }
        return true;
    },
}

const mutations = {
    setValue(state, {key, value}) {
        state[key]= value;
    },
    update(state, {botId, name, channel, changed}) {
        // update counter
        let versionNumber = new Date().getTime();

        if (botId && name) {
            if (!state.updates[botId]) {
                state.updates = {...state.updates, [botId]: {}};
            }

            if (!state.updates[botId][name]) {
                state.updates[botId] = {...state.updates[botId], [name]: {}};
            }

            state.updates[botId][name] = {...state.updates[botId][name], ['' + channel]: {
                changed
            }};

        }

        state.updated = versionNumber;
    },
    resetUpdate(state, {botId, name}) {

        if (botId && name) {
            if (state.updates[botId] && state.updates[botId][name]) {
                state.updates[botId] = {...state.updates[botId], [name]: {}};
            }
        }
    },
    resetDrafts(state, {botId, name, channel}) {
        if (!state.contentDrafts?.[botId]?.[name]) return;
        if (channel === undefined) channel = false;

        // all draft boxes from channel
        let allBoxes = Object
          .values(state.contentDrafts[botId][name])
          .filter(box => {
            if (channel === false) {
                return true;
            }
            if (!channel && box.channel) {
                return false;
            }
            if (!channel && !box.channel) {
                return true;
            }
            if (channel && box.channel && channel === box.channel) {
                return true;
            }

            return false;
        });

        if (allBoxes.length > 0) {
            // delete all drafts
            console.log('DELETE BOXES');
            for (let box of allBoxes) {
                delete state.contentDrafts[botId][name][box.box];
                state.contentDrafts[botId][name] = {...state.contentDrafts[botId][name]};
            }
        }
    },
    setContentDraft(state, {content, botId, intent, box}) {
        const clone = JSON.parse(JSON.stringify(content));
        if (!state.contentDrafts[botId]) {
            state.contentDrafts = { ...state.contentDrafts, [botId]: {}};
        }

        if (!state.contentDrafts[botId][intent]) {
            state.contentDrafts[botId] = { ...state.contentDrafts[botId], [intent]: {}};
        }

        state.contentDrafts[botId][intent] = { ...state.contentDrafts[botId][intent], [box]: clone};
    },
    setContentDrafts(state, {botId, drafts}) {
        for (let l of drafts) {
            const clone = JSON.parse(JSON.stringify(l));


            if (!state.contentDrafts[botId]) {
                state.contentDrafts = { ...state.contentDrafts, [botId]: {}};
            }

            if (!state.contentDrafts[botId][l.intent]) {
                state.contentDrafts[botId] = { ...state.contentDrafts[botId], [l.intent]: {}};
            }

            state.contentDrafts[botId][l.intent] = { ...state.contentDrafts[botId][l.intent], [l.box]: clone};
        }
    },
    setDeployment(state, {botId, deployment}) {
        if (!state.deployment[botId]) {
            state.deployment = { ...state.deployment, [botId]: deployment};
        } else {
            Object.keys(deployment).forEach(key => {
                state.deployment[botId][key] = deployment[key];
            });
        }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
<template>
  <HubBox
    title="Conversions"
    :titleSub="$t('common.allChannels')"
    icon="mdi-flag-outline"
  >
    <IntentStatistic
      class="mb-4"
      :progress-title="
        `Conversion-Rate <span class='primary--text text--lighten-3'>(seit der letzten Änderung)</span>`
      "
      :chart-title="'Zeitliche Entwicklung / Trend'"
      :chart-subline="'Conversions in den letzten 12 Wochen'"
      :loading="loading"
    >
      <template #progressBar>
        <v-progress-linear
          class="v-progress-linear--outer-value"
          :value="progressValue"
          buffer-value="100"
          background-color="#bdbdbd"
          color="var(--v-primary-lighten1)"
          height="10"
        >
          <template v-slot:default="{ value }">
            <strong :style="getProgressValueStyle(value)">
              {{ Math.ceil(value) }}%
            </strong>
          </template>
        </v-progress-linear>
      </template>

      <template #chart>
        <apexchart
          v-if="chart.series.length >= 2"
          type="bar"
          :options="chart.options"
          :series="chart.series"
          height="140px"
        />
      </template>
    </IntentStatistic>
  </HubBox>
</template>

<script>
import moment from 'moment';
import { getProgressValueStyle, baseBarChartOptions } from '@/utils/charts';
import { lastTwelveWeekDates } from '@/utils/numbers';
import { StatsService } from '@/services/stats';
import HubBox from '@/components/hub/HubBox.vue';

import IntentStatistic from './IntentStatistic';

export default {
  name: 'IntentConversion',
  components: {
    IntentStatistic,
    HubBox,
  },
  props: {
    intent: {
      type: String,
      required: true,
    },
    channel: {
      type: String,
      required: true,
    },
    botId: {
      type: String,
      required: true,
    },
    boxId: {
      type: String,
      default: 'IntentConversion',
    },
  },
  data() {
    return {
      loading: false,
      progressValue: 0,
      getProgressValueStyle,
      chart: {
        series: [],
        options: Object.assign(
          JSON.parse(JSON.stringify(baseBarChartOptions)),
          {
            colors: ['#9E9E9E', '#2F3F55'],
            tooltip: {
              enabled: true,
              x: {
                formatter: function(val) {
                  return `Woche ${moment(val).week()}`;
                },
              },
            },
            xaxis: {
              type: 'datetime',
              categories: lastTwelveWeekDates,
              labels: {
                rotateAlways: true,
                offsetY: 3,
                formatter: (_, timestamp) => {
                  return `KW ${moment(timestamp).week()}`;
                },
              },
            },
          }
        ),
      },
    };
  },
  beforeMount() {
    this.loading = true;
    StatsService.getIntentConversion(
      this.botId,
      this.intent,
      this.channel
    ).then(({ history, goals, views }) => {
      const goalsArray = history.map(({ goals }) => goals);
      const viewsArray = history.map(({ views }) => views);

      this.chart.series.push({
        name: 'Ziele',
        data: goalsArray,
      });
      this.chart.series.push({
        name: 'Ansichten',
        data: viewsArray,
      });
      this.chart.options.yaxis.max = Math.max(...goalsArray, ...viewsArray);

      this.progressValue = (goals / (goals + views)) * 100;
      this.loading = false;
    });
  },
};
</script>

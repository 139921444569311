<template>
    <div>
    <v-container class="py-0">
        <v-row
          justify="start"
        >
          <v-col
            cols="12"
          >
            <v-text-field v-if="possibleValues.length === 0"
              ref="valueInput"
              v-model="valueInput"
              append-outer-icon="mdi-plus-circle"
              full-width
              hide-details
              @keyup.enter="addChip"
              :label="placeholder"
              @click:append-outer="addChip"
              single-line
              :rules="[validator]"
            ></v-text-field>
            <v-select v-else
              ref="valueInput"
              v-model="valueInput"
              append-outer-icon="mdi-plus-circle"
              full-width
              hide-details
              @keyup.enter="addChip"
              :items="possibleValuesLeft"
              :label="placeholder"
              @click:append-outer="addChip"
              single-line
              :rules="[validator]"
            ></v-select>
          </v-col>
        </v-row>
      </v-container>
      <v-container class="py-5">
        <v-row
          justify="start"
        >
          <v-col
            v-for="(chip, i) in values"
            :key="chip"
            class="shrink"
          >
            <v-chip
              close
              @click:close="removeChip(i)"
            >
              {{ chip }}
            </v-chip>
          </v-col>
          </v-row>
    </v-container>
  </div>
</template>
<script>
module.exports = {
    props: {
        placeholder: {
            type: String,
            default: 'Enter a value',
        },
        values: {
            type: Array,
            default: () => [],
        },
        possibleValues: {
            type: Array,
            default: () => [],
        },
        validator: {
            type: Function,
            default: () => true,
        },
    },
    data: () => ({
        valueInput: '',
    }),
    computed: {
        possibleValuesLeft() {
            return this.possibleValues.filter((value) => !this.values.includes(value));
        },
    },
    methods: {
        removeChip(index) {
            this.$emit('onRemoveValue', index);
        },
        addChip () {
            if (this.valueInput.length && this.validator(this.valueInput)) {
                this.$emit('onAddValue', this.valueInput);
                this.valueInput = '';
            }    
        },
    },
}
</script>
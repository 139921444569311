<template>
    <v-app style="max-height: 100vh; height: 100vh">
      <HubMenu />
      <Appbar />

      <HubContent :isSidebarOpen="isSidebarOpen">
      <template v-slot:navbar>
        <HubContentNavbar
          :title="$t('botNotifications.title')"
        />
      </template>
      <template v-slot:sidebar>
        <HubContentSidebarSimple
          :isOpen="isSidebarOpen"
          :title="$t('botNotifications.resendTitle')"
          :icon="'info'"
          @onClose="closeSidebar"
        >
          <div class="pa-4">
            <BotNotificationsResend
              :notification="selectedNotification"
              @onResend="resendNotification"
            />
          </div>
        </HubContentSidebarSimple>
      </template>
      <template v-slot:content>
        <BotNotificationsTable
          :notifications="notifications"
          :isLoading="isLoading"
          @onResendNotification="openResendNotification"
        />
      </template>
      </HubContent>
    </v-app>
</template>

<script>
    import HubMenu from '@/components/hub/HubMenu.vue';
    import Appbar from '@/components/Appbar.vue';
    import HubContent from '@/components/hub/HubContent.vue';
    import HubContentNavbar from '@/components/hub/HubContentNavbar.vue';
    import HubContentSidebarSimple from '@/components/hub/HubContentSidebarSimple.vue';
    import BotNotificationsResend from '@/components/botNotifications/BotNotificationsResend.vue';
    import BotNotificationsTable from '@/components/botNotifications/BotNotificationsTable.vue';
    import NotificationService from '@/services/notifications';
    import { mapGetters } from 'vuex';

    export default {
        name: 'BotNotifications',
        components: {
            HubMenu,
            Appbar,
            HubContent,
            HubContentNavbar,
            HubContentSidebarSimple,
            BotNotificationsResend,
            BotNotificationsTable,
        },
        data: () => ({
            isLoading: false,
            isSidebarOpen: false,
            selectedNotification: null,
            notifications: [],
        }),
        computed: {
            ...mapGetters('bots', [
              'currentBot',
            ]),
        },
        created() {
            this.loadNotifications();
        },
        methods: {
            async loadNotifications() {
                this.isLoading = true;
                this.notifications = await NotificationService.get(this.currentBot.uniqueBotId);
                this.isLoading = false;
            },
            openResendNotification(notification) {
                this.isSidebarOpen = true;
                this.selectedNotification = notification;
            },
            closeSidebar() {
                this.isSidebarOpen = false;
                this.selectedNotification = null;
            },
            async resendNotification(recipients) {
                const response = await NotificationService.resend(this.selectedNotification.uniqueBotId, this.selectedNotification._id, recipients);
                this.closeSidebar();
                this.loadNotifications();
            },
        },
    }
</script>
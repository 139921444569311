<script>
import { TokenService } from '@/services/token';
import { capitalize } from '@vue/shared';
import microsoftLogo from '@/assets/brands/microsoft.svg';

export default {
  name: 'SSOLoginButton',
  props: {
    "provider": {
      type: String,
      enum: ['microsoft'],
      required: true,
    },
    // The invite code associated with the login, if applicable
    "invite": {
      type: String,
      default: null,
    },
    // Optional: Provide a hint to the SSO IDP which email the user must log in with
    "emailHint": {
      type: [String,null],
      default: null,
    },
  },
  data() {
    return {
      redirecting: false,
      logos: {
        microsoft: microsoftLogo,
      },
    };
  },
  methods: {
    capitalize,
    /**
     * Creates a random string to be used as nonce for the OAuth flow
     * @returns {string}
     */
    makeNonce() {
      let nonce = "";

      if (window.crypto?.getRandomValues) {
        const buffer = window.crypto.getRandomValues(new Uint32Array(16));
        nonce = Array.from(buffer).map(b => b.toString(16)).join('');
      } else {
        // For browsers before ish 2010-2013
        nonce = btoa(
          Math.random().toString(36).slice(2)
          +Math.random().toString(36).slice(2)
          +Math.random().toString(36).slice(2)
          +Math.random().toString(36).slice(2)
          +Math.random().toString(36).slice(2)
          +Math.random().toString(36).slice(2)
          +Date.now()
        );
      }

      return nonce;
    },
    /**
     * Starts a Single Sign On process with an identity provider.
     * Will redirect the current tab to the login page.
     */
    startSSO() {
      if (this.redirecting) return;

      // Nonce gets cleared in the callback handler
      const nonce = this.makeNonce();
      TokenService.saveSSONonce(this.provider, nonce, [this.invite]);
      let callbackUrl = `${window.location.origin}/login/sso/${this.provider}`;

      if (this.invite) callbackUrl += '/invite';

      if (this.provider==="microsoft") {
        /**
         * See options here:
         * https://login.microsoftonline.com/common/v2.0/.well-known/openid-configuration
         */
        const query = new URLSearchParams({
          nonce,
          client_id: process.env.VUE_APP_MSFT_SSO_CLIENT_ID,
          scope: "openid profile email",
          redirect_uri: callbackUrl,
          // What type of data we need
          response_type: "code",
          // How we want data sent back (goto URL with data in query)
          response_mode: "fragment",
        });
        if (this.emailHint) {
          query.set("login_hint", this.emailHint);
        }

        // Might take a while for page to change
        this.redirecting = true;

        window.location.href = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?${query.toString()}`
      }
    }
  }
};
</script>

<template>
  <button
    :disabled="redirecting"
    class="d-flex items-center justify-center gap-3 px-3 py-3 sso-btn"
    :class="{'redirecting': redirecting}"
    @click.prevent="startSSO"
  >
    <img
      :src="`${logos[provider]}`"
      :alt="`Logo of ${provider}`"
    />
    {{ $t('login.btnSSO', { name: capitalize(provider) }) }}
  </button>
</template>

<style scoped>
.sso-btn {
  transition: all .2s ease;
  color: #5e5e5e;
  font-weight: 500;
  border: solid thin #8c8c8c;
  border-radius: 4px;
}
.sso-btn:hover:not(:disabled) {
  transition: border .2s ease;
  border-color: black;
  color: black;
}
.redirecting {
  opacity: 70;
}
</style>
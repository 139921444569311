import numeral from 'numeral';

export function formatNumber(value) {
  let num = numeral(value).format('0,0.00');
  // Replace . with , and , with .
  num = num.replace(/\,/g, 'X');
  num = num.replace(/\./g, 'Y');
  num = num.replace(/X/g, '.');
  num = num.replace(/Y/g, ',');
  return num;
}

export function formatNumberFixedOne(value) {
  return numeral(value).format('0.0');
}

export function formatNumberFixed(value) {
  return numeral(value).format('0');
}

export function formatPercent(value) {
  return numeral(value).format('0%');
}

export function formatPercentPrecision(value) {
  return numeral(value).format('0.00%');
}

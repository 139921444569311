export const conversationStateTypes = {
  new: 'new',
  open: 'open',
  completed: 'completed',
  canceled: 'canceled',
  running: 'running',
  error: 'error',
};

export const messageTypes = {
  user: 'user',
  assistant: 'assistant',
  system: 'system',
};

import { ProductService } from '../../services/product';

// initial state for authentication
const state = {
  forms: false,
};

const getters = {
  forms: (state) => {
    return state.forms;
  },
};

const actions = {
  /* async add({commit}, {botId, samples, displayName, description, suggested, forward}) {
        let result = await ProductService.addIntent(botId, samples, displayName, description, suggested, forward);
       
        if (!result.error && result.intent) {
            commit('addIntent', result.intent);
        }
        
        return result;
    }, */
  async add({ commit }, { botId, displayName, template }) {
    let result = await ProductService.addForm(botId, displayName, template);
    if (!result.error && result.form) {
      commit('addForm', result.form);
    }

    return result;
  },
  async get({ commit }, uniqueBotId) {
    commit('setForms', false);

    const formsResponse = await ProductService.getAllForms(uniqueBotId);

    if (formsResponse && formsResponse.status === 'ok' && formsResponse.forms) {
      commit('setForms', formsResponse.forms);
    }

    return true;
  },
};

const mutations = {
  setForms(state, forms) {
    if (Array.isArray(forms)) {
      forms.sort((a, b) => {
        return a.displayName
          .toLowerCase()
          .localeCompare(b.displayName.toLowerCase());
      });
    }

    state.forms = forms;
  },
  addForm(state, form) {
    state.forms.push(form);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

<template>
  <div class="pa-6">
    <h2 class="text-h6 primary--text mt-2 mb-4">{{ title }}</h2>
    <StatsSidebarReportsList class="mb-4" />
    <StatsSidebarActiveFilter v-if="!isAnualReview" class="mb-4" />
    <StatsSidebarReportExport class="mb-4" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { statsTypes } from '@/components/stats/statsTypes';
import StatsSidebarActiveFilter from '@/components/stats/components/StatsSidebarActiveFilter.vue';
import StatsSidebarReportExport from '@/components/stats/components/StatsSidebarReportExport.vue';
import StatsSidebarReportsList from '@/components/stats/components/StatsSidebarReportsList.vue';

export default {
  name: 'StatsReportSidebar',
  components: {
    StatsSidebarReportsList,
    StatsSidebarActiveFilter,
    StatsSidebarReportExport,
  },
  props: {
    statsType: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      statsTypes,
    };
  },
  computed: {
    ...mapGetters('stats', ['isAnualReview']),
  },
  methods: {},
};
</script>

<template>
  <div class="ma-2 moin-admin-box">
    <div>
      <div
        class="pa-2 grey--text text--lighten-1 px-4"
        style="font-size: 13px; transition: padding-left 0.3s"
      >
        Admin
      </div>
      <v-list-item
        v-for="item in items"
        :key="`btn-${item.value}`"
        :data-cy="`menu-${item.value}-btn`"
        :to="item.to"
        color="admin"
        active-class="hub-menu-item--active"
      >
        <v-icon left color="admin">{{ item.icon }}</v-icon>
        <span>
          {{ item.title }}
        </span>
      </v-list-item>
      <div class="pa-4">
        <div class="d-flex">
          <v-switch
            class="ma-0"
            dense
            inset
            label="Staging"
            @change="changeViewableBot"
            :input-value="viewStaging"
            hide-details
          />
        </div>
      </div>
      <v-list-item class="d-none d-sm-flex mt-6">
        <BotPicker />
      </v-list-item>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import BotPicker from '@/components/BotPicker';
export default {
  name: 'HubMenuAdmin',
  components: { BotPicker },
  computed: {
    ...mapGetters('bots', ['currentBotId', 'hasWidget', 'viewStaging']),
    items() {
      return [
        {
          title: this.$t('menu.channels'),
          icon: 'call_split',
          value: 'channels',
          to: '/channels',
        },
        {
          title: 'NLP Models',
          icon: 'school',
          value: 'models',
          to: '/models',
        },
        {
          title: this.$t('menu.botmanager'),
          icon: 'list',
          value: 'bots',
          to: '/bots',
        },
        {
          title: this.$t('menu.tools'),
          icon: 'build',
          value: 'tools',
          to: '/tools',
        },
        {
          title: 'Lizenzen',
          icon: 'shopping_basket',
          value: 'licences',
          to: '/licences',
        },
        {
          title: 'Lizenz',
          icon: 'shopping_basket',
          value: 'licence',
          to: '/licence',
        },
        {
          title: 'Regel Validator',
          icon: 'rule',
          value: 'validator',
          to: '/validator',
        },
        {
          title: 'Bot Settings',
          icon: 'settings',
          value: 'bot-settings',
          to: '/bot-settings',
        },
        {
          title: 'Bot notifications',
          icon: 'mdi-email-outline',
          value: 'bot-notifications',
          to: '/notifications',
        },
        {
          title: 'QM Dashboard',
          icon: 'mdi-finance',
          value: 'qm',
          to: '/qm',
        },
        {
          title: 'Demo Creation',
          icon: 'mdi-robot-love',
          value: 'demo-creation',
          to: '/demo-creation',
        },
      ];
    },
  },
  methods: {
    changeViewableBot(value) {
      this.$store.dispatch('bots/setViewStaging', value);
    },
  },
};
</script>
<template>
  <div class="flex-grow-1">
    <div v-if="teaser && teaser.payload" class="teaser pa-4">
      <div class="teaser-close d-flex justify-center align-center">
        <v-icon>close</v-icon>
      </div>
      <div
        v-if="isNoData"
        class="pa-4 text-center text-body-1 grey--text text--darken-1"
      >
        <v-icon style="margin-top: -4px" color="grey darken-1">
          mdi-alert
        </v-icon>
        {{ $t('teasers.previewNoData') }}
      </div>
      <div v-else class="teaser-pos-wrapper">
        <div class="d-flex flex-col align-end">
          <div class="teaser-message pa-2 text-left">
            {{ teaser.payload.message }}
          </div>
          <div class="text-right">
            <template v-for="(action, index) in teaser.payload.quickActions">
              <div
                v-if="action.text.length"
                class="teaser-message teaser-action py-2 px-4 mt-4 ml-4"
                :key="index"
              >
                {{ action.text }}
              </div>
            </template>
          </div>
        </div>
      </div>
      <v-avatar size="64" class="teaser-avatar">
        <img
          src="https://media.moin.ai/1595514610395_chatbot.png"
          alt="Moini"
        />
      </v-avatar>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TeasersTeaserPreview',
  props: {
    teaser: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isNoData() {
      if (!this.teaser?.payload?.message) {
        return true;
      }
      return false;
    },
  },
};
</script>
<style scoped>
.teaser {
  position: relative;
  width: 100%;
  min-height: 140px;
  margin: auto;
  background: #e0e0e0;
  border-radius: 7px;
  overflow: hidden;
}

.teaser-pos-wrapper {
  padding-right: 76px;
  padding-bottom: 20px;
}

.teaser-message {
  background: white;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

.teaser-action {
  display: inline-block;
  border-radius: 20px;
}

.teaser-close {
  position: absolute;
  top: 16px;
  right: 16px;
  background: white;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.teaser-avatar {
  position: absolute;
  bottom: 16px;
  right: 16px;
  border-radius: 50%;
  border: 3px solid white;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
}
</style>
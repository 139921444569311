<template>
  <v-app style="max-height: 100vh; height: 100vh">
    <HubMenu />
    <Appbar />
    <HubContent :isSidebarOpen="isSidebarOpen" fixedHeight>
      <template v-slot:navbar>
        <HubContentNavbar
          :title="$t('channelManagement.title')"
          backTo="/settings"
          :isLoading="isLoading"
        >
          <template v-slot:toolbar>
            <BtnSquare
              v-if="quota"
              icon="view_sidebar"
              @click="isSidebarOpen = !isSidebarOpen"
            />
          </template>
        </HubContentNavbar>
      </template>
      <template v-slot:sidebar>
        <HubContentSidebarSimple
          :isOpen="isSidebarOpen"
          @onClose="isSidebarOpen = false"
          icon="info"
        >
          <div class="pa-4">
            <QuotaInfo />
          </div>
        </HubContentSidebarSimple>
      </template>
      <template v-slot:content>
        <HubFrame>
          <template v-slot:title>
            <h2 class="text-h5">{{ $t('channelManagement.table.title') }}</h2>
          </template>
          <template v-slot:actions>
            <ChannelsNewChannel v-if="isAdmin" />
          </template>
          <template v-slot:content>
            <v-container fluid class="pa-4">
              <v-row>
                <v-col>
                  <ChannelsList />
                </v-col>
              </v-row>
            </v-container>
          </template>
        </HubFrame>
      </template>
    </HubContent>
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex';
import HubMenu from '@/components/hub/HubMenu.vue';
import Appbar from '@/components/Appbar.vue';
import HubContent from '@/components/hub/HubContent.vue';
import HubContentNavbar from '@/components/hub/HubContentNavbar.vue';
import HubContentSidebarSimple from '@/components/hub/HubContentSidebarSimple.vue';
import HubFrame from '@/components/hub/HubFrame.vue';
import ChannelsNewChannel from '@/components/channels/ChannelsNewChannel.vue';
import ChannelsList from '@/components/channels/ChannelsList.vue';
import { TrackingService } from '@/services/tracking';
import BtnSquare from '@/components/common/BtnSquare.vue';
import QuotaInfo from '@/components/common/QuotaInfo.vue';

export default {
  name: 'SettingsChannels',
  components: {
    HubMenu,
    Appbar,
    HubContent,
    HubContentNavbar,
    HubContentSidebarSimple,
    HubFrame,
    ChannelsNewChannel,
    ChannelsList,
    BtnSquare,
    QuotaInfo,
  },
  data() {
    return {
      isLoading: true,
      isSidebarOpen: false,
    };
  },
  computed: {
    ...mapGetters('bots', [
      'currentBotId',
      'currentBot',
      'currentChannels',
      'getBotSettings',
    ]),
    ...mapGetters('auth', ['user', 'isAdmin']),
    ...mapGetters('channels', ['channels', 'channel']),
    quota() {
      const { quota } = this.getBotSettings(this.currentBotId);
      return quota;
    },
  },
  created() {
    this.init();
    this.trackIt();
  },
  methods: {
    async init() {
      this.isLoading = true;
      if (!this.currentBot?.uniqueBotId) return;

      await this.$store.dispatch('channels/loadChannels', {
        botId: this.currentBot.uniqueBotId,
      });

      this.isLoading = false;
    },
    trackIt(type) {
      TrackingService.trackChannelManagement({
        botId: this.currentBotId,
        type: type,
        source: 'SettingsChannels.vue',
      });
    },
  },
};
</script>

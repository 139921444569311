<template>
  <v-app style="max-height: 100vh; height: 100vh">
    <HubMenu />
    <Appbar />

    <HubList
      :value="selectedChat"
      :open-sidebar="$vuetify.breakpoint.mdAndUp"
      :sidebar-items="[
        { title: $t('chats.history.title'), icon: 'message', name: 'info' },
      ]"
      no-active-background
      :loading="isLoading"
      hide-icon
      item-key="uuid"
      :title="$t('conversation.title')"
      :items="filteredChats"
      :sidebar="true"
      :sidebar-width="7"
      @input="(chat) => changeSelectedChat(chat)"
    >
      <template v-slot:item="{ item, selected }">
        <InsightsListItem :item="item" :selected="selected" />
      </template>
      <template v-slot:toolbar>
        <v-menu v-model="isBetaMenuOpen" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              class="px-4"
              label
              v-bind="attrs"
              color="secondary darken-2"
              v-on="on"
              @click="trackIt('open-beta-dialog')"
            >
              Beta Version
            </v-chip>
          </template>
          <v-card max-width="250px">
            <v-card-text>{{ $t('conversation.beta.text1') }}.</v-card-text>
            <v-card-text>{{ $t('conversation.beta.text2') }}.</v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn to="/chats?old=true" text>
                {{ $t('conversation.beta.btn') }}
              </v-btn>
              <v-spacer />
            </v-card-actions>
          </v-card>
        </v-menu>
      </template>

      <template v-slot:item-action="{ item, selected }">
        <!-- LIST ITEM ACTION -->
        <span>
          <DropMenu
            v-if="selected && !isLoading"
            :isDisabled="isLoading"
            :items="[
              {
                text: $t('conversation.menu.protocol'),
                icon: 'open_in_new',
                to: `/chats/messaging/${item.uniqueUserId}?jumpto='${item.uuid}'`,
                target: '_blank',
              },
              {
                text: $t('conversation.menu.delete'),
                icon: 'delete',
                onClick: () => openDeleteDialog(item.uniqueUserId),
                isDisabled: !isAdmin && !isOwner,
              },
            ]"
          >
            <BtnSquare
              icon="mdi-dots-horizontal"
              @click="trackIt('conversation-dropdown-menu')"
            />
          </DropMenu>
          <v-icon v-else color="primary lighten-3" small>
            chevron_right
          </v-icon>
        </span>
      </template>
      <template v-slot:item-append="{ item, selected }">
        <template v-for="t in item ? item.all : []">
          <AnalysisItem
            v-if="selected"
            :key="'item' + t._id"
            type="list"
            :item="t"
          />
        </template>
      </template>
      <template v-slot:[`sidebar.info`]="{ item }">
        <v-card v-if="!item" flat class="mt-8">
          <v-container class="pt-8">
            <v-row align="center" justify="center">
              <v-icon color="grey lighten-1">speaker_notes_off</v-icon>
            </v-row>
            <v-row align="center" justify="center">
              <v-card-title class="grey--text">
                {{ $t('conversation.history.nochat.title') }}
              </v-card-title>
            </v-row>
            <v-row align="center" justify="center">
              <v-card-subtitle class="grey--text">
                {{ $t('conversation.history.nochat.subtitle') }}
              </v-card-subtitle>
            </v-row>
          </v-container>
        </v-card>
        <v-card v-else-if="isCleanUp(item)" flat>
          <v-card-title>
            {{ $t('conversation.dataPrivacyNotice.title') }}
          </v-card-title>
          <v-card-text>
            {{ $t('conversation.dataPrivacyNotice.body') }}
          </v-card-text>
        </v-card>
        <template v-else>
          <template v-for="block in structure.before">
            <v-card
              v-if="block._id.name === 'conversation'"
              :key="block._id.name + '_' + block._id.uuid"
              flat
              class="ma-4"
            >
              <v-card-subtitle class="text-center">
                <v-chip color="grey" outlined>
                  {{ $t('chats.conversation.previouslabel') }}
                  {{ i18nDateFormat( block.created, $vuetify.lang.current ) }}
                </v-chip>
              </v-card-subtitle>
            </v-card>
          </template>
          <template v-for="block in blocks">
            <v-card
              v-if="
                structure.uuids[block._id] &&
                  !structure.uuids[block._id].livechat
              "
              :key="block._id"
              color="white"
              class="ml-4 mr-4 mt-4 mb-0"
              flat
            >
              <v-card-subtitle
                v-if="isConversationStart(structure.uuids[block._id].actions)"
                class="text-center mt-4 white"
              >
                <v-chip color="primary" outlined>
                  {{ $t('chats.conversation.startlabel') }}
                  {{ i18nDateFormat( block.created, $vuetify.lang.current ) }}
                </v-chip>
              </v-card-subtitle>
              <AnalysisItem :item="block" />
              <template
                v-for="(action, idx) in structure.uuids[block._id].actions"
              >
                <v-card
                  v-if="
                    (action._id.name === 'interaction' ||
                      action._id.name === 'action') &&
                      userInput(action, idx, block._id) !== false
                  "
                  :key="action._id.name + action._id.uuid"
                  color="white"
                  class="ml-6"
                  max-width="600px"
                  flat
                >
                  <v-row>
                    <v-col cols="8" offset="1" class="pt-2 pb-1">
                      <v-card-text
                        v-if="
                          action._id.name === 'interaction' &&
                            !isUserInputButton(action)
                        "
                        class="
                          primary
                          lighten-3
                          white--text
                          mb-2
                          rounded-custom
                        "
                      >
                        {{ userInput(action, idx, block._id) }}
                      </v-card-text>
                      <v-chip
                        v-else-if="action._id.name === 'interaction'"
                        class="float-right pl-4 pr-4 mb-2"
                        color="primary lighten-3"
                      >
                        {{ userInput(action, idx, block._id) }}
                      </v-chip>
                      <NlpQuick
                        v-if="
                          false &&
                            action._id.name === 'interaction' &&
                            !isUserInputButton(action)
                        "
                        :text="userInput(action)"
                        :intent="
                          block.intents.length > 0
                            ? block.intents[0]
                            : 'unknown'
                        "
                      />
                    </v-col>
                    <v-col cols="3" class="pt-0 pb-1">
                      <v-card-text
                        v-if="action._id.name === 'interaction'"
                        class="pt-1"
                      >
                        <v-icon color="primary lighten-3" left>
                          account_circle
                        </v-icon>
                        {{ formatTime(action.created, $t('common.timeFormatShort')) }}
                      </v-card-text>
                    </v-col>
                  </v-row>

                  <template v-if="action._id.name === 'action'">
                    <!-- Bot Action -->
                    <v-row class="pb-4">
                      <v-col cols="8" class="pt-2 pb-2 pl-10">
                        <template
                          v-for="(protocol, outerIndex) in action.protocols"
                        >
                          <template
                            v-for="(message,
                            index) in protocol.outgoingMessages"
                          >
                            <v-row :key="'message-' + outerIndex + '-' + index">
                              <v-col class="pt-2 pb-2 pl-4">
                                <v-textarea
                                  v-if="message.message && message.message.text"
                                  class="text-body-2 pt-0 pb-0"
                                  hide-details
                                  dense
                                  outlined
                                  disabled
                                  :value="message.message.text"
                                  auto-grow
                                  rows="1"
                                />
                                <v-textarea
                                  v-else-if="
                                    message.message &&
                                      message.message.attachment &&
                                      message.message.attachment.type ===
                                        'button' &&
                                      message.message.attachment.payload &&
                                      message.message.attachment.payload.text
                                  "
                                  class="text-body-2 pt-0 pb-0"
                                  hide-details
                                  dense
                                  outlined
                                  disabled
                                  :value="
                                    message.message.attachment.payload.text
                                  "
                                  auto-grow
                                  rows="1"
                                />
                                <v-card
                                  v-else-if="
                                    message.message &&
                                      message.message.attachment &&
                                      message.message.attachment.type ===
                                        'cards'
                                  "
                                  class="d-flex justify-center"
                                  min-height="100px"
                                  flat
                                  color="grey lighten-3"
                                >
                                  <v-card
                                    flat
                                    class="grey lighten-3 align-self-center"
                                  >
                                    <v-card-subtitle>
                                      <v-icon left>view_carousel</v-icon>
                                      Slides
                                    </v-card-subtitle>
                                  </v-card>
                                </v-card>
                                <v-card
                                  v-else-if="
                                    message.message &&
                                      message.message.attachment &&
                                      message.message.attachment.type ===
                                        'image'
                                  "
                                  class="d-flex justify-center"
                                  min-height="100px"
                                  flat
                                  color="grey lighten-3"
                                >
                                  <v-card
                                    flat
                                    class="grey lighten-3 align-self-center"
                                  >
                                    <v-card-subtitle>
                                      <v-icon left>image</v-icon>
                                      Media
                                    </v-card-subtitle>
                                  </v-card>
                                </v-card>
                                <v-card
                                  v-else-if="
                                    message.message &&
                                      message.message.attachment &&
                                      message.message.attachment.type ===
                                        'video'
                                  "
                                  class="d-flex justify-center"
                                  min-height="100px"
                                  flat
                                  color="grey lighten-3"
                                >
                                  <v-card
                                    flat
                                    class="grey lighten-3 align-self-center"
                                  >
                                    <v-card-subtitle>
                                      <v-icon left>image</v-icon>
                                      Media
                                    </v-card-subtitle>
                                  </v-card>
                                </v-card>
                                <template
                                  v-if="
                                    message.message &&
                                      message.message.attachment &&
                                      message.message.attachment.type ===
                                        'button' &&
                                      message.message.attachment.payload
                                  "
                                >
                                  <v-btn
                                    v-for="(btn, btnIndex) in message.message
                                      .attachment.payload.buttons"
                                    :key="'btn-' + btnIndex"
                                    small
                                    outlined
                                    class="mt-2 mr-2"
                                    disabled
                                    text
                                  >
                                    {{ btn.title }}
                                  </v-btn>
                                </template>
                                <span
                                  v-if="
                                    message.quick_replies &&
                                      message.quick_replies.length > 0
                                  "
                                >
                                  <v-chip
                                    v-for="(quick,
                                    labelIndex) in message.quick_replies"
                                    :key="'quick-' + labelIndex"
                                    disabled
                                    outlined
                                    small
                                    text-color="primary"
                                    class="mt-2 mr-2"
                                  >
                                    {{ quick.label }}
                                  </v-chip>
                                </span>
                              </v-col>
                            </v-row>
                          </template>
                        </template>
                      </v-col>
                    </v-row>
                  </template>
                </v-card>
              </template>
              <template v-for="after in structure.after">
                <v-card
                  v-if="after._id.name === 'conversation'"
                  :key="after._id.name + '_' + after._id.uuid"
                  flat
                  class="ma-4"
                >
                  <v-card-subtitle class="text-center">
                    <v-chip outlined>
                      {{ $t('chats.conversation.nextlabel') }}
                      {{ i18nDateFormat(after.created, $vuetify.lang.current) }}
                    </v-chip>
                  </v-card-subtitle>
                </v-card>
              </template>
            </v-card>
            <v-card
              v-else-if="
                structure.uuids[block._id] &&
                  structure.uuids[block._id].livechat
              "
              :key="block._id"
              color="white"
              class="ml-4 mr-4 mt-4 mb-0"
              flat
            >
              <AnalysisItem :item="block" />
              <v-row>
                <v-col cols="12" class="pt-0 pb-0">
                  <v-card-subtitle class="text-center">
                    <v-chip
                      color="secondary darken-2"
                      :to="
                        '/chats/messaging/' +
                          item.uniqueUserId +
                          '?jumpto=' +
                          block._id
                      "
                      target="_blank"
                    >
                      Livechat mit einem Agenten
                      <v-icon small right class="mr-2">open_in_new</v-icon>
                    </v-chip>
                  </v-card-subtitle>
                </v-col>
              </v-row>
            </v-card>
          </template>
        </template>
      </template>
      <template v-slot:list-header>
        <!-- LIST HEADER -->
        <v-card class="pl-0 pr-0 pt-4 pb-0 ml-0 mr-0 mt-0 mb-0" flat tile>
          <!-- Datums Auswahl -->
          <v-card-title>
            <v-btn
              icon
              tile
              :disabled="isLoading || dateFilterIsNotAllowed"
              @click="changeDay(-1)"
            >
              <v-icon>chevron_left</v-icon>
            </v-btn>
            <v-spacer />
            <v-menu
              ref="menu"
              v-model="datepickerMenu"
              :disabled="isLoading"
              :close-on-content-click="false"
              :return-value.sync="dateFilter"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn text :disabled="isLoading" v-bind="attrs" v-on="on">
                  <v-icon left>event</v-icon>
                  {{ dateFilterString }}
                </v-btn>
              </template>
              <v-date-picker
                v-model="dateFilter"
                no-title
                scrollable
                :allowed-dates="allowedDates"
                color="secondary darken-2"
              >
                <v-tooltip v-if="cleanupDate" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      class="ml-2"
                      color="primary lighten-2"
                      v-on="on"
                    >
                      verified_user
                    </v-icon>
                  </template>
                  <span>
                    <v-icon left color="white" x-small>verified_user</v-icon>
                    <span
                      v-html="
                        $t('conversation.dataPrivacyActive', {
                          date: formatDateTime(cleanupDate.toISOString(), $t('common.dateFormatShort')),
                        })
                      "
                    ></span>
                  </span>
                </v-tooltip>
                <v-spacer />
                <v-btn text color="primary" @click="datepickerMenu = false">
                  {{ $t('common.cancel') }}
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="
                    $refs.menu.save(dateFilter);
                    loadChats();
                  "
                >
                  {{ $t('common.ok') }}
                </v-btn>
              </v-date-picker>
            </v-menu>
            <v-spacer />
            <v-btn
              :disabled="dateFilterIsToday || isLoading"
              tile
              icon
              @click="changeDay(+1)"
            >
              <v-icon>chevron_right</v-icon>
            </v-btn>
          </v-card-title>
          <ChatInsightsFilter
            v-model="filteredChats"
            :channels="channels"
            :chats="chats"
            @loading="onFilterLoading"
          />
          <v-card-title class="pb-1">
            <span
              v-if="!isLoading"
              :class="{
                'ml-2': filteredChats.length < 10,
                'mr-3': filteredChats.length < 10,
                'mr-2': filteredChats.length >= 10,
              }"
              v-text="filteredChats.length"
            />
            <v-progress-circular
              v-else
              class="ml-1 mr-3"
              indeterminate
              color="darken secondary-2"
              :size="16"
              :width="1"
            />
            {{ $t('conversation.title') }}
          </v-card-title>
        </v-card>
        <v-divider />
      </template>
    </HubList>
    <HubDialog
      v-model="isDeleteDialogOpen"
      :title="$t('conversation.deleteDialog.title')"
      :primary-label="$t('common.remove')"
      primary-icon="save"
      :secondary-label="$t('common.cancel')"
      secondary-icon="close"
      @onPrimary="deleteConversation"
      @onSecondary="isDeleteDialogOpen = false"
    >
      <div class="text-body-1 py-4">
        {{ $t('conversation.deleteDialog.description') }}
      </div>
    </HubDialog>
  </v-app>
</template>

<script>
import HubMenu from '@/components/hub/HubMenu.vue';
import Appbar from '@/components/Appbar.vue';
import HubList from '../components/hub/HubList.vue';
import NlpQuick from '../components/conversation/NlpQuick.vue';
import AnalysisItem from '../components/conversation/AnalysisItem.vue';
import ChatInsightsFilter from '../components/conversation/ChatInsightsFilter';
import DropMenu from '@/components/common/DropMenu.vue';
import BtnSquare from '@/components/common/BtnSquare.vue';

import { mapGetters } from 'vuex';
import DialogService from '@/services/dialogs';
import { TrackingService } from '@/services/tracking';
import moment from 'moment';
import InsightsListItem from '../components/conversation/InsightsListItem.vue';
import HubDialog from '@/components/hub/HubDialog.vue';
import { formatDateTime, i18nDateFormat, formatTime } from '@/utils/formatDateTime';

export default {
  name: 'ChatInsights',
  components: {
    HubMenu,
    Appbar,
    HubList,
    NlpQuick,
    AnalysisItem,
    ChatInsightsFilter,
    InsightsListItem,
    DropMenu,
    BtnSquare,
    HubDialog,
  },
  data() {
    return {
      selectedChat: null,
      selectedChannel: null,
      selectedFilter: [],
      filteredChats: [],
      chats: [],
      dateFilter: moment()
        .startOf('day')
        .toDate()
        .toISOString(),
      dateFilterValue: moment()
        .startOf('day')
        .toDate()
        .toISOString(),
      datepickerMenu: null,
      loading: false,
      filterIsLoading: false,
      history: [],
      filterStats: {
        thumbUp: 0,
        thumbDown: 0,
        goals: 0,
        suggests: 0,
        unknowns: 0,
        channels: {},
      },
      filter: [
        {
          id: 'intents',
          name: 'menu.answers',
          selected: true,
          func: (item) => {
            return item && item.filter;
          },
        },
      ],
      structure: { before: [], after: [], uuids: {} },
      isBetaMenuOpen: false,
      isDeleteDialogOpen: false,
      toDelete: null,
      formatDateTime,
      i18nDateFormat,
      formatTime,
    };
  },
  computed: {
    ...mapGetters('bots', [
      'currentBot',
      'currentBotId',
      'viewableBotId',
      'currentChannels',
      'getBotSettings',
      'getCurrentChannelById',
    ]),
    ...mapGetters('behaviour', ['hublistFilter']),
    ...mapGetters('intents', [
      'intents',
      'bookedIntents',
      'suggested',
      'smalltalks',
      'displayName',
    ]),
    ...mapGetters('auth', ['isAdmin', 'isOwner']),
    isLoading() {
      return this.loading || this.filterIsLoading ? true : false;
    },
    blocks() {
      if (!this.selectedChat) return [];
      return this.selectedChat.all.length > 0
        ? this.selectedChat.all
        : this.selectedChat.all.length === 0
        ? [{ _id: this.selectedChat.uuid, created: this.selectedChat.created }]
        : [];
    },
    cleanupDate() {
      const setting = this.getBotSettings(this.currentBot.uniqueBotId);

      if (
        setting &&
        setting.cleanup &&
        setting.cleanup.active &&
        setting.cleanup.days >= 0
      )
        return moment()
          .startOf('day')
          .add(-setting.cleanup.days, 'days')
          .toDate();

      return null;
    },
    channels() {
      return this.currentChannels.filter((c) => !c.blocked);
    },
    dateFilterIsToday() {
      return (
        this.dateFilterValue && moment().isSame(this.dateFilterValue, 'day')
      );
    },
    dateFilterIsNotAllowed() {
      return (
        this.dateFilterValue &&
        this.cleanupDate &&
        moment(this.dateFilterValue).isSameOrBefore(this.cleanupDate)
      );
    },
    dateFilterString() {
      if (this.dateFilterIsToday) {
        return this.$t('common.today');
      } else if (
        this.dateFilterValue &&
        moment()
          .add(-1, 'day')
          .isSame(this.dateFilterValue, 'day')
      ) {
        return this.$t('common.yesterday');
      }

      return this.i18nDateFormat(
        this.dateFilterValue,
        this.$vuetify.lang.current,
        this.$t('common.dateFormatShort')
      );
    },
  },
  watch: {
    filteredChats() {
      if (this.selectedChat) {
        if (this.filteredChats.length > 0 && this.$vuetify.breakpoint.mdAndUp) {
          this.selectedChat = this.filteredChats[0];
        } else {
          this.selectedChat = null;
        }
      }
    },
    async selectedChat() {
      this.history = [];
      if (this.selectedChat && this.selectedChat.uniqueUserId) {
        const result = await DialogService.getChatHistory(
          this.viewableBotId,
          this.selectedChat.uniqueUserId
        );
        this.history = result.history;
        this.historyStruct(this.selectedChat);
      }
    },
  },
  created() {
    this.loadChats();
  },
  methods: {
    changeSelectedChat(chat) {
      if (!chat) {
        this.selectedChat = null;
        return;
      }
      this.selectedChat = chat;
    },
    isCleanUp(item) {
      if (this.cleanupDate === null || !item) return false;
      return moment(item.created).isBefore(this.cleanupDate);
    },
    allowedDates(val) {
      return (
        (this.cleanupDate === null ||
          moment(val).isSameOrAfter(this.cleanupDate)) &&
        moment(val).isSameOrBefore(moment())
      );
    },
    onFilterLoading(loading) {
      this.filterIsLoading = loading;
    },
    changeDay(days) {
      this.dateFilter = moment(this.dateFilter)
        .add(days, 'days')
        .toDate()
        .toISOString();
      this.loadChats();
    },
    suggestValue(suggestArray) {
      if (!suggestArray) return false;

      const isSuggestion = suggestArray.find((s) => {
        return s;
      });

      return isSuggestion;
    },
    async loadChats() {
      this.selectedChat = null;
      this.chats = [];
      this.loading = true;

      this.dateFilterValue = this.dateFilter;

      // create maps
      this.filterStats = {
        channels: {},
        thumbUp: 0,
        thumbDown: 0,
        goals: 0,
        suggests: 0,
        unknowns: 0,
        conversations: 0,
      };

      this.channels.forEach((channel) => {
        this.filterStats.channels[channel.channelId] = {
          thumbUp: 0,
          thumbDown: 0,
          goals: 0,
          suggests: 0,
          unknowns: 0,
          conversations: 0,
        };
      });

      const result = await DialogService.getChats(
        this.viewableBotId,
        this.dateFilter
      );
      this.chats = result.chats;

      if (this.chats.length > 0 && this.$vuetify.breakpoint.mdAndUp) {
        this.selectedChat = this.chats[0];
      }
      this.chats.forEach((chat) => {
        if (!this.filterStats.channels[chat.channelId]) {
          this.filterStats.channels[chat.channelId] = {
            thumbUp: 0,
            thumbDown: 0,
            goals: 0,
            suggests: 0,
            unknowns: 0,
            conversations: 0,
          };
        }

        this.filterStats.channels[chat.channelId].conversations++;
        this.filterStats.conversations++;

        chat.all.forEach((item) => {
          if (item.pos > 0) {
            this.filterStats.channels[chat.channelId].thumbUp++;
            this.filterStats.thumbUp++;
          } else if (item.goal > 0) {
            this.filterStats.channels[chat.channelId].goals++;
            this.filterStats.goals++;
          } else if (item.neg > 0) {
            this.filterStats.channels[chat.channelId].thumbDown++;
            this.filterStats.thumbDown++;
          } else if (item.suggest.length > 1 || item.suggest[0] !== false) {
            this.filterStats.channels[chat.channelId].suggests++;
            this.filterStats.suggests++;
          } else if (item.unknown > 0) {
            this.filterStats.channels[chat.channelId].unknowns++;
            this.filterStats.unknowns++;
          }
        });
      });

      this.loading = false;
    },
    isConversationStart(actions) {
      const found = actions.find((a) => {
        return a._id.name === 'conversation';
      });

      return !!found;
    },
    historyStruct(item) {
      const structure = {
        before: [],
        after: [],
        uuids: {},
      };

      let nextItem = -1;
      const tmpBefore = [];
      let conversation = false;
      let after = false;

      const conversationStarts = this.history.filter((action) => {
        return action._id.name === 'conversation';
      });

      const selectedConversationIndex = conversationStarts.findIndex(
        (action) => {
          return action._id.uuid === item.uuid;
        }
      );

      if (selectedConversationIndex + 1 < conversationStarts.length) {
        after = conversationStarts[selectedConversationIndex + 1];
      }
      let afterMode = false;

      this.history.forEach((action) => {
        // wenn keine Items mehr verfügbar , wenn action ist nächstes Item, ...
        if (
          (item.all.length > nextItem + 1 &&
            action._id.uuid === item.all[nextItem + 1]._id) ||
          (nextItem < 0 && action._id.uuid === item.uuid)
        ) {
          nextItem++;
        }

        if (after && action._id.uuid === after._id.uuid) {
          afterMode = true;
        }

        if (nextItem < 0 && !afterMode) {
          structure.before.push(action);
        }

        if (
          nextItem >= 0 &&
          !afterMode &&
          (item.all.length > nextItem || item.all.length === 0)
        ) {
          const _id =
            item.all.length === 0 ? item.uuid : item.all[nextItem]._id;
          if (!structure.uuids[_id]) {
            structure.uuids[_id] = { actions: [] };
          }

          if (action._id.name === 'new_user') {
            structure.uuids[_id].new_user = true;
          }

          if (action._id.name === 'conversation' && conversation === false) {
            structure.uuids[_id].conversation = true;
            structure.uuids[_id].actions.push(...tmpBefore);
            conversation = true;
          }
          if (!conversation) {
            tmpBefore.push(action);
          } else {
            structure.uuids[_id].actions.push(action);
          }
        }
        if (afterMode) {
          structure.after.push(action);
        }
      });

      for (const uuid of Object.keys(structure.uuids)) {
        const actions = structure.uuids[uuid].actions;
        for (const action of actions) {
          for (const protocol of action.protocols) {
            if (
              protocol.intent &&
              protocol.intent.name === 'livechat_available'
            ) {
              structure.uuids[uuid].livechat = true;
            }
          }
        }
      }

      this.structure = structure;
    },
    userInput(action, idx, uuid) {
      const found = action.protocols.find((p) => {
        if (p.incomingMessage && p.incomingMessage.userInput) {
          return true;
        }

        return false;
      });

      if (found && found.incomingMessage.data.message) {
        return found.incomingMessage.data.message.text;
      } else if (found) {
        if (
          found.incomingMessage.data.intent.name &&
          found.incomingMessage.data.intent.name.startsWith('goal_')
        ) {
          return this.$t('chats.conversation.goal.label');
        }

        if (idx !== undefined && idx >= 1 && uuid) {
          const lastAction = this.structure.uuids[uuid].actions[idx - 1];
          if (lastAction.protocols && lastAction.protocols.length > 0) {
            const lastMessage =
              lastAction.protocols[lastAction.protocols.length - 1];

            if (
              lastMessage.outgoingMessages &&
              lastMessage.outgoingMessages.length > 0 &&
              lastMessage.outgoingMessages[
                lastMessage.outgoingMessages.length - 1
              ].quick_replies
            ) {
              const qr = lastMessage.outgoingMessages[
                lastMessage.outgoingMessages.length - 1
              ].quick_replies.find((qr) => {
                return (
                  qr.intent.name === found.incomingMessage.data.intent.name
                );
              });

              if (qr) {
                return qr.label;
              }
            }
          }
        }

        if (
          found.incomingMessage.data.intent.name === 'livechat_not_available'
        ) {
          return false;
        }

        // if user input is a teaser button click
        if (found.incomingMessage.data.intent.name.includes('teaser_action')) {
          const teaserAction = this.getTeaserAction(found.incomingMessage.data.intent.name, found.incomingMessage.channelId);
          return teaserAction?.text || 'n.a.';
        }
        return this.displayName(found.incomingMessage.data.intent.name);
      }

      return 'n.a.';
    },
    isUserInputButton(action) {
      const found = action.protocols.find((p) => {
        if (p.incomingMessage && p.incomingMessage.userInput) {
          return true;
        }

        return false;
      });
      if (found) {
        return found.incomingMessage.data && found.incomingMessage.data.intent;
      }

      return false;
    },
    async deleteConversation() {
      if (!this.toDelete) return;

      this.loading = true;
      await DialogService.deleteConversation(
        this.currentBot.uniqueBotId,
        this.toDelete
      );
      this.toDelete = null;
      this.loading = false;
      this.isDeleteDialogOpen = false;
      this.loadChats();
      this.trackIt('conversation-deleted');
    },
    openDeleteDialog(uniqueUserId) {
      this.toDelete = uniqueUserId;
      this.isDeleteDialogOpen = true;
    },
    trackIt(type) {
      TrackingService.track({
        botId: this.currentBot.uniqueBotId,
        name: 'chat-insights',
        type,
        source: 'ChatInsights',
      });
    },
    getTeaserAction(teaserActionId, channelId) {
      const channel = this.getCurrentChannelById(channelId);
      
      if (!channel || !channel.teaser || !Array.isArray(channel.teaser)) {
        return false;
      }

      const teasers = channel.teaser;
      let teaserAction;

      teasers.forEach((teaser) => {
        const ta = teaser.payload?.quickActions?.find((a) => (a.id === teaserActionId));
        if (ta) teaserAction = ta;
      });

      return teaserAction || false;
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === 'chats' && to.query.old === 'true') {
      this.trackIt('open-old');
    }
    next();
  },
};
</script>
<style scoped scss>
.selectborder {
  border-left: 4px solid var(--v-secondary-darken2) !important;
}

.messageborder {
  border: 1px solid rgb(224, 224, 224) !important;
}

.rounded-custom {
  border-radius: 4px;
}

.successborder {
  border-right: 4px solid var(--v-success-darken1) !important;
}
</style>

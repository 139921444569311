<template>
  <div>
    <v-card :class="{ 'ma-3': !isInPopup }" flat>
      <v-row>
        <v-col cols="12" sm="5" class="white pb-0">
          <v-row justify="center">
            <v-card flat class="avatar-preview">
              <v-card-title>{{ $t('common.preview') }}</v-card-title>
              <v-card-text class="text-center d-flex flex-column align-center">
                <Avatar
                  ref="avatar"
                  :image="image"
                  :face-color="face.color"
                  :background="background.color"
                  :arm="arm.color"
                  :shirt="shirt.color"
                  :logo-size="logoSize"
                  :logo-posy="posy"
                  :logo-posx="posx"
                  @onDragLogo="onDragLogo"
                  @onCreateAvatarBase64="onCreateAvatarBase64"
                />
                <p class="mt-1">
                  {{ $t('editor.widget.appearance.avatar.enlargedView') }}
                </p>
              </v-card-text>

              <v-card-text class="text-center d-flex flex-column align-center">
                <Avatar
                  :image="image"
                  class="original-size-avatar"
                  :face-color="face.color"
                  :background="background.color"
                  :arm="arm.color"
                  :shirt="shirt.color"
                  :logo-size="logoSize"
                  :logo-posy="posy"
                  :logo-posx="posx"
                  circle-border-size="3px"
                  prevent-drag
                />
                <p class="mt-1 mb-0">
                  {{ $t('editor.widget.appearance.avatar.originalView') }}
                </p>
              </v-card-text>
            </v-card>
          </v-row>
        </v-col>
        <v-col cols="12" sm="7" class="white">
          <v-card flat>
            <v-card-title>
              {{ $t('editor.widget.appearance.avatar.colors') }}
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" class="pt-0">
                  <HubColorInput
                    v-model="background"
                    :label="
                      $t('editor.widget.appearance.avatar.backgroundColor')
                    "
                  />
                </v-col>
                <v-col cols="12">
                  <HubColorInput
                    v-model="shirt"
                    :label="$t('editor.widget.appearance.avatar.shirt')"
                  />
                </v-col>
                <v-col cols="12">
                  <HubColorInput
                    v-model="arm"
                    :label="$t('editor.widget.appearance.avatar.sleeves')"
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-title class="pt-0 pb-0">Logo</v-card-title>
            <v-card-text class="pb-0">
              <p class="mb-0">
                {{ $t('editor.widget.appearance.avatar.infoLogo') }}
              </p>
            </v-card-text>
            <v-card-text class="pt-0 pb-0">
              <v-row>
                <v-col cols="12" class="pt-4 pb-0">
                  <v-file-input
                    v-model="file"
                    accept="image/png, image/jpeg, image/bmp, image/gif"
                    prepend-icon=""
                    truncate-length="15"
                    outlined
                    :label="$t('editor.widget.appearance.avatar.uploadLogo')"
                  >
                    <template v-if="image" v-slot:append-outer>
                      <div
                        class="avatar mt-n3"
                        style="min-width:48px;width:48px;min-height:48px;height:48px;"
                      >
                        <img width="48px" :src="image" />
                      </div>
                    </template>
                  </v-file-input>
                </v-col>
                <v-col cols="12" class="pt-0 pb-0">
                  <v-slider
                    v-model="logoSize"
                    :disabled="!file"
                    :label="$t('editor.widget.appearance.avatar.size')"
                    max="50"
                    min="5"
                  >
                    <template v-slot:thumb-label="props">
                      {{ props.value }}px
                    </template>
                  </v-slider>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions v-if="!isInPopup">
              <v-btn @click="save">
                {{ $t('editor.widget.appearance.avatar.download') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import Avatar from '@/components/Avatar.vue';
import HubColorInput from '@/components/hub/HubColorPicker.vue';
import ImageFileUtil from '@/utils/imageFile';

export default {
  name: 'AvatarEditor',
  components: {
    Avatar,
    HubColorInput,
  },
  props: {
    isInPopup: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      background: { color: '#CCCCCC' },
      shirt: { color: '#FFFFFF' },
      arm: { color: '#00FF00' },
      face: { color: '#19293e' },
      logoSize: 20,
      file: null,
      image: undefined,
      posx: null,
      posy: null,
    };
  },
  computed: {},
  watch: {
    file(file) {
      if (!file || file === '') {
        this.image = '';
        return;
      }

      ImageFileUtil.createImageURL(file, (dataURL) => (this.image = dataURL));
    },
  },
  created() {},
  methods: {
    save() {
      this.$refs.avatar.save();
    },
    getAvatarImage() {
      return this.$refs.avatar.getImage();
    },
    onCreateAvatarBase64(image) {
      return this.$emit('onCreateAvatarBase64', image);
    },
    onDragLogo({ posx, posy }) {
      this.posx = posx;
      this.posy = posy;
    },
  },
};
</script>

<style lang="scss" scoped>
.original-size-avatar {
  max-width: 50px;
}
.avatar {
  border-radius: 50%;
  overflow: hidden;
}
.avatar-preview {
  width: 100%;
}
</style>

<template>
  <v-row v-if="teaser && teaser.payload">
    <v-col>
      <h3 class="text-h6">{{ $t('teasers.name.title') }}</h3>
      <div class="text-body-1 mb-4">{{ $t('teasers.name.info') }}</div>
      <v-text-field
        class="mb-4"
        v-model="teaser.payload.displayName"
        outlined
        dense
        hide-details
        type="text"
        :label="`${$t('teasers.name.inputLabel')}`"
        @input="checkDirty"
      ></v-text-field>
      <InfoBox :text="$t('teasers.name.infoBottom')" />
    </v-col>
  </v-row>
</template>
<script>
import InfoBox from '@/components/common/InfoBox.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'TeasersName',
  components: {
    InfoBox,
  },
  computed: {
    ...mapGetters('teasers', ['teaser']),
  },
  methods: {
    checkDirty() {
      this.$store.commit('teasers/checkIsDirty');
    }
  }
};
</script>
<template>
  <div style="height: 100%; width: 100%; overflow-y: auto">
    <div class="demo-start px-6 pb-6 d-flex items-center">
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        @submit.prevent="next()"
      >
        <div>
          <div style="min-height: 50vh;" class="py-4 text-center d-flex flex-column justify-center">
            <h1 class="text-h2 mb-6 moinblue--text">
              {{ $t('demo.stepCompany.title') }}
            </h1>
            <div class="text-body-1 primary--text text-lighten-2">
              {{ $t('demo.stepCompany.body') }}
            </div>
          </div>

          <div class="mb-0">
            <v-text-field
              ref="urlInputRef"
              outlined
              v-model="localCompanyUrl"
              :rules="companyUrlRules"
              required
              @change="handleChange()"
              :validateOnBlur="valid"
              @keyup.enter="next()"
              :label="$t('demo.stepCompany.labelCompanyUrl')"
              @blur="
                () => !localCompanyUrl.length && $refs.form.resetValidation()
              "
              autofocus
              :error-messages="hasError ? [$t('demo.stepCompany.errorCompanyUrl')] : []"
             />
          </div>
          <div class="text-body-2 primary--text text-lighten-2 text-center">
            {{ $t('demo.stepCompany.inputHint') }}
          </div>
          <div class="text-center py-4">
            <v-btn color="secondary" depressed @click="next()">
              Los geht's
            </v-btn>
          </div>
        </div>
      </v-form>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'DemoStepCompany',
  data() {
    return {
      valid: true,
      localCompanyUrl: '',
      initialCompanyUrl: '', // for dirty check
      animController: null,
      animPlayAllowed: true,
    };
  },
  mounted() {
    // if the url parameter is there continue with the processing
    const companyUrl = this.$route.query.url;
    if (companyUrl && companyUrl !== this.companyUrl) {
      this.localCompanyUrl = companyUrl;
      this.$store.commit('demo/setValue', {
        key: 'companyUrl',
        value: companyUrl,
      });
      
      this.$nextTick(() => { this.next(); });
      
    } else {
      this.localCompanyUrl = this.companyUrl;
  
      this.initialCompanyUrl = String(this.companyUrl);
    }


    //setTimeout(() => this.$refs.urlInputRef.$parent.$el['1'].focus(), 300);
  },
  computed: {
    ...mapGetters('demo', ['step', 'companyUrl', 'hasError']),
    companyUrlRules() {
      // Regex for valid URL, with url segments after domain e.g. https://www.example.com/segment1/segment2 allow queries e.g. https://www.example.com/segment1/segment2?query=1
      const reg = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-_]+\.[a-zA-Z0-9-._]+(\/[a-zA-Z0-9-_]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-_]+)*$/;

      return [
        (v) => !!v || this.$t('demo.stepCompany.ruleCompanyUrl'),
        (v) =>
          (!!v && v.length <= 250) ||
          this.$t('demo.stepCompany.ruleCompanyUrlLength'),
        (v) =>
          (!!v && reg.test(v)) ||
          this.$t('demo.stepCompany.ruleCompanyUrlValid'),
      ];
    },
  },
  methods: {
    ...mapActions('demo', ['handleStepCompany']),
    handleChange() {
      // Remove query params
      this.localCompanyUrl = this.localCompanyUrl.split('?')[0];
      this.$store.commit('demo/setValue', {
        key: 'companyUrl',
        value: this.localCompanyUrl,
      });
    },
    async next() {
      this.valid = await this.$refs.form.validate();
      if (!this.valid || this.localCompanyUrl.length < 4) return;
      
      const language = this.$vuetify.lang.current;

      let dirty = false;

      if (this.initialCompanyUrl !== this.companyUrl) {
        dirty = true;
        this.$store.commit('demo/resetAll');
      }

      this.handleStepCompany({ language, dirty });
    },
  },
};
</script>
<style lang="scss">
.demo-start {
  margin: 0 auto;
  max-width: 400px;
}
</style>

<template>
  <div class="mb-5">
    <v-divider class="my-5" />
    <div class="pb-5">
      <h2
        class="d-flex text-h6 primary--text mb-3"
        style="font-size: 1.1rem !important"
      >
        <v-icon class="d-inline mr-3" color="primary lighten-3">
          {{ typeValues[formStep.template].icon }}
        </v-icon>
        <span>{{ formStep.displayName }}</span>
        <span class="primary--text text--lighten-3 ml-1">
          ({{ typeValues[formStep.template].label }})
        </span>
      </h2>

      <BarPercentage
        :value="conversionRate"
        backgroundColor="grey lighten-3"
        :no-data="noData"
        :buffer="100"
        :tooltip="
          $t('forms.analysis.tooltipStep', {
            started: String(formStep.started),
            finished: String(formStep.finished),
          })
        "
      />

      <div class="mt-6" v-if="isAdmin && formStep.finishedUsers.length > 0">
        <v-tooltip
          bottom
          color="primary lighten-3"
        >
          <template
            bottom
            v-slot:activator="{ on: onTooltip, attrs: attrsTooltip }"
          >
            <v-btn
              outlined
              x-small
              color="admin"
              @click="openLeads(formStep.finishedUsers)"
              v-on="onTooltip"
              v-bind="attrsTooltip"
            >
              Leads
              <v-icon right>mdi-list-box-outline</v-icon>
            </v-btn>
          </template>
          <span>{{ 'Resultate anzeigen' }}</span>
        </v-tooltip>
      </div>

      <template v-if="formStep.values && formStep.values.length">
        <p class="pt-6">{{ $t('forms.analysis.answerDistribution') }}</p>

        <div
          v-for="(item, index) in answersValuesSorted"
          :key="index"
          style="height: 54px"
        >
          <p class="body-2 mb-2">{{ item.name }}</p>

          <BarPercentage
            small
            noBackground
            :buffer="0"
            :value="item.chosenPercentage"
            :no-data="noData"
            :tooltip="
              $t('forms.analysis.tooltipStepAnswer', {
                answers: String(item.chosen),
                allAnswers: String(formStep.finished),
              })
            "
          />
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import BarPercentage from '@/components/common/BarPercentage.vue';

export default {
  name: 'BotFormsInsightsItem',
  components: { BarPercentage },
  props: {
    formStep: {
      type: Object,
      required: true,
    },
    intent: {
      type: String,
      required: true,
    },
    channelId: {
      type: String,
    },
    noData: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('bots', ['currentBotId']),
    ...mapGetters('auth', ['isAdmin']),
    content() {
      return this.$store.getters['content/getContent'](
        this.currentBotId,
        this.intent,
        this.formStep.box,
        true,
        this.channelId
      );
    },
    conversionRate() {
      if (this.formStep.started === 0) {
        return 0;
      }

      return (this.formStep.finished / this.formStep.started) * 100;
    },
    answersValuesSorted() {
      const answers = this.getAnswersFromContent();

      let list = [...this.formStep.values];
      const groupedMap = {};

      list.forEach((item) => {
        // add item to groupedMap with intent as key
        groupedMap[item.intent] = groupedMap[item.intent] || [];
        groupedMap[item.intent].push(item);
      });

      // create list out of groupedMap
      list = [];
      Object.keys(groupedMap).forEach((key) => {
        // aggregate items out of groupedMap arrays
        const items = groupedMap[key];
        const chosen = items.reduce((acc, item) => acc + item.chosen, 0);
        const chosenPercentage = items.reduce(
          (acc, item) => acc + item.chosenPercentage,
          0
        );
        list.push({
          name: answers[key],
          chosen,
          intent: key,
          chosenPercentage,
        });
      });
      const unknowns = list.filter((item) => item.name === null);
      list = list.filter((item) => item.name);

      list.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        if (a.name.toLowerCase() === b.name.toLowerCase()) return 0;
        return 1;
      });

      if (unknowns.length > 0) {
        const unknownItem = {
          name: `"${this.$t('common.unknown')}"`,
          intent: 'unknown',
          chosen: 0,
          chosenPercentage: 0,
        };

        for (let unknown of unknowns) {
          unknownItem.chosen += unknown.chosen;
          unknownItem.chosenPercentage += unknown.chosenPercentage;
        }

        list.push(unknownItem);
      }

      return list;
    },
    typeValues() {
      // Key names bounded to formItem.template!
      return {
        BotFormFreeText: {
          icon: 'mdi-text',
          label: this.$t('forms.formChildTypes.freeText.name'),
        },
        BotFormEmail: {
          icon: 'mdi-email',
          label: this.$t('forms.formChildTypes.eMail.name'),
        },
        BotFormMultipleChoice: {
          icon: 'mdi-format-list-bulleted-square',
          label: this.$t('forms.formChildTypes.multipleChoice.name'),
        },
        BotFormYesNo: {
          icon: 'mdi-call-split',
          label: this.$t('forms.formChildTypes.yesNo.name'),
        },
        BotFormActionView: {
          icon: 'mdi-text',
          label: this.$t('forms.formChildTypes.actionView.name'),
        },
      };
    },
  },
  methods: {
    getAnswersFromContent() {
      // get last content
      let allContents = this.content?.question?.actions?.filter(
        (a) => a.content
      );
      let content =
        allContents?.length > 0
          ? allContents?.[allContents.length - 1].content
          : null;
      if (!content) return null;

      const answers = {};
      content.quick.forEach((item) => {
        const stringItem = content.strings.find(
          (i) => `{${i.name}}` === item.label
        );
        answers[item.intent] = stringItem.data;
      });
      return answers;
    },
    openLeads(users) {
      this.$emit('openLeads', users);
    },
  },
};
</script>

<style scoped lang="scss">
.list-enter-active,
.list-leave-active {
  transition: all 0.2s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(20px);
}

:deep(.v-alert__icon) {
  align-self: center;
  color: var(--v-secondary-darken1);
}

:deep(.v-progress-linear) {
  overflow: visible;
  transition: 0.5s map-get($transition, 'ease-in-out');
}
:deep(.v-progress-linear--outer-value) {
  padding-bottom: $spacer * 4;
  margin-bottom: $spacer;
}
:deep(.v-progress-linear__background) {
  height: inherit;
}
:deep(.v-progress-linear__content) {
  justify-content: flex-start;
  strong {
    top: 10px;
    font-size: 14px;
    position: absolute;
    color: var(--v-primary-lighten2);
    transition: 0.5s map-get($transition, 'ease-in-out');
    transition-property: left, right;
  }
}
</style>

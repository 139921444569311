<template>
  <div v-if="localIntent">
    <HubBox
      title="Relations"
      boxId="intents-relations"
      :isLoading="isLoading"
      adminOnly
    >
      <div class="text-body-2 mb-4">
        Hier kannst du verwandte Themen einstellen.
      </div>

      <div>
        <!-- similar -->
        <div class="mb-4">
          <div class="primary--text text--lighten-3 mb-1 text-body-2">
            {{ labels[relationTypes.SIMILAR] }}
          </div>
          <template v-if="selectedSimilarsInitial.length">
            <span
              v-for="relation in selectedSimilarsInitial"
              :key="`similar-${relation.intent}`"
            >
              <a :href="`/intent/${relation.intent}/training`">
                {{ relation.displayName }}
              </a>
              , &nbsp;
            </span>
          </template>
          <span v-else>–</span>
        </div>

        <!-- generalization -->
        <div class="mb-4">
          <div class="primary--text text--lighten-3 mb-1 text-body-2">
            {{ labels[relationTypes.GENERALIZATION] }}
          </div>
          <template v-if="selectedGeneralizationsInitial.length">
            <span
              v-for="relation in selectedGeneralizationsInitial"
              :key="`generalization-${relation.intent}`"
            >
              <a :href="`/intent/${relation.intent}/training`">
                {{ relation.displayName }}
              </a>
              , &nbsp;
            </span>
          </template>
          <span v-else>–</span>
        </div>

        <!-- special_case -->
        <div class="mb-4">
          <div class="primary--text text--lighten-3 mb-1 text-body-2">
            {{ labels[relationTypes.SPECIAL_CASE] }}
          </div>
          <a
            v-if="selectedSpecialCaseInitial"
            :href="
              `/intent/${getIntentByIntent(selectedSpecialCaseInitial).intent}`
            "
          >
            {{ getIntentByIntent(selectedSpecialCaseInitial).displayName }}
          </a>
          <span v-else>–</span>
        </div>
      </div>

      <div class="mt-6">
        <v-btn outlined color="primary lighten-3" @click="isDialogOpen = true">
          {{ $t('common.edit') }}
          <v-icon right>edit</v-icon>
        </v-btn>
      </div>
    </HubBox>

    <HubDialog
      :title="`Relations für '${localIntent.displayName}'`"
      v-model="isDialogOpen"
      :primary-label="$t('common.save')"
      primary-icon="save"
      :secondary-label="$t('common.cancel')"
      secondary-icon="close"
      @onPrimary="saveEdit"
      @onSecondary="cancelEdit"
      modal
      :disabled="isLoading"
    >
      <div class="mb-4 text-body-2">
        Auswahl muss gespeichert werden.
      </div>
      <!-- similar -->
      <div class="mb-4">
        <div class="primary--text text--lighten-3 mb-1 text-body-2">
          {{ labels[relationTypes.SIMILAR] }}
        </div>
        <v-combobox
          outlined
          hide-details
          dense
          v-model="selectedSimilars"
          :items="intentsFilteredSimilar"
          item-text="displayName"
          multiple
        ></v-combobox>
      </div>

      <!-- generalization -->
      <div class="mb-4">
        <div class="primary--text text--lighten-3 mb-1 text-body-2">
          {{ labels[relationTypes.GENERALIZATION] }}
        </div>
        <v-combobox
          outlined
          hide-details
          dense
          v-model="selectedGeneralizations"
          :items="intentsFilteredGeneralization"
          item-text="displayName"
          multiple
        ></v-combobox>
      </div>

      <!-- special_case -->
      <div class="mb-4">
        <div class="primary--text text--lighten-3 mb-1 text-body-2">
          {{ labels[relationTypes.SPECIAL_CASE] }}
        </div>
        <v-select
          :items="intentsFilteredSpecialCase"
          :value="selectedSpecialCase"
          item-text="displayName"
          item-value="intent"
          outlined
          dense
          hide-details=""
          @change="(intent) => (selectedSpecialCase = intent)"
        />
      </div>
    </HubDialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import HubBox from '@/components/hub/HubBox.vue';
import HubDialog from '@/components/hub/HubDialog.vue';
import IntentService from '@/services/intents';

const relationTypes = {
  SIMILAR: 'similar',
  SPECIAL_CASE: 'special_case',
  GENERALIZATION: 'generalization',
};

const emptyRelation = { intent: null, displayName: '- KEINE -' };

export default {
  name: 'IntentRelations',
  components: {
    HubBox,
    HubDialog,
  },
  props: {
    intentId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      localIntent: null,
      relationTypes,
      isDialogOpen: false,
      isLoading: false,
      labels: {
        [relationTypes.SIMILAR]: 'Ähnlich wie:',
        [relationTypes.SPECIAL_CASE]: 'Spezialfall von:',
        [relationTypes.GENERALIZATION]: 'Verallgemeinerung von:',
      },
      selectedSimilars: [],
      selectedSimilarsInitial: [],
      selectedGeneralizations: [],
      selectedGeneralizationsInitial: [],
      selectedSpecialCase: null,
      selectedSpecialCaseInitial: null,
    };
  },
  async created() {
    this.initRelations();
  },
  computed: {
    ...mapGetters('bots', ['currentBotId']),
    ...mapGetters('intents', ['intents']),
    intentsFilteredSimilar() {
      const list = this.intents?.filter(
        (i) =>
          i.intent !== this.intentId &&
          !this.selectedGeneralizations.find((r) => r.intent === i.intent) &&
          i.intent !== this.selectedSpecialCase
      );

      return list;
    },
    intentsFilteredGeneralization() {
      const list = this.intents?.filter(
        (i) =>
          i.intent !== this.intentId &&
          !this.selectedSimilars.find((r) => r.intent === i.intent) &&
          i.intent !== this.selectedSpecialCase
      );

      return list;
    },
    intentsFilteredSpecialCase() {
      const list = this.intents?.filter(
        (i) =>
          i.intent !== this.intentId &&
          !this.selectedGeneralizations.find((r) => r.intent === i.intent) &&
          !this.selectedSimilars.find((r) => r.intent === i.intent)
      );
      list.unshift(emptyRelation);

      return list;
    },
  },
  watch: {
    intentId() {
      this.initRelations();
    },
  },
  methods: {
    async initRelations() {
      this.isLoading = true;
      this.localIntent = this.intents.find((i) => i.intent === this.intentId);

      if (!this.localIntent) {
        return;
      }

      // Set similars
      const relationsSimilar =
        this.localIntent.relations?.filter(
          (r) => r.relation === relationTypes.SIMILAR
        ) || [];
      this.selectedSimilars = relationsSimilar.map((r) =>
        this.getIntentByIntent(r.to)
      );
      this.selectedSimilarsInitial = [...this.selectedSimilars];

      // Set generalization
      const relationsGeneralization =
        this.localIntent.relations?.filter(
          (r) => r.relation === relationTypes.GENERALIZATION
        ) || [];
      this.selectedGeneralizations = relationsGeneralization.map((r) =>
        this.getIntentByIntent(r.to)
      );
      this.selectedGeneralizationsInitial = [...this.selectedGeneralizations];

      // Set special case
      const relationSpecialCase = this.localIntent.relations?.find(
        (r) => r.relation === relationTypes.SPECIAL_CASE
      );
      this.selectedSpecialCase = relationSpecialCase?.to || null;
      this.selectedSpecialCaseInitial = relationSpecialCase?.to || null;

      this.isLoading = false;
    },
    getIntentByIntent(intentId) {
      const intent = this.intents.find((i) => i.intent === intentId);
      return { ...intent } || null;
    },
    async saveEdit() {
      this.isLoading = true;

      await this.deleteSimilarRelations();
      await this.saveSimilarRelations();

      await this.deleteGeneralizationsRelations();
      await this.saveGeneralizationsRelations();

      await this.saveSpecialCase();

      await this.$store.dispatch('intents/get', this.currentBotId);
      this.isLoading = false;
    },
    async deleteSimilarRelations() {
      const itemsToDelete = this.selectedSimilarsInitial.filter(
        (s) => !this.selectedSimilars.find((r) => r.intent === s.intent)
      );

      for (let r of itemsToDelete) {
        await this.deleteRelation(r.intent);

        const index = this.selectedSimilarsInitial.findIndex(
          (i) => i.intent === r.intent
        );
        if (index >= 0) {
          this.selectedSimilarsInitial.splice(index, 1);
        }
      }

      return true;
    },
    async deleteGeneralizationsRelations() {
      const itemsToDelete = this.selectedGeneralizationsInitial.filter(
        (s) => !this.selectedGeneralizations.find((r) => r.intent === s.intent)
      );

      for (let r of itemsToDelete) {
        await this.deleteRelation(r.intent);

        const index = this.selectedGeneralizationsInitial.findIndex(
          (i) => i.intent === r.intent
        );
        if (index >= 0) {
          this.selectedGeneralizationsInitial.splice(index, 1);
        }
      }

      return true;
    },
    async saveSimilarRelations() {
      const itemsToSave = this.selectedSimilars.filter(
        (s) => !this.selectedSimilarsInitial.find((r) => r.intent === s.intent)
      );

      for (let r of itemsToSave) {
        await this.saveRelation({
          relatedIntent: r.intent,
          relation: relationTypes.SIMILAR,
        });

        this.selectedSimilarsInitial.push(r);
      }

      return true;
    },
    async saveGeneralizationsRelations() {
      const itemsToSave = this.selectedGeneralizations.filter(
        (s) =>
          !this.selectedGeneralizationsInitial.find(
            (r) => r.intent === s.intent
          )
      );

      for (let r of itemsToSave) {
        await this.saveRelation({
          relatedIntent: r.intent,
          relation: relationTypes.GENERALIZATION,
        });

        this.selectedGeneralizationsInitial.push(r);
      }

      return true;
    },
    async saveSpecialCase() {
      // Delete old relation
      if (
        this.selectedSpecialCaseInitial &&
        this.selectedSpecialCaseInitial !== this.selectedSpecialCase
      ) {
        await this.deleteRelation(this.selectedSpecialCaseInitial);
      }

      if (this.selectedSpecialCaseInitial === this.selectedSpecialCase) {
        return true;
      }

      await this.saveRelation({
        relatedIntent: this.selectedSpecialCase,
        relation: relationTypes.SPECIAL_CASE,
      });

      this.selectedSpecialCaseInitial = this.selectedSpecialCase;

      return true;
    },
    async saveRelation({ relatedIntent, relation }) {
      await IntentService.saveRelation(this.currentBotId, {
        intent: this.intentId,
        relatedIntent,
        relation,
      });
    },
    async deleteRelation(relatedIntent) {
      await IntentService.deleteRelation(this.currentBotId, {
        intent: this.intentId,
        relatedIntent,
      });
      return true;
    },
    cancelEdit() {
      this.selectedSimilars = [...this.selectedSimilarsInitial];
    },
  },
};
</script>

<template>
  <v-app style="max-height:100vh; height:100vh;">
    <HubMenu />

    <Appbar></Appbar>
    <v-main class="fill-height">
      <div
        class="not-found px-6"
        :class="[
          $vuetify.breakpoint.smAndUp ?
            'd-flex flex-column align-center justify-center fill-height' : 'pt-10',
        ]"
      >
        <v-container style="max-width: 1000px !important;" data-cy="404-page">
          <v-row>
            <v-col>
              <div :style="{ width: textWidth }">
                <h1
                  class="text-uppercase primary--text text--lighten-2 not-found-h1"
                >
                  {{ $t('pageNotFound.title') }}
                </h1>
                <h2 class="primary--text text--lighten-2 mb-4">
                  {{ $t('pageNotFound.subTitle') }}
                </h2>
                <p
                  class="text-body-1"
                  v-html="
                    currentBotContactId
                      ? $t('pageNotFound.textCsm')
                      : $t('pageNotFound.textEmail')
                  "
                ></p>

                <ContactItem
                  v-if="currentBotContactId"
                  :contact-id="currentBotContactId"
                  large
                />
              </div>
            </v-col>
            <v-col>
              <div class="d-flex justify-end">
                <div :style="{ width: imageWidth }">
                  <v-img
                    max-width="100%"
                    src="@/assets/page-not-found.png"
                  ></v-img>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-main>
  </v-app>
</template>
<script>
import HubMenu from '@/components/hub/HubMenu.vue';
import Appbar from '@/components/Appbar.vue';
import ContactItem from '@/components/ContactItem.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'NotFound404',
  components: {
    HubMenu,
    Appbar,
    ContactItem,
  },
  computed: {
    ...mapGetters('bots', ['currentBotContactId']),
    textWidth() {
      if (this.$vuetify.breakpoint.smAndUp) return '400px';
      return '300px';
    },
    imageWidth() {
      if (this.$vuetify.breakpoint.smAndUp) return '400px';
      if (this.$vuetify.breakpoint.mdAndUp) return '500px';
      if (this.$vuetify.breakpoint.lgAndUp) return '900px';
      return '280px';
    },
  },
};
</script>
<style scoped>
.not-found {
  font-family: 'Josefin Sans', sans-serif;
}

.not-found-h1 {
  font-size: 50px;
  margin-bottom: 0;
  line-height: 1;
}
</style>

<template>
  <v-navigation-drawer app v-model="drawer">
    <div style="height: 64px; padding-top: 4px" class="text-center">
      <img src="@/assets/moin-logo.svg" height="50px" />
    </div>

    <v-divider />

    <v-img
      v-if="avatarUrl"
      class="avatar ml-auto mr-auto mt-6"
      width="96px"
      height="96px"
      :src="avatarUrl"
    ></v-img>
    <v-list class="pt-0">
      <v-list-item v-if="loggedIn">
        <v-list-item-content class="pt-2">
          <v-list-item-title class="text-center">
            {{ avatarName }}
          </v-list-item-title>
          <v-list-item-subtitle class="text-center">
            {{ avatarTitle }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="loggedIn && channels && channels.length > 1">
        <v-list-item-content class="pt-2">
          <v-select
            :items="channels"
            :value="selectedChannelId"
            item-text="displayName"
            item-value="channelId"
            @change="changeChannel"
          ></v-select>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-6 mb-4"></v-divider>
      <HubDialog
        v-if="teaserCode"
        hideSecondary
        v-model="teaserCode"
        modal
        :title="$t('preview.teaser.integration.title')"
        :primaryLabel="$t('common.close')"
        primaryIcon="close"
      >
        <v-card-text>
          {{ $t('preview.teaser.integration.description') }}
        </v-card-text>
        <ssh-pre language="js" copy-button @copied="codeCopied = 'check'">
          {{ code }}
          <template #copy-button>
            <v-icon small>{{ codeCopied }}</v-icon>
          </template>
        </ssh-pre>
      </HubDialog>
      <h3
        style="font-weight: normal"
        color="primary"
        class="pa-4 mt-4 text-primary text-lighten-2"
      >
        {{ $t('preview.teaser.title') }}
      </h3>
      <v-list-item>
        <v-textarea
          rows="2"
          outlined
          :label="$t('preview.teaser.speech')"
          :value="teaser.message"
          @input="updateTeaserMessage"
        ></v-textarea>
      </v-list-item>
      <v-list-item
        v-for="(answer, index) in teaser.quickActions"
        :key="answer.intent"
      >
        <v-list-item-content>
          <v-text-field
            @click:append-outer="removeTeaserButton(answer.intent)"
            dense
            :append-outer-icon="
              loggedIn && botAvailable ? 'remove_circle_outline' : ''
            "
            class="mb-4"
            hide-details
            outlined
            :label="`Action Button ${index + 1}`"
            :value="answer.text"
            @input="(text) => updateQuickText(answer.intent, text)"
          ></v-text-field>
          <span v-if="loggedIn && botAvailable" class="text-caption mt-n3">
            <v-icon small left class="ml-1">east</v-icon>
            {{ displayName(answer.intent) }}
          </span>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="!startButton && loggedIn">
        <v-list-item-content>
          <v-btn @click="addStandard" text>
            <v-icon color="grey darken-2" style="text-transform: none" left>
              add_circle_outline
            </v-icon>
            Standard-Button
          </v-btn>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <template v-slot:append>
      <div v-if="integrationShown" style="padding-right: 1px">
        <v-divider class="grey lighten-2"></v-divider>
        <v-expansion-panels flat>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-card-title class="pa-0">
                <h5
                  style="font-weight: normal"
                  color="primary"
                  class="text-primary text-lighten-2"
                >
                  {{ $t('preview.teaser.integration.title') }}
                </h5>
              </v-card-title>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="text-body-2 mb-4 primary--text text--lighten-2">
                {{ $t('preview.teaser.description') }}
              </div>

              <v-btn
                class="mb-4"
                color="secondary darken-2"
                depressed
                block
                to="/wt/teaser"
                target="_blank"
              >
                {{ $t('widgetTeaser.teaser.title') }}
                <v-icon color="white" right>
                  mdi-message-text-outline
                </v-icon>
              </v-btn>
              
              <div class="text-body-2 mb-4 primary--text text--lighten-2" v-html="$t('preview.teaser.descriptionCode')">
              </div>

              <v-btn
                class="mb-4"
                color="secondary darken-2"
                outlined
                small
                block
                @click="
                  hideTeaser();
                  teaserCode = true;
                "
              >
                {{ $t('preview.teaser.generate') }}
                <v-icon color="secondary darken-2" right>
                  integration_instructions
                </v-icon>
              </v-btn>

            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <HubMenuProfile :myProfileHidden="!isTester" preview />
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import HubDialog from './hub/HubDialog.vue';
import SshPre from 'simple-syntax-highlighter';
import 'simple-syntax-highlighter/dist/sshpre.css';
import HubMenuProfile from '@/components/hub/HubMenuProfile';

export default {
  name: 'MenuPreview',
  props: {
    loggedIn: {
      type: Boolean,
      default: false,
    },
    botAvailable: {
      type: Boolean,
      default: false,
    },
    config: Object,
    botId: String,
    channelId: String,
  },
  components: {
    HubDialog,
    SshPre,
    HubMenuProfile,
  },
  data() {
    return {
      drawer: null, // drawer visible or not,
      teaserCode: false,
      codeCopied: 'content_copy',
    };
  },
  created() {
    this.$store.commit('preview/setInitialTexts', {
      messageText: this.$t('preview.teaser.defaultMessage'),
      actionText: this.$t('preview.teaser.defaultAction'),
    });
  },
  computed: {
    ...mapGetters('hubUi', ['isMenuOpen']),
    ...mapGetters('auth', [
      'isAdmin',
      'isOwner',
      'isSimpleEdit',
      'isTester',
      'isFormView',
      'isConversationalView',
      'isWidgetEditorView',
      'user',
    ]),
    ...mapGetters('bots', ['currentChannels']),
    ...mapGetters('preview', [
      'teaser',
      'teaserButtons',
      'getWidgetConfig',
      'selectedChannel',
    ]),
    ...mapGetters('intents', ['intents']),
    isFacebook() {
      if (this.channelId && this.currentChannels.length > 0) {
        const channel = this.currentChannels.find(
          (channel) => channel.channelId === this.channelId
        );
        if (channel) {
          return channel.channel === 'facebook';
        }
      }

      return false;
    },
    channels () {
      return this.currentChannels.filter((c) => !c.blocked);
    },
    selectedChannelId() {
      if (this.channelId) {
        return this.channelId;
      } else if (this.selectedChannel && this.channels.length > 0) {
        return this.channels[0].channelId;
      } else {
        return null;
      }
    },
    startButton() {
      return this.teaserButtons.find((button) => button.intent === 'start');
    },
    languages() {
      return [
        { value: 'en', name: 'English' },
        { value: 'de', name: 'Deutsch' },
      ];
    },
    avatarUrl() {
      const widgetConfig = this.getWidgetConfig(this.channelId);
      if (!widgetConfig) {
        return '';
      } else {
        if (
          widgetConfig.editorConfig?.settings?.operator
        ) {
          return widgetConfig.editorConfig.settings.operator.avatar_url;
        } else if (
          widgetConfig.components?.widget?.operator?.default
        ) {
          return widgetConfig.components.widget.operator.default.avatar_url;
        }

        return '';
      }
    },
    avatarName() {
      const widgetConfig = this.getWidgetConfig(this.channelId);
      if (!widgetConfig) {
        if (this.isFacebook) {
          return 'Facebook';
        }

        return '';
      } else {
        if (
          widgetConfig.editorConfig &&
          widgetConfig.editorConfig.settings &&
          widgetConfig.editorConfig.settings.operator
        ) {
          return widgetConfig.editorConfig.settings.operator.name;
        } else if (
          widgetConfig.components &&
          widgetConfig.components.widget &&
          widgetConfig.components.widget.operator &&
          widgetConfig.components.widget.operator.default
        ) {
          return widgetConfig.components.widget.operator.default.name;
        }

        return '';
      }
    },
    avatarTitle() {
      const widgetConfig = this.getWidgetConfig(this.channelId);
      if (!widgetConfig) {
        return '';
      } else {
        if (
          widgetConfig.editorConfig &&
          widgetConfig.editorConfig.settings &&
          widgetConfig.editorConfig.settings.operator
        ) {
          return widgetConfig.editorConfig.settings.operator.titel;
        } else if (
          widgetConfig.components &&
          widgetConfig.components.widget &&
          widgetConfig.components.widget.operator &&
          widgetConfig.components.widget.operator.default
        ) {
          return widgetConfig.components.widget.operator.default.titel;
        }

        return '';
      }
    },
    code() {
      let code =
        ` window.knowhere.api.teaser({\n` +
        `     showTeaser: true,\n` +
        `     showAvatar: true,\n` +
        (this.teaser?.message && `     message: '${this.teaser.message}',\n`) +
        `     quickActions: [\n`;

      // add actions
      this.teaserButtons.forEach((action, i) => {
        code =
          code +
          `     {\n` +
          `       text: '${action.text}',\n` +
          `       intent: '${action.intent}'\n`;
        if (i + 1 < this.teaserButtons.length) {
          code = code + `     },\n`;
        } else {
          code = code + `     }\n`;
        }
      });

      code = code + `     ]\n` + ` });`;

      return code;
    },
    integrationShown() {
      if (this.isAdmin || this.isOwner|| this.isSimpleEdit) {
        return true;
      }
      return false;
    },
  },
  watch: {
    drawer: function(value) {
      this.$store.commit('hubUi/setValue', { key: 'isMenuOpen', value });
    },
    isMenuOpen(val) {
      this.drawer = val;
    },
    codeCopied(value) {
      if (value !== 'conent_copy') {
        let $this = this;
        setTimeout(() => {
          $this.codeCopied = 'content_copy';
        }, 2500);
      }
    },
  },
  methods: {
    ...mapActions('auth', ['logout']),
    ...mapActions('preview', [
      'setTeaserMessage',
      'addTeaserButton',
      'showTeaser',
      'hideTeaser',
      'setTeaserButtonText',
      'removeTeaserButton',
    ]),
    displayName(intent) {
      if (intent === 'start') {
        return this.$t('preview.teaser.standard');
      }

      if (!this.intents) {
        return intent;
      }

      let intentObj = this.intents.find((i) => i.intent === intent);
      if (intentObj) {
        return this.$store.getters['intents/displayName'](intentObj.intent, this.$vuetify.lang.current);
      }

      return intent;
    },
    updateTeaserMessage: _.debounce(async function(text) {
      this.setTeaserMessage(text);
    }, 1000),
    updateQuickText: _.debounce(async function(intent, text) {
      this.setTeaserButtonText({ intent, text });
    }, 1000),
    addStandard() {
      this.addTeaserButton({ intent: 'start', text: 'Frage stellen' });
    },
    changeChannel(channel) {
      this.$router.push({
        name: 'preview',
        params: { botid: this.botId, channelid: channel },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar {
  border-radius: 50%;
  //border-radius: var(--kw-widget-global-borderradius-shape);
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  svg,
  img {
    background: #d3d3d3;
    width: 100%;
    height: 100%;
    overflow: hidden;
    object-fit: cover;
    object-position: center center;
    align-self: flex-end;
    transform: scale(1.1);
    transition: transform 0.15s ease-in-out;
    &:hover {
      transform: scale(1.1);
      transition: transform 0.15s ease-in-out;
    }
    &:active {
      transform: scale(1.1);
      transition: transform 0.15s ease-in-out;
    }
    &.squared {
      border-radius: 10%;
      border-radius: 1px solid #d3d3d3;
    }
  }
}
</style>

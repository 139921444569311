<template>
  <v-app style="max-height: 100vh; height: 100vh">
    <HubMenu />
    <Appbar />
    <HubContent :isSidebarOpen="isSidebarOpen">
      <template v-slot:navbar>
        <HubContentNavbar :title="$t('stats.title')">
          <template v-slot:toolbar>
            <BtnSquare
              icon="view_sidebar"
              @click="() => (isSidebarOpen = !isSidebarOpen)"
            />
          </template>
        </HubContentNavbar>
      </template>
      <template v-slot:sidebar>
        <HubContentSidebarSimple
          :isOpen="isSidebarOpen"
          @onClose="() => (isSidebarOpen = false)"
        >
          <StatsOverviewSidebar />
        </HubContentSidebarSimple>
      </template>
      <template v-slot:content>
        <StatsOverview />
      </template>
    </HubContent>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import HubMenu from '@/components/hub/HubMenu.vue';
import Appbar from '@/components/Appbar.vue';
import HubContent from '@/components/hub/HubContent.vue';
import HubContentNavbar from '@/components/hub/HubContentNavbar.vue';
import HubContentSidebarSimple from '@/components/hub/HubContentSidebarSimple.vue';
import BtnSquare from '@/components/common/BtnSquare.vue';
import StatsOverview from '@/components/stats/StatsOverview.vue';
import StatsOverviewSidebar from '@/components/stats/StatsOverviewSidebar.vue';

export default {
  name: 'Stats',
  components: {
    HubMenu,
    Appbar,
    HubContent,
    HubContentNavbar,
    HubContentSidebarSimple,
    BtnSquare,
    StatsOverview,
    StatsOverviewSidebar,
  },
  data() {
    return {
      isSidebarOpen: false,
    };
  },
  computed: {
    ...mapGetters('bots', [
      'currentBot',
      'viewableBotId',
      'currentChannels',
      'areChannelsBlocked',
    ]),
  },
  methods: {},
};
</script>

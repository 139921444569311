var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{staticStyle:{"max-height":"100vh","height":"100vh"}},[_c('HubMenu'),_c('Appbar'),_c('HubContent',{attrs:{"isSidebarOpen":_vm.isSidebarOpen,"fixedHeight":""},scopedSlots:_vm._u([{key:"navbar",fn:function(){return [_c('HubContentNavbar',{attrs:{"title":_vm.$t('botSettings.roles.title'),"backTo":"/settings","isLoading":_vm.isLoading},scopedSlots:_vm._u([{key:"toolbar",fn:function(){return [_c('BtnSquare',{attrs:{"icon":"mdi-cog","title":_vm.$t('botSettings.roles.generalSettings.title')},on:{"click":function($event){return _vm.toggleSettingSidebar()}}})]},proxy:true}])})]},proxy:true},{key:"sidebar",fn:function(){return [_c('HubContentSidebarSimple',{attrs:{"isOpen":_vm.isSidebarOpen,"title":_vm.selectedUser[0].username
              ? _vm.$t('botSettings.roles.userSettings.userTitle')
              : _vm.$t('botSettings.roles.generalSettings.sidebarTitle'),"icon":_vm.selectedUser[0].username ? 'info' : 'mdi-cog'},on:{"onClose":function($event){_vm.isSidebarOpen = false}}},[_c('div',{staticClass:"pa-4"},[(_vm.selectedUser[0].username)?_c('div',[_c('h3',{staticClass:"text-h5 my-3 text-truncate"},[_vm._v(" "+_vm._s(_vm.selectedUser[0].username)+" ")]),_c('div',{staticClass:"text-body-2 mb-8"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('botSettings.roles.userSettings.sidebarInfo'))}})]),_c('SettingsUserStatus',{staticClass:"mb-4",attrs:{"user":_vm.selectedUser[0]},on:{"onChange":function($event){_vm.fetchUsers();
                  _vm.trackIt('user-reinvited');}}}),(
                  !_vm.selectedUser[0].isItself &&
                    _vm.selectedUser[0] &&
                    _vm.selectedUser[0].active
                )?_c('SettingsUserRole',{staticClass:"mb-4",attrs:{"user":_vm.selectedUser[0]},on:{"onChanged":function($event){return _vm.fetchUsers()}}}):_vm._e(),(
                  !_vm.selectedUser[0].isItself &&
                    _vm.selectedUser[0] &&
                    _vm.selectedUser[0].active
                )?_c('SettingsDeleteUser',{staticClass:"mb-4",attrs:{"user":_vm.selectedUser[0],"currentBotId":_vm.currentBotId},on:{"onDeleted":_vm.fetchUsers}}):_vm._e()],1):_c('div',[_c('h3',{staticClass:"text-h5 my-3"},[_vm._v(" "+_vm._s(_vm.$t('botSettings.roles.generalSettings.sidebarTitle'))+" ")]),_c('div',{staticClass:"text-body-2 mb-8"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('botSettings.roles.generalSettings.sidebarInfo'))}})]),_c('div',{staticClass:"d-flex flex-col gap-4"},[_c('Settings2Auth',{attrs:{"activatedInitial":_vm.allUsersHave2FA},on:{"onChange":function($event){_vm.fetchUsers();
                    _vm.trackIt('2auth-feature');}}}),(_vm.ssoEnabled)?_c('SettingsSSO',{on:{"onChange":function($event){_vm.fetchUsers();
                    _vm.trackIt('sso-feature');}}}):_vm._e()],1)])])])]},proxy:true},{key:"content",fn:function(){return [_c('HubFrame',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h2',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.$t('botSettings.roles.allUsers')))])]},proxy:true},{key:"actions",fn:function(){return [_c('SettingsNewUser',{attrs:{"with2FA":_vm.allUsersHave2FA},on:{"onInvited":function($event){_vm.fetchUsers();
                _vm.trackIt('user-invitation');}}})]},proxy:true},{key:"content",fn:function(){return [_c('v-container',{staticClass:"pa-4",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('div',{staticClass:"d-flex"},[_c('v-icon',{attrs:{"color":"primary lighten-3","size":"16"}},[_vm._v(" mdi-filter ")]),_c('div',{staticClass:"text-body-2 primary--text text--lighten-2 pl-1"},[_vm._v(" "+_vm._s(_vm.$t('botSettings.roles.filterStatusLabel'))+" ")])],1),_c('ChipFilter',{attrs:{"options":_vm.statusFilterItems},on:{"onChange":_vm.changeStatusFilter}})],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"value":_vm.selectedUser,"item-key":"username","headers":_vm.usersHeaders,"items":_vm.usersFiltered,"single-select":"","items-per-page":20,"footer-props":{
                      'items-per-page-options': [10, 20, 50],
                    },"hide-default-footer":_vm.usersFiltered.length < 10},on:{"click:row":_vm.selectUser},scopedSlots:_vm._u([{key:`item.username`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(item.username))]),(item.isItself)?_c('span',{staticClass:"primary--text text--lighten-4"},[_vm._v(" ("+_vm._s(_vm.$t('botSettings.me'))+") ")]):_vm._e()]}},{key:`item.active`,fn:function({ item }){return [(item.active)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('botSettings.userStatus.active'))+" ")]):_vm._e(),(!item.active && !item.isExpired)?_c('span',{staticClass:"grey--text"},[_vm._v(" "+_vm._s(_vm.$t('botSettings.userStatus.pending'))+" ")]):_vm._e(),(item.isExpired)?_c('span',{staticClass:"grey--text"},[_vm._v(" "+_vm._s(_vm.$t('botSettings.userStatus.expired'))+" ")]):_vm._e()]}},{key:`item.role`,fn:function({ item }){return [(!item.role)?_c('span',[_vm._v(" "+_vm._s(_vm.$t(`botSettings.roles.role.unknown`))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t(`botSettings.roles.role.${item.role}`))+" ")])]}},{key:`item.edit`,fn:function(){return [_c('v-icon',{attrs:{"color":"primary lighten-3"}},[_vm._v("info")])]},proxy:true}],null,true)})],1)],1)],1)]},proxy:true}])})]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
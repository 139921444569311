// initial state
const state =  {
    contentQueue: {
        start: false,
        worker: 0,
        maxWorker: 8,
        queue: {}
    } // cache for syncing states
}

const getters = {
    isSyncing: (state) => {
        return state.contentQueue.start;
    },
    getQueue: (state) => (name) => {
        return state.contentQueue.queue[name];
    },
    isEmpty: (state) => {
        return Object.keys(state.contentQueue.queue).length === 0 && state.contentQueue.start && state.contentQueue.worker === 0
    },
    getQueues: (state) => {
        return Object.keys(state.contentQueue.queue);
    },
    workerSlotsAvailable: (state) => {
        return state.contentQueue.worker < state.contentQueue.maxWorker && Object.keys(state.contentQueue.queue).length > 0;
    }
}
const actions = {
    resetQueue({commit}){
        commit('resetQueue');
    }
}

const mutations = {
    addQueue(state, {element}) {
        if (element && element.box && state.contentQueue.queue !== false && !state.contentQueue.queue[element.box + element.channel]) {
            state.contentQueue.queue = {...state.contentQueue.  queue, [element.box + element.channel]: element};
        } else {
            state.contentQueue.queue = {
                [element.box + element.channel]: element
            }
        }
    },
    resetQueue(state){
         state.contentQueue.start = false;
         state.contentQueue.queue = {};
         state.contentQueue.worker = 0;
    },
    removeQueue(state, name) {
        delete state.contentQueue.queue[name];
        state.contentQueue.queue = {...state.contentQueue.queue};
    },
    removeWorker(state) {
        if (state.contentQueue.worker > 0) {
            state.contentQueue.worker--;
        }
    },
    addWorker(state) {
        state.contentQueue.worker++;
    },
    finish(state) {
        state.contentQueue.start = false;
    },
    start(state) {
        state.contentQueue.start = true;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
import _ from 'lodash';

const CopyPasteUtil = {
  createNewName(name, index = '') {
    const parts = name?.split('_');
    // replace date part
    if (parts.length > 1) {
      parts.pop();
    }
    return parts.join('_') + `_${index}_${Date.now()}`;
  },
  cloneActions(actions) {
    return actions?.map((action, index) => {
      if (!action.content) return action;
      const newName = this.createNewName(action.name, index);
      action.name = newName;
      if (action.content) {
        action.content.name = newName;
      }
      return action;
    });
  },
  cloneQuestion(question) {
    const clonedQuestion = _.cloneDeep(question);

    if (clonedQuestion.actions) {
      clonedQuestion.actions = this.cloneActions(clonedQuestion.actions);
    }

    return clonedQuestion;
  },
  cloneAnswer(answer) {
    const clonedAnswer = _.cloneDeep(answer);

    if (clonedAnswer.actions) {
      clonedAnswer.actions = this.cloneActions(clonedAnswer.actions);
    }

    return clonedAnswer;
  },
  cloneAnswers(answers) {
    return _.cloneDeep(answers);
  },
  /**
   * 
   * Clone the content object, follow ups are not cloned.
   * 
   * @param {Content} content - Content to clone.
   */
  cloneContent(content) {
    const clonedContent = _.cloneDeep(content);
    // set new box name
    clonedContent.box = this.createNewName(clonedContent.box);
    // copy all actions, find all properties with actions and clone them
    const allProperties = Object.keys(clonedContent);
    allProperties.forEach((property) => {
      if (clonedContent[property]?.actions) {
        clonedContent[property].actions = this.cloneActions(clonedContent[property].actions);
      }
    });

    return clonedContent;
  }
};

export default CopyPasteUtil;

<template>
  <div style="padding-right: 1px">
    <v-divider class="grey lighten-2"></v-divider>
    <div v-if="!user">
      <v-list class="py-0">
        <v-list-item
          :to="!preview ? `/login?redirect=${$route.fullPath}` : null"
          @click="
            preview ? goToUrl(`/login?redirect=${$route.fullPath}`) : null
          "
        >
          <v-list-item-icon>
            <v-icon color="secondary">exit_to_app</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t('login.btnLabel') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
    <div v-else-if="myProfileHidden">
      <v-list-item link @click="logout()" data-cy="logout">
        <v-list-item-icon>
          <v-icon color="secondary">exit_to_app</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ $t('menu.logout') }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </div>
    <v-expansion-panels v-else tile flat>
      <v-expansion-panel :key="0">
        <v-expansion-panel-header>
          <div class="d-flex align-center" data-cy="profile-menu">
            <v-icon color="secondary">mdi-account-circle</v-icon>
            <div class="text-h6 pt-1 ml-8 primary--text text--lighten-2">
              {{ $t('menu.profile') }}
            </div>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-list class="py-0">
            <v-list-item
              :to="!isTester ? '/user-profile' : null"
              @click="
                () => {
                  if (isTester) isProfileOpen = !isProfileOpen;
                }
              "
            >
              <v-list-item-icon>
                <v-icon color="secondary">mdi-account-cog</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t('menu.myProfile') }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item link @click="logout()" data-cy="logout">
              <v-list-item-icon>
                <v-icon color="secondary">exit_to_app</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t('menu.logout') }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <HubDialog
      v-model="isProfileOpen"
      :title="$t('menu.myProfile')"
      :primary-label="$t('common.save')"
      primary-icon="save"
      secondary-icon="close"
      @onPrimary="saveConfig()"
      @onSecondary="
        resetInputs();
        isProfileOpen = false;
      "
      :secondary-label="$t('common.cancel')"
      style="z-index: 100010"
      max-width="1200px"
    >
      <div>
        <UserProfileComp ref="userProfileComp" @onChange="isChanged = $event" />
      </div>
    </HubDialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import UserProfileComp from '@/components/userProfile/UserProfileComp.vue';
import HubDialog from '@/components/hub/HubDialog.vue';

export default {
  name: 'HubMenuProfile',
  components: {
    HubDialog,
    UserProfileComp,
  },
  props: {
    myProfileHidden: {
      type: Boolean,
      default: false,
    },
    preview: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // Only used, if user role tester
      isProfileOpen: false,
      isChanged: false,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters('auth', ['isOnlyStaging', 'user', 'isAdmin', 'isTester']),
  },
  methods: {
    resetInputs() {
      this.$refs.userProfileComp.resetInputs();
    },
    async saveConfig() {
      this.isLoading = true;
      this.$refs.userProfileComp.saveConfig();
    },
    async logout() {
      await this.$store.dispatch('bots/clear');
      this.$store.dispatch('auth/logout', {
        reload: this.preview ? true : false,
      });
    },
    goToUrl(url) {
      window.location = url;
    },
  },
};
</script>
<style lang="scss" scoped>
.v-expansion-panel-content :deep(.v-expansion-panel-content__wrap) {
  padding: 0;
}
</style>

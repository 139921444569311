<template>
  <div>
    <HubBox
      :title="$t('botSettings.userRole.title')"
      icon="mdi-badge-account-horizontal-outline"
      :titleSub="userRole"
    >
      <div class="mb-4">
        <div class="text-body-2">
          {{ $t('botSettings.userRole.description') }}
        </div>
      </div>

      <v-btn
        class="mt-6"
        outlined
        color="primary lighten-2"
        @click="dialogIsOpen = true"
      >
        {{ $t('botSettings.userRole.widgetBtn') }}
        <v-icon right>mdi-badge-account-horizontal-outline</v-icon>
      </v-btn>
    </HubBox>
    <HubDialog
      :value="dialogIsOpen"
      :title="$t('botSettings.userRole.changeRole')"
      asyncDialog
      primaryIcon="mdi-badge-account-horizontal-outline"
      :primaryLabel="$t('editor.common.deploy')"
      @onSecondary="dialogIsOpen = false"
      @onPrimary="changeRole"
      :isLoading="isLoading"
      :disabled="selectedRoleInitial === selectedRole"
      modal
    >
      <div class="text-body-1 mb-4">
        {{ $t('botSettings.userRole.setRoleAndRights') }}
      </div>

      <div class="text-body-2">
        {{ $t('botSettings.roles.userSettings.userTitle') }}
      </div>
      <div class="text-body-1">
        <strong>{{ user.username }}</strong>
      </div>
      <div class="mt-4 text-body-2 mb-n3">
        {{ $t('botSettings.userRole.role') }}
      </div>
      <v-radio-group v-model="selectedRole">
        <v-radio
          v-for="option in rolesOptions"
          :value="option.value"
          :key="option.value"
          :label="option.label"
        />
      </v-radio-group>

      <InfoBox icon="mdi-alert" :text="$t('botSettings.userRole.changeHint')" />
    </HubDialog>
  </div>
</template>
<script>
import BotService from '@/services/bot';
import HubDialog from '@/components/hub/HubDialog.vue';
import InfoBox from '@/components/common/InfoBox.vue';
import HubBox from '@/components/hub/HubBox.vue';
import { rolesTypes, getFeaturesByRole } from '@/utils/userRoles';

export default {
  name: 'SettingsUserRole',
  components: {
    HubDialog,
    InfoBox,
    HubBox,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialogIsOpen: false,
      isLoading: false,
      selectedRoleInitial: this.user.role,
      selectedRole: this.user.role,
      rolesTypes,
      rolesOptions: [
        {
          label: this.$t(`botSettings.roles.role.${rolesTypes.VIEWER}`),
          value: rolesTypes.VIEWER,
        },
        {
          label: this.$t(`botSettings.roles.role.${rolesTypes.EDITOR}`),
          value: rolesTypes.EDITOR,
        },
        {
          label: this.$t(`botSettings.roles.role.${rolesTypes.OWNER}`),
          value: rolesTypes.OWNER,
        },
      ],
    };
  },
  computed: {
    userRole() {
      if (!this.user?.role) {
        return this.$t(`botSettings.roles.role.unknown`);
      }
      return this.$t(`botSettings.roles.role.${this.user?.role}`);
    },
  },
  methods: {
    async changeRole() {
      const features = getFeaturesByRole(this.selectedRole);

      // Keep 2FA & admin features
      if (this.user.features.includes('2FA')) features.push('2FA');
      if (this.user.features.includes('admin')) features.push('admin');

      const data = {
        active: true,
        features,
      };

      this.isLoading = true;
      await BotService.updateUser(this.user.username, data);

      this.$emit('onChanged');
      this.user.role = this.selectedRole;

      this.selectedRoleInitial = this.selectedRole;
      this.isLoading = false;
      this.dialogIsOpen = false;
    },
  },
};
</script>
export const styleTypes = {
  WARNING: 'warning',
  ERROR: 'error',
  SUCCESS: 'success',
  INFO: 'info',
};

// Bounded to notifications in de.json | en.json
export const messageTypes = {
  NEW_VERSION: 'newVersion',
  NEW_VERSION_PC: 'newVersionPc',
  NEW_VERSION_MAC: 'newVersionMac',
  PERFORMANCE_TRACKING: 'performanceTracking',
  PERFORMANCE_TRACKING_DRAFT: 'performanceTrackingDraft',
  NO_CONNECTION: 'noConnection',
  DEMO_ACCOUNT: 'demoAccount',
  REVIEW_INTENT: 'reviewIntent',
  REVIEW_INTENT_DEMO: 'reviewIntentDemo',
  DEPLOYMENT_READY: 'deploymentReady',
};

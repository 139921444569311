<template>
  <div>
    <IntentNewStepSummary />

    <v-row align="center" justify="space-between" class="mt-4">
      <v-col>
        <v-btn color="primary lighten-2" text @click="cancelCreation">
          <v-icon left size="24">mdi-arrow-left</v-icon>
          {{ $t('common.back') }}
        </v-btn>
      </v-col>

      <v-col class="text-right">
        <v-btn
          color="secondary darken-2"
          depressed
          @click.once="openCreateDialog"
        >
          {{ $t('intentNew.newWithoutTemplate.addIntent') }}
          <v-icon right>mdi-plus-circle</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <HubDialog
      :value="createDialogIsOpen"
      :disabled="isLoading || !isQuotaOk"
      :primary-label="$t('common.save')"
      primary-icon="save"
      :secondary-label="$t('common.cancel')"
      secondary-icon="close"
      :title="
        $t('intentNew.dialogNewWithTemplate.title', {
          displayName: displayName(newIntent.intent, $vuetify.lang.current),
        })
      "
      modal
      @onPrimary="createFromStandard"
      @onSecondary="closeCreateDialog"
      asyncDialog
    >
      {{
        $t('intentNew.dialogNewWithTemplate.body', {
          displayName: displayName(newIntent.intent, $vuetify.lang.current),
        })
      }}

      <div class="pt-6" v-if="isAdmin">
        <InfoBox
          v-if="newIntent.template === 'Smalltalks'"
          :text="$t('intentsSuggestion.quotaSmalltalks')"
        />
        <InfoBox
          v-else-if="quota"
          :text="
            isQuotaOk
              ? $t('intentsSuggestion.quotaInfoOk', {
                  available: availableQuota,
                  all: quota.intents,
                })
              : $t('intentsSuggestion.quotaInfoNotOk', { all: quota.intents })
          "
          :icon="isQuotaOk ? 'mdi-comment-check' : 'mdi-comment-alert-outline'"
        />

        <InfoBox
          v-else
          text="Es sind noch keine Quota eingestellt."
          icon="mdi-comment-alert-outline"
        />
      </div>
      <div class="pt-6" v-else>
        <InfoBox
          v-if="newIntent.template === 'Smalltalks'"
          :text="$t('intentsSuggestion.quotaSmalltalks')"
        />
        <InfoBox
          v-else-if="quota"
          :text="
            isQuotaOk
              ? $t('intentsSuggestion.quotaInfoOk', {
                  available: availableQuota,
                  all: quota.intents,
                })
              : $t('intentsSuggestion.quotaInfoNotOk', { all: quota.intents })
          "
          :icon="isQuotaOk ? 'mdi-comment-check' : 'mdi-comment-alert-outline'"
        />
      </div>
    </HubDialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { statusTypes } from '@/store/modules/intentNew';
import IntentNewStepSummary from './IntentNewStepSummary.vue';
import HubDialog from '@/components/hub/HubDialog.vue';
import { TrackingService } from '@/services/tracking';
import InfoBox from '../common/InfoBox.vue';

export default {
  name: 'IntentNewWithTemplate',
  components: {
    IntentNewStepSummary,
    HubDialog,
    InfoBox,
  },
  data() {
    return {
      isLoading: false,
      createDialogIsOpen: false,
    };
  },
  computed: {
    ...mapGetters('bots', ['currentBotId']),
    ...mapGetters('intentNew', ['newIntent']),
    ...mapGetters('intents', ['intents', 'displayName']),
    ...mapGetters('auth', ['isAdmin']),
    quota() {
      return (
        this.$store.getters['bots/getBotSettings'](this.currentBotId)?.quota ||
        null
      );
    },
    isQuotaOk() {
      if (this.newIntent.template === 'Smalltalks') return true;
      if (!this.quota) return null;
      return this.intents.length < this.quota.intents;
    },
    availableQuota() {
      return this.quota?.intents - this.intents?.length - 1;
    },
  },
  methods: {
    openCreateDialog() {
      this.trackIt('with-template--dialog-open');
      this.createDialogIsOpen = true;
    },
    closeCreateDialog() {
      this.trackIt('with-template--dialog-close');
      this.createDialogIsOpen = false;
    },
    async createFromStandard() {
      this.trackIt('with-template--save');
      let intent = this.$store.getters['intents/getIntentFromLibraryByName'](
        this.newIntent.intent
      );

      if (intent) {
        this.isLoading = true;
        const result = await this.$store.dispatch(
          'intents/addIntentFromSource',
          {
            botId: this.currentBotId,
            intent: intent.intent,
            sourceBot: intent.botId,
          }
        );

        if (result?.intent === this.newIntent.intent) {
          this.isLoading = false;
          this.$store.commit('intentNew/setStatus', statusTypes.created);
          this.$store.commit('intentNew/setCreatedIntent', result.intent);
        }
      }
    },
    cancelCreation() {
      this.trackIt('with-template--cancel');
      this.$store.commit('intentNew/setStatus', statusTypes.initial);
    },
    trackIt(type = 'with-template--undefined') {
      TrackingService.trackNewIntent({
        botId: this.currentBotId,
        type,
        source: 'IntentNewWithTemplate',
        payload: {
          newIntent: this.newIntent,
        },
      });
    },
  },
};
</script>

const state = {
  audio: null,
};

const actions = {
  playAudio({ state }, { url, volume }) {
    if (!url) return;

    try {
      state.audio?.pause();
      state.audio = null;
      state.audio = new Audio(url);
      state.audio.volume = volume || 0.5;
      state.audio.play().catch(() => {});
    } catch (e) {}
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
